import { type AsyncState } from '@essent/common';
import { type UsageQuestionnaireState } from '@innogy/common-ui/sales';
import type { GetSolarPaybackResponse } from '@innogy/eplus/temporary-core-modules';
import { createFeatureSelector } from '@ngrx/store';

export const solarPaybackSelectorKey = 'eplus-solar-payback';
export const SOLAR_PAYBACK_USAGE_ESTIMATE_ID = 'solar-payback-usage-estimate';

export interface EnergyUsage {
  kwh: number;
}

export interface SolarPaybackState {
  usageEstimateFormState: UsageQuestionnaireState;
  apiState: AsyncState<GetSolarPaybackResponse>;
  energyUsage: EnergyUsage;
}

export const eplusSolarPaybackFeature =
  createFeatureSelector<SolarPaybackState>(solarPaybackSelectorKey);

export const initialEnergyUsage: EnergyUsage = {
  kwh: 0,
};
