import { getMGMProductCode } from '@innogy/become-a-customer/shared';
import { createSelector } from '@ngrx/store';
import type { EnvConfigBrand } from '@core/config-models';

import { getBecomeACustomerState } from '../../become-a-customer-feature.selector';
import { getOrderPaymentFormState } from '../../order/payment/order-payment.selector';
import { getOrderPersonalFormState } from '../../order/personal/order-personal.selector';
import { getPropositionOffer } from '../offers/offers.selectors';

export const getSaleId = createSelector(
  getBecomeACustomerState,
  (state) => state?.memberGetMember?.saleId
);

export const hasMemberGetMember = createSelector(
  getSaleId,
  (mgmSaleId) => !!mgmSaleId
);

export const getMemberGetMemberPayload = (brand: EnvConfigBrand) =>
  createSelector(
    getOrderPersonalFormState,
    getOrderPaymentFormState,
    getSaleId,
    getPropositionOffer,
    (
      orderPersonalFormState,
      orderPaymentFormState,
      sale_id,
      propositionOffer
    ) => {
      if (sale_id && propositionOffer) {
        return {
          name: `${orderPersonalFormState.value.initials} ${orderPersonalFormState.value.lastName}`,
          email: orderPersonalFormState.value.email,
          bank_account: orderPaymentFormState.value.iban,
          sale_id,
          product: getMGMProductCode(propositionOffer, brand),
          state: 'confirmed' as const,
        };
      }
      return null;
    }
  );
