import type { ActionReducerMap } from '@ngrx/store';
import { createFeatureSelector } from '@ngrx/store';

import type { StoreAPIState } from './store-api/store-api.reducer';
import { storeApiKey, storeAPIReducer } from './store-api/store-api.reducer';
import type { StoreFunnelState } from './store-funnel/store-funnel.reducer';
import {
  storeFunnelKey,
  storeFunnelReducer,
} from './store-funnel/store-funnel.reducer';

export const storeSelectorKey = 'eplus-store';

export interface StoreReducerState {
  readonly [storeApiKey]: StoreAPIState;
  readonly [storeFunnelKey]: StoreFunnelState;
}

export const storeReducers: ActionReducerMap<StoreReducerState> = {
  [storeApiKey]: storeAPIReducer,
  [storeFunnelKey]: storeFunnelReducer,
};

export const eplusStoreFeature =
  createFeatureSelector<StoreReducerState>(storeSelectorKey);
