import { createSelector } from '@ngrx/store';

import { getBecomeACustomerState } from '../../become-a-customer-feature.selector';

export const getCorrespondenceDetailsState = createSelector(
  getBecomeACustomerState,
  (state) => state.correspondenceDetails.getCorrespondenceDetails
);

export const putCorrespondenceDetailsState = createSelector(
  getBecomeACustomerState,
  (state) => state.correspondenceDetails.putCorrespondenceDetails
);
