<div class="row d-flex justify-content-center">
  <div
    *ngFor="let duration of durations; trackBy: trackByDuration"
    class="col-auto"
  >
    <button
      wlAutomationId="duration-filter-button"
      class="btn offer-duration--button rounded"
      [class.offer-duration--button__active]="
        activeDuration === duration.duration
      "
      (click)="changeDurationClick.emit(duration.duration)"
    >
      {{ duration.durationTitle }}
    </button>
  </div>
</div>
