import type { ActionReducer } from '@ngrx/store';
import type { CreatedAction, KeyValue } from 'ngrx-forms';

import type { Actions, NGRXProgressiveFormsActions } from './actions';
import {
  ALL_PROGRESSIVE_NGRX_FORMS_ACTION_TYPES,
  isNGRXFormsAction,
  isProgressiveNGRXFormsAction,
} from './actions';
import { activateRestorationPointReducer } from './reducer/activate-restoration-point';
import { activeReducer } from './reducer/active';
import { backupFormStateReducer } from './reducer/backup-form-state';
import { formStateReducer } from './reducer/form-state';
import { inactiveReducer } from './reducer/inactive';
import { markAsEditableReducer } from './reducer/mark-as-editable';
import { markAsInertReducer } from './reducer/mark-as-inert';
import { markAsRestorationPointReducer } from './reducer/mark-as-restoration-point';
import { markAsSubmittableReducer } from './reducer/mark-as-submittable';
import { markAsTasksCompletedReducer } from './reducer/mark-as-tasks-completed';
import { markAsTasksPendingReducer } from './reducer/mark-as-tasks-pending';
import { markAsUnsubmittableReducer } from './reducer/mark-as-unsubmittable';
import { markAsUnvisitedReducer } from './reducer/mark-as-unvisited';
import { markAsVisitedReducer } from './reducer/mark-as-visited';
import { resetReducer } from './reducer/reset';
import { restoreFormStateReducer } from './reducer/restore-form-state';
import { submitReducer } from './reducer/submit';
import { unsubmitReducer } from './reducer/unsubmit';
import type { ProgressiveFormGroupState } from './state';
import { isProgressiveFormState } from './state';

function progressiveFormStateReducerInternal<TValue extends KeyValue>(
  state: ProgressiveFormGroupState<TValue>,
  action: Actions
) {
  if (isProgressiveNGRXFormsAction(action)) {
    state = activeReducer(state, action);
    state = inactiveReducer(state, action);
    state = markAsEditableReducer(state, action);
    state = markAsInertReducer(state, action);
    state = markAsVisitedReducer(state, action);
    state = markAsUnvisitedReducer(state, action);
    state = submitReducer(state, action);
    state = unsubmitReducer(state, action);
    state = markAsSubmittableReducer(state, action);
    state = markAsUnsubmittableReducer(state, action);
    state = backupFormStateReducer(state, action);
    state = restoreFormStateReducer(state, action);
    state = resetReducer(state, action);
    state = markAsRestorationPointReducer(state, action);
    state = activateRestorationPointReducer(state, action);
    state = markAsTasksPendingReducer(state, action);
    state = markAsTasksCompletedReducer(state, action);
  }

  if (isNGRXFormsAction(action)) {
    if (!action.controlId.startsWith(state.id)) {
      return state;
    }
    state = formStateReducer(state, action);
  }

  return state;
}

export function progressiveFormStateReducer<TValue extends KeyValue>(
  state: ProgressiveFormGroupState<TValue>,
  action: Actions
) {
  if (!state) {
    throw new Error('The progressive form state must be defined!');
  }

  if (!isProgressiveFormState(state)) {
    throw new Error('The provided state is not a progressive form state!');
  }

  return progressiveFormStateReducerInternal(state, action);
}

export function onProgressiveNGRXForms<TState extends KeyValue>(): {
  reducer: ActionReducer<TState, any>;
  types: string[];
} {
  return {
    reducer: (state: any, action: Actions) =>
      isProgressiveFormState(state)
        ? (progressiveFormStateReducer(state, action) as unknown as TState)
        : reduceNestedProgressiveFormStates(state, action),
    types: ALL_PROGRESSIVE_NGRX_FORMS_ACTION_TYPES,
  };
}
export interface ProgressiveNGRXFormsActionConstructor {
  new (...args: any[]): NGRXProgressiveFormsActions;
  readonly TYPE: string;
}
/**
 * Define a reducer for a progressive forms action. This functions works the same as
 * ngrx's `on` except that you provide the progressive forms action class instead of
 * your action creator as a parameter.
 */
export function onProgressiveNGRXFormsAction<
  TActionCons extends ProgressiveNGRXFormsActionConstructor,
  TState,
>(
  actionCons: TActionCons,
  reducer: (state: TState, action: CreatedAction<TActionCons>) => TState
) {
  return {
    reducer,
    types: [actionCons.TYPE],
  };
}
function reduceNestedProgressiveFormState<TState extends KeyValue>(
  state: TState,
  key: keyof TState,
  action: Actions
): TState {
  const value = state[key];

  if (!isProgressiveFormState(value)) {
    return state;
  }

  return {
    ...state,
    [key]: progressiveFormStateReducer(value, action),
  };
}

function reduceNestedProgressiveFormStates<TState extends KeyValue>(
  state: TState,
  action: Actions
): TState {
  return Object.keys(state).reduce(
    (s, key) =>
      reduceNestedProgressiveFormState(s, key as keyof TState, action),
    state
  );
}

/**
 * This function wraps a reducer and returns another reducer that first calls
 * the given reducer and then calls the given update function for the form state
 * that is specified by the form state locator function.
 *
 * The update function is passed the root state as parameter,
 * this is done so the update function is ensured to have access to the siblings
 * of the progressive form state. We only care about it returning an expected form state.
 *
 */
export function wrapReducerWithProgressiveFormsUpdate<
  TState extends KeyValue,
  TFormState extends KeyValue,
>(
  reducer: ActionReducer<TState>,
  updateFn: (state: TState) => TFormState
): ActionReducer<TState> {
  return (state, action) => {
    if (!state) {
      throw new Error('No state available to wrap reducer with');
    }

    const updatedState = reducer(state, action);

    // if the state itself is the progressive form state, update it directly.
    if (isProgressiveFormState(updatedState)) {
      return {
        ...updatedState,
        formState: updateFn(state),
      };
    }

    // since the formState is always nested under the progressive Forms state,
    // we locate the progressive forms state and validate the contained form state.
    // we force the progressive forms key to be directly nested under the reducer that
    // we provide, no deeper than that.
    const progressiveFormStateKey = Object.keys(updatedState).find((key) =>
      isProgressiveFormState(updatedState[key as keyof TState])
    );

    if (!progressiveFormStateKey) {
      throw new Error(
        'No progressive form state can be located directly contained within provided state.'
      );
    }

    const updatedFormState = updateFn(updatedState);

    return {
      ...updatedState,
      [progressiveFormStateKey]: {
        ...updatedState[progressiveFormStateKey],
        formState: updatedFormState,
      },
    };
  };
}
