import type { OnChanges, SimpleChanges } from '@angular/core';
import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[wlAutomationId]',
  standalone: true,
})
export class AutomationIdDirective implements OnChanges {
  @Input()
  wlAutomationId?: string;

  constructor(
    private readonly renderer: Renderer2,
    private readonly el: ElementRef
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['wlAutomationId'] && this.wlAutomationId) {
      this.renderer.setAttribute(
        this.el.nativeElement,
        'data-automation-id',
        this.wlAutomationId || ''
      );
    }
  }
}
