import { pattern } from 'ngrx-forms/validation';

/**
 * RegExp that represents valid initials.
 */
export const INITIALS_REGEXP = /^([A-Z]\.)+$/;

/**
 * Validates that the given value matches our pattern for initials
 */
export const isInitials = pattern(INITIALS_REGEXP);
