/* eslint-disable max-classes-per-file */
import {
  Component,
  ContentChild,
  Directive,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';

@Directive({ selector: 'ng-template[wlModalBody]' })
export class ModalBodyDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({ selector: 'ng-template[wlModalFooter]' })
export class ModalFooterDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Component({
  selector: 'wl-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.essent.scss', './modal.component.ed.scss'],
})
export class ModalComponent {
  @ContentChild(ModalBodyDirective, { static: true })
  body?: ModalBodyDirective;
  @ContentChild(ModalFooterDirective, { static: true })
  footer?: ModalFooterDirective;

  @Input()
  modalTitle?: string;
  @Input()
  autoCloseTime?: number;

  @Output()
  closeModal = new EventEmitter<void>();
  @Output()
  clickModal = new EventEmitter<void>();

  handleClick() {
    this.clickModal.emit();
    this.closeModal.emit();
  }
}
