import { pattern } from 'ngrx-forms/validation';

/**
 * RegExp that represents a valid prefix.
 */
export const PREFIX_REGEXP = /^[a-z ,.\-/']+$/i;

/**
 * Validates that the given value matches our pattern for prefixes.
 */
export const isPrefix = pattern(PREFIX_REGEXP);
