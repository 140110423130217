import { createAction, props } from '@ngrx/store';

const type = `[Generic Progressive Form]`;

export const registerSCProgressiveFormAction = createAction(
  `${type} Register Form`,
  props<{
    formId: string;
  }>()
);

export const submitGenericProgressiveFormAction = createAction(
  `${type} Submit Form`,
  props<{
    toolName: string;
    formId: string;
  }>()
);

export const resetGenericProgressiveFormAction = createAction(
  `${type} Reset Form`,
  props<{
    payload: string;
  }>()
);
