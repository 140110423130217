import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

import { transformDateInput } from '../dates/date.helper';

@Pipe({ name: 'formatDateInput' })
export class DateInputFormatterPipe implements PipeTransform {
  transform(input = ''): string {
    return transformDateInput(input.replace(/[^0-9-]/g, '')).substring(0, 10);
  }
}
