import type { AsyncState } from '@essent/common';
import {
  createAsyncStateReducer,
  createInitialAsyncState,
} from '@essent/common';
import type { GetInstallations } from '@essent/new-customer';
import {
  getInstallations,
  getInstallationsClear,
  getInstallationsError,
  getInstallationsSuccess,
  putEanDetails,
  putEanDetailsClear,
  putEanDetailsError,
  putEanDetailsSuccess,
} from '@essent/new-customer';
import type { Action, ActionReducerMap } from '@ngrx/store';
import { combineReducers } from '@ngrx/store';

export type GetInstallationsState = AsyncState<GetInstallations[]>;
export type PutEanDetailsState = AsyncState<void>;

export interface InstallationDetailsState {
  getInstallations: GetInstallationsState;
  putEanDetails: PutEanDetailsState;
}

const initialState: InstallationDetailsState = {
  getInstallations: createInitialAsyncState(),
  putEanDetails: createInitialAsyncState(),
};

const _getInstallationDetails = createAsyncStateReducer(
  getInstallations,
  getInstallationsSuccess,
  getInstallationsError,
  getInstallationsClear
);

const _putEanDetailsReducer = createAsyncStateReducer(
  putEanDetails,
  putEanDetailsSuccess,
  putEanDetailsError,
  putEanDetailsClear
);

const _reducers: ActionReducerMap<InstallationDetailsState, Action> = {
  getInstallations: _getInstallationDetails,
  putEanDetails: _putEanDetailsReducer,
};

const _combinedReducers = combineReducers(_reducers);

export function installationDetailsReducer(
  state: InstallationDetailsState = initialState,
  action: Action
): InstallationDetailsState {
  return _combinedReducers(state, action);
}
