import { Injectable } from '@angular/core';
import {
  getSupplyAddressError,
  putConsumptionError,
  putEanDetailsError,
} from '@essent/new-customer';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MarkAsUnsubmittedAction } from 'ngrx-forms';
import { mergeMap } from 'rxjs/operators';

import { handleBacApiErrorAction } from '../bac/0.errors';
import { putInitiateFlowError } from '../bac/1.initiate-flow';
import { CALCULATE_FORM_ID } from './calculate.reducer';
import { setApiCallsFinished } from './calculate.actions';

@Injectable()
export class CalculateErrorEffects {
  constructor(private readonly actions$: Actions) {}

  /**
   * Handles errors of API calls related to the calculate subflow.
   * Emits a generic `handleBacApiError` action when triggered.
   */
  public readonly emitBacApiErrorOnCalculateError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSupplyAddressError, putConsumptionError, putEanDetailsError),
      mergeMap((action) => [
        setApiCallsFinished(),
        handleBacApiErrorAction({
          message: action.payload.message,
          stepName: 'Calculate',
        }),
        new MarkAsUnsubmittedAction(CALCULATE_FORM_ID),
      ])
    )
  );

  public readonly handleBlockingInitiateFlowError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(putInitiateFlowError),
      mergeMap((action) => {
        const { error, message } = action.payload;
        return [
          setApiCallsFinished(),
          handleBacApiErrorAction({
            message: error || message,
            stepName: 'Calculate',
          }),
          new MarkAsUnsubmittedAction(CALCULATE_FORM_ID),
        ];
      })
    )
  );
}
