import { Status } from '@essent/common';
import { unknownActionId } from '@core/ngrx';
import { getCollectionObject, selectQueryParam } from '@innogy/utils-state';
import { ChargeCardType } from '@integration/emobility-api-models';
import { createSelector } from '@ngrx/store';
import { getCustomerCorrespondenceEmail } from '@customer/entity';

import {
  blockChargeCardKey,
  chargeCardsKey,
  emobilityCaseFeature,
} from './chargecards.state';

/* istanbul ignore next */
const getChargeCardsState = createSelector(emobilityCaseFeature, (feature) =>
  getCollectionObject(feature[chargeCardsKey], unknownActionId)
);

/* istanbul ignore next */
const getBlockChargeCardState = createSelector(
  emobilityCaseFeature,
  (feature) => getCollectionObject(feature[blockChargeCardKey], unknownActionId)
);

/* istanbul ignore next */
export const getBlockChargeCardStatus = createSelector(
  getBlockChargeCardState,
  (state) => state?.status
);

export const blockChargeCardLoading = createSelector(
  getBlockChargeCardStatus,
  (status) => status == null || [Status.IDLE, Status.PENDING].includes(status)
);

export const blockChargeCardShowSuccess = createSelector(
  getBlockChargeCardState,
  (state) => {
    return state?.status === Status.SUCCESS;
  }
);

export const blockChargeCardShowError = createSelector(
  getBlockChargeCardState,
  (state) => state?.status === Status.ERROR
);

export const getChargeCardsStatus = createSelector(
  getChargeCardsState,
  (state) => state?.status
);

export const getChargeCardsEntry = createSelector(
  getChargeCardsState,
  (state) => state?.entry
);

export const getChargeCardsHasError = createSelector(
  getChargeCardsStatus,
  (status) => status === Status.ERROR
);

export const getIsChargeCardsLoading = createSelector(
  getChargeCardsStatus,
  (status) => status == null || [Status.IDLE, Status.PENDING].includes(status)
);

export const getChargeCardsData = createSelector(
  getChargeCardsEntry,
  (entry) => entry?.results ?? []
);

export const getHasChargeCards = createSelector(
  getChargeCardsData,
  (chargeCards) => chargeCards.length > 0
);

export const getChargeCardsForSessions = createSelector(
  getChargeCardsEntry,
  (entry) =>
    entry?.results
      ? entry.results.map((chargeCard) => ({
          ...chargeCard,
          expiresAt: new Date(chargeCard.expiresAt),
        }))
      : undefined
);

export const getChargeCards = createSelector(
  getChargeCardsData,
  (chargeCards) =>
    chargeCards.map((chargeCard) => ({
      ...chargeCard,
      expiresAt: new Date(chargeCard.expiresAt),
    }))
);

export const getChargeCardsVm = createSelector(
  getChargeCards,
  getChargeCardsHasError,
  (chargeCards, hasError) => ({ chargeCards, hasError })
);

export type ChargeCard = ReturnType<typeof getChargeCards>[number];

export const getSelectedChargeCard = createSelector(
  selectQueryParam('card'),
  getChargeCards,
  (cardNumber, chargeCards) =>
    chargeCards.find(
      (chargeCard) => chargeCard.cardNumber === cardNumber
    ) as ChargeCard
);

export const chargeCardPrefix = 'NL-ESS-C';
export const keyFobPrefix = 'NL-ESS-D';

/**
 * Returns the charge card type based on the card number, defaults to charge card
 *
 * Charge cards are in the format NL-ESS-C00000000-E
 * Key fobs are in the format NL-ESS-D00000000-E
 */
export const getChargeCardType = (cardNumber = chargeCardPrefix) =>
  cardNumber?.toUpperCase()?.startsWith(keyFobPrefix)
    ? ChargeCardType.KeyFob
    : ChargeCardType.ChargeCard;

export const getBlockChargeCardVm = createSelector(
  blockChargeCardShowError,
  blockChargeCardLoading,
  blockChargeCardShowSuccess,
  getSelectedChargeCard,
  getCustomerCorrespondenceEmail,
  selectQueryParam('action'),
  (
    hasError,
    showConfirmation,
    success,
    chargeCard,
    correspondenceEmail,
    action
  ) => ({
    hasError,
    showConfirmation,
    success,
    chargeCard,
    correspondenceEmail,
    action: action ?? 'block',
    chargeCardType: getChargeCardType(chargeCard?.cardNumber),
  })
);
