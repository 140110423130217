import { createSelector } from '@ngrx/store';

import { getBecomeACustomerState } from '../../become-a-customer-feature.selector';

export const getBudgetBillState = createSelector(
  getBecomeACustomerState,
  (state) => state.budgetBill.getBudgetBill
);

export const putBudgetBillState = createSelector(
  getBecomeACustomerState,
  (state) => state.budgetBill.putBudgetBill
);
