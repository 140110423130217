import type { OnInit } from '@angular/core';
import { Component, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Store } from '@ngrx/store';
import {
  FocusAction,
  FormControlState,
  MarkAsDirtyAction,
  MarkAsTouchedAction,
  SetValueAction,
  UnfocusAction,
} from 'ngrx-forms';

import type { DropdownItem, DropdownItemValue } from './dropdown.model';

@Component({
  selector: 'wl-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: [
    './dropdown.component.essent.scss',
    './dropdown.component.ed.scss',
  ],
})
export class DropdownComponent implements OnInit {
  @Input()
  formsControl?: FormControlState<DropdownItemValue | undefined>;
  @Input()
  icon?: IconProp;
  @Input()
  placeholder?: string;
  @Input()
  label?: string;
  @Input()
  labelForId = 'none';
  @Input()
  clearable = false;
  // floatingLabel = true is the default for dropdowns
  @Input()
  floatingLabel = true;
  @Input()
  omitValidationClasses?: string[];

  @ViewChild('ngSelectComponent')
  public ngSelect!: NgSelectComponent;

  currentValue: DropdownItemValue = null;
  selectedOption?: DropdownItem;

  get anyIcons() {
    return this.dropdownItems?.some((item) => item.icon);
  }

  private _dropdownItems: DropdownItem[] | null = null;
  get dropdownItems() {
    return this._dropdownItems;
  }
  @Input()
  set dropdownItems(value: DropdownItem[] | null | undefined) {
    this._dropdownItems = value ?? null;
    if (this.currentValue != null && !this.isInDropdown(this.currentValue)) {
      this.currentValue = null;
      this.setValue(this.currentValue);
    }
  }

  constructor(
    private readonly store$: Store<any>,
    private readonly viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {
    if (this.placeholder && this.label && this.floatingLabel) {
      this.placeholder = undefined;
    }

    if (
      typeof this.formsControl?.value === 'string' &&
      this.isInDropdown(this.formsControl?.value)
    ) {
      this.currentValue = this.formsControl?.value;
    }
  }

  public onOpen() {
    if (this.formsControl != null) {
      this.store$.dispatch(new FocusAction(this.formsControl.id));
      this.dispatchNativeEvent('focus');
    }
  }

  public onClose() {
    if (this.formsControl != null) {
      this.store$.dispatch(new UnfocusAction(this.formsControl.id));
      this.touchControl(this.formsControl);
    }
  }

  public onSelect(option: DropdownItem | undefined) {
    this.selectedOption = option;
    this.setValue(option?.value as DropdownItemValue);
  }

  private setValue(value: DropdownItemValue) {
    if (this.formsControl != null) {
      this.store$.dispatch(new SetValueAction(this.formsControl.id, value));
      this.touchControl(this.formsControl);
      this.store$.dispatch(new UnfocusAction(this.formsControl.id));
      this.dispatchNativeEvent('change');
    }
  }

  private isInDropdown(dropdownItemValue: DropdownItemValue) {
    return !!this.dropdownItems
      ?.map(({ value }) => value)
      .includes(dropdownItemValue);
  }

  private touchControl(
    control: FormControlState<DropdownItemValue | undefined>
  ) {
    this.store$.dispatch(new MarkAsTouchedAction(control.id));
    this.store$.dispatch(new MarkAsDirtyAction(control.id));
  }

  private dispatchNativeEvent(eventName: string) {
    this.viewContainerRef.element.nativeElement.dispatchEvent(
      new Event(eventName)
    );
  }
}
