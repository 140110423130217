import { createNamedApi } from '@essent/common';

import { PostInsulationConfirmationService } from './post-insulation-confirmation.service';

export const postInsulationConfirmationApi = createNamedApi(
  '[PostInsulationConfirmationState] Post Insulation Confirmation',
  PostInsulationConfirmationService
);

export const {
  postInsulationConfirmationActions,
  postInsulationConfirmationSuccess,
  postInsulationConfirmationClear,
  postInsulationConfirmationError,
  postInsulationConfirmation,
} = postInsulationConfirmationApi;
