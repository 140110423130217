import type { AsyncState } from '@essent/common';
import {
  createAsyncStateReducer,
  createInitialAsyncState,
} from '@essent/common';
import type { CorrespondenceDetails } from '@essent/new-customer';
import {
  getCorrespondenceDetails,
  getCorrespondenceDetailsClear,
  getCorrespondenceDetailsError,
  getCorrespondenceDetailsSuccess,
  putCorrespondenceDetails,
  putCorrespondenceDetailsClear,
  putCorrespondenceDetailsError,
  putCorrespondenceDetailsSuccess,
} from '@essent/new-customer';
import type { Action, ActionReducerMap } from '@ngrx/store';
import { combineReducers } from '@ngrx/store';

export type PutCorrespondenceDetailsState = AsyncState<void>; // FIXME, would expect FlowId
export type GetCorrespondenceDetailsState = AsyncState<CorrespondenceDetails>;

export interface CorrespondenceDetailsState {
  putCorrespondenceDetails: PutCorrespondenceDetailsState;
  getCorrespondenceDetails: GetCorrespondenceDetailsState;
}

const initialState: CorrespondenceDetailsState = {
  putCorrespondenceDetails: createInitialAsyncState(),
  getCorrespondenceDetails: createInitialAsyncState(),
};

const _putCorrespondenceDetailsReducer = createAsyncStateReducer(
  putCorrespondenceDetails,
  putCorrespondenceDetailsSuccess,
  putCorrespondenceDetailsError,
  putCorrespondenceDetailsClear
);

const _getCorrespondenceDetailsReducer = createAsyncStateReducer(
  getCorrespondenceDetails,
  getCorrespondenceDetailsSuccess,
  getCorrespondenceDetailsError,
  getCorrespondenceDetailsClear
);

const _reducers: ActionReducerMap<CorrespondenceDetailsState, Action> = {
  putCorrespondenceDetails: _putCorrespondenceDetailsReducer,
  getCorrespondenceDetails: _getCorrespondenceDetailsReducer,
};

const _combinedReducers = combineReducers(_reducers);

export function correspondenceDetailsReducer(
  state: CorrespondenceDetailsState = initialState,
  action: Action
): CorrespondenceDetailsState {
  return _combinedReducers(state, action);
}
