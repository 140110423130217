<wl-modal (closeModal)="closeModal()" [modalTitle]="rendering?.title">
  <ng-template wlModalBody>
    <ng-container *ngIf="formState$ | async as formState">
      <wl-usage-questionnaire-sme
        [rendering]="rendering"
        [formState]="formState"
        [gasHidden]="gasHidden"
      />
    </ng-container>
  </ng-template>
</wl-modal>
