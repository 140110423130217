import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommonUiCommonRenderingsModule } from '@innogy/common-ui/common-renderings';

import { FunctionalErrorContainerComponent } from './functional-error-container/functional-error-container.component';
import { FunctionalErrorItemPipe } from './functional-errors.pipe';

//TODO: move the FunctionalErrorComp to this folder

@NgModule({
  declarations: [FunctionalErrorItemPipe, FunctionalErrorContainerComponent],
  imports: [CommonModule, CommonUiCommonRenderingsModule],
  exports: [FunctionalErrorItemPipe, FunctionalErrorContainerComponent],
})
export class FunctionalErrorsModule {}
