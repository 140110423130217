import { createNamedApi } from '@essent/common';

import { GetSolarPanelsAddressCheckService } from './get-solar-panels-address-check.service';

export const getSolarPanelsAddressCheckApi = createNamedApi(
  '[GetSolarPanelsAddressCheckState] Get Solar Panels Address Check',
  GetSolarPanelsAddressCheckService
);

export const {
  getSolarPanelsAddressCheckActions,
  getSolarPanelsAddressCheck,
  getSolarPanelsAddressCheckClear,
  getSolarPanelsAddressCheckError,
  getSolarPanelsAddressCheckSuccess,
} = getSolarPanelsAddressCheckApi;
