import type {
  Offer,
  OfferContentIncentive,
} from '@innogy/become-a-customer/shared/interfaces';
import { IncentiveType } from '@innogy/become-a-customer/shared/interfaces';

export function getIncentiveFromOffer(offer: Offer): OfferContentIncentive {
  const {
    incentiveTitle,
    incentiveSummary,
    incentiveFullDescription,
    incentiveImage,
    incentiveType,
    incentiveValue,
    incentiveInfoUrl,
    incentiveUsp,
  } = offer;
  return {
    incentiveTitle,
    incentiveSummary,
    incentiveFullDescription,
    incentiveImage,
    incentiveType,
    incentiveValue,
    incentiveInfoUrl,
    incentiveUsp,
  };
}

export const offerHasDiscount = (offer?: Offer) =>
  offer?.incentiveType === IncentiveType.Cashback &&
  !!offer?.incentiveValue &&
  offer.incentiveValue > 0;
