import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TextField } from '@sitecore-jss/sitecore-jss-angular';

@Component({
  selector: 'wl-error-modal-placeholder',
  templateUrl: './generic-error-modal.component.html',
  styleUrls: [],
})
export class GenericErrorModalComponent {
  @Input()
  public modalTitle?: TextField;

  @Input()
  public modalMessage?: TextField;

  constructor(private readonly modal: NgbActiveModal) {}

  dismissModal() {
    this.modal.dismiss('dismiss');
  }
}
