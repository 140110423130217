import type { OnInit } from '@angular/core';
import { Directive, ViewContainerRef, TemplateRef } from '@angular/core';
import { PlatformService } from '@core/platform';

/**
 * Only renders given element on the client, use to skip rendering during SSR
 */
@Directive({
  selector: '[wlClientOnly]',
})
export class ClientOnlyDirective implements OnInit {
  constructor(
    private readonly viewContainer: ViewContainerRef,
    private readonly templateRef: TemplateRef<any>,
    private readonly platformService: PlatformService
  ) {}

  ngOnInit() {
    if (this.platformService.isServer()) {
      this.viewContainer.clear();
    } else {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
