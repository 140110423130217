import { inject } from '@angular/core';
import { openGenericModal } from '@innogy/common-ui/modals';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, of } from 'rxjs';

import { OfferMailer } from '../services/offer-mailer.service';
import { emailOfferButtonClicked } from './offer-saver.actions';

export const emailOffer = createEffect(
  (
    /* istanbul ignore next */ actions = inject(Actions),
    /* istanbul ignore next */ mailer = inject(OfferMailer)
  ) =>
    actions.pipe(
      ofType(emailOfferButtonClicked),
      exhaustMap((action) =>
        mailer.emailOffer(action.emailAddress).pipe(
          map(() => openGenericModal(action.modalContent.offerEmailed)),
          catchError(() =>
            of(openGenericModal(action.modalContent.offerEmailFailed))
          )
        )
      )
    ),
  { functional: true }
);
