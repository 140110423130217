import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Status } from '@essent/common';
import type { FullCalculateFormValues } from '@innogy/become-a-customer/shared';
import {
  type CalculateComponentRendering,
  CalculateComponentContent,
} from '@innogy/become-a-customer/shared/interfaces';
import type { DropdownItem } from '@innogy/common-ui/forms';
import type { TypedComponentRendering } from '@core/jss-models';
import { uppercaseConverter } from '@innogy/utils-deprecated';
import { FormGroupControls, FormGroupState } from 'ngrx-forms';

@Component({
  selector: 'wl-calculate-form-address',
  templateUrl: './calculate-form-address.component.html',
  styleUrls: ['./calculate-form-address.scss'],
})
export class CalculateFormAddressComponent {
  @Input()
  rendering?: TypedComponentRendering<CalculateComponentRendering>;
  @Input({ required: true })
  content!: CalculateComponentContent;
  @Input()
  controls?: FormGroupControls<FullCalculateFormValues>;
  @Input()
  formState?: FormGroupState<FullCalculateFormValues>;
  @Input()
  houseNumberAdditions?: DropdownItem[];
  @Input()
  manualHouseNumberAdditionInput?: boolean;
  @Output()
  hideManualHouseNumberAdditionInput = new EventEmitter<void>();
  @Input()
  installationAddressStatus?: Status;
  @Input()
  installationAddressNotFound?: boolean;

  @Input()
  isAddressManualTriggerActive?: boolean;
  @Input()
  isAlternateAddressEntryEnabled?: boolean;
  @Output()
  setAddressButtonClicked = new EventEmitter<void>();

  addressNextButtonDisabled = false;

  public readonly _uppercaseConverter = uppercaseConverter;

  get showLoader() {
    return (
      this.controls?.houseNumberAddition?.isDisabled &&
      this.installationAddressStatus === Status.PENDING
    );
  }

  /**
   * Should only show the message when the message has been filled in in Sitecore (and when the API returns a 404)
   */
  get showAddressNotFoundMessage() {
    return (
      this.installationAddressNotFound &&
      !!(
        this.content?.addressNotFoundErrorTitle ||
        this.content?.addressNotFoundErrorMessage
      )
    );
  }
}
