<nav
  class="navbar navbar-light bg-white border-bottom border-gray-400 py-3 mb-3"
>
  <div class="container"></div>
</nav>
<main>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1 class="text-brand mb-3">Sorry, er gaat iets mis</h1>
        <p>
          Door een technische storing is deze pagina even niet zichtbaar. We
          werken hard aan een oplossing.
        </p>
      </div>
    </div>
  </div>
</main>
