import { Component, Input } from '@angular/core';

import { ComposableCardConfig } from './card.model';

@Component({
  selector: 'wl-composable-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.ed.scss'],
})
export class ComposableCardComponent {
  @Input() config?: ComposableCardConfig;
}
