import type {
  InsulationProductType,
  InsulationProductWithCalculations,
} from '@innogy/eplus/temporary-core-modules';
import { createSelector } from '@ngrx/store';

import { selectGetInsulationNewCustomerAddressCheckData } from '../insulation-api';
import { selectInsulationFunnelSettings } from '../insulation-funnel';

export const selectInsulationReceiptViewModel = createSelector(
  selectGetInsulationNewCustomerAddressCheckData,
  selectInsulationFunnelSettings,
  (addressCheckData, funnelSettings) => ({
    unsuitableProducts: determineUnsuitableProducts(
      addressCheckData?.products ?? [],
      addressCheckData?.suitableProductTypes ?? []
    ),
    selectedProducts: funnelSettings.selectedProducts,
    maxYearlySavings: funnelSettings.selectedProducts.reduce(
      (sum, product) => sum + product.yearlySavings,
      0
    ),
  })
);

const determineUnsuitableProducts = (
  products: InsulationProductWithCalculations[],
  installableProductTypes: InsulationProductType[]
) =>
  products.reduce(
    (result, product) =>
      installableProductTypes.includes(product.productType.value)
        ? result
        : [...result, product.productType.value],
    [] as InsulationProductType[]
  );
