import type { ScLinkField } from '@core/jss-models';
import { postGenericFormSubmitError } from '@innogy/eplus/temporary-core-modules';
import {
  createNgrxFormReducer,
  generateFormIdFromUUID,
  wrappedFormReducer,
} from '@innogy/shared/forms';
import type {
  ScForm,
  ScFormFormGroupState,
} from '@innogy/sitecore-forms/models';
import { CONTROL_METADATA_KEY } from '@innogy/sitecore-forms/models';
import { conditionalOn, onNgrxFormsControlId } from '@innogy/utils-deprecated';
import type {
  Action,
  ActionCreator,
  ActionReducer,
  ActionReducerMap,
} from '@ngrx/store';
import { combineReducers, createReducer, on } from '@ngrx/store';
import type { FormGroupState } from 'ngrx-forms';
import {
  createFormGroupState,
  ResetAction,
  setUserDefinedProperty,
  SetValueAction,
  updateGroup,
  updateRecursive,
} from 'ngrx-forms';

import { controlNameForInput, getControlsForType } from './form-values';
import { markGenericFormAsPostedAction } from './generic-form.actions';
import { localFormControlId } from './helpers/generic-form-utils';
import {
  determineSummaryValueForControl,
  enhanceControlWithMetadata,
} from './helpers/metadata-helpers';
import { getValidatorsForField } from './validators';

interface ScFormFormState {
  formState: FormGroupState<ScFormFormGroupState>;
}
interface ScFormFeedbackState {
  isPosted: boolean;
  submitError: boolean;
  redirectOnSuccessPage?: ScLinkField | undefined;
}

export interface ScFormState {
  form: ScFormFormState;
  feedback?: ScFormFeedbackState;
}

export type CreateGenericFormReducerConfig = {
  context: 'standalone' | 'embedded';
  resetOnAction?: {
    action: ActionCreator<string, (props: { payload: string }) => any>;
    payloadShouldEqual: string;
  };
};

export const sitecoreFormsKey = 'sitecore-forms';

export function createGenericFormReducerFromScForm(
  form: ScForm,
  formId = form.id,
  reducerConfig: CreateGenericFormReducerConfig = { context: 'embedded' }
) {
  const reducers: ActionReducerMap<ScFormState> = {
    form: formStateReducer(form, formId, reducerConfig),
  };

  // If feedback is allowed by the configuration, add a feedback reducer.
  if (reducerConfig.context === 'standalone') {
    (reducers.feedback as ActionReducer<ScFormFeedbackState>) = feedbackReducer(
      form,
      formId
    );
  }

  const _combinedReducers = combineReducers(reducers);

  return (state: ScFormState | undefined, action: Action): ScFormState =>
    _combinedReducers(state, action);
}

export function createInitialFormGroupStateFromScForm(
  form: ScForm,
  formId: string
) {
  const formState = form.fields.Inputs.reduce((acc, input) => {
    const type = input.fields.Type.value;
    const controls = getControlsForType(
      type,
      controlNameForInput(input.fields)
    );
    return { ...acc, ...controls };
  }, {} as ScFormFormGroupState);
  return updateRecursive(
    createFormGroupState<ScFormFormGroupState>(formId, formState),
    (control) =>
      setUserDefinedProperty(
        control,
        CONTROL_METADATA_KEY,
        enhanceControlWithMetadata(control, form)
      )
  );
}

export const getInitialScFormState = (form: ScForm, formId: string) => ({
  formState: createInitialFormGroupStateFromScForm(form, formId),
});

export function validateScForm(form: ScForm) {
  return function (state: ScFormFormState) {
    const validators = form.fields.Inputs.reduce((acc, input) => {
      const type = input.fields.Type.value;
      const controls = getValidatorsForField(type, input.fields, state);
      return { ...acc, ...controls };
    }, {});
    return updateGroup<ScFormFormGroupState>(validators)(state.formState);
  };
}

export const resetScFormState =
  (form: ScForm, formId: string) => (_state: ScFormFormState) =>
    getInitialScFormState(form, formId);

const setSummaryValue =
  (form: ScForm) =>
  (state: ScFormFormState, action: SetValueAction<unknown>) => {
    const controlId = localFormControlId(action.controlId);
    const control = state.formState.controls[controlId];
    return {
      formState: updateGroup(state.formState, {
        [controlId]: setUserDefinedProperty(CONTROL_METADATA_KEY, {
          ...control['userDefinedProperties'][CONTROL_METADATA_KEY],
          summaryValue: determineSummaryValueForControl(control, form),
        }),
      }),
    };
  };

const formStateReducer = (
  form: ScForm,
  formId: string,
  config: CreateGenericFormReducerConfig
) => {
  const initialState = getInitialScFormState(form, formId);
  const controls = initialState.formState.controls;
  const formReducer = createNgrxFormReducer(
    initialState,
    formId,
    validateScForm(form),
    onNgrxFormsControlId(ResetAction, formId, resetScFormState(form, formId)),
    onNgrxFormsControlId(
      SetValueAction,
      Object.values(controls).map((control) => control.id),
      setSummaryValue(form)
    ),
    // There is an intentional choice for an externally configurable reset
    // action as this form might have to be cleared in contexts where the calling code has
    // no notion of the ID of this form (e.g. in a complex multi-step form/funnel)
    // in that case, the 'resetOnAction' can be passed along in order to clear
    // forms in a decoupled fashion.
    conditionalOn(
      !!config.resetOnAction,
      config.resetOnAction?.action,
      resetOnActionReducer(form, formId, config.resetOnAction)
    )
  );

  return wrappedFormReducer(formReducer, validateScForm(form));
};

const feedbackReducer = (
  form: ScForm,
  formId: string
): ActionReducer<ScFormFeedbackState> =>
  createReducer(
    {
      isPosted: false,
      submitError: false,
      redirectOnSuccessPage: form.fields.RedirectOnSuccessPage.value,
    } as ScFormFeedbackState,
    on(markGenericFormAsPostedAction, (state, action) => ({
      ...state,
      isPosted: action.formId === formId,
    })),
    on(postGenericFormSubmitError, (state, action) => {
      if (!action.actionId) {
        return state;
      }

      const formId = generateFormIdFromUUID(action.actionId);

      return {
        ...state,
        submitError: formId === formId,
      };
    })
  );

const resetOnActionReducer = (
  form: ScForm,
  formId: string,
  resetOnAction: CreateGenericFormReducerConfig['resetOnAction']
) => {
  return (state: ScFormFormState, action: Action) => {
    if (!resetOnAction) {
      return state;
    }
    const { payloadShouldEqual } = resetOnAction;
    if (
      payloadShouldEqual !== undefined &&
      (action as any).payload === payloadShouldEqual
    ) {
      return resetScFormState(form, formId)(state);
    }
    return { ...state };
  };
};
