import { Input, type OnInit } from '@angular/core';
import { Component } from '@angular/core';
import type { OrderPaymentFormValues } from '@innogy/become-a-customer/state';
import { ibanConverter } from '@innogy/utils-deprecated';

import { OrderFulfillmentStepComponent } from '../order-fulfillment-step.component';
import { ORDER_PAYMENT_STEP_INDEX } from '../constants';

@Component({
  selector: 'wl-order-fulfillment-payment[progressiveFormState]',
  templateUrl: './order-fulfillment-payment.component.html',
  styleUrls: [],
})
export class OrderFulfillmentPaymentComponent
  extends OrderFulfillmentStepComponent<OrderPaymentFormValues>
  implements OnInit
{
  @Input() labelsShownAboveFields = false;
  public readonly _ibanConverter = ibanConverter;

  ngOnInit(): void {
    this.progressiveFormCardContent = {
      title: this.fields.PaymentCardTitle?.value,
      subtitle: this.fields.PaymentCardSubtitle?.value,
      submitButtonText: this.fields.PaymentCardSubmitButton?.value,
      stepNumber: ORDER_PAYMENT_STEP_INDEX,
    };
  }
}
