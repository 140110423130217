import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

@Pipe({
  name: 'stripSymbols',
})
export class StripSymbolsPipe implements PipeTransform {
  symbols = /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/g;

  transform(input: string | undefined): string {
    if (!input) {
      return '';
    }

    return input.replace(this.symbols, '');
  }
}
