<div
  class="d-lg-none"
  [class]="rendering?.fields?.retentionStickyView?.value ? 'd-none' : ''"
></div>

<div
  class="card rounded"
  [class]="
    rendering?.fields?.retentionStickyView?.value
      ? 'card__retention-view'
      : 'card__retention-view'
  "
  [style.z-index]="isCollapsed ? 'auto' : '11'"
>
  <div class="card--body d-flex flex-column">
    <ng-container *ngIf="offer; else loading">
      <essent-order-overview-header
        [offer]="offer"
        [monthlyAmountLabel]="rendering?.fields?.MonthlyAmountLabel"
        [monthlyAmountTooltip]="rendering?.fields?.MonthlyAmountTooltip?.value"
        [budgetBillLabel]="rendering?.fields?.BudgetBillSuggestion"
        [budgetBillTooltip]="
          rendering?.fields?.BudgetBillSuggestionTooltip?.value
        "
        [showEditButton]="rendering?.fields?.showEditButton?.value"
        [fluidContainer]="rendering?.fields?.retentionStickyView?.value"
        [showCostsInHeader]="showCostsInHeader"
      >
      </essent-order-overview-header>

      <div
        #collapse="ngbCollapse"
        [(ngbCollapse)]="isCollapsed"
        class="card--body--content px-2 d-lg-block flex-grow-1"
      >
        <p
          class="fw-bold mt-2"
          *scText="rendering?.fields?.SummaryCalculationBasedOn"
          wlCSCapture
        ></p>
        <div class="mb-2">
          <wl-order-overview-consumption
            *ngIf="consumptionValuesVM"
            [consumptionValues]="consumptionValuesVM"
          ></wl-order-overview-consumption>
        </div>

        <div class="mb-2">
          <wl-order-overview-cost
            [label]="rendering?.fields?.ElectricityLabel"
            [cost]="electricityOverview?.totalPrice?.expectedYearlyAmount"
          ></wl-order-overview-cost>
          <wl-order-overview-cost
            *ngIf="gasOverview?.totalPrice?.expectedYearlyAmount"
            [label]="rendering?.fields?.GasLabel"
            [cost]="gasOverview?.totalPrice?.expectedYearlyAmount"
          ></wl-order-overview-cost>
        </div>
        <wl-order-overview-budget-bill
          [beforeDiscountYearlyLabel]="
            rendering?.fields?.BeforeDiscountExpectedYearlyLabel
          "
          [beforeDiscountYearlyAmount]="
            offer.beforeDiscountExpectedYearlyAmount
          "
          [cashbackAmount]="offer.discountPrice"
          [cashbackLabel]="rendering?.fields?.IncentiveLabel"
          [yearlyLabel]="yearlyCostLabel"
          [yearlyAmount]="offer.expectedYearlyAmount"
          [monthlyAmount]="offer.expectedMonthlyAmount"
          [monthlyAmountLabel]="rendering?.fields?.MonthlyAmountLabel"
          [monthlyAmountTooltip]="
            rendering?.fields?.MonthlyAmountTooltip?.value
          "
          [budgetBillAmount]="offer.budgetBillAmount"
          [budgetBillLabel]="rendering?.fields?.BudgetBillSuggestion"
          [budgetBillTooltip]="
            rendering?.fields?.BudgetBillSuggestionTooltip?.value
          "
          [showCostsInHeader]="showCostsInHeader"
        ></wl-order-overview-budget-bill>
        <div class="mb-2"></div>

        <div
          class="rounded-3 text-center card__mgm-badge"
          *ngIf="displayMGM((hasMemberGetMember$ | async) || false)"
        >
          {{
            rendering?.fields?.MemberGetMemberSharedValue?.value || ''
              | interpolate
                : {
                    variables: {
                      mgmValue: mgmSharedValue | roundedCurrency,
                    },
                  }
          }}
        </div>
        <hr />

        <div class="d-flex mb-3 mt-3 justify-content-center">
          <button
            class="p-0 btn btn-link text-primary"
            [wlTrackLink]="rendering?.fields?.AOMModalLinkText?.value"
            [trackLinkInfo]="{
              rendering: {
                componentName: 'product detail',
              },
              field: rendering?.fields?.AOMModalLinkText?.value,
              eventType: 'link-click',
            }"
            wlAutomationId="show-tarifs"
            (click)="showTarifs.emit(offer)"
            *scText="rendering?.fields?.AOMModalLinkText"
            wlCSCapture
          ></button>
          <span
            *ngIf="!!rendering?.fields?.AOMModalTooltip?.value"
            class="d-flex align-items-center ps-1"
          >
            <wl-tooltip
              [tooltip]="{
                description: rendering?.fields?.AOMModalTooltip?.value,
              }"
            ></wl-tooltip>
          </span>
        </div>
      </div>

      <div ngbDropdown class="d-flex justify-content-center dropdown d-lg-none">
        <button
          class="btn btn-outline-primary dropdown__button shadow-sm d-flex justify-content-center align-items-center"
          id="dropdownBasic1"
          (click)="toggleCollapse()"
        >
          <fa-icon
            class="dropdown__icon d-flex"
            [icon]="isCollapsed ? 'angle-down' : 'angle-up'"
          ></fa-icon>
        </button>
      </div>
    </ng-container>
  </div>
</div>
<div
  class="fade d-lg-none"
  [class.show]="!isCollapsed"
  [class]="rendering?.fields?.retentionStickyView?.value ? 'd-none' : ''"
></div>

<ng-template #loading>
  <wl-svg-skeleton-loader [mask]="skeletonLoaderMask"> </wl-svg-skeleton-loader>
</ng-template>
