import { RenderingContractGroupType } from '@contracting/models';
import { getDroplinkValue } from '@core/jss-utils';
import type { GuardInput } from '@sitecore-jss/sitecore-jss-angular';

export function getContractGroupTypeForRendering(input: GuardInput) {
  return (
    (getDroplinkValue(
      input.rendering?.fields,
      'contractGroupType'
    ) as RenderingContractGroupType) ?? RenderingContractGroupType.COMMODITY
  );
}
