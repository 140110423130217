import { Inject, Injectable } from '@angular/core';
import { openGenericModal } from '@innogy/common-ui/modals';
import { ENVIRONMENT_CONFIG } from '@core/config-angular';
import { EnvironmentConfig } from '@core/config-models';
import type {
  SolarPanelsFunnelSettingsInterface,
  SolarPanelsFunnelStep,
} from '@innogy/eplus/models';
import type { SolarPanelsProductWithCalculations } from '@innogy/eplus/temporary-core-modules';
import { getIsExperienceEditorActive } from '@core/jss-routing';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';

import { createFunnelEffectCreators } from '../../shared/generators/funnel/funnel.effects.factory';
import {
  handleSolarPanelsFunnelAddressNotFoundErrorAction,
  handleSolarPanelsFunnelGenericErrorAction,
  handleSolarPanelsFunnelPrivateErrorAction,
  initializeSolarPanelsFunnelStepAction,
  mintSolarPanelsTrackingIdAction,
  navigateToHouseHasInsufficientRoofAreaPageAction,
  navigateToHouseIsMonumentPageAction,
  navigateToHouseIsUnsuitablePageAction,
  navigateToHouseNotFoundPageAction,
  navigateToOrderFormPageAction,
  navigateToProductOverviewPageAction,
  navigateToResultOverviewPageAction,
  navigateToSuitabilityCheckPageAction,
  onSolarPanelsFunnelNavigationAction,
  setSolarPanelsFunnelInitializedAction,
} from './solar-panels-funnel.actions';
import { selectSolarPanelsFunnelSettings } from './solar-panels-funnel.selectors';

@Injectable()
export class SolarPanelsFunnelEffects {
  funnelSettings$ = this.store$.select(selectSolarPanelsFunnelSettings);
  isXpEditorActive$ = this.store$.select(getIsExperienceEditorActive);

  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store,
    @Inject(ENVIRONMENT_CONFIG) private readonly config: EnvironmentConfig
  ) {}

  private readonly effects = createFunnelEffectCreators<
    SolarPanelsFunnelStep,
    SolarPanelsFunnelSettingsInterface,
    SolarPanelsProductWithCalculations
  >(
    this.actions$,
    this.funnelSettings$,
    this.isXpEditorActive$,
    'startPage',
    'wizard',
    this.config,
    {
      initializeFunnelStepAction: initializeSolarPanelsFunnelStepAction,
      handleFunnelPrivateErrorAction: handleSolarPanelsFunnelPrivateErrorAction,
      onFunnelNavigationAction: onSolarPanelsFunnelNavigationAction,
      setFunnelInitializedAction: setSolarPanelsFunnelInitializedAction,
      handleFunnelGenericErrorAction: handleSolarPanelsFunnelGenericErrorAction,
      mintTrackingIdAction: mintSolarPanelsTrackingIdAction,
    }
  );

  public readonly onInitSolarPanelsFunnel$ =
    this.effects.initializeFunnelStepEffectCreator();

  public readonly mintSolarPanelsFunnelTrackingId$ =
    this.effects.mintTrackingIdEffectCreator();

  public readonly onSolarPanelsFunnelGenericError$ =
    this.effects.handleFunnelGenericErrorEffectCreator();

  public readonly onSolarPanelsFunnelNavigation$ =
    this.effects.onFunnelNavigationEffectCreator();

  public readonly onSolarPanelsFunnelPrivateError$ =
    this.effects.handleFunnelPrivateErrorEffectCreator();

  // When fallout page found, go to page.
  public readonly displayAddressNotFoundPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(handleSolarPanelsFunnelAddressNotFoundErrorAction),
      concatLatestFrom(() => this.funnelSettings$),
      filter(([, { houseNotFoundPage }]) => !!houseNotFoundPage?.href),
      map(([_, { houseNotFoundPage }]) =>
        navigateToHouseNotFoundPageAction({
          pageData: houseNotFoundPage,
        })
      )
    )
  );

  // When no fallout page is found, try to open a modal
  public readonly displayAddressNotFoundErrorModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(handleSolarPanelsFunnelAddressNotFoundErrorAction),
      concatLatestFrom(() => this.funnelSettings$),
      filter(([, { houseNotFoundPage }]) => !houseNotFoundPage?.href),
      map(([_, funnelSettings]) => {
        if (!funnelSettings.addressNotFoundErrorModal) {
          return handleSolarPanelsFunnelPrivateErrorAction({
            message:
              'addressNotFoundErrorModal is not defined on FunnelSettings',
          });
        }
        return openGenericModal(funnelSettings.addressNotFoundErrorModal);
      })
    )
  );

  public readonly handlePageNavigation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        navigateToSuitabilityCheckPageAction,
        navigateToProductOverviewPageAction,
        navigateToResultOverviewPageAction,
        navigateToOrderFormPageAction,
        navigateToHouseIsMonumentPageAction,
        navigateToHouseIsUnsuitablePageAction,
        navigateToHouseNotFoundPageAction,
        navigateToHouseHasInsufficientRoofAreaPageAction
      ),
      map(({ pageData: page, type }) => {
        if (page?.href) {
          return onSolarPanelsFunnelNavigationAction({
            page,
          });
        }
        return handleSolarPanelsFunnelPrivateErrorAction({
          message: `${type} - No page found for this routing action in funnel settings.`,
        });
      })
    )
  );
}
