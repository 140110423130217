export const imagesFileTypes = [
  'image/jpeg',
  'image/png',
  'image/bmp',
  'image/tiff',
];

export const documentsFileTypes = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

export const plainTextFileTypes = ['text/plain', 'text/csv'];

export function generateFileTypeRegex(fileTypes: string[]) {
  return new RegExp(fileTypes.join('|'));
}
