import { createServiceApi, serviceTypes } from '@essent/common';
import type { PostChatTokenResponse } from '@integration/chatting-api-models';

import type { PostChatTokenRequest } from '../interfaces';

const apiVersion = '1';
export const postChatTokenApi = createServiceApi(
  '[ChattingState] Post chat token',
  'post',
  serviceTypes<PostChatTokenRequest, PostChatTokenResponse>(),
  {
    getUrl: `/chatting/chat-token/v${apiVersion}`,
    version: apiVersion,
    defaultHeaders: {
      Accept: 'application/json',
    },
    getBody: (payload) => payload,
  }
);
