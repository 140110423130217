import type { FormGroupState, KeyValue } from 'ngrx-forms';
import { createFormGroupState } from 'ngrx-forms';

export type ProgressiveFormGroupId = string;

export type ProgressiveFormStateImplementor<TValue extends KeyValue> = {
  progressiveForm: ProgressiveFormGroupState<TValue>;
};

/**
 * Base interface for all types of form steps.
 */
export interface ProgressiveFormGroupState<TValue extends KeyValue> {
  /**
   * The unique ID of the form step. Usually this is the name or index
   * of the control in the form value prefixed by the ID of the containing
   * form group, e.g. `MY_PROGRESSIVE_FORM.someFormStep` or `MY_PROGRESSIVE_FORM.0`.
   */
  readonly id: string;

  /**
   * The formState associated with this progressive form.
   */
  readonly formState: FormGroupState<TValue>;
  /**
   * A placeholder to store a backup of the step's form state.
   */
  readonly backupFormState: FormGroupState<TValue>;
  /**
   * This property indicates whether the step is currently active
   * (user is looking at this step).
   */
  readonly isActive: boolean;
  /**
   * This property indicates whether the step is currently inactive
   * (user is not looking at this step).
   */
  readonly isInactive: boolean;
  /**
   * This property indicates whether the step can currently be
   * edited.
   */
  readonly isEditable: boolean;
  /**
   * This property indicates whether the step can currently not be
   * edited.
   */
  readonly isInert: boolean;
  /**
   * This property is set to `true` as soon as the step has ever been activated.
   */
  readonly isVisited: boolean;
  /**
   * This property is set to `false` as soon as the step has ever been activated.
   */
  readonly isUnvisited: boolean;
  /**
   * This property indicates whether the step is submittable.
   * (i.e. to show or hide a submission button)
   */
  readonly isSubmittable: boolean;
  /**
   * This property indicates whether the step is unsubmittable.
   * (i.e. to show or hide a submission button)
   */
  readonly isUnsubmittable: boolean;
  /**
   * This property is set to `true` as soon as the state is submitted.
   */
  readonly isSubmitted: boolean;
  /**
   * This property is set to `false` as soon as the state is submitted.
   */
  readonly isUnsubmitted: boolean;
  /**
   * This property is set to `false` when any other progressive form is marked as restoration point.
   */
  readonly isRestorationPoint: boolean;
  /**
   * This property indicates whether there are currently any async actions pending.
   * This is used for async form submission.
   */
  readonly hasPendingAsyncActions: boolean;
}

/**
 * This function creates a form step state with an id.
 * Based on the id a default state is initialized.
 * we wrap NGRX forms's form group under the formState and backupFormState.
 */
export function createProgressiveFormGroupState<TValue extends KeyValue>(
  id: ProgressiveFormGroupId,
  initialValue: TValue
): ProgressiveFormGroupState<TValue> {
  return {
    id,
    formState: createFormGroupState<TValue>(id, initialValue),
    backupFormState: createFormGroupState<TValue>(id, initialValue),
    isActive: false,
    isInactive: true,
    isEditable: true,
    isInert: false,
    isVisited: false,
    isUnvisited: true,
    isSubmitted: false,
    isUnsubmitted: true,
    isSubmittable: true,
    isUnsubmittable: false,
    isRestorationPoint: false,
    hasPendingAsyncActions: false,
  };
}

/**
 * This function determines if a value is a progressive form state.
 */
export function isProgressiveFormState<TValue extends KeyValue>(
  state: any
): state is ProgressiveFormGroupState<TValue> {
  return (
    !!state &&
    state.hasOwnProperty('id') &&
    state.hasOwnProperty('formState') &&
    state.hasOwnProperty('backupFormState')
  );
}
