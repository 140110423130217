import { getPaymentDetailsSuccess } from '@essent/financial';
import { isIBAN, validateSequential } from '@innogy/utils-deprecated';
import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';
import type { FormGroupState } from 'ngrx-forms';
import {
  createFormGroupState,
  onNgrxForms,
  onNgrxFormsAction,
  reset,
  ResetAction,
  setValue,
  SetValueAction,
  updateGroup,
  wrapReducerWithFormStateUpdate,
} from 'ngrx-forms';
import { notEqualTo, required } from 'ngrx-forms/validation';

export const CHANGE_IBAN_FORM_ID = 'changeIbanForm';
export const ibanControlId = `${CHANGE_IBAN_FORM_ID}.iban`;

export interface FormState {
  iban: string;
}

export interface State {
  changeIbanFormState: FormGroupState<FormState>;
  initialIban: string;
}

export const initialFormState: FormState = {
  iban: '',
};

export const initialFormGroupState = createFormGroupState<FormState>(
  CHANGE_IBAN_FORM_ID,
  initialFormState
);

export const initialState: State = {
  changeIbanFormState: initialFormGroupState,
  initialIban: '',
};

export const validateAndUpdateForms = (state: State) =>
  updateGroup<FormState>({
    iban: validateSequential(required, isIBAN, notEqualTo(state.initialIban)),
  })(state.changeIbanFormState);

const _reducer = createReducer(
  initialState,
  onNgrxForms(),
  onNgrxFormsAction(ResetAction, (state, action) => {
    if (action.controlId === CHANGE_IBAN_FORM_ID) {
      const changeIbanFormState = setValue(
        state.changeIbanFormState,
        initialFormState
      );

      return {
        ...state,
        changeIbanFormState: reset(changeIbanFormState),
      };
    }
    return state;
  }),
  on(getPaymentDetailsSuccess, (state, action) => ({
    ...state,
    initialIban: action.payload.paymentDetails.iban ?? '',
  }))
);
const postProcessorReducer = (
  state: State = initialState,
  action: any
): State =>
  action.value &&
  action.controlId === ibanControlId &&
  action.type === SetValueAction.TYPE &&
  state.changeIbanFormState.errors?.['_iban']?.invalidInputChanged
    ? {
        ...state,
        changeIbanFormState: {
          ...state.changeIbanFormState,
          controls: {
            ...state.changeIbanFormState.controls,
            iban: {
              ...state.changeIbanFormState.controls.iban,
              value: action.value?.replace(/\s/g, '').toUpperCase() || '',
            },
          },
        },
      }
    : state;

const _wrappedReducer = wrapReducerWithFormStateUpdate(
  _reducer,
  (state) => state.changeIbanFormState,
  (_, state) => validateAndUpdateForms(state)
);

const wrappedReducer = (state: State = initialState, action: Action): State => {
  const updatedState = _wrappedReducer(state, action);
  return postProcessorReducer(updatedState, action);
};

export function reducer(state: State = initialState, action: Action): State {
  return wrappedReducer(state, action);
}
