import { createSelector } from '@ngrx/store';

import { getCustomerDetailsState } from './feature.selectors';

export const getChangePhoneNumberFormState = createSelector(
  getCustomerDetailsState,
  (state) => state.changePhoneNumberForm.changePhoneNumberFormState
);

export const getChangePhoneNumberTechnicalError = createSelector(
  getCustomerDetailsState,
  (state) => state.changePhoneNumberForm.technicalError
);
