<div
  class="d-flex"
  [ngClass]="!imageSettings?.aspectRatio && imageSettings?.alignment"
>
  <ng-container *ngIf="!!imageSettings?.link?.value?.href; else image">
    <a *wlGenericLink="imageSettings?.link">
      <ng-container *ngTemplateOutlet="image"></ng-container>
    </a>
  </ng-container>
</div>

<ng-template #image>
  <img
    [style.objectPosition]="imagePosition"
    [ngClass]="[ignoredBorderStyles]"
    wlAutomationId="image"
    loading="lazy"
    *scImage="imageSettings?.image"
    [alt]="imageSettings?.image?.alt"
  />
</ng-template>
