import type { ActionReducerMap, Action } from '@ngrx/store';

import type { ContractsState } from './contract.reducer';
import { contractGroupReducer } from './contract.reducer';

export const contractsSelectorKey = 'contracts';

export interface ContractState {
  readonly [contractsSelectorKey]: ContractsState;
}

export const reducers: ActionReducerMap<ContractState, Action> = {
  [contractsSelectorKey]: contractGroupReducer,
};
