import { Component, Input, type OnInit } from '@angular/core';
import { FormGroupControls } from 'ngrx-forms';
import type { FullCalculateFormValues } from '@innogy/become-a-customer/shared';
import {
  CalculateComponentContent,
  type CalculateComponentRendering,
} from '@innogy/become-a-customer/shared/interfaces';
import type { RadioItem } from '@innogy/common-ui/forms';
import type { ToolTip } from '@innogy/common-ui/utility-components';
import { TypedComponentRendering } from '@core/jss-models';

@Component({
  selector: 'wl-calculate-form-meter',
  templateUrl: './calculate-form-meter.component.html',
})
export class CalculateFormMeterComponent implements OnInit {
  @Input({ required: true }) content!: CalculateComponentContent;
  @Input() formControls?: FormGroupControls<FullCalculateFormValues>;
  @Input() rendering?: TypedComponentRendering<CalculateComponentRendering>;

  radioButtonDescriptions: RadioItem<boolean>[] = [];
  popoverDescription?: ToolTip;

  ngOnInit() {
    this.radioButtonDescriptions = [
      {
        label: this.content.smartMeterRadioButtonLabel ?? '',
        value: true,
      },
      {
        label: this.content.regularMeterRadioButtonLabel ?? '',
        value: false,
      },
    ];
    this.popoverDescription = {
      description: this.content.doubleMeterCheckboxTooltip,
    };
  }
}
