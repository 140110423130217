import { Status } from '@essent/common';
import { createExtensionDropdownList } from '@innogy/become-a-customer/shared';
import {
  getAllSalesFunnelsDisabled,
  getAllSalesFunnelsDisabledMessageLink,
  getAllSalesFunnelsDisabledMessageText,
  getAllSalesFunnelsDisabledMessageTitle,
} from '@core/settings';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { getBAGRegistrationFaulty } from '../bac/bag-registration';
import { getInstallationAddressState } from '../bac/installation-address';
import { getRenderingValue } from './calculate.reducer';
import type { CalculateState } from './calculate.state';
import { becomeACustomerCalculateSelectorKey } from './calculate.state';

export const getCalculateState = createFeatureSelector<CalculateState>(
  becomeACustomerCalculateSelectorKey
);

export const getCalculateRendering = createSelector(
  getCalculateState,
  (state) => state.calculateForm.rendering
);

export const getCalculateRenderingValue = <T>(key: string, defaultValue: T) =>
  createSelector(getCalculateFormState, getRenderingValue(key, defaultValue));

export const getCalculateFormState = createSelector(
  getCalculateState,
  (state) => state.calculateForm
);

export const getCalculateApiCallsPending = createSelector(
  getCalculateFormState,
  (state) => state.apiCallsPending
);

export const getCalculateUpdatedAfterAddressCheck = createSelector(
  getCalculateFormState,
  (state) => state.updatedAfterAddressCheck
);

export const getCalculateManualHouseNumberAddition = createSelector(
  getCalculateFormState,
  (state) => state.manualHouseNumberAddition
);

export const getCalculateFormFormState = createSelector(
  getCalculateState,
  (state) => state.calculateForm.formState
);

export const getCalculateFormIsDoubleMeter = createSelector(
  getCalculateFormFormState,
  (state) => state.value.doubleMeter
);

export const getCalculateViewModel = createSelector(
  getInstallationAddressState,
  getCalculateUpdatedAfterAddressCheck,
  getCalculateManualHouseNumberAddition,
  getCalculateApiCallsPending,
  getCalculateFormFormState,
  getBAGRegistrationFaulty,
  getAllSalesFunnelsDisabled,
  getAllSalesFunnelsDisabledMessageTitle,
  getAllSalesFunnelsDisabledMessageText,
  getAllSalesFunnelsDisabledMessageLink,
  // eslint-disable-next-line complexity
  (
    installationAddressState,
    updatedAfterAddressCheck,
    houseNumberAdditionInputEnabled,
    apiCallsPending,
    formState,
    BAGRegistrationFaulty,
    allSalesFunnelsDisabled,
    allSalesFunnelsDisabledMessageTitle,
    allSalesFunnelsDisabledMessageText,
    allSalesFunnelsDisabledMessageLink
  ) => {
    const installationAddressStatus = installationAddressState.status;
    const installationAddressNotFound =
      installationAddressState?.error?.status === 404;
    const houseNumberExtensions = createExtensionDropdownList(
      installationAddressState.data
    );
    const isSubmitButtonDisabled =
      apiCallsPending ||
      BAGRegistrationFaulty ||
      ((updatedAfterAddressCheck ||
        installationAddressStatus === Status.PENDING) &&
        formState.controls?.postalCode?.isValid &&
        !!formState.controls?.houseNumber?.isValid);
    const showSpinnerWhenSubmitButtonDisabled =
      isSubmitButtonDisabled && !BAGRegistrationFaulty && apiCallsPending;

    return {
      installationAddressStatus,
      installationAddressNotFound,
      houseNumberExtensions,
      updatedAfterAddressCheck,
      houseNumberAdditionInputEnabled,
      isSubmitButtonDisabled,
      showSpinnerWhenSubmitButtonDisabled,
      formState,
      allSalesFunnelsDisabled,
      allSalesFunnelsDisabledMessageTitle:
        allSalesFunnelsDisabledMessageTitle ?? undefined,
      allSalesFunnelsDisabledMessageText:
        allSalesFunnelsDisabledMessageText ?? undefined,
      allSalesFunnelsDisabledMessageLink:
        allSalesFunnelsDisabledMessageLink ?? undefined,
    };
  }
);
