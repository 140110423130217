<div class="ratio" [ngClass]="aspectRatio">
  <img
    [src]="imageUrl"
    class="img-fluid mx-auto d-block"
    alt="Frame uit video die afgespeeld kan worden na het toestaan van tracking cookies."
  />
  <div
    class="p-2 pos-a text-center justify-content-center align-items-center d-flex flex-column bg-white bg-opacity-75"
  >
    <p>
      Om video's te bekijken op deze website moet je het gebruik van cookies
      toestaan.
    </p>
    <a
      *wlGenericLink="
        {
          querystring: queryString,
          href: currentURL,
          text: 'Cookievoorkeuren wijzigen',
        };
        extras: { queryParamsHandling: 'merge' }
      "
    ></a>
  </div>
</div>
