import { Component, ContentChild, Input } from '@angular/core';

import { ReceiptBreakdownDirective } from './receipt-breakdown.directive';
import { ReceiptConclusionDirective } from './receipt-conclusion.directive';

@Component({
  selector: 'wl-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.ed.scss', './receipt.component.essent.scss'],
})
export class ReceiptComponent {
  @Input() title = '';
  @Input() sticky = false;
  @Input() stickyWrapperClass = '';

  @ContentChild(ReceiptBreakdownDirective)
  breakdown?: ReceiptBreakdownDirective;

  @ContentChild(ReceiptConclusionDirective)
  conclusion?: ReceiptConclusionDirective;
}
