import { InjectionToken } from '@angular/core';

export interface SimpleProgressiveFormsConfig {
  /**
   *  When enabled, will allow the form components to scroll into view when they become active.
   */
  scrollIntoViewWhenActive?: boolean;
  /**
   * When enabled, will focus on the first available form control when the form step activates.
   */
  enableAutomaticFocusing?: boolean;
  /**
   * When provided, can be used to show progression in components.
   */
  totalStepsInFlow?: number;
}

export const SIMPLE_PROGRESSIVE_FORMS_CONFIG =
  new InjectionToken<SimpleProgressiveFormsConfig>(
    'simpleProgressiveFormsConfig'
  );
