import type { AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { Directive, ElementRef, HostListener, Input } from '@angular/core';

/**
 * When the target element gets and looses focus the given class is added if
 * the element has a value, else it is removed.
 */
@Directive({
  selector: '[wlHasValueClass]',
})
export class HasValueDirective implements OnChanges, AfterViewInit {
  @Input('wlHasValueClass')
  hasValueClassName?: string;
  @Input()
  wlHasValueClassValue?: string;

  private readonly element?: HTMLInputElement;

  @HostListener('focus')
  onFocus() {
    this.updateClassOnValue(this.hasValueClassName);
  }

  @HostListener('blur')
  onBlur() {
    this.updateClassOnValue(this.hasValueClassName);
  }

  @HostListener('change')
  onChange() {
    this.updateClassOnValue(this.hasValueClassName);
  }

  constructor(el: ElementRef) {
    this.element = el.nativeElement;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.updateClassOnValue(
      this.hasValueClassName,
      changes['hasValueClassName']?.previousValue
    );
  }

  ngAfterViewInit(): void {
    this.updateClassOnValue(this.hasValueClassName);
  }

  private updateClassOnValue(valueClass?: string, oldValueClass?: string) {
    if (
      this.element == null ||
      valueClass == null ||
      valueClass.trim() === ''
    ) {
      return;
    }
    if (this.element.value) {
      this.element.classList.add(valueClass);
    } else {
      this.element.classList.remove(valueClass);
    }

    if (oldValueClass) {
      this.element.classList.remove(oldValueClass);
    }
  }
}
