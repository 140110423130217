import { Component, Input } from '@angular/core';
import { InfoCardContainerRendering } from '@innogy/common-ui/models';

@Component({
  selector: 'wl-info-card-container',
  templateUrl: './info-card-container.component.html',
  styleUrls: [],
})
export class InfoCardContainerComponent {
  @Input()
  rendering?: InfoCardContainerRendering;

  get button() {
    const { Button } = this.rendering?.fields || {};
    const { text, href } = Button?.value || {};
    return text && href ? Button : undefined;
  }
}
