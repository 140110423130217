<ng-container *ngIf="horizontal; else list">
  <div class="row">
    <ng-container *ngTemplateOutlet="list"></ng-container>
  </div>
</ng-container>

<ng-template #list>
  <div
    *ngIf="rendering?.fields?.accompanyingText?.value"
    [ngClass]="{ 'col-12 col-md-6': horizontal }"
  >
    <wl-rich-text-wrapper
      [field]="rendering?.fields?.accompanyingText"
      [rendering]="rendering"
    >
      <div></div>
    </wl-rich-text-wrapper>
  </div>
  <div [ngClass]="{ 'col-12 col-md-6': horizontal }">
    <sc-placeholder name="list-item" [rendering]="rendering"></sc-placeholder>
  </div>
</ng-template>
