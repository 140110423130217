import { Component, Input } from '@angular/core';

@Component({
  selector: 'wl-horizontal-commodity-offer-footer',
  templateUrl: './horizontal-commodity-offer-footer.component.html',
  styleUrls: [
    './horizontal-commodity-offer-footer.component.ed.scss',
    './horizontal-commodity-offer-footer.component.essent.scss',
  ],
})
export class HorizontalCommodityOfferFooterComponent {
  @Input() contactInformation?: string;
  @Input() productBenefits?: string;
}
