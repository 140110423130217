import {
  LeadFormGuard,
  ProductsGuard,
  ProspectGuard,
} from '@innogy/emobility-lead/store';
import type { ComponentNameAndModule } from '@sitecore-jss/sitecore-jss-angular';

const loadChildren = () =>
  import('./lib/emobility-lead-lazy.module').then(
    (mod) => mod.EmobilityLeadLazyModule
  );

export const emobilityLeadLazyImports: ComponentNameAndModule[] = [
  {
    path: 'EmobilityLeadForm',
    loadChildren,
    canActivate: [LeadFormGuard, ProspectGuard, ProductsGuard],
  },
  {
    path: 'EmobilityLeadFormSuccess',
    loadChildren,
  },
];
