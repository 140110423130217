import { createSelector } from '@ngrx/store';

import { getCustomerDetailsState } from '../feature.selectors';

export const getCustomerConsentState = createSelector(
  getCustomerDetailsState,
  (state) => state.customerConsent.getCustomerConsent
);

export const getCustomerConsentStatus = createSelector(
  getCustomerConsentState,
  (state) => state.status
);

export const getCustomerConsents = createSelector(
  getCustomerConsentState,
  (state) => state.data?.customerConsents
);

export const patchCustomerConsentState = createSelector(
  getCustomerDetailsState,
  (state) => state.customerConsent.patchCustomerConsent
);

export const patchCustomerConsentStatus = createSelector(
  patchCustomerConsentState,
  (state) => state.status
);
