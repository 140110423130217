import type { Actions, KeyValue } from 'ngrx-forms';
import { formGroupReducer } from 'ngrx-forms';

import type { ProgressiveFormGroupState } from '../state';

export function dispatchActionOnNGRXFormState<TValue extends KeyValue>(
  state: ProgressiveFormGroupState<TValue>,
  actionCreator: (controlId: string) => Actions<TValue>
) {
  const newState = formGroupReducer(state.formState, actionCreator(state.id));
  return newState;
}
