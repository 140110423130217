import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

import type { LinkDefinition } from './link-definition';

@Injectable({
  providedIn: 'root',
})
export class LinkService {
  head: HTMLHeadElement;

  constructor(@Inject(DOCUMENT) private readonly document: Document) {
    this.head = this.document.head;
  }

  /**
   * Create or update a link tag
   * @param  {LinkDefinition} tag
   */
  public updateTag(tag: LinkDefinition) {
    const selector = this._parseSelector(tag);
    const linkElement =
      this.head.querySelector(selector) ||
      this.head.appendChild(this.document.createElement('link'));

    Object.keys(tag).forEach((prop) => {
      (linkElement as any)[prop] = tag[prop];
    });

    return linkElement;
  }

  /**
   * Remove a link tag from DOM
   * @param  tag
   */
  public removeTag(tag: LinkDefinition): void {
    const selector = this._parseSelector(tag);
    const linkElement = this.head.querySelector(selector);

    if (linkElement) {
      this.head.removeChild(linkElement);
    }
  }

  /**
   * Get link tag
   * @param  tag
   * @return {HTMLLinkElement}
   */
  public getTag(tag: LinkDefinition) {
    const selector = this._parseSelector(tag);

    return this.head.querySelector(selector);
  }

  /**
   * Get all link tags
   * @return {NodeListOf<HTMLLinkElement>}
   */
  public getTags(): NodeListOf<HTMLLinkElement> {
    return this.head.querySelectorAll('link');
  }

  /**
   * Parse tag to create a selector
   * @param  tag
   * @return {string} selector to use in querySelector
   */
  private _parseSelector(tag: LinkDefinition): string {
    const attr: string = tag.rel ? 'rel' : 'hreflang';
    return `link[${attr}="${tag[attr]}"]`;
  }
}
