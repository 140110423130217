<article
  class="
  media-section
  media-section--{{ theme }}
  media-section--order-media-{{ mediaPosition }}
  media-section--scale-media-{{ mediaScale }}
  "
>
  <div class="media-section-area media-section-area--media">
    <div class="media-section-area--background-pattern" *ngIf="pattern">
      <wl-partial-brand-pattern [pattern]="pattern"></wl-partial-brand-pattern>
    </div>
    <div class="media-section-area--media-image">
      <ng-content select="wl-partial-image"></ng-content>
    </div>
  </div>
  <div class="media-section-area media-section-area--content">
    <ng-content></ng-content>
  </div>
</article>
