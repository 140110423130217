<div class="mb-1">
  <ng-container *ngIf="rendering?.subtitle">
    <p>{{ rendering?.subtitle }}</p>
  </ng-container>
</div>
<form
  novalidate
  [ngrxFormState]="formState"
  [wlTrackStep]="{
    rendering: { componentName: 'verbruik inschatten' },
    step: 0.1,
    stepName: 'verbruik inschatten',
  }"
>
  <div class="mb-2">
    <div class="mb-2">
      <ng-container *ngIf="rendering?.residentsOptionsLabel">
        <label for="residents">
          {{ rendering?.residentsOptionsLabel }}
        </label>
      </ng-container>
      <wl-dropdown
        [wlTrackValueChange]="{
          step: 0.1,
          rendering: { componentName: 'verbruik inschatten' },
          label: 'aantal personen',
          inputLabel: formControls.residents?.value || '',
          sendValue: true,
          trackFocus: true,
          stepName: 'verbruik inschatten',
        }"
        [dropdownItems]="rendering?.residentsOptions"
        [formsControl]="formControls.residents"
        [placeholder]="rendering?.residentsOptionsPlaceHolder"
        [floatingLabel]="false"
        icon="user"
        labelForId="residents"
        wlAutomationId="usageQuestionnaire.residents"
      ></wl-dropdown>
    </div>
    <div class="col-12" *ngIf="formControls.residents | isInvalid">
      <wl-input-error
        [messageField]="rendering?.errorMessage"
        messageDescription="aantal personen"
        [rendering]="rendering"
        stepName="verbruik inschatten"
      ></wl-input-error>
    </div>
  </div>
  <div class="mb-2">
    <div class="mb-2">
      <ng-container *ngIf="rendering?.housingTypeOptionsLabel">
        <label for="housingType">{{
          rendering?.housingTypeOptionsLabel
        }}</label>
      </ng-container>
      <wl-dropdown
        [wlTrackValueChange]="{
          step: 0.1,
          rendering: { componentName: 'verbruik inschatten' },
          label: 'type huis',
          inputLabel: formControls.houseType?.value | analyticsMap,
          sendValue: true,
          trackFocus: true,
          stepName: 'verbruik inschatten',
        }"
        [dropdownItems]="rendering?.housingTypeOptions"
        [formsControl]="formControls.houseType"
        [placeholder]="rendering?.housingTypeOptionsPlaceHolder"
        [floatingLabel]="false"
        icon="house"
        labelForId="housingType"
        wlAutomationId="usageQuestionnaire.housingType"
      ></wl-dropdown>
    </div>
    <div class="col-12" *ngIf="formControls.houseType | isInvalid">
      <wl-input-error
        [messageField]="rendering?.errorMessage"
        messageDescription="type huis"
        [rendering]="rendering"
        stepName="verbruik inschatten"
      ></wl-input-error>
    </div>
  </div>
  <div
    *ngIf="energyTypeDropdownShown"
    class="mb-3"
    wlAutomationId="energy-type-dropdown"
  >
    <div class="mb-2">
      <ng-container *ngIf="rendering?.supplyTypeOptionsLabel">
        <label for="supplyType">{{ rendering?.supplyTypeOptionsLabel }}</label>
      </ng-container>
      <wl-dropdown
        [wlTrackValueChange]="{
          step: 0.1,
          rendering: { componentName: 'verbruik inschatten' },
          label: 'type aansluiting',
          inputLabel: formControls.supplyType?.value | analyticsMap,
          sendValue: true,
          trackFocus: true,
          stepName: 'verbruik inschatten',
        }"
        [dropdownItems]="rendering?.supplyTypeOptions"
        [formsControl]="formControls.supplyType"
        [placeholder]="rendering?.supplyTypeOptionsPlaceHolder"
        [floatingLabel]="false"
        icon="plug"
        labelForId="supplyType"
        wlAutomationId="usageQuestionnaire.supplyType"
      ></wl-dropdown>
    </div>
    <div class="col-12" *ngIf="formControls.supplyType | isInvalid">
      <wl-input-error
        [messageField]="rendering?.errorMessage"
        messageDescription="type aansluiting"
        [rendering]="rendering"
        stepName="verbruik inschatten"
      ></wl-input-error>
    </div>
  </div>
  <input
    type="submit"
    class="btn btn-primary text-center-ed w-100-ed"
    wlAutomationId="usageQuestionnaire.submit"
    [value]="rendering?.submitLabel"
  />
</form>
