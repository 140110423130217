import type { ActionReducerMap } from '@ngrx/store';
import { createFeatureSelector } from '@ngrx/store';

import type { DynamicPricesDataState } from './dynamic-prices/reducers/dynamic-prices.reducers';
import { dynamicPricesReducer } from './dynamic-prices/reducers/dynamic-prices.reducers';
import type { DynamicPricesAvailabilityState } from './dynamic-prices/reducers/dynamic-prices-availability.reducers';
import { dynamicPricesAvailabilityReducer } from './dynamic-prices/reducers/dynamic-prices-availability.reducers';

export const tariffsSelectorKey = 'tariffs';

export interface TariffsState {
  dynamicPrices: DynamicPricesDataState;
  dynamicPricesAvailability: DynamicPricesAvailabilityState;
}

export const reducers: ActionReducerMap<TariffsState> = {
  dynamicPrices: dynamicPricesReducer,
  dynamicPricesAvailability: dynamicPricesAvailabilityReducer,
};

export const selectTariffsState =
  createFeatureSelector<TariffsState>(tariffsSelectorKey);
