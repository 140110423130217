<wl-experience-editor>
  <ng-template wlLive>
    <a *ngIf="rendering?.fields?.Name" id="{{ rendering?.fields?.Name?.value }}"
      >&nbsp;</a
    >
  </ng-template>
  <ng-template wlPreview>
    <div class="card wlAnchor">
      <div class="card-body my-2 bg-light">
        <fa-icon icon="anchor"></fa-icon>
        <span class="ps-2" *scText="rendering?.fields?.Name"></span>
      </div>
    </div>
  </ng-template>
</wl-experience-editor>
