export interface ICommerce {
  productNaam: string;
  productID: string;
  productType: ProductType | string;
  productCategorie: ProductCategorie | string;
  productLijst?: string;
  productPositie?: string;
  productAantal: string;
  prijs: string;
}

export interface CommerceElectricityGas extends ICommerce {
  productType: ProductType.Energy_Gas | ProductType.Energy | ProductType.Gas;
  productCategorie: ProductCategorie.Acquisitie | ProductCategorie.Retentie;
  prijsNetto: string;
  prijsMaand: string;
  prijsNettoMaand: string;
  termijnBedrag: string;
  productKorting: string;
  productNPV: string;
  incentiveNaam: string;
  incentiveID: string;
  incentiveType: IncentiveType;
  contractAantal: string;
  contractLooptijd: string;
  contractType: ContractType;
  // This property effectively holds the segment (low, high, etc.) converted to a number started with 1 in ascending order of value.
  // It is obfuscated because the segment is considered a somewhat sensitive information.
  usageType: string;
  campaignID: string;
}

export interface CommerceSolar extends ICommerce {
  productType: ProductType.Solar;
  productCategorie:
    | ProductCategorie.Zonnepanelen
    | ProductCategorie.Zonneboiler;
  besparing: string;
  productGarantie: string;
  opbrengst: string;
  vermogen: string;
  vermogensGarantie: string;
}

export interface CommerceSmart extends ICommerce {
  productType: ProductType.Smart;
  productCategorie:
    | ProductCategorie.Thermostaat
    | ProductCategorie.Verlichting
    | ProductCategorie.Rookmelder
    | ProductCategorie.Beveiliging;
}

export interface CommerceHeating extends ICommerce {
  productType: ProductType.Heating;
  productCategorie: ProductCategorie.None;
}

export interface CommerceStore extends ICommerce {
  productType: string;
  productCategorie: string;
}

export interface CommerceInsulation extends ICommerce {
  productType: ProductType.Insulation;
  productCategorie:
    | ProductCategorie.Vloerisolatie
    | ProductCategorie.Muurisolatie
    | ProductCategorie.Dakisolatie;
}

export interface CommerceService extends ICommerce {
  productType: ProductType.Service;
  productCategorie:
    | ProductCategorie.Administratie
    | ProductCategorie.Cvketel_Onderhoud
    | ProductCategorie.Zonnepanelen_Onderhoud
    | ProductCategorie.Warmtepomp_Onderhoud
    | ProductCategorie.Installatie;
}

export type Commerce =
  | CommerceElectricityGas
  | CommerceSolar
  | CommerceSmart
  | CommerceHeating
  | CommerceInsulation
  | CommerceService;

export enum ProductType {
  Energy_Gas = 'stroom en gas',
  Energy = 'Stroom',
  Gas = 'Gas',
  Solar = 'Solar',
  Smart = 'Smart',
  Heating = 'Heating',
  Insulation = 'Insulation',
  Service = 'Service',
  Unknown = 'Unknown',
}

export enum ProductCategorie {
  Retentie = 'retentie',
  Acquisitie = 'acquisitie',
  Zonnepanelen = 'zonnepanelen',
  Zonneboiler = 'zonneboiler',
  Thermostaat = 'thermostaat',
  Verlichting = 'verlichting',
  Rookmelder = 'rookmelder',
  Beveiliging = 'beveiliging',
  Vloerisolatie = 'vloerisolatie',
  Muurisolatie = 'muurisolatie',
  Dakisolatie = 'dakisolatie',
  Administratie = 'administratie',
  Cvketel_Onderhoud = 'cvketel onderhoud',
  Zonnepanelen_Onderhoud = 'zonnepanelen onderhoud',
  Warmtepomp_Onderhoud = 'warmtepomp onderhoud',
  Installatie = 'installatie',
  None = '',
  Unknown = 'Unknown',
}

export enum IncentiveType {
  Cashback = 'cashback',
  Cadeaubon = 'cadeaubon',
  Cadeau = 'cadeau',
  None = '',
}

export enum ContractType {
  vast = 'vast',
  flexibel = 'flexibel',
  dynamic = 'dynamisch',
}

export enum PowerType {
  DEFAULT = 'DEFAULT',
  GROEN = 'GRO',
  GRIJS = 'GRI',
}

export enum TariffType {
  VARIABEL = 'VAR',
  VAST = 'VAS',
}

export enum DurationType {
  ONE = '12',
  TWO = '24',
  THREE = '36',
  FIVE = '60',
  FLEX = 'F',
  DYNAMIC = 'D',
}

export enum CustomerType {
  Klant = 'klant',
  Prospect = 'prospect',
}

export enum ElectricityConsumptionType {
  Enkel = 'enkel',
  Dubbel = 'dubbel',
}

export type ProductEventType =
  | 'product-impression'
  | 'product-detail'
  | 'product-click';
