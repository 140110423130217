import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { LocationService } from '@core/jss-seo';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import {
  triggerChatbotAction,
  triggerChatbotErrorAction,
} from './chatbot.actions';
import { getFunnelSettings } from '../funnel';

@Injectable()
export class ChatbotEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store,
    private readonly locationService: LocationService,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}

  private readonly CONFIG = {
    chatbotTriggerSelector: 'iframe[title="Kennisgevingslijst iAdvize chat"]',
    chatbotTriggerDesktop: '#notificationApp div[role="button"]',
    chatbotTriggerMobile: 'button[type="button"]',
    minimizedChatboxSelector: 'iframe[name="iAdvize chatbox reduced"]',
    minimizedChatboxButtonSelector: 'button[class^="ReducedButton"]',
  };

  funnelSettings$ = this.store$.select(getFunnelSettings);

  public readonly onTriggerChatbot$ = createEffect(() =>
    this.actions$.pipe(
      ofType(triggerChatbotAction),
      switchMap(({ fallbackUrl }) => {
        const chatbotTrigger = this.document.querySelector(
          this.CONFIG.chatbotTriggerSelector
        );
        const minimizedChatbox = this.document.querySelector(
          this.CONFIG.minimizedChatboxSelector
        );

        // We log errors to the console here, so we can pick up on them via
        // our error log tooling.
        if (chatbotTrigger) {
          try {
            const desktopTrigger = (
              chatbotTrigger as any
            ).contentDocument.querySelector(this.CONFIG.chatbotTriggerDesktop);
            const mobileTrigger = (
              chatbotTrigger as any
            ).contentDocument.querySelector(this.CONFIG.chatbotTriggerMobile);
            const trigger = desktopTrigger || mobileTrigger;
            trigger.click();
          } catch (error) {
            console.error('Unable to click the iAdvize toggle', error);
            return [triggerChatbotErrorAction({ fallbackUrl })];
          }
        } else if (minimizedChatbox) {
          try {
            (minimizedChatbox as any).contentDocument
              .querySelector(this.CONFIG.minimizedChatboxButtonSelector)
              .click();
          } catch (error) {
            console.error('Unable to maximize the iAdvize chatbox', error);
            return [triggerChatbotErrorAction({ fallbackUrl })];
          }
        } else {
          console.error(
            'No clickable iAdvize element can be found on the current page'
          );
          return [triggerChatbotErrorAction({ fallbackUrl })];
        }
        return EMPTY;
      })
    )
  );

  public readonly onTriggerChatbotError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(triggerChatbotErrorAction),
        tap(({ fallbackUrl }) =>
          this.locationService.navigateScLink(fallbackUrl, '/404')
        )
      ),
    { dispatch: false }
  );
}
