import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AnalyticsActionTypes, TrackToolService } from '@innogy/core/analytics';
import { tap, withLatestFrom, take } from 'rxjs/operators';

import { trackStep } from './how-to-questionnaire.actions';
import { getFlattenedChoices } from './how-to-questionnaire.selectors';

@Injectable()
export class HowToQuestionnaireEffects {
  private readonly choices$ = this.store$.select(getFlattenedChoices);

  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<any>,
    private readonly trackToolService: TrackToolService
  ) {}

  public trackStepEvent$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(trackStep),
        withLatestFrom(this.choices$),
        tap(([{ trackingValues }, choices]) => {
          const extraProperties =
            trackingValues?.type === AnalyticsActionTypes.LAST_STEP
              ? { result: choices }
              : undefined;
          this.trackToolService
            .trackTool(trackingValues, extraProperties)
            .pipe(take(1))
            .subscribe();
        })
      ),
    { dispatch: false }
  );
}
