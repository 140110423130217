import type { State as SharedState } from '@innogy/utils-state';
import type { ActionReducerMap } from '@ngrx/store';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import type { Setting, SettingsObject, SettingsValue } from '../settings.model';
import type { SettingsStateActionsUnion } from './settings.actions';
import * as fromSettings from './state.reducer';

export interface StoreSettingsState {
  readonly settings: fromSettings.State;
}

export interface State extends SharedState {
  readonly settingsState: StoreSettingsState;
}

export const reducers: ActionReducerMap<
  StoreSettingsState,
  SettingsStateActionsUnion
> = {
  settings: fromSettings.settingsStateReducer,
};

export const settingsSelectorKey = 'settings';
export const getSettingsFeature =
  createFeatureSelector<StoreSettingsState>(settingsSelectorKey);

export const getState = createSelector(
  getSettingsFeature,
  (feature) => feature.settings
);

export const getSettings = createSelector(getState, fromSettings.getSettings);

export const getSettingsCategory = <T extends SettingsObject>(
  category: string
) =>
  createSelector(getSettings, (settings) => {
    return settings == null ? null : (settings[category] as T);
  });

export const getSettingsObject = <T extends Setting | string | string[]>(
  category: string,
  key: string
) =>
  createSelector(getSettingsCategory(category), (cat: any) => {
    return cat == null ? null : (cat[key] as T);
  });

export const getSettingsValue = <T extends SettingsValue>(
  category: string,
  key: string
) =>
  createSelector(getSettingsObject<Setting>(category, key), (setting) => {
    return setting == null ? null : (setting.value as T);
  });

export const getSettingsLabel = <T extends SettingsValue>(
  category: string,
  key: string
) =>
  createSelector(getSettingsObject<Setting>(category, key), (setting) => {
    return setting == null ? null : (setting.label as T);
  });

export const getFeatureFlag = <T extends SettingsValue>(
  category: string,
  key: string
) =>
  createSelector(getSettingsObject<Setting>(category, key), (setting) => {
    return setting == null ? null : (setting.value as T);
  });

export const getStatus = createSelector(getState, fromSettings.getStatus);

export const getErrors = createSelector(getState, fromSettings.getErrors);
