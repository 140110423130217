<div class="sticker-header__header">
  <div
    class="sticker-header__header-content"
    [class.sticker-header--sticker-spacing]="!!sticker?.src"
  >
    <p class="h4 h3-md m-0" *ngIf="!!title">{{ title }}</p>
    <p *ngIf="!!subtitle" class="sticker-header-subtitle m-0">
      {{ subtitle }}
    </p>
  </div>
  <div class="sticker-header__sticker" *ngIf="!!sticker?.src">
    <img
      [src]="sticker?.src"
      [alt]="sticker?.alt"
      class="sticker-header__sticker-img"
    />
  </div>
</div>
