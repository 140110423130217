import { Component, inject, Input } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CONSENT_PARAM } from '@core/jss-seo';

@Component({
  selector: 'wl-video-fallback',
  templateUrl: './video-fallback.component.html',
})
export class VideoFallbackComponent {
  @Input({ required: true }) aspectRatio!: string;
  @Input() imageUrl?: SafeResourceUrl;

  readonly #activatedRoute = inject(ActivatedRoute);

  get currentURL() {
    return this.#activatedRoute.snapshot.url.join('/');
  }

  get queryString() {
    const params = new URLSearchParams();
    params.set(CONSENT_PARAM, 'true');
    return params.toString();
  }
}
