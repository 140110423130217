<wl-simple-progressive-form-card
  [progressiveFormState]="progressiveFormState"
  [content]="progressiveFormCardContent"
  [asyncSubmission]="true"
  [trackingConfig]="{
    toolName: rendering.componentName,
    step: stepNumber,
    stepName: 'controleren',
  }"
>
  <ng-template #content>
    <div class="mb-4">
      <span
        *wlGenericRichText="rendering?.fields?.confirmationSummaryBefore"
      ></span>
      <wl-tooltip
        *ngIf="rendering?.fields?.confirmationSummaryTooltip?.value"
        class="pe-1"
        [tooltip]="{
          description: rendering?.fields?.confirmationSummaryTooltip?.value,
        }"
      ></wl-tooltip>
      <span
        *wlGenericRichText="rendering?.fields?.confirmationSummaryAfter"
      ></span>
    </div>
    <div
      class="row g-1"
      *ngIf="
        !fields?.TermsAndConditionsV2?.value ||
        fields?.EnableActionMailCheckbox?.value ||
        fields?.EnableInformationMailCheckbox?.value
      "
    >
      <div class="mb-2 col-12">
        <div class="mb-2" *ngIf="!fields?.TermsAndConditionsV2?.value">
          <ng-template
            [ngTemplateOutlet]="termsAndConditionsCheckbox"
          ></ng-template>
        </div>

        <!-- ED/Essent ONLY -->
        <div *ngIf="fields?.TermsAndConditionsV2?.value">
          <p
            *scText="fields?.ActionInformationMailFormGroupLabel"
            class="fw-bold"
          ></p>
        </div>

        <!-- via e-mail -->
        <div
          *ngIf="
            rendering?.fields?.EnableActionMailCheckbox?.value ||
            rendering?.fields?.EnableInformationMailCheckbox?.value
          "
        >
          <p *scText="fields?.EmailConsentLabel" class="fw-bold"></p>
        </div>
        <div
          class="mb-2"
          *ngIf="rendering?.fields?.EnableActionMailCheckbox?.value"
        >
          <wl-checkbox
            [rendering]="rendering"
            stepName="controleren"
            [step]="stepNumber"
            fieldName="email aanbiedingen"
            [formsControl]="formState?.controls?.actionMailAccepted"
          >
            {{ fields?.ActionMail?.value }}
          </wl-checkbox>
        </div>
        <wl-checkbox
          *ngIf="rendering?.fields?.EnableInformationMailCheckbox?.value"
          [rendering]="rendering"
          stepName="controleren"
          [step]="stepNumber"
          fieldName="email nieuwsbrief"
          [formsControl]="formState?.controls?.informationMailAccepted"
        >
          {{ fields?.InformationMail?.value }}
        </wl-checkbox>

        <!-- via telephone -->
        <div
          *ngIf="
            rendering?.fields?.EnableEnergyAdvicePhoneCheckbox?.value ||
            rendering?.fields?.EnableSustainabilityAdvicePhoneCheckbox?.value
          "
        >
          <p *scText="fields?.PhoneConsentLabel" class="fw-bold mt-2"></p>
          <div
            class="mb-2"
            *ngIf="
              rendering?.fields?.EnableEnergyAdvicePhoneCheckbox?.value &&
              rendering?.fields?.EnergyAdvicePhone?.value
            "
          >
            <wl-checkbox
              [formsControl]="formState?.controls?.energyAdvicePhoneAccepted"
              [label]="fields?.EnergyAdvicePhone?.value"
              [rendering]="rendering"
              [showValidation]="false"
              [tooltip]="fields?.EnergyAdvicePhoneTooltip?.value ?? undefined"
              fieldName="phone energy advice"
              stepName="controleren"
              wlAutomationId="energy-advice-phone-checkbox"
            />
          </div>
          <div
            *ngIf="
              rendering?.fields?.EnableSustainabilityAdvicePhoneCheckbox
                ?.value && fields?.SustainabilityAdvicePhone?.value
            "
          >
            <wl-checkbox
              [formsControl]="
                formState?.controls?.sustainabilityAdvicePhoneAccepted
              "
              [label]="fields?.SustainabilityAdvicePhone?.value"
              [rendering]="rendering"
              [showValidation]="false"
              [tooltip]="
                rendering?.fields?.SustainabilityAdvicePhoneTooltip?.value ??
                undefined
              "
              fieldName="phone sustainabiltiy advice"
              stepName="controleren"
              wlAutomationId="sustainabiltiy-advice-phone-checkbox"
            />
          </div>
        </div>
        <!-- /ED/Essent ONLY -->
      </div>
    </div>
    <div class="mb-4">
      <div
        class="d-inline me-1"
        *wlGenericRichText="rendering?.fields?.commercialSummary"
      ></div>
      <wl-tooltip
        [tooltip]="{
          description: rendering?.fields?.commercialTooltip?.value,
        }"
      ></wl-tooltip>
    </div>
    <ng-container *ngIf="fields?.TermsAndConditionsV2?.value">
      <div class="terms-and-conditions-v2-container p-2 rounded">
        <p
          *scText="fields?.TermsAndConditionsFormGroupLabel"
          class="fw-bold"
        ></p>
        <ng-template
          [ngTemplateOutlet]="termsAndConditionsCheckbox"
        ></ng-template>
      </div>
    </ng-container>
  </ng-template>
</wl-simple-progressive-form-card>

<ng-template #termsAndConditionsCheckbox>
  <wl-checkbox
    [rendering]="rendering"
    stepName="controleren"
    [step]="stepNumber"
    fieldName="voorwaarden geaccepteerd"
    class="d-block"
    [class.mb-2]="formState?.controls?.conditionsAccepted | isValid"
    [formsControl]="formState?.controls?.conditionsAccepted"
  >
    <span
      wlAutomationId="orderFulfillment-confirm-checkbox-label"
      *scText="fields?.AcceptConditionsTextBefore"
    ></span>
    <span *ngFor="let disclaimer of disclaimers; index as i">
      <ng-container *ngIf="disclaimer.href && disclaimer.key?.value">
        <a
          [href]="disclaimer.href"
          [wlAutomationId]="disclaimer.automationId"
          target="_blank"
          *scText="disclaimer.key"
        ></a>
        <span *ngIf="i + 1 <= disclaimers.length - 1">, </span>
      </ng-container>
    </span>
    {{ 'And' | translate }}
    <button
      *ngIf="offer"
      (click)="openAOMModalClick($event)"
      class="tariffs-button btn btn-link p-0"
    >
      {{ 'Tarifs' | translate }}
    </button>
    <span *scText="fields?.AcceptConditionsTextAfter"></span>
  </wl-checkbox>
  <ng-container *ngIf="formState?.controls?.conditionsAccepted | isInvalid">
    <div class="ms-6 mb-2">
      <wl-input-error
        wlAutomationId="conditions-accepted-required-error"
        [messageField]="fields?.AcceptConditionsRequiredError"
        messageDescription="voorwaarden geaccepteerd"
        [rendering]="rendering"
        stepName="controleren"
        [step]="stepNumber"
      ></wl-input-error>
    </div>
  </ng-container>
</ng-template>
