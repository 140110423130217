import type { OnInit } from '@angular/core';
import { Component, HostBinding, Input } from '@angular/core';
import { InnogyComponentRendering } from '@core/jss-models';
import { getFieldValue } from '@core/jss-utils';

@Component({
  selector: 'wl-icon-list',
  templateUrl: './icon-list.component.html',
  styleUrls: [],
})
export class IconListComponent implements OnInit {
  @Input() rendering?: InnogyComponentRendering;
  @HostBinding('class') hostClasses?: string;

  horizontal = false;

  ngOnInit(): void {
    if (this.rendering && this.rendering.fields) {
      this.horizontal = getFieldValue(this.rendering, 'horizontal', false);

      if (this.horizontal) {
        this.hostClasses = 'd-flex';
      }
    }
  }
}
