import { Component, Input } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'wl-partial-icon-label',
  templateUrl: './partial-icon-label.component.html',
  styleUrls: [
    './partial-icon-label.component.ed.scss',
    './partial-icon-label.component.essent.scss',
  ],
})
export class PartialIconLabelComponent {
  @Input() icon?: IconProp;
  @Input() colored?: boolean = false;
  @Input() label?: string;
  @Input() fixedWidth?: boolean = true;
}
