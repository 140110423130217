import { Injectable } from '@angular/core';
import {
  getConsumptionError,
  getSupplyAddressError,
  putPropositionError,
} from '@essent/new-customer';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap } from 'rxjs/operators';

import { handleBacApiErrorAction } from '../bac/0.errors';
import { getOffersError } from '../get-offer/get-offers.actions';

@Injectable()
export class OfferErrorEffects {
  constructor(private readonly actions$: Actions) {}

  /**
   * Handle error handeling of API calls.
   * Notifies user if API returns an error.
   */
  public readonly emitBacApiErrorOnOfferError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        putPropositionError,
        getConsumptionError,
        getSupplyAddressError,
        getOffersError
      ),
      mergeMap((action) => [
        handleBacApiErrorAction({
          message: action.payload.message,
          stepName: 'Offer',
        }),
      ])
    )
  );
}
