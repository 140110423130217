import type { ActionReducerMap } from '@ngrx/store';
import { createFeatureSelector } from '@ngrx/store';

import type { QuotationReactionState } from './quotation-reaction/quotation-reaction.reducer';
import { quotationReactionReducer } from './quotation-reaction/quotation-reaction.reducer';

export const contractingSelectorKey = 'contracting';
export const quotationReactionSelectorKey = 'quotationReaction';

export interface ContractingReducerState {
  [quotationReactionSelectorKey]: QuotationReactionState;
}

export const contractingReducer: ActionReducerMap<ContractingReducerState> = {
  [quotationReactionSelectorKey]: quotationReactionReducer,
};

export const getContractingState =
  createFeatureSelector<ContractingReducerState>(contractingSelectorKey);
