import type { Offer } from '@innogy/become-a-customer/shared/interfaces';
import { openGenericLightbox } from '@innogy/common-ui/modals';
import type { Store } from '@ngrx/store';

export const openMoreInfo = (
  store$: Store,
  offer: Offer,
  ctaAction: () => void
) =>
  store$.dispatch(
    openGenericLightbox({
      title: offer.moreInfoTitle || offer.productTitle,
      url: offer.moreInfoUrl,
      tracking: { componentName: 'meer info lightbox' },
      cta: {
        text: offer.moreInfoCtaButtonText,
        onClick: ctaAction,
      },
    })
  );
