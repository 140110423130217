import type { KeyValue } from 'ngrx-forms';

import type { NGRXProgressiveFormsActions } from '../actions';
import { RestoreFormStateAction } from '../actions';
import type { ProgressiveFormGroupState } from '../state';

export function restoreFormStateReducer<TValue extends KeyValue>(
  state: ProgressiveFormGroupState<TValue>,
  action: NGRXProgressiveFormsActions
): ProgressiveFormGroupState<TValue> {
  if (action.type !== RestoreFormStateAction.TYPE) {
    return state;
  }

  if (action.stepId !== state.id) {
    return state;
  }

  return {
    ...state,
    formState: state.backupFormState,
  };
}
