import { Inject, Injectable } from '@angular/core';
import { BAGIntendedUse, getBAGRegistrationSuccess } from '@essent/contract';
import { GenericModalSources } from '@innogy/common-ui/shared/interfaces';
import { ENVIRONMENT_CONFIG } from '@core/config-angular';
import { ClientEnvironmentConfig } from '@core/config-models';
import { LocationService } from '@core/jss-seo';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, mergeMap, tap } from 'rxjs/operators';

import { openModalFromFunnelSettingsAction } from '../../funnel';
import {
  markBAGRegistrationAsFaultyAction,
  redirectToAlternateFunnelAction,
} from './bag-registration.actions';
import { getBAGRegistrationData } from './bag-registration.selectors';

@Injectable()
export class BAGRegistrationEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store,
    @Inject(ENVIRONMENT_CONFIG)
    private readonly environmentConfig: ClientEnvironmentConfig,
    private readonly location: LocationService
  ) {}

  BAGRegistration$ = this.store$.select(getBAGRegistrationData);

  /**
   * Show a modal informing the SME funnel user that the BAG check returned
   * a residential function on their address.
   */
  public readonly showResidentialModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getBAGRegistrationSuccess),
      filter(() => this.environmentConfig.segment === 'zakelijk'),
      filter((action) => {
        const intendedUses = action.payload.intendedUse.split(',');
        return intendedUses.includes(BAGIntendedUse.RESIDENTIAL_FUNCTION);
      }),
      mergeMap(() => [
        markBAGRegistrationAsFaultyAction(),
        openModalFromFunnelSettingsAction({
          source: GenericModalSources.INTENDED_USAGE_MISMATCH,
        }),
      ])
    )
  );

  /**
   * 2. Redirects
   */
  public readonly redirectToAlternateFunnel$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(redirectToAlternateFunnelAction),
        tap(({ redirectUrl }) => this.location.navigateScLink(redirectUrl, '/'))
      ),
    { dispatch: false }
  );
}
