import { CommonModule } from '@angular/common';
import { Component, Input, inject } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonUiSharedModule } from '@innogy/common-ui/shared';
import { AutomationIdDirective } from '@innogy/utils-automation-id';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';

import {
  OfferSaverModalContent,
  type EmailOfferModalContent,
} from '../../offer-saver.model';
import { emailOfferButtonClicked } from '../../state/offer-saver.actions';

@Component({
  standalone: true,
  templateUrl: 'email-offer-modal.component.html',
  imports: [
    AutomationIdDirective,
    CommonModule,
    CommonUiSharedModule,
    ReactiveFormsModule,
  ],
})
export class EmailOfferModalComponent {
  @Input() offerSaverModalContent?: OfferSaverModalContent;

  activeModal = inject(NgbActiveModal);
  formBuilder = inject(FormBuilder);
  store = inject(Store);

  form = this.formBuilder.group({
    emailAddress: ['', [Validators.required, Validators.email]],
  });

  get emailAddressControl() {
    return this.form.controls.emailAddress;
  }

  get modalContent(): EmailOfferModalContent | undefined {
    return this.offerSaverModalContent?.emailOffer;
  }

  dismiss(): void {
    this.activeModal.dismiss();
  }

  emailOffer(): void {
    if (!this.offerSaverModalContent) {
      return;
    }

    if (this.form.valid) {
      this.activeModal.close();
      // The validity of the form implies that an email address was given.
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const emailAddress = this.emailAddressControl.value!;
      this.store.dispatch(
        emailOfferButtonClicked({
          emailAddress,
          modalContent: {
            offerEmailed: this.offerSaverModalContent.offerEmailed,
            offerEmailFailed: this.offerSaverModalContent.offerEmailFailed,
          },
        })
      );
    } else {
      this.form.markAllAsTouched();
    }
  }
}
