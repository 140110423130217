import type { ActionReducerMap } from '@ngrx/store';
import { createFeatureSelector } from '@ngrx/store';

import type { InsulationAPIState } from './insulation-api/insulation-api.reducer';
import {
  insulationApiKey,
  insulationAPIReducer,
} from './insulation-api/insulation-api.reducer';
import type { InsulationFunnelState } from './insulation-funnel/insulation-funnel.reducer';
import {
  insulationFunnelKey,
  insulationFunnelReducer,
} from './insulation-funnel/insulation-funnel.reducer';
import type { InsulationOrderFormState } from './insulation-order-form/insulation-order-form.reducer';
import {
  insulationOrderFormKey,
  insulationOrderFormReducer,
} from './insulation-order-form/insulation-order-form.reducer';
import type { InsulationProductOverviewState } from './insulation-products/insulation-product-overview.reducer';
import {
  insulationProductOverviewKey,
  insulationProductOverviewReducer,
} from './insulation-products/insulation-product-overview.reducer';
import type { InsulationWizardState } from './insulation-wizard/insulation-wizard.reducer';
import {
  insulationWizardFormId,
  insulationWizardReducer,
} from './insulation-wizard/insulation-wizard.reducer';

export const insulationSelectorKey = 'eplus-insulation';

export interface InsulationReducerState {
  readonly [insulationFunnelKey]: InsulationFunnelState;
  readonly [insulationWizardFormId]: InsulationWizardState;
  readonly [insulationProductOverviewKey]: InsulationProductOverviewState;
  readonly [insulationOrderFormKey]: InsulationOrderFormState;
  readonly [insulationApiKey]: InsulationAPIState;
}

export const insulationReducers: ActionReducerMap<InsulationReducerState> = {
  [insulationFunnelKey]: insulationFunnelReducer,
  [insulationWizardFormId]: insulationWizardReducer,
  [insulationProductOverviewKey]: insulationProductOverviewReducer,
  [insulationOrderFormKey]: insulationOrderFormReducer,
  [insulationApiKey]: insulationAPIReducer,
};

export const eplusInsulationFeature =
  createFeatureSelector<InsulationReducerState>(insulationSelectorKey);
