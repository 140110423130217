import { createAction, props } from '@ngrx/store';

const CHANNEL_RECOGNITION_PREFIX = '[channel-recognition]';

export const performChannelRecognitionAction = createAction(
  `${CHANNEL_RECOGNITION_PREFIX} Perform channel recognition`
);

export const channelRecognitionDoneAction = createAction(
  `${CHANNEL_RECOGNITION_PREFIX} Channel recognition done`,
  props<{ partnerId: string | undefined; mgmSaleId: string | undefined }>()
);

export const noDefaultPartnerIdAvailableErrorAction = createAction(
  `${CHANNEL_RECOGNITION_PREFIX} No default partnerId available`
);
