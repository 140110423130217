import type { Params } from '@angular/router';
import type { LayoutServiceData } from '@sitecore-jss/sitecore-jss-angular';
import { RestLayoutService } from '@sitecore-jss/sitecore-jss-angular';
import type { IncomingMessage, ServerResponse } from 'http';
import { fetchData } from '@sitecore-jss/sitecore-jss';
import type { RestLayoutServiceConfig } from '@sitecore-jss/sitecore-jss/layout';

/**
 * We have a custom implementation of "fetchLayoutData" from "RestLayoutService" because we need to be able
 * to send extra queryParameters to the layout-render-endpoint (/sitecore/api/layout/render). This is needed
 * because we have some custom logic in the "Sitecoreplus/Foundation.Sitecore" repo that handles pagination of
 * blog-articles based on a page & pagesize queryParam on this endpoint.
 *
 * see: https://gitlab.essent.nl/Sitecoreplus/Foundation.Sitecore/-/commit/a607f929f657b79e40714597343534da72cb181a
 */
export class CustomRestLayoutService extends RestLayoutService {
  constructor(private readonly serviceConfig2: RestLayoutServiceConfig) {
    super(serviceConfig2);
  }

  /**
   * This is basically all the code from the original `fetchLayoutData` function in the `RestLayoutService` with one
   * addition, extraQueryParams can be provided and will be passed to `fetchData`.
   */
  override fetchLayoutData(
    itemPath: string,
    language?: string,
    req?: IncomingMessage,
    res?: ServerResponse,
    extraQueryParams: Params = {}
  ): Promise<LayoutServiceData> {
    const querystringParams = this.getFetchParams(language);

    const fetcher = this.serviceConfig2.dataFetcherResolver
      ? this.serviceConfig2.dataFetcherResolver<LayoutServiceData>(req, res)
      : this.getDefaultFetcher<LayoutServiceData>(req, res);

    const fetchUrl = this.resolveLayoutServiceUrl('render');

    return fetchData<LayoutServiceData>(fetchUrl, fetcher, {
      item: itemPath,
      ...querystringParams,
      ...extraQueryParams,
    }).catch((error) => {
      if (error.response?.status === 404) {
        return error.response.data;
      }

      throw error;
    });
  }
}
