import type { RenderingContractGroupType } from '@contracting/models';
import { createAction, props } from '@ngrx/store';

/* eslint-disable max-classes-per-file */

export enum UpdatePaymentMethodActionTypes {
  GET_WITH_SUPPLY_ADDRESS = '[Update payment method] get with supply address',
  GET_WITHOUT_SUPPLY_ADDRESS = '[Update payment method] get without supply address',
  PATCH_WITHOUT_SUPPLY_ADDRESS = '[Update payment method] patch without supply address',
}

export const getPaymentDetailsForSelectedContractGroup = createAction(
  UpdatePaymentMethodActionTypes.GET_WITH_SUPPLY_ADDRESS,
  props<{ contractGroupType: RenderingContractGroupType }>()
);

export const getContractGroupsAndPaymentDetails = createAction(
  UpdatePaymentMethodActionTypes.GET_WITHOUT_SUPPLY_ADDRESS
);

export const patchPaymentDetailsForSelectedContractGroup = createAction(
  UpdatePaymentMethodActionTypes.PATCH_WITHOUT_SUPPLY_ADDRESS,
  props<{ contractGroupType: RenderingContractGroupType }>()
);
