import { createNamedApi } from '@essent/common';
import { PostCreateLeadInsulationService } from './post-create-lead-insulation.service';

export const postCreateLeadInsulationApi = createNamedApi(
  '[PostLeadsInsulation] Post Create Lead Insulation',
  PostCreateLeadInsulationService
);

export const {
  postCreateLeadInsulation,
  postCreateLeadInsulationActions,
  postCreateLeadInsulationClear,
  postCreateLeadInsulationError,
  postCreateLeadInsulationSuccess,
} = postCreateLeadInsulationApi;
