import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AddClassToBodyDirective } from './toggle-body-class-directive/add-class-to-body.directive';
import { DisableBodyScrollingClassDirective } from './toggle-body-class-directive/disable-body-scrolling-class.directive';
import { WINDOW_PROVIDERS } from './window-service/window.service';

@NgModule({
  imports: [CommonModule],
  providers: [WINDOW_PROVIDERS],
  declarations: [AddClassToBodyDirective, DisableBodyScrollingClassDirective],
  exports: [AddClassToBodyDirective, DisableBodyScrollingClassDirective],
})
export class UtilsDomModule {}
