import { createSelector } from '@ngrx/store';

import { eplusSolarPanelsFeature } from '../solar-panels-form.state';
import { solarPanelsFunnelKey } from './solar-panels-funnel.reducer';

export const selectSolarPanelsFunnelSettings = createSelector(
  eplusSolarPanelsFeature,
  (state) => state[solarPanelsFunnelKey]
);
export const selectSelectedSolarPanelsProduct = createSelector(
  selectSolarPanelsFunnelSettings,
  ({ selectedProduct }) => selectedProduct
);
export const selectSolarPanelsTrackingId = createSelector(
  selectSolarPanelsFunnelSettings,
  ({ trackingId }) => trackingId
);
