<div class="border rounded mb-2">
  <wl-rich-text-wrapper
    [field]="rendering?.fields?.contentUp"
    [rendering]="rendering"
  >
    <div class="p-2"></div>
  </wl-rich-text-wrapper>
  <img *scImage="rendering?.fields?.image" class="img-fluid" />
  <div class="p-2">
    <wl-rich-text-wrapper
      [field]="rendering?.fields?.content"
      [rendering]="rendering"
    >
      <div></div>
    </wl-rich-text-wrapper>
    <a
      *wlGenericLink="rendering?.fields?.cta"
      class="btn btn-outline-primary mb-2"
    ></a>
    <a *wlGenericLink="rendering?.fields?.link" class="d-block"></a>
  </div>
</div>
