import { HttpClient } from '@angular/common/http';
import { inject, Injectable, Injector } from '@angular/core';
import { EMPTY, firstValueFrom } from 'rxjs';
import type { JWTPayload } from 'jose';
import { subMilliseconds } from 'date-fns';

import { ENVIRONMENT_CONFIG } from './injection-tokens';

@Injectable({
  providedIn: 'root',
})
export class TokenRefreshService {
  readonly #injector = inject(Injector);
  readonly #httpClient = inject(HttpClient);
  #timeoutId?: ReturnType<typeof setTimeout>;

  refreshToken() {
    const environmentConfig = this.#injector.get(ENVIRONMENT_CONFIG);
    const refreshEndpoint =
      environmentConfig?.services?.identityProvider?.refresh;

    if (!refreshEndpoint) {
      return EMPTY;
    }

    return this.#httpClient.get<Pick<JWTPayload, 'exp'>>(refreshEndpoint, {});
  }

  scheduleAutomaticTokenRefresh(expiryTimeInSeconds: number) {
    const ONE_MINUTE = 60_000;
    const currentTime = new Date().getTime();
    const tokenExpiryTime = expiryTimeInSeconds * 1000;
    const tokenExpiresIn = subMilliseconds(
      tokenExpiryTime,
      currentTime
    ).getTime();
    const thirtySecondsBeforeExpiryTime = subMilliseconds(
      tokenExpiresIn,
      ONE_MINUTE / 2
    ).getTime();

    if (tokenExpiresIn < ONE_MINUTE) {
      // This should never happen, but just in case it does, we will log the
      // error and prevent a self-inflicted DDOS attack on the /refresh endpoint
      console.error(
        '[configLoaderServiceFactory] The token was refreshed, but the new token will expire in less than 1 minute (or is already expired)!'
      );
      return;
    }

    // We set a timeout to refresh this token 30 seconds before it expires.
    clearTimeout(this.#timeoutId);
    this.#timeoutId = setTimeout(async () => {
      const payload = await firstValueFrom(this.refreshToken());
      if (payload.exp) {
        this.scheduleAutomaticTokenRefresh(payload.exp);
      }
    }, thirtySecondsBeforeExpiryTime);
  }
}
