import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AdobeLaunchService {
  private readonly launchLoadedSubject$ = new BehaviorSubject<boolean>(false);
  public readonly launchLoaded$ = this.launchLoadedSubject$.asObservable();

  launchLoaded() {
    this.launchLoadedSubject$.next(true);
  }
}
