<div
  [ngClass]="{
    'mb-4': content?.subheading?.text?.value || content?.text?.text?.value,
    'mb-2': !content?.subheading?.text?.value && !content?.text?.text?.value,
  }"
  class="d-flex flex-column gap-2"
>
  <div
    *ngIf="showMarketingHeader; else defaultHeader"
    class="marketing-heading mb-lg-1"
  >
    <div class="marketing-heading__heading">
      <wl-basic-content-partial-heading
        [heading]="content?.heading"
        [centerText]="content?.centerText"
        size="h1"
      ></wl-basic-content-partial-heading>
    </div>
    <ng-container *ngIf="content?.subheading?.text?.value">
      <div class="marketing-heading__sub-heading">
        <div class="marketing-heading__sub-heading-background">
          <p
            class="m-0"
            [ngClass]="content?.subheading?.style"
            *scText="content?.subheading?.text"
          ></p>
        </div>
      </div>
    </ng-container>
  </div>

  <wl-timer-widget-wrapper *ngIf="timerWidgetShown" [rendering]="rendering" />

  <ng-container *ngIf="content?.text?.text?.value">
    <wl-rich-text-wrapper [field]="content?.text?.text">
      <div [ngClass]="content?.text?.style"></div>
    </wl-rich-text-wrapper>
  </ng-container>
</div>

<div *ngIf="hasButtons" class="d-flex flex-wrap" [ngClass]="buttonsAlignment">
  <a
    *wlGenericLink="content?.buttons?.primary?.link"
    [ngClass]="['mb-2', content?.buttons?.primary?.style]"
    [class.me-2]="
      !isInSmallCol &&
      !content.buttons.alignVertically &&
      content?.buttons?.secondary?.link?.href
    "
    wlTrackImpression="button-impression"
    trackImpressionMode="button"
    [trackImpressionName]="content?.buttons?.primary?.link?.text"
    [trackImpressionLinkurl]="content?.buttons?.primary?.link?.href"
    [trackImpressionInfo]="{
      componentName: 'jumbotron',
    }"
    wlTrackLink
    [trackLinkInfo]="{
      eventType: 'button-click',
      rendering: {
        componentName: { value: 'jumbotron' },
      },
      field: content?.buttons?.primary?.link?.text,
    }"
  ></a>
  <a
    *wlGenericLink="content?.buttons?.secondary?.link"
    [ngClass]="['mb-2', content?.buttons?.secondary?.style]"
    wlTrackImpression="button-impression"
    trackImpressionMode="button"
    [trackImpressionName]="content?.buttons?.secondary?.link?.text"
    [trackImpressionLinkurl]="content?.buttons?.secondary?.link?.href"
    [trackImpressionInfo]="{
      componentName: 'jumbotron',
    }"
    wlTrackLink
    [trackLinkInfo]="{
      eventType: 'button-click',
      rendering: {
        componentName: { value: 'jumbotron' },
      },
      field: content?.buttons?.secondary?.link?.text,
    }"
  ></a>
</div>
<ng-container *ngIf="content?.microcopy?.text?.value">
  <p
    *scText="content?.microcopy?.text"
    [ngClass]="[content?.microcopy?.style, 'mb-0', 'mt-2']"
    [class.text-center]="content?.centerText"
  ></p>
</ng-container>

<ng-template #defaultHeader>
  <wl-basic-content-partial-heading
    class="d-block mb-1"
    [heading]="content?.heading"
    [size]="headingSize"
  ></wl-basic-content-partial-heading>
  <ng-container *ngIf="content?.subheading?.text?.value">
    <p
      *scText="content?.subheading?.text"
      [ngClass]="[content?.subheading?.style, subheadingSize]"
    ></p>
  </ng-container>
</ng-template>
