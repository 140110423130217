import type { KeyValue } from 'ngrx-forms';

import type { ProgressiveFormGroupState } from '../state';
import { progressiveFormStateReducer } from '../reducer';
import { ActivateFormStepAction } from '../actions';

export function activateFormStep<TValue extends KeyValue>(
  state: ProgressiveFormGroupState<TValue>
) {
  return progressiveFormStateReducer(
    state,
    new ActivateFormStepAction(state.id)
  );
}
