import { Component, EventEmitter, Input, Output } from '@angular/core';
import type { ProductDuration } from '@essent/new-customer';
import type { GetOfferDuration } from '@innogy/become-a-customer/shared/interfaces';

@Component({
  selector: 'wl-horizontal-offer-duration-filter[durations]',
  templateUrl: './horizontal-offer-duration-filter.component.html',
  styleUrls: [
    './horizontal-offer-duration-filter.component.essent.scss',
    './horizontal-offer-duration-filter.component.ed.scss',
  ],
})
export class HorizontalOfferDurationFilterComponent {
  @Input()
  durations!: GetOfferDuration[];
  @Input()
  activeDuration?: ProductDuration | null;
  @Output()
  changeDurationClick = new EventEmitter<ProductDuration>();

  trackByDuration(_index: number, item: GetOfferDuration): string {
    return item.duration;
  }
}
