import type { HttpErrorResponse } from '@angular/common/http';
import { Status } from '@essent/common';
import { patchPaymentDetailsActions } from '@essent/financial';
import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';

export interface State {
  status: Status;
  error?: HttpErrorResponse;
}

export const initialState: State = {
  status: Status.IDLE,
};

const reducer = createReducer(
  initialState,
  on(patchPaymentDetailsActions.requestAction, (_) => ({
    status: Status.PENDING,
  })),
  on(patchPaymentDetailsActions.successAction, (_) => ({
    status: Status.SUCCESS,
  })),
  on(patchPaymentDetailsActions.errorAction, (_, action) => ({
    status: Status.ERROR,
    error: action.payload,
  })),
  on(patchPaymentDetailsActions.clearAction, () => initialState)
);

export function updatePaymentMethodReducer(
  state = initialState,
  action: Action
): State {
  return reducer(state, action);
}
