import type { FormAddress, NameFormValues } from '@innogy/common-ui/forms';
import type {
  ScFormInput,
  ScFormInputType,
} from '@innogy/sitecore-forms/models';
import camelCase from 'lodash/camelCase';
import { box } from 'ngrx-forms';

export const controlNameForInput = (input: ScFormInput) =>
  `${input.Type.value}_${camelCase(input.Label.value)}`;

// eslint-disable-next-line complexity
export function getControlsForType(
  type: ScFormInputType['value'],
  controlName: string
) {
  switch (type) {
    case 'address':
      return initialAddressFormValues;
    case 'text':
    case 'email':
    case 'payment':
    case 'phone':
    case 'radio':
      return controlNameAsEmptyString(controlName);
    case 'checkbox':
      return initialCheckboxValues(controlName);
    case 'name':
      return initialNameFormValues;
    case 'dropdown':
      return controlNameAsUndefined(controlName);
    case 'checkboxSet':
      return controlNameAsEmptyArray(controlName);
    default:
      return { [controlName]: '' };
  }
}

export const initialAddressFormValues: FormAddress = {
  addressType: 'address',
  postalCode: '',
  communicationNumber: NaN,
  city: '',
  houseNumberAddition: '',
  street: '',
  countryCode: 'NL',
};

export const controlNameAsEmptyString = (controlName: string) => ({
  [controlName]: '',
});

export const initialCheckboxValues = (controlName: string) => ({
  [controlName]: false,
});

export const controlNameAsUndefined = (controlName: string) => ({
  [controlName]: undefined,
});

export const controlNameAsEmptyArray = (controlName: string) => ({
  [controlName]: box([]),
});

export const initialNameFormValues: NameFormValues = {
  initials: '',
  lastName: '',
  insertion: undefined,
};
