import { NgModule } from '@angular/core';
import { DefaultComponentModule } from '@innogy/core/modules/components';

import { MarketingHeadingComponent } from './marketing-heading/marketing-heading.component';

@NgModule({
  imports: [DefaultComponentModule],
  declarations: [MarketingHeadingComponent],
  exports: [MarketingHeadingComponent],
})
export class MarketingHeadingModule {}
