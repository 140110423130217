<wl-modal
  (closeModal)="close()"
  [modalTitle]="title"
  [autoCloseTime]="autoCloseTime"
  wlAutomationId="generic-modal"
  wlTrackImpression="contentblock-impression"
  trackImpressionMode="any"
  [trackImpressionInfo]="{
    name: title,
    type: 'popup',
  }"
>
  <ng-template wlModalBody>
    <div>
      <div class="mb-3">
        <wl-rich-text-wrapper
          [field]="{
            value:
              body
              | interpolate
                : {
                    mode: 'rich_text',
                    variables: interpolatablePayload,
                  },
          }"
        >
          <div></div>
        </wl-rich-text-wrapper>
      </div>
      <div
        class="w-100 d-flex justify-content-center"
        *ngIf="primaryButtonText"
      >
        <button
          wlAutomationId="modal.primaryButton"
          wlTrackLink
          [trackLinkInfo]="{
            rendering: { componentName: tracking?.componentName || 'unknown' },
            field: primaryButtonText,
            eventType: buttonClickEventType(primaryButtonStyle),
          }"
          wlTrackImpression="button-impression"
          trackImpressionMode="button"
          [trackImpressionName]="primaryButtonText"
          (click)="primary()"
          [ngClass]="primaryButtonClasses"
          class="w-100"
        >
          {{ primaryButtonText }}
        </button>
      </div>
      <div
        class="w-100 d-flex justify-content-center mt-2"
        *ngIf="secondaryButtonText"
      >
        <button
          wlTrackLink
          wlAutomationId="modal.secondaryButton"
          [trackLinkInfo]="{
            rendering: { componentName: tracking?.componentName || 'unknown' },
            field: secondaryButtonText,
            eventType: buttonClickEventType(secondaryButtonStyle),
          }"
          wlTrackImpression="button-impression"
          trackImpressionMode="button"
          [trackImpressionName]="secondaryButtonText"
          (click)="secondary()"
          [ngClass]="secondaryButtonStyle"
          class="w-100"
        >
          {{ secondaryButtonText }}
        </button>
      </div>
    </div>
  </ng-template>
</wl-modal>
