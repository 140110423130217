import type { InnogyLayoutServiceData } from '@core/jss-models';
import type { LayoutServiceData } from '@sitecore-jss/sitecore-jss-angular';

import { transformLayoutModel } from './jss.model.transform';

export class JssState {
  language?: string;
  serverRoute?: string;
  previousServerRoute?: string;
  routeFetchError?: boolean;
  sitecore?: InnogyLayoutServiceData;
  viewBag: any;

  static fromResult(
    result: LayoutServiceData | unknown,
    route: string,
    language: string,
    currentRoute?: string | null
  ) {
    const state = new JssState();
    state.language = language;
    state.serverRoute = route;

    if (currentRoute) {
      state.previousServerRoute = currentRoute;
    }

    if (this.isLayoutServiceData(result)) {
      state.sitecore = result.sitecore as InnogyLayoutServiceData;
      transformLayoutModel(state.sitecore);
    } else {
      state.routeFetchError = true;
    }

    return state;
  }

  private static isLayoutServiceData(data: unknown): data is LayoutServiceData {
    return typeof data === 'object' && data != null && 'sitecore' in data;
  }
}
