import { Component, Input } from '@angular/core';
import {
  type RichTextField,
  type TextField,
} from '@sitecore-jss/sitecore-jss-angular';

@Component({
  selector: 'essent-order-overview-budget-bill-cost',
  templateUrl: './order-overview-budget-bill-cost.component.html',
  styleUrls: [],
})
export class OrderOverviewBudgetBillCostComponent {
  @Input()
  monthlyAmountLabel!: TextField;
  @Input()
  monthlyAmountTooltip = '';
  @Input()
  monthlyAmount? = 0;

  @Input()
  budgetBillLabel!: RichTextField;
  @Input()
  budgetBillTooltip = '';
  @Input()
  budgetBillAmount? = 0;
}
