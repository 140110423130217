<div class="row my-1 mx-4">
  <button
    wlTrackImpression="button-impression"
    trackImpressionMode="button"
    [trackImpressionInfo]="{
      componentName: 'AomCtaButton',
      field: fields?.AomCtaButtonText?.value,
    }"
    [trackImpressionName]="fields?.AomCtaButtonText?.value"
    [trackImpressionLinkurl]="orderPageUrl"
    wlTrackLink
    [trackLinkInfo]="{
      rendering: { componentName: 'AomCtaButton' },
      field: fields?.AomCtaButtonText?.value,
      eventType: 'button-click',
      linkurl: orderPageUrl,
    }"
    wlAutomationId="aom.submitButton"
    class="btn btn-sales-primary col-12 col-md-auto"
    (click)="submitSelectedProposition()"
    *scText="fields?.AomCtaButtonText"
  ></button>
</div>
