import type { Connection, Contract, ContractAddress } from '@essent/address';
import { ContractStatus, ProductDuration } from '@essent/address';
import { ProductType } from '@essent/common';
import type { ComponentRendering } from '@sitecore-jss/sitecore-jss-angular';

const createFactory =
  <T>(model: T) =>
  (partial: Partial<T> = {}): T => ({
    ...model,
    ...partial,
  });

export const createContract = createFactory<Contract>({
  contractGroupId: '1',
  contractId: '1',
  durationInYears: ProductDuration.THREE,
  endDate: '2019-10-16',
  commodityProductDescription: '3 Jaar Gas Vast',
  startDate: '2019-10-16',
  renewableStartDate: '2019-10-16',
  status: ContractStatus.ACTIVE,
});

export const createConnection = createFactory<Connection>({
  energyType: ProductType.GAS,
  connectEan: 'adsfads',
  contract: [createContract()],
});

export const createContractAddress = createFactory<ContractAddress>({
  supplyAddressId: '1',
  connections: {
    connection: [createConnection()],
  },
});

export const createRendering = createFactory<ComponentRendering>({
  componentName: 'Contract',
  fields: {
    title: {
      value: 'Stroom & gas contracten',
    },
    tariffsLink: {
      value: {
        href: '/tarieven',
        text: 'Mijn tarieven bekijken',
      },
    },
    durationTitle: { value: 'Looptijd: ' },
    endDateTitle: { value: 'Einddatum: ' },
    renewableStartDateTitle: { value: 'Verlengbaar vanaf: ' },
    electricityTitle: { value: 'Stroom' },
    gasTitle: { value: 'Gas' },
    detailsLink: {
      value: {
        href: '/details',
        text: 'Details',
      },
    },
    multipleConnectionsInformation: {
      value: 'Informatie over meerdere contracten',
    },
    multipleAddressesLink: {
      value: {
        href: '/all-contracts',
        text: 'All contracts',
      },
    },
    error: {
      value: 'Something went wrong',
    },
    noContracts: {
      value: 'U heeft geen actieve contracten bij Essent',
    },
    OnboardingMessage: {
      value: 'Onboarding message',
    },
    showOnboarding: {
      value: true,
    },
  },
});
