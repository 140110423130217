<div *ngIf="offer" class="d-flex flex-column">
  <essent-commodity-offer-product-breakdown-row
    *ngIf="
      selectCommodity(offer, energyType.ELECTRICITY) as electricityOverview
    "
    [description]="content?.electricityLabel"
    [value]="electricityOverview.totalPrice.expectedYearlyAmount"
  ></essent-commodity-offer-product-breakdown-row>
  <essent-commodity-offer-product-breakdown-row
    *ngIf="selectCommodity(offer, energyType.GAS) as gasOverview"
    [description]="content?.gasLabel"
    [value]="gasOverview.totalPrice.expectedYearlyAmount"
  ></essent-commodity-offer-product-breakdown-row>
  <essent-commodity-offer-product-breakdown-row
    *ngIf="offer.discountPrice"
    [description]="content?.cashbackLabel"
    rowStyle="discount"
    [prefix]="'-'"
    [value]="offer.discountPrice"
  ></essent-commodity-offer-product-breakdown-row>
  <essent-commodity-offer-product-breakdown-row
    *ngIf="offer.expectedYearlyAmount"
    [description]="yearlyCostsLabel(offer)"
    textStyle="bold"
    [value]="offer.expectedYearlyAmount"
  ></essent-commodity-offer-product-breakdown-row>
  <div
    class="d-flex justify-content-center mt-2"
    *ngIf="showMemberGetMember && content?.memberGetMemberSharedValue as value"
  >
    <div class="referral-sticker px-2">
      <span class="referral-sticker__text">
        {{
          value | interpolate: { variables: { mgmValue: getMGMValue(offer) } }
        }}
      </span>
    </div>
  </div>
  <p class="mt-2" *ngIf="content?.offerSubmitDescriptionLabel">
    <span class="description">{{ content?.offerSubmitDescriptionLabel }}</span>
    <wl-tooltip
      *ngIf="!!content?.offerSubmitDescriptionTooltip"
      [tooltip]="{ description: content?.offerSubmitDescriptionTooltip }"
    ></wl-tooltip>
  </p>
</div>
