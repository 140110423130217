import type { AsyncState } from '@essent/common';
import {
  createAsyncStateReducer,
  createInitialAsyncState,
} from '@essent/common';
import type { OrganisationDetails } from '@essent/new-customer';
import {
  getOrganisationDetails,
  getOrganisationDetailsClear,
  getOrganisationDetailsError,
  getOrganisationDetailsSuccess,
  putOrganisationDetails,
  putOrganisationDetailsClear,
  putOrganisationDetailsError,
  putOrganisationDetailsSuccess,
} from '@essent/new-customer';
import type { Action, ActionReducerMap } from '@ngrx/store';
import { combineReducers } from '@ngrx/store';

export type PutOrganisationDetailsState = AsyncState<void>;
export type GetOrganisationDetailsState = AsyncState<OrganisationDetails>;

export interface OrganisationDetailsState {
  putOrganisationDetails: PutOrganisationDetailsState;
  getOrganisationDetails: GetOrganisationDetailsState;
}

const initialState: OrganisationDetailsState = {
  putOrganisationDetails: createInitialAsyncState(),
  getOrganisationDetails: createInitialAsyncState(),
};

const _putOrganisationDetailsReducer = createAsyncStateReducer(
  putOrganisationDetails,
  putOrganisationDetailsSuccess,
  putOrganisationDetailsError,
  putOrganisationDetailsClear
);

const _getOrganisationDetailsReducer = createAsyncStateReducer(
  getOrganisationDetails,
  getOrganisationDetailsSuccess,
  getOrganisationDetailsError,
  getOrganisationDetailsClear
);

const _reducers: ActionReducerMap<OrganisationDetailsState, Action> = {
  putOrganisationDetails: _putOrganisationDetailsReducer,
  getOrganisationDetails: _getOrganisationDetailsReducer,
};

const _combinedReducers = combineReducers(_reducers);

export function organisationDetailsReducer(
  state: OrganisationDetailsState = initialState,
  action: Action
): OrganisationDetailsState {
  return _combinedReducers(state, action);
}
