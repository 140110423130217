import { Component, Input } from '@angular/core';

@Component({
  selector: 'wl-offer-breakdown-row[value]',
  templateUrl: './offer-breakdown-row.component.html',
  styleUrls: [
    './offer-breakdown-row.component.ed.scss',
    './offer-breakdown-row.component.essent.scss',
  ],
})
export class OfferBreakdownRowComponent {
  @Input()
  public description?: string;
  @Input()
  public value!: number;
  @Input()
  public prefix = '';
  @Input()
  public rowStyle: 'regular' | 'discount' = 'regular';

  public get backgroundClass(): string {
    return this.rowStyle === 'discount'
      ? 'offer-breakdown-cell--discount'
      : 'bg-light';
  }
}
