<wl-composable-card [config]="cardConfig">
  <div
    class="row h-100 m-lg-0"
    *ngIf="hasItemAlignedLeftOrRight; else basicContent"
  >
    <div
      [class]="
        'mb-2 mb-lg-0 col-12 order-1 ps-lg-0 col-lg-' + settings?.itemSize
      "
      *ngIf="settings?.itemAlignment === 'left'"
    >
      <ng-template *ngTemplateOutlet="img"></ng-template>
    </div>
    <div
      [class]="'col-12 order-2 col-lg-' + (12 - settings?.itemSize)"
      [class.pe-lg-0]="settings?.itemAlignment === 'left'"
      [class.ps-lg-0]="settings?.itemAlignment === 'right'"
      [class.px-lg-0]="hasBackgroundOrBorderStyle"
    >
      <ng-template *ngTemplateOutlet="basicContent"></ng-template>
    </div>
    <div
      [class]="
        'mb-2 mb-lg-0 col-12 order-1 order-lg-3 pe-lg-0 col-lg-' +
        settings?.itemSize
      "
      *ngIf="settings?.itemAlignment === 'right'"
    >
      <ng-template *ngTemplateOutlet="img"></ng-template>
    </div>
  </div>
</wl-composable-card>

<ng-template #img>
  <img
    #imageRef
    class="object-fit-cover w-100"
    [style.objectPosition]="image.position || 'center center'"
    *scImage="image.image"
  />
</ng-template>

<ng-template #basicContent>
  <wl-basic-content-partial-top-between
    [settings]="settings"
    [content]="content"
    [context]="context"
    class="h-100"
  >
    <wl-image
      [imageSettings]="image"
      [ignoreBorderStyles]="'bottom'"
    ></wl-image>
  </wl-basic-content-partial-top-between>
</ng-template>
