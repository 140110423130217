import { Component, Input } from '@angular/core';

import {
  ContentAlignment,
  ContentMediaScale,
  ContentPattern,
  ContentTheme,
} from '../../models';

@Component({
  selector: 'wl-partial-media-section',
  templateUrl: './media-section.component.html',
  styleUrls: ['./media-section.component.ed.scss'],
})
export class MediaSectionComponent {
  // @NOTE: when we refactor the BCC brand wide,
  // consider changing alignment options to start and end?
  @Input() mediaPosition: ContentAlignment = 'left';
  @Input() mediaScale: ContentMediaScale = 'md';
  @Input() theme: ContentTheme = 'default';
  @Input() pattern?: ContentPattern;
}
