import { Component, Input } from '@angular/core';

import { ListVariantBaseComponent } from '../list-variant.base.component';
import { MinimalSubVariant } from './list-variant-minimal.model';

@Component({
  selector: 'wl-list-variant-minimal',
  templateUrl: './list-variant-minimal.component.html',
  styleUrls: [
    './list-variant-minimal.component.ed.scss',
    './list-variant-minimal.component.essent.scss',
  ],
})
export class ListVariantMinimalComponent extends ListVariantBaseComponent {
  @Input() subVariant: MinimalSubVariant = 'brand';
}
