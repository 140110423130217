import type { ModuleWithProviders } from '@angular/core';
import { NgModule } from '@angular/core';
import type { NgProgressOptions } from 'ngx-progressbar';
import { NgProgressbar, provideNgProgressOptions } from 'ngx-progressbar';
import { NgProgressRouter } from 'ngx-progressbar/router';

@NgModule({
  imports: [NgProgressbar, NgProgressRouter],
  exports: [NgProgressbar, NgProgressRouter],
})
export class CoreLoaderModule {
  static withConfig(
    config: NgProgressOptions
  ): ModuleWithProviders<CoreLoaderModule> {
    return {
      ngModule: CoreLoaderModule,
      providers: [provideNgProgressOptions(config)],
    };
  }
}
