import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';
import type { ProgressiveFormGroupState } from '@innogy/progressive-ngrx-forms';
import type { KeyValue } from 'ngrx-forms';

@Pipe({
  name: 'showProgressiveFormsSummary',
})
export class ShowProgressiveFormsSummaryPipe<TValue extends KeyValue>
  implements PipeTransform
{
  transform(state: ProgressiveFormGroupState<TValue>) {
    return state.isInert || (state.isInactive && state.isSubmitted);
  }
}
