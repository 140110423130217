import { Component, Input } from '@angular/core';

@Component({
  selector: 'wl-commodity-usp-list',
  templateUrl: './commodity-usp-list.component.html',
  styleUrls: [],
})
export class CommodityOfferUspListComponent {
  @Input({ required: true }) usps!: string[];
}
