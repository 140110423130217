<button
  class="btn btn-link text-primary p-0"
  wlAutomationId="offer-trigger-aom-button"
  wlTrackLink
  [trackLinkInfo]="{
    rendering: { componentName: 'contractdetail' },
    field: 'toon prijs samenstelling',
    eventType: 'link-click',
  }"
  *scText="rendering?.fields?.TarrifModalLinkText"
  (click)="showTariffs.emit()"
></button>
