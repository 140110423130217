import { patchCommunicationAddressError } from '@essent/communication-address';
import { getCorrespondenceDetailsSuccess } from '@essent/customer';
import { isPhoneNumber, validateSequential } from '@innogy/utils-deprecated';
import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';
import type { FormGroupState } from 'ngrx-forms';
import {
  createFormGroupState,
  markAsUnsubmitted,
  onNgrxForms,
  onNgrxFormsAction,
  reset,
  ResetAction,
  setValue,
  updateGroup,
  wrapReducerWithFormStateUpdate,
} from 'ngrx-forms';
import { notEqualTo, required } from 'ngrx-forms/validation';

export const CHANGE_PHONE_NUMBER_FORM_ID = 'changePhoneNumberForm';
export const phoneNumberControlId = `${CHANGE_PHONE_NUMBER_FORM_ID}.phoneNumber`;

export interface FormStateChangePhoneNumber {
  phoneNumber: string;
}

export interface State {
  changePhoneNumberFormState: FormGroupState<FormStateChangePhoneNumber>;
  initialPhoneNumber: string;
  technicalError: boolean;
}

export const initialFormState: FormStateChangePhoneNumber = {
  phoneNumber: '',
};

export const initialFormGroupState =
  createFormGroupState<FormStateChangePhoneNumber>(
    CHANGE_PHONE_NUMBER_FORM_ID,
    initialFormState
  );

export const initialState: State = {
  changePhoneNumberFormState: initialFormGroupState,
  initialPhoneNumber: '',
  technicalError: false,
};

export const validateAndUpdateForms = (state: State) => {
  return updateGroup<FormStateChangePhoneNumber>({
    phoneNumber: validateSequential(
      required,
      isPhoneNumber,
      notEqualTo(state.initialPhoneNumber)
    ),
  })(state.changePhoneNumberFormState);
};

export const _reducer = createReducer(
  initialState,
  onNgrxForms(),
  onNgrxFormsAction(ResetAction, (state, action) => {
    if (action.controlId === CHANGE_PHONE_NUMBER_FORM_ID) {
      const changePhoneNumberFormState = setValue(
        state.changePhoneNumberFormState,
        initialFormState
      );

      return {
        ...state,
        technicalError: false,
        changePhoneNumberFormState: reset(changePhoneNumberFormState),
      };
    }
    return state;
  }),
  on(patchCommunicationAddressError, (state) => {
    return {
      ...state,
      technicalError: true,
      changePhoneNumberFormState: markAsUnsubmitted(
        state.changePhoneNumberFormState
      ),
    };
  }),
  on(getCorrespondenceDetailsSuccess, (state, action) => {
    return {
      ...state,
      technicalError: false,
      initialPhoneNumber:
        action.payload.correspondence.phone.find((number) => number.primary)
          ?.phoneNumber ?? '',
    };
  })
);

export const wrappedReducer = wrapReducerWithFormStateUpdate(
  _reducer,
  (state) => state.changePhoneNumberFormState,
  (_, state) => validateAndUpdateForms(state)
);

export function reducer(state: State = initialState, action: Action): State {
  return wrappedReducer(state, action);
}
