import { CommonUiFormsModule } from '@innogy/common-ui/forms';
import { ProgressiveFormComponentsModule } from '@innogy/shared/progressive-form';
import { NgrxFormsModule } from 'ngrx-forms';
import { NgModule } from '@angular/core';

const modules = [
  CommonUiFormsModule,
  NgrxFormsModule,
  ProgressiveFormComponentsModule,
];

@NgModule({
  exports: modules,
})
export class DefaultFormComponentModule {}
