import type { Locale } from '../typings';

export const throwLocaleError = (locale: Locale) => {
  throw new Error(`Locale "${locale}" is not supported.`);
};

export const isValidNumber = (value: number) => {
  return (
    value != undefined &&
    !isNaN(value) &&
    value !== Infinity &&
    value !== -Infinity
  );
};

/**
 * A regex to validate numeric input for KeyboardEvents
 * NOTE: This regex is set up to check one character, not an entire number.
 *
 * @param locale
 */
export const invalidDecimalSeperatorForLocaleRegex = (
  locale: Locale = 'nl-NL'
): RegExp => {
  switch (locale) {
    case 'en-US':
      return /^,$/;
    case 'nl-NL':
      return /^\.$/;
    default:
      return throwLocaleError(locale);
  }
};
