import { createAction, props } from '@ngrx/store';

export const setResidentialAction = createAction(
  '[AdditionalInformation] Set residential',
  props<{ value: boolean | undefined }>()
);

export const setContractWithOtherSupplierAction = createAction(
  '[AdditionalInformation] Set contractWithOtherSupplier',
  props<{ value: boolean | undefined }>()
);
