import type { StoreFunnelSettingsInterface } from '@innogy/eplus/models';
import type { StoreProductData } from '@innogy/eplus/temporary-core-modules';
import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';

import { handleToggleProduct } from '../../shared/+state';
import {
  clearStoreFunnelInitializedAction,
  mintStoreTrackingIdAction,
  resetStoreProductSelectionAction,
  selectStoreProductAction,
  selectStoreProductsAction,
  setStoreFunnelInitializedAction,
  storeAssociatedGenericFormIdAction,
  toggleStoreProductSelectionAction,
} from './store-funnel.actions';

export const storeFunnelKey = 'store-funnel';

export type StoreFunnelState = StoreFunnelSettingsInterface & {
  selectedProducts: StoreProductData[];
  orderFormId: string | undefined;
};

export const initialStoreFunnelState: StoreFunnelState = {
  id: undefined,
  initialized: false,
  productOverviewPage: undefined,
  orderPage: undefined,
  orderFormId: undefined,
  successPage: undefined,
  apiIntegrationPath: '',
  selectedProducts: [],
  trackingId: undefined,
  productCategory: undefined,
  productType: undefined,
  hidePrices: false,
};

const reducer = createReducer(
  initialStoreFunnelState,
  on(setStoreFunnelInitializedAction, (state, { funnelSettings }) => ({
    ...state,
    ...funnelSettings,
    initialized: true,
  })),
  on(clearStoreFunnelInitializedAction, (state) => ({
    ...state,
    initialized: false,
  })),
  on(mintStoreTrackingIdAction, (state, { id: trackingId }) => ({
    ...state,
    trackingId,
  })),
  on(selectStoreProductAction, (state, { product }) => ({
    ...state,
    selectedProducts: [product],
  })),
  on(selectStoreProductsAction, (state, { products }) => ({
    ...state,
    selectedProducts: products,
  })),
  on(toggleStoreProductSelectionAction, (state, { product }) => ({
    ...state,
    selectedProducts: handleToggleProduct(product, state.selectedProducts),
  })),
  on(resetStoreProductSelectionAction, (state) => ({
    ...state,
    selectedProducts: [],
  })),
  on(storeAssociatedGenericFormIdAction, (state, { formId, instanceId }) => {
    if (instanceId === state.id) {
      return {
        ...state,
        orderFormId: formId,
      };
    }
    return { ...state };
  })
);

export const storeFunnelReducer = (
  state: StoreFunnelState = initialStoreFunnelState,
  action: Action
): StoreFunnelState => reducer(state, action);
