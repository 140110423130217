import { Component, Input } from '@angular/core';
import type { InnogyComponentRendering } from '@core/jss-models';
import { getFieldValue } from '@core/jss-utils';
@Component({
  selector: 'wl-ewo-footer',
  templateUrl: './footer-ewo.component.html',
  styleUrls: ['./footer-ewo.component.scss'],
})
export class FooterEwoComponent {
  @Input() rendering: InnogyComponentRendering | undefined;

  get showAppButtons(): boolean {
    return getFieldValue<boolean>(
      this.rendering,
      'appButtonsShouldShow',
      false
    );
  }

  get fields() {
    return this.rendering?.fields;
  }
}
