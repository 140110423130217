import type { OnInit } from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import type { InstallationAddress } from '@essent/installation-address';
import type { SupplyAddress } from '@essent/new-customer';
import type { OrderAddressFormValues } from '@innogy/become-a-customer/state';
import type { DropdownItem, RadioItem } from '@innogy/common-ui/forms';
import type { UppercaseConverter } from '@innogy/utils-deprecated';
import {
  normalizeDateString,
  uppercaseConverter,
} from '@innogy/utils-deprecated';

import { OrderFulfillmentStepComponent } from '../order-fulfillment-step.component';
import { ORDER_ADDRESS_STEP_INDEX } from '../constants';

@Component({
  selector: 'wl-order-fulfillment-address[progressiveFormState][rendering]',
  templateUrl: './order-fulfillment-address.component.html',
  styleUrls: [],
})
export class OrderFulfillmentAddressComponent
  extends OrderFulfillmentStepComponent<OrderAddressFormValues>
  implements OnInit
{
  @Input() labelsShownAboveFields = false;
  @Input() residenceOrWorkplaceOptions?: RadioItem[];
  @Input() exclusivelyCorporateUsageOptions?: RadioItem[];
  @Input() supplyAddress?: Partial<SupplyAddress> | null;
  @Input() correspondenceAddress?: InstallationAddress | null;
  @Input() correspondenceHouseNumberExtensions?: DropdownItem[] | null;

  @Output() changeSupplyAddress = new EventEmitter<void>();

  _uppercaseConverter: UppercaseConverter = uppercaseConverter;

  ngOnInit() {
    this.progressiveFormCardContent = {
      title: this.fields.AddressCardTitle?.value,
      subtitle: this.fields.AddressCardSubtitle?.value,
      submitButtonText: this.fields.AddressCardSubmitButton?.value,
      stepNumber: ORDER_ADDRESS_STEP_INDEX,
    };
  }

  changeSupplyAddressClick = (e: Event) => {
    e.preventDefault();
    this.changeSupplyAddress.emit();
  };

  hideCorrespondenceHouseNumberExtensions(): boolean {
    return !this.progressiveFormState?.formState?.controls
      .houseNumberCorrespondence?.value;
  }

  get controls() {
    return this.formState?.controls;
  }

  get selectedInswitchOrInhouseOptionLabel() {
    return this.formState?.value?.inswitchInhouse?.value?.label;
  }

  get selectedResidenceOrWorkplaceOption() {
    return this.residenceOrWorkplaceOptions?.find(
      (option) => option.value === this.controls?.residenceOrWorkplace?.value
    );
  }

  get showBusinessFields() {
    return this.fields?.EnableBusinessFields?.value ?? false;
  }

  get selectedStartDate() {
    return normalizeDateString(this.controls?.selectedStartDate?.value ?? '');
  }
}
