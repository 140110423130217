<div class="mb-3">
  <h4 class="accordion__title mb-2" *ngIf="accordions?.title">
    {{ accordions?.title }}
  </h4>

  <div
    ngbAccordion
    [closeOthers]="false"
    [id]="accordions?.id || 'accordion'"
    class="mb-1"
    [ngClass]="accordions?.class"
  >
    <div
      ngbAccordionItem
      [id]="item.id"
      *ngFor="let item of accordions?.items; trackBy: trackById"
      [ngClass]="enableTransparancy ? 'accordion-item--transparent' : ''"
    >
      <h3 ngbAccordionHeader>
        <button
          class="accordion-button"
          ngbAccordionButton
          wlTrackImpression="accordion-impression"
          [trackImpressionInfo]="getImpressionData(item)"
          wlTrackAccordion
          [trackAccordionInfo]="getClickData(item)"
        >
          <wl-partial-accordion-title
            [title]="item?.question?.value"
          ></wl-partial-accordion-title>
        </button>
      </h3>

      <div ngbAccordionCollapse>
        <div ngbAccordionBody>
          <wl-rich-text-wrapper [rendering]="rendering" [field]="item?.answer">
            <div
              [ngClass]="{ accordion__spacing: !accordions?.hidePrefixIcon }"
              class="accordion__content"
            ></div>
          </wl-rich-text-wrapper>
        </div>
      </div>
    </div>
  </div>
</div>
