import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import type { BaseService } from '@essent/common';
import { snakeCase, toHttpParams } from '@essent/common';
import { API_PREFIX } from '@core/config-angular';
import type { Observable } from 'rxjs';

import type {
  InsulationNewCustomerAddressCheckRequestQuery,
  InsulationNewCustomerAddressCheckResponseV1,
} from '../../models';

@Injectable()
export class GetInsulationAddressCheckService
  implements
    BaseService<
      InsulationNewCustomerAddressCheckRequestQuery,
      InsulationNewCustomerAddressCheckResponseV1
    >
{
  public readonly apiVersion = '1';
  public readonly endpoint = `${
    this.apiUrl || ''
  }/eplus/insulation/new-customer/address-check/v${this.apiVersion}`;

  public constructor(
    @Optional()
    @Inject(API_PREFIX)
    private readonly apiUrl: string,
    private readonly httpClient: HttpClient
  ) {}

  public call$(
    payload: InsulationNewCustomerAddressCheckRequestQuery
  ): Observable<InsulationNewCustomerAddressCheckResponseV1> {
    const headers = { Accept: 'application/json' };
    const params = toHttpParams(snakeCase(payload));

    return this.httpClient.get<InsulationNewCustomerAddressCheckResponseV1>(
      this.endpoint,
      { headers, params }
    );
  }
}
