<ng-template #A let-rendering="rendering" let-track="track">
  <div
    sc-placeholder
    name="component-test-a"
    [rendering]="rendering"
    (loaded)="onPlaceholderLoaded(track)"
  ></div>
</ng-template>

<ng-template #B let-rendering="rendering" let-track="track">
  <div
    sc-placeholder
    name="component-test-b"
    [rendering]="rendering"
    (loaded)="onPlaceholderLoaded(track)"
  ></div>
</ng-template>

<wl-experience-editor>
  <ng-template wlLive>
    <ng-container
      *ngTemplateOutlet="
        variant === 'B' ? B : A;
        context: { rendering, track: true }
      "
    ></ng-container>
  </ng-template>
  <ng-template wlPreview>
    Variant "A":
    <ng-container
      *ngTemplateOutlet="A; context: { rendering, track: false }"
    ></ng-container>
    <br />
    Variant "B":
    <ng-container
      *ngTemplateOutlet="B; context: { rendering, track: false }"
    ></ng-container>
  </ng-template>
</wl-experience-editor>
