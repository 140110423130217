import { createSelector } from '@ngrx/store';

import {
  eplusSolarPaybackFeature,
  type SolarPaybackState,
} from './solar-payback.state';

export const selectSolarPaybackApiState = createSelector(
  eplusSolarPaybackFeature,
  (state: SolarPaybackState) => state.apiState
);

export const selectSolarPaybackEnergyUsage = createSelector(
  eplusSolarPaybackFeature,
  (state: SolarPaybackState) => state.energyUsage.kwh
);

export const selectSolarPaybackUsageEstimateFormState = createSelector(
  eplusSolarPaybackFeature,
  (state: SolarPaybackState) => state.usageEstimateFormState
);
