import type { HttpErrorResponse } from '@angular/common/http';
import { Status } from '@essent/common';
import type { CustomerConsent } from '@essent/customer';
import {
  getCustomerConsent,
  getCustomerConsentClear,
  getCustomerConsentError,
  getCustomerConsentSuccess,
} from '@essent/customer';
import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';

export interface State {
  status: Status;
  error?: HttpErrorResponse;
  consents: CustomerConsent[] | null;
}

export const initialState: State = {
  status: Status.IDLE,
  consents: null,
};

const reducer = createReducer(
  initialState,
  on(getCustomerConsent, (state) => ({
    ...state,
    status: Status.PENDING,
  })),
  on(getCustomerConsentSuccess, (_, { payload }) => ({
    status: Status.SUCCESS,
    consents: payload.customerConsents,
  })),
  on(getCustomerConsentError, (_, { payload }) => ({
    status: Status.ERROR,
    error: payload,
    consents: null,
  })),
  on(getCustomerConsentClear, (_) => initialState)
);

export function consentsReducer(
  state: State = initialState,
  action: Action
): State {
  return reducer(state, action);
}

export const getConsentsList = (state: State) => state.consents;
export const getStatus = (state: State) => state.status;
export const getError = (state: State) => state.error;
