import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { getSolarPanelsAddressCheckSuccess } from '@innogy/eplus/temporary-core-modules';
import {
  getFirstFocusControlName,
  ScrollService,
} from '@innogy/utils-deprecated';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { FocusAction } from 'ngrx-forms';
import { delay, filter, map, mergeMap, tap } from 'rxjs/operators';

import {
  navigateToProductOverviewPageAction,
  navigateToResultOverviewPageAction,
  selectSolarPanelsFunnelSettings,
} from '../solar-panels-funnel';
import {
  provideCriteriaAndCalculationParametersToSuitabilityCheckAction,
  scrollToSolarPanelsSuitabilityCheckAction,
  solarPanelsSuitabilityCheckFormSubmitAction,
} from './solar-panels-suitability-check.actions';
import { solarPanelsSuitabilityCheckFormId } from './solar-panels-suitability-check.reducer';
import { selectSolarPanelsSuitabilityCheckFormState } from './solar-panels-suitability-check.selectors';

@Injectable()
export class SolarPanelsSuitabilityCheckEffects {
  funnelSettings$ = this.store$.select(selectSolarPanelsFunnelSettings);
  formState$ = this.store$.select(selectSolarPanelsSuitabilityCheckFormState);

  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store,
    @Inject(DOCUMENT)
    private readonly document: Document,
    private readonly scrollService: ScrollService
  ) {}

  public readonly provideCriteriaAndParameters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSolarPanelsAddressCheckSuccess),
      mergeMap((action) => {
        const { calculationParameters, criteria } = action.payload;
        return [
          provideCriteriaAndCalculationParametersToSuitabilityCheckAction({
            calculationParameters,
            criteria,
          }),
        ];
      })
    )
  );

  public readonly scrollToSuitabilityCheckOnSinglePage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSolarPanelsAddressCheckSuccess),
      concatLatestFrom(() => this.funnelSettings$),
      filter(([, { showSuitabilityCheckPage }]) => !!showSuitabilityCheckPage),
      map(scrollToSolarPanelsSuitabilityCheckAction)
    )
  );

  public readonly scrollToSuitabilityCheck$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(scrollToSolarPanelsSuitabilityCheckAction),
        delay(350),
        tap(() => {
          const formElement = this.document.getElementById(
            solarPanelsSuitabilityCheckFormId
          );

          this.scrollService.scrollToElementAnimated(
            this.scrollService.getWrappingDynamicRow(formElement)
          );
        })
      ),
    { dispatch: false }
  );

  public readonly focusOnSuitabilityCheck$ = createEffect(() =>
    this.actions$.pipe(
      ofType(scrollToSolarPanelsSuitabilityCheckAction),
      delay(450),
      mergeMap(() => {
        return [
          new FocusAction(
            getFirstFocusControlName(
              this.document,
              solarPanelsSuitabilityCheckFormId
            ) ?? ''
          ),
        ];
      })
    )
  );

  public readonly dispatchNavigateToResultOverviewPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(solarPanelsSuitabilityCheckFormSubmitAction),
      concatLatestFrom(() => this.funnelSettings$),
      filter(
        ([, funnelSettings]) =>
          !!funnelSettings.showResultOverviewPage &&
          !funnelSettings.showProductOverviewPage
      ),
      map(([, { resultOverviewPage }]) =>
        navigateToResultOverviewPageAction({
          pageData: resultOverviewPage,
        })
      )
    )
  );

  public readonly dispatchNavigateToProductOverviewPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(solarPanelsSuitabilityCheckFormSubmitAction),
      concatLatestFrom(() => this.funnelSettings$),
      filter(([, funnelSettings]) => !!funnelSettings.showProductOverviewPage),
      map(([, { productOverviewPage }]) =>
        navigateToProductOverviewPageAction({
          pageData: productOverviewPage,
        })
      )
    )
  );
}
