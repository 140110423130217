import type { Actions as FormActions } from 'ngrx-forms';
import type { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

/**
 * Pipeble operator that filters NgrxForms actions based on the controlId property,
 * entering multiple controlId's allows all the provided controlId's to pass.
 *
 * @param controlIds
 */
export const ofControlId =
  (...controlIds: string[]) =>
  (source$: Observable<FormActions<unknown>>) =>
    source$.pipe(
      filter((action) => controlIds.indexOf(action.controlId) !== -1)
    );
