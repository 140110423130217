import { createSelector } from '@ngrx/store';

import { getOrderState } from '../order.selector';

export const getOrderPersonalProgressiveFormState = createSelector(
  getOrderState,
  (state) => state.orderPersonalForm.progressiveForm
);

export const getOrderPersonalFormState = createSelector(
  getOrderPersonalProgressiveFormState,
  (state) => state.formState
);
