import type { AfterViewInit } from '@angular/core';
import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';

import { BasicContentVariantInheritableComponent } from '../basic-content-variant.inheritable.component';

@Component({
  selector: 'wl-basic-content-variant-vertical-image',
  templateUrl: './basic-content-variant-vertical-image.component.html',
  styleUrls: ['./basic-content-variant-vertical-image.component.scss'],
})
export class BasicContentVariantVerticalImageComponent
  extends BasicContentVariantInheritableComponent
  implements AfterViewInit
{
  @ViewChild('imageRef') imageRef?: ElementRef;
  imageHeight?: number;

  constructor(private readonly renderer: Renderer2) {
    super();
  }

  ngAfterViewInit() {
    this.setImageHeight();
  }

  setImageHeight() {
    if (this.imageRef) {
      if (this.image?.aspectRatio) {
        const [widthRatio, heightRatio] = this.image?.aspectRatio
          .split('/')
          .map((v) => parseInt(v));
        const width = this.imageRef.nativeElement.offsetWidth;
        if (widthRatio >= heightRatio) {
          this.renderer.setStyle(
            this.imageRef.nativeElement,
            'height',
            `${(width / widthRatio) * heightRatio}px`
          );
        }
      } else {
        this.renderer.addClass(this.imageRef.nativeElement, 'h-100');
      }
    }
  }
}
