import type { KeyValue } from 'ngrx-forms';

import type { ProgressiveFormGroupState } from '../state';
import { progressiveFormStateReducer } from '../reducer';
import { MarkAsUnsubmittableAction } from '../actions';

export function markAsUnsubmittable<TValue extends KeyValue>(
  state: ProgressiveFormGroupState<TValue>
) {
  return progressiveFormStateReducer(
    state,
    new MarkAsUnsubmittableAction(state.id)
  );
}
