import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';

import {
  solarPanelsResultOverviewHideLoaderAction,
  solarPanelsResultOverviewShowLoaderAction,
} from './solar-panels-result-overview.actions';

export const solarPanelsResultOverviewKey = 'solarPanelResultOverview';

export interface SolarPanelsResultOverviewState {
  showLoader: boolean;
}

export const initialSolarPanelsResultOverviewState: SolarPanelsResultOverviewState =
  {
    showLoader: false,
  };

const featureReducer = createReducer(
  initialSolarPanelsResultOverviewState,
  on(solarPanelsResultOverviewShowLoaderAction, (state) => ({
    ...state,
    showLoader: true,
  })),
  on(solarPanelsResultOverviewHideLoaderAction, (state) => ({
    ...state,
    showLoader: false,
  }))
);

export function solarPanelsResultOverviewReducer(
  state: SolarPanelsResultOverviewState | undefined,
  action: Action
) {
  return featureReducer(state, action);
}
