import { createSelector } from '@ngrx/store';

import { getCommonUiServiceComponentsState } from '../../common-ui-service-components-store.selector';

export const getHowToQuestionnaireState = createSelector(
  getCommonUiServiceComponentsState,
  ({ howToQuestionnaire }) => howToQuestionnaire
);

export const getFlattenedChoices = createSelector(
  getHowToQuestionnaireState,
  ({ choices }) =>
    Object.values(choices).reduce((acc, value) => ({ ...acc, ...value }), {})
);
