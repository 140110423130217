import type { AsyncState } from '@essent/common';
import {
  createAsyncStateReducer,
  createInitialAsyncState,
} from '@essent/common';
import type { SupplyAddress } from '@essent/new-customer';
import {
  getSupplyAddress,
  getSupplyAddressClear,
  getSupplyAddressError,
  getSupplyAddressSuccess,
} from '@essent/new-customer';
import type { Action, ActionReducerMap } from '@ngrx/store';
import { combineReducers } from '@ngrx/store';

export interface SupplyAddressState {
  getSupplyAddress: AsyncState<SupplyAddress>;
}

const initialState: SupplyAddressState = {
  getSupplyAddress: createInitialAsyncState(),
};

const _getSupplyAddressReducer = createAsyncStateReducer(
  getSupplyAddress,
  getSupplyAddressSuccess,
  getSupplyAddressError,
  getSupplyAddressClear
);

const _reducers: ActionReducerMap<SupplyAddressState, Action> = {
  getSupplyAddress: _getSupplyAddressReducer,
};

const _combinedReducers = combineReducers(_reducers);

export function supplyAddressReducer(
  state: SupplyAddressState = initialState,
  action: Action
): SupplyAddressState {
  return _combinedReducers(state, action);
}
