import {
  getAgreementTypes,
  getContractGroupsIsLoading,
} from '@innogy/core/contract';
import type { JssRouteBuilderService } from '@core/jss-routing';
import {
  getLanguage,
  getServiceNavigationItemsWithCategories,
} from '@core/jss-routing';
import type { JssNavItem } from '@core/jss-models';
import type { JssRouteData } from '@core/jss-utils';
import { isNotNullish, waitForData } from '@innogy/utils-rxjs';
import type { ActiveLinkService } from '@innogy/utils-deprecated';
import type { AgreementType } from '@integration/base-models';
import type { ActionReducerMap, Store } from '@ngrx/store';
import { createFeatureSelector } from '@ngrx/store';
import { combineLatestWith, filter, map, pipe } from 'rxjs';

import type { EcmpHeaderState } from './ecmp-header/ecmp-header.reducer';
import {
  ecmpHeaderReducer,
  ecmpHeaderSelectorKey,
} from './ecmp-header/ecmp-header.reducer';

export const navigationSelectorKey = 'navigation';

export interface NavigationReducerState {
  [ecmpHeaderSelectorKey]: EcmpHeaderState;
}

export const navigationReducer: ActionReducerMap<NavigationReducerState> = {
  [ecmpHeaderSelectorKey]: ecmpHeaderReducer,
};

export const getNavigationState = createFeatureSelector<NavigationReducerState>(
  navigationSelectorKey
);

export const DEFAULT_AGREEMENT_TYPE: `${AgreementType.COMMODITY}` = 'Commodity';

export class RouteItem {
  path?: JssRouteData;
  name?: string;
  active?: boolean;
  children?: RouteItem[];
  category?: string;
}

export function getNavItemPathAndActiveState(
  language: string,
  urlBuilder: JssRouteBuilderService,
  activeLinkService: ActiveLinkService,
  isActiveCategory: boolean,
  categoryPath?: string
) {
  return (item: JssNavItem): RouteItem | undefined => {
    if (item.path == null) {
      return undefined;
    }
    const path = urlBuilder.createRouteData(item, language);
    return {
      name: item.name,
      active: isActiveCategory
        ? activeLinkService.isActiveLinkInCategory(categoryPath, path.href)
        : false,
      path,
    };
  };
}
function getCategoryPathAndActiveStateDeep(
  language: string,
  urlBuilder: JssRouteBuilderService,
  activeLinkService: ActiveLinkService,
  categories: JssNavItem[],
  types: AgreementType[] | undefined
) {
  return (category: JssNavItem): RouteItem | undefined => {
    if (category.path == null) {
      return undefined;
    }
    const isActiveCategory = activeLinkService.isBestActiveCategory(
      category,
      categories
    );
    const path = urlBuilder.createRouteData(category, language);
    return {
      ...category,
      active: isActiveCategory,
      path,
      children: category.children
        ?.filter(
          (item) =>
            !item.category || types?.includes(item.category as AgreementType)
        )
        ?.map(
          getNavItemPathAndActiveState(
            language,
            urlBuilder,
            activeLinkService,
            isActiveCategory,
            path.href
          )
        )
        .filter(isNotNullish),
    };
  };
}

const getRelevantNavigationItemsForAgreements = (
  navigationItems: JssNavItem[],
  types: AgreementType[] | undefined
): JssNavItem[] => {
  return navigationItems.filter(
    (item: JssNavItem) =>
      item.category == null ||
      types == null ||
      // In the case the customer has no agreement type, only show the default agreement type menu items
      (types.length === 0 && item.category === DEFAULT_AGREEMENT_TYPE) ||
      (types as string[])?.includes(item.category)
  );
};

export const getNavigationItems = (
  store: Store,
  jssRouteBuilderService: JssRouteBuilderService,
  activeLinkService: ActiveLinkService
) =>
  pipe(
    getServiceNavigationItemsWithCategories,
    combineLatestWith(
      store.select(getAgreementTypes),
      store.select(getContractGroupsIsLoading)
    ),
    filter(([_navigationItems, _types, loading]) => !loading),
    map(([navigationItems, types]) => ({
      categories: getRelevantNavigationItemsForAgreements(
        navigationItems,
        types
      ),
      types,
    })),
    waitForData(store.select(getLanguage)),
    map(([{ categories, types }, language]) =>
      categories
        .map(
          getCategoryPathAndActiveStateDeep(
            language,
            jssRouteBuilderService,
            activeLinkService,
            categories,
            types
          )
        )
        .filter(isNotNullish)
    )
  );

export const showCategories = (
  store: Store,
  jssRouteBuilderService: JssRouteBuilderService,
  activeLinkService: ActiveLinkService
) =>
  pipe(
    getNavigationItems(store, jssRouteBuilderService, activeLinkService),
    map((items) => items.length > 1)
  );
