import type { ComponentNameAndModule } from '@sitecore-jss/sitecore-jss-angular';

const loadChildrenFunc = () =>
  import('./lib/sitecore-forms-lazy.module').then(
    (module) => module.SitecoreFormsLazyModule
  );

const defineImport = (path: string) => ({
  path,
  loadChildren: loadChildrenFunc,
});

const sitecoreFormsLazyImports: ComponentNameAndModule[] = [
  defineImport('GenericForm'),
  defineImport('ProgressiveForm'),
];
export { sitecoreFormsLazyImports };
