import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { UtilsModule } from '@innogy/utils-deprecated';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';

import { PaginationComponent } from './pagination.component';
import { PaginationByUrlComponent } from './pagination-by-url/pagination-by-url.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    UtilsModule,
    FontAwesomeModule,
    NgbPaginationModule,
  ],
  declarations: [PaginationComponent, PaginationByUrlComponent],
  exports: [PaginationComponent, PaginationByUrlComponent],
})
export class PaginationModule {}
