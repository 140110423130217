import { createSelector } from '@ngrx/store';

import { getOrderState } from '../order.selector';

export const getOrderPaymentProgressiveFormState = createSelector(
  getOrderState,
  (state) => state.orderPaymentForm.progressiveForm
);

export const getOrderPaymentFormState = createSelector(
  getOrderPaymentProgressiveFormState,
  (state) => state.formState
);
