import { Inject, Injectable, Optional } from '@angular/core';

import {
  SIMPLE_PROGRESSIVE_FORMS_CONFIG,
  SimpleProgressiveFormsConfig,
} from '../models/simple-progressive-forms-config.model';

@Injectable()
export class SimpleProgressiveFormsConfigService {
  constructor(
    @Optional()
    @Inject(SIMPLE_PROGRESSIVE_FORMS_CONFIG)
    private readonly config: SimpleProgressiveFormsConfig
  ) {}

  get scrollIntoViewWhenActive(): boolean {
    return this.config?.scrollIntoViewWhenActive ?? false;
  }

  get enableAutomaticFocusing(): boolean {
    return this.config?.enableAutomaticFocusing ?? false;
  }

  get totalStepsInFlow(): number {
    return this.config?.totalStepsInFlow ?? NaN;
  }
}
