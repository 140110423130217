import {
  type ComponentRendering,
  getFieldValue,
} from '@sitecore-jss/sitecore-jss-angular';

import type { TimerLabels } from '../timer.model';
import { optionallyApply } from './timer-wrapper.helpers';

export class TimerContent {
  dateToCountDownTo?: Date;
  timerLabels: Partial<TimerLabels>;

  constructor(rendering: ComponentRendering) {
    this.dateToCountDownTo = optionallyApply((s: DateString) => new Date(s))(
      getFieldValue<DateString>(rendering, 'DateToCountDownTo')
    );
    this.timerLabels = {
      daysLabel: getFieldValue(rendering, 'DaysLabel'),
      hoursLabel: getFieldValue(rendering, 'HoursLabel'),
      minutesLabel: getFieldValue(rendering, 'MinutesLabel'),
      secondsLabel: getFieldValue(rendering, 'SecondsLabel'),
    };
  }
}

export type DateString =
  `${number}-${number}-${number}T${number}:${number}:${number}Z`;
