import type { FunnelSettings } from '@innogy/become-a-customer/shared/interfaces';
import {
  GenericModalPosition,
  GenericModalSources,
} from '@innogy/common-ui/shared/interfaces';

export const mockFunnelSettings: FunnelSettings = {
  id: 'F06E0C84CD344F1B9D1CA34CB6955DD3',
  offerset: 'regular-offerset',
  initialized: true,
  disableChannelRecognition: false,
  scrollToInitialStep: false,
  enabledOrderSteps: [],
  startPage: {
    href: '/calculate',
  },
  offerPage: {
    href: '/offer,',
  },
  orderPage: {
    href: '/order',
  },
  orderSuccesPage: {
    href: '/success',
  },
  [GenericModalSources.CHATBOT]: {
    source: GenericModalSources.CHATBOT,
    title: 'goat-title',
    body: 'goat-body',
    tracking: {
      componentName: 'goat-componentName',
      primaryButtonTrackingField: 'goat-primaryButtonTrackingField',
      secondaryButtonTrackingField: 'goat-secondaryButtonTrackingField',
    },
    primaryButtonText: 'goat-primaryButtonText',
    primaryButtonStyle: 'goat-primaryButtonStyle',
    secondaryButtonText: 'goat-secondaryButtonText',
    secondaryButtonStyle: 'goat-secondaryButtonStyle',
    additionalPayload: {
      some: 'goat',
    },
    position: GenericModalPosition.CENTER,
  },
  changeCalculationComponent: {
    electricityUsageRequired: false,
    enableDoubleMeterCheckbox: false,
    enableOfferScroll: false,
    enableUsageQuestionnaireTrigger: false,
    estimationToolIsCollapsible: false,
    estimationToolIsShown: false,
    gasUsageRequired: false,
    isInModal: false,
    usageIconsTransparent: false,
    electricityConsumptionFieldsVerticallyStacked: false,
    smartMeterCheckboxShownAsRadioButtons: false,
    estimateConsumptionButtonShownBeneathFields: false,
    gasHidden: false,
    enableAlternateAddressEntry: false,
  },
  usageWizard: {
    submitLabel: 'goat',
    supplyTypeOptionsLabel: 'goat',
    errorMessage: 'goat',
    residentsOptions: [
      { label: 'a', value: 'a' },
      { label: 'a', value: 'b' },
    ],
    subtitle: 'goat',
    supplyTypeOptionsPlaceHolder: 'goat',
    housingTypeOptions: [
      { label: 'a', value: 'a' },
      { label: 'a', value: 'b' },
    ],
    residentsOptionsLabel: 'goat',
    supplyTypeOptions: [
      { label: 'a', value: 'a' },
      { label: 'a', value: 'b' },
    ],
    title: 'goat',
    housingTypeOptionsLabel: 'goat',
    residentsOptionsPlaceHolder: 'goat',
    housingTypeOptionsPlaceHolder: 'goat',
    componentName: 'goat',
  },
};
