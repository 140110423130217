/**
 * Intuitively: only apply the given function to the given value if it is
 * defined.
 * Mathematically: on-morphisms part of the functor underlying the maybe monad
 * (https://ncatlab.org/nlab/show/maybe+monad).
 */
export function optionallyApply<T, U>(
  f: (x: T) => U
): (x?: T) => U | undefined {
  return (x?: T) => (x !== undefined ? f(x) : undefined);
}
