import {
  EplusOrderAddressFormReducer,
  EplusOrderConfirmationFormReducer,
  EplusOrderPersonalDetailsFormReducer,
  initialOrderAddressState,
  initialOrderConfirmationState,
  initialOrderPersonalDetailsState,
  type OrderAddressState,
  type OrderConfirmationState,
  type OrderPersonalDetailsState,
} from '@innogy/eplus/shared';
import {
  combineReducers,
  createReducer,
  on,
  type Action,
  type ActionReducerMap,
} from '@ngrx/store';
import { setValue } from 'ngrx-forms';
import {
  activateFormStep,
  activateProgressiveFormStepOnSubmissionOf,
  markAsInert,
  markAsyncTasksAsCompleted,
} from '@innogy/progressive-ngrx-forms';
import { postHeatpumpsLeadSuccess } from '@innogy/eplus/temporary-core-modules';

import {
  initHeatpumpsOrderFormCheckboxMapping,
  prefillHeatpumpsOrderFormAddressStateAction,
} from './heatpumps-order-form.actions';

export const heatpumpsOrderFormKey = 'heatpumpsOrderForm';
export const heatpumpsOrderAddressFormId = 'heatpumps-address';
export const heatpumpsOrderPersonalDetailsFormId = 'heatpumps-personal-details';
export const heatpumpsOrderConfirmationFormId = 'heatpumps-confirmation';

export interface HeatpumpsOrderFormState {
  readonly [heatpumpsOrderAddressFormId]: OrderAddressState;
  readonly [heatpumpsOrderPersonalDetailsFormId]: OrderPersonalDetailsState;
  readonly [heatpumpsOrderConfirmationFormId]: OrderConfirmationState;
  readonly checkboxMapping: { [key: string]: string };
}

export const initialHeatpumpsOrderFormState: HeatpumpsOrderFormState = {
  [heatpumpsOrderAddressFormId]: initialOrderAddressState(
    heatpumpsOrderAddressFormId
  ),
  [heatpumpsOrderPersonalDetailsFormId]: initialOrderPersonalDetailsState(
    heatpumpsOrderPersonalDetailsFormId
  ),
  [heatpumpsOrderConfirmationFormId]: initialOrderConfirmationState(
    heatpumpsOrderConfirmationFormId
  ),
  checkboxMapping: {},
};

export const _reducers: ActionReducerMap<HeatpumpsOrderFormState> = {
  [heatpumpsOrderAddressFormId]: EplusOrderAddressFormReducer(
    heatpumpsOrderAddressFormId,
    on(prefillHeatpumpsOrderFormAddressStateAction, (state, action) => {
      const formState = setValue(state.progressiveForm.formState, {
        ...state.progressiveForm.formState.value,
        ...action.payload,
      });
      const progressiveForm = markAsInert({
        ...state.progressiveForm,
        formState,
      });

      return {
        ...state,
        progressiveForm,
      };
    })
  ),
  [heatpumpsOrderPersonalDetailsFormId]: EplusOrderPersonalDetailsFormReducer(
    heatpumpsOrderPersonalDetailsFormId,
    on(prefillHeatpumpsOrderFormAddressStateAction, (state) => ({
      ...state,
      progressiveForm: activateFormStep(state.progressiveForm),
    }))
  ),
  [heatpumpsOrderConfirmationFormId]: EplusOrderConfirmationFormReducer(
    heatpumpsOrderConfirmationFormId,
    activateProgressiveFormStepOnSubmissionOf(
      heatpumpsOrderPersonalDetailsFormId
    ),
    on(postHeatpumpsLeadSuccess, (state) => ({
      ...state,
      progressiveForm: markAsyncTasksAsCompleted(state.progressiveForm),
    }))
  ),
  checkboxMapping: createReducer(
    {},
    on(initHeatpumpsOrderFormCheckboxMapping, (_, { mapping }) => mapping)
  ),
};

const _combinedReducers = combineReducers(_reducers);

export const heatpumpsOrderFormReducer = (
  state: HeatpumpsOrderFormState = initialHeatpumpsOrderFormState,
  action: Action
): HeatpumpsOrderFormState => _combinedReducers(state, action);
