import type { InnogyComponentRendering } from '@core/jss-models';
import { getDroplinkField, getFieldValue } from '@core/jss-utils';

export interface SkeletonLoaderMask {
  mask: string;
  naturalWidth: number;
  naturalHeight: number;
}

export function getSkeletonMaskFromRendering(
  rendering: InnogyComponentRendering | undefined,
  skeletonMaskField = 'SkeletonMask'
): SkeletonLoaderMask {
  const maskFields = getDroplinkField(
    rendering?.fields || {},
    skeletonMaskField
  );
  return {
    mask: getFieldValue<string>(maskFields, 'Mask', ''),
    naturalWidth: parseInt(
      getFieldValue<string>(maskFields, 'NaturalWidth', '0')
    ),
    naturalHeight: parseInt(
      getFieldValue<string>(maskFields, 'NaturalHeight', '0')
    ),
  };
}
