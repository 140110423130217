import type { HttpErrorResponse } from '@angular/common/http';
import { Status } from '@essent/common';
import { patchCommunicationAddressSuccess } from '@essent/communication-address';
import type { CorrespondenceDetails } from '@essent/customer';
import {
  getCorrespondenceDetails,
  getCorrespondenceDetailsClear,
  getCorrespondenceDetailsError,
  getCorrespondenceDetailsSuccess,
} from '@essent/customer';
import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';

export interface State {
  status: Status;
  error?: HttpErrorResponse;
  details?: CorrespondenceDetails;
}
export const initialState: State = {
  status: Status.IDLE,
};

const _reducer = createReducer(
  initialState,
  on(getCorrespondenceDetails, (state) => ({
    ...state,
    status: Status.PENDING,
  })),
  on(getCorrespondenceDetailsSuccess, (_, action) => ({
    status: Status.SUCCESS,
    details: action.payload,
  })),
  on(getCorrespondenceDetailsError, (_, action) => ({
    status: Status.ERROR,
    error: action.payload,
  })),
  on(
    getCorrespondenceDetailsClear,
    patchCommunicationAddressSuccess,
    () => initialState
  )
);

export function correspondenceDetailsReducer(
  state: State = initialState,
  action: Action
): State {
  return _reducer(state, action);
}

export const getDetails = (state: State) => state.details;
export const getStatus = (state: State) => state.status;
export const getError = (state: State) => state.error;
