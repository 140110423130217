import type {
  OrderAddressState,
  OrderConfirmationState,
  OrderPersonalDetailsState,
} from '@innogy/eplus/shared';
import {
  EplusOrderAddressFormReducer,
  EplusOrderPersonalDetailsFormReducer,
  EplusOrderConfirmationFormReducer,
  initialOrderAddressState,
  initialOrderConfirmationState,
  initialOrderPersonalDetailsState,
} from '@innogy/eplus/shared';
import type { Action, ActionReducerMap } from '@ngrx/store';
import { combineReducers, on } from '@ngrx/store';
import {
  activateFormStep,
  activateProgressiveFormStepOnSubmissionOf,
  markAsInert,
  markAsyncTasksAsCompleted,
} from '@innogy/progressive-ngrx-forms';
import { postSolarPanelsConfirmationSuccess } from '@innogy/eplus/temporary-core-modules';
import { setValue } from 'ngrx-forms';

import { prefillSolarPanelsOrderFormAddressStateAction } from './solar-panels-order-form.actions';

export const solarPanelsOrderFormKey = 'solarPanelsOrderForm';
export const solarPanelsOrderAddressFormId = 'solar-panels-address';
export const solarPanelsOrderPersonalDetailsFormId =
  'solar-panels-personal-details';
export const solarPanelsOrderConfirmationFormId = 'solar-panels-confirmation';

export interface SolarPanelsOrderFormState {
  readonly [solarPanelsOrderAddressFormId]: OrderAddressState;
  readonly [solarPanelsOrderPersonalDetailsFormId]: OrderPersonalDetailsState;
  readonly [solarPanelsOrderConfirmationFormId]: OrderConfirmationState;
}

export const initialSolarPanelsOrderFormState: SolarPanelsOrderFormState = {
  [solarPanelsOrderAddressFormId]: initialOrderAddressState(
    solarPanelsOrderAddressFormId
  ),
  [solarPanelsOrderPersonalDetailsFormId]: initialOrderPersonalDetailsState(
    solarPanelsOrderPersonalDetailsFormId
  ),
  [solarPanelsOrderConfirmationFormId]: initialOrderConfirmationState(
    solarPanelsOrderConfirmationFormId
  ),
};

export const _reducers: ActionReducerMap<SolarPanelsOrderFormState> = {
  [solarPanelsOrderAddressFormId]: EplusOrderAddressFormReducer(
    solarPanelsOrderAddressFormId,
    on(prefillSolarPanelsOrderFormAddressStateAction, (state, action) => {
      const formState = setValue(state.progressiveForm.formState, {
        ...state.progressiveForm.formState.value,
        ...action.payload,
      });
      const progressiveForm = markAsInert({
        ...state.progressiveForm,
        formState,
      });

      return {
        ...state,
        progressiveForm,
      };
    })
  ),
  [solarPanelsOrderPersonalDetailsFormId]: EplusOrderPersonalDetailsFormReducer(
    solarPanelsOrderPersonalDetailsFormId,
    on(prefillSolarPanelsOrderFormAddressStateAction, (state) => ({
      ...state,
      progressiveForm: activateFormStep(state.progressiveForm),
    }))
  ),
  [solarPanelsOrderConfirmationFormId]: EplusOrderConfirmationFormReducer(
    solarPanelsOrderConfirmationFormId,
    activateProgressiveFormStepOnSubmissionOf(
      solarPanelsOrderPersonalDetailsFormId
    ),
    on(postSolarPanelsConfirmationSuccess, (state) => ({
      ...state,
      progressiveForm: markAsyncTasksAsCompleted(state.progressiveForm),
    }))
  ),
};

const _combinedReducers = combineReducers(_reducers);

export const solarPanelsOrderFormReducer = (
  state: SolarPanelsOrderFormState = initialSolarPanelsOrderFormState,
  action: Action
): SolarPanelsOrderFormState => _combinedReducers(state, action);
