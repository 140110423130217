import { routerSelectors } from './lib/router-state';
export { selectQueryParam } from './lib/router-state';
export const {
  selectQueryParam: ngrxSelectQueryParam,
  selectCurrentRoute,
  selectQueryParams,
  selectRouteData,
  selectRouteParam,
  selectRouteParams,
  selectUrl,
} = routerSelectors;

export {
  metaReducers,
  reducers,
  routerSelectorKey,
  runtimeChecks,
} from './lib/app-state';
export type { State } from './lib/app-state';
export { extModules } from './lib/build-specifics';
export {
  createInitialCollectionState,
  getCollectionEntry,
  getCollectionObject,
  getCollectionStatus,
  isError,
  isLoading,
  removeFromCollectionState,
  updateCollectionState,
} from './lib/collection-state';
export type { CollectionState, Entries, Entry } from './lib/collection-state';
export { createCollectionStateReducer } from './lib/collection-state.reducers';
export type { CollectionStateType } from './lib/collection-state.reducers';
export { provideBootstrapEffects } from './lib/effects.utils';
export type { ChangeFormState, StateStatus } from './lib/state-status.model';
export { createStateStatusReducer } from './lib/state-status.reducers';
export {
  getData,
  getError,
  getErrorState,
  getIdle,
  getLoading,
  getLoadingState,
  getPending,
  getStatus,
  getSuccess,
} from './lib/state-status.selectors';
export { getChangeFormViewModel } from './lib/view-model-helpers';
