<ng-container *ngIf="viewModel$ | async as vm">
  <ng-container [ngSwitch]="content?.variant">
    <wl-horizontal-commodity-offer
      *ngSwitchCase="'horizontal'"
      [offers]="vm.offers"
      [content]="content"
      [showMemberGetMember]="vm.hasMemberGetMember"
      (selectOffer)="selectOffer($event)"
      (openMoreInfo)="openMoreInfo($event)"
      (viewTariffs)="viewTariffs($event)"
      (adjustCalculation)="adjustCalculation()"
      [supplyAddress]="vm.address"
      [consumptionValues]="vm.consumption"
    />

    <wl-vertical-commodity-offer
      *ngSwitchCase="'vertical'"
      [offers]="vm.offers"
      [content]="content"
      [showMemberGetMember]="vm.hasMemberGetMember"
      (selectOffer)="selectOffer($event)"
      (openMoreInfo)="openMoreInfo($event)"
      (viewTariffs)="viewTariffs($event)"
      (adjustCalculation)="adjustCalculation()"
      [supplyAddress]="vm.address"
      [consumptionValues]="vm.consumption"
    />

    <wl-mixed-commodity-offer
      *ngSwitchCase="'mixed'"
      [offers]="vm.offers"
      [content]="content"
      [showMemberGetMember]="vm.hasMemberGetMember"
      (selectOffer)="selectOffer($event)"
      (openMoreInfo)="openMoreInfo($event)"
      (viewTariffs)="viewTariffs($event)"
      (adjustCalculation)="adjustCalculation()"
      [supplyAddress]="vm.address"
      [consumptionValues]="vm.consumption"
    />
  </ng-container>
</ng-container>
