import type { InnogyComponentRendering } from '@core/jss-models';
import { createAction, props } from '@ngrx/store';

export const ORDER_REFRESH_OFFER_ACTION_ID = 'OrderRefreshOffer';
export const SUBMIT_CORRESPONDENCE_DETAILS_ACTION_ID =
  'submit_correspondence_details';

const BAC_ORDER_PREFIX = `[become-a-customer-order]`;

export const initOrderAction = createAction(`${BAC_ORDER_PREFIX} Init order`);

export const addOrderRenderingAction = createAction(
  `${BAC_ORDER_PREFIX} Add rendering`,
  props<{ rendering: InnogyComponentRendering }>()
);

export const submitOrderFormAction = createAction(
  `${BAC_ORDER_PREFIX} Submit order form`
);

export const resetOrderConditionsAction = createAction(
  `${BAC_ORDER_PREFIX} Reset the "conditions accepted" checkbox`
);

export const redirectToSuccessPageAction = createAction(
  `${BAC_ORDER_PREFIX} Redirect to the success page`
);
