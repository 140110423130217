import { Component, Input } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { TextField } from '@sitecore-jss/sitecore-jss-angular';

@Component({
  selector: 'wl-error-card',
  templateUrl: './error-card.component.html',
  styleUrls: [
    './error-card.component.essent.scss',
    './error-card.component.ed.scss',
  ],
})
export class ErrorCardComponent {
  @Input()
  title?: TextField;
  @Input()
  titleTranslationKey?: string;
  @Input()
  message?: TextField;
  @Input()
  messageTranslationKey?: string;
  @Input()
  messageTranslationParams?: string;
  @Input()
  icon?: IconProp;
  @Input()
  showDivider = false;
  @Input()
  mode: 'full' | 'body' | 'content' = 'full';
  @Input()
  automationId?: string;
}
