import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';

import {
  hideInsulationProductOverviewAction,
  showInsulationProductOverviewAction,
} from './insulation-product-overview.actions';

export const insulationProductOverviewKey = 'insulationProductOverview';

export interface InsulationProductOverviewState {
  isVisible: boolean;
}

export const initialInsulationProductOverviewState: InsulationProductOverviewState =
  {
    isVisible: false,
  };

const reducer = createReducer(
  initialInsulationProductOverviewState,
  on(showInsulationProductOverviewAction, (state) => ({
    ...state,
    isVisible: true,
  })),
  on(hideInsulationProductOverviewAction, (state) => ({
    ...state,
    isVisible: false,
  }))
);

export const insulationProductOverviewReducer = (
  state: InsulationProductOverviewState = initialInsulationProductOverviewState,
  action: Action
): InsulationProductOverviewState => reducer(state, action);
