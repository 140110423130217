<ng-container
  *ngIf="supplyAddress?.street && supplyAddress?.city; else alternativeSummary"
>
  <span wlCSMask>
    {{ supplyAddress?.street }} {{ supplyAddress?.houseNumber
    }}<span *ngIf="supplyAddress?.houseNumberExtension">
      {{ supplyAddress?.houseNumberExtension }}</span
    >,
    {{ supplyAddress?.city }}
  </span>
</ng-container>

<ng-template #alternativeSummary>
  <span wlCSMask>
    {{ supplyAddress?.postcode }}, {{ supplyAddress?.houseNumber
    }}<span *ngIf="supplyAddress?.houseNumberExtension">
      {{ supplyAddress?.houseNumberExtension }}
    </span>
  </span>
</ng-template>
