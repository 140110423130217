import type { ValidationErrors, ValidationFn } from 'ngrx-forms';

export interface PostalCodeIsExcluded {
  actual: any;
}

declare module 'ngrx-forms/src/state' {
  export interface ValidationErrors {
    postalCodeIsExcluded?: PostalCodeIsExcluded;
  }
}

export const postalCodeIsExcluded = (
  exclusion: string
): ValidationFn<string> => {
  return (value: string): ValidationErrors => {
    const exclusionArray = exclusion.split(',').map((s) => s.trim());
    if (valueMatchesPostalcodeExclusion(exclusionArray, value)) {
      return {
        postalCodeIsExcluded: { actual: value },
      };
    }
    return {};
  };
};

export function valueMatchesPostalcodeExclusion(
  exclusion: Array<string>,
  value: string
): boolean {
  return exclusion.some(
    (e) =>
      e.length > 0 && value.substring(0, Math.min(e.length, value.length)) === e
  );
}
