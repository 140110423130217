import { createSelector } from '@ngrx/store';
import { Status } from '@essent/common';

import { getBecomeACustomerState } from '../become-a-customer-feature.selector';

export const getGetOfferState = createSelector(
  getBecomeACustomerState,
  (state) => state.getOffers
);

export const isGetOffersLoading = createSelector(
  getGetOfferState,
  (state) => state.status === Status.PENDING
);
