import { getAddressCheckError, getAddressCheckSuccess } from '@essent/address';
import { patchCommunicationAddressError } from '@essent/communication-address';
import { getCorrespondenceDetailsSuccess } from '@essent/customer';
import {
  isNumeric,
  isPostalCode,
  useValidatorIf,
  validateSequential,
} from '@innogy/utils-deprecated';
import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';
import type { FormGroupState } from 'ngrx-forms';
import {
  createFormGroupState,
  markAsUnsubmitted,
  onNgrxForms,
  onNgrxFormsAction,
  reset,
  ResetAction,
  setValue,
  SetValueAction,
  UnfocusAction,
  updateGroup,
  wrapReducerWithFormStateUpdate,
} from 'ngrx-forms';
import { maxLength, required } from 'ngrx-forms/validation';

import type { ChangeCommunicationAddressFormState } from '../customer-details.model';
import { AddressTypes } from '../customer-details.model';
export const CHANGE_COMMUNICATION_ADDRESS_FORM_ID =
  'changeCommunicationAddress';
export const addressTypeControlId = `${CHANGE_COMMUNICATION_ADDRESS_FORM_ID}.addressType`;
export const postalCodeControlId = `${CHANGE_COMMUNICATION_ADDRESS_FORM_ID}.postalCode`;
export const communicationNumberControlId = `${CHANGE_COMMUNICATION_ADDRESS_FORM_ID}.communicationNumber`;
export const houseNumberAdditionControlId = `${CHANGE_COMMUNICATION_ADDRESS_FORM_ID}.houseNumberAddition`;
export const streetControlId = `${CHANGE_COMMUNICATION_ADDRESS_FORM_ID}.street`;
export const cityControlId = `${CHANGE_COMMUNICATION_ADDRESS_FORM_ID}.city`;
export const personOfContactControlId = `${CHANGE_COMMUNICATION_ADDRESS_FORM_ID}.personOfContact`;

export interface State {
  changeCommunicationAddressForm: FormGroupState<ChangeCommunicationAddressFormState>;
  technicalError: boolean;
  isFetchingAddressCheck: boolean;
  initialAddressType: AddressTypes;
}

export const initialFormState: ChangeCommunicationAddressFormState = {
  addressType: AddressTypes.ADDRESS,
  postalCode: '',
  communicationNumber: undefined,
  city: '',
  houseNumberAddition: '',
  street: '',
};

export const initialFormGroupState =
  createFormGroupState<ChangeCommunicationAddressFormState>(
    CHANGE_COMMUNICATION_ADDRESS_FORM_ID,
    initialFormState
  );

export const initialState: State = {
  changeCommunicationAddressForm: initialFormGroupState,
  technicalError: false,
  isFetchingAddressCheck: false,
  initialAddressType: AddressTypes.ADDRESS,
};

const isAddress = (state: State) => {
  return (
    state.changeCommunicationAddressForm.value.addressType ===
    AddressTypes.ADDRESS
  );
};

export const validateAndUpdateForms = (state: State) => {
  return updateGroup<ChangeCommunicationAddressFormState>({
    postalCode: validateSequential(required, isPostalCode),
    communicationNumber: validateSequential(required, isNumeric),
    houseNumberAddition: validateSequential(
      useValidatorIf(maxLength(6), isAddress(state))
    ),
    street: validateSequential(useValidatorIf(required, isAddress(state))),
    city: validateSequential(required),
  })(state.changeCommunicationAddressForm);
};

const _reducer = createReducer(
  initialState,
  onNgrxForms(),
  onNgrxFormsAction(ResetAction, (state, action) => {
    if (action.controlId === CHANGE_COMMUNICATION_ADDRESS_FORM_ID) {
      const updated = setValue(
        state.changeCommunicationAddressForm,
        initialFormState
      );

      return {
        ...state,
        changeCommunicationAddressForm: reset(updated),
        isFetchingAddressCheck: false,
        technicalError: false,
      };
    }
    return state;
  }),
  onNgrxFormsAction(UnfocusAction, (state, action) => {
    if (
      action.controlId.startsWith(`${CHANGE_COMMUNICATION_ADDRESS_FORM_ID}.`)
    ) {
      return {
        ...state,
        technicalError: false,
        changeCommunicationAddressForm: validateAndUpdateForms(state),
      };
    }
    return state;
  }),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    if (
      action.controlId === postalCodeControlId ||
      action.controlId === communicationNumberControlId
    ) {
      return {
        ...state,
        isFetchingAddressCheck: true,
      };
    }

    return state;
  }),
  on(getAddressCheckError, getAddressCheckSuccess, (state) => ({
    ...state,
    isFetchingAddressCheck: false,
  })),
  on(patchCommunicationAddressError, (state) => {
    return {
      ...state,
      technicalError: true,
      changeCommunicationAddressForm: markAsUnsubmitted(
        state.changeCommunicationAddressForm
      ),
    };
  }),
  on(getCorrespondenceDetailsSuccess, (state, action) => {
    const addressType =
      action.payload.correspondence.address != null &&
      'pobox' in action.payload.correspondence.address
        ? AddressTypes.POSTALBOX
        : AddressTypes.ADDRESS;
    return {
      ...state,
      initialAddressType: addressType,
    };
  })
);

const wrappedReducer = wrapReducerWithFormStateUpdate(
  _reducer,
  (state) => state.changeCommunicationAddressForm,
  (_, state) => validateAndUpdateForms(state)
);

export function reducer(state: State = initialState, action: Action): State {
  return wrappedReducer(state, action);
}
