<div class="d-flex flex-column gap-1">
  <div
    *ngIf="monthlyAmountLabel && monthlyAmount"
    class="d-flex justify-content-between fw-bold"
  >
    <div>
      <span *scText="monthlyAmountLabel"></span>
      <wl-tooltip
        [tooltip]="{
          description: monthlyAmountTooltip,
        }"
        [color]="'white'"
        *ngIf="!!monthlyAmountTooltip"
        class="ps-1"
      >
      </wl-tooltip>
    </div>
    <div>{{ monthlyAmount | roundedCurrency }}</div>
  </div>
  <div class="d-flex justify-content-between">
    <div>
      <span *wlGenericRichText="budgetBillLabel"></span>
      <wl-tooltip
        [tooltip]="{
          description: budgetBillTooltip,
        }"
        [color]="'white'"
        *ngIf="!!budgetBillTooltip"
        class="ps-1"
      >
      </wl-tooltip>
    </div>
    <div>{{ budgetBillAmount | roundedCurrency }}</div>
  </div>
</div>
