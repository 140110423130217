import type { InstallationAddress } from '@essent/installation-address';
import { AlternativeHousenumberExtensions } from '@innogy/become-a-customer/shared/interfaces';
import type { DropdownItem } from '@innogy/common-ui/forms';

export const createExtensionDropdownList = (
  addresses: InstallationAddress[] = []
) => {
  const dropdownItems = addresses
    .map((address) => address.houseNumberExtension)
    .filter((extension): extension is string => !!extension)
    .map<DropdownItem>((extension) => ({
      label: extension,
      value: extension,
    }));

  dropdownItems.unshift({
    label: AlternativeHousenumberExtensions.NONE,
    value: '',
  });
  dropdownItems.push({
    label: AlternativeHousenumberExtensions.OTHER,
    value: AlternativeHousenumberExtensions.OTHER,
  });

  return dropdownItems;
};
