import { Component, Input } from '@angular/core';

import { VideoModel } from './video.model';

@Component({
  selector: 'wl-video',
  templateUrl: './video.component.html',
  styleUrls: [],
})
export class VideoComponent {
  @Input()
  video?: VideoModel;
}
