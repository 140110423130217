import type { OfferType } from '@innogy/become-a-customer/shared/interfaces';
import { createAction, props } from '@ngrx/store';

import { BAC_ACTION_PREFIX } from '../constants';

export const consumptionEANMismatchErrorAction = createAction(
  `${BAC_ACTION_PREFIX} Consumption EAN mismatch error`,
  props<{ mismatchOfferType: OfferType }>()
);

export const performPutConsumptionAction = createAction(
  `${BAC_ACTION_PREFIX} Perform put consumption`
);

export const skipToConsumptionOnNonBlockingErrorAction = createAction(
  `${BAC_ACTION_PREFIX} Skip to consumption on non-blocking error`
);
