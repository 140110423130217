<div class="offer-budgetbill" *ngIf="offer">
  <div class="mb-2 border total-cost-breakdown">
    <div class="d-flex flex-column">
      <ng-container *ngIf="offer.discountPrice">
        <div class="d-flex justify-content-between">
          <div class="text-start pe-1">
            <span
              wlAutomationId="yearly-costs-before-discount"
              *scText="fields?.YearlyCosts"
              wlCSCapture
            ></span>
          </div>
          <div class="text-end text-nowrap" wlCSCapture>
            {{ offer.beforeDiscountExpectedYearlyAmount | roundedCurrency }}
          </div>
        </div>
        <div
          class="d-flex justify-content-between text-success cashback-value-row"
        >
          <div class="text-start pe-1">
            <span
              wlAutomationId="cashback-value"
              *scText="fields?.CashbackValue"
              wlCSCapture
            ></span>
          </div>
          <div class="text-end text-nowrap" wlCSCapture>
            - {{ offer.discountPrice | roundedCurrency }}
          </div>
        </div>
      </ng-container>
      <div class="d-flex justify-content-between fw-bold">
        <div class="text-start pe-1">
          <span wlAutomationId="yearly-costs" wlCSCapture>{{
            yearlyCostLabel
          }}</span>
        </div>
        <div class="text-end text-nowrap" wlCSCapture>
          {{ offer.expectedYearlyAmount | roundedCurrency }}
        </div>
      </div>
    </div>
  </div>

  <div class="budget-bill">
    <div class="d-flex flex-column align-items-center">
      <div>
        <span
          class="monthly-amount__before fw-bold"
          *scText="fields?.MonthlyCostsBefore"
          wlCSCapture
        ></span
        ><wl-tooltip
          [tooltip]="{
            description: fields?.MonthlyCostsBeforeTooltip?.value,
          }"
          *ngIf="!!fields?.MonthlyCostsBeforeTooltip?.value"
          class="ps-1"
        >
        </wl-tooltip>
      </div>

      <div class="monthly-amount__price">
        <span class="text-brand h1" wlCSCapture>{{
          offer.expectedMonthlyAmount | roundedCurrency
        }}</span>
        <span class="ps-1" *scText="fields?.MonthSupLabel" wlCSCapture></span>
      </div>
      <div class="budget-bill-amount">
        <span
          class="budget-bill-amount-text"
          *wlGenericRichText="fields?.proposedBudgetBillBefore"
          wlCSCapture
        ></span>
        <span wlCSCapture>{{ offer.budgetBillAmount! | roundedCurrency }}</span>
        <wl-tooltip
          [tooltip]="{
            description: fields?.ProposedBudgetbillBeforeTooltip?.value,
          }"
          *ngIf="!!fields?.ProposedBudgetbillBeforeTooltip?.value"
          class="ps-1"
        >
        </wl-tooltip>
      </div>
    </div>
  </div>

  <div *ngIf="displayMGM(hasMemberGetMember)" class="mt-2">
    <span class="rounded-1 budget-bill__badge">
      {{
        fields?.MemberGetMemberSharedValue?.value || ''
          | interpolate
            : {
                variables: {
                  mgmValue: mgmSharedValue | roundedCurrency,
                },
              }
      }}
    </span>
  </div>
</div>
