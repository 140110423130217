import type { OnDestroy, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { LoginToolstepLaststepService } from '@innogy/core/analytics';
import { myEnvironmentActive } from '@core/settings';
import { Store, select } from '@ngrx/store';
import { Subject, combineLatest } from 'rxjs';
import { filter, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { loadUserInfo } from '../+state/account.actions';
import { selectAccountId } from '../+state/account.selectors';
import type { State } from '../+state/feature.selectors';
@Component({
  selector: 'wl-load-account',
  templateUrl: './load-account.component.html',
})
export class LoadAccountComponent implements OnInit, OnDestroy {
  private readonly myEnvironmentActive$ = this.store$.pipe(
    select(myEnvironmentActive),
    filter((active) => active === true)
  );

  private readonly accountId$ = this.store$.pipe(selectAccountId);

  private readonly trackLoginLastStep$ = this.accountId$.pipe(
    switchMap((accountId) =>
      this.loginToolstepLaststepService.trackLoginLaststep(accountId)
    ),
    take(1)
  );

  private readonly onDestroy$ = new Subject();

  constructor(
    private readonly store$: Store<State>,
    private readonly loginToolstepLaststepService: LoginToolstepLaststepService
  ) {}

  ngOnInit() {
    const loadUserInfo$ = this.myEnvironmentActive$.pipe(
      tap(() => this.store$.dispatch(loadUserInfo()))
    );
    const trackLoginLaststep$ = this.myEnvironmentActive$.pipe(
      switchMap(() => this.trackLoginLastStep$)
    );

    combineLatest([loadUserInfo$, trackLoginLaststep$])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe();
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
