import { Component, Input } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import type { InnogyComponentRendering } from '@core/jss-models';
import { InterpolationOptions } from '@core/jss-utils';
import type { RichTextField } from '@sitecore-jss/sitecore-jss-angular';
import { LinkField, TextField } from '@sitecore-jss/sitecore-jss-angular';

@Component({
  selector: 'wl-information-block',
  templateUrl: './information-block.component.html',
  styleUrls: [],
})
export class InformationBlockComponent {
  @Input({ required: true })
  rendering: InnogyComponentRendering | undefined;

  @Input()
  title?: TextField;
  @Input()
  message?: TextField | RichTextField;
  @Input()
  interpolationOptions?: InterpolationOptions;
  @Input()
  link?: LinkField;
  @Input()
  linkSecondary?: LinkField;
  @Input()
  linedTitle = true;
  @Input()
  sideNote = false;
  @Input()
  buttonType: 'button' | 'link' = 'link';
  @Input()
  icon?: IconProp;
  @Input()
  navigationExtras: NavigationExtras = {
    queryParamsHandling: 'merge',
  };
  @Input()
  automationId = 'information-block';
  @Input()
  trackImpressionName?: string;
}
