import { createSelector } from '@ngrx/store';
import { getCustomerData, getCustomerHasError } from '@customer/entity';

import { getAccountHasError } from './account.selectors';

const getShowError = createSelector(
  getAccountHasError,
  getCustomerHasError,
  (accountError, customerError) => accountError || customerError
);

const getShowCustomer = createSelector(
  getCustomerData,
  getShowError,
  (customerInfo, showError) => customerInfo != null && !showError
);

export const getCustomerWithStates = createSelector(
  getCustomerData,
  getShowError,
  getShowCustomer,
  (customer, showError, showCustomer) => ({
    customer,
    showError,
    showCustomer,
  })
);
