import type { MultipleAnswerQuestion } from './multiple-answer-question.interface';
import type { SingleAnswerQuestion } from './single-answer-question.interface';

// Need to define some repeating consts to keep SonarQube happy
const singleAnswerQuestion = 'single-answer-question';
const typeGebouw = 'type-gebouw';
const weetIkNiet = 'Weet ik niet';
const orderPage = 'orderPage';
const nextQuestion = 'next-question';
const infoModal = 'info-modal';
const link = 'link';

/*
  This structure is used by the reducer and effects to navigate from question to question.
  Each answer has a 'target' that either refers to another question or a completely different webpage.
  The target mapping is implemented in the questionnaire effects.
 */
export const QuestionnaireStructure: {
  [key: string]: SingleAnswerQuestion | MultipleAnswerQuestion;
} = {
  huiseigenaar: {
    type: singleAnswerQuestion,
    question: 'Ben je eigenaar van de woning?',
    apiKey: 'home_ownership',
    trackingStepName: '- eigenaar woning',
    options: [
      {
        label: 'Ja, ik ben eigenaar',
        target: {
          type: nextQuestion,
          questionName: typeGebouw,
        },
        apiValue: 'Ja',
      },
      {
        label:
          'Nee, ik huur de woning maar ik heb wel toestemming van de verhuurder',
        target: {
          type: nextQuestion,
          questionName: typeGebouw,
        },
        apiValue: 'Nee',
      },
      {
        label:
          'Nee, ik huur de woning en ik heb geen toestemming van de verhuurder',
        target: {
          type: link,
          funnelKey: 'permission',
        },
        apiValue: null,
      },
    ],
    percentage: 10,
  },
  'type-gebouw': {
    question: 'Wat voor type woning heb je?',
    type: singleAnswerQuestion,
    apiKey: 'building_type',
    trackingStepName: '- type woning',
    options: [
      {
        label: 'Appartement',
        target: {
          type: nextQuestion,
          questionName: 'mechanische-ventilatie',
        },
        apiValue: 'Appartement',
      },
      {
        label: 'Tussenwoning',
        target: {
          type: nextQuestion,
          questionName: 'mechanische-ventilatie',
        },
        apiValue: 'Tussenwoning',
      },
      {
        label: 'Hoekwoning',
        target: {
          type: nextQuestion,
          questionName: 'bouwjaar',
        },
        apiValue: 'Hoekwoning',
      },
      {
        label: 'Twee-onder-één-kap woning',
        target: {
          type: nextQuestion,
          questionName: 'bouwjaar',
        },
        apiValue: 'Twee-onder-een-kap woning',
      },
      {
        label: 'Vrijstaande woning',
        target: {
          type: nextQuestion,
          questionName: 'bouwjaar',
        },
        apiValue: 'Vrijstaande woning',
      },
    ],
    percentage: 30,
  },
  'mechanische-ventilatie': {
    type: singleAnswerQuestion,
    question: 'Heeft je woning mechanische ventilatie?',
    apiKey: 'vent_housebuilt',
    trackingStepName: '- mechanische ventilatie',
    options: [
      {
        label: 'Ja, mijn woning heeft mechanische ventilatie',
        target: {
          type: nextQuestion,
          questionName: 'bouwjaar',
        },
        apiValue: 'Ja',
      },
      {
        label: 'Nee, mijn woning heeft geen mechanische ventilatie',
        target: {
          type: link,
          funnelKey: 'mechanicalVentilation',
        },
        apiValue: null,
      },
    ],
    percentage: 40,
    info: {
      label: 'Uitleg over mechanische ventilatie',
      target: {
        type: infoModal,
        funnelKey: 'infoMechanicalVentilation',
      },
    },
  },
  bouwjaar: {
    type: singleAnswerQuestion,
    question: 'Wat is het bouwjaar van je woning?',
    apiKey: 'construction_year',
    trackingStepName: '- bouwjaar woning',
    options: [
      {
        label: 'Tot 1930',
        target: {
          type: nextQuestion,
          questionName: 'isolatie',
        },
        apiValue: 'Tot 1930',
      },
      {
        label: '1931 - 1994',
        target: {
          type: nextQuestion,
          questionName: 'isolatie',
        },
        apiValue: '1931 - 1994',
      },
      {
        label: 'Vanaf 1995',
        target: {
          type: nextQuestion,
          questionName: 'zonnepanelen',
        },
        apiValue: 'Vanaf 1995',
      },
    ],
    percentage: 50,
  },
  isolatie: {
    type: 'multiple-answer-question',
    question: 'Over welke isolatiemaatregelen beschikt je woning?',
    subtitle: 'Meerdere opties zijn hier mogelijk',
    apiKey: 'installed_insulation',
    trackingStepName: '- isolatiemaatregelen',
    options: [
      {
        label: 'Spouwmuurisolatie',
        value: 1,
        apiValue: 'Spouwmuurisolatie',
      },
      {
        label: 'Vloerisolatie',
        value: 1,
        apiValue: 'Vloerisolatie',
      },
      {
        label: 'Dakisolatie',
        value: 1,
        apiValue: 'Dakisolatie',
      },
      {
        label: 'Dubbel/HR++ glas',
        value: 0,
        apiValue: 'Dubbel/HR++ glas',
      },
      {
        label: weetIkNiet,
        value: 2,
        apiValue: null,
      },
    ],
    valueNeeded: 2,
    goodAnswer: {
      target: {
        type: nextQuestion,
        questionName: 'zonnepanelen',
      },
    },
    badAnswer: {
      target: {
        type: link,
        funnelKey: 'insulation',
      },
    },
    percentage: 55,
  },
  zonnepanelen: {
    type: singleAnswerQuestion,
    question: 'Heb je zonnepanelen?',
    apiKey: 'has_solar_panel',
    trackingStepName: '- zonnepanelen',
    options: [
      {
        label: 'Ja, ik heb zonnepanelen',
        target: {
          type: nextQuestion,
          questionName: 'cv-ketel',
        },
        apiValue: 'Ja',
      },
      {
        label: 'Nee, ik heb geen zonnepanelen',
        target: {
          type: nextQuestion,
          questionName: 'cv-ketel',
        },
        apiValue: 'Nee',
      },
    ],
    percentage: 60,
  },
  'cv-ketel': {
    type: singleAnswerQuestion,
    question: 'Heb je een cv-ketel?',
    subtitle:
      'We leggen je graag uit waarom het van belang is dat je een cv-ketel hebt en wie onze service partners zijn.',
    apiKey: 'boiler_ownership',
    trackingStepName: '- eigenaar cv-ketel',
    info: {
      label: 'Uitleg over cv-ketels en servicepartners',
      target: {
        type: infoModal,
        funnelKey: 'infoBoiler',
      },
    },
    options: [
      {
        label: 'Ja, ik heb een gekochte cv-ketel',
        target: {
          type: nextQuestion,
          questionName: 'leeftijd',
        },
        apiValue: 'Eigendom',
      },
      {
        label: 'Ja, ik huur een cv-ketel van één van de servicepartners',
        target: {
          type: nextQuestion,
          questionName: 'leeftijd',
        },
        apiValue: 'Huur',
      },
      {
        label: 'Ja, ik huur een cv-ketel van iemand anders',
        target: {
          type: link,
          funnelKey: 'boiler',
        },
        apiValue: null,
      },
      {
        label: 'Nee, ik heb geen cv-ketel',
        target: {
          type: link,
          funnelKey: 'boiler',
        },
        apiValue: null,
      },
      {
        label: weetIkNiet,
        target: {
          type: nextQuestion,
          questionName: 'leeftijd',
        },
        apiValue: 'Onbekend',
      },
    ],
    percentage: 70,
  },
  leeftijd: {
    type: singleAnswerQuestion,
    question: 'Wat is de leeftijd van je cv-ketel?',
    apiKey: 'boiler_production_year',
    trackingStepName: '- leeftijd cv-ketel',
    options: [
      {
        label: 'Jonger dan 12 jaar',
        target: {
          type: nextQuestion,
          questionName: 'verwarming',
        },
        apiValue: 'Jonger dan 12 jaar',
      },
      {
        label: 'Ouder dan 12 jaar',
        target: {
          type: nextQuestion,
          questionName: 'verwarming',
        },
        apiValue: 'Ouder dan 12 jaar',
      },
      {
        label: weetIkNiet,
        target: {
          type: nextQuestion,
          questionName: 'verwarming',
        },
        apiValue: weetIkNiet,
      },
    ],
    percentage: 80,
  },
  verwarming: {
    type: singleAnswerQuestion,
    question: 'Hoe wordt je woning verwarmd?',
    subtitle:
      'Je woning kan op verschillende manieren verwarmd worden. We leggen het graag uit.',
    apiKey: 'heat_distribution_system',
    trackingStepName: '- verwarming',
    info: {
      label: 'Uitleg over verwarming',
      target: {
        type: infoModal,
        funnelKey: 'infoHeating',
      },
    },
    options: [
      {
        label: 'Vloer- of lage temperatuur verwarming',
        target: {
          type: link,
          funnelKey: orderPage,
        },
        apiValue: 'Lage temperatuur verwarming',
      },
      {
        label: 'Radiatoren en vloerverwarming',
        target: {
          type: link,
          funnelKey: orderPage,
        },
        apiValue: 'Radiatoren en lage temperatuur verwarming',
      },
      {
        label: 'Radiatoren',
        target: {
          type: link,
          funnelKey: orderPage,
        },
        apiValue: 'Radiator',
      },
      {
        label: 'Luchtverwarming',
        target: {
          type: link,
          funnelKey: 'heating',
        },
        apiValue: null,
      },
      {
        label: 'Convectoren',
        target: {
          type: link,
          funnelKey: 'heating',
        },
        apiValue: null,
      },
      {
        label: weetIkNiet,
        target: {
          type: link,
          funnelKey: orderPage,
        },
        apiValue: 'Onbekend',
      },
    ],
    percentage: 90,
  },
};
