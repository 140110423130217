import type { Offer } from '@innogy/become-a-customer/shared/interfaces';
import type { TypedSimpleChange } from '@innogy/utils-deprecated';
import difference from 'lodash/difference';

export function shouldTrackProductChange(
  offerChange: TypedSimpleChange<Offer | Offer[]>
) {
  if (offerChange?.currentValue !== undefined) {
    if (Array.isArray(offerChange.currentValue)) {
      return multipleOffersChanged(offerChange as TypedSimpleChange<Offer[]>);
    }

    return singleOfferChanged(offerChange as TypedSimpleChange<Offer>);
  }

  return false;
}

function singleOfferChanged(offerChange: TypedSimpleChange<Offer>) {
  return (
    offerChange.previousValue === undefined ||
    offerChange.currentValue.offerId !== offerChange.previousValue.offerId
  );
}

function multipleOffersChanged(offerChange: TypedSimpleChange<Offer[]>) {
  const { previousValue, currentValue } = offerChange;

  const previousIds = previousValue?.map((offer) => offer.offerId);
  const currentIds = currentValue?.map((offer) => offer.offerId);

  return difference(previousIds, currentIds).length > 0;
}
