import { InjectionToken } from '@angular/core';
import type { State as BaseState } from '@innogy/utils-state';
import type { Action, ActionReducerMap } from '@ngrx/store';
import { createFeatureSelector } from '@ngrx/store';

import * as fromAccount from './account.reducer';

export interface AccountState {
  readonly account: fromAccount.State;
}

export interface State extends BaseState {
  readonly account: AccountState;
}

export const accountReducers: ActionReducerMap<AccountState, Action> = {
  account: fromAccount.accountReducer,
};

export const ACCOUNT_FEATURE_REDUCERS = new InjectionToken<
  typeof accountReducers
>('Account Reducers');

export const accountSelectorKey = 'account';

export const getAccountState =
  createFeatureSelector<AccountState>(accountSelectorKey);
