import type { FormAddress } from '@innogy/common-ui/forms';
import { AddressTypes } from '@innogy/common-ui/forms';
import type { ProgressiveFormStateImplementor } from '@innogy/progressive-ngrx-forms';
import {
  createProgressiveFormGroupState,
  createProgressiveNgrxFormReducerWithFormStateUpdate,
} from '@innogy/progressive-ngrx-forms';
import {
  isNumeric,
  isPostalCode,
  useValidatorIf,
  validateSequential,
} from '@innogy/utils-deprecated';
import type { Action, ActionCreator, ReducerTypes } from '@ngrx/store';
import { updateGroup } from 'ngrx-forms';
import { maxLength, required } from 'ngrx-forms/validation';

export type OrderAddressState = ProgressiveFormStateImplementor<FormAddress>;

type AdditionalOnFns = ReducerTypes<
  OrderAddressState,
  readonly ActionCreator<any, any>[]
>[];

export const initialOrderAddressFormState = (id: string) =>
  createProgressiveFormGroupState<FormAddress>(id, {
    street: '',
    communicationNumber: NaN,
    houseNumberAddition: '',
    postalCode: '',
    city: '',
    countryCode: 'NL',
    addressType: AddressTypes.ADDRESS,
  });

export const initialOrderAddressState = (id: string): OrderAddressState => ({
  progressiveForm: initialOrderAddressFormState(id),
});

const validateFormGroupState = (state: OrderAddressState) => {
  const isAddress = (addressType: `${AddressTypes}`) =>
    addressType === AddressTypes.ADDRESS;

  const { value } = state.progressiveForm.formState;

  return updateGroup<FormAddress>({
    postalCode: validateSequential(required, isPostalCode),
    communicationNumber: validateSequential(required, isNumeric),
    houseNumberAddition: validateSequential(
      useValidatorIf(maxLength(6), isAddress(value.addressType))
    ),
    street: validateSequential(
      useValidatorIf(required, isAddress(value.addressType))
    ),
    city: validateSequential(required),
  })(state.progressiveForm.formState);
};

const _reducer = (id: string, ...additionalOnFns: AdditionalOnFns) =>
  createProgressiveNgrxFormReducerWithFormStateUpdate(
    initialOrderAddressState(id),
    validateFormGroupState,
    ...additionalOnFns
  );

export const EplusOrderAddressFormReducer =
  (id: string, ...additionalOnFns: AdditionalOnFns) =>
  (state = initialOrderAddressState(id), action: Action) =>
    _reducer(id, ...additionalOnFns)(state, action);
