import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InnogyComponentRendering } from '@core/jss-models';
import { getDropTreeValue, getFieldValue } from '@core/jss-utils';
import type { ImageField } from '@sitecore-jss/sitecore-jss-angular';

@Component({
  selector: 'wl-how-to-questionnaire-option',
  templateUrl: './how-to-questionnaire-option.component.html',
  styleUrls: [
    './how-to-questionnaire-option.component.ed.scss',
    './how-to-questionnaire-option.component.essent.scss',
  ],
})
export class HowToQuestionnaireOptionComponent {
  @Input() rendering?: InnogyComponentRendering;
  @Input() active = false;

  @Output() selected: EventEmitter<string> = new EventEmitter();

  breakpointMd = true;

  get title(): string {
    return getFieldValue<string>(this.rendering, 'Title', '');
  }

  get image(): ImageField {
    return getFieldValue<ImageField>(this.rendering, 'Image', {});
  }

  get nextQuestion(): InnogyComponentRendering | undefined {
    return getDropTreeValue<InnogyComponentRendering>(
      this.rendering?.fields,
      'NextQuestion'
    );
  }

  get icon(): InnogyComponentRendering | undefined {
    return this.rendering?.fields?.['Icon'] as
      | InnogyComponentRendering
      | undefined;
  }

  onSelect() {
    this.selected.emit(
      (this.nextQuestion as InnogyComponentRendering & { id: string })?.id
    );
  }
}
