<div
  class="offer-dropdown d-inline-block border py-1"
  [class.offer-dropdown__revealed]="dropdownState"
>
  <button
    class="px-2 btn btn-link text-decoration-none m-0 p-0 offer-dropdown-title d-flex justify-content-between align-items-center w-100"
    wlTrackLink
    [trackLinkInfo]="{
      rendering: rendering,
      field: rendering?.fields?.DropdownTitle?.value,
      type: 'button',
      eventType: 'button-click',
    }"
    [attr.aria-expanded]="dropdownState"
    aria-controls="offer-dropdown"
    wlAutomationId="commodity-offer-dropdown"
    (click)="toggle()"
  >
    <span *scText="rendering?.fields?.DropdownTitle"></span>
    <fa-icon
      class="offer-dropdown-title__icon ms-2"
      [icon]="dropdownState ? 'angle-up' : 'angle-down'"
    ></fa-icon>
  </button>

  <div
    id="offer-dropdown"
    class="offer-dropdown__content mt-3 mb-2 text-start"
    [ngClass]="dropdownState ? '' : 'offer-dropdown__content--hide'"
    [attr.aria-hidden]="!dropdownState"
  >
    <div class="d-block">
      <div class="px-2">
        <!-- electricity dropdown vm-->
        <table class="w-100" *ngIf="electricityDropdownVM">
          <tbody>
            <tr>
              <td colspan="2">
                <strong
                  *scText="rendering?.fields?.ElectricityFlexibleRateLabel"
                ></strong>
              </td>
            </tr>
            <tr
              *ngFor="let tariffPeriod of electricityDropdownVM?.tariffPeriods"
            >
              <td [innerHTML]="tariffPeriod.description | safeHtml"></td>
              <td class="text-end align-top">
                {{ tariffPeriod.price | currency: 'EUR' : 'symbol' : '1.5' }}
              </td>
            </tr>
            <tr class="mb-1">
              <td>
                <em *scText="rendering?.fields?.FixedRateLabel"></em>
              </td>
              <td class="text-end align-top">
                {{
                  electricityDropdownVM.fixedDeliveryCosts
                    | currency: 'EUR' : 'symbol' : '1.5'
                }}
              </td>
            </tr>
            <ng-container
              *ngIf="electricityDropdownVM.fixedRedeliveryCostsScale"
            >
              <tr>
                <td colspan="2">
                  <strong *scText="rendering?.fields?.RedeliveryTitle"></strong>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  {{ electricityDropdownVM.fixedRedeliveryCostsScale }}
                </td>
              </tr>
              <tr>
                <td>
                  <em *scText="rendering?.fields?.RedeliveryCostsLabel"></em>
                </td>
                <td class="text-end align-top">
                  {{
                    electricityDropdownVM.fixedRedeliveryCosts
                      | currency: 'EUR' : 'symbol' : '1.5'
                  }}
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
        <!-- gas dropdown vm-->
        <table class="w-100 mt-2" *ngIf="gasDropdownVM">
          <tbody>
            <tr>
              <td colspan="2">
                <strong
                  *scText="rendering?.fields?.GasFlexibleRateLabel"
                ></strong>
              </td>
            </tr>
            <tr
              *ngFor="
                let tariffPeriod of gasDropdownVM?.tariffPeriods;
                trackBy: trackByTariffPeriod
              "
            >
              <td [innerHTML]="tariffPeriod.description | safeHtml"></td>
              <td class="text-end align-top">
                {{ tariffPeriod.price | currency: 'EUR' : 'symbol' : '1.5' }}
              </td>
            </tr>
            <tr class="mb-1">
              <td>
                <em *scText="rendering?.fields?.FixedRateLabel"></em>
              </td>
              <td class="text-end align-top">
                {{
                  gasDropdownVM.fixedDeliveryCosts
                    | currency: 'EUR' : 'symbol' : '1.5'
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <hr />
      <div class="px-2">
        <p *scText="rendering?.fields?.WithBTWText"></p>
        <div class="text-center mt-3">
          <wl-vertical-offer-trigger-aom-button
            [rendering]="rendering"
            (showTariffs)="showTarifs.emit(offer)"
          ></wl-vertical-offer-trigger-aom-button>
        </div>
      </div>
    </div>
  </div>
</div>
