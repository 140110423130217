import { Component } from '@angular/core';

import { BasicContentVariantInheritableComponent } from '../basic-content-variant.inheritable.component';

@Component({
  selector: 'wl-basic-content-variant-minimal',
  templateUrl: './basic-content-variant-minimal.component.html',
  styleUrls: [],
})
export class BasicContentVariantMinimalComponent extends BasicContentVariantInheritableComponent {}
