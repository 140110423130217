import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { GenericModalPosition } from '@innogy/common-ui/shared/interfaces';
import { IAdvizeService } from '@innogy/core/chatting';

import { GenericModalComponent } from '../generic-modal.component';
import { initializeGenericModal } from './generic-modal-helpers';
import { closeGenericModal, openGenericModal } from './generic-modal.actions';

@Injectable()
export class GenericModalEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly modalService: NgbModal,
    @Inject(DOCUMENT) protected readonly document: Document,
    private readonly iAdvizeService: IAdvizeService
  ) {}

  public readonly openGenericModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(openGenericModal),
        tap((action) => {
          this.modalService.dismissAll();
          const modal = this.modalService.open(GenericModalComponent, {
            windowClass:
              (action.position ?? GenericModalPosition.CENTER) +
              ((action.backdrop ?? true) ? '' : ' pe-none'),
            keyboard: true,
            modalDialogClass: action.backdrop === false ? 'modal-shadow' : '',
            backdrop: action.backdrop ?? true,
            animation: action.animation ?? true,
          });
          const bodyElement = this.document.body;
          if (bodyElement && action.backdrop === false) {
            bodyElement.classList.add('modal-backdrop--hidden');
          }

          initializeGenericModal(modal, action);

          // Trigger chatbot service to scan the page for new actions
          if (action.chatbotSelectorClass) {
            this.iAdvizeService.scanCurrentPage();
          }
        })
      ),
    { dispatch: false }
  );

  public readonly closeGenericModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(closeGenericModal),
        tap(() => {
          const bodyElement = this.document.body;
          if (bodyElement) {
            bodyElement.classList.remove('modal-backdrop--hidden');
          }
        })
      ),
    { dispatch: false }
  );
}
