import type { StoreProductData } from '@innogy/eplus/temporary-core-modules';
import { createAction, props } from '@ngrx/store';
import type { ProgressiveFormFormState } from '@innogy/shared/progressive-form/sitecore/models';
import type { FormGroupControls } from 'ngrx-forms';

const feature = 'Store';

export const submitStoreOrderAction = createAction(
  `[${feature} Funnel] Submit Order`,
  props<{
    formControls: FormGroupControls<ProgressiveFormFormState>;
    apiIntegrationPath: string;
    trackingId: string;
    selectedProducts: StoreProductData[];
  }>()
);

export const trackStoreOrderFormCompleteAction = createAction(
  `${feature} Track tool-complete`,
  props<{
    formControls: FormGroupControls<ProgressiveFormFormState>;
    toolName: string;
    stepCount: number;
  }>()
);
