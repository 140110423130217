import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

/**
 * Given a byte size input returns a string
 * formatted value of B, KB or MB depending on
 * size.
 */
@Pipe({
  name: 'fileSize',
})
export class FileSizePipe implements PipeTransform {
  transform(size: number | string): string {
    if (typeof size !== 'number') {
      size = parseFloat(size);
    }

    if (size < 0 || isNaN(size)) {
      return 'unknown';
    }

    if (size < 1024) {
      return size + 'B';
    }

    if (size < 1024 * 1024) {
      return (size / 1024).toFixed() + 'KB';
    }

    return (size / (1024 * 1024)).toFixed(1) + 'MB';
  }
}
