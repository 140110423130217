<div class="marketing-heading mb-essent-5">
  <div
    class="marketing-heading__heading"
    [ngClass]="'marketing-heading__heading' + theme"
  >
    <h1 class="p-2 pb-essent-0 m-0" *scText="rendering?.fields?.Heading"></h1>
  </div>
  <div
    class="marketing-heading__sub-heading"
    [ngClass]="'marketing-heading__sub-heading' + theme"
  >
    <div
      class="marketing-heading__sub-heading-background"
      [ngClass]="'marketing-heading__sub-heading-background' + theme"
    >
      <h2
        class="p-2 pt-essent-0 m-0"
        *scText="rendering?.fields?.SubHeading"
      ></h2>
    </div>
  </div>
</div>
