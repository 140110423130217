import type { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { defer, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { SettingsService } from '../settings.service';
import * as actions from './settings.actions';

@Injectable()
export class SettingsEffects {
  loadSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.SettingsStateLoad>(actions.SettingsStateActionTypes.LOAD),
      switchMap(() =>
        this.settingsService.getSettings().pipe(
          map((res) => new actions.SettingsStateLoaded(res)),
          catchError((error: unknown) =>
            of(new actions.SettingsStateHttpError(error as HttpErrorResponse))
          )
        )
      )
    )
  );

  // Should be your last effect
  init$ = createEffect(() =>
    defer(() => {
      return of(new actions.SettingsStateLoad());
    })
  );

  constructor(
    private readonly settingsService: SettingsService,
    private readonly actions$: Actions
  ) {}
}
