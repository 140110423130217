<div
  ngbAccordion
  wlTrackImpression="accordion-impression"
  [trackImpressionInfo]="{
    componentName: title || '',
  }"
  wlTrackAccordion
  [trackAccordionInfo]="{
    subject: 'Totaal verwachte kosten per jaar',
    name: title || '',
    placement: rendering,
  }"
>
  <div
    ngbAccordionItem
    id="{{ title || 'unknown' }}-accordion-0"
    class="accordion-item--dark-header mb-1"
  >
    <div ngbAccordionHeader class="accordion-header">
      <button ngbAccordionButton class="accordion-button">
        <wl-partial-accordion-title
          [icon]="faIcon"
          [title]="title"
          [after]="
            priceOverview?.totalPrice?.expectedYearlyAmount
              | currency: 'EUR' : 'symbol' : '1.2'
          "
        />
      </button>
    </div>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody>
        <ng-template>
          <div ngbAccordion class="m-0">
            <div
              ngbAccordionItem
              class="accordion-item--light-header nested-accordion m-0"
              *ngFor="
                let pricegroup of priceOverview?.priceGroups;
                let i = index;
                trackBy: trackByPricegroup
              "
              wlTrackImpression="accordion-impression"
              [trackImpressionInfo]="{
                componentName: pricegroup.description,
              }"
              wlTrackAccordion
              [trackAccordionInfo]="{
                subject: title || '',
                name: pricegroup.description,
                placement: rendering,
              }"
            >
              <div ngbAccordionHeader class="accordion-header">
                <button ngbAccordionButton class="accordion-button">
                  <wl-partial-accordion-title
                    [title]="pricegroup.description"
                    [after]="
                      pricegroup?.totalPrice?.expectedYearlyAmount
                        | currency: 'EUR' : 'symbol' : '1.2'
                    "
                  />
                </button>
              </div>
              <div ngbAccordionCollapse>
                <div ngbAccordionBody>
                  <ng-template>
                    <div
                      class="p-2 d-inline-flex w-100"
                      *ngFor="
                        let price of pricegroup.prices;
                        trackBy: trackByPrice
                      "
                    >
                      <div class="me-2">
                        <p class="m-0">
                          {{ price.description }}
                        </p>
                        <p class="m-0 text-muted">
                          {{ calculateYearlyUnitAmount(price) }}
                          {{ price.unit }} x
                          {{
                            price.unitPrice | currency: 'EUR' : 'symbol' : '1.5'
                          }}
                        </p>
                      </div>
                      <div class="ms-auto">
                        {{
                          price.expectedPeriodAmount
                            | currency: 'EUR' : 'symbol' : '1.2'
                        }}
                      </div>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
