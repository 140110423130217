import { OfferErrorEffects } from './offer-error.effects';
import { OfferTrackingEffects } from './offer-tracking.effects';
import { OfferEffects } from './offer.effects';

export * from './offer.actions';
export * from './offer.reducer';
export * from './offer.selectors';

export const offerEffects = [
  OfferErrorEffects,
  OfferTrackingEffects,
  OfferEffects,
];
