import { createSelector } from '@ngrx/store';

import { getPaymentDetailsState } from './index';
import { getPaymentMethod } from './payment-details.selectors';

export const getChangeIbanFormState = createSelector(
  getPaymentDetailsState,
  (state) => state.changeIbanForm.changeIbanFormState
);

export const getUpdateIbanPayload = createSelector(
  getChangeIbanFormState,
  getPaymentMethod,
  (state, incomingPaymentMethod) => ({
    iban: state.value.iban,
    incomingPaymentMethod,
  })
);
