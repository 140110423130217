import { NgModule, Optional, SkipSelf } from '@angular/core';
import { combineEffectsWithProviders } from '@essent/common';
import { EffectsModule } from '@ngrx/effects';

import { postLeadFormApi } from './post-lead-form/post-lead-form.config';

const { EFFECTS, PROVIDERS } = combineEffectsWithProviders(postLeadFormApi);

@NgModule({
  imports: [EffectsModule.forFeature(EFFECTS)],
  providers: [...PROVIDERS],
})
export class EmobilityLeadServicesModule {
  public constructor(
    @Optional()
    @SkipSelf()
    parentModule: EmobilityLeadServicesModule
  ) {
    if (parentModule) {
      throw new Error(
        'EmobilityLeadServicesModule is imported more than once.'
      );
    }
  }
}
