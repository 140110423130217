import { Injectable } from '@angular/core';
import type { PrefillSingleFieldFormActionType } from '@innogy/shared/forms';
import { prefillSingleFieldFormAction } from '@innogy/shared/forms';
import { isNotNullish } from '@innogy/utils-rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import type { MemoizedSelector } from '@ngrx/store';
import { Store } from '@ngrx/store';
import {
  AddArrayControlAction,
  RemoveArrayControlAction,
  ResetAction,
  SetValueAction,
} from 'ngrx-forms';
import type { Observable } from 'rxjs';
import { combineLatest } from 'rxjs';
import { filter, map, switchMap, withLatestFrom } from 'rxjs/operators';

import * as actions from './+state/progressive-form.actions';
import { generateSelectors } from './+state/progressive-form.selectors';

interface SkipRule<TField> {
  stepId: string;
  fieldSelector: MemoizedSelector<object, TField | undefined>;
  stepsToSkip: string[];
  skipWhen: (fieldValue: TField) => boolean;
}
@Injectable({ providedIn: 'root' })
export class ProgressiveFormService {
  constructor(
    private readonly store$: Store,
    private readonly actions$: Actions
  ) {}

  // Actions
  openFormStep = actions.openFormStep;
  setActiveFormStep = actions.setActiveFormStep;
  cancelEditingFormStep = actions.cancelEditingFormStep;
  resetProgressiveForm = actions.resetProgressiveForm;
  setFormConfigAction = actions.setFormConfig;
  continueFromLastStepAction = actions.continueFromLastStep;
  submitFormStep = actions.submitFormStep;
  setValueAndSubmit = actions.setValueAndSubmit;
  updateSkipFormSteps = actions.updateSkipFormSteps;
  initProgressiveForm = actions.initProgressiveForm;
  updateCompletedFormSteps = actions.updateCompletedFormSteps;

  // Selectors
  readonly selectors = generateSelectors();
  progressOnValidSubmit$ = (formId: string) =>
    this.store$.select(this.selectors.progressOnValidSubmit(formId));
  currentActiveFormStepId$ = (formId: string) =>
    this.store$.select(this.selectors.getCurrentActiveStepId(formId));
  isEditingPreviouslySubmittedForm$ = (formId: string) =>
    this.store$.select(
      this.selectors.getIsEditingPreviouslySubmittedForm(formId)
    );
  formSteps$ = (formId: string) =>
    this.store$.select(this.selectors.getFormSteps(formId));

  isSkipped$ = (formId: string, stepId?: string) =>
    this.store$
      .select(this.selectors.getSkippedFormSteps(formId))
      .pipe(
        map((skippedSteps) => stepId != null && skippedSteps?.includes(stepId))
      );

  totalSteps$ = (formId: string) =>
    this.store$
      .select(this.selectors.getFormSteps(formId))
      .pipe(map((steps) => steps?.length));

  currentStepPresentableNumber$ = (formId: string, stepId?: string) =>
    this.store$
      .select(this.selectors.getFormSteps(formId))
      .pipe(
        map((steps) =>
          stepId != null && steps != null ? steps.indexOf(stepId) + 1 : NaN
        )
      );

  // Helper functions
  isEditingFormStep$ = (
    formId: string,
    formStepId?: string
  ): Observable<boolean> => {
    return this.currentActiveFormStepId$(formId).pipe(
      withLatestFrom(this.isEditingPreviouslySubmittedForm$(formId)),
      map(
        ([currentActiveStepId, isEditing]): boolean =>
          !!isEditing && currentActiveStepId === formStepId
      )
    );
  };

  isPreviousStep$ = (formId: string, step?: string) => {
    return combineLatest([
      this.formSteps$(formId),
      this.currentActiveFormStepId$(formId),
    ]).pipe(
      map(([steps, activeStep]) =>
        step != null && activeStep != null && steps != null
          ? steps?.indexOf(activeStep) > steps?.indexOf(step)
          : false
      )
    );
  };

  isFutureStep$ = (formId: string, step?: string): Observable<boolean> => {
    return combineLatest([
      this.formSteps$(formId),
      this.currentActiveFormStepId$(formId),
    ]).pipe(
      map(([steps, activeStep]) =>
        step != null && activeStep != null && steps != null
          ? steps.indexOf(activeStep) < steps.indexOf(step)
          : false
      )
    );
  };

  public createSkipStepsEffectGenerator(formId: string) {
    return <TFieldType>(skipRule: SkipRule<TFieldType>) => {
      return createEffect(() =>
        this.actions$.pipe(
          ofType<
            | SetValueAction<any>
            | AddArrayControlAction<any>
            | RemoveArrayControlAction
            | PrefillSingleFieldFormActionType
          >(
            SetValueAction.TYPE,
            AddArrayControlAction.TYPE,
            RemoveArrayControlAction.TYPE,
            prefillSingleFieldFormAction
          ),
          filter((action) => `${skipRule.stepId}.field` === action.controlId),
          withLatestFrom(this.store$.select(skipRule.fieldSelector)),
          map(([_, stepValue]) => stepValue),
          filter(isNotNullish),
          switchMap((stepValue) => [
            this.updateSkipFormSteps({
              formId,
              stepIds: skipRule.stepsToSkip,
              state: skipRule.skipWhen(stepValue),
            }),
            ...(skipRule.skipWhen(stepValue)
              ? skipRule.stepsToSkip.map((step) => new ResetAction(step))
              : []),
          ])
        )
      );
    };
  }
}
