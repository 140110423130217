<wl-header-tools
  [navItems]="navItems$ | async"
  [showCategories]="showCategories$ | async"
  [isLoggedIn]="isLoggedIn$ | async"
  [hasMultipleAccounts]="hasMultipleAccounts$ | async"
  [fullName]="fullName$ | async"
  [title]="title$ | async"
  [loginUrlLabel]="loginUrlLabel"
  [loginUrl]="loginUrl"
  [logOutButtonText]="logoutButtonText$ | async"
  [logoutEndpointUrl]="logoutEndpointUrl"
  [switchAccountButtonText]="switchAccountButtonText$ | async"
  [switchAccountEndpointUrl]="switchAccountEndpointUrl"
  (navItemClick)="navItemClick.emit($event)"
></wl-header-tools>
