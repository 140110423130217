import { CommonModule } from '@angular/common';
import { Inject, NgModule } from '@angular/core';
import { Angulartics2Module } from 'angulartics2';

import type { AnalyticsEnhancer } from './provider/analytics-enhancer.model';
import { ROOT_ANALYTICS_ENHANCERS } from './provider/analytics-enhancer.model';
import { AnalyticsEnhancerSources } from './provider/analytics-enhancer-sources.service';

@NgModule({
  imports: [CommonModule, Angulartics2Module.forRoot()],
  exports: [Angulartics2Module],
})
export class CoreAnalyticsRootModule {
  constructor(
    private readonly sources: AnalyticsEnhancerSources,
    @Inject(ROOT_ANALYTICS_ENHANCERS) rootEnhancers: AnalyticsEnhancer[]
  ) {
    rootEnhancers.forEach((enhancerSourceInstance) =>
      sources.addEnhancers(enhancerSourceInstance)
    );
  }

  addEnhancers(enhancerSourceInstance: AnalyticsEnhancer) {
    this.sources.addEnhancers(enhancerSourceInstance);
  }
}
