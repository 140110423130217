import { Status, type AsyncState } from '@essent/common';
import {
  getTimeslotsSuccess,
  type GetTimeslotsResponse,
} from '@innogy/eplus/temporary-core-modules';
import { createReducer, on } from '@ngrx/store';

import { setTimeslotDay } from './sustainability.actions';

export const sustainabilityTimeslotsId = 'timeslots';

export type SustainabilityTimeslotsState = {
  selectedDay: string;
  apiState: AsyncState<GetTimeslotsResponse>;
};

export const initialSustainabilityTimeslotsState = {
  selectedDay: '',
  apiState: { status: Status.IDLE },
};

export const sustainabilityTimeslotsReducer =
  createReducer<SustainabilityTimeslotsState>(
    initialSustainabilityTimeslotsState,
    on(getTimeslotsSuccess, (state, params) => ({
      ...state,
      apiState: {
        status: Status.SUCCESS,
        data: params.payload,
      },
    })),
    on(setTimeslotDay, (state, param) => ({
      ...state,
      selectedDay: param.day,
    }))
  );
