import { CustomerStatusGuard } from '@innogy/customer/store';
import type { ComponentNameAndModule } from '@sitecore-jss/sitecore-jss-angular';

const loadChildrenFunc = () =>
  import('./lib/common-ui.lazy.module').then(
    (module) => module.CommonUiLazyModule
  );

const defineImport = (path: string) => ({
  path,
  loadChildren: loadChildrenFunc,
});

const commonUiLazyImports: ComponentNameAndModule[] = [
  defineImport('RowContainer'),
  defineImport('RowContainerSolo'),
  defineImport('SingleColumn'),
  defineImport('TwoColumn39'),
  defineImport('TwoColumn48'),
  defineImport('TwoColumn66'),
  defineImport('ThreeColumn336'),
  defineImport('ThreeColumn444'),
  defineImport('FourColumn3333'),
  defineImport('MyEnvironmentGridThreeColumns'),
  defineImport('MyEnvironmentGridTwoColumns'),
  defineImport('FileList'),
  defineImport('TabsComponent'),
  defineImport('Button'),
  defineImport('Accordion'),
  defineImport('Anchor'),
  defineImport('IconLink'),
  defineImport('ContentBlock'),
  defineImport('Video'),
  defineImport('Tooltip'),
  defineImport('HorizontalLinksList'),
  defineImport('MultipleLinksList'),
  {
    ...defineImport('MijnInformationBlock'),
    canActivate: [CustomerStatusGuard],
  },
  defineImport('RichText'),
  defineImport('BackButtonSidebar'),
  defineImport('BackButtonMobile'),
  defineImport('Carousel'),
  defineImport('InfoCard'),
  defineImport('TopTasks'),
  defineImport('Availability'),
  defineImport('Taxonomy'),
  defineImport('FaqContainer'),
  defineImport('Breadcrumb'),
  defineImport('DynamicRow'),
  defineImport('HowToQuestionnaire'),
  defineImport('HowToReadBill'),
  defineImport('IconList'),
  defineImport('IconListItem'),
  defineImport('Image'),
  defineImport('BasicContent'),
  defineImport('Jumbotron'),
  defineImport('List'),
  defineImport('StandaloneProductOverview'),
  defineImport('GenericForm'),
  defineImport('BlogIndex'),
  defineImport('CardContentBlock'),
  defineImport('InformationBar'),
  defineImport('Timer'),
  defineImport('TimerWidget'),
];
export { commonUiLazyImports };
