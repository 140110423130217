export function getAccountHolderString(
  initials: string | undefined,
  prefix: string | undefined,
  lastName: string | undefined
) {
  return `${initials || ''} ${prefix || ''} ${lastName || ''}`.replace(
    '  ',
    ' '
  );
}
