import type { NgrxValueConverter } from 'ngrx-forms';

import { normalizeDateString } from '../../dates/date.helper';

export type DateConverter = NgrxValueConverter<string | null, string | null>;

export const dateConverter: DateConverter = {
  // normalizeDateString will convert a valid dd-MM-yyyy date to yyyy-MM-dd, this format will be saved from the state
  convertViewToStateValue: (value: string) => normalizeDateString(value),
  // The value coming from the state should already be in yyyy-MM-dd format, normalizeDateString is just used
  // as a precaution. The view can handle this format as well, in the view the datepicker.adapter.ts code
  // will format the yyyy-MM-dd value to be displayed as dd-MM-yyyy.
  convertStateToViewValue: (value: string) => normalizeDateString(value),
};
