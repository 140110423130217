import { createSelector } from '@ngrx/store';

import { eplusSolarPanelsFeature } from '../solar-panels-form.state';
import { solarPanelsApiKey } from './solar-panels-api.reducer';

const selectSolarPanelsApiState = createSelector(
  eplusSolarPanelsFeature,
  (state) => state[solarPanelsApiKey]
);

/**
 * Address check endpoint
 */

export const selectGetSolarPanelsNewCustomerAddressCheckState = createSelector(
  selectSolarPanelsApiState,
  (state) => state.getSolarPanelsNewCustomerAddressCheck
);

export const selectGetSolarPanelsNewCustomerAddressCheckData = createSelector(
  selectGetSolarPanelsNewCustomerAddressCheckState,
  (state) => state.data
);

export const selectSolarPanelsProductsWithCalculations = createSelector(
  selectGetSolarPanelsNewCustomerAddressCheckData,
  (state) => state?.products
);

/**
 * Product endpoint
 */

export const selectGetSolarPanelsProductsState = createSelector(
  selectSolarPanelsApiState,
  (state) => state.getSolarPanelsProducts
);

/**
 * Confirmation endpoint
 */

export const selectPostSolarPanelsConfirmationState = createSelector(
  selectSolarPanelsApiState,
  (state) => state.postSolarPanelsNewCustomerConfirmation
);
