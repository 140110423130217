import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'wl-commodity-offer-view-tariffs',
  templateUrl: './commodity-offer-view-tariffs.component.html',
})
export class CommodityOfferViewTariffsComponent {
  @Input() tariffModalDescriptionLabel?: string;
  @Input() tariffModalLinkTextLabel?: string;
  @Output() viewTariffs = new EventEmitter<void>();
}
