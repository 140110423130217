import { Component, Input } from '@angular/core';
import { type FormGroupControls, FormGroupState } from 'ngrx-forms';

import { UsageQuestionnaireProps } from './usage-questionnaire.model';
import type { UsageQuestionnaireFormValues } from './state/usage-questionnaire.reducer';

@Component({
  selector: 'wl-usage-questionnaire',
  templateUrl: './usage-questionnaire.component.html',
})
export class UsageQuestionnaireComponent {
  @Input({ required: true })
  formState!: FormGroupState<UsageQuestionnaireFormValues>;
  @Input() rendering?: UsageQuestionnaireProps;
  @Input() gasHidden = false;

  get formControls(): FormGroupControls<UsageQuestionnaireFormValues> {
    return this.formState.controls;
  }

  get energyTypeDropdownShown(): boolean {
    return (
      this.formState.userDefinedProperties['supplyTypeRequired'] &&
      !this.gasHidden
    );
  }
}
