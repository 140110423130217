import { Status } from '@essent/common';
import type { PostQuotationReactionResponse } from '@essent/contract';
import { createSelector } from '@ngrx/store';

import {
  getContractingState,
  quotationReactionSelectorKey,
} from '../contracting-core.selectors';

const getQuotationReactionResponse = createSelector(
  getContractingState,
  (state) => state[quotationReactionSelectorKey].postQuotationReaction
);

const getQuotationReactionMessage = createSelector(
  getContractingState,
  (state) => state[quotationReactionSelectorKey].quotationReactionMessage
);

const getQuotationReaction = createSelector(
  getQuotationReactionResponse,
  ({ data, error }) =>
    (data || error?.error) as PostQuotationReactionResponse | undefined
);

export const isQuotationReactionLoading = createSelector(
  getQuotationReactionResponse,
  (state) => [Status.PENDING, Status.IDLE].includes(state.status)
);

export const getQuotationReactionVM = createSelector(
  getQuotationReaction,
  getQuotationReactionMessage,
  isQuotationReactionLoading,
  (response, { message: { title, message }, messageType: type }, loading) => ({
    loading,
    code: response?.messages.code,
    title,
    message,
    type,
  })
);
