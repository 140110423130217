import { EnergyType } from '@essent/new-customer';
import type {
  Offer,
  OfferOverview,
} from '@innogy/become-a-customer/shared/interfaces';
import { createSelector } from '@ngrx/store';

import { getBecomeACustomerState } from '../../become-a-customer-feature.selector';

export const getOffersState = createSelector(
  getBecomeACustomerState,
  (state) => state.offers
);

export const getAcquisitionOffers = createSelector(
  getOffersState,
  (state) => state.offersets[0]?.offers
);

/**
 * Get the current active offer ids
 */
export const getActiveOfferids = createSelector(
  getOffersState,
  (state) => state.activeOfferIds
);

/**
 * Get the current active offerset
 *
 * @return active offerset, undefined when no offerset is active
 */
export const getActiveOfferset = createSelector(getOffersState, (state) =>
  state.offersets.find((set) => set.offerset === state.activeOfferset)
);

/**
 * Get all offers based on the current active type and active ids in the state
 */
export const getActiveOffers = createSelector(
  getActiveOfferids,
  getActiveOfferset,
  (activeOfferIds, activeOfferset) => {
    if (!activeOfferset) {
      return [];
    }

    return activeOfferIds.reduce<Offer[]>((result, offerId) => {
      const offer = activeOfferset.offers.find((o) => o.offerId === offerId);

      if (!offer) {
        return result;
      }
      return [...result, offer];
    }, []);
  }
);

export const getFirstActiveOffer = createSelector(
  getActiveOffers,
  (offers) => offers[0]
);

export const getActiveDuration = createSelector(
  getOffersState,
  (state) => state.activeDuration
);

export const getActiveOffersetPresentDurations = createSelector(
  getActiveOfferset,
  (offerSet) => {
    if (!offerSet) {
      return [];
    }

    const { durations, offers } = offerSet;

    // Filter durations based on whether there are actual offers present for this duration
    // otherwise, do not retrieve them.
    // This prevents durations without offers from being selectable in offer-card components.
    return durations.present.filter((getOfferDuration) =>
      offers.some((offer) => offer.duration === getOfferDuration.duration)
    );
  }
);

export const getActiveOffersetRetrievedDurations = createSelector(
  getActiveOfferset,
  (offerSet) => (offerSet ? offerSet.durations.retrieved : [])
);

export const getPropositionOfferStatus = createSelector(
  getOffersState,
  (state) => state.propositionOffer.status
);

export const getPropositionOffer = createSelector(
  getOffersState,
  (state) => state.propositionOffer.offer
);

export const getPropositionDuration = createSelector(
  getPropositionOffer,
  getActiveOfferset,
  (propositionOffer, activeOfferset) => {
    const duration = propositionOffer?.duration;

    return activeOfferset?.durations.present.find(
      (d) => d.duration === duration
    );
  }
);

export const getElectricityOverview = createSelector(
  getPropositionOffer,
  (selectedOffer): OfferOverview | undefined => {
    return selectedOffer?.offerOverviews.find(
      (o) => o.energyType === EnergyType.ELECTRICITY
    );
  }
);

export const getGasOverview = createSelector(
  getPropositionOffer,
  (selectedOffer): OfferOverview | undefined => {
    return selectedOffer?.offerOverviews.find(
      (o) => o.energyType === EnergyType.GAS
    );
  }
);
