import { createAction, props } from '@ngrx/store';

import type { GenericLightboxProps } from '../generic-lightbox.model';

export const openGenericLightbox = createAction(
  '[Generic Lightbox] Open',
  props<GenericLightboxProps>()
);

export const closeGenericLightbox = createAction('[Generic Lightbox] Close');
