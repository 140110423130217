import { Component, Input } from '@angular/core';

import { ToolTip } from './tooltip.model';

@Component({
  selector: 'wl-tooltip',
  templateUrl: './tooltip.component.html',
})
export class ToolTipComponent {
  @Input()
  tooltip?: ToolTip;
  @Input()
  color: 'primary' | 'secondary' | 'dark' | 'white' = 'primary';
  @Input()
  style: 'light' | 'solid' = 'light';

  isOpen = false;

  get tooltipColorCssClass(): string {
    return `text-${this.color}`;
  }
}
