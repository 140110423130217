import type { OnInit } from '@angular/core';
import {
  Directive,
  HostListener,
  Inject,
  Input,
  Optional,
} from '@angular/core';
import { ActionsSubject } from '@ngrx/store';
import type { KeyValue } from 'ngrx-forms';

import type { Actions } from '../actions';
import {
  InactivateFormStepAction,
  ActivateRestorationPointAction,
  RestoreFormStateAction,
} from '../actions';
import { ProgressiveFormGroupState } from '../state';

/**
 * Takes care of dispatching the right actions when a form step has been edited, yet the back-up state should be restored over the form state.
 * Useful when providing a 'undo changes' button in the UI.
 */
@Directive({
  selector:
    // eslint-disable-next-line @angular-eslint/directive-selector
    'a[role=button][restoreProgressiveFormStep],button[restoreProgressiveFormStep]',
})
export class RestoreProgressiveFormStepDirective<TStateValue extends KeyValue>
  implements OnInit
{
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('restoreProgressiveFormStep')
  state!: ProgressiveFormGroupState<TStateValue>;

  constructor(
    @Optional()
    @Inject(ActionsSubject)
    private readonly actionsSubject: ActionsSubject | null
  ) {
    this.actionsSubject = actionsSubject;
  }

  protected dispatchAction(action: Actions) {
    if (this.actionsSubject !== null) {
      this.actionsSubject.next(action);
    } else {
      throw new Error(
        'ActionsSubject must be present in order to dispatch actions!'
      );
    }
  }

  ngOnInit() {
    if (!this.state) {
      throw new Error('The progressive form step state must not be undefined!');
    }
  }

  @HostListener('click', ['$event'])
  onClick(event: Event) {
    event.preventDefault();
    this.dispatchAction(new RestoreFormStateAction(this.state.id));
    this.dispatchAction(new InactivateFormStepAction(this.state.id));
    this.dispatchAction(new ActivateRestorationPointAction(this.state.id));
  }
}
