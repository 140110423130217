<ng-container [ngSwitch]="mode">
  <ng-container *ngSwitchCase="'full'">
    <ng-container *ngTemplateOutlet="cardFull"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'body'">
    <ng-container *ngTemplateOutlet="cardContent"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'content'">
    <ng-container *ngTemplateOutlet="cardContent"></ng-container>
  </ng-container>
</ng-container>

<ng-template #cardFull>
  <wl-partial-card class="mb-3">
    <ng-container slot="content">
      <ng-container *ngTemplateOutlet="cardContent"></ng-container>
    </ng-container>
  </wl-partial-card>
</ng-template>

<ng-template #cardContent>
  <div
    *ngIf="title || titleTranslationKey"
    class="d-flex align-items-baseline"
    wlAutomationId="error-card-title"
  >
    <fa-icon *ngIf="icon" [icon]="icon" class="me-1"></fa-icon>
    <ng-container *ngIf="title">
      <h3 [class.mb-0]="showDivider" *scText="title" wlCSCapture></h3>
    </ng-container>
    <h3 [class.mb-0]="showDivider" *ngIf="titleTranslationKey" wlCSCapture>
      {{ titleTranslationKey | translate }}
    </h3>
  </div>
  <hr *ngIf="showDivider" />
  <div class="d-flex error-card--message" [wlAutomationId]="automationId">
    <fa-icon class="icon icon--danger" icon="circle-info"></fa-icon>
    <ng-container *ngIf="message">
      <wl-rich-text-wrapper [field]="message">
        <p class="text-small text-start ms-1 mb-0" wlCSCapture></p>
      </wl-rich-text-wrapper>
    </ng-container>
    <p
      class="text-small text-start ms-1 mb-0"
      *ngIf="messageTranslationKey"
      wlCSCapture
    >
      {{ messageTranslationKey | translate: messageTranslationParams }}
    </p>
  </div>
</ng-template>
