import type { ToolTrackingConfig } from '@innogy/core/analytics';
import { createToolStepConfig, parseToolConfig } from '@innogy/core/analytics';

import { selectInsulationWizardAnalyticsPayload } from './insulation-wizard.selectors';

const insulationWizardSteps = [
  {
    step: 0,
    stepName: 'postcode check',
    id: 'wizard',
  },
] as const;

export type InsulationWizardToolTrackingSteps =
  (typeof insulationWizardSteps)[number]['id'];

const insulationWizardToolTrackingConfig: ToolTrackingConfig<InsulationWizardToolTrackingSteps> =
  {
    steps: insulationWizardSteps,
    toolComplete: createToolStepConfig(
      selectInsulationWizardAnalyticsPayload,
      (payload) => ({
        step: 1,
        payload: {
          ['type woning']: payload.buildingType,
        },
      })
    ),
  };

export const parsedInsulationWizardToolTrackingConfig = (toolName?: string) =>
  parseToolConfig({
    ...insulationWizardToolTrackingConfig,
    ...(toolName && { toolName }),
  });
