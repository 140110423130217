import { createNamedApi } from '@essent/common';

import { PostSolarPanelsConfirmationService } from './post-solar-panels-confirmation.service';

export const postSolarPanelsConfirmationApi = createNamedApi(
  '[PostSolarPanelsConfirmationState] Post Solar Panels Confirmation',
  PostSolarPanelsConfirmationService
);

export const {
  postSolarPanelsConfirmationActions,
  postSolarPanelsConfirmationSuccess,
  postSolarPanelsConfirmationClear,
  postSolarPanelsConfirmationError,
  postSolarPanelsConfirmation,
} = postSolarPanelsConfirmationApi;
