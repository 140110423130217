import type { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  Optional,
  Output,
  Renderer2,
} from '@angular/core';
import { ActionsSubject } from '@ngrx/store';
import type { KeyValue } from 'ngrx-forms';
import { MarkAsSubmittedAction } from 'ngrx-forms';

import type { Actions } from '../actions';
import {
  ActivateRestorationPointAction,
  InactivateFormStepAction,
  MarkAsyncTasksAsPendingAction,
  SubmitFormStepAction,
} from '../actions';
import { ProgressiveFormGroupState } from '../state';

/**
 * Takes care of submitting of progressive form steps in a predictable way.
 */
@Directive({
  selector:
    // eslint-disable-next-line @angular-eslint/directive-selector
    'a[role=button][submitProgressiveFormStep],button[submitProgressiveFormStep]',
})
export class SubmitProgressiveFormStepDirective<TStateValue extends KeyValue>
  implements OnInit, OnChanges
{
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('submitProgressiveFormStep')
  state!: ProgressiveFormGroupState<TStateValue>;

  @Input()
  asyncSubmission = false;

  @Input()
  updateFormState = false;

  @Output()
  submitted = new EventEmitter();

  constructor(
    @Optional()
    @Inject(ActionsSubject)
    private readonly actionsSubject: ActionsSubject | null,
    private readonly el: ElementRef,
    private readonly renderer: Renderer2
  ) {
    this.actionsSubject = actionsSubject;
  }
  protected dispatchAction(action: Actions) {
    if (this.actionsSubject !== null) {
      this.actionsSubject.next(action);
    } else {
      throw new Error(
        'ActionsSubject must be present in order to dispatch actions!'
      );
    }
  }

  ngOnInit() {
    if (!this.state) {
      throw new Error('The progressive form step state must not be undefined!');
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const oldState: ProgressiveFormGroupState<TStateValue> =
      changes['state']?.previousValue;
    const newState: ProgressiveFormGroupState<TStateValue> =
      changes['state']?.currentValue;

    if (!oldState || !newState) {
      return;
    }

    this.updateDisabledState(oldState, newState);

    if (this.asyncSubmission) {
      this.dispatchSubmissionIfAsyncActionFinished(oldState, newState);
    }
  }

  @HostListener('click', ['$event'])
  onClick(event: Event): void {
    event.preventDefault();

    if (!this.state) {
      return void 0;
    }

    // Mark the formState as submitted to trigger validation.
    this.dispatchAction(new MarkAsSubmittedAction(this.state.id));

    // break out early if our formState is not valid.
    if (!this.state?.formState?.isValid) {
      return void 0;
    }

    // if async submission is enabled, only mark that we're going to start an async task
    if (this.asyncSubmission) {
      this.dispatchAction(new MarkAsyncTasksAsPendingAction(this.state.id));
    } else {
      this.dispatchSubmission(this.state);
    }
    return void 0;
  }

  private dispatchSubmission(state: ProgressiveFormGroupState<TStateValue>) {
    this.submitted.emit();

    this.dispatchAction(new InactivateFormStepAction(state.id));
    this.dispatchAction(new SubmitFormStepAction(state.id));
    if (this.updateFormState) {
      this.dispatchAction(new ActivateRestorationPointAction(state.id));
    }
  }

  private dispatchSubmissionIfAsyncActionFinished(
    oldState: ProgressiveFormGroupState<TStateValue>,
    newState: ProgressiveFormGroupState<TStateValue>
  ) {
    if (oldState.hasPendingAsyncActions && !newState.hasPendingAsyncActions) {
      // Messy workaround to ensure actions are picked up,
      // without waiting for the js queue to flush the actions are not
      // picked up by any reducers :(
      setTimeout(() => this.dispatchSubmission(newState), 0);
    }
  }

  private updateDisabledState(
    oldState: ProgressiveFormGroupState<TStateValue>,
    newState: ProgressiveFormGroupState<TStateValue>
  ) {
    if (
      (!oldState.hasPendingAsyncActions && newState.hasPendingAsyncActions) ||
      (oldState.isSubmittable && newState.isUnsubmittable)
    ) {
      this.renderer.setProperty(this.el.nativeElement, 'disabled', true);
    }
    if (
      (oldState.hasPendingAsyncActions && !newState.hasPendingAsyncActions) ||
      (oldState.isUnsubmittable && newState.isSubmittable)
    ) {
      this.renderer.setProperty(this.el.nativeElement, 'disabled', false);
    }
  }
}
