import { pattern } from 'ngrx-forms/validation';

/**
 * RegExp that represents a valid date.
 */
export const DATE_REGEXP = /^(0[1-9]|[12]\d|3[01])-(0[1-9]|1[012])-\d{4}$/;

/**
 * Validates that the given value matches our pattern for dates

 */
export const isDate = pattern(DATE_REGEXP);
