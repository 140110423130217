import { DomSanitizer } from '@angular/platform-browser';
import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

@Pipe({ name: 'safeHtml' })
export class TrustedHtmlPipe implements PipeTransform {
  constructor(private readonly sanitized: DomSanitizer) {}
  transform(value: string) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}
