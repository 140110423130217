export interface InViewportConfigOptions {
  root?: Element;
  threshold?: number | number[];
}

/**
 *  Set default threshold on 75% as requested by analytics team
 */
export const DEFAULT_IMPRESSION_THRESHOLD = 0.75;

/**
 * More info https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API#Intersection_observer_options
 */
const DEFAULT_INTERSECTION_THRESHOLD = [DEFAULT_IMPRESSION_THRESHOLD];

export class InViewportConfig {
  private _root?: Element;
  public readonly rootMargin = '0px 0px 0px 0px';
  private _threshold: number | number[] = [...DEFAULT_INTERSECTION_THRESHOLD];

  constructor(options?: InViewportConfigOptions) {
    if (options == null) {
      return;
    }
    this.root = options.root;
    this.threshold = options.threshold;
  }

  public get root(): Element | undefined {
    return this._root;
  }

  public set root(value: Element | undefined) {
    this._root = value && value.nodeType === 1 ? value : undefined;
  }

  public get threshold(): number | number[] | undefined {
    return this._threshold;
  }

  public set threshold(value: number | number[] | undefined) {
    let threshold: number[] = [];
    const isValidThreshold = (val: any): val is number =>
      typeof val === 'number' && val >= 0 && val <= 1;
    if (isValidThreshold(value)) {
      threshold = [value];
    } else if (Array.isArray(value) && value.length) {
      threshold = value.filter((val) => isValidThreshold(val));
    }
    if (threshold.length === 0) {
      threshold = [...DEFAULT_INTERSECTION_THRESHOLD];
    }
    this._threshold = threshold;
  }
}
