import { provideBootstrapEffects } from '@innogy/utils-state';

import { OrderAddressEffects } from './address';
import { OrderConfirmationEffects } from './confirmation';
import { OrderErrorEffects } from './order-error.effects';
import { OrderFormSubmitEffects } from './order-form-submit.effects';
import { OrderTrackingEffects } from './order-tracking.effects';
import { OrderPaymentEffects } from './payment';

export * from './address';
export * from './confirmation';
export * from './payment';
export * from './personal';

export * from './order.state';
export * from './order.actions';
export * from './order.selector';

export const orderEffects = [
  OrderErrorEffects,
  OrderTrackingEffects,
  OrderConfirmationEffects,
  OrderPaymentEffects,
];

export const orderProviders = [
  provideBootstrapEffects([OrderFormSubmitEffects, OrderAddressEffects]),
];
