import type { SolarPanelsFunnelSettingsInterface } from '@innogy/eplus/models';
import type { SolarPanelsProductWithCalculations } from '@innogy/eplus/temporary-core-modules';
import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';

import {
  clearSolarPanelsFunnelInitializedAction,
  mintSolarPanelsTrackingIdAction,
  resetSolarPanelsProductSelectionAction,
  setSelectedSolarPanelProductAction,
  setSolarPanelsFunnelInitializedAction,
} from './solar-panels-funnel.actions';

export const solarPanelsFunnelKey = 'solar-panels-funnel';

export type SolarPanelsFunnelState = SolarPanelsFunnelSettingsInterface & {
  selectedProduct?: SolarPanelsProductWithCalculations;
};

export const initialSolarPanelsFunnelState: SolarPanelsFunnelState = {
  id: undefined,
  initialized: false,
  startPage: undefined,
  houseIsMonumentPage: undefined,
  houseHasInsufficientRoofAreaPage: undefined,
  suitabilityCheckPage: undefined,
  productOverviewPage: undefined,

  orderPage: undefined,
  successPage: undefined,
  showSuitabilityCheckPage: undefined,
  showProductOverviewPage: undefined,
  showResultOverviewPage: undefined,
  selectedProduct: undefined,
  trackingId: undefined,
  usageWizard: undefined,
};

const reducer = createReducer(
  initialSolarPanelsFunnelState,
  on(setSolarPanelsFunnelInitializedAction, (state, { funnelSettings }) => ({
    ...state,
    ...funnelSettings,
    initialized: true,
  })),
  on(clearSolarPanelsFunnelInitializedAction, (state) => ({
    ...state,
    initialized: false,
  })),
  on(mintSolarPanelsTrackingIdAction, (state, { id: trackingId }) => ({
    ...state,
    trackingId,
  })),
  on(setSelectedSolarPanelProductAction, (state, { product }) => ({
    ...state,
    selectedProduct: product,
  })),
  on(resetSolarPanelsProductSelectionAction, (state) => ({
    ...state,
    selectedProduct: undefined,
  }))
);

export const solarPanelsFunnelReducer = (
  state: SolarPanelsFunnelState = initialSolarPanelsFunnelState,
  action: Action
): SolarPanelsFunnelState => reducer(state, action);
