import { combineEffectsWithProviders } from '@essent/common';
import { provideBootstrapEffects } from '@innogy/utils-state';

import { ApiErrorEffects } from './0.errors';
import { putInitiateFlowApi } from './1.initiate-flow';
import { PropositionEffects } from './10.proposition';
import { ExpectedConsumptionEffects } from './5.expected-consumption';
import { BAGRegistrationEffects } from './bag-registration';
import { OffersEffects } from './offers';

const { EFFECTS: bacEffectsWithProviders, PROVIDERS: bacProvidersWithEffects } =
  combineEffectsWithProviders(putInitiateFlowApi);

export const bacFlowEffects = [
  ...bacEffectsWithProviders,
  ApiErrorEffects,
  ExpectedConsumptionEffects,
  PropositionEffects,
  OffersEffects,
];

export const bacFlowProviders = [
  ...bacProvidersWithEffects,
  provideBootstrapEffects([ApiErrorEffects, BAGRegistrationEffects]),
];
