<div class="h2" *scText="listConfig?.title"></div>
<ul class="mb-0 list-unstyled list-no-default-spacing">
  <li
    *ngFor="let item of listItems"
    class="step-by-step"
    [class.step-by-step__brand]="subVariant === 'brand'"
    [class.step-by-step__brand-alt]="subVariant === 'primary'"
    [class.step-by-step__white]="subVariant === 'white'"
  >
    <wl-composable-card [config]="cardConfig">
      <div class="step-by-step-card">
        <div class="step-by-step-icon" *ngIf="item.fields?.icon">
          <fa-icon
            [icon]="item.fields?.icon | scIcon"
            size="lg"
            [fixedWidth]="true"
          ></fa-icon>
        </div>
        <div class="step-by-step-content" [ngClass]="backgroundColorClass">
          <wl-rich-text-wrapper [field]="item.fields?.title">
            <div></div>
          </wl-rich-text-wrapper>
        </div>
      </div>
    </wl-composable-card>
  </li>
</ul>
