import { Location } from '@angular/common';
import type {
  EmbeddedViewRef,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  Directive,
  Input,
  Renderer2,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import {
  extractNavigationExtrasFromPath,
  isAbsoluteUrl,
  isSitecoreMediaLink,
  mergeNavigationExtras,
} from '@core/routing-utils';
import { ScrollService } from '@innogy/utils-deprecated';
import { RichTextField } from '@sitecore-jss/sitecore-jss-angular';

@Directive({
  selector: '[wlGenericRichText]',
})
export class GenericRichTextDirective implements OnChanges, OnInit {
  private viewRef?: EmbeddedViewRef<HTMLElement>;

  @Input('wlGenericRichText')
  field?: RichTextField;

  @Input()
  navigationExtras?: NavigationExtras;

  constructor(
    private readonly viewContainer: ViewContainerRef,
    private readonly templateRef: TemplateRef<any>,
    private readonly renderer: Renderer2,
    private readonly router: Router,
    private readonly location: Location,
    private readonly scrollService: ScrollService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['field'] ||
      changes['navigationExtras'] ||
      changes['editable']
    ) {
      if (!this.viewRef) {
        this.viewContainer.clear();
        this.viewRef = this.viewContainer.createEmbeddedView(this.templateRef);
      }

      this.updateView();
    }
  }

  ngOnInit() {
    this.viewRef?.rootNodes.forEach((node: HTMLElement) => {
      if (node.classList) {
        node.classList.add('rte-content');
      }
    });
  }

  private updateView() {
    const field = this.field;

    if (!field || (!field.editable && !field.value)) {
      return;
    }

    const html = field.editable ? field.editable : field.value;

    this.viewRef?.rootNodes.forEach((node) =>
      this.handleHtmlRootNode(node, html)
    );
  }

  private handleHtmlRootNode(node: HTMLElement, field: any) {
    node.innerHTML = field;

    if (!node.querySelectorAll) {
      return;
    }

    const links: NodeListOf<HTMLLinkElement> = node.querySelectorAll('a[href]');
    const linksArray: Array<HTMLLinkElement> = [].slice.call(links);

    linksArray.forEach((link) => this.handleLinkNavigation(link));
  }

  private handleLinkNavigation(link: HTMLLinkElement) {
    const href = link.getAttribute('href');
    const target = link.getAttribute('target');

    if (
      !href ||
      isAbsoluteUrl(href) ||
      this.isIllegalLinkTarget(target) ||
      isSitecoreMediaLink(href)
    ) {
      return;
    }

    this.renderer.listen(link, 'click', (event) => {
      event.preventDefault();

      const [path, navigationExtras] = extractNavigationExtrasFromPath(href);
      const combinedNavigationExtras = mergeNavigationExtras(
        this.navigationExtras,
        navigationExtras
      );

      if (!path && combinedNavigationExtras?.fragment) {
        /**
         * When there is no path defined (meaning it should stay on the current page), but there
         * is a fragment, it should (try to) scroll to the anchor-point.
         */
        this.scrollService.scrollToAnchorAnimated(
          combinedNavigationExtras.fragment
        );
      } else {
        /**
         * Angular navigation will see an empty path as a navigation to the root path (/), in this case we will use the
         * current path. If a link should redirect to the homepage a "/" should be used (or an absolute URL).
         */
        this.router.navigate(
          [path || this.location.path()],
          combinedNavigationExtras
        );
      }
    });
  }

  private isIllegalLinkTarget(target: string | null) {
    return target === null ? false : target === '_blank' || target === '_top';
  }
}
