import type { ValidationErrors } from 'ngrx-forms';

export interface IsIntValidator {
  actual: any;
}

declare module 'ngrx-forms/src/state' {
  export interface ValidationErrors {
    isInt?: IsIntValidator;
  }
}

const _isInt = <T>(value: T | number) => {
  return (
    typeof value === 'number' &&
    !isNaN(value) &&
    value !== Infinity &&
    value !== -Infinity &&
    Math.floor(value) === value
  );
};

/**
 * Validates that the given value is an int
 * @param value
 */
export const isInt = <T>(value: T | number): ValidationErrors => {
  if (_isInt(value)) {
    return {};
  }

  return {
    isInt: { actual: value },
  };
};
