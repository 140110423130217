import { Injectable } from '@angular/core';
import { putProposition } from '@essent/new-customer';
import { SITUATION_ACTION_ID } from '@innogy/become-a-customer/shared';
import { ensureFlowId } from '@innogy/utils-rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { filter, map, mergeMap } from 'rxjs/operators';
import {
  GenericModalResults,
  GenericModalSources,
} from '@innogy/common-ui/shared/interfaces';
import { closeGenericModal } from '@innogy/common-ui/modals';

import { getOfferSuccess } from '../../get-offer/get-offer.actions';
import { getOffersSuccess } from '../../get-offer/get-offers.actions';
import { ORDER_REFRESH_OFFER_ACTION_ID } from '../../order';
import { getFlowId } from '../flow-id';
import { getPropositionOffer, setPropositionOfferAction } from '../offers';
import {
  choosePropositionAction,
  submitPropositionAction,
} from './proposition.actions';
import { openModalFromFunnelSettingsAction } from '../../funnel';

@Injectable()
export class PropositionEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<any>
  ) {}

  flowId$ = this.store$.select(getFlowId);
  proposition$ = this.store$.select(getPropositionOffer);

  public readonly switchIsCustomerActiveOffer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getOffersSuccess),
      filter((action) => action.actionId === SITUATION_ACTION_ID),
      concatLatestFrom(() => [this.flowId$, this.proposition$]),
      mergeMap(([{ payload }, flowId, proposition]) => {
        const selectedOfferId = payload.offerIds.find((offerId) => {
          const offer = payload.offers[offerId];
          return offer.duration === proposition?.duration;
        });
        if (flowId && selectedOfferId) {
          const selectedOffer = payload.offers[selectedOfferId];
          return [
            putProposition({
              actionId: SITUATION_ACTION_ID,
              payload: {
                metaData: {
                  flowId,
                },
                payload: {
                  campaignId: selectedOffer.campaignId,
                  durationInYears: selectedOffer.duration,
                  incentiveId: selectedOffer.incentiveId,
                },
              },
            }),
            setPropositionOfferAction({ offer: selectedOffer }),
          ];
        }
        return [];
      })
    );
  });

  public readonly refreshProposition$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getOfferSuccess),
      filter((action) => action.actionId === ORDER_REFRESH_OFFER_ACTION_ID),
      ensureFlowId(this.flowId$),
      mergeMap(([action]) => [
        setPropositionOfferAction({ offer: action.payload }),
      ])
    )
  );

  public readonly choosePropositionEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(choosePropositionAction),
      map(({ offer }) =>
        offer.isDynamicProduct
          ? openModalFromFunnelSettingsAction({
              source: GenericModalSources.DYNAMIC_OFFER,
              additionalPayload: offer,
            })
          : submitPropositionAction({ offer })
      )
    )
  );

  public readonly redirectToOrderPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(closeGenericModal),
      filter(
        (action) =>
          action.source === GenericModalSources.DYNAMIC_OFFER &&
          action.result === GenericModalResults.PRIMARY
      ),
      map(({ additionalPayload: offer }) => {
        return submitPropositionAction({ offer });
      })
    )
  );
}
