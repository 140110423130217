import type { AsyncState } from '@essent/common';
import { Status } from '@essent/common';
import type { PostChatTokenResponse } from '@integration/chatting-api-models';
import { createReducer, on } from '@ngrx/store';

import {
  postChatToken,
  postChatTokenClear,
  postChatTokenError,
  postChatTokenSuccess,
} from '../chat-token';

export interface State extends AsyncState<PostChatTokenResponse> {
  token?: string;
}

export const initialState: State = {
  status: Status.IDLE,
};

export const reducer = createReducer(
  initialState,
  on(postChatToken, (state) => ({
    ...state,
    status: Status.PENDING,
  })),
  on(postChatTokenSuccess, (state, action) => ({
    ...state,
    token: action.payload.token,
    status: Status.SUCCESS,
  })),
  on(postChatTokenError, (state, action) => ({
    ...state,
    error: action.payload,
    status: Status.ERROR,
  })),
  on(postChatTokenClear, () => initialState)
);

export const getStatus = (state: State) => state.status;
export const getError = (state: State) => state.error;
export const getToken = (state: State) => state.token;
export const getLoading = (state: State) =>
  state.status === Status.IDLE || state.status === Status.PENDING;
