import { createInitialAsyncState, Status } from '@essent/common';
import type { ProductDuration } from '@essent/new-customer';
import type {
  Offer,
  OfferSet,
} from '@innogy/become-a-customer/shared/interfaces';
import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';
import concat from 'lodash/concat';
import findIndex from 'lodash/findIndex';
import uniqBy from 'lodash/uniqBy';
import uniq from 'lodash/uniq';

import {
  appendOffersAction,
  clearActiveOffersAction,
  clearOffersAction,
  loadOffersetAction,
  setActiveOffersAction,
  setActiveOffersByDurationAction,
  setActiveOffersetAction,
  setPropositionOfferAction,
  setPropositionOfferStatusAction,
} from './offers.actions';

export interface OffersState {
  offersets: OfferSet[];
  activeOfferIds: string[];
  activeOfferset: string | null;
  activeDuration: ProductDuration | null;
  propositionOffer: {
    status: Status;
    offer?: Offer;
  };
}

export const initalOffersState: OffersState = {
  offersets: [],
  activeOfferIds: [],
  activeOfferset: null,
  activeDuration: null,
  propositionOffer: createInitialAsyncState(),
};

const _reducer = createReducer<OffersState>(
  initalOffersState,
  on(loadOffersetAction, (state, action) => {
    const newOfferset: OfferSet = {
      offerset: action.offerset,
      offers: [],
      customer: false,
      durations: {
        present: [],
        retrieved: [],
      },
    };

    const activeOfferset = action.setAsActive
      ? newOfferset.offerset
      : state.activeOfferset;
    const newOffersets = uniqBy(
      concat(newOfferset, state.offersets),
      (set) => set.offerset
    );

    return {
      ...state,
      offersets: newOffersets,
      activeOfferset,
    };
  }),
  on(appendOffersAction, (state, action) => {
    const offersetIndex = findIndex(
      state.offersets,
      (set) => set.offerset === action.offerset
    );
    const offerset = state.offersets[offersetIndex];
    const offers = offerset?.offers ?? [];
    const durations = offerset?.durations.retrieved ?? [];

    // Set the new offers by id in the existing offer state
    const newOfferset = {
      offerset: action.offerset,
      offers: uniqBy(concat(action.offers, offers), (offer) => offer.offerId),
      customer: action.customer,
      durations: {
        present: action.durations.present,
        retrieved: uniq(concat(action.durations.retrieved, durations)),
      },
    };

    const newOffersets = uniqBy(
      concat(newOfferset, state.offersets),
      (set) => set.offerset
    );

    return {
      ...state,
      offersets: newOffersets,
    };
  }),
  on(setActiveOffersAction, (state, action) => {
    return {
      ...state,
      activeOfferIds: action.offerIds,
      activeOfferset: action.offerset,
    };
  }),
  on(setActiveOffersByDurationAction, (state, action) => {
    const offerset = state.offersets.find(
      (set) => set.offerset === action.offerset
    );
    const offers = offerset?.offers ?? [];
    const offerIds = offers
      .filter((offer) => offer.duration === action.duration)
      .map((offer) => offer.offerId);

    return {
      ...state,
      activeOfferset: action.offerset,
      activeOfferIds: offerIds,
      activeDuration: action.duration,
    };
  }),
  on(setActiveOffersetAction, (state, action) => {
    const offerset = state.offersets.find(
      (set) => set.offerset === action.offerset
    );
    const offers = offerset?.offers ?? [];
    const offerIds = offers
      .filter((offer) => offer.duration === state.activeDuration)
      .map((offer) => offer.offerId);

    return {
      ...state,
      activeOfferset: action.offerset,
      activeOfferIds: offerIds,
    };
  }),
  on(clearActiveOffersAction, (state, _) => {
    return {
      ...state,
      activeOfferIds: [],
      activeOfferset: null,
      activeDuration: null,
    };
  }),
  on(clearOffersAction, () => {
    return {
      ...initalOffersState,
    };
  }),
  on(setPropositionOfferAction, (state, props) => ({
    ...state,
    propositionOffer: {
      status: Status.SUCCESS,
      offer: props.offer,
    },
  })),
  on(setPropositionOfferStatusAction, (state, props) => ({
    ...state,
    propositionOffer: {
      status: props.status,
      offer: state.propositionOffer.offer,
    },
  }))
);

export function offersReducer(
  state: OffersState = initalOffersState,
  action: Action
): OffersState {
  return _reducer(state, action);
}
