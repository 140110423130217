<ng-container>
  <div class="row mb-1">
    <div class="col-12">
      <wl-rich-text-wrapper
        [field]="{ value: content?.usageQuestionnaireTitle }"
        ><p
          class="h6 mt-0 mb-0 fw-bold"
          wlAutomationId="usage-questionnaire-title"
        ></p
      ></wl-rich-text-wrapper>
    </div>
  </div>
  <div class="row mb-1">
    <div class="col-sm-12 col-md-12">
      <div class="d-grid gap-2">
        <button
          *ngIf="content?.manualButtonLabel"
          class="btn btn-outline-primary"
          type="button"
          (click)="setQuestionnaireContainerVisibility.emit(true)"
          wlAutomationId="calculate-form-consumption-help.ManualButton"
        >
          {{ content?.manualButtonLabel }}
        </button>
        <button
          *ngIf="content?.calculatorButtonLabel"
          class="btn btn-outline-primary"
          wlAutomationId="calculate-form-consumption-help.EstimationToolButton"
          (click)="calculateOpenModal.emit()"
        >
          {{ content?.calculatorButtonLabel }}
        </button>
      </div>
    </div>
  </div>
</ng-container>
