import { Component, HostBinding, Input } from '@angular/core';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss-angular';

import { ButtonModel } from './button.model';

@Component({
  selector: 'wl-button',
  templateUrl: './button.component.html',
})
export class ButtonComponent {
  @Input()
  rendering?: ComponentRendering;

  @Input()
  config?: ButtonModel;

  @HostBinding('class')
  class = '';
}
