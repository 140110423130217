import { createBaseAction } from '@essent/common';

export const startChat = createBaseAction<{ login: boolean }>(
  '[Chatting] Start chat'
);

export const logoutChatAndRedirect = createBaseAction<{
  redirect_url: string;
  chatEnabled: boolean;
}>('[Chatting] Logout chat and redirect');
