import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { type Observable } from 'rxjs';

@Injectable()
export class OfferMailer {
  http = inject(HttpClient);

  emailOffer = (emailAddress: string): Observable<unknown> =>
    this.http.post('/api/email-offer', {
      parameter_values: {
        MAIL: emailAddress,
      },
    });
}
