import { createBaseAction } from '@essent/common';
import type { RequestedContractStatus } from '@essent/supply-address-v3';

export const setMonths = createBaseAction<{
  numberOfMonths: number;
  requestContractStatus: RequestedContractStatus;
}>('[SupplyAddresses] Set number of months');

export const fetchSupplyAddresses = createBaseAction<RequestedContractStatus>(
  '[SupplyAddresses] Number of months changed'
);
