import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';
import type { EnergyType } from '@essent/new-customer';
import type {
  Offer,
  OfferOverview,
} from '@innogy/become-a-customer/shared/interfaces';

@Pipe({
  name: 'selectCommodity',
})
export class SelectCommodityPipe implements PipeTransform {
  transform(value: Offer, energyType: EnergyType): OfferOverview | undefined {
    const { offerOverviews } = value;

    return offerOverviews.find(
      (offerOverview) => offerOverview.energyType === energyType
    );
  }
}
