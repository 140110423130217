<ng-container *ngIf="placement$ | async as placement">
  <footer
    *ngIf="rendering?.fields as fields"
    wlTrackImpression="navigation-impression"
    trackImpressionMode="any"
    [trackImpressionInfo]="{
      name: 'footernavigation',
      placement: placement,
    }"
  >
    <ng-container *ngIf="isEw">
      <wl-ewo-footer [rendering]="rendering"></wl-ewo-footer>
    </ng-container>
    <ng-container *ngIf="!isEw">
      <wl-footer-upper [rendering]="rendering"></wl-footer-upper>
      <wl-footer-lower [rendering]="rendering"></wl-footer-lower>
    </ng-container>
  </footer>
</ng-container>
