import type { ValidationErrors } from 'ngrx-forms';

export const kvkRegex = new RegExp('^[0-9]{8}$');

export interface IsKvkValidator {
  actual: any;
}

declare module 'ngrx-forms/src/state' {
  export interface ValidationErrors {
    isKvk?: IsKvkValidator;
  }
}

export const isKvkValue = (value?: string) => {
  if (value == null) {
    return false;
  }
  return kvkRegex.test(value);
};

/**
 * Validates that the given value is a number
 * @param value
 */
export const isKvk = (value: string): ValidationErrors => {
  if (isKvkValue(value)) {
    return {};
  }

  return {
    isKvk: { actual: value },
  };
};
