<div *ngIf="hasLinks" class="social-icons">
  <p *ngIf="title" class="fw-bold">
    {{ title }}
  </p>
  <div class="social-icons__container" role="navigation" aria-label="Social">
    <ng-container *ngFor="let social of socials">
      <ng-container *ngIf="getLink(social) as link">
        <ng-container *ngIf="link.href">
          <a
            *wlGenericLink="link"
            rel="nofollow"
            class="social-icons__link"
            [attr.aria-label]="social"
          >
            <fa-icon
              class="social-icons__icon"
              [icon]="['fab', socialIcons[social]]"
              size="2x"
              [fixedWidth]="true"
            ></fa-icon>
          </a>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>
