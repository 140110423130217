/**
 * NOTE TO DEVELOPERS:
 *
 * This file contains a lot of frontend business logic due to limitations in our current backend responses.
 *
 * Main concerns:
 * - We are extracting multiple pieces of data from string, which increasing the risk of errors.
 * - The frontend is tightly coupled with the backend's data format.
 *
 * Action Item: Regularly check for backend updates to move some business logic there. This will help simplify the frontend and reduce errors
 */

import type {
  ConsumptionPrice,
  TariffPeriod,
  TariffPeriodForDropdown,
} from '@innogy/become-a-customer/shared/interfaces';
import {
  TariffZoneElectricity,
  TariffType,
  TariffSeasonDates,
} from '@innogy/become-a-customer/shared/interfaces';

/**
 * Helps to calculate the Tariff price of gas
 *
 * @param consumptionPrice
 * @returns
 */
export function calculateTariffPriceForGas(
  consumptionPrice: ConsumptionPrice[]
): TariffPeriod[] {
  return consumptionPrice.map((tariffPeriod) => ({
    summerPrice: tariffPeriod.amount,
    description: updateTariffDescription(tariffPeriod.description),
  }));
}

export function calculateTariffPriceForElectricity(
  consumptionPrice: ConsumptionPrice[]
): TariffPeriod[] {
  const zones = [
    TariffZoneElectricity.Zone1,
    TariffZoneElectricity.Zone2,
    TariffZoneElectricity.Zone3,
    TariffZoneElectricity.Zone4,
  ];

  return zones.flatMap((zone) =>
    calculateElectricityTariffByZone(consumptionPrice, zone)
  );
}

/**
 * Helps to calculate tariffs for a specified zone by grouping tariffs based on their type
 * For each tariff type, it calls `calculateSeasonalTariff` to compute the tariff details for the season
 *
 * @param consumptionPrice
 * @returns
 */
function calculateElectricityTariffByZone(
  consumptionPrices: ConsumptionPrice[],
  zoneRange: string
) {
  const tariffPeriods: TariffPeriod[] = [];

  const singleTariff = filterTariffsByZoneAndType(
    consumptionPrices,
    TariffType.Single,
    zoneRange
  );
  const normalTariff = filterTariffsByZoneAndType(
    consumptionPrices,
    TariffType.Normal,
    zoneRange
  );
  const lowTariff = filterTariffsByZoneAndType(
    consumptionPrices,
    TariffType.Low,
    zoneRange
  );

  const ExpectedDeliveryTariff = filterTariffsByZoneAndType(
    consumptionPrices,
    TariffType.ExpectedDelivery,
    zoneRange
  );

  if (singleTariff.length) {
    tariffPeriods.push(calculateSeasonalTariff(singleTariff));
  }

  if (normalTariff.length) {
    tariffPeriods.push(calculateSeasonalTariff(normalTariff));
  }

  if (lowTariff.length) {
    tariffPeriods.push(calculateSeasonalTariff(lowTariff));
  }

  if (ExpectedDeliveryTariff.length) {
    tariffPeriods.push(calculateSeasonalTariff(ExpectedDeliveryTariff));
  }

  return tariffPeriods;
}

export function filterTariffsByZoneAndType(
  tariffs: ConsumptionPrice[],
  type: TariffType,
  zoneRange: string
) {
  return tariffs.filter(
    (tariff) =>
      tariff.description.includes(zoneRange) &&
      tariff.description.includes(type)
  );
}

export function calculateSeasonalTariff(
  consumptionPrices: ConsumptionPrice[]
): TariffPeriod {
  const tarriffPeriod: TariffPeriod = {
    description: updateTariffDescription(consumptionPrices[0].description),
    summerPrice: undefined,
    winterPrice: undefined,
  };

  consumptionPrices.forEach((consumptionPrice) => {
    if (isSummerExits(consumptionPrice.fromDate, consumptionPrice.toDate)) {
      tarriffPeriod.summerPrice = consumptionPrice.amount;
    } else if (
      isWinterExits(consumptionPrice.fromDate, consumptionPrice.toDate)
    ) {
      tarriffPeriod.winterPrice = consumptionPrice.amount;
    } else {
      // Default case, no summer winter
      tarriffPeriod.summerPrice = consumptionPrice.amount;
    }
  });

  return tarriffPeriod;
}

export function updateTariffDescription(value: string) {
  const startIndex = value.indexOf('(');
  const endIndex = value.indexOf(')');

  let description =
    startIndex !== -1 && endIndex !== -1
      ? value.slice(startIndex + 1, endIndex)
      : '';

  if (value.includes(TariffType.Normal)) {
    description = '<strong>normaal</strong><br />' + description;
  } else if (value.includes(TariffType.Low)) {
    description = '<strong>dal</strong><br />' + description;
  }
  return description;
}

export function isSummerExits(fromDate?: string, toDate?: string) {
  return (
    fromDate === TariffSeasonDates.SummerStart &&
    toDate === TariffSeasonDates.SummerEnd
  );
}

export function isWinterExits(fromDate?: string, toDate?: string) {
  return (
    fromDate === TariffSeasonDates.WinterStart &&
    toDate === TariffSeasonDates.WinterEnd
  );
}

/**
 * Get the tariffs for a given tax zone
 *
 * @param consumptionPrice
 * @param tariffZone
 * @returns
 */
export function calculateTariffForDropDownVM(
  consumptionPrice: ConsumptionPrice[],
  tariffZone: string
): TariffPeriodForDropdown[] {
  return consumptionPrice
    .filter((price) => price?.description?.includes(`(${tariffZone}`))
    .map((price) => ({
      description: updateTariffDescriptionForDropdownVM(
        price.description,
        tariffZone,
        price.fromDate,
        price.toDate
      ),
      price: price.amount,
    }));
}

export function updateTariffDescriptionForDropdownVM(
  value: string,
  zone: string,
  fromDate?: string,
  toDate?: string
) {
  let description = '';

  // Normal or dal
  if (value.includes(TariffType.Normal)) {
    description = `<i><strong>normaal</strong><br /></i>`;
  } else if (value.includes(TariffType.Low)) {
    description = `<i><strong>dal</strong><br /></i>`;
  }

  // zone
  description += `${zone}<br />`;

  // summer/winter
  if (isSummerExits(fromDate, toDate)) {
    description += `<i>(van ${TariffSeasonDates.SummerStart} t/m ${TariffSeasonDates.SummerEnd})</i>`;
  } else if (isWinterExits(fromDate, toDate)) {
    description += `<i>(van ${TariffSeasonDates.WinterStart} t/m ${TariffSeasonDates.WinterEnd})</i>`;
  }

  return description;
}
