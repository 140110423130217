import { Component, ContentChild, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'wl-receipt-breakdown-row',
  templateUrl: './receipt-breakdown-row.component.html',
  styleUrls: [
    './receipt-breakdown-row.component.ed.scss',
    './receipt-breakdown-row.component.essent.scss',
  ],
})
export class ReceiptBreakdownRowComponent {
  @Input() separator = false;

  @ContentChild('left') leftProjection?: TemplateRef<any>;
  @ContentChild('right') rightProjection?: TemplateRef<any>;
}
