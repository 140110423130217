<article
  class="media-card media-card--{{ theme }}"
  [class.media-card--grow]="grow"
  [class.media-card--shadow]="shadow"
>
  <div class="media-card__media">
    <ng-content select="wl-partial-image"></ng-content>
  </div>
  <section class="media-card__content">
    <ng-content></ng-content>
  </section>
</article>
