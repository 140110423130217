import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommonUiPartialsModule } from '@innogy/common-ui/partials';
import { CommonUiSharedModule } from '@innogy/common-ui/shared';
import { DefaultComponentModule } from '@innogy/core/modules/components';
import { CoreJssUtilsModule } from '@core/jss-utils';
import { OfferSaverButtonComponent } from '@innogy/offer-saver';
import { UtilsModule } from '@innogy/utils-deprecated';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';

import { CommodityOfferCalculationBasedOnComponent } from './commodity-offer-calculation-based-on/commodity-offer-calculation-based-on.component';
import { CommodityOfferCustomerInformationComponent } from './commodity-offer-customer-information/commodity-offer-customer-information.component';
import { CommodityOfferHeaderComponent } from './commodity-offer-header/commodity-offer-header.component';
import { CommodityOfferReferenceIdComponent } from './commodity-offer-reference-id/commodity-offer-reference-id.component';
import { HorizontalCommodityOfferComponent } from './horizontal-commodity-offer/horizontal-commodity-offer.component';
import { CommodityOfferComponents } from './index';
import { MixedCommodityOfferComponent } from './mixed-commodity-offer/mixed-commodity-offer.component';
import { VerticalCommodityOfferComponent } from './vertical-commodity-offer/vertical-commodity-offer.component';

@NgModule({
  imports: [
    CommonModule,
    CommonUiSharedModule,
    UtilsModule,
    DefaultComponentModule,
    CommonUiPartialsModule,
    NgbAccordionModule,
    CoreJssUtilsModule,
    OfferSaverButtonComponent,
  ],
  declarations: [CommodityOfferComponents],
  exports: [
    HorizontalCommodityOfferComponent,
    VerticalCommodityOfferComponent,
    CommodityOfferCalculationBasedOnComponent,
    CommodityOfferCustomerInformationComponent,
    CommodityOfferReferenceIdComponent,
    CommodityOfferHeaderComponent,
    MixedCommodityOfferComponent,
  ],
})
export class CommodityOffersModule {}
