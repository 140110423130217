import { Injectable, Injector } from '@angular/core';
import { BaseGuard } from '@core/ngrx';
import { getRenderingData } from '@core/jss-routing';
import { Store, select } from '@ngrx/store';
import type { GuardInput } from '@sitecore-jss/sitecore-jss-angular';
import { switchMap } from 'rxjs/operators';

import { loadCustomerOnboardingStatus } from '../onboarding/onboarding.actions';
import { getOnboardingIsLoading } from '../onboarding/onboarding.selectors';

@Injectable({
  providedIn: 'root',
})
export class CustomerStatusGuard extends BaseGuard {
  protected isStateLoading() {
    return this.store$.pipe(
      getRenderingData,
      switchMap(() => {
        return this.store$.pipe(select(getOnboardingIsLoading));
      })
    );
  }

  protected getDispatchActions() {
    return loadCustomerOnboardingStatus();
  }

  override canActivate(input: GuardInput) {
    return super.canActivate(input);
  }

  constructor(
    protected override readonly injector: Injector,
    public readonly store$: Store<any>
  ) {
    super(injector);
  }
}
