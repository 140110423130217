import type { AsyncState } from '@essent/common';
import {
  createAsyncStateReducer,
  createInitialAsyncState,
} from '@essent/common';
import {
  putConfirmation,
  putConfirmationClear,
  putConfirmationError,
  putConfirmationSuccess,
} from '@essent/new-customer';
import type { Action, ActionReducerMap } from '@ngrx/store';
import { combineReducers } from '@ngrx/store';

export type PutConfirmationState = AsyncState<void>; // FIXME, would expect FlowId

export interface ConfirmationState {
  putConfirmation: PutConfirmationState;
}

const initialState: ConfirmationState = {
  putConfirmation: createInitialAsyncState(),
};

const _putConfirmationReducer = createAsyncStateReducer(
  putConfirmation,
  putConfirmationSuccess,
  putConfirmationError,
  putConfirmationClear
);
const _reducers: ActionReducerMap<ConfirmationState, Action> = {
  putConfirmation: _putConfirmationReducer,
};

const _combinedReducers = combineReducers(_reducers);

export function confirmationReducer(
  state: ConfirmationState = initialState,
  action: Action
): ConfirmationState {
  return _combinedReducers(state, action);
}
