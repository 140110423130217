<div class="bg-light pt-2 w-100 h-100">
  <article>
    <div class="container">
      <div class="row mb-3">
        <div class="col">
          <h1 class="not-found-title mb-3" wlAutomationId="not-found-title">
            {{ title }}
          </h1>
          <p>
            Sorry. Op dit moment heeft onze website een storing. We zijn hard
            aan het werk om dit probleem zo snel mogelijk op te lossen.
          </p>
        </div>
      </div>
    </div>
  </article>
</div>
