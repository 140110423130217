import type { ProgressiveFormStateImplementor } from '@innogy/progressive-ngrx-forms';
import {
  createProgressiveFormGroupState,
  createProgressiveNgrxFormReducerWithFormStateUpdate,
} from '@innogy/progressive-ngrx-forms';
import type { Action, ActionCreator, ReducerTypes } from '@ngrx/store';
import { on } from '@ngrx/store';
import type { FormGroupState, StateUpdateFns } from 'ngrx-forms';
import {
  ResetAction,
  addGroupControl,
  onNgrxFormsAction,
  removeGroupControl,
  setUserDefinedProperty,
  updateGroup,
} from 'ngrx-forms';

import { addCheckboxFormControlAction } from './eplus-confirmation-form.actions';

export interface FormConfirmation {
  checkboxes: { [name: string]: boolean };
}

export interface ProgressiveConfirmationFormState {
  formState: FormGroupState<FormConfirmation>;
}

export type OrderConfirmationState =
  ProgressiveFormStateImplementor<FormConfirmation>;

type AdditionalOnFns = ReducerTypes<
  OrderConfirmationState,
  readonly ActionCreator<any, any>[]
>[];

export const initialOrderConfirmationProgressiveFormState = (id: string) =>
  createProgressiveFormGroupState<FormConfirmation>(id, {
    checkboxes: {},
  });

export const initialOrderConfirmationState = (
  id: string
): OrderConfirmationState => ({
  progressiveForm: initialOrderConfirmationProgressiveFormState(id),
});

const validateFormGroupState = (state: OrderConfirmationState) => {
  //TODO: Simplify
  const { controls, userDefinedProperties } =
    state.progressiveForm.formState.controls.checkboxes;
  const validations = Object.keys(controls).reduce(
    (acc, key) => ({
      ...acc,
      ...(userDefinedProperties[key] && { [key]: userDefinedProperties[key] }),
    }),
    {}
  );

  return updateGroup<FormConfirmation>({
    checkboxes: updateGroup(validations),
  })(state.progressiveForm.formState);
};

const _addCheckboxReducer = (
  state: OrderConfirmationState,
  { name, validator }: any
) => {
  const controlAlreadyExists = !!state.progressiveForm.formState.controls
    .checkboxes.controls[name] as boolean;
  const validatorAlreadyExists =
    !!state.progressiveForm.formState.controls.checkboxes.userDefinedProperties[
      name
    ];

  const removeControl: StateUpdateFns<FormConfirmation> = controlAlreadyExists
    ? {
        checkboxes: removeGroupControl(name),
      }
    : {};
  const removeValidator: StateUpdateFns<FormConfirmation> =
    validatorAlreadyExists
      ? {
          checkboxes: setUserDefinedProperty(name, undefined),
        }
      : {};
  const addControl: StateUpdateFns<FormConfirmation> = {
    checkboxes: addGroupControl(name, false),
  };
  const addValidator: StateUpdateFns<FormConfirmation> = validator
    ? {
        checkboxes: setUserDefinedProperty(name, validator),
      }
    : {};
  return {
    ...state,
    progressiveForm: {
      ...state.progressiveForm,
      formState: updateGroup<FormConfirmation>(
        state.progressiveForm.formState,
        removeControl,
        removeValidator,
        addControl,
        addValidator
      ),
    },
  };
};

const _resetReducer =
  (id: string) => (state: OrderConfirmationState, action: ResetAction) => {
    if (action.controlId === id) {
      return initialOrderConfirmationState(id);
    }
    return state;
  };

const _reducer = (id: string, ...additionalOnFns: AdditionalOnFns) =>
  createProgressiveNgrxFormReducerWithFormStateUpdate(
    initialOrderConfirmationState(id),
    validateFormGroupState,
    onNgrxFormsAction(ResetAction, _resetReducer(id)),
    on(addCheckboxFormControlAction, _addCheckboxReducer),
    ...additionalOnFns
  );

export const EplusOrderConfirmationFormReducer =
  (id: string, ...additionalOnFns: AdditionalOnFns) =>
  (state = initialOrderConfirmationState(id), action: Action) =>
    _reducer(id, ...additionalOnFns)(state, action);
