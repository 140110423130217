import { inject, Injectable } from '@angular/core';
import {
  type AlloyProposition,
  AdobeWebSDKService,
} from '@innogy/core/analytics';
import { Angulartics2 } from 'angulartics2';
import { WINDOW } from '@innogy/utils-dom';

import type { ABTestingVariant } from '../ab-testing.model';

const COMPONENT_TEST_EVENT_NAME = 'ab-experience-assigned';

@Injectable({
  providedIn: 'root',
})
export class ABTestingService {
  readonly #angulartics = inject(Angulartics2);
  readonly #adobeWebSDK = inject(AdobeWebSDKService);
  readonly #window = inject(WINDOW);

  private readonly errorPrefix = `[AB testing]`;

  /**
   * fetches information based on the test we're running and returns the assigned variant.
   * @param testName name of the A/B test we want to get an assignment for. This value has to correspond with the mbox name configured in instance of Adobe Target serving out JSON Offers.
   * @param defaultVariant variant to return in case of no test data being found, functional errors or any other unexpected problems.
   * @param permittedVariants list of variants that are allowed to be returned from Adobe Web SDK. When the returned variant is not in this list we default out.
   * @returns test variant returned from Adobe Web SDK.
   */
  public async getAssignedVariant(
    testName: string,
    defaultVariant: ABTestingVariant,
    permittedVariants: ABTestingVariant[]
  ) {
    // Default out to default variant when the web SDK is disabled.
    if (!this.#adobeWebSDK.isEnabled()) {
      return {
        variant: defaultVariant,
        assignmentResolved: false,
        trackingPayload: [],
      };
    }

    // Make a request to Adobe Web SDK to get offers
    try {
      const { propositions } =
        await this.#adobeWebSDK.fetchPropositionsForTest(testName);

      // Extract the proposition
      const proposition = propositions.find((p) => p.scope === testName);
      const propositionContent = proposition?.items?.[0]?.data?.content;

      // Handle cases where no proposition is found.
      if (!propositionContent) {
        return {
          variant: defaultVariant,
          assignmentResolved: false,
          trackingPayload: [],
        };
      }

      // Handle misconfigured AB tests
      if (
        !(propositionContent instanceof Object) ||
        !propositionContent['variant']
      ) {
        console.warn(
          `${this.errorPrefix} Test is misconfigured, the configured offer is not a JSON offer containing a 'variant' key. Falling back to the default variant '${defaultVariant}.'`
        );
        return {
          variant: defaultVariant,
          assignmentResolved: false,
          trackingPayload: [],
        };
      }

      const { variant } = propositionContent;

      // Check if the variant is in the list of permitted variants
      if (!permittedVariants.includes(variant)) {
        console.warn(
          `${this.errorPrefix} Test is misconfigured, the configured variant '${variant}' is not present in the list of permitted variants '${permittedVariants}. Falling back to the default variant '${defaultVariant}.`
        );
        return {
          variant: defaultVariant,
          assignmentResolved: false,
          trackingPayload: [],
        };
      }
      // Return the assigned variant
      return {
        variant: variant as ABTestingVariant,
        assignmentResolved: true,
        trackingPayload: propositions,
      };
    } catch (error) {
      console.error(
        `${this.errorPrefix} An error occurred while fetching the assigned variant:`,
        error
      );
      return {
        variant: defaultVariant,
        assignmentResolved: false,
        trackingPayload: [],
      };
    }
  }

  /**
   * sends a signal to the Adobe web SDK that the assigned items have been displayed.
   * Also sends an event to the GDDL marking that a test has been assigned.
   * @param payload a list of propositions that have been displayed.
   */
  public async trackAssignment(payload: AlloyProposition[]) {
    await this.#adobeWebSDK.applyPropositions({
      propositions: payload,
    });
    this.#adobeWebSDK.markPropositionAsDisplayed(payload);
    this.sendTestAssignedEvent(payload);
    this.storeTestDuringSession(payload[0]);
  }

  /**
   * Dispatches an event signalling that the user is assigned to an A/B test experience.
   */
  public sendTestAssignedEvent(payload: AlloyProposition[]) {
    this.#angulartics.eventTrack.next({
      action: COMPONENT_TEST_EVENT_NAME,
      properties: payload.map((proposition) => ({
        test: proposition.scope,
        activity: proposition.scopeDetails.activity['id'],
        experience: proposition.scopeDetails.experience['id'],
        offerPayload: proposition.items[0]?.data.content,
      })),
    });
  }

  /**
   * Method used to test if setting an ab-experience in the session storage can help patch some
   * data issues in adobe analytics. This code will be either refactored or removed after the test.
   * @param payload a proposition containing a scope and variant data.
   */
  public storeTestDuringSession(payload: AlloyProposition) {
    this.#window.sessionStorage?.setItem(
      'ab-experience',
      `${payload.scope}:${(payload.items[0]?.data.content as any)?.variant}`
    );
  }
}
