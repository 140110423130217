import { createAction, props } from '@ngrx/store';

import { BAC_ACTION_PREFIX } from '../constants';

export const setPartnerIdAction = createAction(
  `${BAC_ACTION_PREFIX} Set partnerId`,
  props<{ partnerId: string }>()
);

export const setDefaultPartnerIdAction = createAction(
  `${BAC_ACTION_PREFIX} Set default partnerId`
);
