import { DOCUMENT } from '@angular/common';
import type { AfterViewInit, OnDestroy } from '@angular/core';
import { Component, Inject, Input } from '@angular/core';
import { ENVIRONMENT_CONFIG } from '@core/config-angular';
import { EnvironmentConfig } from '@core/config-models';
import { JssPlatformService } from '@core/jss-platform';
import { PlatformService } from '@core/platform';
import {
  type IAdvizeInterface,
  IAdvizeService,
  startChat,
} from '@innogy/core/chatting';
import { ConsentService } from '@innogy/core/consent';
import { DomService, ScriptInjectOn, WINDOW } from '@innogy/utils-dom';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';

import type { IAdvize, IAdvizeCallbacks } from './chatbot-iadvize.interface';

@Component({
  selector: 'wl-chatbot-iadvize',
  template: '',
})
export class ChatbotIadvizeComponent implements AfterViewInit, OnDestroy {
  constructor(
    @Inject(ENVIRONMENT_CONFIG) private readonly config: EnvironmentConfig,
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(WINDOW)
    private readonly windowRef: Window & {
      iAdvizeCallbacks?: IAdvizeCallbacks;
      iAdvize?: IAdvize;
      iAdvizeInterface?: IAdvizeInterface;
    },
    private readonly jssPlatformService: JssPlatformService,
    private readonly platformService: PlatformService,
    private readonly iAdvizeService: IAdvizeService,
    private readonly domService: DomService,
    private readonly store$: Store,
    private readonly consentService: ConsentService
  ) {}

  private readonly onDestroy$ = new Subject();

  @Input()
  public login = false;

  async ngAfterViewInit() {
    if (this.consentService.hasConsent()) {
      await this.#initChatbot();
    }
  }

  async #initChatbot() {
    if (
      !this.jssPlatformService.isEditorActive() &&
      this.platformService.isClient() &&
      this.config.iAdvizeChatbot.enabled
    ) {
      this.#setupCallbacks();

      this.domService.injectScript({
        injectOnPlatform: ScriptInjectOn.CLIENT_ONLY,
        target: 'body',
        innerHTML: this.iAdvizeInnerHTML,
      });
      this.domService.injectScript({
        src: this.scriptSrc,
        injectOnPlatform: ScriptInjectOn.CLIENT_ONLY,
        async: true,
        target: 'body',
      });

      await this.iAdvizeService.ensureStable();

      if (this.config.iAdvizeChatbot.authenticationEnabled) {
        this.store$.dispatch(startChat({ payload: { login: this.login } }));
      }
    }
  }

  #setupCallbacks() {
    const { windowRef } = this;

    windowRef.iAdvizeCallbacks = {
      ...windowRef.iAdvizeCallbacks,
      // extra callbacks can be registered here.
    };
  }

  get scriptSrc() {
    const { url } = this.config.iAdvizeChatbot;
    const { protocol } = this.document.location;

    return `${protocol}${url}`;
  }

  get iAdvizeInnerHTML(): string {
    const { scriptId, language } = this.config.iAdvizeChatbot;
    return `window.iAdvizeInterface = window.iAdvizeInterface || [];
      iAdvizeInterface.config = {
        "sid": "${scriptId}",
        "lang": "${language}"
      };`;
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
