<wl-radio
  [label]="fields.Label?.value"
  [formsControl]="control"
  [radioItems]="radioItems"
  [rendering]="{ componentName: trackStep.toolName }"
  [fieldName]="fields.Label?.value"
  [sendValue]="fields.SendValue?.value"
  [hashValue]="fields.HashValue?.value"
  [step]="trackStep.step"
  [stepName]="trackStep.stepName"
  [inline]="fields.Inline?.value"
></wl-radio>
