/**
 * Attempts to convert given value to a number. Return converted value, undefined or NaN
 *
 * - Null, undefined and empty string returns ''
 * - All types besides `number` and `string` return NaN
 * - `Infinity` and `-Infinity` return NaN
 * - All other numbers return
 * - Strings are parsed to floats with `parseFloat`
 * - The entire string must be convertable to a number, `1.12acb` returns NaN
 *
 * @param value
 */
export const valueToNumber = <T>(value?: T | string | number) => {
  if (value == null || value === '') {
    return undefined;
  }

  if (value === Infinity || value === -Infinity) {
    return NaN;
  }

  if (typeof value === 'number') {
    return value;
  }

  if (typeof value === 'string') {
    if (isNaN(Number(value))) {
      return NaN;
    }

    const parsedFloat = parseFloat(value);
    if (parsedFloat === Infinity || parsedFloat === -Infinity) {
      return NaN;
    }

    return parsedFloat;
  }

  return NaN;
};
