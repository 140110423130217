<wl-structured-data [structuredData]="structuredData"></wl-structured-data>

<ng-container *ngIf="deviceInfo$ | async as deviceInfo">
  <div [class.bg-light]="greyBackground">
    <div class="breadcrumbs-container container">
      <nav aria-label="Breadcrumb">
        <ul
          class="breadcrumbs m-0 px-0 py-2"
          [class.mt-2]="overlap && deviceInfo.isDesktop"
          [class.breadcrumbs--overlap]="overlap"
          [class.breadcrumbs--white]="whiteText"
        >
          <ng-container *ngIf="showFullPath(deviceInfo); else mobile">
            <li
              *ngFor="let item of routes; let i = index"
              class="breadcrumbs__parent-page"
            >
              <ng-container
                *ngTemplateOutlet="
                  breadcrumb;
                  context: { $implicit: item, index: i }
                "
              ></ng-container>
            </li>
          </ng-container>
          <li
            *ngIf="includeCurrentPage && currentPage"
            wlAutomationId="breadcrumb-current-page"
            class="breadcrumbs__current-page"
          >
            <fa-icon icon="angle-right" class="breadcrumb__icon"></fa-icon>
            <span class="fw-bold">{{ currentPage.displayName }}</span>
          </li>
        </ul>
      </nav>
    </div>
  </div>

  <ng-template #mobile>
    <li [class.text-white]="whiteText && deviceInfo.isDesktop">
      <button
        (click)="onShowFullPathClick()"
        class="breadcrumb-ellipsis btn btn-link p-0 border-0"
        wlAutomationId="breadcrumb-ellipsis"
      >
        ...
      </button>
    </li>
    <li
      *ngFor="let item of routes.slice(-1); let i = index"
      class="breadcrumbs__parent-page"
    >
      <ng-container
        *ngTemplateOutlet="breadcrumb; context: { $implicit: item, index: i }"
      ></ng-container>
    </li>
  </ng-template>

  <!-- single breadcrumb template -->
  <ng-template #breadcrumb let-item let-index="index">
    <fa-icon
      class="breadcrumb__icon"
      *ngIf="!item.isRoot"
      icon="angle-right"
    ></fa-icon>
    <a
      *wlGenericLink="{ value: { href: item.url } }"
      class="breadcrumb"
      [class.text-white]="whiteText && deviceInfo.isDesktop"
      wlTrackLink
      [trackLinkInfo]="{
        rendering: { componentName: { value: 'breadcrumb' } },
        field: item.displayName,
        eventType: 'navigation-click',
        position: index + 1,
      }"
      [wlAutomationId]="'breadcrumb-anchor-' + index"
    >
      <fa-icon
        *ngIf="item.isRoot && showFullPath(deviceInfo)"
        icon="house"
        wlAutomationId="breadcrumb-home"
        aria-label="Terug naar homepage"
      ></fa-icon>
      <span *ngIf="!item.isRoot">{{ item.displayName }}</span>
    </a>
  </ng-template>
</ng-container>
