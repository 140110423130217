import type { Type } from '@angular/core';
import { InjectionToken } from '@angular/core';
import type { EventTrack } from 'angulartics2';

import type { PageInfo } from '../shared/page-info.model';

export type AnalyticsEvent = EventTrack | PageInfo;

/* eslint-disable max-classes-per-file */

/**
 * Base class for PageTrack analytic enhancers, extends this class to implement an enhancer for page events
 */
export abstract class PageTrackEnhancer {
  abstract enhance(
    page: PageInfo
  ): Partial<PageInfo> | Promise<Partial<PageInfo>>;
}

/**
 * Base class for EventTrack analytic enhancers, extends this class to implement an enhancer for events
 */
export abstract class EventTrackEnhancer {
  abstract enhance(
    event: Partial<EventTrack>
  ): Partial<EventTrack> | Promise<Partial<EventTrack>>;
}

export type AnalyticsEnhancer = PageTrackEnhancer | EventTrackEnhancer;

export const analyticsEnhancer: keyof AnalyticsEnhancer = 'enhance';

export const ROOT_ANALYTICS_ENHANCERS = new InjectionToken<
  Type<AnalyticsEnhancer>[]
>('core/analytics: Root Enhancers');
export const FEATURE_ANALYTICS_ENHANCERS = new InjectionToken<
  Type<AnalyticsEnhancer>[][]
>('core/analytics: Feature Enhancers');
