import { createNamedApi } from '@essent/common';

import { GetSolarPanelsProductsService } from './get-solar-panels-products.service';

export const getSolarPanelsProductsApi = createNamedApi(
  '[GetSolarPanelsProductsState] Get Solar Panels Products',
  GetSolarPanelsProductsService
);

export const {
  getSolarPanelsProductsActions,
  getSolarPanelsProducts,
  getSolarPanelsProductsClear,
  getSolarPanelsProductsError,
  getSolarPanelsProductsSuccess,
} = getSolarPanelsProductsApi;
