import { createAction } from '@ngrx/store';

import { BAC_ACTION_PREFIX } from '../constants';

export const handleBacApiErrorAction = createAction(
  `${BAC_ACTION_PREFIX} Handle BAC API error`,
  (payload: { message: string; stepName: string; showModal?: boolean }) => ({
    ...payload,
    showModal: payload.showModal ?? true,
  })
);
