import type { ActionReducerMap } from '@ngrx/store';
import { createFeatureSelector } from '@ngrx/store';
import type { AsyncState } from '@essent/common';
import type { MarketingProfile } from '@essent/marketing/profile';

import { getMarketingProfileReducer } from './marketing-profile/reducers/marketing-profile.reducers';

export const customerProfileSelectorKey = 'customer-profile';

export interface CustomerProfileState {
  marketingProfile: AsyncState<MarketingProfile>;
}

export const reducers: ActionReducerMap<CustomerProfileState> = {
  marketingProfile: getMarketingProfileReducer,
};

export const selectCustomerProfileState =
  createFeatureSelector<CustomerProfileState>(customerProfileSelectorKey);
