import type { HttpErrorResponse } from '@angular/common/http';
import { createBaseAction } from '@essent/common';

import type { FunctionalErrorItemRendering } from './functional-errors.state';

export const getFunctionalErrorAction = createBaseAction<undefined>(
  '[Functional Errors] GET'
);

export const getFunctionalErrorSuccessAction =
  createBaseAction<FunctionalErrorItemRendering>(
    '[Functional Errors] GET SUCCESS'
  );

export const getFunctionalErrorErrorAction =
  createBaseAction<HttpErrorResponse>('[Functional Errors] GET ERROR');

export const getFunctionalErrorClearAction = createBaseAction(
  '[Functional Errors] GET CLEAR'
);
