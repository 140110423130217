<ng-progress
  *wlClientOnly
  aria-label="Aan het laden..."
  ngProgressRouter
></ng-progress>
<wl-load-fonts font="Lato"></wl-load-fonts>
<wl-load-fonts font="Baton"></wl-load-fonts>
<wl-environment-classes></wl-environment-classes>
<wl-visitor-identification></wl-visitor-identification>
<wl-scroll-manager></wl-scroll-manager>
<wl-essent-header></wl-essent-header>
<router-outlet></router-outlet>
<wl-analytics-setup></wl-analytics-setup>
<wl-chatbot-iadvize [login]="false"></wl-chatbot-iadvize>
<wl-load-account *wlClientOnly></wl-load-account>
<wl-cookiewall-container></wl-cookiewall-container>
