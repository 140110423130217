import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Status } from '@essent/common';
import type { FullCalculateFormValues } from '@innogy/become-a-customer/shared';
import {
  type CalculateComponentContent,
  type CalculateComponentRendering,
} from '@innogy/become-a-customer/shared/interfaces';
import type { DropdownItem } from '@innogy/common-ui/forms';
import type { TypedComponentRendering } from '@core/jss-models';
import { FormGroupState } from 'ngrx-forms';

@Component({
  selector: 'wl-calculate-form',
  templateUrl: './calculate-form.component.html',
  styleUrls: [
    './calculate-form.component.ed.scss',
    './calculate-form.component.essent.scss',
  ],
})
export class CalculateFormComponent {
  @Input()
  rendering?: TypedComponentRendering<CalculateComponentRendering>;
  @Input({ required: true })
  content!: CalculateComponentContent;
  @Input({ required: true })
  formState!: FormGroupState<FullCalculateFormValues>;
  @Input()
  installationAddressStatus?: Status;
  @Input()
  houseNumberExtensions?: DropdownItem[];
  @Input()
  showManualHouseNumberAdditionInput?: boolean;
  @Input()
  submitButtonDisabled?: boolean;
  @Input()
  showSpinnerWhenSubmitButtonDisabled?: boolean;
  @Input()
  isInModal?: boolean;
  @Input()
  installationAddressNotFound?: boolean;

  @Output()
  submitCalculate = new EventEmitter<void>();
  @Output()
  openModal = new EventEmitter<boolean>();
  @Output()
  hideHouseNumberInput = new EventEmitter<void>();

  hideTriggerContainer = false;

  addressNextButtonClicked = false;

  public get controls() {
    return this.formState.controls;
  }

  setQuestionnaireContainerVisibility(value: boolean) {
    this.hideTriggerContainer = value;
  }

  get questionnaireContainerVisibility() {
    return this.hideTriggerContainer && this.isAddressManualTriggerActive;
  }

  updateAddressButtonClicked() {
    this.setAddressButtonClicked(false);
  }

  setAddressButtonClicked(value: boolean) {
    this.addressNextButtonClicked = value;
  }

  get isAddressManualTriggerActive() {
    return this.isAlternateAddressEntryEnabled
      ? this.addressNextButtonClicked
      : true;
  }

  get isAlternateAddressEntryEnabled() {
    return this.content?.enableAlternateAddressEntry ?? false;
  }

  get isAddressNextButtonPressed() {
    return this.addressNextButtonClicked;
  }

  get isUsageQuestionnaireTriggerEnabled() {
    return (
      (this.content?.enableUsageQuestionnaireTrigger &&
        this.isAddressManualTriggerActive) ??
      false
    );
  }

  /**
   * show the container when the
   * 1. user has not opted for manual input
   * 2. user has used questionnaire model ( form submitted)
   */
  get showUsageQuestionnaireContainer() {
    return (
      !this.isUsageQuestionnaireFormSubmitted() ||
      !this.questionnaireContainerVisibility
    );
  }
  get showCalculateFormContainer() {
    return (
      (this.isUsageQuestionnaireFormSubmitted() ||
        !this.isUsageQuestionnaireTriggerEnabled ||
        this.questionnaireContainerVisibility) &&
      this.isAddressManualTriggerActive
    );
  }

  /**
   * when a questionnaire form is submitted then
   * the controls
   * electricity usage will be valid for single meter
   * electricity usage normal or off-peak will be valid for double meter
   * @returns boolean
   */
  isUsageQuestionnaireFormSubmitted() {
    if (this.controls?.doubleMeter?.value) {
      this.hideTriggerContainer =
        this.controls.electricityUsageNormal?.isValid ||
        this.controls.electricityUsageOffPeak?.isValid ||
        this.hideTriggerContainer;
      return this.hideTriggerContainer;
    }
    // when it is a single meter
    this.hideTriggerContainer =
      this.controls?.electricityUsage?.isValid || this.hideTriggerContainer;
    return this.hideTriggerContainer;
  }

  submit(event: Event) {
    event.preventDefault();
    this.submitCalculate.emit();
  }
}
