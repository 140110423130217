import { createCollectionStateReducer } from '@innogy/utils-state';
import type { Action } from '@ngrx/store';

import {
  getFunctionalErrorAction,
  getFunctionalErrorClearAction,
  getFunctionalErrorErrorAction,
  getFunctionalErrorSuccessAction,
} from './functional-errors.actions';
import type { FunctionalErrorItemRendering } from './functional-errors.state';

const { reducer, initialState } =
  createCollectionStateReducer<FunctionalErrorItemRendering>({
    getAction: getFunctionalErrorAction,
    clearAction: getFunctionalErrorClearAction,
    errorAction: getFunctionalErrorErrorAction,
    successAction: getFunctionalErrorSuccessAction,
  });

export const functionalErrorReducer = (state = initialState, action: Action) =>
  reducer(state, action);
