import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TemporaryCoreModulesModule } from '@innogy/eplus/temporary-core-modules';
import { provideBootstrapEffects } from '@innogy/utils-state';
import { EffectsModule } from '@ngrx/effects';
import { combineReducers, StoreModule } from '@ngrx/store';

import {
  insulationReducers,
  insulationSelectorKey,
} from './+state/insulation/insulation-form.state';
import { InsulationFunnelEffects } from './+state/insulation/insulation-funnel/insulation-funnel.effects';
import { InsulationOrderFormEffects } from './+state/insulation/insulation-order-form/insulation-order-form.effects';
import { InsulationProductOverviewEffects } from './+state/insulation/insulation-products/insulation-product-overview.effects';
import { InsulationWizardEffects } from './+state/insulation/insulation-wizard/insulation-wizard.effects';
import { GenericEffects } from './+state/shared/+state/generic/generic.effects';
import {
  solarPanelsReducers,
  solarPanelsSelectorKey,
} from './+state/solar-panels-form/solar-panels-form.state';
import { SolarPanelsFunnelEffects } from './+state/solar-panels-form/solar-panels-funnel/solar-panels-funnel.effects';
import { SolarPanelsOrderFormEffects } from './+state/solar-panels-form/solar-panels-order-form/solar-panels-order-form.effects';
import { SolarPanelsProductOverviewEffects } from './+state/solar-panels-form/solar-panels-products/solar-panels-product-overview.effects';
import { SolarPanelsResultOverviewEffects } from './+state/solar-panels-form/solar-panels-result-overview/solar-panels-result-overview.effects';
import { SolarPanelsSuitabilityCheckEffects } from './+state/solar-panels-form/solar-panels-suitability-check/solar-panels-suitability-check.effects';
import { SolarPanelsWizardEffects } from './+state/solar-panels-form/solar-panels-wizard/solar-panels-wizard.effects';
import { SolarPanelsUsageQuestionnaireFormSubmitEffects } from './+state/solar-panels-form/usage-questionnaire-modal/usage-questionnaire-form-submit.effects';
import {
  storeReducers,
  storeSelectorKey,
} from './+state/store-form/store-form.state';
import { StoreFunnelEffects } from './+state/store-form/store-funnel/store-funnel.effects';
import { StoreOrderFormEffects } from './+state/store-form/store-order-form/store-order-form.effects';
import { StoreProductOverviewEffects } from './+state/store-form/store-products/store-product-overview.effects';
import {
  sustainabilityReducers,
  sustainabilitySelectorKey,
} from './+state/sustainability/sustainability.state';
import { solarPaybackSelectorKey } from './+state/solar-payback/solar-payback.state';
import { SolarPaybackEffects } from './+state/solar-payback/solar-payback.effects';
import { SolarPaybackUsageEstimateFormSubmitEffects } from './+state/solar-payback/usage-estimate-form-submit.effects';
import { solarPaybackReducer } from './+state/solar-payback/solar-payback.reducer';
import {
  heatpumpsReducers,
  heatpumpsSelectorKey,
} from './+state/heatpumps/heatpumps.state';
import { HeatpumpsFunnelEffects } from './+state/heatpumps/heatpumps-funnel/heatpumps-funnels.effects';
import { HeatpumpsWizardEffects } from './+state/heatpumps/heatpumps-wizard/heatpumps-wizard.effects';
import { HeatpumpsOrderFormEffects } from './+state/heatpumps/heatpumps-order-form/heatpumps-order-form.effects';
import { HeatpumpsQuestionnaireEffects } from './+state/heatpumps/heatpumps-questionnaire/heatpumps-questionnaire.effects';

const solarPanelsEffects = [
  SolarPanelsWizardEffects,
  SolarPanelsSuitabilityCheckEffects,
  SolarPanelsProductOverviewEffects,
  SolarPanelsResultOverviewEffects,
  SolarPanelsOrderFormEffects,
  SolarPanelsUsageQuestionnaireFormSubmitEffects,
];

const insulationEffects = [
  InsulationWizardEffects,
  InsulationProductOverviewEffects,
  InsulationOrderFormEffects,
];

const storeEffects = [StoreProductOverviewEffects];

const solarPaybackEffects = [
  SolarPaybackEffects,
  SolarPaybackUsageEstimateFormSubmitEffects,
];

const heatpumpsEffects = [
  HeatpumpsWizardEffects,
  HeatpumpsOrderFormEffects,
  HeatpumpsQuestionnaireEffects,
];

@NgModule({
  imports: [
    CommonModule,
    TemporaryCoreModulesModule,
    StoreModule.forFeature(
      solarPanelsSelectorKey,
      combineReducers(solarPanelsReducers)
    ),
    StoreModule.forFeature(
      insulationSelectorKey,
      combineReducers(insulationReducers)
    ),
    StoreModule.forFeature(
      sustainabilitySelectorKey,
      combineReducers(sustainabilityReducers)
    ),
    StoreModule.forFeature(solarPaybackSelectorKey, solarPaybackReducer),
    StoreModule.forFeature(storeSelectorKey, combineReducers(storeReducers)),
    StoreModule.forFeature(
      heatpumpsSelectorKey,
      combineReducers(heatpumpsReducers)
    ),
    EffectsModule.forFeature([
      GenericEffects,
      ...solarPanelsEffects,
      ...insulationEffects,
      ...storeEffects,
      ...solarPaybackEffects,
      ...heatpumpsEffects,
    ]),
  ],
  providers: [
    provideBootstrapEffects([
      InsulationFunnelEffects,
      SolarPanelsFunnelEffects,
      StoreFunnelEffects,
      StoreOrderFormEffects,
      SolarPaybackEffects,
      HeatpumpsFunnelEffects,
    ]),
  ],
})
export class EplusStoreModule {}
