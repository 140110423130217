import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { InfoCardType } from '@innogy/common-ui/models';
import type { ComponentRendering } from '@sitecore-jss/sitecore-jss-angular';
import {
  LinkField,
  RichTextField,
  TextField,
} from '@sitecore-jss/sitecore-jss-angular';

@Component({
  selector: 'wl-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: [
    './info-card.component.essent.scss',
    './info-card.component.ed.scss',
  ],
})
export class InfoCardComponent {
  @Input()
  title?: TextField;
  @Input()
  titleTranslationKey?: string;
  @Input()
  message?: RichTextField;
  @Input()
  messageTranslationKey?: string;
  @Input()
  messageTranslationParams?: any;
  @Input()
  automationId = 'info-card';
  @Input()
  border = false;
  @Input()
  type: InfoCardType = 'info';
  @Input()
  rendering?: ComponentRendering | any;
  @Input()
  buttonLink?: LinkField;
  @Input()
  buttonType: 'button' | 'button-outline' | 'link' = 'button';
  @Input()
  navigationExtras: NavigationExtras = {
    queryParamsHandling: 'merge',
  };
  @Input()
  trackImpressionName?: string;
  @Input()
  mode: 'multiline' | 'single-line' = 'multiline';
  /**
   * This property does nothing for ED
   */
  @Input()
  shadow = true;
  @Input()
  backgroundGrey = false;

  @Input()
  hijackButton = false;
  @Input()
  buttonText?: string;
  @Output()
  buttonClick = new EventEmitter<void>();
}
