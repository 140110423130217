import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'wl-progressive-form-submit-button',
  templateUrl: './progressive-form-submit-button.component.html',
  styleUrls: ['./progressive-form-submit-button.component.scss'],
})
export class ProgressiveFormSubmitButtonComponent {
  @Input() submitButtonAutomationId?: string;
  @Input() submitButtonText?: string;
  @Input() submitButtonIcon?: IconProp;
  @Input() submitButtonLoading?: boolean;
  @Input() isDisabled?: boolean;
  @Input() isEditingCurrentStep?: boolean;
  @Output() submitProgessiveForm: EventEmitter<void> = new EventEmitter();
  @Output() cancelEditingFormStep: EventEmitter<void> = new EventEmitter();
}
