import { Component, Input } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { ContentTheme } from '../../models';

@Component({
  selector: 'wl-partial-structured-content',
  templateUrl: './structured-content.component.html',
  styleUrls: ['./structured-content.component.ed.scss'],
})
export class StructuredContentComponent {
  @Input() theme: ContentTheme = 'default';

  @Input() icon?: IconProp;
  @Input() heading?: string;
  @Input() subheading?: string;
  @Input() paragraph?: string;

  @Input() layoutButtonsInline = false;
  @Input() centered = false;
}
