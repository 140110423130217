import { CommonModule, ViewportScroller } from '@angular/common';
import { Inject, NgModule } from '@angular/core';
import { ConfigModule, VERTICAL_SCROLL_OFFSET } from '@core/config-angular';
import { CoreAnalyticsModule } from '@innogy/core/analytics';

import { EnvironmentClassesComponent } from './environment-classes/environment-classes.component';
import { ScrollManagerComponent } from './scroll-manager/scroll-manager.component';
import { VisitorIdentificationComponent } from './sitecore-identification/sitecore-identification.component';
import { StableMeasureComponent } from './stable-measure/stable-measure.component';

const exportedComponents = [
  EnvironmentClassesComponent,
  StableMeasureComponent,
  ScrollManagerComponent,
  VisitorIdentificationComponent,
];

@NgModule({
  imports: [ConfigModule, CommonModule, CoreAnalyticsModule],
  declarations: [...exportedComponents],
  exports: [...exportedComponents],
})
export class InitializeModule {
  constructor(
    readonly viewportScroller: ViewportScroller,
    @Inject(VERTICAL_SCROLL_OFFSET) readonly verticalScrollOffset: number
  ) {
    viewportScroller.setOffset([0, verticalScrollOffset]);
  }
}
