import type {
  OrderAddressState,
  OrderConfirmationState,
  OrderPersonalDetailsState,
} from '@innogy/eplus/shared';
import {
  EplusOrderAddressFormReducer,
  EplusOrderPersonalDetailsFormReducer,
  EplusOrderConfirmationFormReducer,
  initialOrderAddressState,
  initialOrderConfirmationState,
  initialOrderPersonalDetailsState,
} from '@innogy/eplus/shared';
import { postInsulationConfirmationSuccess } from '@innogy/eplus/temporary-core-modules';
import {
  activateFormStep,
  activateProgressiveFormStepOnSubmissionOf,
  markAsInert,
  markAsyncTasksAsCompleted,
} from '@innogy/progressive-ngrx-forms';
import type { Action, ActionReducerMap } from '@ngrx/store';
import { combineReducers, on } from '@ngrx/store';
import { setValue } from 'ngrx-forms';

import { prefillInsulationOrderFormAddressStateAction } from './insulation-order-form.actions';

export const insulationOrderFormKey = 'insulationOrderForm';
export const insulationOrderAddressFormId = 'insulation-address';
export const insulationOrderPersonalDetailsFormId =
  'insulation-personal-details';
export const insulationOrderConfirmationFormId = 'insulation-confirmation';

export interface InsulationOrderFormState {
  readonly [insulationOrderAddressFormId]: OrderAddressState;
  readonly [insulationOrderPersonalDetailsFormId]: OrderPersonalDetailsState;
  readonly [insulationOrderConfirmationFormId]: OrderConfirmationState;
}

export const initialInsulationOrderFormState: InsulationOrderFormState = {
  [insulationOrderAddressFormId]: initialOrderAddressState(
    insulationOrderAddressFormId
  ),
  [insulationOrderPersonalDetailsFormId]: initialOrderPersonalDetailsState(
    insulationOrderPersonalDetailsFormId
  ),
  [insulationOrderConfirmationFormId]: initialOrderConfirmationState(
    insulationOrderConfirmationFormId
  ),
};

export const _reducers: ActionReducerMap<InsulationOrderFormState> = {
  [insulationOrderAddressFormId]: EplusOrderAddressFormReducer(
    insulationOrderAddressFormId,
    on(prefillInsulationOrderFormAddressStateAction, (state, action) => {
      const formState = setValue(state.progressiveForm.formState, {
        ...state.progressiveForm.formState.value,
        ...action.payload,
      });
      const progressiveForm = markAsInert({
        ...state.progressiveForm,
        formState,
      });

      return {
        ...state,
        progressiveForm,
      };
    })
  ),
  [insulationOrderPersonalDetailsFormId]: EplusOrderPersonalDetailsFormReducer(
    insulationOrderPersonalDetailsFormId,
    on(prefillInsulationOrderFormAddressStateAction, (state) => ({
      ...state,
      progressiveForm: activateFormStep(state.progressiveForm),
    }))
  ),
  [insulationOrderConfirmationFormId]: EplusOrderConfirmationFormReducer(
    insulationOrderConfirmationFormId,
    activateProgressiveFormStepOnSubmissionOf(
      insulationOrderPersonalDetailsFormId
    ),
    on(postInsulationConfirmationSuccess, (state) => ({
      ...state,
      progressiveForm: markAsyncTasksAsCompleted(state.progressiveForm),
    }))
  ),
};

const _combinedReducers = combineReducers(_reducers);
export const insulationOrderFormReducer = (
  state: InsulationOrderFormState = initialInsulationOrderFormState,
  action: Action
): InsulationOrderFormState => _combinedReducers(state, action);
