import { DOCUMENT } from '@angular/common';
import type { OnDestroy, OnInit } from '@angular/core';
import { Directive, Inject, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[wlAddClassToBody]',
})
export class AddClassToBodyDirective implements OnInit, OnDestroy {
  @Input()
  bodyClassName!: string;

  /**
   * TODO: Change 'any' to 'Document' and figure out why server tests are failing :  SC-11535
   */
  constructor(
    protected readonly renderer: Renderer2,
    @Inject(DOCUMENT) protected readonly document: Document
  ) {}

  ngOnInit(): void {
    this.renderer.addClass(this.document.body, this.bodyClassName);
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(this.document.body, this.bodyClassName);
  }
}
