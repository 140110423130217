import type { ValidationErrors } from 'ngrx-forms';

// International phone numbers can be 15 digits in total, including the 1-3 digit country code
export const internationalPhoneNumber = new RegExp(/^\+\d{1,3}\d{1,14}$/);
export const localPhoneNumber = new RegExp(/^0\d{9}$/);

export interface IsPhoneNumberValidator {
  actual: any;
}

declare module 'ngrx-forms/src/state' {
  export interface ValidationErrors {
    isPhoneNumber?: IsPhoneNumberValidator;
  }
}

const _IsPhoneNumber = (value?: string) => {
  if (value == null) {
    return false;
  }

  return internationalPhoneNumber.test(value) || localPhoneNumber.test(value);
};

/**
 * Validates that the given value is an PhoneNumber
 * @param value
 */
export const isPhoneNumber = (value: string): ValidationErrors => {
  if (_IsPhoneNumber(value)) {
    return {};
  }

  return {
    isPhoneNumber: { actual: value },
  };
};
