import type { AsyncState } from '@essent/common';
import { createAsyncStateReducer } from '@essent/common';
import type { PutInitiateFlowResponse } from '@innogy/become-a-customer/shared/interfaces';

import {
  putInitiateFlow,
  putInitiateFlowClear,
  putInitiateFlowError,
  putInitiateFlowSuccess,
} from './put-initiate-flow.actions';

export const putInitiateFlowReducer = createAsyncStateReducer(
  putInitiateFlow,
  putInitiateFlowSuccess,
  putInitiateFlowError,
  putInitiateFlowClear
);

export type InitiateFlowState = AsyncState<PutInitiateFlowResponse>;
