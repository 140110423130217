<aside *ngIf="timerWidgetShown" class="timer-widget">
  <img
    *ngIf="image"
    [src]="image.url"
    [alt]="image.alternativeText"
    class="timer-widget__image"
    wlAutomationId="timer-widget-image"
  />
  <div class="timer-widget__body">
    <span
      *ngIf="title"
      class="timer-widget__body__title"
      wlAutomationId="timer-widget-title"
    >
      {{ title }}
    </span>
    <span *ngIf="text" wlAutomationId="timer-widget-text">{{ text }}</span>
    <wl-timer
      [dateToCountDownTo]="dateToCountDownTo"
      [labels]="timerLabels"
      (timerEnded)="hideTimerWidget()"
    />
  </div>
</aside>
