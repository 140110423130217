import type { DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';
import type { KeyValue } from 'ngrx-forms';

import {
  FALLBACK_STEP,
  FALLBACK_STEP_NAME,
  FALLBACK_TOOL_NAME,
} from '../constants';
import type {
  ParsedToolTrackingConfig,
  ToolCompleteConfig,
  ToolTrackingCompleteConfig,
  ToolTrackingConfig,
} from './track-tool.model';

/**
 * Parses the tracking configuration for a step into an object that contains helper methods to
 * provide components and services to consistently implement tracking for this tool.
 * @param config Tracking config for the tool we want to track
 * @returns
 */
export function parseToolConfig<TStepIds extends string>(
  config: ToolTrackingConfig<TStepIds>
): ParsedToolTrackingConfig<TStepIds> {
  const _getStep = (id: TStepIds) =>
    config.steps.find((step) => step.id === id);

  return {
    toolName: config.toolName ?? FALLBACK_TOOL_NAME,
    toolComplete: config.toolComplete,
    step: (id: TStepIds) => _getStep(id)?.step ?? -1,
    stepName: (id: TStepIds) => _getStep(id)?.stepName ?? FALLBACK_STEP_NAME,
    forStep: (id: TStepIds) => {
      const stepConfig = _getStep(id);
      return {
        step: stepConfig?.step ?? -1,
        stepName: stepConfig?.stepName ?? FALLBACK_STEP,
        toolName: config.toolName ?? FALLBACK_TOOL_NAME,
      };
    },
  };
}

/**
 * Guarding function that guarantees a type-safe object will be provided for completing tools.
 * @param selector NGRX selector that provides input data to the onComplete function.
 * @param onComplete formatting function that describes in what shape to dispatch the tool-laststep event on completion of the tool.
 * @returns
 */
export function createToolStepConfig<TPayloadInput extends KeyValue>(
  selector: MemoizedSelector<
    Record<string, unknown>,
    TPayloadInput,
    DefaultProjectorFn<TPayloadInput>
  >,
  onComplete: (input: TPayloadInput) => ToolTrackingCompleteConfig
): ToolCompleteConfig {
  return {
    selector,
    onComplete,
  } as unknown as ToolCompleteConfig;
}
