import { Component, Input } from '@angular/core';

@Component({
  selector: 'wl-disclaimer[disclaimer]',
  templateUrl: './disclaimer.component.html',
  styleUrls: [
    './disclaimer.component.ed.scss',
    './disclaimer.component.essent.scss',
  ],
})
export class DisclaimerComponent {
  @Input()
  disclaimer!: string;
}
