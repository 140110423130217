import { createSelector } from '@ngrx/store';

import { eplusInsulationFeature } from '../insulation-form.state';
import { insulationWizardFormId } from './insulation-wizard.reducer';

const selectInsulationWizardState = createSelector(
  eplusInsulationFeature,
  (state) => state[insulationWizardFormId]
);

export const selectInsulationWizardViewModel = selectInsulationWizardState;

export const selectInsulationWizardFormState = createSelector(
  selectInsulationWizardState,
  (state) => state.formState
);

export const selectInsulationWizardAnalyticsPayload = createSelector(
  selectInsulationWizardFormState,
  (state) => ({
    buildingType: state.value.buildingType,
  })
);
