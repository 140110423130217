import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { provideBootstrapEffects } from '@innogy/utils-state';

import { reducer } from './+state/create-online-account.reducer';
import { createOnlineAccountSelectorKey } from './+state/create-online-account.selectors';
import { CreateOnlineAccountEffects } from './+state/create-online-account.effects';

@NgModule({
  imports: [StoreModule.forFeature(createOnlineAccountSelectorKey, reducer)],
  providers: [provideBootstrapEffects([CreateOnlineAccountEffects])],
})
export class CreateOnlineAccountStoreModule {}
