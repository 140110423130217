import { createFeatureSelector } from '@ngrx/store';

import type { SupplyAddressesEntityState } from './supply-addresses-entity.reducer';

export const supplyAddressesEntityFeatureKey = 'supplyAddressesEntity';

export const getSupplyAddressesEntityStateSelector =
  createFeatureSelector<SupplyAddressesEntityState>(
    supplyAddressesEntityFeatureKey
  );
