import type { AsyncState } from '@essent/common';
import { createAsyncStateReducer, Status } from '@essent/common';
import type { OnboardingStatus } from '@essent/contract';
import {
  getOnboardingStatus,
  getOnboardingStatusActions,
  getOnboardingStatusClear,
  getOnboardingStatusError,
  getOnboardingStatusSuccess,
} from '@essent/contract';
import { createStateStatusReducer } from '@innogy/utils-state';

export const { reducer: onboardingReducers, initialState } =
  createStateStatusReducer({
    updateAction: getOnboardingStatusActions.requestAction,
    successAction: getOnboardingStatusActions.successAction,
    errorAction: getOnboardingStatusActions.errorAction,
    clearAction: getOnboardingStatusActions.clearAction,
  });

export const reducer = createAsyncStateReducer(
  getOnboardingStatus,
  getOnboardingStatusSuccess,
  getOnboardingStatusError,
  getOnboardingStatusClear
);

export type OnboardingStatusState = AsyncState<OnboardingStatus>;

export const getOnboardingState = (state: OnboardingStatusState) => state.data;
export const getOnboardingError = (state: OnboardingStatusState) =>
  state.status === Status.ERROR;
export const getOnboardingLoading = (state: OnboardingStatusState) =>
  state.status === Status.PENDING || state.status === Status.IDLE;
export const getOnboardingLoaded = (state: OnboardingStatusState) =>
  state.status === Status.SUCCESS;
