<div class="border rounded border p-2" [class]="containerClass">
  <div
    class="d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center"
  >
    <div class="pe-1">
      <label *ngIf="title" [for]="elementId">
        <strong *scText="title"></strong>
      </label>
      <wl-tooltip
        *ngIf="tooltip"
        class="ms-1"
        [tooltip]="{
          description: tooltip,
        }"
      ></wl-tooltip>
    </div>

    <wl-toggle
      [rendering]="rendering"
      [checked]="checked"
      (toggle)="onToggle($event)"
      [toggleId]="elementId"
    ></wl-toggle>
  </div>
  <ng-container *ngIf="description">
    <div *wlGenericRichText="description"></div>
  </ng-container>
</div>
