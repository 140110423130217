<wl-phone
  [rendering]="{ componentName: trackStep.toolName }"
  [formsControl]="control"
  [fieldName]="fields.Label?.value"
  [stepName]="trackStep.stepName"
  [step]="trackStep.step"
  [label]="fields.Label?.value"
  [placeholder]="fields.Placeholder?.value"
  [tooltip]="fields.Tooltip?.value"
  [hashValue]="fields.HashValue?.value"
  [sendValue]="fields.SendValue?.value"
  [floatingLabel]="floatingLabel"
></wl-phone>
