import type { Offer } from '@innogy/become-a-customer/shared/interfaces';
import type { ProductEventType } from '@innogy/core/analytics';
import type { InnogyComponentRendering } from '@core/jss-models';
import { createAction, props } from '@ngrx/store';

const BAC_OFFER_PREFIX = `[become-a-customer-offer]`;

export const showOfferAction = createAction(`${BAC_OFFER_PREFIX} Show offer`);

export const storeSitecoreOffersetAction = createAction(
  `${BAC_OFFER_PREFIX} Store offerset from sitecore`,
  props<{ sitecoreOfferSet: string }>()
);

export const storeSitecoreAlternativeOfferset = createAction(
  `${BAC_OFFER_PREFIX} Store alternative offerset from sitecore`,
  props<{ sitecoreOfferSet: string }>()
);

export const trackProductChangeAction = createAction(
  `${BAC_OFFER_PREFIX} Track selected product change`
);

export const trackProductAction = createAction(
  `${BAC_OFFER_PREFIX} Track product analytics`,
  props<{
    eventType: ProductEventType;
    products: Offer[];
  }>()
);

export const OfferOpenCalculateModalAction = createAction(
  `${BAC_OFFER_PREFIX} Open calculate modal via offer component`,
  props<{ rendering: InnogyComponentRendering }>()
);
