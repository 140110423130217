import type { Consumption } from '@essent/new-customer';
import type { ConsumptionValuesVM } from '@innogy/become-a-customer/shared/interfaces';

export const mockConsumptionValuesVM: ConsumptionValuesVM = {
  electricity: {
    supplyLow: 1385,
    supplyNormal: 1385,
    returnSupplyLow: 0,
    returnSupplyNormal: 0,
    returnSupplyTotal: 0,
  },
  gas: 1166,
};

export const mockConsumptionValuesVMWithReturn: ConsumptionValuesVM = {
  ...mockConsumptionValuesVM,
  electricity: {
    ...mockConsumptionValuesVM.electricity,
    returnSupplyNormal: 130,
    returnSupplyLow: 114,
    returnSupplyTotal: 244,
  },
};

export const mockConsumptionValuesVMSingleMeter: ConsumptionValuesVM = {
  ...mockConsumptionValuesVM,
  electricity: {
    ...mockConsumptionValuesVM.electricity,
    supplyLow: 0,
  },
};

export const getConsumptionResponse = [
  {
    energyType: 'electricity',
    standardAnnualUsages: [
      {
        directionTariff: 'supply_low',
        reading: mockConsumptionValuesVM.electricity.supplyLow,
      },
      {
        directionTariff: 'supply_normal',
        reading: mockConsumptionValuesVM.electricity.supplyNormal,
      },
      {
        directionTariff: 'return_supply_low',
        reading: mockConsumptionValuesVM.electricity.returnSupplyLow,
      },
      {
        directionTariff: 'return_supply_normal',
        reading: mockConsumptionValuesVM.electricity.returnSupplyNormal,
      },
    ],
  },
  {
    energyType: 'gas',
    standardAnnualUsages: [
      {
        directionTariff: 'supply_normal',
        reading: mockConsumptionValuesVM.gas,
      },
    ],
  },
] as unknown as Consumption[];
