import { Injectable, NgZone } from '@angular/core';
import { Subject } from 'rxjs';

import type { InViewportConfig } from './in-viewport-config';

@Injectable()
export class InViewportService {
  private readonly _trigger$ = new Subject<IntersectionObserverEntry>();
  public readonly trigger$ = this._trigger$.asObservable();

  private observer?: IntersectionObserver;

  constructor(private readonly ngZone: NgZone) {}

  private emitTrigger(entries: IntersectionObserverEntry[]) {
    if (Array.isArray(entries) && entries.length) {
      entries.forEach((entry) => this._trigger$.next(entry));
    }
  }

  private getRootElement(element?: Element) {
    return element && element.nodeType === Node.ELEMENT_NODE
      ? element
      : undefined;
  }

  public register(target: Element, config: InViewportConfig): void {
    this.ngZone.runOutsideAngular(() => {
      const options: IntersectionObserverInit = {
        root: this.getRootElement(config.root),
        rootMargin: config.rootMargin,
        threshold: config.threshold,
      };
      this.observer = new IntersectionObserver(
        (entries: IntersectionObserverEntry[]) =>
          this.ngZone.run(() => this.emitTrigger(entries)),
        options
      );

      this.observer.observe(target);
    });
  }

  public unregister(target: Element): void {
    this.ngZone.runOutsideAngular(() => {
      if (this.observer) {
        this.observer.unobserve(target);
        this.observer.disconnect();
        this.observer = undefined;
      }
    });
  }
}
