import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  MarkAsPristineAction,
  MarkAsUntouchedAction,
  SetValueAction,
} from 'ngrx-forms';
import { mergeMap } from 'rxjs/operators';

import { resetOrderConditionsAction } from '../order.actions';
import { conditionsAcceptedFormControlID } from './order-confirmation.reducer';

@Injectable()
export class OrderConfirmationEffects {
  constructor(private readonly actions$: Actions) {}

  public resetConditionsAccepted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resetOrderConditionsAction),
      mergeMap(() => {
        return [
          new SetValueAction(conditionsAcceptedFormControlID, false),
          new MarkAsPristineAction(conditionsAcceptedFormControlID),
          new MarkAsUntouchedAction(conditionsAcceptedFormControlID),
        ];
      })
    )
  );
}
