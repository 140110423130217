import type { AsyncState } from '@essent/common';
import {
  createAsyncStateReducer,
  createInitialAsyncState,
} from '@essent/common';
import type { PostQuotationReactionResponse } from '@essent/contract';
import { postQuotationReactionActions } from '@essent/contract';
import type { InfoCardType } from '@innogy/common-ui/models';
import type { Action, ActionReducerMap } from '@ngrx/store';
import { combineReducers, createReducer, on } from '@ngrx/store';
import type { QuotationReactionMessage } from '@contracting/models';

import { setQuotationReactionMessageAction } from './quotation-reaction.actions';

export type PostQuotationReactionState =
  AsyncState<PostQuotationReactionResponse>;

export interface QuotationReactionMessageState {
  message: QuotationReactionMessage;
  messageType: InfoCardType;
}

export interface QuotationReactionState {
  postQuotationReaction: PostQuotationReactionState;
  quotationReactionMessage: QuotationReactionMessageState;
}

const initialQuotationReactionState = {
  message: {
    message: undefined,
    title: undefined,
  },
  messageType: 'info' as InfoCardType,
};

export const initialState: QuotationReactionState = {
  postQuotationReaction: createInitialAsyncState(),
  quotationReactionMessage: initialQuotationReactionState,
};

const quotationReactionMessageReducer =
  createReducer<QuotationReactionMessageState>(
    initialQuotationReactionState,
    on(
      setQuotationReactionMessageAction,
      (_state, { message, messageType = 'info' }) => ({
        message,
        messageType,
      })
    )
  );

const reducers: ActionReducerMap<QuotationReactionState, Action> = {
  postQuotationReaction: createAsyncStateReducer(postQuotationReactionActions),
  quotationReactionMessage: quotationReactionMessageReducer,
};

const _combinedReducers = combineReducers(reducers);

export function quotationReactionReducer(
  state: QuotationReactionState = initialState,
  action: Action
): QuotationReactionState {
  return _combinedReducers(state, action);
}
