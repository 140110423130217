import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { filter, map, mergeMap, tap } from 'rxjs';
import { NgbModal, type NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import {
  UsageQuestionnaireModalComponent,
  expectedConsumptionDefinedAction,
} from '@innogy/common-ui/sales';
import { mapUsageQuestionnaire } from '@innogy/eplus/shared';
import { getSolarPayback } from '@innogy/eplus/temporary-core-modules';

import { SOLAR_PAYBACK_USAGE_ESTIMATE_ID } from './solar-payback.state';
import {
  closeSolarPaybackEstimateModal,
  openSolarPaybackEstimateModal,
  setSolarPaybackEstimate,
  submitSolarPaybackUsage,
} from './solar-payback.actions';
import { selectSolarPaybackUsageEstimateFormState } from './solar-payback.selectors';

@Injectable()
export class SolarPaybackEffects {
  private estimateModal: NgbModalRef | null = null;

  constructor(
    private readonly actions$: Actions,
    private readonly modalService: NgbModal,
    private readonly store$: Store
  ) {}

  submitEnergyUsage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(submitSolarPaybackUsage),
      map((params) => getSolarPayback({ payload: { kwh: params.energyUsage } }))
    )
  );

  openModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(openSolarPaybackEstimateModal),
        tap((params) => {
          this.estimateModal = this.modalService.open(
            UsageQuestionnaireModalComponent,
            {
              centered: true,
              keyboard: true,
            }
          );
          this.estimateModal.componentInstance.formState$ = this.store$.select(
            selectSolarPaybackUsageEstimateFormState
          );
          this.estimateModal.componentInstance.rendering =
            mapUsageQuestionnaire(params.modalRendering.fields);
        })
      ),
    { dispatch: false }
  );

  closeModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(closeSolarPaybackEstimateModal),
        tap(() => {
          this.estimateModal?.close();
          this.estimateModal = null;
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect that applies the estimate base on the questionnaire modal to the solar panel form.
   */
  public applyConsumptionEstimateToSolarPaybackWizardForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(expectedConsumptionDefinedAction),
      filter((action) => action.formId === SOLAR_PAYBACK_USAGE_ESTIMATE_ID),
      mergeMap((action) => {
        return [
          setSolarPaybackEstimate({
            estimate: action.expectedConsumption.electricity.total,
          }),
          closeSolarPaybackEstimateModal(),
        ];
      })
    )
  );
}
