import type { OnInit } from '@angular/core';
import { Component, Inject, Input } from '@angular/core';
import type { ImageSettings } from '@innogy/common-ui/utility-components';
import { getImageSettingsFromRendering } from '@innogy/common-ui/utility-components';
import { InnogyComponentRendering } from '@core/jss-models';
import { ColumnContext } from '@innogy/common-ui/shared/interfaces';
import { ENVIRONMENT_CONFIG } from '@core/config-angular';
import type { EnvConfigBrand } from '@core/config-models';
import { EnvironmentConfig } from '@core/config-models';

import type { ComposableCardConfig } from '../../composables/card/card.model';
import { getComposableCardConfigFromRendering } from '../../composables/card/card.model';
import type {
  BasicContentSettings,
  BasicContentValues,
  BasicContentVariant,
  ItemAlignment,
} from '../basic-content-settings.model';
import {
  getBasicContentSettingsFromRendering,
  getBasicContentValuesFromRendering,
} from '../basic-content-settings.model';

@Component({
  selector: 'wl-basic-content-wrapper',
  templateUrl: './basic-content-wrapper.component.html',
  styleUrls: [],
})
export class BasicContentWrapperComponent implements OnInit {
  @Input() rendering?: InnogyComponentRendering;
  @Input() context?: ColumnContext; // provided through <sc-placeholder> in dynamic-row

  settings?: BasicContentSettings;
  content?: BasicContentValues;
  variant?: BasicContentVariant;
  cardConfig?: ComposableCardConfig;
  image?: ImageSettings;
  brand?: EnvConfigBrand;

  constructor(
    @Inject(ENVIRONMENT_CONFIG) private readonly _config: EnvironmentConfig
  ) {
    this.brand = this._config.brand;
  }

  ngOnInit() {
    if (this.rendering) {
      this.settings = getBasicContentSettingsFromRendering(this.rendering);
      this.variant = this.settings.variant || 'minimal';
      this.content = getBasicContentValuesFromRendering(this.rendering);
      this.image = getImageSettingsFromRendering(this.rendering);
      this.cardConfig = getComposableCardConfigFromRendering(this.rendering);
    }
  }

  get hasContent(): boolean {
    return (
      !!this.content?.heading?.text?.value ||
      !!this.content?.subheading?.text?.value ||
      !!this.content?.text?.text?.value ||
      !!this.content?.buttons?.primary?.link?.href ||
      !!this.content?.buttons?.secondary?.link?.href ||
      !!this.content?.microcopy?.text?.value
    );
  }

  isSectionVariant(itemAlignment?: ItemAlignment) {
    if (!itemAlignment) {
      return false;
    }
    const sectionAlignments: ItemAlignment[] = ['left', 'right'];
    return sectionAlignments.includes(itemAlignment);
  }
}
