import { Component, Input } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'wl-input-group',
  templateUrl: './input-group.component.html',
  styleUrls: [
    './input-group.component.ed.scss',
    './input-group.component.essent.scss',
  ],
})
export class InputGroupComponent {
  @Input() icon?: IconProp;
  @Input() suffix?: string;
  @Input() valid?: boolean;
  @Input() invalid?: boolean;
}
