import type { UploadableFile, UploadedFile } from './uploadable-file.interface';

export function isUploadableFile(
  file: UploadableFile | UploadedFile
): file is UploadableFile {
  return 'file' in file;
}

export function isUploadedFile(
  file: UploadableFile | UploadedFile
): file is UploadedFile {
  return 'documentUrl' in file;
}
