import type { EventTrack } from 'angulartics2';
import type { Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';

import type { PageInfo } from '../shared/page-info.model';
import type { AnalyticsEnhancerSources } from './analytics-enhancer-sources.service';

export const withPageTrackEnhancers =
  (sources: AnalyticsEnhancerSources) => (source$: Observable<PageInfo>) =>
    source$.pipe(concatMap((event) => sources.enhancePageInfo(event)));

export const withEventTrackEnhancers =
  (sources: AnalyticsEnhancerSources) =>
  (source$: Observable<Partial<EventTrack>>) =>
    source$.pipe(concatMap((event) => sources.enhanceEventTrack(event)));
