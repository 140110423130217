import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Offer } from '@innogy/become-a-customer/shared/interfaces';

import {
  CommodityOfferLayoutType,
  CommodityOfferLabels,
} from '../../commodity-offers.model';

@Component({
  selector: 'ed-consumer-retention-commodity-offer',
  templateUrl: './ed-consumer-retention-commodity-offer.component.html',
})
export class EdConsumerRetentionCommodityOfferComponent {
  @Input() offer?: Offer;
  @Input() content?: CommodityOfferLabels;
  @Input() variant?: CommodityOfferLayoutType;
  @Input() active = false;
  @Input() showLoadingState = false;
  @Input() showTariffs = false;

  @Output() openMoreInfo = new EventEmitter<void>();
  @Output() viewTariffModal = new EventEmitter<void>();
  @Output() selectOffer = new EventEmitter<void>();
  @Output() openTariffDropdown = new EventEmitter<void>();
  @Output() closeTariffDropdown = new EventEmitter<void>();
}
