import { Component, Input } from '@angular/core';
import type { InnogyComponentRendering } from '@core/jss-models';
import type { FaqStructuredData } from '@core/structured-data';

import type { FaqAccordionItem } from './faq.model';
import { FaqAccordionViewModel } from './faq.model';

@Component({
  selector: 'wl-faq[faqAccordionViewModel]',
  templateUrl: './faq.component.html',
  host: { ngSkipHydration: 'true' },
})
export class FaqComponent {
  @Input() faqAccordionViewModel!: FaqAccordionViewModel;

  trackByItem(_index: number, item: FaqAccordionItem): string {
    return item.id;
  }

  get items(): FaqAccordionItem[] {
    return this.faqAccordionViewModel.items;
  }

  get isVertical(): boolean {
    return this.faqAccordionViewModel.isVertical;
  }

  get feedbackSettings(): InnogyComponentRendering | undefined {
    return this.faqAccordionViewModel.feedbackSettings;
  }

  get structuredData(): FaqStructuredData {
    return this.faqAccordionViewModel.structuredData;
  }
}
