import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InnogyComponentRendering } from '@core/jss-models';

@Component({
  selector: 'wl-vertical-offer-trigger-aom-button[rendering]',
  templateUrl: './vertical-offer-trigger-aom-button.component.html',
  styleUrls: [],
})
export class VerticalOfferTriggerAomButtonComponent {
  @Input()
  public rendering!: InnogyComponentRendering;
  @Input()
  public isCompactMode?: boolean;
  @Output()
  public showTariffs = new EventEmitter<void>();

  get buttonStyle() {
    return {
      'mt-1 mb-2 p-0': this.isCompactMode,
    };
  }
}
