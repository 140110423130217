import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { type ModuleWithProviders, NgModule, Optional } from '@angular/core';
import { AppConfigService, ENVIRONMENT_CONFIG } from '@core/config-angular';
import type { EnvironmentConfig } from '@core/config-models';
import { JSS_DICTIONARY, type JssDictionaryResult } from '@core/jss-models';
import {
  provideTranslateService,
  TranslateDirective,
  TranslateLoader,
  TranslatePipe,
} from '@ngx-translate/core';

import { JssTranslationLoaderService } from './jss-translation-loader.service';

@NgModule({
  imports: [CommonModule, TranslatePipe, TranslateDirective],
  exports: [TranslatePipe, TranslateDirective],
})
export class CoreTranslateModule {
  static forRoot(): ModuleWithProviders<CoreTranslateModule> {
    const translateModuleProviders = provideTranslateService({
      loader: {
        provide: TranslateLoader,
        useFactory: (
          config: EnvironmentConfig,
          http: HttpClient,
          dictionary: JssDictionaryResult,
          appConfig: AppConfigService
        ) =>
          new JssTranslationLoaderService(config, http, appConfig, dictionary),
        deps: [
          ENVIRONMENT_CONFIG,
          HttpClient,
          [new Optional(), JSS_DICTIONARY],
          AppConfigService,
        ],
      },
    });

    return {
      ngModule: CoreTranslateModule,
      providers: [translateModuleProviders],
    };
  }
}
