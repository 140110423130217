import { Directive, HostBinding, Input } from '@angular/core';
import type { KeyValue } from 'ngrx-forms';

import { ProgressiveFormGroupState } from '../state';

/**
 * Lists the available status class names based on the property
 * they are depending on.
 */
export const PROGRESSIVE_NGRX_STATUS_CLASS_NAMES = {
  isValid: 'progressive-ngrx-forms-valid',
  isInvalid: 'progressive-ngrx-forms-invalid',
  isVisited: 'progressive-ngrx-forms-visited',
  isUnvisited: 'progressive-ngrx-forms-unvisited',
  isInert: 'progressive-ngrx-forms-inert',
  isSubmitted: 'progressive-ngrx-forms-submitted',
  isUnsubmitted: 'progressive-ngrx-forms-unsubmitted',
  isSubmittable: 'progressive-ngrx-forms-submittable',
  isUnsubmittable: 'progressive-ngrx-forms-unsubmittable',
  hasPendingAsyncActions: 'progressive-ngrx-forms-async-actions-pending',
};

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[progressiveFormStep]',
})
export class ProgressiveNgrxStatusCssClassesDirective<
  TStateValue extends KeyValue,
> {
  private state?: ProgressiveFormGroupState<TStateValue>;

  @Input()
  set progressiveFormStep(state: ProgressiveFormGroupState<TStateValue>) {
    this.state = state;
  }

  @HostBinding(`class.${PROGRESSIVE_NGRX_STATUS_CLASS_NAMES.isValid}`)
  get isValid() {
    return this.state?.formState.isValid;
  }

  @HostBinding(`class.${PROGRESSIVE_NGRX_STATUS_CLASS_NAMES.isInvalid}`)
  get isInvalid() {
    return this.state?.formState.isInvalid;
  }

  @HostBinding(`class.${PROGRESSIVE_NGRX_STATUS_CLASS_NAMES.isVisited}`)
  get isVisited() {
    return this.state?.isVisited;
  }

  @HostBinding(`class.${PROGRESSIVE_NGRX_STATUS_CLASS_NAMES.isUnvisited}`)
  get isUnvisited() {
    return this.state?.isUnvisited;
  }

  @HostBinding(`class.${PROGRESSIVE_NGRX_STATUS_CLASS_NAMES.isInert}`)
  get isInert() {
    return this.state?.isInert;
  }

  @HostBinding(`class.${PROGRESSIVE_NGRX_STATUS_CLASS_NAMES.isSubmitted}`)
  get isSubmitted() {
    return this.state?.isSubmitted;
  }

  @HostBinding(`class.${PROGRESSIVE_NGRX_STATUS_CLASS_NAMES.isUnsubmitted}`)
  get isUnsubmitted() {
    return this.state?.isUnsubmitted;
  }

  @HostBinding(`class.${PROGRESSIVE_NGRX_STATUS_CLASS_NAMES.isSubmittable}`)
  get isSubmittable() {
    return this.state?.isSubmittable;
  }

  @HostBinding(`class.${PROGRESSIVE_NGRX_STATUS_CLASS_NAMES.isUnsubmittable}`)
  get isUnsubmittable() {
    return this.state?.isUnsubmittable;
  }

  @HostBinding(
    `class.${PROGRESSIVE_NGRX_STATUS_CLASS_NAMES.hasPendingAsyncActions}`
  )
  get isValidationPending() {
    return this.state?.hasPendingAsyncActions;
  }
}
