import type { AfterViewInit } from '@angular/core';
import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[wlAutocomplete], [wlAutocompleteDisabled]',
})
export class AutocompleteDirective implements AfterViewInit {
  /**
   * We default to a random ID, so that upon applying 'wlAutocompleteDisabled' we brick autocompletion.
   * This approach is favoured over using a `role='presentational'` workaround, as this affects accessibility.
   * For more info, see:
   * https://stackoverflow.com/questions/12374442/chrome-ignores-autocomplete-off
   * https://bugs.chromium.org/p/chromium/issues/detail?id=370363#c7
   */
  @Input()
  wlAutocomplete = this.autocompleteId;

  @Input()
  wlAutocompleteDisabled: string | boolean = false;

  constructor(
    private readonly renderer: Renderer2,
    private readonly el: ElementRef
  ) {}

  get autocompleteId() {
    return Math.random().toString(36).slice(2);
  }

  ngAfterViewInit() {
    const input = this.el.nativeElement.querySelector('input');

    if (this.wlAutocomplete && input) {
      this.renderer.setAttribute(input, 'autocomplete', this.wlAutocomplete);
    }
  }
}
