import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { customerEntityReducer } from './+state/customer-entity.reducer';
import { customerEntityFeatureKey } from './+state/customer-entity.store';

@NgModule({
  imports: [
    StoreModule.forFeature(customerEntityFeatureKey, customerEntityReducer),
  ],
})
export class CustomerEntityModule {}
