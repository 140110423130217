import { createSelector } from '@ngrx/store';

import { eplusStoreFeature } from '../store-form.state';
import { storeApiKey } from './store-api.reducer';

const selectStoreApiState = createSelector(
  eplusStoreFeature,
  (state) => state[storeApiKey]
);

/**
 * Product endpoint
 */

export const selectGetStoreProductsState = createSelector(
  selectStoreApiState,
  (state) => state.getStoreProducts
);

export const selectStoreProducts = createSelector(
  selectGetStoreProductsState,
  (state) => state.data?.products ?? []
);

/**
 * Confirmation endpoint
 */

export const selectPostStoreOrderState = createSelector(
  selectStoreApiState,
  (state) => state.postStoreOrder
);
