<div
  class="text-danger input-error"
  [wlAutomationId]="automationId"
  [wlTrackError]="{
    rendering: rendering,
    field: field || label | lowercase | stripSymbols,
    description: description | lowercase | stripSymbols,
    type: errorType,
    stepName: stepName,
    step: step,
    toolName: toolName,
  }"
>
  <ng-container *ngIf="messageField">
    <wl-rich-text-wrapper [field]="{ value: field }" [rendering]="rendering">
      <small></small>
    </wl-rich-text-wrapper>
  </ng-container>
  <small *ngIf="label">{{ label }}</small>
</div>
