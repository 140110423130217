import type {
  SolarPanelsProductData,
  SolarPanelsProductWithCalculations,
} from '@innogy/eplus/temporary-core-modules';

export const mockSolarPanelsProducts: SolarPanelsProductData[] = [
  {
    performance: 360,
    performanceGuaranteedFactor: 0.82,
    performanceGuaranteedAfterYears: 25,
    productGuarantee: 20,
    content: {
      title: 'Testpaneel',
      highlight: '',
      scores: [
        {
          text: 'Materiaal',
          outOf5: 4,
        },
      ],
      text: '<h1>Supermooie unit</h1>\n<p>Met een prachtig tekstje erbij ook nog, dolle pret</p>',
      image: {
        src: 'https://tst-sitecore-essent-cd-open-consument.innogynederland.nl/-/media/energiewonen/zonnepanelen/bitmapzonnepaneel.ashx?h=260&iar=0&w=260&hash=89EED7D2D2E35CCAD3EF2483FC5811B4',
        alt: '',
      },
      subTitle: 'Mooi ding',
      usps: [
        {
          text: 'Goedkoop',
          inactive: false,
          tooltip: '',
        },
        {
          text: 'Milieuvriendelijk',
          inactive: true,
          tooltip: 'Niet Milieuvriendelijk',
        },
      ],
      productInfo: [
        {
          icon: {
            name: 'goat-icon',
            displayName: 'goat-icon',
            fields: {
              value: {
                value: 'goat-icon',
              },
            },
          },
          key: 'goat key',
          value: 'goat value',
        },
      ],
      submitButtonText: 'submit',
      submitButtonAction: 'navigate',
      submitButtonLink: { href: '/submit' },
      secondaryButtonText: 'secondary',
      secondaryButtonStyle: 'btn btn-link',
      secondaryButtonAction: 'navigate',
      secondaryButtonLink: { href: '/secondary' },
    },
    height: 1.776,
    width: 1.052,
    unitPrice: 1080,
    suiteCrmId: 'suiteCrmId1',
  },
  {
    performance: 300,
    performanceGuaranteedFactor: 0.82,
    performanceGuaranteedAfterYears: 25,
    productGuarantee: 25,
    content: {
      title: 'Testpaneel',
      highlight: 'Beste keus!',
      scores: [
        {
          text: 'Materiaal',
          outOf5: 4,
        },
      ],
      text: '<h1>Supermooie unit</h1>\n<p>Met een prachtig tekstje erbij ook nog, dolle pret</p>',
      image: {
        src: 'https://tst-sitecore-essent-cd-open-consument.innogynederland.nl/-/media/energiewonen/zonnepanelen/bitmapzonnepaneel.ashx?h=260&iar=0&w=260&hash=89EED7D2D2E35CCAD3EF2483FC5811B4',
        alt: '',
      },
      subTitle: 'Mooi ding',
      usps: [
        {
          text: 'Goedkoop',
          inactive: false,
          tooltip: '',
        },
        {
          text: 'Milieuvriendelijk',
          inactive: false,
          tooltip: '',
        },
      ],
      productInfo: [],
      submitButtonText: 'submit',
      submitButtonAction: 'navigate',
      submitButtonLink: { href: '/submit' },
      secondaryButtonText: 'secondary',
      secondaryButtonStyle: 'btn btn-link',
      secondaryButtonAction: 'navigate',
      secondaryButtonLink: { href: '/secondary' },
    },
    height: 1.719,
    width: 1.14,
    unitPrice: 750,
    suiteCrmId: 'suiteCrmId2',
  },
  {
    performance: 400,
    performanceGuaranteedFactor: 0.82,
    performanceGuaranteedAfterYears: 25,
    productGuarantee: 15,
    content: {
      title: 'Absolute Unit',
      highlight: '',
      scores: [
        {
          text: 'Materiaal',
          outOf5: 5,
        },
      ],
      text: '<h1>Supermooie unit</h1>\n<p>Met een prachtig tekstje erbij ook nog, dolle pret</p>',
      image: {
        src: 'https://tst-sitecore-essent-cd-open-consument.innogynederland.nl/-/media/energiewonen/zonnepanelen/s-energy-small.ashx?h=243&iar=0&w=125&hash=D834023973EC8C8A2E1DE3F5EE396899',
        alt: 'S-Energy zonnepanelen',
      },
      subTitle: 'Very nice',
      usps: [
        {
          text: 'Milieuvriendelijk',
          inactive: false,
          tooltip: '',
        },
      ],
      productInfo: [],
      submitButtonText: 'submit',
      submitButtonAction: 'navigate',
      submitButtonLink: { href: '/submit' },
      secondaryButtonText: 'secondary',
      secondaryButtonStyle: 'btn btn-link',
      secondaryButtonAction: 'navigate',
      secondaryButtonLink: { href: '/secondary' },
    },
    height: 1.821,
    width: 1.016,
    unitPrice: 1400,
    suiteCrmId: 'suiteCrmId3',
  },
];

export const mockSolarPanelsProductsWithCalculations: SolarPanelsProductWithCalculations[] =
  [
    {
      ...mockSolarPanelsProducts[0],
      maxPanelAmount: 2496,
      recommendation: {
        amount: 2349,
        remainingConsumption: 0,
        yearlyYield: 700092,
      },
      paybackPeriodInYears: 7,
      yearlySavings: 350000,
      costs: 2536920,
    },
    {
      ...mockSolarPanelsProducts[1],
      maxPanelAmount: 2379,
      recommendation: {
        amount: 2379,
        remainingConsumption: 122950,
        yearlyYield: 577050,
      },
      costs: 1784250,
      paybackPeriodInYears: 6,
      yearlySavings: 288525,
    },
    {
      ...mockSolarPanelsProducts[2],
      maxPanelAmount: 2521,
      recommendation: {
        amount: 2032,
        remainingConsumption: 0,
        yearlyYield: 700080,
      },
      paybackPeriodInYears: 8,
      yearlySavings: 350000,
      costs: 2844800,
    },
  ];

export const mockSolarPanelsProductWithCalculations =
  mockSolarPanelsProductsWithCalculations[0];
