import {
  createOnlineAccountClear,
  createOnlineAccountError,
  createOnlineAccountSuccess,
} from '@essent/customer';
import { validateNgrxState } from '@innogy/shared/forms';
import {
  getDateOffset,
  isDateInRange,
  isEmailAddress,
  isNumeric,
  onNgrxFormsControlId,
  validateSequential,
} from '@innogy/utils-deprecated';
import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';
import type { FormGroupState } from 'ngrx-forms';
import {
  createFormGroupState,
  MarkAsSubmittedAction,
  markAsUnsubmitted,
  onNgrxForms,
  reset,
  ResetAction,
  setValue,
  updateGroup,
  wrapReducerWithFormStateUpdate,
} from 'ngrx-forms';
import { maxLength, minLength, required } from 'ngrx-forms/validation';

export const CREATE_ONLINE_ACCOUNT_FORM_ID = 'createOnlineAccountForm';
export const emailControlId = `${CREATE_ONLINE_ACCOUNT_FORM_ID}.email`;
export const accountIdControlId = `${CREATE_ONLINE_ACCOUNT_FORM_ID}.accountId`;
export const dateOfBirthControlId = `${CREATE_ONLINE_ACCOUNT_FORM_ID}.dateOfBirth`;
export const ibanKeyControlId = `${CREATE_ONLINE_ACCOUNT_FORM_ID}.ibanKey`;

export const minimumDate = getDateOffset(0, 0, -100);
export const maximumDate = getDateOffset(0, 0, -18);

const PARTIAL_IBAN_LENGTH = 3;
const CUSTOMER_NUMBER_LENGTH = 10;

export interface CreateOnlineAccountFormState {
  email: string;
  accountId: string;
  dateOfBirth: string;
  ibanKey: string;
}

export interface CreateOnlineAccountState {
  formState: FormGroupState<CreateOnlineAccountFormState>;
  technicalError: boolean;
  validationError: boolean;
  success: boolean;
}

export const initialFormState: CreateOnlineAccountFormState = {
  accountId: '',
  dateOfBirth: '',
  ibanKey: '',
  email: '',
};

export const initialFormGroupState =
  createFormGroupState<CreateOnlineAccountFormState>(
    CREATE_ONLINE_ACCOUNT_FORM_ID,
    initialFormState
  );

export const initialState: CreateOnlineAccountState = {
  formState: initialFormGroupState,
  technicalError: false,
  validationError: false,
  success: false,
};

export const validateAndUpdateForms = (state: CreateOnlineAccountState) => {
  return updateGroup<CreateOnlineAccountFormState>({
    email: validateSequential(required, isEmailAddress),
    dateOfBirth: validateSequential(
      required,
      isDateInRange(minimumDate, maximumDate)
    ),
    ibanKey: validateSequential(
      required,
      isNumeric,
      minLength(PARTIAL_IBAN_LENGTH),
      maxLength(PARTIAL_IBAN_LENGTH)
    ),
    accountId: validateSequential(
      required,
      isNumeric,
      minLength(CUSTOMER_NUMBER_LENGTH),
      maxLength(CUSTOMER_NUMBER_LENGTH)
    ),
  })(state.formState);
};

const _reducer = createReducer(
  initialState,
  onNgrxForms(),
  onNgrxFormsControlId(ResetAction, CREATE_ONLINE_ACCOUNT_FORM_ID, (state) => {
    const createOnlineAccountFormState = setValue(
      state.formState,
      initialFormState
    );

    return {
      ...state,
      technicalError: false,
      formState: reset(createOnlineAccountFormState),
    };
  }),
  onNgrxFormsControlId(
    MarkAsSubmittedAction,
    CREATE_ONLINE_ACCOUNT_FORM_ID,
    (state) => {
      return validateNgrxState(state, validateAndUpdateForms);
    }
  ),
  on(createOnlineAccountError, (state, action) => {
    const unsubmittedFormState = markAsUnsubmitted(state.formState);

    return {
      ...state,
      formState: unsubmittedFormState,
      technicalError:
        action.payload.status >= 500 && action.payload.status <= 599,
      validationError:
        action.payload.status >= 400 && action.payload.status <= 499,
      success: false,
    };
  }),
  on(createOnlineAccountSuccess, (state) => ({
    ...state,
    success: true,
    technicalError: false,
    validationError: false,
  })),
  on(createOnlineAccountClear, () => initialState)
);

const wrappedReducer = wrapReducerWithFormStateUpdate(
  _reducer,
  (state) => state.formState,
  (_, state) => validateAndUpdateForms(state)
);

export function reducer(
  state: CreateOnlineAccountState = initialState,
  action: Action
): CreateOnlineAccountState {
  return wrappedReducer(state, action);
}
