import type { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Component, Input } from '@angular/core';
import type { IAccordionItems } from '@innogy/common-ui/utility-components';
import { IAccordion } from '@innogy/common-ui/utility-components';
import { getPageTitle } from '@core/jss-routing';
import { InnogyComponentRendering } from '@core/jss-models';
import { getFieldValue, getItemLinkFieldValue } from '@core/jss-utils';
import { Store, select } from '@ngrx/store';
import type { RichTextField } from '@sitecore-jss/sitecore-jss-angular';
import type { Item } from '@sitecore-jss/sitecore-jss/layout';

@Component({
  selector: 'wl-accordion-container',
  templateUrl: './accordion-container.component.html',
  // TODO: temporary solution to fix styling in experience editor (https://gitlab.essent.nl/Sitecoreplus/Frontend/issues/31)
  styleUrls: ['./accordion-container.component.scss'],
})
export class AccordionContainerComponent implements OnInit, OnChanges {
  @Input()
  rendering?: InnogyComponentRendering;

  @Input()
  accordions?: IAccordion;

  readonly pageTitle$ = this.store$.pipe(select(getPageTitle));

  constructor(private readonly store$: Store<any>) {}

  ngOnInit() {
    this.accordions = this.renderingToModel(this.rendering);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      this.accordions = this.renderingToModel(this.rendering);
    }
  }

  public renderingToModel(
    rendering: InnogyComponentRendering | undefined
  ): IAccordion {
    if (rendering == null || rendering.fields == null) {
      return {};
    }

    const fields = rendering.fields;
    const itemFields = fields['items'] as Item[];

    const title = getFieldValue<string>(fields, 'title');

    const items = itemFields.map((item): IAccordionItems => {
      const question = item.fields['question'] as RichTextField;
      const answer = item.fields['answer'] as RichTextField;

      return {
        question,
        answer,
        id: `item-${this.hashCode(`${question.value} ${answer.value}`)}`,
      };
    });

    const cssClass = getItemLinkFieldValue(rendering.fields, 'class', '');
    return {
      id: rendering.uid,
      title,
      items,
      class: cssClass,
    };
  }

  private hashCode(str: string): number {
    if (str.length === 0) {
      return 0;
    }

    let hash = 0;

    for (let i = 0; i < str.length; i++) {
      const currentChar = str.charCodeAt(i);
      // eslint-disable-next-line no-bitwise
      hash = (hash << 5) - hash + currentChar;
    }

    return hash;
  }
}
