import type {
  CalculateComponentContent,
  CalculateComponentRendering,
} from '@innogy/become-a-customer/shared/interfaces';
import type { TypedComponentRendering } from '@core/jss-models';
import { getFieldValue } from '@core/jss-utils';
import type {
  ImageFieldValue,
  LinkFieldValue,
} from '@sitecore-jss/sitecore-jss-angular';

export function scToCalculateContentMapper(
  rendering: TypedComponentRendering<CalculateComponentRendering>
): CalculateComponentContent {
  return {
    title: getFieldValue(rendering, 'Title'),
    subtitle: getFieldValue(rendering, 'Subtitle'),
    sticker: getFieldValue<ImageFieldValue>(rendering, 'Sticker'),
    submitLabel: getFieldValue(rendering, 'SubmitLabel'),
    placeHolderModalTitle: getFieldValue(rendering, 'PlaceHolderModalTitle'),
    isInModal: getFieldValue<boolean>(rendering, 'IsInModal', false),
    enableOfferScroll: getFieldValue<boolean>(
      rendering,
      'EnableOfferScroll',
      false
    ),
    enableUsageQuestionnaireTrigger: getFieldValue<boolean>(
      rendering,
      'EnableUsageQuestionnaireTrigger',
      false
    ),
    enableDoubleMeterCheckbox: getFieldValue<boolean>(
      rendering,
      'EnableDoubleMeterCheckbox',
      false
    ),
    addressTitle: getFieldValue(rendering, 'AddressTitle'),
    postalCodeLabel: getFieldValue(rendering, 'PostalcodeLabel'),
    postalCodePlaceholder: getFieldValue(rendering, 'PostalcodePlaceholder'),
    houseNumberLabel: getFieldValue(rendering, 'HousenumberLabel'),
    houseNumberPlaceholder: getFieldValue(rendering, 'HousenumberPlaceholder'),
    houseNumberAdditionLabel: getFieldValue(
      rendering,
      'HousenumberAdditionLabel'
    ),
    houseNumberAdditionPlaceholder: getFieldValue(
      rendering,
      'HousenumberAdditionPlaceholder'
    ),
    postalCodeRequiredError: getFieldValue(
      rendering,
      'PostalcodeRequiredError'
    ),
    postalCodeNotValidError: getFieldValue(
      rendering,
      'PostalcodeNotValidError'
    ),
    houseNumberRequiredError: getFieldValue(
      rendering,
      'HousenumberRequiredError'
    ),
    houseNumberNotValidError: getFieldValue(
      rendering,
      'HousenumberNotValidError'
    ),
    checkingAddressMessage: getFieldValue(rendering, 'CheckingAddressMessage'),
    addressNotFoundErrorTitle: getFieldValue(
      rendering,
      'AddressNotFoundErrorTitle'
    ),
    addressNotFoundErrorMessage: getFieldValue(
      rendering,
      'AddressNotFoundErrorMessage'
    ),
    usageTitle: getFieldValue(rendering, 'UsageTitle'),
    electricityUsageLabel: getFieldValue(rendering, 'ElectricityUsageLabel'),
    electricityUsagePlaceholder: getFieldValue(
      rendering,
      'ElectricityUsagePlaceholder'
    ),
    electricityUsageNormalLabel: getFieldValue(
      rendering,
      'ElectricityUsageNormalLabel'
    ),
    electricityUsageNormalPlaceholder: getFieldValue(
      rendering,
      'ElectricityUsageNormalPlaceholder'
    ),
    electricityUsageOffPeakLabel: getFieldValue(
      rendering,
      'ElectricityUsageOffPeakLabel'
    ),
    electricityUsageOffPeakPlaceholder: getFieldValue(
      rendering,
      'ElectricityUsageOffPeakPlaceholder'
    ),
    electricityUsageUnit: getFieldValue(rendering, 'ElectricityUsageUnit'),
    electricityUsageRequiredError: getFieldValue(
      rendering,
      'ElectricityUsageRequiredError'
    ),
    electricityUsageNotValidError: getFieldValue(
      rendering,
      'ElectricityUsageNotValidError'
    ),
    gasUsageLabel: getFieldValue(rendering, 'GasUsageLabel'),
    gasUsagePlaceholder: getFieldValue(rendering, 'GasUsagePlaceholder'),
    gasUsageUnit: getFieldValue(rendering, 'GasUsageUnit'),
    usageIconsTransparent: getFieldValue<boolean>(
      rendering,
      'UsageIconsTransparent',
      false
    ),
    gasUsageRequiredError: getFieldValue(rendering, 'GasUsageRequiredError'),
    gasUsageNotValidError: getFieldValue(rendering, 'GasUsageNotValidError'),
    gasUsageRequired: getFieldValue(rendering, 'GasUsageRequired', false),
    electricityUsageRequired: getFieldValue<boolean>(
      rendering,
      'ElectricityUsageRequired',
      false
    ),
    solarPanelCheckboxLabel: getFieldValue(
      rendering,
      'SolarPanelCheckboxLabel'
    ),
    solarPanelReturnLabel: getFieldValue(rendering, 'SolarPanelReturnLabel'),
    solarPanelReturnPlaceholder: getFieldValue(
      rendering,
      'SolarPanelReturnPlaceholder'
    ),
    solarPanelCheckboxTooltip: getFieldValue(
      rendering,
      'SolarPanelCheckboxTooltip'
    ),
    solarPanelReturnError: getFieldValue(rendering, 'SolarPanelReturnError'),
    doubleMeterCheckboxLabel: getFieldValue(
      rendering,
      'DoubleMeterCheckboxLabel'
    ),
    doubleMeterCheckboxTooltip: getFieldValue(
      rendering,
      'DoubleMeterCheckboxTooltip'
    ),
    estimateUsageLinkText: getFieldValue(rendering, 'EstimateUsageLinkText'),
    electricityLimitExceededError: getFieldValue(
      rendering,
      'ElectricityLimitExceededError'
    ),
    gasLimitExceededError: getFieldValue(rendering, 'GasLimitExceededError'),
    usageLimitElectricity: getFieldValue(rendering, 'UsageLimitElectricity'),
    usageLimitGas: getFieldValue(rendering, 'UsageLimitGas'),
    requiredError: getFieldValue(rendering, 'RequiredError'),
    invalidNumberError: getFieldValue(rendering, 'InvalidNumberError'),
    negativeValueError: getFieldValue(rendering, 'NegativeValueError'),
    noInputsFilledError: getFieldValue(rendering, 'NoInputsFilledError'),
    usageQuestionnaireTitle: getFieldValue(
      rendering,
      'UsageQuestionnaireTitle'
    ),
    manualButtonLabel: getFieldValue(rendering, 'ManualButtonLabel'),
    calculatorButtonLabel: getFieldValue(rendering, 'CalculatorButtonLabel'),
    estimationToolIsShown: getFieldValue<boolean>(
      rendering,
      'EstimationToolIsShown',
      false
    ),
    numberOfSolarPanelsLabel: getFieldValue(
      rendering,
      'NumberOfSolarPanelsLabel'
    ),
    numberOfSolarPanelsPlaceholder: getFieldValue(
      rendering,
      'NumberOfSolarPanelsPlaceholder'
    ),
    estimationToolIsCollapsible: getFieldValue<boolean>(
      rendering,
      'EstimationToolIsCollapsible',
      false
    ),
    collapseEstimationToolButtonText: getFieldValue(
      rendering,
      'CollapseEstimationToolButtonText'
    ),
    estimationExplanation: getFieldValue(rendering, 'EstimationExplanation'),
    returnedElectricityLabel: getFieldValue(
      rendering,
      'ReturnedElectricityLabel'
    ),
    returnedElectricityPopoverText: getFieldValue(
      rendering,
      'ReturnedElectricityPopoverText'
    ),
    returnedElectricityPlaceholder: getFieldValue(
      rendering,
      'ReturnedElectricityPlaceholder'
    ),
    returnedElectricityPeakLabel: getFieldValue(
      rendering,
      'ReturnedElectricityPeakLabel'
    ),
    returnedElectricityPeakPlaceholder: getFieldValue(
      rendering,
      'ReturnedElectricityPeakPlaceholder'
    ),
    returnedElectricityOffPeakLabel: getFieldValue(
      rendering,
      'ReturnedElectricityOffPeakLabel'
    ),
    returnedElectricityOffPeakPlaceholder: getFieldValue(
      rendering,
      'ReturnedElectricityOffPeakPlaceholder'
    ),
    missingNumericInputErrorMessage: getFieldValue(
      rendering,
      'MissingNumericInputErrorMessage'
    ),
    inputIsNotIntegerErrorMessage: getFieldValue(
      rendering,
      'InputIsNotIntegerErrorMessage'
    ),
    directOrderLink: getFieldValue<LinkFieldValue>(
      rendering,
      'DirectOrderLink'
    ),
    smartMeterCheckboxShownAsRadioButtons: getFieldValue(
      rendering,
      'SmartMeterCheckboxShownAsRadioButtons',
      false
    ),
    meterLabel: getFieldValue(rendering, 'MeterLabel'),
    regularMeterRadioButtonLabel: getFieldValue(
      rendering,
      'RegularMeterRadioButtonLabel'
    ),
    smartMeterRadioButtonLabel: getFieldValue(
      rendering,
      'SmartMeterRadioButtonLabel'
    ),
    hideMeterCheckbox: getFieldValue<boolean>(
      rendering,
      'HideMeterCheckbox',
      false
    ),
    electricityConsumptionFieldsVerticallyStacked: getFieldValue<boolean>(
      rendering,
      'ElectricityConsumptionFieldsVerticallyStacked',
      false
    ),
    estimateConsumptionButtonShownBeneathFields: getFieldValue(
      rendering,
      'EstimateConsumptionButtonShownBeneathFields',
      false
    ),
    gasHidden: getFieldValue(rendering, 'GasHidden', false),
    enableAlternateAddressEntry: getFieldValue(
      rendering,
      'EnableAlternateAddressEntry',
      false
    ),
    addressManualInputButtonLabel: getFieldValue(
      rendering,
      'AddressManualInputButtonLabel'
    ),
    updateAddressLabel: getFieldValue(rendering, 'UpdateAddressLabel'),
  };
}
