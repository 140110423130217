import type { OnDestroy } from '@angular/core';
import { Directive, HostListener, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { getComponentNameFromFields } from '../shared/utils';
import { TrackAccordionInfo } from './track-accordion.model';
import { TrackAccordionService } from './track-accordion.service';

@Directive({
  selector: '[wlTrackAccordion]',
})
export class TrackAccordionDirective implements OnDestroy {
  @Input()
  trackAccordionInfo!: TrackAccordionInfo;

  private readonly defaultEventType = 'accordion-click';
  private readonly onDestroy$ = new Subject();

  @HostListener('click', ['$event']) onClick(event: Event) {
    /**
     * stopPropagation() is used to prevent clicks from bubbling up, which
     * causes problems when nesting accordions.
     */
    event.stopPropagation();
    this.triggerAngularTics();
  }

  private triggerAngularTics() {
    const { subject, name, placement, variant } = this.trackAccordionInfo;
    const componentName = getComponentNameFromFields(placement);

    this.trackAccordionService
      .trackAccordion(componentName, this.defaultEventType, {
        subject,
        name,
        variant,
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe();
  }

  constructor(private readonly trackAccordionService: TrackAccordionService) {}

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
