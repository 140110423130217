import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgrxFormsModule } from 'ngrx-forms';

import { RestoreProgressiveFormStepDirective } from './directives/restore-progressive-form-step.directive';
import { SubmitProgressiveFormStepDirective } from './directives/submit-progressive-form-step.directive';
import { EditProgressiveFormStepDirective } from './directives/edit-progressive-form-step.directive';
import { ProgressiveFormStepDirective } from './directives/progressive-form-step.directive';
import { ProgressiveNgrxStatusCssClassesDirective } from './directives/status-css-class.directive';

const declarationsAndExports = [
  EditProgressiveFormStepDirective,
  RestoreProgressiveFormStepDirective,
  SubmitProgressiveFormStepDirective,
  ProgressiveFormStepDirective,
  ProgressiveNgrxStatusCssClassesDirective,
];
@NgModule({
  imports: [CommonModule, NgrxFormsModule],
  declarations: declarationsAndExports,
  exports: [...declarationsAndExports, NgrxFormsModule],
})
export class ProgressiveNgrxFormsModule {}
