import { provideBootstrapEffects } from '@innogy/utils-state';

import { FunnelSettingsService } from './funnel-settings.service';
import { FunnelEffects } from './funnel.effects';

export * from './funnel-settings.actions';
export * from './funnel-settings.reducer';
export * from './funnel-settings.selector';
export * from './funnel-settings.service';
export * from './funnel.effects';

export { FunnelSettingsGuard } from './funnel-settings.guard';
export { mockFunnelSettings } from './mock-funnel-settings';

export const funnelEffects = [FunnelEffects];

export const funnelProviders = provideBootstrapEffects([FunnelSettingsService]);
