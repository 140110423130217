import {
  type ComponentRendering,
  getFieldValue,
} from '@sitecore-jss/sitecore-jss-angular';

import { TimerContent } from '../../timer-wrapper/timer-wrapper.model';

export class TimerWidgetContent extends TimerContent {
  timerWidgetImage?: Image;
  timerWidgetTitle?: string;
  timerWidgetText?: string;

  constructor(rendering: ComponentRendering) {
    super(rendering);

    const timerWidgetImageData = getFieldValue<ImageData>(
      rendering,
      'TimerWidgetImage'
    );
    if (timerWidgetImageData) {
      this.timerWidgetImage = {
        url: timerWidgetImageData.src,
        alternativeText: timerWidgetImageData.alt,
      };
    }

    this.timerWidgetTitle = getFieldValue(rendering, 'TimerWidgetTitle');
    this.timerWidgetText = getFieldValue(rendering, 'TimerWidgetText');
  }
}

export interface Image {
  url: string;
  alternativeText: string;
}

export interface ImageData {
  src: string;
  alt: string;
}
