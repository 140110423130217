import type { Field } from '@sitecore-jss/sitecore-jss-angular';
import type { Item } from '@sitecore-jss/sitecore-jss/layout';
import camelCase from 'lodash/camelCase';

import { getFieldValue } from '../jss/value-helpers';

interface SimpleValueReferenceField extends Item {
  fields: {
    value: {
      value: any;
    };
  };
}

export const jssRenderingToObject = <T>(
  jssRendering: Item,
  preserveItemId = false
): T => {
  const { fields } = jssRendering;
  const obj = {} as any;

  // We just assume there's an 'id' field when we want to preserve it,
  // default typing seems to lack this field :)
  if (preserveItemId) {
    obj['id'] = (jssRendering as Item & { id: string }).id;
  }

  Object.entries(fields ?? {}).forEach(([key, field]) => {
    if (!field) {
      obj[camelCase(key)] = undefined;
      return;
    }
    if (Array.isArray(field)) {
      obj[camelCase(key)] = field.map((entry) => jssRenderingToObject(entry));
      return;
    }
    if (isSimpleValueFieldReference(field)) {
      obj[camelCase(key)] = getFieldValue(field.fields, 'value');
      return;
    }
    if (isItem(field)) {
      obj[camelCase(key)] = jssRenderingToObject(field);
      return;
    }

    obj[camelCase(key)] = getFieldValue(fields, key);
    return;
  });

  return obj as unknown as T;
};

function isSimpleValueFieldReference(
  field: Field | Item | Item[] | undefined
): field is SimpleValueReferenceField {
  return (
    (field as Item).fields !== undefined &&
    !Array.isArray((field as Item).fields) &&
    ((field as Item).fields as any).value?.value
  );
}

function isItem(field: Field | Item | Item[] | undefined): field is Item {
  return (
    (field as Item).fields !== undefined &&
    typeof (field as Item).fields === 'object' &&
    !Array.isArray((field as Item).fields)
  );
}
