import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EnvConfigType } from '@core/config-models';

import type { ConfigState } from './config.reducer';

export const configSelectorKey = 'config';
export const selectConfigFeature =
  createFeatureSelector<ConfigState>(configSelectorKey);

export const getAppConfig = createSelector(
  selectConfigFeature,
  (state) => state.config
);

export const selectEnvironmentType = createSelector(
  getAppConfig,
  (config) => config?.type
);

export const selectIsOpenEnvironment = createSelector(
  selectEnvironmentType,
  (type) => type === EnvConfigType.Open
);

export const selectIsMijnEnvironment = createSelector(
  selectEnvironmentType,
  (type) => type === EnvConfigType.Mijn
);
