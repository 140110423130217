import { Injectable, isDevMode } from '@angular/core';
import type { EventTrack } from 'angulartics2';

import type { PageInfo } from '../shared/page-info.model';
import type { AnalyticsEnhancer } from './analytics-enhancer.model';
import {
  analyticsEnhancer,
  EventTrackEnhancer,
  PageTrackEnhancer,
} from './analytics-enhancer.model';

const isPageTrackEnhancer = (e: AnalyticsEnhancer): e is PageTrackEnhancer =>
  e instanceof PageTrackEnhancer;

const isEventTrackEnhancer = (e: AnalyticsEnhancer): e is EventTrackEnhancer =>
  e instanceof EventTrackEnhancer;

@Injectable()
export class AnalyticsEnhancerSources {
  private readonly _enhancers: AnalyticsEnhancer[] = [];

  public addEnhancers(analyticsEnhancerSourceInstance: AnalyticsEnhancer) {
    if (
      analyticsEnhancer in analyticsEnhancerSourceInstance &&
      typeof analyticsEnhancerSourceInstance[analyticsEnhancer] === 'function'
    ) {
      this._enhancers.push(analyticsEnhancerSourceInstance);
    } else if (isDevMode()) {
      console.error(
        'Analytics enhancers must implement the `AnalyticsEnhancer` interface',
        analyticsEnhancerSourceInstance
      );
    }
  }

  public async enhancePageInfo(event: PageInfo): Promise<PageInfo> {
    const pageEnhancers = this._enhancers.filter(isPageTrackEnhancer);

    const promises = pageEnhancers.map((enhancer) =>
      Promise.resolve(enhancer.enhance({ ...event }))
    );

    // Resolve all pending promises concurrently, maintain injection order in result
    // Apply all changes in injection order, as if it was done synchronously.
    const results = await Promise.all(promises);
    return results.reduce(
      (acc: PageInfo, enhanced) => ({ ...acc, ...enhanced }),
      event
    );
  }

  public async enhanceEventTrack(
    event: Partial<EventTrack>
  ): Promise<Partial<EventTrack>> {
    const eventEnhancers = this._enhancers.filter(isEventTrackEnhancer);

    const promises = eventEnhancers.map((enhancer) =>
      Promise.resolve(enhancer.enhance({ ...event }))
    );

    // Resolve all pending promises concurrently, maintain injection order in result
    // Apply all changes in injection order, as if it was done synchronously.
    const results = await Promise.all(promises);
    return results.reduce((acc, enhanced) => ({ ...acc, ...enhanced }), event);
  }
}
