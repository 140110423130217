import { combineReducers, createReducer, on } from '@ngrx/store';
import { createUsageQuestionnaireReducer } from '@innogy/common-ui/sales';
import { createAsyncStateReducer } from '@essent/common';
import { getSolarPaybackActions } from '@innogy/eplus/temporary-core-modules';

import {
  SOLAR_PAYBACK_USAGE_ESTIMATE_ID,
  initialEnergyUsage,
} from './solar-payback.state';
import {
  setSolarPaybackEstimate,
  submitSolarPaybackUsage,
} from './solar-payback.actions';

const solarPaybackEstimateReducer = createReducer(
  initialEnergyUsage,
  on(setSolarPaybackEstimate, (state, params) => ({
    ...state,
    kwh: params.estimate,
  })),
  on(submitSolarPaybackUsage, (state, params) => ({
    ...state,
    kwh: params.energyUsage,
  }))
);

export const solarPaybackReducer = combineReducers({
  energyUsage: solarPaybackEstimateReducer,
  usageEstimateFormState: createUsageQuestionnaireReducer(
    SOLAR_PAYBACK_USAGE_ESTIMATE_ID
  ),
  apiState: createAsyncStateReducer(getSolarPaybackActions),
});
