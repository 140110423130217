import { createAction, props } from '@ngrx/store';

/**
 * Used to extend ngrx-forms and add an action that signifies a valid submission.
 * Simplifies unit tests and provides an extra hook for external effects to use.
 */
export const markAsValidSubmissionAction = createAction(
  `[NGRX Forms utils] Mark form submission as valid`,
  props<{ formId?: string; controlId: string }>()
);
