import { concatLatestFrom } from '@ngrx/operators';
import type { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

export const ensureFlowId = <T>(flowId$: Observable<string | undefined>) =>
  function (source$: Observable<T>) {
    return source$.pipe(
      concatLatestFrom(() => [flowId$]),
      filter((args) => {
        const flowId = [...args].pop() as string | undefined;
        return flowId !== undefined;
      }),
      //The following map is only to make typescript a happy boy.
      map((args) => args as [T, string])
    );
  };
