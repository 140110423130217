import type { SafeResourceUrl } from '@angular/platform-browser';

export interface OfferContentIncentive {
  incentiveType?: IncentiveType;
  incentiveTitle?: string;
  incentiveImage?: string;
  incentiveValue?: number;
  incentiveSummary?: string;
  incentiveFullDescription?: string;
  incentiveUsp?: string;
  incentiveInfoUrl?: SafeResourceUrl;
  actionConditionsUrl?: string;
}

export enum IncentiveType {
  Cashback = 'Cashback',
  Giftcard = 'Giftcard',
  Gift = 'Gift',
}
