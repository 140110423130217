import type { KeyValue } from 'ngrx-forms';

import type { NGRXProgressiveFormsActions } from '../actions';
import { MarkAsyncTasksAsCompletedAction } from '../actions';
import type { ProgressiveFormGroupState } from '../state';

export function markAsTasksCompletedReducer<TValue extends KeyValue>(
  state: ProgressiveFormGroupState<TValue>,
  action: NGRXProgressiveFormsActions
): ProgressiveFormGroupState<TValue> {
  if (action.type !== MarkAsyncTasksAsCompletedAction.TYPE) {
    return state;
  }

  if (action.stepId !== state.id) {
    return state;
  }

  if (!state.hasPendingAsyncActions) {
    return state;
  }

  return {
    ...state,
    hasPendingAsyncActions: false,
  };
}
