import { minutesToHours, secondsToMinutes } from 'date-fns';

import { quotient } from './timer.helpers';

export class Time {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;

  constructor(totalNumberOfSeconds?: number | null) {
    const n = totalNumberOfSeconds ?? 0;
    const totalNumberOfMinutes = secondsToMinutes(n);
    const totalNumberOfHours = minutesToHours(totalNumberOfMinutes);
    const totalNumberOfDays = quotient(totalNumberOfHours, 24);

    this.seconds = n % 60;
    this.minutes = totalNumberOfMinutes % 60;
    this.hours = totalNumberOfHours % 24;
    this.days = totalNumberOfDays;
  }
}

export interface TimerLabels {
  daysLabel: string;
  hoursLabel: string;
  minutesLabel: string;
  secondsLabel: string;
}
