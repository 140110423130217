import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';

import {
  storeSitecoreAlternativeOfferset,
  storeSitecoreOffersetAction,
} from './offer.actions';
import {
  setReadyToLoadOffersAction,
  resetReadyToLoadOffersAction,
} from '../bac/offers';

export const INITIAL_OFFER_LOADING = 'InitialOfferLoading';
export const LAZY_OFFER_LOADING = 'LazyOfferLoading';
export interface OfferState {
  sitecoreOfferSet?: string | null;
  sitecoreAlternativeOfferSet?: string | null;
  readyToLoadOffers?: boolean;
}

export const initialOfferState: OfferState = {
  sitecoreOfferSet: null,
  sitecoreAlternativeOfferSet: null,
  readyToLoadOffers: false,
};

const _reducer = createReducer<OfferState>(
  initialOfferState,
  on(storeSitecoreOffersetAction, (state, action) => ({
    ...state,
    sitecoreOfferSet: action.sitecoreOfferSet,
  })),
  on(storeSitecoreAlternativeOfferset, (state, action) => ({
    ...state,
    sitecoreAlternativeOfferSet: action.sitecoreOfferSet,
  })),
  on(setReadyToLoadOffersAction, (state) => ({
    ...state,
    readyToLoadOffers: true,
  })),
  on(resetReadyToLoadOffersAction, (state) => ({
    ...state,
    readyToLoadOffers: false,
  }))
);

export function offerReducer(
  state: OfferState = initialOfferState,
  action: Action
): OfferState {
  return _reducer(state, action);
}
