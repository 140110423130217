import type { KeyValue } from 'ngrx-forms';

import type { NGRXProgressiveFormsActions } from '../actions';
import { UnsubmitFormStepAction } from '../actions';
import type { ProgressiveFormGroupState } from '../state';

export function unsubmitReducer<TValue extends KeyValue>(
  state: ProgressiveFormGroupState<TValue>,
  action: NGRXProgressiveFormsActions
): ProgressiveFormGroupState<TValue> {
  if (action.type !== UnsubmitFormStepAction.TYPE) {
    return state;
  }

  if (action.stepId !== state.id) {
    return state;
  }

  if (state.isUnsubmitted) {
    return state;
  }

  return {
    ...state,
    isSubmitted: false,
    isUnsubmitted: true,
  };
}
