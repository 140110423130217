import { createSelector } from '@ngrx/store';
import { Status } from '@essent/common';

import type { ConsentsWithStates } from '../customer-details.model';
import { getCustomerDetailsState } from './feature.selectors';
import * as fromPreferences from './preferences.reducer';

export const getConsentsState = createSelector(
  getCustomerDetailsState,
  (feature) => feature.communicationPreferences
);

export const getConsents = createSelector(
  getConsentsState,
  fromPreferences.getConsentsList
);

export const getConsentsError = createSelector(
  getConsentsState,
  fromPreferences.getError
);

export const getConsentsStatus = createSelector(
  getConsentsState,
  fromPreferences.getStatus
);

export const getConsentsAndStates = createSelector(
  getConsents,
  getConsentsError,
  getConsentsStatus,
  (consents, consentsError, status): ConsentsWithStates => {
    const showError =
      !!consentsError || (consents && consents.length === 0) || false;
    const showLoading =
      (status === Status.PENDING || status === Status.IDLE) && !showError;
    return {
      consents,
      consentsError,
      showError,
      showLoading,
    };
  }
);
