import type { OrderInswitchInhouseOption } from '@innogy/become-a-customer/shared/interfaces';
import { BAC_COOLDOWN_PERIOD_IN_DAYS } from '@innogy/become-a-customer/shared/interfaces';
import type { EnvConfigSegment } from '@core/config-models';

import type { OrderInswitchInhouseFields } from '../../../order-fulfillment-container.model';

export const orderInswitchInhouseOptions = (
  segment: EnvConfigSegment,
  fields?: OrderInswitchInhouseFields
): OrderInswitchInhouseOption[] => {
  const isSME = segment === 'zakelijk';
  // Inswitch always at least one day ahead because of market message, for consumer + 1 day to account for half-days of previous supplier's notice period.
  const minDaysAheadInswitch = isSME ? 1 : BAC_COOLDOWN_PERIOD_IN_DAYS + 1;
  const maxDaysAheadInswitch = isSME ? 365 : 180;
  const minDaysAheadInhouse = 0;
  const maxDaysAheadInhouse = 365;
  const cooldownApplicable = !isSME;

  return [
    {
      reason: 'no_supplier',
      label: fields?.SituationNoSupplierLabel,
      type: 'inhouse',
      showStartDateInput: true,
      startDateRestrictions: {
        minimumDaysAheadStart: minDaysAheadInhouse,
        maximumDaysAheadEnd: maxDaysAheadInhouse,
      },
      cooldownApplicable,
      inswitchText: fields?.SituationNoSupplierInswitchText,
      startDateInputTooltip:
        fields?.SituationNoSupplierDatePickerTooltip?.value,
      startDateInputHint:
        fields?.SituationOtherSupplierDatePickerInfoMessage?.value,
    },
    {
      reason: 'other_supplier',
      label: fields?.SituationOtherSupplierLabel,
      type: 'inswitch',
      inswitchText: fields?.SituationOtherSupplierInswitchText,
      infoMessage: fields?.SituationOtherSupplierInfoMessage,
      showStartDateInput: true,
      startDateRestrictions: {
        minimumDaysAheadStart: minDaysAheadInswitch,
        maximumDaysAheadEnd: maxDaysAheadInswitch,
      },
      cooldownApplicable,
      startDateInputTooltip:
        fields?.SituationOtherSupplierDatePickerTooltip?.value,
    },
    {
      reason: 'retention',
      label: fields?.SituationRetentionLabel,
      infoMessage: fields?.SituationRetentionInfoMessage,
      infoMessageButtonLink: fields?.SituationRetentionInfoMessageButtonLink,
      disableFunnelAdvancement: true,
    },
    {
      reason: 'move_other_supplier',
      label: fields?.SituationMoveOtherSupplierLabel,
      type: 'inhouse',
      infoMessage: fields?.SituationMoveOtherSupplierInfoMessage,
      inswitchText: fields?.SituationMoveOtherSupplierInswitchText,
      showStartDateInput: true,
      startDateRestrictions: {
        minimumDaysAheadStart: minDaysAheadInhouse,
        maximumDaysAheadEnd: maxDaysAheadInhouse,
      },
      cooldownApplicable,
      startDateInputTooltip:
        fields?.SituationMoveOtherSupplierDatePickerTooltip?.value,
      startDateInputHint:
        fields?.SituationMoveOtherSupplierDatePickerInfoMessage?.value,
    },
    {
      reason: 'move',
      label: fields?.SituationMoveLabel,
      infoMessage: fields?.SituationMoveInfoMessage,
      infoMessageButtonLink: fields?.SituationMoveInfoMessageButtonLink,
      disableFunnelAdvancement: true,
    },
    {
      reason: 'extra_address',
      label: fields?.SituationExtraAddressLabel,
      infoMessage: fields?.SituationExtraAddressInfoMessage,
      disableFunnelAdvancement: true,
    },
  ];
};
