import type { AsyncState } from '@essent/common';
import {
  createAsyncStateReducer,
  createInitialAsyncState,
} from '@essent/common';
import type { PersonalDetails } from '@essent/new-customer';
import {
  getPersonalDetails,
  getPersonalDetailsClear,
  getPersonalDetailsError,
  getPersonalDetailsSuccess,
  putPersonalDetails,
  putPersonalDetailsClear,
  putPersonalDetailsError,
  putPersonalDetailsSuccess,
} from '@essent/new-customer';
import type { Action, ActionReducerMap } from '@ngrx/store';
import { combineReducers } from '@ngrx/store';

export type PutPersonalDetailsState = AsyncState<void>; // FIXME, should be FlowId. core modules whut? Ramon...
export type GetPersonalDetailsState = AsyncState<PersonalDetails>;

export interface PersonalDetailsState {
  putPersonalDetails: PutPersonalDetailsState;
  getPersonalDetails: GetPersonalDetailsState;
}

const initialState: PersonalDetailsState = {
  putPersonalDetails: createInitialAsyncState(),
  getPersonalDetails: createInitialAsyncState(),
};

const _putPersonalDetailsReducer = createAsyncStateReducer(
  putPersonalDetails,
  putPersonalDetailsSuccess,
  putPersonalDetailsError,
  putPersonalDetailsClear
);

const _getPersonalDetailsReducer = createAsyncStateReducer(
  getPersonalDetails,
  getPersonalDetailsSuccess,
  getPersonalDetailsError,
  getPersonalDetailsClear
);

const _reducers: ActionReducerMap<PersonalDetailsState, Action> = {
  putPersonalDetails: _putPersonalDetailsReducer,
  getPersonalDetails: _getPersonalDetailsReducer,
};

const _combinedReducers = combineReducers(_reducers);

export function personalDetailsReducer(
  state: PersonalDetailsState = initialState,
  action: Action
): PersonalDetailsState {
  return _combinedReducers(state, action);
}
