import { Component, Inject, Input, type OnInit } from '@angular/core';
import {
  earliestPossibleStartDate,
  getStartDateConfirmationRequired,
  type OrderAddressFormValues,
  selectSelectedInswitchInhouseOption,
  type StartDateOption,
  startDateOptionFormControlId,
  startDateRange,
} from '@innogy/become-a-customer/state';
import type { DropdownItem, RadioItem } from '@innogy/common-ui/forms';
import { EnvironmentConfig } from '@core/config-models';
import { ENVIRONMENT_CONFIG } from '@core/config-angular';
import { TypedComponentRendering } from '@core/jss-models';
import { getFieldValue } from '@core/jss-utils';
import { Store } from '@ngrx/store';
import { FormGroupState, SetUserDefinedPropertyAction } from 'ngrx-forms';

import type { OrderInswitchInhouseFields } from '../../../order-fulfillment-container.model';
import { getOrderInswitchInhouseDropdownOptions } from './order-fulfillment-inswitch-inhouse.helpers';

@Component({
  selector: 'wl-order-fulfillment-inswitch-inhouse',
  templateUrl: './order-fulfillment-inswitch-inhouse.component.html',
  styleUrls: [
    './order-fulfillment-inswitch-inhouse.component.ed.scss',
    './order-fulfillment-inswitch-inhouse.component.essent.scss',
  ],
})
export class OrderFulfillmentInswitchInhouseComponent implements OnInit {
  @Input() labelsShownAboveFields = false;
  @Input() formState?: FormGroupState<OrderAddressFormValues>;
  @Input() rendering?: TypedComponentRendering<OrderInswitchInhouseFields>;
  @Input() stepNumber!: number;

  fields?: OrderInswitchInhouseFields | any;
  items: DropdownItem[] | null = null;
  selectedInswitchInhouseOption = this.store.select(
    selectSelectedInswitchInhouseOption
  );

  radioButtonDescriptions: RadioItem<StartDateOption>[] = [];
  noStartDateOptionChosenErrorMessage = '';
  helpText = '';
  startDateOptionControlEnabled = false;

  get switchingSupplier() {
    return (
      this.formState?.value?.inswitchInhouse?.value?.reason === 'other_supplier'
    );
  }

  get startDateChosen() {
    return !!this.formState?.value?.selectedStartDate;
  }

  get optedForCustomStartDate() {
    return this.formState?.value?.startDateOption === 'custom';
  }

  constructor(
    private readonly store: Store,
    @Inject(ENVIRONMENT_CONFIG) private readonly envConfig: EnvironmentConfig
  ) {}

  ngOnInit(): void {
    this.fields = this.rendering?.fields;
    this.items = getOrderInswitchInhouseDropdownOptions(
      this.envConfig.segment,
      this.fields
    );

    this.store.dispatch(
      new SetUserDefinedPropertyAction(
        startDateOptionFormControlId,
        'segment',
        this.envConfig.segment
      )
    );
    const earliestPossibleStartDateString = earliestPossibleStartDate(
      this.envConfig.segment
    ).toLocaleDateString('nl', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
    const label = getFieldValue(
      this.rendering,
      'EarliestPossibleStartDateRadioButtonLabel',
      ''
    );
    const earliestPossibleStartDateRadioButtonLabel = label.replace(
      '{datum}',
      earliestPossibleStartDateString
    );
    const customStartDateRadioButtonLabel = getFieldValue(
      this.rendering,
      'CustomStartDateRadioButtonLabel',
      ''
    );
    this.radioButtonDescriptions = [
      {
        label: earliestPossibleStartDateRadioButtonLabel,
        value: 'earliestPossible',
      },
      { label: customStartDateRadioButtonLabel, value: 'custom' },
    ];
    this.noStartDateOptionChosenErrorMessage = getFieldValue(
      this.rendering,
      'NoStartDateOptionChosenErrorMessage',
      ''
    );
    this.helpText = getFieldValue(this.rendering, 'HelpText', '');
    this.startDateOptionControlEnabled = getFieldValue(
      this.rendering,
      'StartDateOptionControlEnabled',
      false
    );
  }

  get startDateRange() {
    return startDateRange(
      this.formState?.controls?.inswitchInhouse?.value?.value
        ?.startDateRestrictions
    );
  }

  get startDateConfirmationRequired() {
    return getStartDateConfirmationRequired(this.formState?.controls);
  }

  get selectedSituationLabel() {
    return (
      this.formState?.controls?.inswitchInhouse?.value?.value?.label ||
      this.fields?.DropdownPlaceholder?.value
    );
  }
}
