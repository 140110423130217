import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Status } from '@essent/common';

import type { ChattingState } from './';

export const chattingSelectorKey = 'chatting';

export const getChattingState =
  createFeatureSelector<ChattingState>(chattingSelectorKey);

export const getChatTokenState = createSelector(
  getChattingState,
  (state) => state.chatToken
);

export const getChatToken = createSelector(
  getChatTokenState,
  (state) => state.token
);

export const getIsChatTokenLoadingDone = createSelector(
  getChattingState,
  (state) => state.chatToken.status !== Status.PENDING
);
