<wl-commodity-offer-header
  [alignment]="'horizontal'"
  [headerTitle]="content?.componentTitle"
></wl-commodity-offer-header>

<wl-commodity-offer-calculation-based-on
  [variant]="'wide'"
  [label]="content?.calculationBasedOnLabel"
  [address]="supplyAddress"
  [consumptions]="consumptionValues"
  [electricityLabel]="'ElectricityUsageUnit' | translate"
  [gasLabel]="'GasUsageUnit' | translate"
  [adjustCalculationLabel]="content?.adjustCalculationLabel"
  (adjustCalculation)="adjustCalculation.emit()"
></wl-commodity-offer-calculation-based-on>
<hr class="mb-3" />
<wl-commodity-offer-list
  [offers]="offers"
  [alignment]="'horizontal'"
  [content]="content"
  [showMemberGetMember]="showMemberGetMember"
  (selectOffer)="selectOffer.emit($event)"
  (openMoreInfo)="openMoreInfo.emit($event)"
  (viewTariffs)="viewTariffs.emit($event)"
  wlAutomationId="horizontal-commodity-offer-list"
></wl-commodity-offer-list>
<wl-horizontal-commodity-offer-footer
  [productBenefits]="content?.offerFootNote"
  [contactInformation]="content?.componentFootNote"
></wl-horizontal-commodity-offer-footer>
