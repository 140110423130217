import { createAction, props } from '@ngrx/store';

export const collapseFormStepAction = createAction(
  '[Progressive Form Step] Collapse',
  props<{
    formId: string;
    stepId: string;
  }>()
);

export const finishCollapseStepAnimationAction = createAction(
  `[Progressive Form] Finished Collapse animation`,
  props<{
    formId: string;
    stepId: string;
    collapsed: boolean;
  }>()
);
