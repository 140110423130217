import type { OnInit } from '@angular/core';
import { Component, inject, Input } from '@angular/core';
import { ColumnContext } from '@innogy/common-ui/shared/interfaces';
import { ConsentService } from '@innogy/core/consent';
import { InnogyComponentRendering } from '@core/jss-models';
import { getFieldValue, getItemLinkFieldValue } from '@core/jss-utils';
import { TranslateService } from '@ngx-translate/core';
import type { Observable } from 'rxjs';
import { forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';

import type { VideoModel } from '../video.model';

@Component({
  selector: 'wl-video-container',
  templateUrl: './video-container.component.html',
  styleUrls: ['./video-container.component.scss'],
})
export class VideoContainerComponent implements OnInit {
  @Input() rendering?: InnogyComponentRendering;
  @Input() context?: ColumnContext;

  video$?: Observable<VideoModel>;

  private readonly defaultAspectRatio = 'ratio-16x9';

  readonly #translate = inject(TranslateService);
  readonly videoAllowed = inject(ConsentService).allowsTrackingCookies();

  ngOnInit() {
    this.video$ = this.renderingToModel(this.rendering);
  }

  public renderingToModel(
    rendering: InnogyComponentRendering | undefined
  ): Observable<VideoModel> {
    if (rendering == null || rendering.fields == null) {
      return of({});
    }

    const fields = rendering.fields;
    const videoId = getFieldValue<string>(fields, 'videoid');

    const youtubeUrl$ = this.#translate.get('youtubeUrl', { videoId });
    const youtubeImageUrl$ = this.#translate.get('youtubeImageUrl', {
      videoId,
    });

    return forkJoin([youtubeUrl$, youtubeImageUrl$]).pipe(
      map(([url, image]) => {
        const aspectRatio = getItemLinkFieldValue<string, string>(
          fields,
          'aspectratio',
          this.defaultAspectRatio
        );

        return {
          videoUrl: url,
          imageUrl: image,
          aspectRatio,
        };
      })
    );
  }
}
