import type { AsyncState } from '@essent/common';
import {
  createAsyncStateReducer,
  createInitialAsyncState,
} from '@essent/common';
import type { Consumption } from '@essent/new-customer';
import {
  getConsumption,
  getConsumptionClear,
  getConsumptionError,
  getConsumptionSuccess,
  putConsumption,
  putConsumptionClear,
  putConsumptionError,
  putConsumptionSuccess,
} from '@essent/new-customer';
import type { Action, ActionReducerMap } from '@ngrx/store';
import { combineReducers } from '@ngrx/store';

export type PutConsumptionState = AsyncState<void>;
export type GetConsumptionState = AsyncState<Consumption[]>;

export interface ConsumptionState {
  putConsumption: PutConsumptionState;
  getConsumption: GetConsumptionState;
}

const initialState: ConsumptionState = {
  putConsumption: createInitialAsyncState(),
  getConsumption: createInitialAsyncState(),
};

const _putConsumptionReducer = createAsyncStateReducer(
  putConsumption,
  putConsumptionSuccess,
  putConsumptionError,
  putConsumptionClear
);
const _getConsumptionReducer = createAsyncStateReducer(
  getConsumption,
  getConsumptionSuccess,
  getConsumptionError,
  getConsumptionClear
);

const _reducers: ActionReducerMap<ConsumptionState, Action> = {
  putConsumption: _putConsumptionReducer,
  getConsumption: _getConsumptionReducer,
};

const _combinedReducers = combineReducers(_reducers);

export function consumptionReducer(
  state = initialState,
  action: Action
): ConsumptionState {
  return _combinedReducers(state, action);
}
