import { Component, Input } from '@angular/core';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss-angular';

import { JumbotronHeight } from '../../../jumbotron/jumbotron-settings.model';
import type { HeaderSize } from '../../basic-content-settings.model';
import { BasicContentVariantInheritableComponent } from '../basic-content-variant.inheritable.component';

@Component({
  selector: 'wl-basic-content-variant-jumbotron',
  templateUrl: './basic-content-variant-jumbotron.component.html',
  styleUrls: [
    './basic-content-variant-jumbotron.component.ed.scss',
    './basic-content-variant-jumbotron.component.essent.scss',
  ],
})
export class BasicContentVariantJumbotronComponent extends BasicContentVariantInheritableComponent {
  @Input({ required: true }) rendering!: ComponentRendering;
  @Input() public showMarketingHeader = false;
  @Input() public height: JumbotronHeight = 'xsmall';
  @Input() timerWidgetShown = false;

  private readonly heightToHeadingSize: {
    [height in JumbotronHeight]: HeaderSize;
  } = {
    large: 'h1',
    medium: 'h2',
    small: 'h3',
    xsmall: 'h4',
  };

  private readonly heightToSubheadingSize: {
    [height in JumbotronHeight]: HeaderSize;
  } = {
    large: 'h2',
    medium: 'h3',
    small: 'h4',
    xsmall: 'h5',
  };

  override get headingSize(): HeaderSize {
    return this.heightToHeadingSize[this.height] ?? 'h1';
  }

  override get subheadingSize(): HeaderSize {
    return this.heightToSubheadingSize[this.height] ?? 'h2';
  }

  get isSmall(): boolean {
    return this.height === 'small' || this.height === 'xsmall';
  }

  get hasButtons() {
    return (
      this.content?.buttons?.primary?.link?.href ||
      this.content?.buttons?.secondary?.link?.href
    );
  }
}
