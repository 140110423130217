import { createNamedApi } from '@essent/common';

import { PostGenericFormSubmitService } from './post-generic-form-submit.service';

export const postGenericFormSubmitApi = createNamedApi(
  '[PostGenericFormSubmitState] Post Generic Form Submit',
  PostGenericFormSubmitService
);

export const {
  postGenericFormSubmitActions,
  postGenericFormSubmitSuccess,
  postGenericFormSubmitClear,
  postGenericFormSubmitError,
  postGenericFormSubmit,
} = postGenericFormSubmitApi;
