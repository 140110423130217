<ng-container *ngFor="let layer of navigationLayers; let index = index">
  <ul class="navigation-layer" [attr.data-layer]="index + 1">
    <li *ngFor="let menuItem of layer" class="navigation-layer__entry">
      <ng-container *ngIf="menuItem.children?.length">
        <button
          (click)="navItemSelected(menuItem, index)"
          class="navigation-layer__button"
          [attr.tabindex]="tabIndexForLayer(index, navigationLayers.length)"
        >
          <wl-partial-list-item
            [label]="menuItem.name"
            [iconTrailing]="'chevron-right'"
          >
          </wl-partial-list-item>
        </button>
      </ng-container>
      <ng-container *ngIf="!menuItem.children?.length">
        <a
          class="navigation-layer__link"
          [attr.tabindex]="tabIndexForLayer(index, navigationLayers.length)"
          (click)="navItemSelected(menuItem, index)"
          *wlGenericLink="{ value: { href: menuItem.path } }"
        >
          <wl-partial-list-item [label]="menuItem.name"> </wl-partial-list-item>
        </a>
      </ng-container>
    </li>
  </ul>
</ng-container>
