import { createNamedApi } from '@essent/common';

import { GetStoreProductsService } from './get-store-products.service';

export const getStoreProductsApi = createNamedApi(
  '[GetStoreProductsState] Get Store Products',
  GetStoreProductsService
);

export const {
  getStoreProductsActions,
  getStoreProducts,
  getStoreProductsClear,
  getStoreProductsError,
  getStoreProductsSuccess,
} = getStoreProductsApi;
