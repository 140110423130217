interface PaginationDeviceSplit {
  desktop: number;
  mobile: number;
}

export type PaginationMaxSize = number | PaginationDeviceSplit;
export type PaginationPageSize = number | PaginationDeviceSplit;

export interface PaginationOverrides {
  prevText?: string;
  nextText?: string;
  summaryOnMobile?: boolean;
  summaryText?: string;
}

export function getPaginationDeviceValue(
  value: PaginationMaxSize | PaginationPageSize,
  isMobile: boolean,
  defaultValue = 1
) {
  if (!value) {
    return defaultValue;
  } else if (typeof value === 'number') {
    return value;
  } else {
    return isMobile ? value.mobile : value.desktop;
  }
}
