<!-- This ngSwitch is a temporary solution to enable updating the Energiedirect cards without affecting Essent.
When we migrate Essent to the new component set, this switch will be disabled and all brands
will use the new variants currently used for ED only -->
<ng-container [ngSwitch]="brand">
  <ng-container *ngSwitchCase="'ed'">
    <ng-container *ngIf="isSectionVariant(settings?.itemAlignment)">
      <wl-basic-content-variant-media-section
        [content]="content"
        [settings]="settings"
        [context]="context"
        [image]="image"
        [rendering]="rendering"
        wlTrackImpression="contentblock-impression"
        [trackImpressionInfo]="rendering"
        [trackImpressionContext]="context"
      >
      </wl-basic-content-variant-media-section>
    </ng-container>
    <ng-container *ngIf="!isSectionVariant(settings?.itemAlignment)">
      <wl-basic-content-variant-media-card
        [content]="content"
        [settings]="settings"
        [context]="context"
        [image]="image"
        [rendering]="rendering"
        [cardConfig]="cardConfig"
        wlTrackImpression="contentblock-impression"
        [trackImpressionInfo]="rendering"
        [trackImpressionContext]="context"
      >
      </wl-basic-content-variant-media-card>
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <wl-basic-content
      [variant]="variant"
      [content]="content"
      [settings]="settings"
      [context]="context"
      [image]="image"
      [rendering]="rendering"
      [cardConfig]="cardConfig"
      wlTrackImpression="contentblock-impression"
      [trackImpressionInfo]="rendering"
      [trackImpressionContext]="context"
    >
    </wl-basic-content>
  </ng-container>
</ng-container>

<ng-container *wlShowInExperienceEditor>
  <div
    class="d-flex align-items-center justify-content-center bg-light p-2"
    *ngIf="!hasContent"
  >
    <p class="m-0">BasicContent</p>
  </div>
</ng-container>
