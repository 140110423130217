import type { AsyncState } from '@essent/common';
import {
  createAsyncStateReducer,
  createInitialAsyncState,
} from '@essent/common';
import type {
  SolarPanelsProductWithCalculations,
  SolarPanelsNewCustomerAddressCheckResponseV1,
} from '@innogy/eplus/temporary-core-modules';
import {
  getSolarPanelsAddressCheck,
  getSolarPanelsAddressCheckClear,
  getSolarPanelsAddressCheckError,
  getSolarPanelsAddressCheckSuccess,
  getSolarPanelsProductsActions,
  postSolarPanelsConfirmationActions,
} from '@innogy/eplus/temporary-core-modules';
import type { Action, ActionReducerMap } from '@ngrx/store';
import { combineReducers } from '@ngrx/store';

export const solarPanelsApiKey = 'solarPanelsAPI';

export type GetSolarPanelsNewCustomerAddressCheckState =
  AsyncState<SolarPanelsNewCustomerAddressCheckResponseV1>;
export type GetSolarPanelsProductsState = AsyncState<
  SolarPanelsProductWithCalculations[]
>;
export type PostSolarPanelsNewCustomerConfirmationState = AsyncState<void>;
export interface SolarPanelsAPIState {
  getSolarPanelsNewCustomerAddressCheck: GetSolarPanelsNewCustomerAddressCheckState;
  getSolarPanelsProducts: GetSolarPanelsProductsState;
  postSolarPanelsNewCustomerConfirmation: PostSolarPanelsNewCustomerConfirmationState;
}

export const initialSolarPanelsAPIState: SolarPanelsAPIState = {
  getSolarPanelsNewCustomerAddressCheck: createInitialAsyncState(),
  getSolarPanelsProducts: createInitialAsyncState(),
  postSolarPanelsNewCustomerConfirmation: createInitialAsyncState(),
};

const _getSolarPanelsNewCustomerAddressCheckReducer = createAsyncStateReducer(
  getSolarPanelsAddressCheck,
  getSolarPanelsAddressCheckSuccess,
  getSolarPanelsAddressCheckError,
  getSolarPanelsAddressCheckClear
);

const _getSolarPanelsProductsReducer = createAsyncStateReducer(
  getSolarPanelsProductsActions
);

const _postSolarPanelsNewCustomerConfirmationReducer = createAsyncStateReducer(
  postSolarPanelsConfirmationActions
);

const _reducers: ActionReducerMap<SolarPanelsAPIState, Action> = {
  getSolarPanelsNewCustomerAddressCheck:
    _getSolarPanelsNewCustomerAddressCheckReducer,
  getSolarPanelsProducts: _getSolarPanelsProductsReducer,
  postSolarPanelsNewCustomerConfirmation:
    _postSolarPanelsNewCustomerConfirmationReducer,
};

const _combinedReducers = combineReducers(_reducers);
export const solarPanelsAPIReducer = (
  state: SolarPanelsAPIState = initialSolarPanelsAPIState,
  action: Action
): SolarPanelsAPIState => _combinedReducers(state, action);
