import { Component, Input } from '@angular/core';
import { LinkField, TextField } from '@sitecore-jss/sitecore-jss-angular';
import { NavigationExtras } from '@angular/router';

@Component({
  selector: 'wl-modal-page-header',
  templateUrl: './modal-page-header.component.html',
  styleUrls: [
    './modal-page-header.component.ed.scss',
    './modal-page-header.component.essent.scss',
  ],
})
export class ModalPageHeaderComponent {
  @Input() public title?: TextField;
  @Input() public href?: LinkField;
  @Input() public navigationExtras?: NavigationExtras;
}
