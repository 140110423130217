import type { ActionReducer } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';

import { setEcmpHeaderAction } from './ecmp-header.actions';

export const ecmpHeaderSelectorKey = 'ecmp-header';

export interface EcmpHeaderState {
  ecmp?: string;
  description?: string;
  logo?: string;
  url?: string;
}

export const initialState = {};

export const ecmpHeaderReducer: ActionReducer<EcmpHeaderState> = createReducer(
  initialState,
  on(setEcmpHeaderAction, (state, action) => ({
    ...state,
    ...action.ecmpHeader,
  }))
);
