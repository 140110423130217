import type { AsyncState } from '@essent/common';
import {
  createAsyncStateReducer,
  createInitialAsyncState,
} from '@essent/common';
import type { InstallationAddress } from '@essent/installation-address';
import {
  getInstallationAddresses,
  getInstallationAddressesClear,
  getInstallationAddressesError,
  getInstallationAddressesSuccess,
} from '@essent/installation-address';
import type { Action } from '@ngrx/store';

export type InstallationAddressState = AsyncState<InstallationAddress[]>;

const initialState: InstallationAddressState = createInitialAsyncState();

const _installationAddressesReducer = createAsyncStateReducer(
  getInstallationAddresses,
  getInstallationAddressesSuccess,
  getInstallationAddressesError,
  getInstallationAddressesClear
);

export function installationAddressReducer(
  state: InstallationAddressState = initialState,
  action: Action
): InstallationAddressState {
  return _installationAddressesReducer(state, action);
}
