<wl-info-card
  class="d-block"
  [class.mb-3]="rendering?.fields.AddMarginBottom?.value"
  [buttonLink]="button"
  [buttonType]="rendering?.fields?.ButtonType?.value"
  [message]="rendering?.fields.Message"
  [rendering]="rendering"
  [title]="rendering?.fields.Title"
  [type]="rendering?.fields.Type?.value || 'info'"
></wl-info-card>
