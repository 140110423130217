import type {
  LinkField,
  RichTextField,
  TextField,
} from '@sitecore-jss/sitecore-jss-angular';

export const BAC_COOLDOWN_PERIOD_IN_DAYS = 14;

export type Reasons =
  | 'no_supplier'
  | 'other_supplier'
  | 'retention'
  | 'move_other_supplier'
  | 'move'
  | 'extra_address';

export interface OrderInswitchInhouseOption {
  reason: Reasons;
  label?: TextField;
  type?: 'inhouse' | 'inswitch';
  infoMessage?: RichTextField;
  infoMessageButtonLink?: LinkField;
  inswitchText?: TextField;
  showStartDateInput?: boolean;
  startDateRestrictions?: {
    minimumDaysAheadStart: number;
    maximumDaysAheadEnd: number;
  };
  startDateInputTooltip?: string;
  startDateInputHint?: string;
  cooldownApplicable?: boolean;
  disableFunnelAdvancement?: boolean;
}

export type OrderInswitchInhouseOptionDropdownItem = Omit<
  OrderInswitchInhouseOption,
  'label'
> & { label: string };
