import { QuotationReactionGuard } from '@innogy/contracting/store';
import type { ComponentNameAndModule } from '@sitecore-jss/sitecore-jss-angular';

const loadChildren = () =>
  import('./lib/contracting.lazy.module').then(
    (mod) => mod.ContractingLazyModule
  );

const contractingLazyImports: ComponentNameAndModule[] = [
  {
    path: 'EmailQuotationReactionHandler',
    loadChildren,
    canActivate: [QuotationReactionGuard],
  },
];
export { contractingLazyImports };
