<div class="returned-electricity" wlAutomationId="returned-electricity-section">
  <ng-container
    *ngIf="
      content?.estimationToolIsShown && !content?.estimationToolIsCollapsible
    "
  >
    <ng-container *ngTemplateOutlet="numberOfSolarPanelsField" />
  </ng-container>

  <fieldset
    *ngIf="
      content?.estimationToolIsShown && controls?.doubleMeter?.value;
      else returnedElectricityField
    "
  >
    <legend class="fs-6 fw-bold">
      <span>{{ content?.returnedElectricityLabel }}</span>
      <wl-tooltip
        [tooltip]="{
          description: content?.returnedElectricityPopoverText,
        }"
        class="ms-1"
      />
    </legend>
    <ng-container *ngTemplateOutlet="returnedElectricityPeakField" />
    <ng-container *ngTemplateOutlet="returnedElectricityOffPeakField" />
  </fieldset>

  <details
    *ngIf="
      content?.estimationToolIsShown && content?.estimationToolIsCollapsible
    "
    class="collapsible-estimation-tool"
  >
    <summary
      class="btn btn-link px-0 py-2 border-0"
      wlTrackLink
      [trackLinkInfo]="{
        rendering: rendering,
        eventType: 'button-click',
        field: 'ESC teruglevering inschatten',
      }"
    >
      <span>{{ content?.collapseEstimationToolButtonText }}</span>
      <fa-icon icon="chevron-down" class="d-inline-block chevron ms-1" />
    </summary>
    <p class="small mt-1 mb-3">{{ content?.estimationExplanation }}</p>
    <ng-container *ngTemplateOutlet="numberOfSolarPanelsField" />
  </details>
</div>

<ng-template #numberOfSolarPanelsField>
  <div wlAutomationId="number-of-solar-panels-field">
    <label [for]="controls?.numberOfSolarPanels?.id" class="fw-bold">
      {{ content?.numberOfSolarPanelsLabel }}
    </label>
    <wl-input-group
      icon="solar-panel"
      [valid]="controls?.numberOfSolarPanels | isValid"
      [invalid]="controls?.numberOfSolarPanels | isInvalid"
    >
      <wl-number
        [formsControl]="controls?.numberOfSolarPanels"
        [placeholder]="content?.numberOfSolarPanelsPlaceholder ?? ''"
        class="flex-grow-1"
        [step]="0.1"
        fieldName="aantal zonnepanelen"
        stepName="teruglevering inschatten"
        toolName="verbruik inschatten"
      />
    </wl-input-group>
    <ng-container *ngIf="controls?.numberOfSolarPanels | isInvalid">
      <wl-input-error
        *ngIf="controls?.numberOfSolarPanels?.errors?.isInt"
        [messageField]="content?.inputIsNotIntegerErrorMessage"
      />
      <wl-input-error
        *ngIf="controls?.numberOfSolarPanels?.errors?.greaterThan"
        [messageField]="content?.negativeValueError"
      />
    </ng-container>
  </div>
</ng-template>

<ng-template #returnedElectricityField>
  <div wlAutomationId="returned-electricity-field">
    <label
      *ngIf="content?.estimationToolIsShown"
      [for]="controls?.electricityReturn?.id"
      class="fw-bold"
    >
      <span>{{ content?.returnedElectricityLabel }}</span>
      <wl-tooltip
        [tooltip]="{
          description: content?.returnedElectricityPopoverText,
        }"
        class="ms-1"
      />
    </label>
    <wl-input-group
      icon="solar-panel"
      [suffix]="content?.electricityUsageUnit"
      [valid]="controls?.electricityReturn | isValid"
      [invalid]="controls?.electricityReturn | isInvalid"
    >
      <wl-number
        [formsControl]="controls?.electricityReturn"
        [label]="
          !content?.estimationToolIsShown
            ? (content?.returnedElectricityLabel ?? '')
            : ''
        "
        [placeholder]="content?.returnedElectricityPlaceholder ?? ''"
        class="flex-grow-1"
        [step]="0.1"
        fieldName="teruglevering jaar"
        stepName="invullen"
        toolName="keuzehulp"
      />
    </wl-input-group>
    <ng-container *ngIf="controls?.electricityReturn | isInvalid">
      <wl-input-error
        *ngIf="controls?.electricityReturn?.errors?.requiredNumber"
        [messageField]="content?.missingNumericInputErrorMessage"
      />
      <wl-input-error
        *ngIf="controls?.electricityReturn?.errors?.isInt"
        [messageField]="content?.inputIsNotIntegerErrorMessage"
      />
      <wl-input-error
        *ngIf="controls?.electricityReturn?.errors?.greaterThan"
        [messageField]="content?.negativeValueError"
      />
    </ng-container>
  </div>
</ng-template>

<ng-template #returnedElectricityPeakField>
  <div class="mb-1" wlAutomationId="returned-peak-electricity-field">
    <wl-input-group
      icon="solar-panel"
      [suffix]="content?.electricityUsageUnit"
      [valid]="controls?.returnedElectricityPeak | isValid"
      [invalid]="controls?.returnedElectricityPeak | isInvalid"
    >
      <wl-number
        [formsControl]="controls?.returnedElectricityPeak"
        [label]="content?.returnedElectricityPeakLabel ?? ''"
        [placeholder]="content?.returnedElectricityPeakPlaceholder ?? ''"
        class="flex-grow-1"
        [step]="0.1"
        fieldName="teruglevering jaar piek"
        stepName="invullen"
        toolName="keuzehulp"
      />
    </wl-input-group>
    <ng-container *ngIf="controls?.returnedElectricityPeak | isInvalid">
      <wl-input-error
        *ngIf="controls?.returnedElectricityPeak?.errors?.requiredNumber"
        [messageField]="content?.missingNumericInputErrorMessage"
      />
      <wl-input-error
        *ngIf="controls?.returnedElectricityPeak?.errors?.isInt"
        [messageField]="content?.inputIsNotIntegerErrorMessage"
      />
      <wl-input-error
        *ngIf="controls?.returnedElectricityPeak?.errors?.greaterThan"
        [messageField]="content?.negativeValueError"
      />
    </ng-container>
  </div>
</ng-template>

<ng-template #returnedElectricityOffPeakField>
  <div wlAutomationId="returned-off-peak-electricity-field">
    <wl-input-group
      icon="solar-panel"
      [suffix]="content?.electricityUsageUnit"
      [valid]="controls?.returnedElectricityOffPeak | isValid"
      [invalid]="controls?.returnedElectricityOffPeak | isInvalid"
    >
      <wl-number
        [formsControl]="controls?.returnedElectricityOffPeak"
        [label]="content?.returnedElectricityOffPeakLabel ?? ''"
        [placeholder]="content?.returnedElectricityOffPeakPlaceholder ?? ''"
        class="flex-grow-1"
        [step]="0.1"
        fieldName="teruglevering jaar dal"
        stepName="invullen"
        toolName="keuzehulp"
      />
    </wl-input-group>
    <ng-container *ngIf="controls?.returnedElectricityOffPeak | isInvalid">
      <wl-input-error
        *ngIf="controls?.returnedElectricityOffPeak?.errors?.requiredNumber"
        [messageField]="content?.missingNumericInputErrorMessage"
      />
      <wl-input-error
        *ngIf="controls?.returnedElectricityOffPeak?.errors?.isInt"
        [messageField]="content?.inputIsNotIntegerErrorMessage"
      />
      <wl-input-error
        *ngIf="controls?.returnedElectricityOffPeak?.errors?.greaterThan"
        [messageField]="content?.negativeValueError"
      />
    </ng-container>
  </div>
</ng-template>
