import type {
  HeatpumpsFunnelSettingsInterface,
  HeatpumpsFunnelStep,
} from '@innogy/eplus/models';
import type { GenericProductData } from '@innogy/common-ui/models';
import { createAction } from '@ngrx/store';

import { createNamedFunnelActions } from '../../shared/generators';

const funnelType = 'Heatpumps';
const PREFIX = `[${funnelType} Funnel]`;

export const {
  initializeHeatpumpsFunnelStepAction,
  clearHeatpumpsFunnelInitializedAction,
  setHeatpumpsFunnelInitializedAction,
  onHeatpumpsFunnelNavigationAction,
  handleHeatpumpsFunnelGenericErrorAction,
  handleHeatpumpsFunnelPrivateErrorAction,
  selectHeatpumpsProductAction,
  resetHeatpumpsProductSelectionAction,
  mintHeatpumpsTrackingIdAction,
} = createNamedFunnelActions<
  typeof funnelType,
  HeatpumpsFunnelStep,
  HeatpumpsFunnelSettingsInterface,
  GenericProductData
>(funnelType);

export const handleHeatpumpsFunnelAddressNotFoundErrorAction = createAction(
  `${PREFIX} Address not found Error`
);
