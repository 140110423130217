import type { FormAddress } from '@innogy/common-ui/forms';
import { createAction, props } from '@ngrx/store';

import { createNamedOrderFormActions } from '../../shared/generators';

const feature = 'Insulation';
const PREFIX = `[${feature} Order Form]`;

export const { resetInsulationOrderFormAction } =
  createNamedOrderFormActions<typeof feature>(feature);

export const prefillInsulationOrderFormAddressStateAction = createAction(
  `${PREFIX} Prefill address form data`,
  props<{ payload: Partial<FormAddress> }>()
);
