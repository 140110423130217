import type { AsyncState } from '@essent/common';
import { Status } from '@essent/common';
import type { SupplyAddress } from '@essent/supply-address-v3';
import {
  getSupplyAddresses,
  getSupplyAddressesClear,
  getSupplyAddressesError,
  getSupplyAddressesSuccess,
} from '@essent/supply-address-v3';
import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';

import { setMonths } from './supply-addresses-entity.actions';

export interface SupplyAddressEntries {
  [key: string]: SupplyAddress;
}

export interface SupplyAddressesEntityState
  extends AsyncState<SupplyAddress[]> {
  entities: SupplyAddressEntries;
  ids: string[];
  fetchNumberOfMonths: number;
}

export const initialState: SupplyAddressesEntityState = {
  status: Status.IDLE,
  entities: {},
  ids: [],
  fetchNumberOfMonths: 3,
};

const _reducer = createReducer(
  initialState,
  on(getSupplyAddresses, (state) => {
    return {
      ...state,
      status: Status.PENDING,
    };
  }),
  on(getSupplyAddressesSuccess, (state, action) => {
    const entities = action.payload.reduce<SupplyAddressEntries>(
      (prev, cur) => {
        prev[cur.supplyAddressId] = cur;
        return prev;
      },
      {}
    );

    return {
      ...state,
      status: Status.SUCCESS,
      entities,
      ids: Object.keys(entities),
      actionId: action.actionId,
    };
  }),
  on(getSupplyAddressesError, (state, action) => {
    return {
      ...state,
      status: Status.ERROR,
      error: action.payload,
      actionId: action.actionId,
    };
  }),
  on(getSupplyAddressesClear, () => initialState),
  on(setMonths, (state, action) => ({
    ...state,
    fetchNumberOfMonths: action.payload.numberOfMonths,
  }))
);

export function supplyAddressesEntityReducer(
  state = initialState,
  action: Action
): SupplyAddressesEntityState {
  return _reducer(state, action);
}

export const getStatus = (state: SupplyAddressesEntityState) => state.status;
export const getEntities = (state: SupplyAddressesEntityState) =>
  state.entities;
export const getIds = (state: SupplyAddressesEntityState) => state.ids;
export const getError = (state: SupplyAddressesEntityState) => state.error;
export const getLoading = (state: SupplyAddressesEntityState) =>
  state.status === Status.IDLE || state.status === Status.PENDING;
