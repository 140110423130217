<ng-container
  *ngIf="
    experienceEditorActive$ | async as active;
    then previewRef;
    else contentRef
  "
>
</ng-container>
<ng-template #contentRef>
  <ng-template [ngTemplateOutlet]="content?.templateRef"></ng-template>
</ng-template>
<ng-template #previewRef>
  <ng-template [ngTemplateOutlet]="preview?.templateRef"></ng-template>
</ng-template>
