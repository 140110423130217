import { Component, Input } from '@angular/core';
import { Offer } from '@innogy/become-a-customer/shared/interfaces';
import { RichTextField, TextField } from '@sitecore-jss/sitecore-jss-angular';

@Component({
  selector: 'essent-order-overview-header',
  templateUrl: './order-overview-header.component.html',
  styleUrls: ['./order-overview-header.component.scss'],
})
export class OrderOverviewHeaderComponent {
  @Input()
  offer?: Offer;
  @Input()
  monthlyAmountLabel!: TextField;
  @Input()
  monthlyAmountTooltip = '';
  @Input()
  budgetBillLabel!: RichTextField;
  @Input()
  budgetBillTooltip = '';
  @Input()
  showEditButton = false;
  @Input()
  fluidContainer = false;

  @Input()
  showCostsInHeader = false;
}
