<wl-modal
  (closeModal)="dismissModal()"
  [modalTitle]="rendering?.fields?.ModalAdjustCalculationTitle?.value"
>
  <ng-template wlModalBody>
    <ng-container *ngIf="calculateContent">
      <wl-calculate-container
        [content]="calculateContent"
        [isInModal]="true"
      ></wl-calculate-container>
    </ng-container>
  </ng-template>
</wl-modal>
