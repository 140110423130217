import type { ValidationErrors } from 'ngrx-forms';

import { valueToNumber } from '../converters/value-to-number';

export interface IsNumberValidator {
  actual: any;
}

declare module 'ngrx-forms/src/state' {
  export interface ValidationErrors {
    isNumeric?: IsNumberValidator;
  }
}

export const isNumericValue = <T>(value: T) => {
  const parsedValue = valueToNumber(value);
  return parsedValue != null && !isNaN(parsedValue);
};

/**
 * Validates that the given value is a number
 * @param value
 */
export const isNumeric = <T>(value: T): ValidationErrors => {
  if (isNumericValue(value)) {
    return {};
  }

  return {
    isNumeric: { actual: value },
  };
};
