import type { OnDestroy } from '@angular/core';
import { Directive, HostListener, Input } from '@angular/core';
import { getFieldValue } from '@sitecore-jss/sitecore-jss-angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TrackLinkInfo } from './track-link.model';
import { TrackLinkService } from './track-link.service';

@Directive({
  selector: '[wlTrackLink]',
})
export class TrackLinkDirective implements OnDestroy {
  @Input('wlTrackLink')
  trackLink = 'unknown-link';
  private readonly defaultEventType = 'link-click';

  @Input()
  trackLinkInfo: TrackLinkInfo = {};

  private readonly onDestroy$ = new Subject();

  @HostListener('click') onClick() {
    this.triggerAngularTics();
  }

  private triggerAngularTics() {
    const rendering = this.trackLinkInfo.rendering;
    if (rendering == null) {
      return;
    }
    const componentName =
      getFieldValue<string>(rendering, 'componentName') ??
      rendering?.componentName ??
      'unknown';

    this.trackLinkService
      .trackLink(
        componentName,
        this.trackLinkInfo.eventType || this.defaultEventType,
        this.trackLinkInfo.field,
        this.trackLinkInfo.type,
        this.trackLinkInfo.linkurl,
        this.trackLinkInfo.position
      )
      .pipe(takeUntil(this.onDestroy$))
      .subscribe();
  }

  constructor(private readonly trackLinkService: TrackLinkService) {}

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
