import { Component, Inject, Input } from '@angular/core';
import { OfferOverview } from '@innogy/become-a-customer/shared/interfaces';
import { Store } from '@ngrx/store';
import { hasMemberGetMember } from '@innogy/become-a-customer/state';
import {
  getMGMSharedValue,
  getOrderOverviewFields,
  getYearlyCostsLabel,
} from '@innogy/become-a-customer/shared';
import { ENVIRONMENT_CONFIG } from '@core/config-angular';
import { EnvironmentConfig } from '@core/config-models';
import type { ComponentFields } from '@sitecore-jss/sitecore-jss-angular';
import { getFieldValue } from '@core/jss-utils';

import { BaseOrderOverviewComponent } from '../base-order-overview/base-order-overview.component';

@Component({
  selector: 'essent-order-overview',
  templateUrl: './order-overview-essent.component.html',
  styleUrls: ['./order-overview-essent.component.scss'],
})
export class OrderOverviewEssentComponent extends BaseOrderOverviewComponent {
  @Input()
  public electricityOverview?: OfferOverview;
  @Input()
  public gasOverview?: OfferOverview;
  @Input() retentionStickyView = false;

  public isCollapsed = true;

  hasMemberGetMember$ = this.store$.select(hasMemberGetMember);

  constructor(
    protected readonly store$: Store<any>,
    @Inject(ENVIRONMENT_CONFIG)
    private readonly environmentConfig: EnvironmentConfig
  ) {
    super();
  }

  displayMGM(hasMGM: boolean): boolean {
    return hasMGM && !!this.mgmSharedValue;
  }

  get mgmSharedValue() {
    return getMGMSharedValue(this.offer, this.environmentConfig.brand);
  }

  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }

  get fields(): ComponentFields | any {
    return this.rendering?.fields;
  }

  get yearlyCostLabel() {
    return getYearlyCostsLabel(
      ...getOrderOverviewFields(this.rendering),
      this.offer
    );
  }

  get showCostsInHeader() {
    return getFieldValue<boolean>(this.rendering, 'showCostsInHeader', false);
  }
}
