import type { FlowId } from '@essent/new-customer';
import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';

import { setFlowIdAction } from './flow-id.actions';
import { setShouldExtendTimeToLiveAction } from '../6.extend-time-to-live';

export type FlowIdState = Partial<FlowId> & {
  extendTimeToLive: boolean;
};

const initialState: FlowIdState = { extendTimeToLive: false };

const _reducer = createReducer(
  initialState,
  on(setFlowIdAction, (state, { flowId }) => ({ ...state, flowId })),
  on(setShouldExtendTimeToLiveAction, (state, { extendTimeToLive }) => ({
    ...state,
    extendTimeToLive,
  }))
);

export function flowIdReducer(
  state: FlowIdState = initialState,
  action: Action
): FlowIdState {
  return _reducer(state, action);
}
