import { Component, Input } from '@angular/core';
import { Offer } from '@innogy/become-a-customer/shared/interfaces';

@Component({
  selector: 'wl-offer-label',
  templateUrl: './offer-label.component.html',
  styleUrls: [
    './offer-label.component.ed.scss',
    './offer-label.component.essent.scss',
  ],
})
export class OfferLabelComponent {
  @Input()
  public offer?: Offer;
}
