import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ensureArray } from '@essent/core-utils';
import {
  getFirstFocusControlName,
  markAsValidSubmissionAction,
  ScrollService,
} from '@innogy/utils-deprecated';
import { isNotNullish } from '@innogy/utils-rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { select, Store } from '@ngrx/store';
import {
  DisableAction,
  EnableAction,
  FocusAction,
  MarkAsDirtyAction,
  MarkAsSubmittedAction,
  MarkAsTouchedAction,
  MarkAsUnsubmittedAction,
  SetValueAction,
} from 'ngrx-forms';
import type { Observable } from 'rxjs';
import { EMPTY, timer } from 'rxjs';
import {
  concatMap,
  delay,
  delayWhen,
  filter,
  map,
  mergeMap,
} from 'rxjs/operators';

import { collapseFormStepAction } from './progressive-form-step';
import * as progressiveFormActions from './progressive-form.actions';
import {
  getNextFormStepIndex,
  isInitialStep,
  shouldSkipInitialStep,
} from './progressive-form.helpers';
import type { ProgressiveFormState } from './progressive-form.model';
import { generateSelectors } from './progressive-form.selectors';

/**
 * @deprecated - please use the `ProgressiveNGRXFormsModule` instead.
 * For information on how to migrate, see `forms/progressive ngrx forms` in the application docs.
 */
@Injectable()
export class ProgressiveFormEffects {
  constructor(
    private readonly store$: Store,
    private readonly actions$: Actions,
    private readonly scrollService: ScrollService,
    @Inject(DOCUMENT)
    private readonly document: Document
  ) {}

  progressiveFormSelectors = generateSelectors();

  private readonly filterSetActiveForm$ = this.actions$.pipe(
    ofType(progressiveFormActions.setActiveFormStep),
    concatLatestFrom((action) => this.progressiveState$(action.formId)),
    // Delay so formStep collapse animation can complete and element position is stable for scrollToAnchorAnimated
    delayWhen(([_, progressiveState]) =>
      progressiveState.useFormStepConfig === undefined ? timer(500) : timer(0)
    ),
    filter(
      ([action, progressiveState]) =>
        progressiveState.formSteps.includes(
          action.stepId ?? progressiveState.currentStepId ?? ''
        ) && progressiveState.currentStepId !== undefined
    )
  );

  public readonly handleInitProgressiveForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        progressiveFormActions.initProgressiveForm,
        progressiveFormActions.resetProgressiveForm
      ),
      concatLatestFrom((action) => this.progressiveState$(action.formId)),
      mergeMap(([action, progressiveState]) => [
        ...progressiveState.formSteps
          .slice(1)
          .map((formStepId) => new DisableAction(formStepId)),
        progressiveFormActions.setActiveFormStep({
          formId: action.formId,
          stepId: progressiveState.formSteps[0],
        }),
      ])
    )
  );

  public readonly handleSetActiveFormStep$ = createEffect(() =>
    this.filterSetActiveForm$.pipe(
      mergeMap(([_action, progressiveState]) => [
        new DisableAction(progressiveState.previousStepId ?? ''),
        new EnableAction(progressiveState.currentStepId ?? ''),
        new MarkAsUnsubmittedAction(progressiveState.currentStepId ?? ''),
        new FocusAction(
          getFirstFocusControlName(
            this.document,
            progressiveState.currentStepId
          ) ?? ''
        ),
      ])
    )
  );
  //TechDebt move scroll effects to own file
  public readonly scrollToStep$ = createEffect(
    () =>
      this.filterSetActiveForm$.pipe(
        filter(([_, progressiveState]) => !isInitialStep(progressiveState)),
        filter(([action, _]) => !action.noScroll),
        map(([_, progressiveState]) => progressiveState.currentStepId),
        filter(isNotNullish),
        mergeMap((currentStepId) =>
          this.scrollService.scrollToAnchorAnimated(currentStepId)
        )
      ),
    { dispatch: false }
  );

  public readonly scrollToStepWithDelay$ = createEffect(
    () =>
      this.filterSetActiveForm$.pipe(
        filter(
          ([_, progressiveState]) => !shouldSkipInitialStep(progressiveState)
        ),
        filter(([action, _]) => !action.noScroll),
        map(([_, progressiveState]) => progressiveState.currentStepId),
        filter(isNotNullish),
        delay(1000),
        mergeMap((currentStepId) =>
          this.scrollService.scrollToAnchorAnimated(currentStepId)
        )
      ),
    { dispatch: false }
  );

  public readonly handleOpenFormStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(progressiveFormActions.openFormStep),
      concatLatestFrom((action) => this.progressiveState$(action.formId)),
      mergeMap(([action, progressiveState]) => {
        const currentStepId = progressiveState.currentStepId ?? '';
        return [
          progressiveFormActions.updateCompletedFormSteps({
            formId: action.formId,
            stepIds: ensureArray(currentStepId),
            state: false,
          }),
          progressiveState.useFormStepConfig
            ? collapseFormStepAction({
                formId: action.formId,
                stepId: currentStepId,
              })
            : progressiveFormActions.setActiveFormStep({
                formId: action.formId,
                stepId: action.stepId,
                isEditing: action.isEditing,
                noScroll: action.noScroll,
                stepFormState: action.stepFormState,
              }),
        ];
      })
    )
  );

  // testing this would be very very hard and not worth the time effort for now...
  /* istanbul ignore next */
  public readonly handleContinueFromLastStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(progressiveFormActions.continueFromLastStep),
      concatLatestFrom((action) => this.progressiveState$(action.formId)),
      mergeMap(([action, progressiveState]) => [
        progressiveFormActions.setActiveFormStep({
          formId: action.formId,
          stepId: progressiveState.currentStepId || '',
        }),
      ])
    )
  );

  public readonly handleCancelEditingFormStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(progressiveFormActions.cancelEditingFormStep),
      concatLatestFrom((action) => this.progressiveState$(action.formId)),
      filter(([_action, progressiveState]) => progressiveState != null),
      filter(([action, progressiveState]) =>
        progressiveState.formSteps.includes(action.stepId)
      ),
      mergeMap(([action, progressiveState]) => {
        return [
          new SetValueAction(
            action.stepId,
            progressiveState.stepFormStateBackup.value
          ),
          new MarkAsSubmittedAction(action.stepId),
          markAsValidSubmissionAction({
            formId: action.formId,
            controlId: action.stepId,
          }),
        ];
      })
    )
  );

  public readonly handleFormStepSubmit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(markAsValidSubmissionAction),
      concatLatestFrom(({ formId }) => this.progressiveState$(formId)),
      filter(
        ([_, progressiveState]) =>
          progressiveState.useFormStepConfig === undefined
      ),
      filter(([action]) => action.formId != null),
      filter(
        ([action, progressiveState]) =>
          progressiveState?.formSteps.includes(action.controlId) || false
      ),
      mergeMap(([action, progressiveState]) => {
        const nextFormStepIndex = getNextFormStepIndex(progressiveState);
        if (nextFormStepIndex) {
          const formId = action.formId || '';

          return [
            progressiveFormActions.updateCompletedFormSteps({
              formId,
              stepIds: progressiveState.currentStepId
                ? [progressiveState.currentStepId]
                : [],
              state: true,
            }),
            progressiveFormActions.setActiveFormStep({
              formId,
              stepId: progressiveState.formSteps[nextFormStepIndex],
            }),
            progressiveFormActions.setEditing({
              formId,
              editing: false,
            }),
          ];
        }
        return [];
      })
    )
  );

  public readonly markFormAsValidatedOnSubmit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(progressiveFormActions.submitFormStep),
      filter((action) => action.formState?.isValid ?? false),
      filter((action) => !action.formState?.isValidationPending),
      concatLatestFrom((action) => [this.progressiveState$(action.formId)]),
      filter(
        ([_action, progressiveState]) => progressiveState.progressOnValidSubmit
      ),
      mergeMap(([action, progressiveState]) => {
        return [
          markAsValidSubmissionAction({
            controlId: progressiveState?.currentStepId || '',
            formId: action.formId,
          }),
        ];
      })
    )
  );

  public readonly setValueAndSubmit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(progressiveFormActions.setValueAndSubmit),
      concatLatestFrom((action) =>
        this.store$.pipe(
          select(
            this.progressiveFormSelectors.progressOnValidSubmit(action.formId)
          )
        )
      ),
      concatMap(([action, progressOnValidSubmit]) => [
        new SetValueAction(action.controlId, action.value),
        new MarkAsDirtyAction(action.controlId),
        new MarkAsTouchedAction(action.controlId),
        ...(progressOnValidSubmit
          ? [
              markAsValidSubmissionAction({
                controlId: action.stepId,
                formId: action.formId,
              }),
            ]
          : []),
      ])
    )
  );

  progressiveState$ = (formId?: string): Observable<ProgressiveFormState> =>
    formId
      ? this.store$
          .select(
            this.progressiveFormSelectors.getProgressiveStateForForm(formId)
          )
          .pipe(filter(isNotNullish))
      : EMPTY;
}
