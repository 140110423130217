import { Component, Input } from '@angular/core';
import type { FullCalculateFormValues } from '@innogy/become-a-customer/shared';
import {
  type CalculateComponentRendering,
  CalculateComponentContent,
} from '@innogy/become-a-customer/shared/interfaces';
import type { TypedComponentRendering } from '@core/jss-models';
import type { FormGroupControls } from 'ngrx-forms';

@Component({
  selector: 'wl-calculate-form-checkboxes',
  templateUrl: './calculate-form-checkboxes.component.html',
  styles: ['.dummyCheckboxForXpEditor { display: inline-block }'],
})
export class CalculateFormCheckboxesComponent {
  @Input()
  rendering?: TypedComponentRendering<CalculateComponentRendering>;
  @Input({ required: true })
  content!: CalculateComponentContent;
  @Input()
  controls?: FormGroupControls<FullCalculateFormValues> | undefined;

  get showDoubleMeter() {
    return !this.content.hideMeterCheckbox;
  }
}
