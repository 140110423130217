import type { AfterViewInit, OnDestroy } from '@angular/core';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MediaObserverService } from '@innogy/shared/responsive-tools';
import { NgbPagination } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';

import {
  getPaginationDeviceValue,
  PaginationMaxSize,
  PaginationOverrides,
  PaginationPageSize,
} from './pagination.model';

const defaultMaxSize: PaginationMaxSize = 1;

const defaultPageSize: PaginationPageSize = 1;

const defaultOverrides: PaginationOverrides = {
  prevText: '',
  nextText: '',
};

@Component({
  selector: 'wl-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: [
    './pagination.component.essent.scss',
    './pagination.component.ed.scss',
    './pagination.component.ew.scss',
  ],
})
export class PaginationComponent implements AfterViewInit, OnDestroy {
  private readonly onDestroy$ = new Subject();

  pages: number[] = [];
  _page = 1;

  /**
   * If `true`, pagination links will be disabled.
   */
  @Input() disabled = false;

  /**
   * If `true`, the "First" and "Last" page links are shown.
   */
  @Input() boundaryLinks = false;

  /**
   * If `true`, the "Next" and "Previous" page links are shown.
   */
  @Input() directionLinks = true;

  /**
   * If `true`, the ellipsis symbols and first/last page numbers will be shown when `maxSize` > number of pages.
   */
  @Input() ellipses = true;

  /**
   * Whether to rotate pages when `maxSize` > number of pages.
   *
   * The current page always stays in the middle if `true`.
   */
  @Input() rotate = true;

  /**
   *  The number of items in your paginated collection.
   *
   *  Note, that this is not the number of pages. Page numbers are calculated dynamically based on
   *  `collectionSize` and `pageSize`. Ex. if you have 100 items in your collection and displaying 20 items per page,
   *  you'll end up with 5 pages.
   */
  @Input() collectionSize = 1;

  /**
   *  The maximum number of pages to display. Configurable for desktop and mobile.
   */
  @Input() maxSize: PaginationMaxSize = defaultMaxSize;

  /**
   *  The current page.
   *
   *  Page numbers start with `1`.
   */
  get page() {
    return this._page;
  }

  @Input() set page(value: number) {
    this._page = value;
    this.pageChange.emit(value);
  }

  /**
   *  The number of items per page, configurable for desktop and mobile.
   */
  @Input() pageSize: PaginationPageSize = defaultPageSize;

  /**
   * An object containing overrides for specific component properties.
   */
  @Input() overrides: PaginationOverrides = defaultOverrides;

  /**
   *  An event fired when the page is changed. Will fire only if collection size is set and all values are valid.
   *
   *  Event payload is the number of the newly selected page.
   *
   *  Page numbers start with `1`.
   */
  @Output() pageChange = new EventEmitter<number>(true);

  /**
   * The pagination display size.
   *
   * Bootstrap currently supports small and large sizes.
   */
  @Input() size?: 'sm' | 'lg';

  @ViewChild(NgbPagination) pagination!: NgbPagination;

  constructor(
    private readonly mediaObserverService: MediaObserverService,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {}

  isMobile$ = this.mediaObserverService.deviceInfo$.pipe(
    map(({ isMobile }) => isMobile)
  );

  // The maxSize applicable for the current device resolution.
  deviceMaxSize$ = this.isMobile$.pipe(
    map((mobile) => getPaginationDeviceValue(this.maxSize, mobile))
  );
  // The pageSize applicable for the current device resolution.
  devicePageSize$ = this.isMobile$.pipe(
    map((mobile) => getPaginationDeviceValue(this.pageSize, mobile))
  );

  // Defines whether the `pages` template of the pagination has to be overridden to an excerpt.
  showPaginationSummary$ = this.isMobile$.pipe(
    map((mobile) => mobile && this.overrides.summaryOnMobile)
  );

  ngAfterViewInit() {
    /**
     * The following might seem totally useless, yet it is a crucial workaround
     * to ensure that we can switch between the default pagination pages and the excerpt automatically.
     * Removing the following code introduces an issue where the template is only switched after a state change in the
     * pagination component triggered via the UI.
     *
     * The issue we work around is that ngb-pagination uses the `OnPush` changeDetection strategy,
     * meaning that changes are not picked up until an Input of the component is changed.
     * A `detectChanges` call on the parent component does not suffice, as no property is changed on the child,
     * so we toggle and reset one of the component properties that is shared between parent and child and call `detectChanges()` on the parent twice.
     * This ensures changeDetection is "automatically" ran within the child component.
     *
     * For more info, see: https://github.com/angular/angular/issues/20611
     */
    this.isMobile$
      .pipe(distinctUntilChanged(), takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.ellipses = !this.ellipses;
        this.changeDetectorRef.detectChanges();
        this.ellipses = !this.ellipses;
        this.changeDetectorRef.detectChanges();
      });
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
