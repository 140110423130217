export enum BuildingType {
  WOONWAGEN_WOONBOOT = 'woonwagen-woonboot',
  ETAGEWONING = 'etagewoning',
  FLAT_APPARTEMENT = 'flat-appartement',
  VRIJSTAANDE_WONING = 'vrijstaande-woning',
  RIJTJESHUIS = 'rijtjeshuis',
  HOEKWONING = 'hoekwoning',
  TWEE_ONDER_EEN_KAP = 'twee-onder-een-kap',
  GESCHAKELDE_WONING = 'geschakelde-woning',
}
