import type { OnInit } from '@angular/core';
import { Component, HostBinding, Input } from '@angular/core';

import { ImageSettings } from './image.model';

export type BorderStylePosition = 'top' | 'right' | 'bottom' | 'left';

@Component({
  selector: 'wl-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
})
export class ImageComponent implements OnInit {
  @Input() imageSettings?: ImageSettings;
  @Input() ignoreBorderStyles?: BorderStylePosition;

  imagePosition?: string;
  ignoredBorderStyles?: string;

  ngOnInit(): void {
    this.imagePosition = this.imageSettings?.position ?? 'center center';
    this.ignoredBorderStyles = this.ignoreBorderStyles
      ? `rounded-${this.ignoreBorderStyles}-0`
      : '';
  }

  @HostBinding('attr.has-valid-aspect-ratio') get hasValidAspectRatio() {
    return !!this.aspectRatioAsTopPaddingPercentage;
  }

  /**
   * Parses the Aspect Ratio field value to a string containing a percentage.
   * Only respects the first two numbers in the string, e.g. "16/9/12" will be parsed as "16/9".
   * An invalid aspect ratio (or no defined aspect ratio) will return null.
   *
   * @todo: when safari 14 is no longer supported, this component can be refactored to utilized the
   *        css level `aspect-ratio` property. This would remove the need to infer the value into a
   *        padding-top based solution. Given querying support of a css property is somewhat awkward
   *        in javascript, we apply the fallback scenario on all browsers.
   */
  @HostBinding('style.paddingTop')
  get aspectRatioAsTopPaddingPercentage(): `${number}%` | null {
    try {
      if (this.imageSettings?.aspectRatio) {
        const separator = '/';
        const [basis, divider] = this.imageSettings.aspectRatio
          .split(separator, 2)
          .map((n) => parseInt(n));
        const percentage = (1 / (basis / divider)) * 100;
        if (isNaN(percentage)) {
          return null;
        }
        return `${percentage}%`;
      }
      return null;
    } catch (e) {
      return null;
    }
  }
}
