import { pattern } from 'ngrx-forms/validation';

/**
 * RegExp that represents a valid last name.
 */
export const LASTNAME_REGEXP = /^[\p{L} ,.'-]+$/u;

/**
 * Validates that the given value matches our pattern for last names
 */
export const isLastName = pattern(LASTNAME_REGEXP);
