import {
  documentsFileTypes,
  imagesFileTypes,
  plainTextFileTypes,
  generateFileTypeRegex,
} from '@innogy/common-ui/forms';

export const allowedFileTypes = [
  ...imagesFileTypes,
  ...documentsFileTypes,
  ...plainTextFileTypes,
];

export const allowedFileTypesRegex = generateFileTypeRegex([
  ...imagesFileTypes,
  ...documentsFileTypes,
  ...plainTextFileTypes,
]);
export const maxFileSize = 5242880;
export const minFiles = 1;
export const maxFiles = 10;
