import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CookiewallContainerComponent } from './cookiewall-container/cookiewall-container.component';
import { CookiewallComponent } from './cookiewall/cookiewall.component';

@NgModule({
  imports: [CommonModule, CookiewallComponent, CookiewallContainerComponent],
  declarations: [],
  exports: [CookiewallComponent, CookiewallContainerComponent],
})
export class CoreConsentModule {}
