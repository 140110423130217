import type { HttpErrorResponse } from '@angular/common/http';
import type { CorrespondenceDetails, CustomerConsent } from '@essent/customer';
import type { Reimbursement } from '@essent/payment';
import type { ChangeFormState } from '@innogy/utils-state';
import type { LinkFieldValue } from '@core/jss-models';
import type { FormGroupState } from 'ngrx-forms';

import type { FormStateChangeContactPerson } from './+state/change-contact-person-form.reducer';
import type { FormStateChangePhoneNumber } from './+state/change-phone-number-form.reducer';
import type { ReimbursementFormValues } from './+state/reimbursements.model';

export interface ConsentsWithStates {
  consents: CustomerConsent[] | null;
  consentsError?: HttpErrorResponse;
  showError: boolean;
  showLoading: boolean;
}

export interface CorrespondenceDetailsWithStates {
  correspondenceDetailsError?: HttpErrorResponse;
  correspondenceDetails?: CorrespondenceDetails;
  primaryPhoneNumber?: string;
  showError: boolean;
  showLoading: boolean;
  showAddress: boolean;
  canEditEmailAddress: boolean;
  hasMultipleAccounts?: boolean;
}

export interface ChangePhoneNumberWithInfo {
  updatePhoneNumber: ChangeFormState;
  formState: FormGroupState<FormStateChangePhoneNumber>;
  primaryPhoneNumber?: string;
  technicalError: boolean;
  backLink?: LinkFieldValue | null;
}

export interface ChangeCommunicationAddressWithInfo {
  status: ChangeFormState;
  formState: FormGroupState<ChangeCommunicationAddressFormState>;
  correspondenceDetails?: CorrespondenceDetails;
  backLink?: LinkFieldValue | null;
  technicalError: boolean;
  isFetchingAddressCheck: boolean;
}

export interface ReimbursementsWithStates {
  reimbursementsError?: HttpErrorResponse;
  reimbursements?: Reimbursement[];
  showError: boolean;
  showLoading: boolean;
}

export enum AddressTypes {
  POSTALBOX = 'postalbox',
  ADDRESS = 'address',
}

export interface ConsentSettingsModel {
  communicationPreferencesActionmailText?: string;
  communicationPreferencesActionmailTextNoConsent?: string;
  communicationPreferencesInfomailText?: string;
  communicationPreferencesInfomailTextNoConsent?: string;
  communicationPreferencesNewsletterText?: string;
  communicationPreferencesNewsletterTextNoConsent?: string;
  communicationPreferencesProductmailText?: string;
  communicationPreferencesProductmailTextNoConsent?: string;
  supportedChannels?: string[];
  communicationPreferencesEnergieReportText?: string;
  communicationPreferencesEnergieReportTextNoConsent?: string;
}

export interface ChangeContactPersonWithInfo {
  status: ChangeFormState;
  formState: FormGroupState<FormStateChangeContactPerson>;
  correspondenceDetails?: CorrespondenceDetails;
  backLink?: LinkFieldValue | null;
  technicalError: boolean;
}

export type RequestCompensationForm = FormGroupState<ReimbursementFormValues>;

export interface ChangeCommunicationAddressFormState {
  addressType: AddressTypes;
  postalCode: string;
  communicationNumber: number | undefined;
  city: string;
  houseNumberAddition: string;
  street: string;
}
