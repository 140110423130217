import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Offer } from '@innogy/become-a-customer/shared/interfaces';

import {
  CommodityOfferLabels,
  CommodityOfferLayoutType,
} from '../../commodity-offers.model';

@Component({
  selector: 'essent-sme-acquisition-commodity-offer',
  templateUrl: './essent-sme-acquisition-commodity-offer.component.html',
})
export class EssentSMEAcquisitionCommodityOfferComponent {
  @Input() offer?: Offer;
  @Input() content?: CommodityOfferLabels;
  @Input() variant?: CommodityOfferLayoutType;
  @Input() active = false;
  @Input() showMemberGetMember = false;
  @Input() showLoadingState = false;
  @Input() ensureHorizontalAlignment = false;
  @Output() selectOffer = new EventEmitter<void>();
  @Output() openMoreInfo = new EventEmitter<void>();
  @Output() viewTariffs = new EventEmitter<void>();
}
