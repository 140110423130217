import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TemporaryCoreModulesModule } from '@innogy/eplus/temporary-core-modules';
import { provideBootstrapEffects } from '@innogy/utils-state';
import { combineReducers, StoreModule } from '@ngrx/store';

import {
  tariffBreakdownReducers,
  tariffBreakdownSelectorKey,
} from './tariff-breakdown.state';
import { TariffBreakdownEffects } from './tariff-breakdown.effects';

@NgModule({
  imports: [
    CommonModule,
    TemporaryCoreModulesModule,
    StoreModule.forFeature(
      tariffBreakdownSelectorKey,
      combineReducers(tariffBreakdownReducers)
    ),
  ],
  providers: [provideBootstrapEffects([TariffBreakdownEffects])],
})
export class SharedTariffBreakdownStoreModule {}
