import { createSelector } from '@ngrx/store';
import { Status } from '@essent/common';
import { ContractStatus } from '@essent/supply-address-v3';

import {
  getEntities,
  getError,
  getIds,
  getLoading,
  getStatus,
} from './supply-addresses-entity.reducer';
import { getSupplyAddressesEntityStateSelector } from './supply-addresses-entity.store';

export const getSupplyAddressesNumberOfMonths = createSelector(
  getSupplyAddressesEntityStateSelector,
  (state) => state.fetchNumberOfMonths
);

export const getSupplyAddressesEntries = createSelector(
  getSupplyAddressesEntityStateSelector,
  getEntities
);

export const getSupplyAddressesIds = createSelector(
  getSupplyAddressesEntityStateSelector,
  getIds
);

export const getSupplyAddressesLoading = createSelector(
  getSupplyAddressesEntityStateSelector,
  getLoading
);

export const getSupplyAddressesError = createSelector(
  getSupplyAddressesEntityStateSelector,
  getError
);

export const getSupplyAddressesStatus = createSelector(
  getSupplyAddressesEntityStateSelector,
  getStatus
);

export const getSupplyAddresses = createSelector(
  getSupplyAddressesEntries,
  getSupplyAddressesIds,
  (entries, ids) => ids.map((id) => entries[id])
);

export const getActiveSupplyAddresses = createSelector(
  getSupplyAddresses,
  (entries) =>
    entries.filter(
      (address) =>
        address.contractStatus == null ||
        address.contractStatus === ContractStatus.ACTIVE
    )
);

// This is a temporary solution to get all supply addresses without future and past addresses.
// The final solution should be that the future addresses are left out on GI level only for Mijn environments.
export const getActiveAndRecentlyInactiveSupplyAddresses = createSelector(
  getSupplyAddresses,
  (supplyAddresses) =>
    supplyAddresses.filter(
      (address) =>
        address.contractStatus === ContractStatus.ACTIVE ||
        address.contractStatus === ContractStatus.RECENTLY_INACTIVE
    )
);

export const getActiveAndInactiveSupplyAddresses = createSelector(
  getSupplyAddresses,
  (supplyAddresses) =>
    supplyAddresses.filter(
      (address) =>
        address.contractStatus === ContractStatus.ACTIVE ||
        address.contractStatus === ContractStatus.PAST ||
        address.contractStatus === ContractStatus.RECENTLY_INACTIVE
    )
);

export const hasMultipleSupplyAddresses = createSelector(
  getActiveAndRecentlyInactiveSupplyAddresses,
  (supplyAddresses) => supplyAddresses.length > 1
);

export const hasSupplyAddresses = createSelector(
  getActiveAndRecentlyInactiveSupplyAddresses,
  (supplyAddresses) => supplyAddresses.length > 0
);

export const hasSingleSupplyAddress = createSelector(
  getActiveAndRecentlyInactiveSupplyAddresses,
  getSupplyAddressesLoading,
  (addresses, loading) => addresses.length === 1 && !loading
);

export const getSingleSupplyAddress = createSelector(
  getActiveAndRecentlyInactiveSupplyAddresses,
  (addresses) => addresses[0]
);

export const getSupplyAddressesSuccess = createSelector(
  getSupplyAddressesStatus,
  (status) => status === Status.SUCCESS
);

export const hasNoActiveSupplyAddresses = createSelector(
  getSupplyAddressesSuccess,
  hasSupplyAddresses,
  (isSuccess, hasAddresses) => isSuccess && !hasAddresses
);

export const hasActiveSupplyAddress = createSelector(
  getActiveSupplyAddresses,
  (hasActiveAddresses) => hasActiveAddresses.length > 0
);
