<div class="d-flex flex-column" [class.h-100]="settings?.stretchVertical">
  <div
    [class.p-2]="hasBackgroundOrBorderStyle"
    *ngIf="settings?.itemAlignment === 'between'"
  >
    <ng-template *ngTemplateOutlet="headingsPair"></ng-template>
  </div>
  <div
    *ngIf="hasItemAlignedTopOrBetween && !hasNoItem"
    class="mb-2"
    #itemContainer
  >
    <ng-content></ng-content>
  </div>
  <div
    class="d-flex flex-column flex-grow-1"
    [ngClass]="{
      'px-2 pb-2 pt-lg-2': hasBackgroundOrBorderStyle,
      'pt-2': hasBackgroundOrBorderStyle && hasNoItem,
    }"
  >
    <div *ngIf="!(settings?.itemAlignment === 'between')">
      <ng-template *ngTemplateOutlet="headingsPair"></ng-template>
    </div>
    <ng-container *ngIf="content?.text?.text?.value">
      <wl-rich-text-wrapper [field]="content?.text?.text">
        <div
          [class.text-center]="content?.centerText"
          [ngClass]="['mb-2', content?.text?.style]"
        ></div>
      </wl-rich-text-wrapper>
    </ng-container>
    <div *ngIf="settings?.stretchVertical" class="flex-grow-1"></div>
    <div class="d-flex flex-wrap" [ngClass]="buttonsAlignment">
      <a
        *wlGenericLink="
          content?.buttons?.primary?.link;
          extras: navigationExtras
        "
        [ngClass]="['mb-2', content?.buttons?.primary?.style]"
        [class.d-block]="isInSmallCol"
        [class.w-100]="isInSmallCol"
        [class.me-2]="
          !isInSmallCol &&
          !content.buttons.alignVertically &&
          content?.buttons?.secondary?.link?.href
        "
        wlTrackImpression="button-impression"
        trackImpressionMode="button"
        [trackImpressionName]="content?.buttons?.primary?.link?.text"
        [trackImpressionLinkurl]="content?.buttons?.primary?.link?.href"
        [trackImpressionInfo]="{
          componentName: 'basic-content',
        }"
      ></a>
      <a
        *wlGenericLink="
          content?.buttons?.secondary?.link;
          extras: navigationExtras
        "
        [ngClass]="['mb-2 mt-0', content?.buttons?.secondary?.style]"
        [class.d-block]="isInSmallCol"
        [class.w-100]="isInSmallCol"
        wlTrackImpression="button-impression"
        trackImpressionMode="button"
        [trackImpressionName]="content?.buttons?.secondary?.link?.text"
        [trackImpressionLinkurl]="content?.buttons?.secondary?.link?.href"
        [trackImpressionInfo]="{
          componentName: 'basic-content',
        }"
      ></a>
    </div>
    <ng-container *ngIf="content?.microcopy?.text?.value">
      <p
        *scText="content?.microcopy?.text"
        [ngClass]="[content?.microcopy?.style, 'mb-0']"
        [class.text-center]="content?.centerText"
      ></p>
    </ng-container>
  </div>
</div>

<ng-template #headingsPair>
  <div class="d-flex mb-1" [class.justify-content-center]="content?.centerText">
    <wl-basic-content-partial-heading
      [heading]="content?.heading"
      [size]="headingSize"
      [icon]="settings?.icon"
      [showIcon]="showIconInHeader"
      [centerText]="content?.centerText"
    >
    </wl-basic-content-partial-heading>
  </div>
  <ng-container *ngIf="content?.subheading?.text?.value">
    <p
      *scText="content?.subheading?.text"
      class="subheading"
      [class.text-center]="content?.centerText"
      [ngClass]="[
        content?.subheading?.style,
        subheadingSize,
        settings?.itemAlignment === 'between' ? 'mb-0' : 'mb-1',
      ]"
    ></p>
  </ng-container>
</ng-template>
