<div *ngIf="offer" class="d-flex flex-column" wlCSCapture>
  <wl-offer-breakdown-row
    *ngIf="
      offer | selectCommodity: energyType.ELECTRICITY as electricityOverview
    "
    [description]="rendering?.fields?.ElectricityLabel?.value"
    [value]="electricityOverview.totalPrice.expectedYearlyAmount"
  ></wl-offer-breakdown-row>
  <wl-offer-breakdown-row
    *ngIf="offer | selectCommodity: energyType.GAS as gasOverview"
    [description]="rendering?.fields?.GasLabel?.value"
    [value]="gasOverview.totalPrice.expectedYearlyAmount"
  ></wl-offer-breakdown-row>
</div>
