import type {
  AfterViewInit,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  Renderer2,
} from '@angular/core';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss-angular';
import { getEnergyTypeDropdownVM } from '@innogy/become-a-customer/shared';
import type { EnergyTypeDropdownVM } from '@innogy/become-a-customer/shared/interfaces';
import {
  Offer,
  TariffZoneElectricity,
  TariffZoneGas,
} from '@innogy/become-a-customer/shared/interfaces';
import { EnergyType } from '@essent/new-customer';

@Component({
  selector: 'wl-vertical-offer-dropdown',
  templateUrl: './vertical-offer-dropdown.component.html',
  styleUrls: ['./vertical-offer-dropdown.component.scss'],
})
export class VerticalOfferDropdownComponent
  implements OnInit, AfterViewInit, OnChanges
{
  @Input()
  offer?: Offer;
  @Input()
  rendering!: ComponentRendering;
  /**
   * Allow this dropdown to be expanded by default, this is used in the XP-editor so the values can be changed
   */
  @Input()
  initialDropdownState?: boolean;

  @Output()
  showTarifs = new EventEmitter<Offer>();

  dropdownState = false;
  paddingX = 0;
  electricityDropdownVM: EnergyTypeDropdownVM | undefined;
  gasDropdownVM: EnergyTypeDropdownVM | undefined;

  @HostListener('window:resize') onResize() {
    this.setDimensions();
  }

  constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2
  ) {}

  ngOnInit() {
    this.setInitialDropdownState();
    this.updateDropdownVMs();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['offer']) {
      this.updateDropdownVMs();
      // The height of the dropdown is calculated when opening/closing. Closing the dropdown when offers
      // change makes sure the height is always correct.
      this.dropdownState = false;
    }
  }

  ngAfterViewInit() {
    if (this.initialDropdownState) {
      this.setDimensions();
    }
  }

  setInitialDropdownState() {
    if (
      this.initialDropdownState !== undefined &&
      this.dropdownState !== this.initialDropdownState
    ) {
      this.dropdownState = this.initialDropdownState;
    }
  }

  toggle() {
    this.setDimensions();
    this.dropdownState = !this.dropdownState;
  }

  /**
   * The open and close animation needs a fixed width and height to be able to calculate the animation.
   * We still want the component to have a dynamic size, that is why we are setting the dimensions on toggle
   */
  private setDimensions() {
    const contentContainerEl = this.elementRef.nativeElement.querySelector(
      '.offer-dropdown__content'
    );
    const contentWrapperEl = contentContainerEl.querySelector('div');
    this.renderer.setStyle(contentWrapperEl, 'width', this.contentWidth());
    this.renderer.setStyle(contentContainerEl, 'width', this.contentWidth());
    this.renderer.setStyle(contentContainerEl, 'height', this.contentHeight());
  }

  private contentWidth(): string {
    const parentEl = this.elementRef.nativeElement;
    return `${parentEl.clientWidth - this.paddingX}px`;
  }

  private contentHeight(): string {
    const contentWrapperEl = this.elementRef.nativeElement.querySelector(
      '.offer-dropdown__content > div'
    );
    return `${contentWrapperEl.offsetHeight}px`;
  }

  updateDropdownVMs() {
    /**
     * Zone1 is specified to show correct tariff calculation because first item in consumptionPrice not always be zone1
     * To support both current and new tax zone, we need to check both.
     */
    this.electricityDropdownVM = this.createDropdownVM(
      EnergyType.ELECTRICITY,
      TariffZoneElectricity.Zone1
    );
    this.gasDropdownVM = this.createDropdownVM(
      EnergyType.GAS,
      TariffZoneGas.Zone1
    );
  }

  private createDropdownVM(
    energyType: EnergyType,
    newTariffZone: TariffZoneElectricity | TariffZoneGas
  ) {
    const energyData = this.offer?.offerOverviews.find(
      (overview) => overview.energyType === energyType
    );
    return energyData
      ? getEnergyTypeDropdownVM(energyData, newTariffZone)
      : undefined;
  }
}
