import { Component, Input } from '@angular/core';
import { getDroplinkValue, getLinkFieldValue } from '@core/jss-utils';
import type {
  ImageField,
  LinkField,
  TextField,
} from '@sitecore-jss/sitecore-jss-angular';
import { getFieldValue } from '@sitecore-jss/sitecore-jss-angular';

import type { BaseEplusWizardRendering as BWR } from '../models';

@Component({
  template: '',
  selector: 'wl-base-eplus-wizard[rendering]',
})
export class BaseEplusWizardComponent<WR extends BWR = BWR> {
  @Input() rendering!: WR;

  get titleField() {
    return this.rendering.fields.Title;
  }

  get deliveryAddressTitleField() {
    return this.rendering.fields.DeliveryAddressTitle;
  }

  get descriptionField(): TextField {
    return this.rendering.fields.Description;
  }

  get sticker(): ImageField {
    return this.rendering.fields.Sticker;
  }

  get houseIsBoughtLabel(): string {
    return getFieldValue(this.rendering, 'HouseIsBoughtLabel', '');
  }

  get houseIsBoughtTooltip(): string {
    return getFieldValue(this.rendering, 'HouseIsBoughtTooltip', '');
  }

  get submitButtonTextField(): TextField {
    return this.rendering.fields.SubmitButtonText;
  }

  get secondaryButton(): { link?: LinkField; style?: string } {
    return {
      link: getLinkFieldValue(this.rendering, 'SecondaryButtonLink'),
      style:
        getDroplinkValue(this.rendering.fields, 'SecondaryButtonStyle') ??
        'btn btn-link',
    };
  }
}
