<ng-container *ngIf="rendering.fields?.DeliveryAddressTitle?.value">
  <p class="mb-1 fw-bold" *scText="rendering.fields.DeliveryAddressTitle"></p>
</ng-container>
<wl-address-form
  [formState]="formState"
  [rendering]="$any(rendering)"
  [content]="addressFormFields"
  [floatingLabels]="floatingLabels"
  [showAddressTypeSelector]="false"
  [trackStep]="trackStep"
></wl-address-form>
