<wl-simple-progressive-form-card
  [progressiveFormState]="progressiveFormState"
  [content]="progressiveFormCardContent"
  [asyncSubmission]="true"
  [trackingConfig]="{
    toolName: rendering.componentName,
    step: stepNumber,
    stepName: 'leveringsgegevens',
  }"
>
  <ng-template #content>
    <div class="row mb-2">
      <label
        class="col-12 fw-bold"
        *scText="fields?.SupplyAddressTitle"
      ></label>
      <div class="col-12">
        <p class="d-flex align-items-center mb-0" *ngIf="supplyAddress">
          <wl-supply-address
            [supplyAddress]="supplyAddress"
            wlCSMask
          ></wl-supply-address>
          <button
            class="btn btn-link m-0 p-0 ms-1 text-dark"
            (click)="changeSupplyAddressClick($event)"
            *ngIf="rendering?.fields?.showChangeSupplyAddressLink.value"
          >
            {{ rendering?.fields?.changeSupplyAddressLinkText.value }}
          </button>
        </p>
      </div>
    </div>

    <ng-container *ngIf="showBusinessFields">
      <div class="row mb-2">
        <wl-checkbox
          [rendering]="rendering"
          stepName="leveringsgegevens"
          [step]="stepNumber"
          fieldName="gebruik als correspondentieadres"
          [formsControl]="controls?.useAsCorrespondenceAddress"
          [label]="fields?.CorrespondenceAddressLabel?.value"
        >
        </wl-checkbox>
      </div>
      <div *ngIf="!controls?.useAsCorrespondenceAddress?.value">
        <p class="h6" *scText="fields?.CorrespondenceAddressTitle"></p>
        <div class="row mb-2">
          <div class="row mb-2">
            <!-- Postal code -->
            <div class="col-6 col-lg-3">
              <wl-text
                [rendering]="rendering"
                [step]="stepNumber"
                stepName="leveringsgegevens"
                fieldName="postcode correspondentieadres"
                [sendValue]="false"
                [valueConverter]="_uppercaseConverter"
                [formsControl]="controls?.postalCodeCorrespondence"
                [placeholder]="fields?.PostalCodePlaceholder?.value"
                [label]="fields?.PostalCodeLabel?.value"
              ></wl-text>
            </div>
            <!-- Huisnummer -->
            <div class="col-6 col-lg-3">
              <wl-number
                [step]="stepNumber"
                [rendering]="rendering"
                stepName="leveringsgegevens"
                fieldName="huisnummer correspondentieadres"
                [sendValue]="false"
                [formsControl]="controls?.houseNumberCorrespondence"
                [placeholder]="fields?.HouseNumberPlaceholder?.value"
                [label]="fields?.HouseNumberLabel?.value"
              ></wl-number>
            </div>
            <!-- Huisnummer additie -->
            <div
              class="col-6 col-lg-3"
              *ngIf="!hideCorrespondenceHouseNumberExtensions()"
            >
              <wl-dropdown
                [dropdownItems]="correspondenceHouseNumberExtensions"
                [formsControl]="controls?.houseNumberAdditionCorrespondence"
                [placeholder]="'Toev.'"
                stepName="leveringsgegevens"
                fieldName="toevoeging correspondentieadres"
              ></wl-dropdown>
            </div>
            <div
              class="col-12"
              *ngIf="rendering?.fields?.EnableBusinessFields?.value"
            >
              <p
                class="address-details--success mb-0 mt-1"
                *ngIf="correspondenceAddress"
              >
                {{ correspondenceAddress.street }}
                {{ correspondenceAddress.houseNumber
                }}{{ correspondenceAddress.houseNumberExtension }},
                {{ correspondenceAddress.city }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="row mb-2">
      <label class="col-12 fw-bold">
        <span class="me-1" *scText="fields?.ResidenceOrWorkplaceLabel"></span>
        <wl-tooltip
          *ngIf="fields?.ResidenceOrWorkplaceTooltip?.value"
          [tooltip]="{
            description: fields?.ResidenceOrWorkplaceTooltip?.value,
          }"
        ></wl-tooltip>
      </label>
      <div class="col-12" *ngIf="fields?.ResidenceOrWorkplaceText?.value">
        <p *scText="fields?.ResidenceOrWorkplaceText"></p>
      </div>
      <wl-radio
        class="col-12"
        [rendering]="rendering"
        [step]="stepNumber"
        stepName="leveringsgegevens"
        fieldName="woon of werkadres"
        [formsControl]="controls?.residenceOrWorkplace"
        [radioItems]="residenceOrWorkplaceOptions"
        [inline]="false"
      ></wl-radio>
      <ng-container *ngIf="controls?.residenceOrWorkplace | isInvalid">
        <wl-input-error
          stepName="leveringsgegevens"
          [step]="stepNumber"
          wlAutomationId="residence-or-workdate-required-error"
          [messageField]="fields?.ResidenceOrWorkplaceRequiredError"
          messageDescription="woon of werkadres"
          [rendering]="rendering"
        ></wl-input-error>
      </ng-container>
    </div>

    <wl-order-fulfillment-inswitch-inhouse
      *ngIf="progressiveFormState?.formState"
      [rendering]="rendering"
      [formState]="progressiveFormState?.formState"
      [stepNumber]="stepNumber"
      [labelsShownAboveFields]="labelsShownAboveFields"
    />
  </ng-template>
  <ng-template #summary>
    <p class="h6 mb-0" *scText="fields?.SupplyAddressTitle"></p>
    <p>
      <wl-supply-address
        [supplyAddress]="supplyAddress"
        wlCSMask
      ></wl-supply-address>
    </p>

    <ng-container *ngIf="showBusinessFields">
      <p
        class="h6 mb-0"
        *scText="fields?.CorrespondenceAddressTitle"
        wlAutomationId="business-fields"
      ></p>
      <p
        *ngIf="
          progressiveFormState?.formState?.value?.useAsCorrespondenceAddress
        "
      >
        <wl-supply-address
          [supplyAddress]="supplyAddress"
          wlCSMask
        ></wl-supply-address>
      </p>
      <p
        *ngIf="
          !progressiveFormState?.formState?.value?.useAsCorrespondenceAddress
        "
      >
        <wl-supply-address
          [supplyAddress]="correspondenceAddress"
          wlCSMask
        ></wl-supply-address>
      </p>
    </ng-container>

    <p class="h6 mb-0" *scText="fields?.ResidenceOrWorkplaceLabel"></p>
    <p>{{ selectedResidenceOrWorkplaceOption?.label }}</p>

    <p class="h6 mb-0" *scText="fields?.SituationSummaryLabel"></p>
    <p wlAutomationId="inswitch-inhouse-summary">
      {{ selectedInswitchOrInhouseOptionLabel }}
    </p>

    <p class="h6 mb-0" *scText="fields?.InswitchLabel"></p>
    <p>{{ selectedStartDate | date: 'd MMMM yyyy' }}</p>
  </ng-template>
</wl-simple-progressive-form-card>
