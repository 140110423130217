import type { PostalAddressFormValues } from '@innogy/common-ui/forms';
import { extendPostalAddressFormReducer } from '@innogy/common-ui/forms';
import type { FormGroupValidatorMap } from '@innogy/utils-deprecated';
import { validateSequential } from '@innogy/utils-deprecated';
import type { ActionReducer } from '@ngrx/store';
import { combineReducers } from '@ngrx/store';
import type { FormGroupState } from 'ngrx-forms';
import { required, requiredTrue } from 'ngrx-forms/validation';

export const insulationWizardFormId = 'insulationWizardForm';

export interface InsulationWizardFormValues extends PostalAddressFormValues {
  buildingType: string;
  houseIsBought: boolean;
}

export interface InsulationWizardState {
  formState: FormGroupState<InsulationWizardFormValues>;
}

const initialFormValues = {
  postalCode: '',
  communicationNumber: NaN,
  houseNumberAddition: '',
  buildingType: '',
  houseIsBought: false,
};

const validators: FormGroupValidatorMap<
  InsulationWizardFormValues,
  PostalAddressFormValues
> = {
  buildingType: validateSequential(required),
  houseIsBought: validateSequential(requiredTrue),
};

const {
  extendedControlIds,
  initialExtendedFormGroupState: initialInsulationWizardFormGroupState,
  extendedReducer: insulationWizardFormStateReducer,
} = extendPostalAddressFormReducer<InsulationWizardFormValues>({
  formId: insulationWizardFormId,
  baseInitialState: initialFormValues,
  extendingValidators: validators,
});

export const insulationWizardFormControlIds = extendedControlIds;

export const initialInsulationWizardState: InsulationWizardState = {
  formState: initialInsulationWizardFormGroupState,
};

export const insulationWizardReducer: ActionReducer<InsulationWizardState> =
  combineReducers({
    formState: insulationWizardFormStateReducer,
  });
