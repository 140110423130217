import type {
  CorrespondenceAddress,
  PoboxAddress,
  StreetAddress,
} from '@essent/customer';

import type { FormAddress } from '../state/address-form.types';
import { AddressTypes } from '../state/address-form.types';

export function convertCorrespondenceAddressToFormAddress(
  address: CorrespondenceAddress | undefined,
  usePoBox: boolean
): Partial<FormAddress> {
  if (!address) {
    return {};
  }
  if (isStreetAddress(address)) {
    return getStreetAddress(address);
  } else if (usePoBox) {
    return getPobox(address);
  } else {
    return {};
  }
}

function getPobox(address: PoboxAddress) {
  return {
    addressType: AddressTypes.POBOX,
    city: address.city ?? '',
    communicationNumber: parseInt(address.pobox) ?? '',
    postalCode: address.postcode ?? '',
    countryCode: address.countryCode ?? '',
  };
}

function isStreetAddress(
  object: StreetAddress | PoboxAddress
): object is StreetAddress {
  return 'houseNumber' in object;
}

function getStreetAddress(address: StreetAddress) {
  return {
    addressType: AddressTypes.ADDRESS,
    city: address.city ?? '',
    communicationNumber: parseInt(address.houseNumber) ?? '',
    houseNumberAddition: address.houseNumberExtension ?? '',
    countryCode: address.countryCode ?? '',
    postalCode: address.postcode ?? '',
    street: address.street ?? '',
  };
}
