<div class="commodity-offer-breakdown-cell" [ngClass]="backgroundClass">
  <div
    class="d-flex justify-content-between commodity-offer-breakdown-cell--discount"
    *ngIf="discountValue"
  >
    <div>
      {{ discountDescription }}
    </div>
    <div>- {{ discountValue | roundedCurrency }}</div>
  </div>
  <div class="d-flex justify-content-between">
    <div>
      {{ description }}
    </div>
    <div>{{ value | roundedCurrency }}</div>
  </div>
</div>
