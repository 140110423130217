import { SegmentType } from '@essent/customer';
import { EnvConfigSegment, EnvConfigType } from '@core/config-models';
import { getAppConfig } from '@core/config-store';
import { getError, getLoading, getStatus } from '@innogy/utils-state';
import { createSelector } from '@ngrx/store';

import type { State } from './customer-entity.reducer';
import { getCustomerEntityStateSelector } from './customer-entity.store';

export const getIsCustomerLoading = createSelector(
  getCustomerEntityStateSelector,
  (state: State) => getLoading(getStatus(state))
);

export const getCustomerHasError = createSelector(
  getCustomerEntityStateSelector,
  (state: State) => getError(getStatus(state))
);

export const getCustomerData = createSelector(
  getCustomerEntityStateSelector,
  (state: State) => state.data
);

export const getOrganisation = createSelector(
  getCustomerData,
  (customer) => customer && customer.organization
);

export const getPerson = createSelector(
  getCustomerData,
  (customer) => customer && customer.person
);

export const getPersonFullName = createSelector(
  getPerson,
  (customer) => customer && customer.fullName
);

export const getOrganisationName = createSelector(
  getOrganisation,
  (customer) => customer && customer.organisationName
);

export const getCustomerCorrespondence = createSelector(
  getCustomerData,
  (customer) => customer && customer.correspondence
);

export const getCustomerCorrespondenceEmail = createSelector(
  getCustomerCorrespondence,
  (correspondence) => correspondence && correspondence.email
);

export const getCustomerName = createSelector(
  getPersonFullName,
  getOrganisationName,
  (personName, organisationName) => organisationName || personName
);

export const isCustomerHH = createSelector(
  getCustomerData,
  (customer) => customer?.customerSegment === SegmentType.HH
);

export const isCustomerSME = createSelector(
  getCustomerData,
  (customer) => customer?.customerSegment === SegmentType.SME
);

export const isCustomerSOHO = createSelector(
  getCustomerData,
  (customer) => customer?.customerSegment === SegmentType.SOHO
);

export const getCustomerSegment = createSelector(
  getCustomerData,
  (customer) => customer?.customerSegment
);

export const isVATIncluded = createSelector(
  getCustomerData,
  (customer) =>
    customer != null &&
    customer.customerSegment !== SegmentType.SME &&
    customer.customerSegment !== SegmentType.SOHO
);

export const shouldShowPricesIncludingVAT = createSelector(
  isVATIncluded,
  getAppConfig,
  (vatIncluded, appConfig) => {
    // Default to showing prices including VAT
    if (appConfig == null) {
      return true;
    }

    // For mijn environment, show VAT based on customer segment
    if (appConfig.type === EnvConfigType.Mijn) {
      return vatIncluded;
    }

    // For open environment, show VAT based on environment segment
    switch (appConfig.segment) {
      case EnvConfigSegment.Consument:
        return true;
      default:
        return false;
    }
  }
);
