import type { InsulationFunnelSettingsInterface } from '@innogy/eplus/models';
import type { InsulationProductWithCalculations } from '@innogy/eplus/temporary-core-modules';
import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';

import { handleToggleProduct } from '../../shared/+state';
import {
  clearInsulationFunnelInitializedAction,
  mintInsulationTrackingIdAction,
  resetInsulationProductSelectionAction,
  selectInsulationProductsAction,
  setInsulationFunnelInitializedAction,
  toggleInsulationProductSelectionAction,
} from './insulation-funnel.actions';

export const insulationFunnelKey = 'insulation-funnel';

export type InsulationFunnelState = InsulationFunnelSettingsInterface & {
  selectedProducts: InsulationProductWithCalculations[];
};

export const initialInsulationFunnelState: InsulationFunnelState = {
  id: undefined,
  initialized: false,
  startPage: undefined,
  alreadyInsulatedPage: undefined,
  noProductsAvailablePage: undefined,
  productOverviewPage: undefined,
  orderPage: undefined,
  successPage: undefined,
  selectedProducts: [],
  trackingId: undefined,
};

const reducer = createReducer(
  initialInsulationFunnelState,
  on(setInsulationFunnelInitializedAction, (state, { funnelSettings }) => ({
    ...state,
    ...funnelSettings,
    initialized: true,
  })),
  on(clearInsulationFunnelInitializedAction, (state) => ({
    ...state,
    initialized: false,
  })),
  on(mintInsulationTrackingIdAction, (state, { id: trackingId }) => ({
    ...state,
    trackingId,
  })),
  on(selectInsulationProductsAction, (state, { products }) => ({
    ...state,
    selectedProducts: products,
  })),
  on(toggleInsulationProductSelectionAction, (state, { product }) => ({
    ...state,
    selectedProducts: handleToggleProduct(product, state.selectedProducts),
  })),
  on(resetInsulationProductSelectionAction, (state) => ({
    ...state,
    selectedProducts: [],
  }))
);

export const insulationFunnelReducer = (
  state: InsulationFunnelState = initialInsulationFunnelState,
  action: Action
): InsulationFunnelState => reducer(state, action);
