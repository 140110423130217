import { Injectable, Injector } from '@angular/core';
import { BaseGuard } from '@core/ngrx';
import { Store } from '@ngrx/store';
import type { GuardInput } from '@sitecore-jss/sitecore-jss-angular';
import type { Observable } from 'rxjs';
import { of } from 'rxjs';

import {
  isFunnelSettingsLoading,
  setFunnelSettingsFromGraphqlAction,
} from './';

@Injectable({ providedIn: 'root' })
export class FunnelSettingsGuard extends BaseGuard {
  protected isStateLoading(input: GuardInput): Observable<boolean> {
    const funnelId = input.activatedRoute.queryParams['funnel_id'];

    // There is no need to wait for funnel settings if there is no funnel_id present. The guard can resolve
    // immediately and the effect will handle eventual errors.
    if (!funnelId) {
      return of(false);
    }

    return this.store$.select(isFunnelSettingsLoading);
  }

  protected getDispatchActions(input: GuardInput) {
    const funnelId = input.activatedRoute.queryParams['funnel_id'];

    if (funnelId) {
      return setFunnelSettingsFromGraphqlAction({ guid: funnelId });
    }

    return [];
  }

  constructor(
    protected override readonly injector: Injector,
    protected readonly store$: Store<any>
  ) {
    super(injector);
  }
}
