import type {
  SolarPanelsCalculationParameters,
  SolarPanelCriteria,
} from '@innogy/eplus/temporary-core-modules';
import { createAction, props } from '@ngrx/store';

const PREFIX = '[Solar Panels Suitability Check]';

export const resetSuitabilityFormAction = createAction(`${PREFIX} Reset form`);

export const showSolarPanelsSuitabilityCheckFormAction = createAction(
  `${PREFIX} Show form`
);

export const hideSolarPanelsSuitabilityCheckFormAction = createAction(
  `${PREFIX} Hide form`
);

export const solarPanelsSuitabilityCheckFormSubmitAction = createAction(
  `${PREFIX} Submit form`
);

export const scrollToSolarPanelsSuitabilityCheckAction = createAction(
  `${PREFIX} Scroll to suitability check`
);

export const provideCriteriaAndCalculationParametersToSuitabilityCheckAction =
  createAction(
    `${PREFIX} Provide criteria and calculation parameters`,
    props<{
      calculationParameters: SolarPanelsCalculationParameters;
      criteria: SolarPanelCriteria;
    }>()
  );
