import { createSelector } from '@ngrx/store';

import { getBecomeACustomerState } from '../../become-a-customer-feature.selector';

export const propositionState = createSelector(
  getBecomeACustomerState,
  (state) => state.proposition
);

export const putPropositionState = createSelector(
  propositionState,
  (state) => state.putProposition
);
