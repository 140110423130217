import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DefaultComponentModule } from '@innogy/core/modules/components';

import { ComponentTestingContainerComponent } from './component-testing-container/component-testing-container.component';
import { ComponentTestingComponent } from './component-testing/component-testing.component';

const components = [
  ComponentTestingContainerComponent,
  ComponentTestingComponent,
];

@NgModule({
  imports: [CommonModule, DefaultComponentModule],
  declarations: components,
  exports: components,
})
export class ABTestingModule {}
