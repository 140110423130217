<wl-sticky-wrapper [sticky]="sticky" [class]="stickyWrapperClass">
  <div class="card receipt">
    <div class="card--body flex-column">
      <div class="receipt__header">
        <div class="d-flex justify-content-between">
          <p class="h3 receipt__header--title">
            {{ title }}
          </p>
        </div>
      </div>
      <div class="receipt__body">
        <div class="receipt__body--content" *ngIf="breakdown">
          <ng-container
            *ngTemplateOutlet="breakdown.templateRef"
          ></ng-container>
        </div>
        <div class="receipt__body--suffix" *ngIf="conclusion">
          <ng-container
            *ngTemplateOutlet="conclusion.templateRef"
          ></ng-container>
        </div>
      </div>
    </div>
  </div>
</wl-sticky-wrapper>
