import { Component, Input } from '@angular/core';
import { AnalyticsActionTypes } from '@innogy/core/analytics';
import { FunctionalErrorItemRendering } from '@innogy/functional-errors/store';

@Component({
  selector: 'wl-functional-error[analyticsImpressionName]',
  templateUrl: './functional-error.component.html',
})
export class FunctionalErrorComponent {
  @Input()
  errorItem?: FunctionalErrorItemRendering;

  @Input()
  componentName = 'defaultComponent';

  /**
   * Indicates where the component is placed on the page vertically for analytics purposes.
   * RowContext has priority over this value.
   * If the component is placed in a row context, the position of the row is used.
   * If not, this value will be used. Can be found in sitecore rendering (rendering.position)
   */
  @Input()
  analyticsRootComponentPosition = 0;

  /**
   * used to indicate the name which is used in the content block impression event
   */
  @Input()
  analyticsImpressionName = 'default';

  @Input()
  automationId = 'default';

  @Input()
  AnalyticsActionTypes = AnalyticsActionTypes;

  @Input()
  backgroundGrey = false;

  @Input()
  shadow = true;

  get rendering(): FunctionalErrorItemRendering | undefined {
    if (this.errorItem) {
      return {
        ...this.errorItem,
        componentName: this.componentName,
        position: this.analyticsRootComponentPosition,
      };
    }
    return undefined;
  }
}
