import type { AsyncState } from '@essent/common';
import {
  createAsyncStateReducer,
  createInitialAsyncState,
} from '@essent/common';
import type {
  FlowPutSuccess,
  MetaData,
  MetaDataItem,
  PutMetaDataPayload,
} from '@essent/new-customer';
import { getMetaDataActions, putMetaDataActions } from '@essent/new-customer';
import type { Action, ActionReducerMap } from '@ngrx/store';
import { combineReducers } from '@ngrx/store';

export type PutMetaDataState = AsyncState<FlowPutSuccess>;
export type GetMetaDataState = AsyncState<MetaData>;
export interface MetaDataState {
  putMetaData: PutMetaDataState;
  getMetaData: GetMetaDataState;
}

const initialState: MetaDataState = {
  putMetaData: createInitialAsyncState(),
  getMetaData: createInitialAsyncState(),
};

const reducers: ActionReducerMap<MetaDataState, Action> = {
  getMetaData: createAsyncStateReducer(getMetaDataActions),
  putMetaData: createAsyncStateReducer(putMetaDataActions),
};

const _combinedReducers = combineReducers(reducers);

export function metaDataReducer(
  state: MetaDataState = initialState,
  action: Action
): MetaDataState {
  return _combinedReducers(state, action);
}

export const createPutMetaDataPayload = (
  flowId: string,
  metaData: MetaDataItem[]
): PutMetaDataPayload => ({
  metaData: { flowId },
  payload: { metadata: metaData },
});
