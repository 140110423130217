import { Component, Input } from '@angular/core';
@Component({
  selector: 'wl-commodity-offer-label',
  templateUrl: './commodity-offer-label.component.html',
  styleUrls: [
    './commodity-offer-label.component.ed.scss',
    './commodity-offer-label.component.essent.scss',
  ],
})
export class CommodityOfferLabelComponent {
  @Input({ required: true }) style!: 'highlight' | 'incentive' | 'text';
  @Input({ required: true }) label!: string;

  get styleClasses() {
    return `label--${this.style}`;
  }
}
