import type { GetCheckPostcodeRequest } from '@essent/feh';
import { createAction, props } from '@ngrx/store';

import type { SustainabilityFormConfigState } from './sustainability.reducers';

const PREFIX = '[Sustainability]';

export const validatePostcode = createAction(
  `${PREFIX} Validate postcode`,
  props<GetCheckPostcodeRequest>()
);

export const updateFormConfig = createAction(
  `${PREFIX} Set form config`,
  props<Partial<SustainabilityFormConfigState>>()
);

export const setTimeslotDay = createAction(
  `${PREFIX} Set timeslot day`,
  props<{ day: string }>()
);
