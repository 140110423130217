import type { Type } from '@angular/core';
import { APP_BOOTSTRAP_LISTENER, InjectionToken, Inject } from '@angular/core';
import { EffectSources } from '@ngrx/effects';

export const BOOTSTRAP_EFFECTS = new InjectionToken('Bootstrap Effects');

export function bootstrapEffects(
  effectsCollection: Type<any>[][],
  sources$: EffectSources
) {
  return () => {
    effectsCollection.forEach((effects) =>
      effects.forEach((effect) => sources$.addEffects(effect))
    );
  };
}

export function createInstances(...instances: any[]) {
  return instances;
}

/**
 * We need this to prevent the effects from initializing before the APP_INITIALIZER of
 * the ConfigModule.
 * See: https://github.com/ngrx/platform/issues/931
 */
export function provideBootstrapEffects(effects: Type<any>[]) {
  return [
    effects,
    {
      provide: BOOTSTRAP_EFFECTS,
      multi: true,
      deps: effects,
      useFactory: createInstances,
    },
    {
      provide: APP_BOOTSTRAP_LISTENER,
      multi: true,
      useFactory: bootstrapEffects,
      deps: [[new Inject(BOOTSTRAP_EFFECTS)], EffectSources],
    },
  ];
}
