import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { SegmentedControlEntry } from './segmented-control.model';

@Component({
  selector: 'wl-segmented-control',
  templateUrl: './segmented-control.component.html',
  styleUrls: [
    './segmented-control.component.essent.scss',
    './segmented-control.component.ed.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SegmentedControlComponent<T> {
  @Input()
  entries?: SegmentedControlEntry<T>[];

  @Input()
  selected?: SegmentedControlEntry<T>;

  @Output()
  entrySelected = new EventEmitter<T>();

  clicked(entry: SegmentedControlEntry<T>) {
    this.selected = entry;
    this.entrySelected.emit(entry.value);
  }

  trackById(_: number, entry: SegmentedControlEntry<T>) {
    return entry.id;
  }
}
