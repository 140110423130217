<wl-order-fulfillment-personal
  [progressiveFormState]="fulfillmentPersonalProgressiveFormState$ | async"
  [rendering]="rendering"
  [genderOptions]="genderOptions"
  wlAutomationId="order-fulfillment-personal"
  [stepNumber]="1"
  [labelsShownAboveFields]="labelsShownAboveFields"
/>
<wl-order-fulfillment-address
  [progressiveFormState]="fulfillmentAddressProgressiveFormState$ | async"
  [rendering]="rendering"
  [residenceOrWorkplaceOptions]="residenceOrWorkplaceOptions"
  [exclusivelyCorporateUsageOptions]="exclusivelyCorporateUsageOptions"
  [supplyAddress]="supplyAddressVM$ | async"
  [correspondenceAddress]="correspondenceInstallationAddressState$ | async"
  [correspondenceHouseNumberExtensions]="
    correspondenceHouseNumberExtensions$ | async
  "
  [stepNumber]="2"
  (changeSupplyAddress)="changeSupplyAddress()"
  [labelsShownAboveFields]="labelsShownAboveFields"
/>
<wl-order-fulfillment-payment
  [progressiveFormState]="fulfillmentPaymentProgressiveFormState$ | async"
  [rendering]="rendering"
  [stepNumber]="3"
  [labelsShownAboveFields]="labelsShownAboveFields"
/>
<wl-order-fulfillment-confirmation
  [progressiveFormState]="fulfillmentConfirmationProgressiveFormState$ | async"
  [rendering]="rendering"
  [offer]="offer$ | async"
  (openAOMModal)="openAOMModal($event)"
  [stepNumber]="4"
/>
