import { Component, Input } from '@angular/core';

import {
  BasicContentHeading,
  BasicContentIcon,
  HeaderSize,
} from '../../basic-content-settings.model';

@Component({
  selector: 'wl-basic-content-partial-heading',
  templateUrl: './basic-content-partial-heading.component.html',
  styleUrls: ['./basic-content-partial-heading.component.ed.scss'],
})
export class BasicContentPartialHeadingComponent {
  @Input() heading?: BasicContentHeading;
  @Input() icon?: BasicContentIcon;
  @Input() centerText?: boolean;
  @Input() size: HeaderSize = '';
  @Input() showIcon = true;
}
