import { createNgrxFormReducer } from '@innogy/shared/forms';
import { ChargeCardType } from '@integration/emobility-api-models';
import {
  type FormGroupState,
  createFormGroupState,
  updateGroup,
  validate,
} from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';

export interface ChargeCardTypeFormState {
  chargeCardType: ChargeCardType;
}

export interface ChargeCardTypeState {
  formState: FormGroupState<ChargeCardTypeFormState>;
}

export const chargeCardTypeFormId = 'emobility-lead-charge-card-type';

const chargeCardInitialState = createFormGroupState<ChargeCardTypeFormState>(
  chargeCardTypeFormId,
  {
    chargeCardType: ChargeCardType.ChargeCard,
  }
);

export const initialCardTypeState: ChargeCardTypeState = {
  formState: chargeCardInitialState,
};

export const validateChargeCardTypeForm = (state: ChargeCardTypeState) => {
  return updateGroup(state.formState, {
    chargeCardType: validate(required),
  });
};

export const chargeCardTypeReducer = createNgrxFormReducer(
  initialCardTypeState,
  chargeCardTypeFormId,
  validateChargeCardTypeForm
);
