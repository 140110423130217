<figure [style.aspectRatio]="ratio">
  <picture>
    <img
      [style.objectPosition]="objectPosition ?? 'center center'"
      [style.objectFit]="objectFit ?? 'cover'"
      [src]="src"
      [alt]="alt"
      [attr.loading]="loading ?? 'lazy'"
      [class.p-3]="objectFit === 'contain'"
    />
  </picture>
  <figcaption *ngIf="caption">{{ caption }}</figcaption>
</figure>
