import { createSelector } from '@ngrx/store';

import { getBecomeACustomerState } from '../../become-a-customer-feature.selector';

export const getPersonalDetailsState = createSelector(
  getBecomeACustomerState,
  (state) => state.personalDetails.getPersonalDetails
);

export const putPersonalDetailsState = createSelector(
  getBecomeACustomerState,
  (state) => state.personalDetails.putPersonalDetails
);
