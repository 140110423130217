import type { Params } from '@angular/router';

export interface GetPartnerIdQuery {
  params?: Params;
  path?: string;
  referrer?: string;
}

interface PartnerIdConfigEntry {
  channel: Channel;
  extPartnerId: string;
}

export interface EcmpHeader {
  ecmp: string;
  description: string;
  logo: string;
  url: string;
}

export interface PartnerIdConfig {
  partnerIdMap: PartnerIdConfigEntry[];
  socialReferrers: string[];
  searchReferrers: string[];
  ecmpHeaders: EcmpHeader[];
}

export type BrandPartnerConfigKey = 'ecmp' | 'channel' | 'referrer';

export enum Channel {
  PaidSearchNonBrand = 'paid_search_non_brand',
  PaidSearchBrand = 'paid_search_brand',
  Email = 'email',
  DaisyconAlgemeen = 'daisycon_algemeen',
  DaisyconVergelijker = 'daisycon_vergelijker',
  DaisyconEnergieAanbiedingenCom = 'daisycon_eaco',
  DaisyconEnergieAanbiedingenNu = 'daisycon_eanu',
  MemberGetMember = 'member_get_member',
  Display = 'display',
  Social = 'social',
  Winback = 'winback',
  OrganicSearch = 'organic_search',
  BrandWebsiteDefault = 'brand_website_default',
  PSV = 'psv',
}
