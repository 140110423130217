import { Component, Input } from '@angular/core';
import type { SupplyAddress } from '@essent/new-customer';
import type { InstallationAddress } from '@essent/installation-address';

@Component({
  selector: 'wl-supply-address',
  templateUrl: './supply-address.component.html',
  styleUrls: [],
})
export class SupplyAddressComponent {
  @Input() supplyAddress?: Partial<SupplyAddress> | InstallationAddress;
}
