import { HttpClient } from '@angular/common/http';
import { TransferState, Injectable, makeStateKey } from '@angular/core';
import { AppConfigService } from '@core/config-angular';
import type { Observable } from 'rxjs';
import { of } from 'rxjs';

import type { Settings } from './settings.model';

export const appSettingsStateKey = makeStateKey<Settings>('app_settings');

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor(
    private readonly appConfigService: AppConfigService,
    private readonly http: HttpClient,
    private readonly transfer: TransferState
  ) {}

  getSettings(): Observable<Settings> {
    if (this.transfer.hasKey(appSettingsStateKey)) {
      const settings = this.transfer.get<Settings>(
        appSettingsStateKey,
        null as any
      );
      this.transfer.remove(appSettingsStateKey);

      return of(settings);
    }

    return this.http.get<Settings>(
      `${this.appConfigService.basePath}/api/settings`
    );
  }
}
