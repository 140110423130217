import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import type { RadioItem } from '@innogy/common-ui/forms';
import { TrackToolStep } from '@innogy/core/analytics';
import { ScRadioFormInput } from '@innogy/sitecore-forms/models';
import type { RenderingField } from '@sitecore-jss/sitecore-jss-angular';
import type { FormState } from 'ngrx-forms';

@Component({
  selector: 'wl-sc-radio[control][fields][trackStep]',
  templateUrl: './sc-radio.component.html',
})
export class ScRadioComponent implements OnInit {
  @Input() control!:
    | FormState<boolean | undefined>
    | FormState<string | undefined>;
  @Input() fields!: ScRadioFormInput;
  @Input() floatingLabel = false;
  @Input() trackStep!: TrackToolStep;

  radioItems: RadioItem[] = [];

  ngOnInit() {
    this.radioItems = this.fields.Items.map((item) => ({
      label: item.fields.Label.value ?? '',
      value: item.fields.Value.value ?? '',
      disabled: item.fields.Disabled.value ?? false,
      sublabel: item.fields.SummaryLabel.value as RenderingField<string>,
      tooltip: {
        description: item.fields.Tooltip.value,
      },
      trackingLabel: item.fields.TrackingLabel.value,
    }));
  }
}
