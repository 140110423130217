import type { IncomingPaymentMethod } from '@essent/common';
import type { ValidationErrors } from 'ngrx-forms';

declare module 'ngrx-forms/src/state' {
  export interface ValidationErrors {
    paymentMethodNotChanged?: boolean;
  }
}

const _IsPaymentMethodChanged = (
  comparand?: `${IncomingPaymentMethod}`,
  value?: `${IncomingPaymentMethod}`
) => {
  return value !== comparand;
};

export const isPaymentMethodChanged = (
  comparand?: `${IncomingPaymentMethod}`
) => {
  return (value?: string): ValidationErrors => {
    if (
      _IsPaymentMethodChanged(comparand, value as `${IncomingPaymentMethod}`)
    ) {
      return {};
    }

    return {
      paymentMethodNotChanged: true,
    };
  };
};
