import { Directive, HostListener, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Item } from '@sitecore-jss/sitecore-jss/layout';

import { GenericErrorModalComponent } from './generic-error-modal.component';

@Directive({
  providers: [NgbModal],
  selector: '[wlOpenModal]',
})
export class OpenModalDirective {
  @Input()
  enabled = true;

  @Input()
  modalRendering?: Item;

  @HostListener('click', ['$event'])
  public onClick(event: any): void {
    if (!this.enabled) {
      return;
    }
    const target = event.target as Element;
    if (
      target.tagName.toLowerCase() === 'a' &&
      this.hasModalCheck(target) &&
      this.modalRendering
    ) {
      event.stopPropagation();
      event.preventDefault();

      const ref = this.modalService.open(GenericErrorModalComponent, {
        centered: true,
        keyboard: true,
        size: 'lg',
      });

      ref.componentInstance.modalTitle =
        this.modalRendering.fields?.['ModalTitle'];
      ref.componentInstance.modalMessage =
        this.modalRendering.fields?.['ModalMessage'];
    }
  }
  constructor(private readonly modalService: NgbModal) {}

  private hasModalCheck(target: Element): boolean {
    const nameAttr = target.getAttribute('name')?.toLowerCase();
    return nameAttr === 'modal';
  }
}
