import type { ValidationErrors } from 'ngrx-forms';

export const emailRegex =
  /^(?=.{1,64}@.*$)(?=.{1,254}$)(?:(?:[a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~]+(?:\.[a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~]+)*))@(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$/;

export interface IsEmailAddressValidator {
  actual: any;
}

declare module 'ngrx-forms/src/state' {
  export interface ValidationErrors {
    isEmailAddress?: IsEmailAddressValidator;
  }
}

const _IsEmailAddress = <T>(value: T | string) => {
  return typeof value === 'string' && emailRegex.test(value);
};

/**
 * Validates that the given value is an email
 * @param value
 */
export const isEmailAddress = <T>(value: T | string): ValidationErrors => {
  if (_IsEmailAddress(value)) {
    return {};
  }

  return {
    isEmailAddress: { actual: value },
  };
};
