import type {
  InsulationFunnelSettingsInterface,
  InsulationFunnelStep,
} from '@innogy/eplus/models';
import type { InsulationProductWithCalculations } from '@innogy/eplus/temporary-core-modules';
import { createAction } from '@ngrx/store';

import { createNamedFunnelActions } from '../../shared/generators';

const feature = 'Insulation';
const PREFIX = `[${feature} Funnel]`;

export const {
  initializeInsulationFunnelStepAction,
  setInsulationFunnelInitializedAction,
  clearInsulationFunnelInitializedAction,
  onInsulationFunnelNavigationAction,
  handleInsulationFunnelPrivateErrorAction,
  handleInsulationFunnelGenericErrorAction,
  toggleInsulationProductSelectionAction,
  confirmInsulationProductSelectionAction,
  resetInsulationProductSelectionAction,
  selectInsulationProductsAction,
  mintInsulationTrackingIdAction,
} = createNamedFunnelActions<
  typeof feature,
  InsulationFunnelStep,
  InsulationFunnelSettingsInterface,
  InsulationProductWithCalculations
>(feature);

export const handleInsulationFunnelAddressNotFoundErrorAction = createAction(
  `${PREFIX} Address not found Error`
);
