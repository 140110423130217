<wl-aom-costs-per-unit
  [costPerUnitVM]="costPerUnitVM"
  [rendering]="rendering"
  [isVATIncluded]="isVATIncluded"
  [fields]="fields"
  [isDynamicProduct]="offer?.isDynamicProduct"
></wl-aom-costs-per-unit>
<ng-container *ngIf="fields?.AomDynamicPricingDisclaimer && isDynamicProduct">
  <div *wlGenericRichText="fields?.AomDynamicPricingDisclaimer"></div>
</ng-container>
<p
  class="h4 mt-3 text-black"
  *scText="fields?.BudgetBillAmountTitle"
  wlCSCapture
></p>
<wl-rich-text-wrapper
  [field]="
    fields?.BudgetBillAmountParagraph
      | interpolate
        : {
            mode: 'rich_text',
            variables: {
              kwhNormal: kwhNormal,
              kwhLow: kwhLow,
              gas: gas,
              budgetBillAmount: budgetBillAmount | roundedCurrency,
            },
          }
  "
>
  <p class="section-content" wlCSCapture></p>
</wl-rich-text-wrapper>

<ng-container *ngIf="hasDiscount; else description">
  <p
    wlCSCapture
    class="h4 mt-3 text-black"
    *scText="
      fields?.CashbackTitle
        | interpolate
          : {
              variables: {
                cashback:
                  offer?.discountPrice | currency: 'EUR' : 'symbol' : '1.2',
              },
            }
    "
  ></p>
  <p class="section-content">
    <span *scText="fields?.CashbackParagraphPart1" wlCSCapture></span>
    <span class="text-green fw-bold ms-1" wlCSCapture>{{
      fields?.CashbackParagraphPart2?.value + '.'
        | interpolate
          : {
              variables: {
                cashback:
                  offer?.discountPrice | currency: 'EUR' : 'symbol' : '1.2',
              },
            }
    }}</span>
    <br />
    <span *scText="fields?.CashbackParagraphPart3" wlCSCapture></span>
  </p>
</ng-container>
<ng-template #description>
  <ng-container *ngIf="offer?.incentiveFullDescription">
    <p class="h4 mt-3 text-black">
      <span *scText="fields?.IncentiveTitlePrefix" wlCSCapture></span>
      {{ offer?.incentiveTitle }}
    </p>
    <p
      wlCSCapture
      class="section-content"
      [innerHtml]="
        fields?.IncentiveBodyPrefix?.value +
        ' ' +
        offer?.incentiveFullDescription
      "
    ></p>
  </ng-container>
</ng-template>

<p class="h4 mt-3 text-black" *scText="fields?.AomOfferTitle" wlCSCapture></p>
<div class="section-content">
  <ng-container *ngIf="!kwhLow; else paragraphDoubleMeter">
    <wl-rich-text-wrapper
      wlAutomationId="aom-paragraph-single"
      [field]="
        fields?.AomOfferParagraph
          | interpolate
            : {
                mode: 'rich_text',
                variables: {
                  kwh: kwhNormal,
                  gas: gas,
                  solarReturn: solarReturn,
                },
              }
      "
    >
      <p></p>
    </wl-rich-text-wrapper>
  </ng-container>
  <ng-template #paragraphDoubleMeter>
    <wl-rich-text-wrapper
      wlAutomationId="aom-paragraph-double"
      [field]="
        fields?.AomOfferParagraphDoubleMeter
          | interpolate
            : {
                mode: 'rich_text',
                variables: {
                  kwhNormal: kwhNormal,
                  kwhLow: kwhLow,
                  gas: gas,
                  solarReturn: solarReturn,
                  solarReturnLow: solarReturnLow,
                },
              }
      "
    >
      <p class="mb-3"></p>
    </wl-rich-text-wrapper>
  </ng-template>
</div>

<wl-aom-cost-table
  [rendering]="rendering"
  [offer]="offer"
  [fields]="fields"
></wl-aom-cost-table>

<div class="cost-section mt-3">
  <div class="d-flex justify-content-between w-100 h5 text-black">
    <span *scText="fields?.ExpectedYearlyCost" wlCSCapture></span>
    <span wlCSCapture>
      {{
        offer?.beforeDiscountExpectedYearlyAmount
          | currency: 'EUR' : 'symbol' : '1.2'
      }}
    </span>
  </div>
  <div
    *ngIf="hasDiscount"
    class="d-flex justify-content-between w-100 h5 text-green"
  >
    <span>
      <span *scText="fields?.DiscountFirstYear" wlCSCapture></span>
      <wl-tooltip
        class="ps-1"
        *ngIf="fields?.DiscountFirstYearTooltip?.value"
        [tooltip]="{
          description: fields?.DiscountFirstYearTooltip?.value,
        }"
      ></wl-tooltip>
    </span>
    <span wlCSCapture>
      - {{ offer?.discountPrice | currency: 'EUR' : 'symbol' : '1.2' }}
    </span>
  </div>
  <hr class="hr" />
  <div class="d-flex justify-content-between w-100 h5 text-body">
    <span *scText="fields?.YearlyCost" wlCSCapture></span>
    <span wlCSCapture>{{
      offer?.expectedYearlyAmount | currency: 'EUR' : 'symbol' : '1.2'
    }}</span>
  </div>
  <div class="d-flex justify-content-between w-100">
    <span>
      <span *scText="fields?.MonthlyCost" wlCSCapture></span>
      <wl-tooltip
        class="ps-1"
        *ngIf="fields?.MonthlyCostTooltip?.value"
        [tooltip]="{
          description: fields?.MonthlyCostTooltip?.value,
        }"
      ></wl-tooltip>
    </span>
    <span wlCSCapture>{{
      offer?.expectedMonthlyAmount | currency: 'EUR' : 'symbol' : '1.2'
    }}</span>
  </div>
  <div class="d-flex justify-content-between w-100">
    <span>
      <span *scText="fields?.TermCost" wlCSCapture></span>
      <wl-tooltip
        class="ps-1"
        *ngIf="fields?.TermCostTooltip?.value"
        [tooltip]="{
          description: fields?.TermCostTooltip?.value,
        }"
      ></wl-tooltip>
    </span>
    <span wlCSCapture>{{
      offer?.budgetBillAmount | currency: 'EUR' : 'symbol' : '1.2'
    }}</span>
  </div>
</div>

<wl-disclaimer [disclaimer]="disclaimer"></wl-disclaimer>
