import { createAction } from '@ngrx/store';

interface AddFileParams {
  singleFile: boolean;
  file: File;
  referenceId: string;
  controlId: string;
}

export const addFileAction = createAction(
  '[File input] Added file',
  (params: AddFileParams) => params
);

interface RemoveFileParams {
  referenceId: string;
  controlId: string;
}
export const removeFileAction = createAction(
  '[File input] Removed file',
  (params: RemoveFileParams) => params
);
