import { Injectable } from '@angular/core';
import type { CalculateToolstepLaststepResult } from '@innogy/become-a-customer/shared';
import { CalculateToolstepLaststepService } from '@innogy/become-a-customer/shared';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';

import { getUsageQuestionnaireFormState } from '../usage-questionnaire';
import { emitCalculateLaststepAction } from './calculate.actions';
import { getCalculateFormFormState } from './calculate.selectors';

@Injectable()
export class CalculateTrackingEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<any>,
    private readonly calculateToolStepLaststepService: CalculateToolstepLaststepService
  ) {}
  private readonly formValues$ = this.store$.select(getCalculateFormFormState);
  private readonly usageQuestionnaireFormValues$ = this.store$.select(
    getUsageQuestionnaireFormState
  );

  /**
   * Constructs and submits a laststep event when the Calculate subflow is finished.
   */
  public readonly emitCalculateLatstepEvent$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(emitCalculateLaststepAction),
        concatLatestFrom(() => [
          this.formValues$,
          this.usageQuestionnaireFormValues$,
        ]),
        tap(([_, formValues, questionnaireValues]) => {
          const calculateResultValues: CalculateToolstepLaststepResult = {
            stroomverbruikJaar: formValues.value.electricityUsage || 0,
            stroomverbruikJaarDal:
              formValues.value.electricityUsageOffPeak || 0,
            stroomverbruikJaarPiek:
              formValues.value.electricityUsageNormal || 0,
            gasverbruikJaar: formValues.value.gasUsage || 0,
            terugleveringJaar: formValues.value.electricityReturn || 0,
            bewonersAantal: questionnaireValues.value.residents || '',
            woningType: questionnaireValues.value.houseType || '',
            aansluitingType: questionnaireValues.value.supplyType || '',
          };

          this.calculateToolStepLaststepService.trackCalculateLaststep(
            calculateResultValues
          );
        })
      ),
    { dispatch: false }
  );
}
