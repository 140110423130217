import { StepName } from '@essent/contract';
import { createSelector } from '@ngrx/store';

import { onboardingFeatureSelector } from './onboarding.feature.selector';
import {
  getOnboardingError,
  getOnboardingLoading,
  getOnboardingState,
} from './onboarding.reducer';
import { isDeliveryStarted } from './onboarding.utils';

const getOnboardingFeature = createSelector(
  onboardingFeatureSelector,
  (feature) => feature
);

export const getOnboardingStatusState = createSelector(
  getOnboardingFeature,
  getOnboardingState
);

export const getOnboardingIsLoading = createSelector(
  getOnboardingFeature,
  getOnboardingLoading
);

export const getOnboardingHasError = createSelector(
  getOnboardingFeature,
  getOnboardingError
);

export const getCustomerDeliveryStartDate = createSelector(
  getOnboardingStatusState,
  (state) =>
    state?.steps.find((step) => step.step === StepName.DELIVERYSTART)?.date
);

export const getIsDeliveryStartCompleted = createSelector(
  getOnboardingStatusState,
  (state) => (state != null ? isDeliveryStarted(state.steps ?? []) : undefined)
);

export const isChangeEventDatePossible = createSelector(
  getOnboardingStatusState,
  (state) => state?.isChangeEventDatePossible
);

export const getOnboardingStatus = createSelector(
  getOnboardingStatusState,
  (state) => state?.processType
);

export const getOnboardingViewmodel = createSelector(
  getOnboardingStatusState,
  getCustomerDeliveryStartDate,
  getOnboardingHasError,
  (onboardingStatus, deliveryStartDate, hasError) => ({
    onboardingStatus,
    deliveryStartDate,
    hasError,
  })
);
