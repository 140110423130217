<wl-price-overview-table
  *ngIf="electricityOverview"
  [title]="fields?.ElectricityCostTitle?.value"
  [faIcon]="'plug'"
  [priceOverview]="electricityOverview"
  [rendering]="rendering"
></wl-price-overview-table>
<wl-price-overview-table
  *ngIf="gasOverview"
  [title]="fields?.GasCostTitle?.value"
  [faIcon]="'fire-flame'"
  [priceOverview]="gasOverview"
  [rendering]="rendering"
></wl-price-overview-table>
