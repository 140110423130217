import type {
  InsulationProductData,
  InsulationProductWithCalculations,
} from '@innogy/eplus/temporary-core-modules';
import {
  BuildingType,
  InsulationProductType,
} from '@innogy/eplus/temporary-core-modules';

export const mockInsulationProducts: InsulationProductData[] = [
  {
    suiteCrmId: 'spouwmuur',
    productType: {
      value: InsulationProductType.SPOUWMUURISOLATIE,
    },
    content: {
      usps: [
        {
          text: 'Warm in de winter en koel in de zomer',
          inactive: false,
          tooltip: '',
        },
      ],
      productInfo: [],
      title: 'Spouwmuurisolatie',
      highlight: '',
      scores: [{ text: 'Materiaal', outOf5: 3 }],
      text: 'Isolatie voor <em>in</em> je muur',
      image: {
        src: 'https://tst-sitecore-essent-cd-open-consument.innogynederland.nl/-/media/biogas-goeievraag-rechts-small.ashx?h=200&iar=0&w=350&hash=0995295FD1E05BE7203DF5826C4C6697',
        alt: '',
      },
      subTitle: 'Warm in de winter',
      submitButtonText: 'submit',
      submitButtonAction: 'navigate',
      submitButtonLink: { href: '/submit' },
      secondaryButtonText: 'secondary',
      secondaryButtonStyle: 'btn btn-link',
      secondaryButtonAction: 'navigate',
      secondaryButtonLink: { href: '/secondary' },
    },
    pricePerSquareMeter: 20.18,
    startingInstallationCosts: 155,
    minimumInstallationCosts: 900,
    gasSavingsPerSquareMeterPerYear: 9,
    buildingConstructionYearMinimum: 1930,
    unsuitableBuildingTypes: [
      {
        label: 'Flat of appartement',
        value: BuildingType.FLAT_APPARTEMENT,
      },
    ],
  },
  {
    suiteCrmId: 'vloer',
    productType: {
      value: InsulationProductType.VLOERISOLATIE,
    },
    content: {
      usps: [{ text: 'Mooie USP 1', inactive: false, tooltip: '' }],
      productInfo: [],
      title: 'Vloerisolatie',
      highlight: '',
      scores: [{ text: 'Materiaal', outOf5: 1 }],
      text: 'Hier veeg je de vloer mee aan',
      image: {
        src: 'https://tst-sitecore-essent-cd-open-consument.innogynederland.nl/-/media/biogas-goeievraag-rechts-small.ashx?h=200&iar=0&w=350&hash=0995295FD1E05BE7203DF5826C4C6697',
        alt: '',
      },
      subTitle: '',
      submitButtonText: 'submit',
      submitButtonAction: 'navigate',
      submitButtonLink: { href: '/submit' },
      secondaryButtonText: 'secondary',
      secondaryButtonStyle: 'btn btn-link',
      secondaryButtonAction: 'navigate',
      secondaryButtonLink: { href: '/secondary' },
    },
    pricePerSquareMeter: 31.78,
    startingInstallationCosts: 0,
    minimumInstallationCosts: 900,
    gasSavingsPerSquareMeterPerYear: 6,
    buildingConstructionYearMinimum: 1930,
    unsuitableBuildingTypes: [
      {
        label: 'Flat of appartement',
        value: BuildingType.FLAT_APPARTEMENT,
      },
    ],
  },
  {
    suiteCrmId: 'dak',
    productType: {
      value: InsulationProductType.DAKISOLATIE,
    },
    content: {
      usps: [{ text: 'Mooie USP 1', inactive: false, tooltip: '' }],
      productInfo: [],
      title: 'Dakisolatie',
      highlight: '',
      scores: [{ text: 'Materiaal', outOf5: 1 }],
      text: 'Je kan het dak op!',
      image: {
        src: 'https://tst-sitecore-essent-cd-open-consument.innogynederland.nl/-/media/biogas-goeievraag-rechts-small.ashx?h=200&iar=0&w=350&hash=0995295FD1E05BE7203DF5826C4C6697',
        alt: '',
      },
      subTitle: '',
      submitButtonText: 'submit',
      submitButtonAction: 'navigate',
      submitButtonLink: { href: '/submit' },
      secondaryButtonText: 'secondary',
      secondaryButtonStyle: 'btn btn-link',
      secondaryButtonAction: 'navigate',
      secondaryButtonLink: { href: '/secondary' },
    },
    pricePerSquareMeter: 68.78,
    startingInstallationCosts: 0,
    minimumInstallationCosts: 900,
    gasSavingsPerSquareMeterPerYear: 8,
    buildingConstructionYearMinimum: 1930,
    unsuitableBuildingTypes: [
      {
        label: 'Flat of appartement',
        value: BuildingType.FLAT_APPARTEMENT,
      },
      {
        label: 'Etagewoning',
        value: BuildingType.ETAGEWONING,
      },
      {
        label: 'Woonwagen of woonboot',
        value: BuildingType.WOONWAGEN_WOONBOOT,
      },
    ],
  },
];

export const mockInsulationProductsWithCalculations: InsulationProductWithCalculations[] =
  [
    {
      ...mockInsulationProducts[0],
      installationCosts: 9436,
      paybackPeriodInYears: 52,
      yearlySavings: 183,
      gasSavingsPerSquareMeterPerYear: 4139,
    },
    {
      ...mockInsulationProducts[1],
      installationCosts: 176566,
      paybackPeriodInYears: 1304,
      yearlySavings: 135,
      gasSavingsPerSquareMeterPerYear: 33335,
    },
    {
      ...mockInsulationProducts[2],
      installationCosts: 465878,
      paybackPeriodInYears: 2117,
      yearlySavings: 220,
      gasSavingsPerSquareMeterPerYear: 54188,
    },
  ];

export const mockInsulationProductWithCalculations =
  mockInsulationProductsWithCalculations[0];
