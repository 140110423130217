import type { OnInit, AfterViewInit } from '@angular/core';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  HostListener,
} from '@angular/core';
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';
import type { DeviceInfo } from '@innogy/shared/responsive-tools';
import { MediaObserverService } from '@innogy/shared/responsive-tools';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  IconCardAlignment,
  IconCardBackground,
  IconCardContext,
  IconCardVariant,
} from './icon-card.model';

@Component({
  selector: 'wl-icon-card-button',
  templateUrl: './icon-card-button.component.html',
  styleUrls: [
    './icon-card-button.component.ed.scss',
    './icon-card-button.component.essent.scss',
  ],
})
export class IconCardButtonComponent implements OnInit, AfterViewInit {
  @Input()
  iconName?: IconProp;

  @Input()
  title = '';

  @Input()
  context: IconCardContext = 'top-task';

  /**
   * NOTE: Subtitle will only be visible in the large variant
   */
  @Input()
  subTitle = '';

  @Input()
  variant: IconCardVariant = 'large';

  @Input()
  variantMobile: IconCardVariant = 'small';

  @Input()
  alignment: IconCardAlignment = 'center';

  @Input()
  alignmentMobile: IconCardAlignment = 'left';

  @Input()
  background: IconCardBackground = 'white';

  @Input()
  hoverBackground: IconCardBackground = 'brand';

  @Input()
  iconSize?: SizeProp;

  @Input()
  iconSizeMobile?: SizeProp;

  /**
   * For Essent, this will make the title $primary, for ED, this does not change anything visually.
   */
  @Input()
  useLinkStyle = false;

  // Chevron should appear to indicate a next action
  @Input()
  showNext = false;

  @Input()
  highlighted = false;

  @Input()
  automationId?: string;

  @Output()
  clicked = new EventEmitter<void>();

  deviceInfo$ = this.mediaObserverService.deviceInfo$;

  computedVariant$?: Observable<IconCardVariant>;
  computedAlignment$?: Observable<IconCardAlignment>;
  computedIconSize$?: Observable<SizeProp | undefined>;

  constructor(
    private readonly elementRef: ElementRef,
    private readonly mediaObserverService: MediaObserverService
  ) {}

  ngOnInit() {
    this.computedVariant$ = this.compute(this.variantMobile, this.variant);
    this.computedAlignment$ = this.compute(
      this.alignmentMobile,
      this.alignment
    );
    this.computedIconSize$ = this.compute(this.iconSizeMobile, this.iconSize);
  }

  ngAfterViewInit(): void {
    this.resolveMultilineStyling();
  }

  private compute<T>(mobileValue: T, desktopValue: T): Observable<T> {
    return this.deviceInfo$.pipe(
      map(({ isMobile }: DeviceInfo) => (isMobile ? mobileValue : desktopValue))
    );
  }

  /**
   * This function sets the width of the title's container to the title's width. By removing
   * the extra width on line-breaks, it enables the icon to stick to the title in the
   * small-centered variant. This is needed because flexbox will cause the title's container
   * (span) to be fullWidth if it spans across multiple rows.
   */
  @HostListener('window:resize')
  private resolveMultilineStyling(): void {
    const node = this.elementRef.nativeElement.querySelector(
      '.title[needs-fixed-width]'
    );
    if (node?.parentNode) {
      node.parentNode.style.width = null;
      const titleWidth = node.offsetWidth + 1; // Add 1px to avoid rounding errors
      node.parentNode.style.width = titleWidth + 'px';
    }
  }
}
