<div class="bg-light">
  <div class="d-flex justify-content-between align-items-center container">
    <div class="my-1">
      <ng-container
        *ngIf="openNavigation && openNavigation.value && openNavigation.label"
      >
        <a
          class="top-navigation--item open-link"
          wlTrackLink
          [trackLinkInfo]="{
            rendering: { componentName: { value: 'top-navigation' } },
            field: openNavigation.value,
            eventType: 'navigation-click',
          }"
          *wlGenericLink="openNavigation.value"
          wlCSCapture
        >
          {{ openNavigation.label }}
        </a>
      </ng-container>
      <ng-container *ngFor="let navItem of navItems">
        <a
          class="top-navigation--item"
          [class.active]="navItem?.active"
          *wlGenericLink="{ value: { href: navItem.path } }"
          wlCSCapture
        >
          {{ navItem.name }}
        </a>
      </ng-container>
    </div>

    <ng-container
      *ngIf="serviceNavigationActive; then serviceMenu; else serviceLink"
    ></ng-container>
    <ng-template #serviceMenu>
      <div ngbDropdown placement="bottom-right">
        <button
          class="top-navigation--dropdown-button"
          wlAutomationId="serviceNavigation"
          id="serviceNavigation"
          ngbDropdownToggle
          title="Servicenavigation"
        >
          <fa-icon *ngIf="!isLoggedIn" icon="user"></fa-icon>
          <fa-icon
            class="text-success"
            *ngIf="isLoggedIn"
            icon="user-check"
          ></fa-icon>
          <span wlCSCapture>{{ serviceNavigationTitle }}</span>
          <fa-icon
            class="top-navigation--menu-icon"
            icon="angle-down"
          ></fa-icon>
        </button>

        <div
          ngbDropdownMenu
          aria-labelledby="serviceNavigation"
          class="top-navigation--dropdown"
        >
          <wl-header-tools-container
            [loginUrl]="loginUrl"
            [loginUrlLabel]="loginUrlLabel"
          ></wl-header-tools-container>
        </div>
      </div>
    </ng-template>
    <ng-template #serviceLink>
      <ng-container
        *ngIf="
          serviceNavigationLink &&
          serviceNavigationLink.label &&
          serviceNavigationLink.value
        "
      >
        <a
          wlAutomationId="serviceLink"
          [title]="serviceNavigationLink.label"
          class="p-0 d-flex align-items-center top-navigation--button-link"
          *wlGenericLink="serviceNavigationLink.value"
        >
          <fa-icon icon="user"></fa-icon>
          <span>{{ serviceNavigationLink?.label }}</span>
        </a>
      </ng-container>
    </ng-template>
  </div>
</div>
