import type { ScFormFormGroupState } from '@innogy/sitecore-forms/models';
import { createAction, props } from '@ngrx/store';
import type { FormGroupState } from 'ngrx-forms';

export const submitGenericFormAction = createAction(
  '[Generic Form] Submit',
  props<{
    formId: string;
    integrationSettingsPath: string;
    e2eTrackingId: string;
    formGroupState: FormGroupState<ScFormFormGroupState>;
  }>()
);

export const markGenericFormAsPostedAction = createAction(
  '[Generic Form] Mark As Posted',
  props<{
    formId: string;
  }>()
);

export const resetGenericFormAction = createAction(
  '[Generic Form] Reset',
  props<{
    formId: string;
  }>()
);
