import { createSelector } from '@ngrx/store';

import { eplusInsulationFeature } from '../insulation-form.state';
import { insulationFunnelKey } from './insulation-funnel.reducer';

export const selectInsulationFunnelSettings = createSelector(
  eplusInsulationFeature,
  (state) => state[insulationFunnelKey]
);
export const selectSelectedInsulationProducts = createSelector(
  selectInsulationFunnelSettings,
  ({ selectedProducts }) => selectedProducts
);
export const selectInsulationTrackingId = createSelector(
  selectInsulationFunnelSettings,
  ({ trackingId }) => trackingId
);
