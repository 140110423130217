<div
  class="d-flex align-items-center"
  [class.justify-content-center]="centerText"
>
  <fa-icon
    *ngIf="showIcon && icon?.placement === 'left' && icon?.name"
    [icon]="icon?.name | faIcon"
    size="2x"
    [ngClass]="[icon?.color, 'me-1']"
  ></fa-icon>
  <ng-container *ngIf="heading?.text?.value">
    <ng-container *ngIf="heading?.tag === 'h1'">
      <h1
        *scText="heading?.text"
        [ngClass]="[heading?.style, heading?.size ?? size, 'mb-0']"
        [class.text-center]="centerText"
      ></h1>
    </ng-container>
    <ng-container *ngIf="heading?.tag === 'h2'">
      <h2
        *scText="heading?.text"
        [ngClass]="[heading?.style, heading?.size ?? size, 'mb-0']"
        [class.text-center]="centerText"
      ></h2>
    </ng-container>
    <ng-container *ngIf="heading?.tag === 'h3'">
      <h3
        *scText="heading?.text"
        [ngClass]="[heading?.style, heading?.size ?? size, 'mb-0']"
        [class.text-center]="centerText"
      ></h3>
    </ng-container>
    <ng-container *ngIf="heading?.tag === 'h4'">
      <h4
        *scText="heading?.text"
        [ngClass]="[heading?.style, heading?.size ?? size, 'mb-0']"
        [class.text-center]="centerText"
      ></h4>
    </ng-container>
    <ng-container *ngIf="heading?.tag === 'h5'">
      <h5
        *scText="heading?.text"
        [ngClass]="[heading?.style, heading?.size ?? size, 'mb-0']"
        [class.text-center]="centerText"
      ></h5>
    </ng-container>
    <ng-container *ngIf="heading?.tag === 'h6'">
      <h6
        *scText="heading?.text"
        [ngClass]="[heading?.style, heading?.size ?? size, 'mb-0']"
        [class.text-center]="centerText"
      ></h6>
    </ng-container>
  </ng-container>
  <fa-icon
    *ngIf="showIcon && icon?.placement === 'right' && icon?.name"
    [icon]="icon?.name | faIcon"
    size="2x"
    [ngClass]="[icon?.color, 'ms-1']"
  ></fa-icon>
</div>
