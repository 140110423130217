import type { HttpErrorResponse } from '@angular/common/http';
import { Status } from '@essent/common';
import {
  patchCommunicationAddress,
  patchCommunicationAddressError,
  patchCommunicationAddressSuccess,
  patchCommunicationAddressClear,
} from '@essent/communication-address';
import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';

export interface State {
  status: Status;
  error?: HttpErrorResponse;
}

export const initialState: State = {
  status: Status.IDLE,
};

export const _reducer = createReducer(
  initialState,
  on(patchCommunicationAddress, (_) => ({ status: Status.PENDING })),
  on(patchCommunicationAddressSuccess, (_) => ({
    status: Status.SUCCESS,
  })),
  on(patchCommunicationAddressError, (_, action) => ({
    status: Status.ERROR,
    error: action.payload,
  })),
  on(patchCommunicationAddressClear, (_) => initialState)
);

export function updateCommunicationAddressReducer(
  state = initialState,
  action: Action
): State {
  return _reducer(state, action);
}

export const getStatus = (state: State) => state.status;
export const getError = (state: State) => state.error;
