import type { OnDestroy, OnInit } from '@angular/core';
import { Directive, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import type {
  AnalyticsActionStepTypes,
  ToolTrackValues,
} from './track-tool.model';
import { AnalyticsActionTypes } from './track-tool.model';
import { TrackToolService } from './track-tool.service';

interface TrackStepModel extends ToolTrackValues {
  type?: AnalyticsActionStepTypes;
  extraProperties?: { [key: string]: any };
}

/**
 * Track a form tool-step.
 * @param type the step type, either step or last_step
 * @param formName the formname to send towards analytics. Defaults to the rendering componentname.
 */
@Directive({
  selector: '[wlTrackStep]',
})
export class TrackStepDirective implements OnDestroy, OnInit {
  private readonly defaultValues: TrackStepModel = {
    type: AnalyticsActionTypes.STEP,
    step: 0,
  };

  @Input('wlTrackStep')
  trackStep: TrackStepModel = this.defaultValues;

  private readonly onDestroy$ = new Subject();

  ngOnInit(): void {
    this.triggerAngularTics();
  }

  private triggerAngularTics() {
    const values = {
      ...this.defaultValues,
      ...this.trackStep,
    };
    return this.trackToolService
      .trackTool(values, values.extraProperties)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe();
  }

  constructor(private readonly trackToolService: TrackToolService) {}

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
