import { createNamedApi } from '@essent/common';

import { GetTimeslotsService } from './get-timeslots.service';

export const getTimeslotsApi = createNamedApi(
  '[GetTimeslots] Get timeslots',
  GetTimeslotsService
);

export const {
  getTimeslotsActions,
  getTimeslots,
  getTimeslotsClear,
  getTimeslotsError,
  getTimeslotsSuccess,
} = getTimeslotsApi;
