import { createSelector } from '@ngrx/store';

import { eplusHeatpumpsFeature } from '../heatpumps.state';
import { heatpumpsWizardKey } from './heatpumps-wizard.reducer';

export const selectHeatpumpsWizardState = createSelector(
  eplusHeatpumpsFeature,
  (state) => state[heatpumpsWizardKey]
);

export const selectHeatpumpsWizardFormState = createSelector(
  selectHeatpumpsWizardState,
  (state) => state.formState
);
