import type { Provider } from '@angular/core';
import {
  getAddressFormControlIds,
  createAddressEffects,
} from '@innogy/common-ui/forms';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import {
  selectLeadFormCorrespondenceAddress,
  selectLeadFormInstallationAddress,
} from './lead-form/lead-form.selectors';
import {
  correspondenceAddressGroupId,
  installationAddressGroupId,
} from './lead-form/lead-form.address.reducer';

class InstallationAddressEffects {}
const InstallationAddressEffectsProvider: Provider = {
  provide: InstallationAddressEffects,
  useFactory: (actions: Actions, store: Store) =>
    createAddressEffects(
      actions,
      store,
      getAddressFormControlIds(installationAddressGroupId),
      selectLeadFormInstallationAddress,
      {
        skipAddressCheckOnPrefilledData: true,
      }
    ),
  deps: [Actions, Store],
};

class CorrespondenceAddressEffects {}
const CorrespondenceEffectsProvider: Provider = {
  provide: CorrespondenceAddressEffects,
  useFactory: (actions: Actions, store: Store) =>
    createAddressEffects(
      actions,
      store,
      getAddressFormControlIds(correspondenceAddressGroupId),
      selectLeadFormCorrespondenceAddress,
      {
        skipAddressCheckOnPrefilledData: true,
      }
    ),
  deps: [Actions, Store],
};

export const addressEffects = [
  InstallationAddressEffects,
  CorrespondenceAddressEffects,
];
export const addressProviders = [
  InstallationAddressEffectsProvider,
  CorrespondenceEffectsProvider,
];
