import { IncomingPaymentMethod } from '@essent/common';
import type { ValidationErrors } from 'ngrx-forms';

export interface IsManualPaymentMethodValidator {
  actual: any;
}

declare module 'ngrx-forms/src/state' {
  export interface ValidationErrors {
    isManualPaymentMethod?: IsManualPaymentMethodValidator;
  }
}

const _IsManualPaymentMethod = (value?: IncomingPaymentMethod) => {
  return value === IncomingPaymentMethod.MANUAL_TRANSFER;
};

export const isManualPaymentMethod = (value?: string): ValidationErrors => {
  if (_IsManualPaymentMethod(value as IncomingPaymentMethod)) {
    return { isManualPaymentMethod: { actual: value } };
  }

  return {};
};
