import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  ComponentRendering,
  RichTextField,
} from '@sitecore-jss/sitecore-jss-angular';
import { FormState, MarkAsTouchedAction } from 'ngrx-forms';

@Component({
  selector: 'wl-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.ed.scss', './toggle.component.essent.scss'],
})
export class ToggleComponent {
  @Input()
  toggleId?: string;
  @Input()
  labelText?: RichTextField;

  @Input()
  formControl?: FormState<boolean>;

  _checked = false;
  get checked(): boolean {
    return this._checked;
  }
  @Input() set checked(value: boolean) {
    this._checked = value;
    this.checkedChange.emit(value);
  }

  @Input()
  fieldName?: string;
  @Input()
  stepName?: string;
  @Input()
  formName?: string;
  @Input()
  rendering?: ComponentRendering;
  @Input()
  name?: string;

  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-native
  toggle = new EventEmitter<Event>();
  @Output() checkedChange = new EventEmitter<boolean>();

  private _markedAsTouched = false;

  public onChange(event: Event) {
    this.toggle.emit(event);

    if (!this._markedAsTouched) {
      this.markAsTouched();
      this._markedAsTouched = true;
    }
  }

  public markAsTouched() {
    if (this.formControl) {
      this.store$.dispatch(new MarkAsTouchedAction(this.formControl.id));
    }
  }

  constructor(private readonly store$: Store<any>) {}
}
