import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import type { BaseService } from '@essent/common';
import { API_PUBLIC_PREFIX } from '@core/config-angular';
import { CreateLeadInsulationSchema } from '@integration/api-client/feh';
import type { Observable } from 'rxjs';

@Injectable()
export class PostCreateLeadInsulationService
  implements BaseService<CreateLeadInsulationSchema, {}>
{
  readonly apiVersion = 'v1';
  readonly endpoint = `${this.apiUrl || ''}/feh/leads/insulation/${
    this.apiVersion
  }`;

  public constructor(
    @Optional()
    @Inject(API_PUBLIC_PREFIX)
    private readonly apiUrl: string,
    private readonly httpClient: HttpClient
  ) {}

  public call$(payload: CreateLeadInsulationSchema): Observable<{}> {
    return this.httpClient.post(this.endpoint, payload);
  }
}
