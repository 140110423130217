import { createSelector } from '@ngrx/store';

import { getBecomeACustomerState } from '../../become-a-customer-feature.selector';

export const getPaymentDetailsState = createSelector(
  getBecomeACustomerState,
  (state) => state.paymentDetails.getPaymentDetails
);

export const putPaymentDetailsState = createSelector(
  getBecomeACustomerState,
  (state) => state.paymentDetails.putPaymentDetails
);
