import type { GenericProductData } from '@innogy/common-ui/models';
import difference from 'lodash/difference';
import includes from 'lodash/includes';

export function handleToggleProduct<TProductData extends GenericProductData>(
  product: TProductData,
  products: TProductData[]
) {
  if (includes(products, product)) {
    return difference(products, [product]);
  } else {
    return [...products, product];
  }
}
