import type { StoreProductData } from '@innogy/eplus/temporary-core-modules';

export const mockStoreProducts: StoreProductData[] = [
  {
    suiteCrmId: 'suiteCrmId1',
    price: 123,
    isSubscription: true,
    campaignId: '123456',
    subscriptionInterval: 'monthly',
    content: {
      productPricePrefixLabel: 'string',
      productPriceSuffixLabel: 'string',
      title: 'Testproduct',
      highlight: '',
      scores: [
        {
          text: 'Materiaal',
          outOf5: 4,
        },
      ],
      text: '<h1>Supermooie unit</h1>\n<p>Met een prachtig tekstje erbij ook nog, dolle pret</p>',
      image: {
        src: 'https://tst-sitecore-essent-cd-open-consument.innogynederland.nl/-/media/energiewonen/zonnepanelen/bitmapzonnepaneel.ashx?h=260&iar=0&w=260&hash=89EED7D2D2E35CCAD3EF2483FC5811B4',
        alt: '',
      },
      subTitle: 'Mooi ding',
      usps: [
        {
          text: 'Goedkoop',
          inactive: false,
          tooltip: '',
        },
        {
          text: 'Milieuvriendelijk',
          inactive: true,
          tooltip: 'Niet Milieuvriendelijk',
        },
      ],
      productInfo: [],
      submitButtonText: 'submit',
      submitButtonAction: 'navigate',
      submitButtonLink: { href: '/submit' },
      secondaryButtonText: 'secondary',
      secondaryButtonStyle: 'btn btn-link',
      secondaryButtonAction: 'navigate',
      secondaryButtonLink: { href: '/secondary' },
    },
  },
  {
    suiteCrmId: 'suiteCrmId2',
    price: 456,
    campaignId: '1234567',
    isSubscription: false,
    subscriptionInterval: undefined,

    content: {
      productPricePrefixLabel: 'string',
      productPriceSuffixLabel: 'string',
      title: 'Testproduct 2',
      highlight: 'Beste keus!',
      scores: [
        {
          text: 'Materiaal',
          outOf5: 4,
        },
      ],
      text: '<h1>Supermooie unit</h1>\n<p>Met een prachtig tekstje erbij ook nog, dolle pret</p>',
      image: {
        src: 'https://tst-sitecore-essent-cd-open-consument.innogynederland.nl/-/media/energiewonen/zonnepanelen/bitmapzonnepaneel.ashx?h=260&iar=0&w=260&hash=89EED7D2D2E35CCAD3EF2483FC5811B4',
        alt: '',
      },
      subTitle: 'Mooi ding',
      usps: [
        {
          text: 'Goedkoop',
          inactive: false,
          tooltip: '',
        },
        {
          text: 'Milieuvriendelijk',
          inactive: false,
          tooltip: '',
        },
      ],
      productInfo: [],
      submitButtonText: 'submit',
      submitButtonAction: 'navigate',
      submitButtonLink: { href: '/submit' },
      secondaryButtonText: 'secondary',
      secondaryButtonStyle: 'btn btn-link',
      secondaryButtonAction: 'navigate',
      secondaryButtonLink: { href: '/secondary' },
    },
  },
  {
    suiteCrmId: 'suiteCrmId3',
    price: 789,
    campaignId: '12345678',
    isSubscription: false,
    subscriptionInterval: undefined,

    content: {
      productPricePrefixLabel: 'string',
      productPriceSuffixLabel: 'string',
      title: 'Absolute Unit',
      highlight: '',
      scores: [
        {
          text: 'Materiaal',
          outOf5: 5,
        },
      ],
      text: '<h1>Supermooie unit</h1>\n<p>Met een prachtig tekstje erbij ook nog, dolle pret</p>',
      image: {
        src: 'https://tst-sitecore-essent-cd-open-consument.innogynederland.nl/-/media/energiewonen/zonnepanelen/s-energy-small.ashx?h=243&iar=0&w=125&hash=D834023973EC8C8A2E1DE3F5EE396899',
        alt: 'S-Energy zonnepanelen',
      },
      subTitle: 'Very nice',
      usps: [
        {
          text: 'Milieuvriendelijk',
          inactive: false,
          tooltip: '',
        },
      ],
      productInfo: [],
      submitButtonText: 'submit',
      submitButtonAction: 'navigate',
      submitButtonLink: { href: '/submit' },
      secondaryButtonText: 'secondary',
      secondaryButtonStyle: 'btn btn-link',
      secondaryButtonAction: 'navigate',
      secondaryButtonLink: { href: '/secondary' },
    },
  },
];

export const mockStoreProduct = mockStoreProducts[0];
