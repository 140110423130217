import type { AsyncState } from '@essent/common';
import {
  createAsyncStateReducer,
  createInitialAsyncState,
} from '@essent/common';
import type { CustomerPaymentDetails } from '@essent/new-customer';
import {
  getPaymentDetails,
  getPaymentDetailsClear,
  getPaymentDetailsError,
  getPaymentDetailsSuccess,
  putPaymentDetails,
  putPaymentDetailsClear,
  putPaymentDetailsError,
  putPaymentDetailsSuccess,
} from '@essent/new-customer';
import type { Action, ActionReducerMap } from '@ngrx/store';
import { combineReducers } from '@ngrx/store';

export type PutPaymentDetailsState = AsyncState<void>; // FIXME, expected FlowId to be required
export type GetPaymentDetailsState = AsyncState<CustomerPaymentDetails>;

export interface PaymentDetailsState {
  putPaymentDetails: PutPaymentDetailsState;
  getPaymentDetails: GetPaymentDetailsState;
}

const initialState: PaymentDetailsState = {
  putPaymentDetails: createInitialAsyncState(),
  getPaymentDetails: createInitialAsyncState(),
};

const _putPaymentDetailsReducer = createAsyncStateReducer(
  putPaymentDetails,
  putPaymentDetailsSuccess,
  putPaymentDetailsError,
  putPaymentDetailsClear
);

const _getPaymentDetailsReducer = createAsyncStateReducer(
  getPaymentDetails,
  getPaymentDetailsSuccess,
  getPaymentDetailsError,
  getPaymentDetailsClear
);

const _reducers: ActionReducerMap<PaymentDetailsState, Action> = {
  putPaymentDetails: _putPaymentDetailsReducer,
  getPaymentDetails: _getPaymentDetailsReducer,
};

const _combinedReducers = combineReducers(_reducers);

export function paymentDetailsReducer(
  state: PaymentDetailsState = initialState,
  action: Action
): PaymentDetailsState {
  return _combinedReducers(state, action);
}
