import {
  createAsyncStateReducer,
  createInitialAsyncState,
} from '@essent/common';
import {
  getEstimatedConsumptionByHouseType,
  getEstimatedConsumptionByHouseTypeClear,
  getEstimatedConsumptionByHouseTypeError,
  getEstimatedConsumptionByHouseTypeSuccess,
} from '@essent/estimate-consumption';
import type { Action } from '@ngrx/store';

import type { EstimateConsumptionByHouseTypeState } from './estimate-consumption-by-house.selector';

const initialState: EstimateConsumptionByHouseTypeState =
  createInitialAsyncState();

const _estimatedConsumptionByHouseTypeReducer = createAsyncStateReducer(
  getEstimatedConsumptionByHouseType,
  getEstimatedConsumptionByHouseTypeSuccess,
  getEstimatedConsumptionByHouseTypeError,
  getEstimatedConsumptionByHouseTypeClear
);

export function estimateConsumptionByHouseTypeReducer(
  state: EstimateConsumptionByHouseTypeState = initialState,
  action: Action
): EstimateConsumptionByHouseTypeState {
  return _estimatedConsumptionByHouseTypeReducer(state, action);
}
