import type { Status } from '@essent/common';
import type { ProductDuration } from '@essent/new-customer';
import type {
  CallGetOffersPayload,
  Offer,
  OfferSet,
} from '@innogy/become-a-customer/shared/interfaces';
import { createAction, props } from '@ngrx/store';

import { BAC_ACTION_PREFIX } from '../constants';

export const loadOffersetAction = createAction(
  `${BAC_ACTION_PREFIX} Load offerset`,
  props<{ offerset: string; setAsActive?: boolean }>()
);

export const loadNonDefaultDurationsAction = createAction(
  `${BAC_ACTION_PREFIX} Load non_default offerset durations`,
  props<{ offerset: string }>()
);

export const appendOffersAction = createAction(
  `${BAC_ACTION_PREFIX} Append offers`,
  props<OfferSet>()
);

export const setActiveOffersAction = createAction(
  `${BAC_ACTION_PREFIX} Set active offers by ids`,
  props<{ offerset: string; offerIds: string[] }>()
);

export const setActiveOffersByDurationAction = createAction(
  `${BAC_ACTION_PREFIX} Set active offers by duration`,
  props<{ offerset: string | null; duration: ProductDuration | null }>()
);

export const setActiveOffersetAction = createAction(
  `${BAC_ACTION_PREFIX} Set active offerset`,
  props<{ offerset: string }>()
);

export const clearActiveOffersAction = createAction(
  `${BAC_ACTION_PREFIX} Clear active offers`
);
export const clearOffersAction = createAction(
  `${BAC_ACTION_PREFIX} Clear offers`
);

export const setReadyToLoadOffersAction = createAction(
  `${BAC_ACTION_PREFIX} Mark state as ready to load offers`
);

export const setPropositionOfferStatusAction = createAction(
  `${BAC_ACTION_PREFIX} Set proposition offer status`,
  props<{ status: Status }>()
);

export const setPropositionOfferAction = createAction(
  `${BAC_ACTION_PREFIX} Set proposition offer`,
  props<{ offer?: Offer }>()
);

export const resetReadyToLoadOffersAction = createAction(
  `${BAC_ACTION_PREFIX} Reset ready to load offers`
);

export const callGetOffersAction = createAction(
  `${BAC_ACTION_PREFIX} Call get offers`,
  props<CallGetOffersPayload>()
);
