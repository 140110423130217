import { Component, Input, type OnInit, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { type FormGroupState, SetValueAction } from 'ngrx-forms';

import {
  BAC_QUESTIONNAIRE_ID,
  UsageQuestionnaireProps,
} from '../usage-questionnaire/usage-questionnaire.model';
import {
  SupplyType,
  type UsageQuestionnaireFormValues,
  getUsageFormControlIds,
} from '../usage-questionnaire/state/usage-questionnaire.reducer';

@Component({
  selector: 'wl-usage-questionnaire-modal',
  templateUrl: './usage-questionnaire-modal.component.html',
})
export class UsageQuestionnaireModalComponent implements OnInit {
  @Input({ required: true })
  formState$!: Observable<FormGroupState<UsageQuestionnaireFormValues>>;
  @Input() rendering?: UsageQuestionnaireProps;
  @Input() gasHidden = false;

  private readonly store = inject(Store);

  constructor(private readonly activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    if (this.gasHidden) {
      this.store.dispatch(
        new SetValueAction(
          getUsageFormControlIds(BAC_QUESTIONNAIRE_ID).supplyTypeControlId,
          SupplyType.E_ONLY
        )
      );
    }
  }

  public closeModal() {
    this.activeModal.close();
  }
}
