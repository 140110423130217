import { NgModule } from '@angular/core';
import { combineEffectsWithProviders } from '@essent/common';
import { EffectsModule } from '@ngrx/effects';

import { getChargeCardsAPI } from './chargecards/get-chargecards.config';
import { postBlockChargeCardAPI } from './chargecards/post-block-charge-card.config';

const { EFFECTS, PROVIDERS } = combineEffectsWithProviders(
  getChargeCardsAPI,
  postBlockChargeCardAPI
);
@NgModule({
  imports: [EffectsModule.forFeature(EFFECTS)],
  providers: [...PROVIDERS],
})
export class EmobilityChargeCardServicesModule {}
