import type { KeyValue } from 'ngrx-forms';

import type { NGRXProgressiveFormsActions } from '../actions';
import { MarkAsRestorationPointAction } from '../actions';
import type { ProgressiveFormGroupState } from '../state';

export function markAsRestorationPointReducer<TValue extends KeyValue>(
  state: ProgressiveFormGroupState<TValue>,
  action: NGRXProgressiveFormsActions
): ProgressiveFormGroupState<TValue> {
  if (action.type !== MarkAsRestorationPointAction.TYPE) {
    return state;
  }

  if (action.stepId !== state.id && state.isRestorationPoint) {
    return {
      ...state,
      isRestorationPoint: false,
    };
  }

  if (action.stepId !== state.id) {
    return state;
  }

  if (state.isRestorationPoint) {
    return state;
  }

  return {
    ...state,
    isRestorationPoint: true,
  };
}
