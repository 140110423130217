import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { UsageWizardSMEProps } from '@innogy/become-a-customer/shared/interfaces';
import {
  type BecomeACustomerState,
  UsageQuestionnaireSME,
} from '@innogy/become-a-customer/state';
import { getUsageQuestionnaireSMEFormState } from '@innogy/become-a-customer/state';
import { SupplyType } from '@innogy/common-ui/sales';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store, select } from '@ngrx/store';
import { type FormGroupState, SetValueAction } from 'ngrx-forms';
import type { Observable } from 'rxjs';

@Component({
  selector: 'wl-usage-questionnaire-sme-modal',
  templateUrl: './usage-questionnaire-sme-modal.component.html',
  styleUrls: [],
})
export class UsageQuestionnaireSMEModalComponent implements OnInit {
  @Input() rendering!: UsageWizardSMEProps;
  @Input() gasHidden = false;

  formState$?: Observable<FormGroupState<UsageQuestionnaireSME.FormValues>>;

  constructor(
    private readonly activeModal: NgbActiveModal,
    private readonly store$: Store<BecomeACustomerState>
  ) {}

  ngOnInit(): void {
    this.formState$ = this.store$.pipe(
      select(getUsageQuestionnaireSMEFormState)
    );

    if (this.gasHidden) {
      this.store$.dispatch(
        new SetValueAction(
          UsageQuestionnaireSME.supplyTypeControlId,
          SupplyType.E_ONLY
        )
      );
    }
  }

  closeModal() {
    this.activeModal.close();
  }
}
