import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
  name: 'roundedCurrency',
})
export class RoundedCurrencyPipe implements PipeTransform {
  transform(value: string | number, ..._args: unknown[]): string {
    return `${new CurrencyPipe('nl').transform(
      value,
      'EUR',
      'symbol',
      '1.0-0'
    )},-`;
  }
}
