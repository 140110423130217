<div class="offers">
  <div class="mt-lg-6">
    <wl-commodity-offer-header
      [alignment]="'vertical'"
      [headerTitle]="content?.componentTitle"
    ></wl-commodity-offer-header>
    <div class="mb-4">
      <wl-commodity-offer-calculation-based-on
        [variant]="'narrow'"
        [label]="content?.calculationBasedOnLabel"
        [address]="supplyAddress"
        [consumptions]="consumptionValues"
        [electricityLabel]="'ElectricityUsageUnit' | translate"
        [gasLabel]="'GasUsageUnit' | translate"
        [adjustCalculationLabel]="content?.adjustCalculationLabel"
        (adjustCalculation)="adjustCalculation.emit()"
      ></wl-commodity-offer-calculation-based-on>
    </div>
    <div
      class="d-none d-lg-block"
      *ngIf="content?.offerFootNote || content?.componentFootNote"
    >
      <ng-container *ngTemplateOutlet="text"></ng-container>
    </div>
  </div>
  <div>
    <div class="mb-3 mb-lg-0 d-flex flex-column gap-3">
      <wl-commodity-offer-list
        [offers]="offers"
        [alignment]="'vertical'"
        [content]="content"
        [showMemberGetMember]="showMemberGetMember"
        (selectOffer)="selectOffer.emit($event)"
        (openMoreInfo)="openMoreInfo.emit($event)"
        (viewTariffs)="viewTariffs.emit($event)"
        wlAutomationId="vertical-commodity-offer-list"
      />

      <ng-container *ngIf="content?.offerSaver as offerSaverContent">
        <wl-offer-saver-button
          *ngIf="content?.offerSaverShown"
          [content]="offerSaverContent"
        />
      </ng-container>
    </div>

    <div class="d-lg-none">
      <ng-container *ngTemplateOutlet="text"></ng-container>
    </div>
  </div>
</div>

<ng-template #text>
  <ng-container *ngIf="content?.offerFootNote">
    <hr class="my-3" />
    <wl-rich-text-wrapper [field]="{ value: content?.offerFootNote }"
      ><div></div
    ></wl-rich-text-wrapper>
  </ng-container>
  <ng-container *ngIf="content?.componentFootNote">
    <hr class="my-3" />
    <wl-rich-text-wrapper [field]="{ value: content?.componentFootNote }"
      ><div></div
    ></wl-rich-text-wrapper>
  </ng-container>
</ng-template>
