import type { OnChanges, SimpleChanges } from '@angular/core';
import {
  ContentChildren,
  Directive,
  ElementRef,
  Inject,
  Input,
  Optional,
  QueryList,
} from '@angular/core';
import type { Actions } from '@innogy/progressive-ngrx-forms';
import { ProgressiveFormGroupState } from '@innogy/progressive-ngrx-forms';
import { ActionsSubject } from '@ngrx/store';
import type { KeyValue } from 'ngrx-forms';
import { FocusAction, NgrxFormControlDirective } from 'ngrx-forms';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[focusOnFirstChildControl]',
})
export class FocusOnFirstChildControlDirective<TStateValue extends KeyValue>
  implements OnChanges
{
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('enableFocusing')
  enableAutomaticFocusing?: boolean;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('focusOnFirstChildControl')
  state?: ProgressiveFormGroupState<TStateValue>;

  @ContentChildren(NgrxFormControlDirective)
  contentChildren!: QueryList<NgrxFormControlDirective<any>>;

  constructor(
    private readonly elementRef: ElementRef,
    @Optional()
    @Inject(ActionsSubject)
    private readonly actionsSubject: ActionsSubject | null
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const oldState: ProgressiveFormGroupState<TStateValue> =
      changes['state']?.previousValue;
    const newState: ProgressiveFormGroupState<TStateValue> =
      changes['state']?.currentValue;

    if (!newState) {
      return;
    }

    if ((!oldState || oldState.isInactive) && newState.isActive) {
      if (this.enableAutomaticFocusing) {
        // Wait for the queue to flush, so all child nodes are
        // guaranteed to be available in the DOM.
        setTimeout(() => this.focusOnFirstChildControl(), 0);
      }
    }
  }

  protected dispatchAction(action: Actions) {
    if (this.actionsSubject !== null) {
      this.actionsSubject.next(action);
    } else {
      throw new Error(
        'ActionsSubject must be present in order to dispatch actions!'
      );
    }
  }

  private focusOnFirstChildControl() {
    const inputElement: HTMLInputElement | null =
      this.elementRef.nativeElement.querySelector('input');
    if (inputElement?.name) {
      this.dispatchAction(new FocusAction(inputElement.name));
    }
  }
}
