import { createSelector } from '@ngrx/store';

import { eplusSolarPanelsFeature } from '../solar-panels-form.state';
import { solarPanelsWizardFormId } from './solar-panels-wizard.reducer';

const selectSolarPanelsWizardState = createSelector(
  eplusSolarPanelsFeature,
  (state) => state[solarPanelsWizardFormId]
);

export const selectSolarPanelsWizardViewModel = selectSolarPanelsWizardState;

export const selectSolarPanelsWizardFormState = createSelector(
  selectSolarPanelsWizardState,
  (state) => state.formState
);

export const selectSolarPanelsWizardAnalyticsPayload = createSelector(
  selectSolarPanelsWizardFormState,
  (state) => ({
    consumption: state.value.consumption,
  })
);
