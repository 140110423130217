import type {
  Connection,
  Contract,
  ContractAddress,
  ContractAddressBpm,
  ContractAddressCrm,
} from '@essent/address';
import { ContractStatus } from '@essent/address';
import { Status } from '@essent/common';
import { getSettingsValue } from '@core/settings';
import { selectQueryParam } from '@innogy/utils-state';
import { sortBy } from '@innogy/utils-deprecated';
import { createSelector } from '@ngrx/store';
import { isBefore, parseISO, sub } from 'date-fns';

import * as fromContracts from './contract-addresses.reducer';
import { getCustomerDetailsState } from './feature.selectors';

const getContractAddressState = createSelector(
  getCustomerDetailsState,
  (state) => state.contractAddresses
);

export const getContractAddresses = createSelector(
  getContractAddressState,
  fromContracts.selectContractAddresses
);

export const isCrmContract = (
  contract: ContractAddress
): contract is ContractAddressCrm =>
  (contract as ContractAddressCrm).supplyAddressId != null;

export const isBPMContract = (
  contract: ContractAddress
): contract is ContractAddressBpm => !('supplyAddressId' in contract);

export const getActiveContractAddresses = createSelector(
  getContractAddresses,
  (addresses = []) => addresses.filter(isCrmContract)
);

export const getFutureContractAddresses = createSelector(
  getContractAddresses,
  (addresses = []) => addresses.filter(isBPMContract)
);

export const getSelectedContractAddress = createSelector(
  getActiveContractAddresses,
  selectQueryParam('address'),
  (addresses, selectedId) =>
    addresses.find((address) => address.supplyAddressId === selectedId)
);

export const getContractAddressError = createSelector(
  getContractAddressState,
  (state) => state.status === Status.ERROR
);

const isShowTariffsEnabled = getSettingsValue<boolean>(
  'featureFlags',
  'enableShowTariffs'
);

export const getContractAddressLoading = createSelector(
  getContractAddressState,
  (state) => state.status === Status.PENDING || state.status === Status.IDLE
);

export const getContractAddressesDetails = createSelector(
  getContractAddressLoading,
  getContractAddressError,
  getSelectedContractAddress,
  isShowTariffsEnabled,
  (showLoading, showError, contractAddress, showTariffsEnabled) => {
    return {
      showLoading,
      showError,
      contractAddress,
      showTariffsEnabled: showTariffsEnabled ?? false,
    };
  }
);

const getContractsFromContractAddress = (address?: ContractAddress) =>
  address?.connections?.connection?.reduce(
    (accumulatedContracts: Contract[], connection: Connection) => {
      return [...accumulatedContracts, ...(connection?.contract ?? [])];
    },
    []
  ) ?? [];

const getYoungestContract = createSelector(
  getSelectedContractAddress,
  (address?: ContractAddress) => {
    const contracts = getContractsFromContractAddress(address);
    contracts.sort((a, b) => sortBy(b, a, 'startDate'));

    return contracts.length ? contracts[0] : undefined;
  }
);

export const getFirstContractEndDateToEnd = createSelector(
  getSelectedContractAddress,
  (address) => {
    const contracts = getContractsFromContractAddress(address).filter(
      (contract) => contract.status === ContractStatus.ACTIVE
    );
    contracts.sort((a, b) => sortBy(a, b, 'endDate'));

    return contracts.length ? contracts[0]?.endDate : undefined;
  }
);

export const shouldShowCancellationOption = createSelector(
  getYoungestContract,
  (contract) => {
    const threeMonthsAgo = sub(new Date(), { months: 3 });

    return contract?.startDate
      ? isBefore(threeMonthsAgo, parseISO(contract.startDate))
      : false;
  }
);
