import { NgModule } from '@angular/core';

import { NavigationSharedModule } from './navigation.shared.module';
import { MinimalStickyHeaderComponent } from './minimal-sticky-header/minimal-sticky-header.component';
import { MinimalStickyHeaderContainerComponent } from './minimal-sticky-header/minimal-sticky-header-container/minimal-sticky-header-container.component';
import { EssentHeaderComponent } from './essent-header/essent-header.component';
import { NavigationEssentModule } from './navigation.essent.module';

@NgModule({
  imports: [NavigationEssentModule, NavigationSharedModule],
  declarations: [
    EssentHeaderComponent,
    MinimalStickyHeaderComponent,
    MinimalStickyHeaderContainerComponent,
  ],
  exports: [EssentHeaderComponent],
})
export class NavigationEssentOpenModule {}
