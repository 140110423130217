import type { ToolTrackingConfig } from '@innogy/core/analytics';
import { createToolStepConfig, parseToolConfig } from '@innogy/core/analytics';

import {
  ANALYTICS_KEY_ASBESTOS,
  ANALYTICS_KEY_CONSUMPTION,
  ANALYTICS_KEY_RENEWED_ROOF,
  ANALYTICS_KEY_ROOF_TYPE,
} from '../../constants';
import { selectSolarPanelsSuitabilityCheckAnalyticsPayload } from './solar-panels-suitability-check.selectors';

const solarPanelSuitabilityCheckSteps = [
  {
    step: 0,
    stepName: ANALYTICS_KEY_ASBESTOS,
    id: 'asbestos',
  },
  {
    step: 1,
    stepName: ANALYTICS_KEY_ROOF_TYPE,
    id: 'roofType',
  },
  {
    step: 2,
    stepName: ANALYTICS_KEY_RENEWED_ROOF,
    id: 'renewedRoofWithinTimeLimit',
  },
] as const;

export type SolarPanelSuitabilityCheckToolTrackingSteps =
  (typeof solarPanelSuitabilityCheckSteps)[number]['id'];

const solarPanelsSuitabilityCheckToolTrackingConfig: ToolTrackingConfig<SolarPanelSuitabilityCheckToolTrackingSteps> =
  {
    steps: solarPanelSuitabilityCheckSteps,
    toolComplete: createToolStepConfig(
      selectSolarPanelsSuitabilityCheckAnalyticsPayload,
      (payload) => ({
        step: 3,
        payload: {
          [ANALYTICS_KEY_CONSUMPTION]: payload.consumption,
          [ANALYTICS_KEY_ASBESTOS]: payload.asbestos ?? '',
          [ANALYTICS_KEY_ROOF_TYPE]: payload.roofType ?? '',
          [ANALYTICS_KEY_RENEWED_ROOF]:
            payload.renewedRoofWithinTimeLimit ?? '',
        },
      })
    ),
  };

export const parsedSolarPanelsSuitabilityCheckToolTrackingConfig = (
  toolName?: string
) =>
  parseToolConfig({
    ...solarPanelsSuitabilityCheckToolTrackingConfig,
    ...(toolName && { toolName }),
  });
