/* eslint-disable max-classes-per-file */
import type { HttpErrorResponse } from '@angular/common/http';
import type { Action } from '@ngrx/store';

import type { Settings } from '../settings.model';

export const enum SettingsStateActionTypes {
  LOAD = '[SettingsState] load settings',
  LOADED = '[SettingsState] settings loaded',
  HTTP_ERROR = '[SettingsState] http error',
}

export class SettingsStateLoad implements Action {
  readonly type = SettingsStateActionTypes.LOAD;
}

export class SettingsStateLoaded implements Action {
  readonly type = SettingsStateActionTypes.LOADED;
  public constructor(public payload: Settings) {}
}

export class SettingsStateHttpError implements Action {
  readonly type = SettingsStateActionTypes.HTTP_ERROR;
  public constructor(public payload: HttpErrorResponse) {}
}

export type SettingsStateActionsUnion =
  | SettingsStateLoad
  | SettingsStateLoaded
  | SettingsStateHttpError;
