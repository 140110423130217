import type { AfterViewInit, OnInit } from '@angular/core';
import {
  Component,
  ElementRef,
  Inject,
  Input,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { InnogyComponentRendering } from '@core/jss-models';
import { getFieldValue } from '@core/jss-utils';
import { WINDOW } from '@innogy/utils-dom';
import type { ImageField } from '@sitecore-jss/sitecore-jss-angular';
import type { Item } from '@sitecore-jss/sitecore-jss/layout';

@Component({
  selector: 'wl-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.ew.scss'],
})
export class CarouselComponent implements OnInit, AfterViewInit {
  @Input()
  rendering?: InnogyComponentRendering;

  @ViewChild('slider')
  private readonly _slider?: ElementRef;
  public slider?: HTMLDivElement;

  @ViewChildren('slide')
  private readonly _slides?: QueryList<ElementRef>;
  public slides: HTMLDivElement[] = [];

  public images: Array<ImageField | undefined> = [];
  public currentSlideIndex = 0;

  constructor(@Inject(WINDOW) private readonly window: Window) {}

  ngOnInit() {
    if (!this.rendering || !this.rendering.fields) {
      return;
    }

    const items = this.rendering.fields['items'] as Item[];
    this.images = items.map((item) => getFieldValue(item.fields, 'image'));
  }

  ngAfterViewInit() {
    if (!this._slider || !this._slides) {
      return;
    }

    this.slider = this._slider.nativeElement;
    this.slides = this._slides.map((slide) => slide.nativeElement);
  }

  onScroll() {
    const scrollPosition = this.slider?.scrollLeft ?? 0;
    const activeIndex = this.slides.findIndex((slide) => {
      const slideMiddle = slide.offsetLeft + slide.clientWidth / 2;

      return slideMiddle > scrollPosition;
    });

    this.currentSlideIndex = activeIndex;
  }

  /**
   * Animate the scroll to the next slide. This fucntion is needed since Safari does not support smooth scrolling :(.
   * If there will be ever a future where Safari supports smooth scrolling this complete function can be remove and replaced
   * with an scrollLeft = position.
   *
   * @param position The y-position to scroll to
   * @param duration The duration the scroll animation should take
   */
  /* istanbul ignore next */
  scrollTo(position: number, duration = 300) {
    const element = this.slider;
    const startX = element?.scrollLeft ?? 0;
    const diff = position - startX;
    let start: number;

    const step = (timestamp: number) => {
      if (!start) {
        start = timestamp;
      }

      // Elapsed milliseconds since start of scrolling.
      const time = timestamp - start;
      // Get percent of completion in range [0, 1].
      const percent = Math.min(time / duration, 1);

      element?.scrollTo(startX + diff * percent, 0);

      // Proceed with animation as long as we wanted it to.
      if (time < duration) {
        this.window.requestAnimationFrame(step);
      }
    };

    this.window.requestAnimationFrame(step);
  }

  hasNextSlide() {
    return this.currentSlideIndex < this.images.length - 1;
  }

  hasPreviousSlide() {
    return this.currentSlideIndex > 0;
  }

  goToSlide(index: number) {
    if (!this.slider) {
      return;
    }

    const isFirstSlide = index === 0;
    const isLastSlide = index === this.slides.length - 1;
    const targetSlide = this.slides[index];
    const widthDifference = this.slider.clientWidth - targetSlide.clientWidth;
    let offset = targetSlide.offsetLeft;

    if (!isFirstSlide && !isLastSlide) {
      offset = offset - widthDifference / 2;
    }

    if (isLastSlide) {
      offset = offset - widthDifference;
    }

    this.scrollTo(offset);

    // Wait for the animation to finish
    setTimeout(() => {
      this.currentSlideIndex = index;
    }, 300);
  }

  nextSlide() {
    if (!this.slider || !this.hasNextSlide()) {
      return;
    }

    this.goToSlide(this.currentSlideIndex + 1);
  }

  previousSlide() {
    if (!this.slider || !this.hasPreviousSlide()) {
      return;
    }

    this.goToSlide(this.currentSlideIndex - 1);
  }
}
