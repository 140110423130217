import {
  getCustomer,
  getCustomerClear,
  getCustomerError,
  getCustomerSuccess,
} from '@essent/customer';
import { createStateStatusReducer } from '@innogy/utils-state';
import type { Action } from '@ngrx/store';

export const { reducer, initialState } = createStateStatusReducer({
  updateAction: getCustomer,
  clearAction: getCustomerClear,
  errorAction: getCustomerError,
  successAction: getCustomerSuccess,
});

export type State = typeof initialState;

export function customerEntityReducer(
  state: State = initialState,
  action: Action
): State {
  return reducer(state, action);
}
