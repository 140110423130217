import type { ToolTrackingConfig } from '@innogy/core/analytics';
import {
  createToolStepConfig,
  defaultCommerceMapperContext,
  E2E_TRACKING_ID_KEY,
  parseToolConfig,
  ProductCategorie,
  ProductType,
} from '@innogy/core/analytics';
import type { ProgressiveFormFormState } from '@innogy/shared/progressive-form/sitecore/models';
import { analyticsPayloadFromGenericFormControls } from '@innogy/sitecore-forms/store';
import type { FormGroupControls } from 'ngrx-forms';

import { selectStoreFunnelSettings } from '../store-funnel';
import { StoreProductTrackingConfig } from '../store-products';

interface StoreOrderFormToolTrackingConfigInput {
  toolName: string;
  formControls: FormGroupControls<ProgressiveFormFormState>;
  stepCount: number;
}

const storeOrderFormToolTrackingConfig = ({
  formControls,
  stepCount,
  toolName,
}: StoreOrderFormToolTrackingConfigInput): ToolTrackingConfig<''> => ({
  toolName,
  steps: [],
  toolComplete: createToolStepConfig(
    selectStoreFunnelSettings,
    ({
      trackingId,
      selectedProducts,
      productCategory = ProductCategorie.Unknown,
      productType = ProductType.Unknown,
    }) => ({
      step: stepCount,
      payload: {
        ...analyticsPayloadFromGenericFormControls(formControls),
        products: selectedProducts.map((product) =>
          StoreProductTrackingConfig.mapToCommerce(
            product,
            defaultCommerceMapperContext,
            productType,
            productCategory
          )
        ),
        [E2E_TRACKING_ID_KEY]: trackingId,
      },
    })
  ),
});

export const parsedStoreOrderFormToolTrackingConfig = (
  input: StoreOrderFormToolTrackingConfigInput
) => parseToolConfig(storeOrderFormToolTrackingConfig(input));
