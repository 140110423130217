import { createAction, props } from '@ngrx/store';
import type { EstimatedConsumption } from '@essent/estimate-consumption';

import type {
  SupplyType,
  UsageQuestionnaireFormValues,
} from './usage-questionnaire.reducer';

const PREFIX = `[Usage Questionnaire]`;

export const enableSupplyTypeQuestionAction = createAction(
  `${PREFIX} Enable supply type question`,
  props<{ formId: string }>()
);

export const submitUsageQuestionnaireAction = createAction(
  `${PREFIX} Submit usage questionnaire`,
  props<{ payload: Partial<UsageQuestionnaireFormValues> }>()
);

export const expectedConsumptionDefinedAction = createAction(
  `${PREFIX} Expected consumption`,
  props<{
    formId: string;
    expectedConsumption: EstimatedConsumption;
    supplyType?: SupplyType;
  }>()
);

export const usageQuestionnaireCloseModalAction = createAction(
  `${PREFIX} Close modal`
);
