import { isNotNullish } from '@innogy/utils-rxjs';
import { createSelector } from '@ngrx/store';

import { getIsDeliveryStartCompleted } from '../onboarding';
import { CustomerStatus } from './customer-status.enum';

export const getCustomerStatus = createSelector(
  getIsDeliveryStartCompleted,
  (isDeliveryStartCompleted) => {
    if (isNotNullish(isDeliveryStartCompleted) && !isDeliveryStartCompleted) {
      return CustomerStatus.Cooldown;
    }

    return CustomerStatus.RegularCustomer;
  }
);
