import { isDateInRange, validateSequentialIf } from '@innogy/utils-deprecated';
import type { Boxed, FormGroupState } from 'ngrx-forms';
import { updateGroup, validate } from 'ngrx-forms';
import { required, requiredTrue, pattern } from 'ngrx-forms/validation';
import type { OrderInswitchInhouseOptionDropdownItem } from '@innogy/become-a-customer/shared/interfaces';

import type { OrderAddressFormValues } from './order-address.model';
import {
  getStartDateConfirmationRequired,
  startDateRange,
} from './inswitch-inhouse.helpers';

export const validateInswitchInhouse = <T extends OrderAddressFormValues>({
  controls,
}: FormGroupState<T>) => {
  const confirmInhouseRequired = getStartDateConfirmationRequired(controls);
  const startDateRestrictions = (
    (controls?.inswitchInhouse as any)
      ?.value as unknown as Boxed<OrderInswitchInhouseOptionDropdownItem>
  )?.value?.startDateRestrictions;

  return updateGroup<OrderAddressFormValues>({
    inswitchInhouse: validate(required),
    selectedStartDate: validateSequentialIf(!!startDateRestrictions)(
      required,
      pattern(/^\d{4}-\d{2}-\d{2}$/),
      isDateInRange(
        startDateRange(startDateRestrictions).start,
        startDateRange(startDateRestrictions).end,
        true
      )
    ),
    confirmInhouseTerm: validateSequentialIf(confirmInhouseRequired)(
      required,
      requiredTrue
    ),
  });
};
