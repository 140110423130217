import { HttpClient } from '@angular/common/http';
import {
  Inject,
  Injectable,
  makeStateKey,
  Optional,
  TransferState,
} from '@angular/core';
import {
  ClientEnvironmentConfig,
  ENVIRONMENT_CONFIG_TRANSFER_STATE,
} from '@core/config-models';
import { isNotNullish } from '@innogy/utils-rxjs';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { filter, first } from 'rxjs/operators';

export const configStateKey = makeStateKey<ClientEnvironmentConfig>('config');
/**
 * The {@link ConfigLoaderService} is used to either retrieve the {@link EnvironmentConfig} using
 * the {@link TransferState} or make a http call using {@link HttpClient} to retrieve the configuration.
 *
 * It will then set the data on this class for it to be used by the rest of the application.
 *
 * @class ConfigLoaderService
 */
@Injectable()
export class ConfigLoaderService {
  /**
   * DO NOT USE THIS WITHOUT PERMISSION FROM OTHER DEVS.
   * @deprecated THIS IS ONLY MEANT FOR THE URL MATCHER: https://github.com/angular/angular/issues/17145. DO NOT USE THIS.
   */
  public static GLOBAL_CONFIG: ClientEnvironmentConfig;
  private readonly _environmentConfig$: BehaviorSubject<
    ClientEnvironmentConfig | undefined
  > = new BehaviorSubject<ClientEnvironmentConfig | undefined>(undefined);

  constructor(
    private readonly httpClient: HttpClient,
    private readonly transferState: TransferState,
    @Optional()
    @Inject(ENVIRONMENT_CONFIG_TRANSFER_STATE)
    private readonly environmentConfig: ClientEnvironmentConfig,
    @Optional() @Inject('DEPLOY_PATH') private readonly deployPath: string = ''
  ) {}

  /**
   * @deprecated Use the async method getConfigAsync instead to ensure it always exists.
   * Gets the {@link ClientEnvironmentConfig}.
   */
  public getConfig(): ClientEnvironmentConfig {
    if (this._environmentConfig$.value == undefined) {
      throw new Error(
        'Config not loaded, CONFIG MUST BE LOADED DURING BOOTSTRAP!'
      );
    }
    return this._environmentConfig$.value;
  }

  /**
   * Async gets the {@link ClientEnvironmentConfig}, waiting for it to be loaded.
   */
  public getConfigAsync(): Promise<ClientEnvironmentConfig> {
    return firstValueFrom(
      this._environmentConfig$.pipe(filter(isNotNullish), first())
    );
  }

  /**
   * Sets the configuration to be used application-wide.
   * @param config The confiugration to apply that will be used application-wide.
   */
  private setConfig(config: ClientEnvironmentConfig | null) {
    if (!config) {
      return;
    }
    ConfigLoaderService.GLOBAL_CONFIG = config;
    this._environmentConfig$.next(config);
  }

  /**
   * Retrieves the configuration either from the {@link TransferState} or makes a http call to retrieve it.
   *
   * If the {@link TransferState} does not have the necessary data, only then will a http call be
   * made. Note: the {@link TransferState} is set via the {@link ConfigLoaderServerService}.
   *
   * @see ConfigLoaderServerService
   * @returns {Promise<void>} That will be used to wait for the config to be loaded when doing an http call.
   * @memberof ConfigLoaderService
   */
  retrieveConfiguration(): Promise<ClientEnvironmentConfig | null> {
    // If config is injected
    if (this.environmentConfig != null) {
      this.transferState.set(configStateKey, this.environmentConfig);
      this.setConfig(this.environmentConfig);

      return Promise.resolve(this.environmentConfig);
    }

    /*
     * Check whether the server has set the config for us to use.
     */
    const hasServerConfig = this.transferState.hasKey(configStateKey);
    if (hasServerConfig) {
      const config = this.transferState.get<ClientEnvironmentConfig | null>(
        configStateKey,
        null
      );
      // This is not really necessary since this function only gets run once on app initialization (see: config.module.ts).
      // For the purpose of preventing devs mistaking the need for this piece of code, this has been added.
      this.transferState.remove(configStateKey);
      this.setConfig(config);
      return Promise.resolve(config);
    }

    /*
     * No server configuration defined, so we're going to try and retrieve it using a http call.
     * If this is not defined, the app will not do anything at all.
     */
    return firstValueFrom(
      this.httpClient.get<ClientEnvironmentConfig>(
        this.deployPath + '/environment.json'
      )
    ).then((config) => {
      this.setConfig(config);
      return config;
    });
  }
}
