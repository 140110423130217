import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { provideBootstrapEffects } from '@innogy/utils-state';

import { MetaEffects } from './meta.effects';

@NgModule({
  imports: [CommonModule],
  providers: [
    /*
     * We need this to prevent the effects from initializing before the APP_INITIALIZER of
     * the ConfigModule.
     * See: https://github.com/ngrx/platform/issues/931
     */
    provideBootstrapEffects([MetaEffects]),
  ],
})
export class CoreJssSeoModule {}
