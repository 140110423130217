<div class="d-flex" wlAutomationId="filter-button">
  <button
    *ngFor="let entry of entries; trackBy: trackById"
    (click)="clicked(entry)"
    class="filter-button"
    [ngClass]="{
      'filter-button--selected': selected?.id === entry.id,
      'flex-grow-1': styleIsStretched,
    }"
    [wlAutomationId]="'filter-button-' + entry.id"
  >
    <ng-container *ngIf="entry.icon; else text">
      <span class="d-flex justify-content-center">
        <fa-icon [icon]="entry.icon" class="me-0 me-md-1"></fa-icon>
        <span class="d-none d-xl-block d-md-block d-lg-none">{{
          entry.text
        }}</span>
      </span>
    </ng-container>

    <ng-template #text>
      {{ entry.text }}
    </ng-template>
  </button>
</div>
