import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ClearAsyncErrorAction, UnfocusAction } from 'ngrx-forms';
import { filter, mergeMap } from 'rxjs/operators';

import { ibanFormControlId } from './order-payment.reducer';

@Injectable()
export class OrderPaymentEffects {
  constructor(private readonly actions$: Actions) {}

  /**
   * On unfocus of the payment details, clear the validation.
   */
  public readonly onUnfocusIbanFormControl$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UnfocusAction>(UnfocusAction.TYPE),
      filter((action) => action.controlId === ibanFormControlId),
      mergeMap(() => {
        return [
          new ClearAsyncErrorAction(ibanFormControlId, 'ibanBlacklisted'),
        ];
      })
    )
  );
}
