import { getRouterSelectors } from '@ngrx/router-store';
import { createSelector, type MemoizedSelector } from '@ngrx/store';

import type { State } from './app-state';

export const routerSelectors = getRouterSelectors<State>(
  (state) => state.router
);

/**
 * Only use the first queryparameter for backwards compatibility
 */
export const selectQueryParam: (
  param: string
) => MemoizedSelector<State, string | undefined> = (param: string) =>
  createSelector(routerSelectors.selectQueryParam(param), (item) =>
    Array.isArray(item) ? item[0] : item
  );
