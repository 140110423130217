import { createSelector } from '@ngrx/store';
import { Status } from '@essent/common';

import {
  sustainabilityCreateLeadResultId,
  sustainabilityFormConfigId,
  sustainabilityPostcodeValidationId,
} from './sustainability.reducers';
import { eplusSustainabilityFeature } from './sustainability.state';
import { sustainabilityTimeslotsId } from './get-timeslots.reducers';

export const selectSustainabilityCreateLeadResult = createSelector(
  eplusSustainabilityFeature,
  (state) => state[sustainabilityCreateLeadResultId]
);

export const selectSustainabilityPostcodeValidation = createSelector(
  eplusSustainabilityFeature,
  (state) => state[sustainabilityPostcodeValidationId]
);

export const selectSustainabilityFormConfig = createSelector(
  eplusSustainabilityFeature,
  (state) => state[sustainabilityFormConfigId]
);

export const selectSustainabilityTimeslots = createSelector(
  eplusSustainabilityFeature,
  (state) => state[sustainabilityTimeslotsId]
);

export const selectSustainabilityTimeslotsApiState = createSelector(
  selectSustainabilityTimeslots,
  (state) => state.apiState
);

export const selectSustainabilityTimeslotsDay = createSelector(
  selectSustainabilityTimeslots,
  (state) => state.selectedDay
);

export const selectSustainabilityTimeslotDays = createSelector(
  selectSustainabilityTimeslotsApiState,
  (state) =>
    (state.status === Status.SUCCESS
      ? state.data?.dateslots.map((o) => o.date)
      : null) || ['']
);

export const selectSustainabilityTimeslotTimes = createSelector(
  selectSustainabilityTimeslotsApiState,
  selectSustainabilityTimeslotsDay,
  (state, day) => {
    const defaultResult = [{ id: '', start: '', end: '' }];
    if (state.status !== Status.SUCCESS) {
      return defaultResult;
    }
    const dateslot = state.data
      ? state.data.dateslots.find((slot) => slot.date === day)
      : null;

    return dateslot ? dateslot.timeslots : defaultResult;
  }
);
