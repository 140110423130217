import { inject, Injectable } from '@angular/core';
import { Angulartics2 } from 'angulartics2';

import {
  CONSENT_LEVELS,
  CONSENT_REASON,
  type CONSENT_REASON_VALUES,
} from './consent.service';

const IMPRESSION_EVENT_NAME = 'cookiestatement-impression';
const CLICK_EVENT_NAME = 'cookiestatement-click';

@Injectable({
  providedIn: 'root',
})
export class ConsentAnalyticsService {
  readonly #angulartics = inject(Angulartics2);

  /**
   * Dispatches an event to the analytics layer signalling that the cookie statement is visible.
   */
  public sendImpressionBeacon() {
    this.#angulartics.eventTrack.next({
      action: IMPRESSION_EVENT_NAME,
      properties: {},
    });
  }

  /**
   * Dispatches an event to the analytics layer signalling that cookie settings have been submitted.
   * @param allowTrackingCookies whether or not tracking cookies are allowed - adjusts the payload going to the analytics layer.
   */
  public sendSubmissionBeacon(allowTrackingCookies: CONSENT_REASON_VALUES) {
    const consentLevel =
      allowTrackingCookies === CONSENT_REASON.ACCEPT
        ? CONSENT_LEVELS.TRACKING
        : CONSENT_LEVELS.DEFAULT;

    this.#angulartics.eventTrack.next({
      action: CLICK_EVENT_NAME,
      properties: {
        level: consentLevel,
      },
    });
  }
}
