import { Component, Input } from '@angular/core';
import { Offer } from '@innogy/become-a-customer/shared/interfaces';

import { CommodityOfferLabels } from '../../../commodity-offers.model';

@Component({
  selector: 'wl-commodity-offer-budgetbill-proposal',
  templateUrl: './commodity-offer-budgetbill-proposal.component.html',
  styleUrls: [
    './commodity-offer-budgetbill-proposal.component.ed.scss',
    './commodity-offer-budgetbill-proposal.component.essent.scss',
  ],
})
export class CommodityOfferBudgetbillProposalComponent {
  @Input()
  public offer?: Offer;
  @Input()
  public content?: CommodityOfferLabels;
}
