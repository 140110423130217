import { Component, Input } from '@angular/core';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss-angular';

import { TimerContent } from './timer-wrapper.model';

@Component({
  selector: 'wl-timer-wrapper',
  templateUrl: './timer-wrapper.component.html',
})
export class TimerWrapperComponent {
  @Input({ required: true })
  set rendering(value: ComponentRendering) {
    this.content = new TimerContent(value);
  }

  content!: TimerContent;
}
