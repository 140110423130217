import type { CollectionState } from '@innogy/utils-state';
import { getCollectionEntry } from '@innogy/utils-state';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import type { ProgressiveFormState } from './progressive-form.model';

/**
 * @deprecated - please use the `ProgressiveNGRXFormsModule` instead.
 * For information on how to migrate, see `forms/progressive ngrx forms` in the application docs.
 */
export const progressiveDisclosureKey = 'progressive-disclosure';

export const generateSelectors = () => ({
  getProgressiveStateForForm: (formId: string) =>
    getProgressiveStateForForm(formId),
  progressOnValidSubmit: (formId: string) => progressOnValidSubmit(formId),
  getCurrentActiveStepId: (formId: string) => getCurrentActiveStepId(formId),
  getFormSteps: (formId: string) => getFormSteps(formId),
  getSkippedFormSteps: (formId: string) => getSkippedFormSteps(formId),
  getIsEditingPreviouslySubmittedForm: (formId: string) =>
    getIsEditingPreviouslySubmittedForm(formId),
});

const getProgressiveState = () =>
  createFeatureSelector<CollectionState<ProgressiveFormState>>(
    progressiveDisclosureKey
  );

const progressOnValidSubmit = (formId: string) =>
  createSelector(
    getProgressiveStateForForm(formId),
    (formState) => formState?.progressOnValidSubmit
  );

const getCurrentActiveStepId = (formId: string) =>
  createSelector(
    getProgressiveStateForForm(formId),
    (state?: ProgressiveFormState) => state?.currentStepId
  );

const getIsEditingPreviouslySubmittedForm = (formId: string) =>
  createSelector(
    getProgressiveStateForForm(formId),
    (state?: ProgressiveFormState) => state?.isEditingPreviouslySubmittedForm
  );

const getFormSteps = (formId: string) =>
  createSelector(
    getProgressiveStateForForm(formId),
    (state?: ProgressiveFormState) => state?.formSteps
  );

const getSkippedFormSteps = (formId: string) =>
  createSelector(
    getProgressiveStateForForm(formId),
    (state?: ProgressiveFormState) => state?.skippedFormSteps
  );

/**
 * @deprecated - please use the `ProgressiveNGRXFormsModule` instead.
 * For information on how to migrate, see `forms/progressive ngrx forms` in the application docs.
 */
export const getProgressiveStateForForm = (formId: string) =>
  createSelector(getProgressiveState(), (collectionState) =>
    getCollectionEntry<ProgressiveFormState>(collectionState, formId)
  );
