import type { KeyValue } from 'ngrx-forms';

import type { NGRXProgressiveFormsActions } from '../actions';
import { MarkAsUnsubmittableAction } from '../actions';
import type { ProgressiveFormGroupState } from '../state';

export function markAsUnsubmittableReducer<TValue extends KeyValue>(
  state: ProgressiveFormGroupState<TValue>,
  action: NGRXProgressiveFormsActions
): ProgressiveFormGroupState<TValue> {
  if (action.type !== MarkAsUnsubmittableAction.TYPE) {
    return state;
  }

  if (action.stepId !== state.id) {
    return state;
  }

  if (state.isUnsubmittable) {
    return state;
  }

  return {
    ...state,
    isSubmittable: false,
    isUnsubmittable: true,
  };
}
