import type { AbstractControlState, FormState } from 'ngrx-forms';
import { setValue } from 'ngrx-forms';

import { transformDateInput } from '../../dates/date.helper';

export const formatDate = <T extends string | undefined>(
  control: AbstractControlState<T>
): FormState<T> => {
  const output = transformDateInput(control.value ?? '');
  return setValue(control, control.value ? (output as T) : control.value);
};
