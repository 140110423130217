import { createSelector } from '@ngrx/store';

import { eplusInsulationFeature } from '../insulation-form.state';
import {
  selectInsulationTrackingId,
  selectSelectedInsulationProducts,
} from '../insulation-funnel';
import { selectInsulationWizardAnalyticsPayload } from '../insulation-wizard';
import {
  insulationOrderAddressFormId,
  insulationOrderConfirmationFormId,
  insulationOrderFormKey,
  insulationOrderPersonalDetailsFormId,
} from './insulation-order-form.reducer';

const selectInsulationOrderFormState = createSelector(
  eplusInsulationFeature,
  (form) => form[insulationOrderFormKey]
);

export const selectInsulationOrderFormPersonalDetails = createSelector(
  selectInsulationOrderFormState,
  (form) => form[insulationOrderPersonalDetailsFormId].progressiveForm
);

export const selectInsulationOrderFormPersonalDetailsFormState = createSelector(
  selectInsulationOrderFormPersonalDetails,
  (progressiveForm) => progressiveForm.formState
);

export const selectInsulationOrderFormAddress = createSelector(
  selectInsulationOrderFormState,
  (form) => form[insulationOrderAddressFormId].progressiveForm
);

export const selectInsulationOrderFormAddressFormState = createSelector(
  selectInsulationOrderFormAddress,
  (progressiveForm) => progressiveForm.formState
);

export const selectInsulationOrderFormConfirmation = createSelector(
  selectInsulationOrderFormState,
  (form) => form[insulationOrderConfirmationFormId].progressiveForm
);

export const selectInsulationOrderFormConfirmationFormState = createSelector(
  selectInsulationOrderFormConfirmation,
  (progressiveForm) => progressiveForm.formState
);

export const selectInsulationOrderFormViewModel = createSelector(
  selectInsulationOrderFormAddress,
  selectInsulationOrderFormPersonalDetails,
  selectInsulationOrderFormConfirmation,
  (
    addressProgressiveFormState,
    personalDetailsProgressiveFormState,
    confirmationProgressiveFormState
  ) => ({
    addressProgressiveFormState,
    personalDetailsProgressiveFormState,
    confirmationProgressiveFormState,
  })
);

export const selectInsulationOrderFormAnalyticsPayload = createSelector(
  selectInsulationWizardAnalyticsPayload,
  selectSelectedInsulationProducts,
  selectInsulationTrackingId,
  (suitabilityCheckPayload, selectedProducts, trackingId) => ({
    ...suitabilityCheckPayload,
    products: selectedProducts,
    trackingId,
  })
);
