import { createSelector } from '@ngrx/store';

import { eplusHeatpumpsFeature } from '../heatpumps.state';
import {
  heatpumpsOrderAddressFormId,
  heatpumpsOrderConfirmationFormId,
  heatpumpsOrderFormKey,
  heatpumpsOrderPersonalDetailsFormId,
} from './heatpumps-order-form.reducer';

const selectHeatpumpsOrderFormState = createSelector(
  eplusHeatpumpsFeature,
  (form) => form[heatpumpsOrderFormKey]
);

export const selectHeatpumpsOrderFormPersonalDetails = createSelector(
  selectHeatpumpsOrderFormState,
  (form) => form[heatpumpsOrderPersonalDetailsFormId].progressiveForm
);

export const selectHeatpumpsOrderFormAddress = createSelector(
  selectHeatpumpsOrderFormState,
  (form) => form[heatpumpsOrderAddressFormId].progressiveForm
);

export const selectHeatpumpsOrderFormConfirmation = createSelector(
  selectHeatpumpsOrderFormState,
  (form) => form[heatpumpsOrderConfirmationFormId].progressiveForm
);

export const selectHeatpumpsOrderFormViewModel = createSelector(
  selectHeatpumpsOrderFormPersonalDetails,
  selectHeatpumpsOrderFormAddress,
  selectHeatpumpsOrderFormConfirmation,
  (
    personalDetailsProgressiveFormState,
    addressProgressiveFormState,
    confirmationProgressiveFormState
  ) => ({
    personalDetailsProgressiveFormState,
    addressProgressiveFormState,
    confirmationProgressiveFormState,
  })
);

export const selectHeatpumpsOrderFormCheckboxMapping = createSelector(
  selectHeatpumpsOrderFormState,
  (state) => state.checkboxMapping
);
