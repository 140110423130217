import type { ComponentNameAndModule } from '@sitecore-jss/sitecore-jss-angular';

const loadChildren = () =>
  import('./lib/chatbot-iadvize-lazy.module').then(
    (mod) => mod.ChatbotIadvizeLazyModule
  );

export const chatbotLazyImports: ComponentNameAndModule[] = [
  {
    path: 'ChatbotIAdvize',
    loadChildren,
  },
];
