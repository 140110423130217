import type { OnInit } from '@angular/core';
import { Component, Inject, Input } from '@angular/core';
import { Offer } from '@innogy/become-a-customer/shared/interfaces';
import { EnergyType } from '@essent/new-customer';
import {
  getMGMValue,
  getYearlyCostsLabel,
} from '@innogy/become-a-customer/shared';
import { ENVIRONMENT_CONFIG } from '@core/config-angular';
import type { EnvConfigBrand } from '@core/config-models';
import { EnvironmentConfig } from '@core/config-models';

import { CommodityOfferLabels } from '../../../commodity-offers.model';

@Component({
  selector: 'ed-commodity-product-breakdown',
  templateUrl: './commodity-product-breakdown.component.html',
  styleUrls: ['./commodity-product-breakdown.component.ed.scss'],
})
export class CommodityOfferEDProductBreakdownComponent implements OnInit {
  @Input() offer?: Offer;
  @Input() content?: CommodityOfferLabels;
  @Input() showMemberGetMember = false;
  public energyType = EnergyType;

  brand?: EnvConfigBrand;

  constructor(
    @Inject(ENVIRONMENT_CONFIG) private readonly envConfig: EnvironmentConfig
  ) {}

  ngOnInit() {
    this.brand = this.envConfig.brand;
  }

  getMGMValue = (offer: Offer) =>
    this.brand ? getMGMValue(offer, this.brand).toString() : '';

  yearlyCostsLabel(offer: Offer) {
    return getYearlyCostsLabel(
      this.content?.yearlyCostsTotalDynamicWithIncentiveLabel ?? '',
      this.content?.yearlyCostsTotalDynamicWithoutIncentiveLabel ?? '',
      this.content?.yearlyCostsTotalWithIncentiveLabel ?? '',
      this.content?.yearlyCostsTotalWithoutIncentiveLabel ?? '',
      this.content?.yearlyCostsTotalMultiYearWithIncentiveLabel ?? '',
      this.content?.yearlyCostsTotalMultiYearWithoutIncentiveLabel ?? '',
      offer
    );
  }
}
