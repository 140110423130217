import { createSelector } from '@ngrx/store';

import { selectGetSolarPanelsNewCustomerAddressCheckData } from '../solar-panels-api';
import { selectSelectedSolarPanelsProduct } from '../solar-panels-funnel';
import { eplusSolarPanelsFeature } from '../solar-panels-form.state';
import { solarPanelsResultOverviewKey } from './solar-panels-result-overview.reducer';

const selectSolarPanelsResultOverview = createSelector(
  eplusSolarPanelsFeature,
  (state) => state[solarPanelsResultOverviewKey]
);

export const selectSolarPanelsResultOverviewShowLoader = createSelector(
  selectSolarPanelsResultOverview,
  (state) => state.showLoader
);

export const selectSolarPanelsResultOverviewViewModal = createSelector(
  selectSelectedSolarPanelsProduct,
  selectGetSolarPanelsNewCustomerAddressCheckData,
  selectSolarPanelsResultOverviewShowLoader,
  (product, address, showLoader) => ({
    product,
    address: address?.address,
    building: address?.building,
    showLoader,
  })
);
