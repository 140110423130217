import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import type { ImageSettings } from '@innogy/common-ui/utility-components';
import { getImageSettingsFromRendering } from '@innogy/common-ui/utility-components';
import { InnogyComponentRendering } from '@core/jss-models';
import { getTreeListValues } from '@core/jss-utils';

import { ListConfig } from '../list.model';

@Component({
  selector: 'wl-list-variant-base',
  template: '',
})
export abstract class ListVariantBaseComponent implements OnInit {
  @Input() rendering?: InnogyComponentRendering;
  @Input() listConfig?: ListConfig;

  imageSettings?: ImageSettings;
  listItems = [];

  ngOnInit() {
    if (this.rendering) {
      this.imageSettings = getImageSettingsFromRendering(this.rendering);
      this.listItems = getTreeListValues(this.rendering?.fields, 'Items');
    }
  }
}
