import { inject, Injectable } from '@angular/core';

import type { PageInfo } from './page-info.model';
import type {
  XDMAnalyticsSubSchema,
  WebXDMSchema,
  XDMWebSubSchema,
} from './xdm.model';
import { PageInfoService } from './page-info.service';

type PageDetails = {
  url: PageInfo['url'];
  name: PageInfo['name'];
  consent: PageInfo['consent'];
};
@Injectable({
  providedIn: 'root',
})
export class XdmBuilderService {
  readonly #pageInfoService = inject(PageInfoService);

  /**
   * Enhances the provided XDM schema with data derived from the Page Info object.
   * This data is used to enrich the experience schema with custom analytics dimensions used by Essent.
   * @param builderArgs - Object containing a base schema to enrich and a pageInfo object.
   * @returns XDM schema enhanced with custom analytics dimensions.
   */
  enhanceSchemaWithPageInfo(
    baseSchema: Partial<WebXDMSchema> = {}
  ): WebXDMSchema {
    const pageDetails = this.#getPageDetails();

    return {
      ...baseSchema,
      _experience: {
        ...baseSchema._experience,
        analytics: this.#buildAnalyticsSchema(pageDetails),
      },
      web: this.#buildWebSchema(pageDetails),
    };
  }

  #getPageDetails(): PageDetails {
    return {
      url: this.#pageInfoService.getUrlWithoutParams(),
      consent: this.#pageInfoService.getCookieConsent(),
      name: this.#pageInfoService.getPageName(),
    };
  }

  #buildAnalyticsSchema(pageDetails: PageDetails): XDMAnalyticsSubSchema {
    return {
      customDimensions: {
        eVars: {
          eVar1: pageDetails.consent,
          eVar2: pageDetails.name,
          eVar6: pageDetails.url,
        },
        props: {
          prop6: pageDetails.url,
        },
      },
    };
  }

  #buildWebSchema(pageDetails: PageDetails): XDMWebSubSchema {
    return {
      webInteraction: {
        URL: pageDetails.url,
        name: pageDetails.name,
      },
      webPageDetails: {
        URL: pageDetails.url,
        name: pageDetails.name,
      },
    };
  }
}
