import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';

import { addChoices, removeChoices } from './how-to-questionnaire.actions';

export interface HowToQuestionnaireChoices {
  [optionName: string]: 'ja' | 'nee';
}

export interface HowToQuestionnaireChoicesPerStep {
  [step: number]: HowToQuestionnaireChoices;
}

export interface HowToQuestionnaireState {
  choices: HowToQuestionnaireChoicesPerStep;
}

export const initialState: HowToQuestionnaireState = {
  choices: {},
};

export const reducer = createReducer(
  initialState,
  on(addChoices, (state, { choices }) => ({
    ...state,
    choices: {
      ...state.choices,
      ...choices,
    },
  })),
  on(removeChoices, (state, { step }) => {
    const choices = { ...state.choices };
    delete choices[step];
    return {
      ...state,
      choices,
    };
  })
);

export function howToQuestionnaireReducer(
  state: HowToQuestionnaireState = initialState,
  action: Action
): HowToQuestionnaireState {
  return reducer(state, action);
}
