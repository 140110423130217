import { createNamedApi } from '@essent/common';

import { PostHeatpumpsLeadService } from './post-heatpumps-lead.service';

export const postHeatpumpsLeadApi = createNamedApi(
  '[PostHeatpumpsLeadState] Post Heatpumps Lead',
  PostHeatpumpsLeadService
);

export const {
  postHeatpumpsLead,
  postHeatpumpsLeadSuccess,
  postHeatpumpsLeadClear,
  postHeatpumpsLeadError,
  postHeatpumpsLeadActions,
} = postHeatpumpsLeadApi;
