export type GraphqlJSSField = {
  name: string;
  jss: ScValue | ScNestedItem | null;
};

export type ScValue = {
  value: any;
};

export type ScNestedItem = {
  id: string;
  url: string;
  fields?: {
    [key: string]: any;
  };
};

export interface ScImageValue {
  src: string;
  alt: string;
}

export type ScImage = {
  value: ScImageValue;
};

export type ScMultiList = {
  name: string;
  targetItems: {
    fields: GraphqlJSSField[];
  }[];
};

export type ScNestedMultiList = ScNestedItem[];

export function isScValue(item: ScValue | ScNestedItem): item is ScValue {
  return (item as ScValue).value !== undefined;
}

export function isScNestedItem(item: any): item is ScNestedItem {
  return (
    (item as ScNestedItem).fields !== undefined &&
    (item as ScNestedItem).fields instanceof Object
  );
}

export function isScMultiListField(item: any): item is ScMultiList {
  return (
    (item as ScMultiList).targetItems !== undefined &&
    (item as ScMultiList).targetItems instanceof Array
  );
}

export function isScNestedMultiListField(item: any): item is ScNestedMultiList {
  return item instanceof Array;
}
