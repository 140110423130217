import { Status } from '@essent/common';
import type { StateStatus } from '@innogy/utils-state';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-angular';

import type { JssState } from '../jss-state.model';
import type {
  JssStateActionsUnion,
  JssStatePlaceholderLoaded,
  JssStatePlaceholderLoading,
} from './jss-route.actions';
import { JssStateActionTypes } from './jss-route.actions';

export interface State extends StateStatus {
  jssState: JssState | null;
  experienceEditorActive: boolean;
  experienceEditorServerActive: boolean;
  placeholdersLoading: {
    [placeholderName: string]: number;
  };
  queryParameterHistory: {
    [name: string]: string;
  };
}

export const initialState: State = {
  status: Status.IDLE,
  jssState: null,
  experienceEditorActive: isEditorActive(),
  experienceEditorServerActive: false,
  placeholdersLoading: {},
  queryParameterHistory: {},
};

function onPlaceholderLoading(
  state: State,
  { payload }: JssStatePlaceholderLoading
) {
  if (!payload) {
    return state;
  }

  return {
    ...state,
    placeholdersLoading: {
      ...state.placeholdersLoading,
      [payload]: (state.placeholdersLoading[payload] || 0) + 1,
    },
  };
}

function onPlaceholderLoaded(
  state: State,
  { payload }: JssStatePlaceholderLoaded
) {
  if (!payload || !state.placeholdersLoading[payload]) {
    return state;
  }

  const copy = {
    ...state,
    placeholdersLoading: {
      ...state.placeholdersLoading,
    },
  };

  if (copy.placeholdersLoading[payload] === 1) {
    delete copy.placeholdersLoading[payload];
  } else {
    copy.placeholdersLoading[payload] -= 1;
  }

  return {
    ...copy,
  };
}

export function jssStateReducer(
  state: State = initialState,
  action: JssStateActionsUnion
): State {
  switch (action.type) {
    case JssStateActionTypes.UPDATE:
      return {
        ...state,
        status: Status.SUCCESS,
        jssState: action.payload,
      };
    case JssStateActionTypes.ERROR:
      return {
        ...state,
        status: Status.ERROR,
        error: action.payload,
        jssState: null,
      };
    case JssStateActionTypes.CHANGE_ROUTE:
      return {
        ...state,
        status: Status.PENDING,
      };
    case JssStateActionTypes.EXPERIENCE_EDITOR_ACTIVE:
      return {
        ...state,
        experienceEditorActive: action.payload,
      };
    case JssStateActionTypes.EXPERIENCE_EDITOR_SERVER_ACTIVE:
      return {
        ...state,
        experienceEditorServerActive: action.payload,
      };
    case JssStateActionTypes.PLACEHOLDER_LOADING:
      return onPlaceholderLoading(state, action);
    case JssStateActionTypes.PLACEHOLDER_LOADED:
      return onPlaceholderLoaded(state, action);
    case JssStateActionTypes.UPDATE_HISTORY:
      return {
        ...state,
        queryParameterHistory: {
          ...state.queryParameterHistory,
          ...action.payload,
        },
      };
    default:
      return state;
  }
}

export const getStatus = (jssState: State) => jssState.status;
export const getJssState = (jssState: State) => jssState.jssState;
