import { Component, EventEmitter, Input, Output } from '@angular/core';

import { CollapseDirection } from './collapse-direction.model';

@Component({
  selector: 'wl-off-canvas',
  templateUrl: './off-canvas.component.html',
  styleUrls: [
    './off-canvas.component.ed.scss',
    './off-canvas.component.essent.scss',
  ],
})
export class OffCanvasComponent {
  private openValue = false;

  @Output()
  public openChange = new EventEmitter<boolean>();

  @Output()
  public back = new EventEmitter<void>();

  get open() {
    return this.openValue;
  }

  @Input()
  set open(isOpen: boolean) {
    this.openValue = isOpen;
    this.openChange.emit(isOpen);
  }

  @Input()
  backgroundColor?: 'white' | 'light';

  @Input()
  public direction!: CollapseDirection;

  @Input()
  public icon!: string;

  @Input()
  public openButtonTitle?: string;

  @Input()
  public title?: string;

  @Input()
  public showToggleIcon?: boolean;

  @Input()
  public showTitleCollapsed?: boolean;

  @Input()
  public showBackButton?: boolean;

  public toggleOffCanvas() {
    this.open = !this.open;
  }
}
