import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'wl-partial-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.ed.scss', './card.component.essent.scss'],
})
export class CardComponent {
  @HostBinding('class.ui-card--shadow')
  @Input()
  shadow = true;

  // This is an attribute so dynamic values can be used for HostBinding value to apply styles
  @HostBinding('attr.ui-card-border-radius')
  @Input()
  borderRadius: 'top' | 'right' | 'bottom' | 'left' | 'none' | 'all' = 'all';

  @HostBinding('class.ui-card') cardClass = true;

  @Input() divider = false;
}
