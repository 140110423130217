import { Inject, Injectable } from '@angular/core';
import {
  calculateQuestionnaireOpenModalAction,
  getFunnelSettings,
  getUsageQuestionnaireFormState,
} from '@innogy/become-a-customer/state';
import {
  BAC_QUESTIONNAIRE_ID,
  enableSupplyTypeQuestionAction,
  usageQuestionnaireCloseModalAction,
  UsageQuestionnaireModalComponent,
} from '@innogy/common-ui/sales';
import { ENVIRONMENT_CONFIG } from '@core/config-angular';
import { EnvironmentConfig } from '@core/config-models';
import type { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { select, Store } from '@ngrx/store';
import { mergeMap, tap } from 'rxjs/operators';

import { UsageQuestionnaireSMEModalComponent } from '../usage-questionnaire-sme-modal.component';

@Injectable()
export class BacUsageQuestionnaireModalEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<any>,
    private readonly modalService: NgbModal,
    @Inject(ENVIRONMENT_CONFIG) readonly config: EnvironmentConfig
  ) {}
  private calculateModal?: NgbModalRef;
  private readonly funnelSettings$ = this.store$.pipe(
    select(getFunnelSettings)
  );

  /**
   * Effect that opens the Calculate Usage Wizard.
   * Uses the generic ModalService to spawn a modal.
   */
  public readonly openCalculateQuestionnaireModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(calculateQuestionnaireOpenModalAction),
      concatLatestFrom((_) => this.funnelSettings$),
      tap(([action, funnelSettings]) => {
        this.calculateModal = this.modalService.open(
          this.isSME
            ? UsageQuestionnaireSMEModalComponent
            : UsageQuestionnaireModalComponent,
          {
            centered: true,
            keyboard: true,
          }
        );

        this.calculateModal.componentInstance.gasHidden = action.gasHidden;

        if (!this.isSME) {
          this.calculateModal.componentInstance.formState$ = this.store$.select(
            getUsageQuestionnaireFormState
          );
        }
        this.calculateModal.componentInstance.rendering =
          funnelSettings.usageWizard;
      }),
      mergeMap(() => [
        enableSupplyTypeQuestionAction({ formId: BAC_QUESTIONNAIRE_ID }),
      ])
    )
  );

  /**
   * Effect that closes the Calculate Usage Wizard.
   * Closes the Modal Instance programmatically.
   */
  public readonly closeCalculateQuestionnaireModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(usageQuestionnaireCloseModalAction),
        tap((_) => {
          this.calculateModal?.componentInstance.closeModal();
        })
      ),
    { dispatch: false }
  );

  get isSME() {
    return this.config.segment === 'zakelijk';
  }
}
