import type { PriceGroup, PriceItem } from '@essent/new-customer';

type PriceItemLike<TAmountsLike> = Omit<
  PriceItem,
  'expectedMonthlyAmount' | 'expectedYearlyAmount'
> &
  TAmountsLike;

type PriceGroupLike<TAmountsLike> = Omit<
  PriceGroup,
  'prices' | 'totalPrice'
> & {
  prices: PriceItemLike<TAmountsLike>[];
  totalPrice: TAmountsLike;
};

export function castToNewCustomerPriceGroup<
  // eslint-disable-next-line @typescript-eslint/ban-types
  TAmountsLike extends {},
  TPriceGroupLike extends PriceGroupLike<TAmountsLike>,
>(
  inputPriceGroup: TPriceGroupLike,
  keysToMap: {
    toExpectedYearlyAmount: keyof TAmountsLike;
    toExpectedMonthlyAmount: keyof TAmountsLike;
  }
): PriceGroup {
  const { toExpectedMonthlyAmount, toExpectedYearlyAmount } = keysToMap;

  const totalPrice = { ...inputPriceGroup.totalPrice };
  delete totalPrice[toExpectedYearlyAmount];
  delete totalPrice[toExpectedMonthlyAmount];

  return {
    ...inputPriceGroup,
    totalPrice: {
      ...totalPrice,
      expectedYearlyAmount: Number(
        inputPriceGroup.totalPrice[toExpectedYearlyAmount]
      ),
      expectedMonthlyAmount: Number(
        inputPriceGroup.totalPrice[toExpectedMonthlyAmount]
      ),
    },
    prices: inputPriceGroup.prices.map((price) => {
      const clonedPrice = { ...price };
      delete clonedPrice[toExpectedYearlyAmount];
      delete clonedPrice[toExpectedMonthlyAmount];
      return {
        ...clonedPrice,
        expectedYearlyAmount: Number(price[toExpectedYearlyAmount]),
        expectedMonthlyAmount: Number(price[toExpectedMonthlyAmount]),
      };
    }),
  };
}
