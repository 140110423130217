import type { OnChanges, SimpleChanges } from '@angular/core';
import { Directive, Inject, Input, Optional } from '@angular/core';
import { ActionsSubject } from '@ngrx/store';
import type { KeyValue } from 'ngrx-forms';

import type { Actions } from '../actions';
import {
  MarkAsRestorationPointAction,
  MarkAsSubmittableAction,
  MarkAsVisitedAction,
} from '../actions';
import { ProgressiveFormGroupState } from '../state';

/**
 * used by applying `[progressiveFormStep]` to an element wrapping a progressive form.
 * This directive is mandatory if you want to set up your own usecase.
 * The directive takes care of correctly setting the form step state when the `isActive` flag changes (marking as submittable, visited and keeping track of restoration points).
 */
@Directive({
  selector:
    // eslint-disable-next-line @angular-eslint/directive-selector
    '[progressiveFormStep]',
})
/**
 * Directive used to tag a section as the main content for the progressive form.
 */
export class ProgressiveFormStepDirective<TStateValue extends KeyValue>
  implements OnChanges
{
  // private isInitialized = false;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('progressiveFormStep')
  state?: ProgressiveFormGroupState<TStateValue>;

  constructor(
    @Optional()
    @Inject(ActionsSubject)
    private readonly actionsSubject: ActionsSubject | null
  ) {}

  private updateViewIfActiveChanged(
    oldState: ProgressiveFormGroupState<TStateValue>,
    newState: ProgressiveFormGroupState<TStateValue>
  ) {
    if ((!oldState || !oldState.isActive) && newState.isActive) {
      this.dispatchAction(new MarkAsSubmittableAction(newState.id));

      if (newState.isUnvisited) {
        this.dispatchAction(new MarkAsVisitedAction(newState.id));
      }

      // If this card has not been submitted yet, the restoration point for the form.
      if (newState.isUnsubmitted) {
        this.dispatchAction(new MarkAsRestorationPointAction(newState.id));
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const oldState: ProgressiveFormGroupState<TStateValue> =
      changes['state']?.previousValue;
    const newState: ProgressiveFormGroupState<TStateValue> =
      changes['state']?.currentValue;

    this.updateViewIfActiveChanged(oldState, newState);
  }

  protected dispatchAction(action: Actions) {
    if (this.actionsSubject !== null) {
      this.actionsSubject.next(action);
    } else {
      throw new Error(
        'ActionsSubject must be present in order to dispatch actions!'
      );
    }
  }
}
