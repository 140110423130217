import type { EntityAdapter, EntityState } from '@ngrx/entity';
import { createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import type { FormStep } from '../progressive-form.model';
import { setFormConfig, setActiveFormStep } from '../progressive-form.actions';
import {
  collapseFormStepAction,
  finishCollapseStepAnimationAction,
} from './progressive-form-step.actions';

/**
 * @deprecated - please use the `ProgressiveNGRXFormsModule` instead.
 * For information on how to migrate, see `forms/progressive ngrx forms` in the application docs.
 */
export const formStepFeatureKey = 'progressive-form-steps';

/**
 * @deprecated - please use the `ProgressiveNGRXFormsModule` instead.
 * For information on how to migrate, see `forms/progressive ngrx forms` in the application docs.
 */
export type FormStepFeatureState = EntityState<FormStep>;

export const formStepAdapter: EntityAdapter<FormStep> =
  createEntityAdapter<FormStep>({});

const initialState: FormStepFeatureState = formStepAdapter.getInitialState();

/**
 * @deprecated - please use the `ProgressiveNGRXFormsModule` instead.
 * For information on how to migrate, see `forms/progressive ngrx forms` in the application docs.
 */
export const formStepReducer = createReducer(
  initialState,
  on(setFormConfig, (state, action) => {
    if (action.formStepsEntities) {
      return formStepAdapter.setMany(action.formStepsEntities, state);
    }
    return state;
  }),

  on(setActiveFormStep, (state, action) =>
    formStepAdapter.updateOne(
      {
        changes: {
          state: {
            expectedCollapsed: false,
            isAnimating: false,
          },
        },
        id: action.stepId,
      },
      state
    )
  ),
  on(collapseFormStepAction, (state, action) =>
    formStepAdapter.updateOne(
      {
        changes: {
          state: {
            expectedCollapsed: true,
            isAnimating: true,
          },
        },
        id: action.stepId,
      },
      state
    )
  ),
  on(finishCollapseStepAnimationAction, (state, action) =>
    formStepAdapter.updateOne(
      {
        changes: {
          state: {
            expectedCollapsed: action.collapsed,
            isAnimating: false,
          },
        },
        id: action.stepId,
      },
      state
    )
  )
);
