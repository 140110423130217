import type { State as SharedState } from '@innogy/utils-state';
import type { ActionReducerMap } from '@ngrx/store';
import { createFeatureSelector } from '@ngrx/store';

import type { JssStateActionsUnion } from './jss-route.actions';
import * as fromRoute from './state.reducer';

export interface StoreJssState {
  readonly route: fromRoute.State;
}

export interface State extends SharedState {
  readonly jssState: StoreJssState;
}

export const reducers: ActionReducerMap<StoreJssState, JssStateActionsUnion> = {
  route: fromRoute.jssStateReducer,
};

export const jssSelectorKey = 'jss';
export const getJssState = createFeatureSelector<StoreJssState>(jssSelectorKey);
