import { Component, Input } from '@angular/core';
@Component({
  selector: 'essent-commodity-offer-header',
  templateUrl: './commodity-offer-header.component.html',
  styleUrls: ['./commodity-offer-header.component.essent.scss'],
})
export class CommodityOfferEssentHeaderComponent {
  @Input() duration?: string;
  @Input() productTitle?: string;
  @Input() price?: number;
  @Input() priceDurationLabel?: string;
  @Input() incentive?: string;
  @Input() highlight?: string;
  @Input() addLabelSpacer = false;
  @Input() floatingIncentive = true;
  @Input({ required: true }) showPriceTag = true;
}
