import { Component, Input } from '@angular/core';
import type { TariffPeriod } from '@innogy/become-a-customer/shared/interfaces';
import { TariffSeasonDates } from '@innogy/become-a-customer/shared/interfaces';
import {
  TextField,
  type RichTextField,
} from '@sitecore-jss/sitecore-jss-angular';
@Component({
  selector: 'wl-aom-costs-per-unit-with-summer-winter',
  templateUrl: './aom-costs-per-unit-with-summer-winter.component.html',
  styleUrls: [
    './aom-costs-per-unit-with-summer-winter.component.essent.scss',
    './aom-costs-per-unit-with-summer-winter.component.ed.scss',
  ],
})
export class AomCostsPerUnitWithSummerWinterComponent {
  @Input() public tariffPeriods?: TariffPeriod[];
  @Input() public energyTitle?: TextField;

  @Input() public deliveryTitle?: TextField;
  @Input() public redeliveryTitle?: TextField;
  @Input() public redeliveryDescription?: RichTextField;

  @Input() public fixedDeliveryCostsLabel?: TextField;
  @Input() public fixedDeliveryCosts?: string;

  @Input() public fixedRedeliveryCostsLabel?: TextField;
  @Input() public fixedRedeliveryCosts?: string;
  @Input() public fixedRedeliveryCostsScale?: string;

  get getSummerDate() {
    return `Van ${TariffSeasonDates.SummerStart}<br />t/m ${TariffSeasonDates.SummerEnd}`;
  }

  get getWinterDate() {
    return `Van ${TariffSeasonDates.WinterStart}<br />t/m ${TariffSeasonDates.WinterEnd}`;
  }

  get showRedeliveryCosts() {
    return !!this.fixedRedeliveryCostsScale && !!this.fixedRedeliveryCostsLabel;
  }
}
