import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FaConfig,
  FaIconLibrary,
  FontAwesomeModule,
} from '@fortawesome/angular-fontawesome';

import * as essentIcons from './essent-icons';
import { createLibrary } from '../utils';

@NgModule({
  declarations: [],
  imports: [CommonModule, FontAwesomeModule],
})
export class EssentIconModule {
  constructor(faLibrary: FaIconLibrary, faConfig: FaConfig) {
    faConfig.defaultPrefix = 'far';
    faLibrary.addIcons(...createLibrary(essentIcons));
  }
}
