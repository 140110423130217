<ng-container *ngIf="showSticker">
  <div class="sticker-container mx-n2">
    <img
      *ngIf="offer?.incentiveImage; else imgrendering"
      [src]="offer?.incentiveImage"
      [alt]="offer?.incentiveValue"
      class="sticker-container--img"
    />
  </div>
</ng-container>

<ng-template #imgrendering>
  <ng-container *ngIf="rendering?.fields?.Sticker as sticker">
    <img
      *scImage="sticker"
      [alt]="offer?.incentiveValue"
      class="sticker-container--img"
    />
  </ng-container>
</ng-template>
