import { createAction, props } from '@ngrx/store';

import { BAC_ACTION_PREFIX } from '../constants';

export const setMGMSaleIdAction = createAction(
  `${BAC_ACTION_PREFIX} Set MemberGetMember sale id`,
  props<{ saleId: string }>()
);

export const loadMGMPingScriptAction = createAction(
  `${BAC_ACTION_PREFIX} Load MemberGetMember ping script`
);
