import { Gender } from '@essent/common';

export function mapGenderToSalutation(
  gender: Gender | undefined
): string | undefined {
  switch (gender) {
    case Gender.MALE:
      return 'Dhr.';
    case Gender.FEMALE:
      return 'Mevr.';
    case Gender.OTHER:
      return '';
    default:
      return undefined;
  }
}
