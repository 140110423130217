import { Injectable, Injector } from '@angular/core';
import { BaseGuard } from '@core/ngrx';
import { emobilityActions } from '@innogy/emobility-shared';
import { Store } from '@ngrx/store';
import type { GuardInput } from '@sitecore-jss/sitecore-jss-angular';
import { of, type Observable } from 'rxjs';
import { getFieldValue } from '@core/jss-utils';
import { ProductGroup } from '@integration/api-client/emobility';

import { selectIsLoadingProducts } from './lead-form.selectors';

@Injectable({
  providedIn: 'root',
})
export class ProductsGuard extends BaseGuard {
  protected isStateLoading(input: GuardInput): Observable<boolean> {
    const productsEnabled = getFieldValue(
      input.rendering,
      'chargePointConfigurationEnabled',
      false
    );

    return productsEnabled
      ? this.store$.select(selectIsLoadingProducts)
      : of(false);
  }

  protected getDispatchActions(input: GuardInput) {
    return emobilityActions.getEmobilityProductsV2.requestAction({
      payload: {
        productGroup: ProductGroup.CHARGING_STATION,
        verificationCode: input.activatedRoute.queryParams['verification-code'],
      },
    });
  }

  constructor(
    private readonly store$: Store<any>,
    protected override readonly injector: Injector
  ) {
    super(injector);
  }
}
