import { Component, Input, type OnInit } from '@angular/core';
import { isFuture } from 'date-fns';

import type { TimerLabels } from '../timer.model';
import { Image } from './timer-widget-wrapper/timer-widget-wrapper.model';

@Component({
  selector: 'wl-timer-widget',
  templateUrl: './timer-widget.component.html',
  styleUrls: [
    './timer-widget.component.ed.scss',
    './timer-widget.component.essent.scss',
  ],
})
export class TimerWidgetComponent implements OnInit {
  @Input({ required: true }) dateToCountDownTo: Date | undefined;
  @Input() timerLabels?: Partial<TimerLabels>;
  @Input() image?: Image;
  @Input() title?: string;
  @Input() text?: string;

  timerWidgetShown = false;

  hideTimerWidget = (): void => {
    this.timerWidgetShown = false;
  };

  ngOnInit(): void {
    if (this.dateToCountDownTo && isFuture(this.dateToCountDownTo)) {
      this.timerWidgetShown = true;
    }
  }
}
