import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { HowToQuestionnaireEffects } from './+state/how-to-questionnaire/how-to-questionnaire.effects';
import { commonUiServiceComponentsSelectorKey } from './common-ui-service-components-store.selector';
import { reducers } from './common-ui-service-components-store.state';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(commonUiServiceComponentsSelectorKey, reducers),
    EffectsModule.forFeature([HowToQuestionnaireEffects]),
  ],
})
export class CommonUiServiceComponentsStoreModule {}
