import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import type { NameFormValues } from '@innogy/common-ui/forms';
import { TrackToolStep } from '@innogy/core/analytics';
import type { InnogyComponentRendering } from '@core/jss-models';
import { ScNameFormInput } from '@innogy/sitecore-forms/models';
import type { ComponentFields } from '@sitecore-jss/sitecore-jss-angular';
import { FormGroupState } from 'ngrx-forms';

@Component({
  selector: 'wl-sc-name[formState][fields][trackStep]',
  templateUrl: './sc-name.component.html',
})
export class ScNameComponent implements OnInit {
  @Input() formState!: FormGroupState<NameFormValues>;
  @Input() fields!: ScNameFormInput;
  @Input() trackStep!: TrackToolStep;
  @Input() floatingLabels = false;

  rendering!: InnogyComponentRendering & { fields: ScNameFormInput };

  ngOnInit() {
    // We override the componentName because analytics needs to read the
    // toolname of the generic form and not of the name rendering
    this.rendering = {
      componentName: this.trackStep.toolName,
      fields: {
        ...this.fields,
        componentName: { value: this.trackStep.toolName },
      } as ComponentFields & ScNameFormInput,
    };
  }
}
