<div *ngIf="offer && content" class="commodity-offer-price-tag">
  <div class="commodity-offer-price-tag__title">
    <span class="fw-bold">{{ content.monthlyCostsLabel }}</span>
    <span *ngIf="showMonthlyCostsLabelDiscountInfix()"
      >&nbsp;{{ content.monthlyCostsLabelDiscountInfix }}</span
    >
    <span class="d-block">
      {{ content.monthlyCostsLabelSuffix }}
    </span>
  </div>
  <div class="commodity-offer-price-tag__monthly-amount">
    <span class="h1 mb-0">
      {{ offer.expectedMonthlyAmount | roundedCurrency }}
    </span>
    <span class="lh-sm">{{ content.priceDurationLabel }}</span>
  </div>

  <wl-commodity-offer-budgetbill-proposal
    *ngIf="showBudgetbillProposal"
    class="w-100"
    [offer]="offer"
    [content]="content"
  ></wl-commodity-offer-budgetbill-proposal>
</div>
