import type { PipeTransform } from '@angular/core';
import { Inject, Optional, Pipe } from '@angular/core';

@Pipe({
  name: 'deployUrl',
})
export class DeployUrlPipe implements PipeTransform {
  constructor(
    @Optional() @Inject('DEPLOY_PATH') private readonly deployPath?: string
  ) {}

  transform(location: string): string {
    if (!this.deployPath) {
      return location;
    }

    return this.normalizePath(`${this.deployPath}/${location}`);
  }

  private normalizePath(path: string): string {
    return path.replace(/\/+/g, '/').replace(/^\//, '');
  }
}
