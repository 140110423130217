import type { Actions } from '@ngrx/effects';
import { ofType } from '@ngrx/effects';
import type { DefaultProjectorFn, MemoizedSelector, Store } from '@ngrx/store';
import { MarkAsSubmittedAction } from 'ngrx-forms';
import { filter, map, withLatestFrom } from 'rxjs/operators';
/**
 * Utility function that checks whether or not a form that has been submitted is actually valid.
 * @param parentSelector Ngrx selector that is used to read the validity of our form.
 * @param validSelectorChildProps a list of form control ID's that we allow lookups to be done on.
 * @param childPropertyToValidateOn optional parameter, can be used to validate on a child property of the formGrup
 * selected by the parentSelector.
 * @returns MarkAsSubmittedAction
 */
export const isValidatedSubmitAction =
  (
    parentSelector: MemoizedSelector<object, any, DefaultProjectorFn<any>>,
    validSelectorChildProps?: string[],
    childPropertyToValidateOn?: string
  ) =>
  (store$: Store<any>) =>
  (source$: Actions) =>
    source$.pipe(
      ofType<MarkAsSubmittedAction>(MarkAsSubmittedAction.TYPE),
      filter((action) =>
        validSelectorChildProps
          ? validSelectorChildProps.includes(action.controlId)
          : true
      ),
      withLatestFrom(store$.select(parentSelector)),
      filter(([action, parentState]) => {
        const control = childPropertyToValidateOn
          ? parentState[action.controlId]?.[childPropertyToValidateOn]
          : parentState[action.controlId];
        return !control?.isValidationPending && control?.isValid;
      }),
      map(([action]) => action)
    );
