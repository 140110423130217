import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RequestStrategy } from '@essent/common';
import {
  getConsumption,
  getConsumptionSuccess,
  getSupplyAddress,
  getSupplyAddressSuccess,
  putProposition,
  putPropositionSuccess,
} from '@essent/new-customer';
import {
  generateGetOfferPayload,
  offersAreEqual,
  SITUATION_ACTION_ID,
} from '@innogy/become-a-customer/shared';
import { GenericModalSources } from '@innogy/common-ui/shared/interfaces';
import { PlatformService } from '@core/platform';
import { getIsExperienceEditorActive } from '@core/jss-routing';
import { stripQuerystringFromPath } from '@core/routing-utils';
import { ProgressiveFormService } from '@innogy/shared/progressive-form';
import { ensureFlowId } from '@innogy/utils-rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import type { Action } from '@ngrx/store';
import { Store } from '@ngrx/store';
import { zip } from 'rxjs';
import { filter, map, mergeMap, switchMap, take } from 'rxjs/operators';

import {
  putPropositionState,
  submitPropositionAction,
  submitPropositionHorizontalAction,
} from '../bac/10.proposition';
import { getSupplyAddressState } from '../bac/2.supply-address';
import {
  getConsumptionState,
  getConsumptionValuesVM,
} from '../bac/7.consumption';
import { getFlowId, noFlowIdErrorAction } from '../bac/flow-id';
import { getIsCustomer, setIsCustomerAction } from '../bac/is-customer';
import {
  appendOffersAction,
  callGetOffersAction,
  getActiveOfferids,
  getActiveOfferset,
  getPropositionOffer,
  loadNonDefaultDurationsAction,
  resetReadyToLoadOffersAction,
  setActiveOffersByDurationAction,
  setPropositionOfferAction,
  setReadyToLoadOffersAction,
} from '../bac/offers';
import { calculationDoneAction } from '../calculate';
import {
  getFunnelSettings,
  openModalFromFunnelSettingsAction,
} from '../funnel';
import { getGetOfferState } from '../get-offer';
import { getOffers, getOffersSuccess } from '../get-offer/get-offers.actions';
import { becomeACustomerOrderSelectorKey } from '../order';
import { showOfferAction } from './offer.actions';
import { INITIAL_OFFER_LOADING, LAZY_OFFER_LOADING } from './offer.reducer';

// FIXME cut into smaller chunks and move into the right libraries, a lot of these effects don't belong together.
@Injectable()
export class OfferEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<any>,
    private readonly progressiveFormService: ProgressiveFormService,
    private readonly router: Router,
    private readonly platformService: PlatformService
  ) {}
  consumptionValuesVM$ = this.store$.select(getConsumptionValuesVM);

  putPropositionState$ = this.store$.select(putPropositionState);
  getConsumptionState$ = this.store$.select(getConsumptionState);
  getSupplyAddressState$ = this.store$.select(getSupplyAddressState);
  getOfferState$ = this.store$.select(getGetOfferState);
  getActiveOfferset$ = this.store$.select(getActiveOfferset);
  activeOfferIds$ = this.store$.select(getActiveOfferids);
  getPropositionOffer$ = this.store$.select(getPropositionOffer);

  funnelSettings$ = this.store$.select(getFunnelSettings);
  isCustomer$ = this.store$.select(getIsCustomer);
  flowId$ = this.store$.select(getFlowId);
  isXpEditorActive$ = this.store$.select(getIsExperienceEditorActive);

  /**
   * we batch the get calls for consumption and supplyAddress
   * for some free performance :)
   */
  public readonly getConsumptionAndSupplyAddress$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(calculationDoneAction, showOfferAction),
      concatLatestFrom(() => [this.flowId$]),
      switchMap(([_, flowId]) => {
        if (flowId) {
          const payload = { payload: { flowId } };

          return [getConsumption(payload), getSupplyAddress(payload)];
        }

        return [];
      })
    );
  });

  public readonly awaitReadyToLoadOffers$ = createEffect(() => {
    return this.actions$.pipe(
      // We pipe based on the same actions so that every execution
      // is sure to have its own context, so we don't need to correlate between calls.
      ofType(calculationDoneAction, showOfferAction),
      switchMap(() => {
        const consumptionSuccess$ = this.actions$.pipe(
          ofType(getConsumptionSuccess),
          take(1)
        );

        const supplyAddressSuccess$ = this.actions$.pipe(
          ofType(getSupplyAddressSuccess),
          take(1)
        );

        return zip(consumptionSuccess$, supplyAddressSuccess$).pipe(
          map(() => setReadyToLoadOffersAction())
        );
      })
    );
  });

  /**
   *  Get Offer Step 3 | Get it all and get the offer
   */
  public readonly getOffer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(callGetOffersAction),
      concatLatestFrom(() => [
        this.flowId$,
        this.consumptionValuesVM$,
        this.getSupplyAddressState$,
        this.isCustomer$,
      ]),
      filter(() => this.platformService.isClient()),
      map(([action, flowId, consumption, supplyAddress, isCustomer]) => {
        const { offerSet, durationFilter = 'all_durations', actionId } = action;

        const address = supplyAddress?.data;

        /**
         * When the user navigates back to (e.g.) horizontal-offer, the address and consumption will be retrieved again,
         * and might still be pending when the offers are trying to load
         */
        if (flowId && (!address || !consumption)) {
          return resetReadyToLoadOffersAction();
        }

        if (flowId && consumption && address && offerSet) {
          const payload = generateGetOfferPayload({
            flowId,
            offerSet,
            address,
            consumption,
            isCustomer,
            durationFilter,
          });

          return getOffers({
            actionId,
            payload,
            requestStrategy: RequestStrategy.SEQUENCE,
          });
        }

        return noFlowIdErrorAction();
      })
    );
  });

  /**
   * When there is no customer valuesteering defined, set it via the
   */
  public readonly setCustomerValueFromOffersWhenUndefined$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(getOffersSuccess),
        concatLatestFrom(() => this.isCustomer$),
        filter(([_, isCustomer]) => isCustomer === undefined),
        mergeMap(([offers, _]) => [
          setIsCustomerAction({ isCustomer: offers.payload.isCustomer }),
        ])
      );
    }
  );

  /**
   * When new offers are loaded append these to the state and corresponding offerset
   */
  public readonly appendOffers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getOffersSuccess),
      concatLatestFrom(() => this.isCustomer$),
      mergeMap(([offers]) => {
        const offerset = offers.payload.offerset;
        const durations = offers.payload.durations;
        const offerList = Object.values(offers.payload.offers);

        const actions: Action[] = [
          appendOffersAction({
            offers: offerList,
            durations: {
              present: durations.present,
              retrieved: durations.retrieved,
            },
            offerset: offers.payload.offerset,
            customer: offers.payload.isCustomer,
          }),
        ];

        // Only load the non_default_durations if there are any
        if (
          durations.filter === 'default_duration' &&
          durations.present.length > durations.retrieved.length
        ) {
          actions.push(loadNonDefaultDurationsAction({ offerset }));
        }

        return actions;
      })
    );
  });

  /**
   * Set all offers of the first retrieved duration as active
   */
  public readonly setActiveOffers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getOffersSuccess),
      concatLatestFrom(() => this.getActiveOfferset$),
      filter(
        ([offers, activeOfferset]) =>
          offers.payload.offerIds.length > -1 &&
          activeOfferset?.durations.retrieved.length === 0 &&
          activeOfferset?.offerset === offers.payload.offerset
      ),
      mergeMap(([offers]) => {
        const { durations, offerset } = offers.payload;
        return [
          setActiveOffersByDurationAction({
            duration: durations.retrieved[0],
            offerset,
          }),
        ];
      })
    );
  });

  public resetProgressiveFormWhenOffersNotEqual$ = createEffect(() =>
    this.actions$.pipe(
      ofType(submitPropositionAction),
      concatLatestFrom(() => [this.getPropositionOffer$]),
      filter(
        ([action, propositionOffer]) =>
          propositionOffer === undefined ||
          !offersAreEqual(action.offer, propositionOffer)
      ),
      mergeMap(() => [
        this.progressiveFormService.resetProgressiveForm({
          formId: becomeACustomerOrderSelectorKey,
        }),
      ])
    )
  );

  public continueProgressiveFormWhenOffersEqual$ = createEffect(() =>
    this.actions$.pipe(
      ofType(submitPropositionAction),
      concatLatestFrom(() => [this.getPropositionOffer$]),
      filter(
        ([action, propositionOffer]) =>
          propositionOffer !== undefined &&
          offersAreEqual(action.offer, propositionOffer)
      ),
      mergeMap(() => [
        this.progressiveFormService.continueFromLastStepAction({
          formId: becomeACustomerOrderSelectorKey,
        }),
      ])
    )
  );

  /**
   * Handle error when no offers are retrieved (and no offers are available)
   */
  public readonly handleNoOffers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getOffersSuccess),
      concatLatestFrom(() => this.getActiveOfferset$),
      filter(([offers, activeOfferset]) => {
        const hasNoPotentialOffersAfterInitialLoad =
          offers.actionId === INITIAL_OFFER_LOADING &&
          offers.payload.durations.retrieved.length === 0 &&
          (offers.payload.durations.present.length === 0 ||
            offers.payload.durations.filter === 'all_durations');

        const hasNoPotentialOffersAfterLazyLoad =
          !!activeOfferset &&
          offers.actionId === LAZY_OFFER_LOADING &&
          offers.payload.offerset === activeOfferset.offerset &&
          offers.payload.durations.retrieved.length === 0 &&
          activeOfferset.durations.retrieved.length === 0;

        return (
          hasNoPotentialOffersAfterInitialLoad ||
          hasNoPotentialOffersAfterLazyLoad
        );
      }),
      mergeMap(() => {
        return [
          openModalFromFunnelSettingsAction({
            source: GenericModalSources.NO_OFFERS,
          }),
        ];
      })
    );
  });

  /**
   * Submit active offer on submit
   * For vertical offer
   */
  public readonly submitSelectedProposition$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(submitPropositionAction),
      ensureFlowId(this.flowId$),
      mergeMap(([action, flowId]) => {
        const actions: Action[] = [];
        const { offer } = action;
        if (flowId && offer) {
          actions.push(
            putProposition({
              payload: {
                metaData: {
                  flowId,
                },
                payload: {
                  campaignId: offer.campaignId,
                  durationInYears: offer.duration,
                  incentiveId: offer.incentiveId,
                },
              },
            }),
            setPropositionOfferAction({ offer })
          );
        }

        return actions;
      })
    );
  });

  /**
   *  Submit offer to bac
   * For horizontal offer
   */
  public readonly submitPropositionHorizontal$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(submitPropositionHorizontalAction),
      concatLatestFrom(() => [this.flowId$, this.getPropositionOffer$]),
      switchMap(([action, flowId, propositionOffer]) => {
        const actions: Action[] = [];
        const { offer } = action;
        if (flowId) {
          const payload = {
            campaignId: offer.campaignId,
            durationInYears: offer.duration,
            incentiveId: offer.incentiveId,
          };
          actions.push(
            putProposition({
              payload: { metaData: { flowId }, payload },
            }),
            setPropositionOfferAction({ offer })
          );
        }

        if (
          offer.campaignId !== propositionOffer?.campaignId ||
          offer.duration !== propositionOffer?.duration ||
          offer.incentiveId !== propositionOffer?.incentiveId
        ) {
          actions.push(
            this.progressiveFormService.resetProgressiveForm({
              formId: becomeACustomerOrderSelectorKey,
            })
          );
        } else {
          actions.push(
            this.progressiveFormService.continueFromLastStepAction({
              formId: becomeACustomerOrderSelectorKey,
            })
          );
        }

        return actions;
      })
    );
  });

  /**
   * Redirect to the order page on putPropositionSuccess
   */
  public readonly redirectToOrder$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(putPropositionSuccess),
        concatLatestFrom(() => [this.funnelSettings$, this.isXpEditorActive$]),
        filter(
          ([{ actionId }, _, isXpActive]) =>
            !isXpActive && actionId !== SITUATION_ACTION_ID
        ),
        map(([, { orderPage, id }]) => {
          this.router.navigate([orderPage.href], {
            queryParams: {
              funnel_id: id,
              returnUrl: stripQuerystringFromPath(this.router.url),
            },
            queryParamsHandling: 'merge',
          });
        })
      );
    },
    { dispatch: false }
  );
}
/* eslint-disable max-lines */
