<ng-container *ngIf="viewState$ | async as viewState">
  <ng-container *ngIf="viewState.activeOfferset">
    <div class="d-block pt-3"><!-- Spacer --></div>
    <wl-horizontal-offer-duration-filter
      [activeDuration]="viewState.activeDuration"
      (changeDurationClick)="
        setActiveOffersByNameAndDuration(
          $event,
          viewState.activeOfferset?.offerset ?? ''
        )
      "
      [durations]="viewState.presentDurations"
    ></wl-horizontal-offer-duration-filter>

    <div class="mb-4">
      <wl-horizontal-offer-multiple-cards-container
        *ngIf="isActiveDurationLoaded$ | async; else loader"
        [hasMemberGetMember]="(hasMemberGetMember$ | async) || false"
        [offers]="viewState.activeOffers"
        [addOn]="viewState.addOn"
        [rendering]="rendering"
        [moreInfoButtonClick]="moreInfoButtonClick"
        (showTarifs)="showTarifs($event)"
        (addOnToggle)="
          onAddOnToggle(
            $event,
            viewState.regularOfferset,
            viewState.addOn?.offerSet
          )
        "
        (submitProposition)="onSubmitProposition($event)"
        (showMoreIncentiveInfo)="onShowMoreIncentiveInfo($event)"
        wlCSCapture
      ></wl-horizontal-offer-multiple-cards-container>
    </div>

    <wl-commodity-offer-calculation-based-on
      [variant]="'narrow'"
      [label]="rendering?.fields?.CalculationBasedOnLabel?.value"
      [address]="viewState.supplyAddressVM"
      [consumptions]="viewState.consumptionValues"
      [electricityLabel]="'ElectricityUsageUnit' | translate"
      [gasLabel]="'GasUsageUnit' | translate"
      [adjustCalculationLabel]="
        rendering?.fields?.AdjustCalculationLabel?.value
      "
      (adjustCalculation)="onCalculationChanged()"
      class="justify-content-center"
    ></wl-commodity-offer-calculation-based-on>
  </ng-container>
</ng-container>
<ng-template #loader>
  <wl-horizontal-offer-loader
    [mask]="skeletonLoaderMask"
  ></wl-horizontal-offer-loader>
</ng-template>
