import { Component, EventEmitter, Input, Output } from '@angular/core';
import { offerHasDiscount } from '@innogy/become-a-customer/shared';
import {
  ConsumptionValuesVM,
  Offer,
} from '@innogy/become-a-customer/shared/interfaces';
import { getSkeletonMaskFromRendering } from '@innogy/common-ui/shared';
import { InnogyComponentRendering } from '@core/jss-models';

@Component({
  template: ``,
})
export class BaseOrderOverviewComponent {
  @Input()
  rendering?: InnogyComponentRendering;
  @Input()
  offer?: Offer;
  @Input()
  consumptionValuesVM?: ConsumptionValuesVM;

  @Output()
  showTarifs = new EventEmitter<Offer>();

  get skeletonLoaderMask() {
    return getSkeletonMaskFromRendering(this.rendering);
  }

  get hasDiscount(): boolean {
    return offerHasDiscount(this.offer);
  }
}
