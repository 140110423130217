import type { KeyValue } from 'ngrx-forms';

import type { NGRXProgressiveFormsActions } from '../actions';
import { ActivateRestorationPointAction } from '../actions';
import type { ProgressiveFormGroupState } from '../state';

export function activateRestorationPointReducer<TValue extends KeyValue>(
  state: ProgressiveFormGroupState<TValue>,
  action: NGRXProgressiveFormsActions
): ProgressiveFormGroupState<TValue> {
  if (action.type !== ActivateRestorationPointAction.TYPE) {
    return state;
  }

  if (!state.isRestorationPoint) {
    return state;
  }

  if (state.isRestorationPoint && state.isActive) {
    return state;
  }

  return {
    ...state,
    isActive: true,
    isInactive: false,
  };
}
