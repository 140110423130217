import { createSelector } from '@ngrx/store';
import { ContractType } from '@essent/marketing';
import { Status } from '@essent/common';

import { selectCustomerProfileState } from '../../customer-profile.state';

export const selectMarketingProfileState = createSelector(
  selectCustomerProfileState,
  (state) => state.marketingProfile
);

export const selectMarketingProfileIsDynamic = createSelector(
  selectMarketingProfileState,
  (profile) => profile.data?.contractType === ContractType.DYNAMIC
);

export const selectMarketingProfileIsLoading = createSelector(
  selectMarketingProfileState,
  (profile) =>
    profile.status === Status.PENDING || profile.status === Status.IDLE
);
