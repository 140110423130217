import { createAction } from '@ngrx/store';

import type { NamedOrderFormActions } from './order-form.actions.factory.model';

const prefix = (type: string) => `[${type} Order Form]`;

export const createResetOrderFormAction = (type: string) =>
  createAction(`${prefix(type)} Reset form`);

export const createNamedOrderFormActions = <TActionType extends string>(
  type: TActionType
): NamedOrderFormActions<TActionType> => {
  return {
    [`reset${type}OrderFormAction`]: createResetOrderFormAction(type),
  } as NamedOrderFormActions<TActionType>;
};
