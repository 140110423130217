import type { AsyncState } from '@essent/common';
import {
  createAsyncStateReducer,
  createInitialAsyncState,
} from '@essent/common';
import {
  putAdditionalInformation,
  putAdditionalInformationClear,
  putAdditionalInformationError,
  putAdditionalInformationSuccess,
} from '@essent/new-customer';
import type { Action, ActionReducerMap } from '@ngrx/store';
import { combineReducers, createReducer, on } from '@ngrx/store';

import {
  setContractWithOtherSupplierAction,
  setResidentialAction,
} from './additional-information.actions';

export interface AdditionalInformationState {
  putAdditionalInformation: AsyncState<void>; // FIXME, would expect FlowId;
  additionalInformationValues: AdditionalInformationValuesState;
}

export interface AdditionalInformationValuesState {
  residential: boolean | undefined;
  contractWithOtherSupplier: boolean | undefined;
}

const additionalInformationInitialState: AdditionalInformationValuesState = {
  residential: true,
  contractWithOtherSupplier: undefined,
};

const initialState: AdditionalInformationState = {
  putAdditionalInformation: createInitialAsyncState(),
  additionalInformationValues: additionalInformationInitialState,
};

const _putAdditionalInformationReducer = createAsyncStateReducer(
  putAdditionalInformation,
  putAdditionalInformationSuccess,
  putAdditionalInformationError,
  putAdditionalInformationClear
);

const _additionalInformationReducer = createReducer(
  additionalInformationInitialState,
  on(setResidentialAction, (state, { value }) => ({
    ...state,
    residential: value,
  })),
  on(setContractWithOtherSupplierAction, (state, { value }) => ({
    ...state,
    contractWithOtherSupplier: value,
  }))
);

const _reducers: ActionReducerMap<AdditionalInformationState, Action> = {
  putAdditionalInformation: _putAdditionalInformationReducer,
  additionalInformationValues: _additionalInformationReducer,
};

const _combinedReducers = combineReducers(_reducers);

export function additionalInformationReducer(
  state: AdditionalInformationState = initialState,
  action: Action
): AdditionalInformationState {
  return _combinedReducers(state, action);
}
