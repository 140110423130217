import { normalizeDateString } from '@innogy/utils-deprecated';
import type { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

export const toNgbDate = (
  date: NgbDateStruct | NgbDate | Date | string
): NgbDate => {
  if (typeof date === 'string') {
    const [year, month, day] = normalizeDateString(date).split('-');
    return new NgbDate(
      parseInt(year, 10),
      parseInt(month, 10),
      parseInt(day, 10)
    );
  }
  if (date instanceof Date) {
    return new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
  } else {
    return new NgbDate(date.year, date.month, date.day);
  }
};

export const toDateString = (date: NgbDateStruct) => {
  return `${('0' + date.day).slice(-2)}-${('0' + date.month).slice(-2)}-${
    date.year
  }`;
};
