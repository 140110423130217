export const InViewportMetadata = Symbol('InViewportMetadata');

export interface TrackImpressionEvent {
  [InViewportMetadata]: {
    entry: IntersectionObserverEntry | undefined;
  };
  target: any;
  visible: boolean;
  isIntersectingThreshold: boolean;
}
