import {
  accountIdHeaderName,
  createServiceApi,
  serviceTypes,
} from '@essent/common';
import type { SuccessResponse } from '@integration/emobility-api-models';

const apiVersion = '1';

export interface PostBlockChargeCard {
  accountId: string;
  cardId: string;
}

export const postBlockChargeCardAPI = createServiceApi(
  '[Emobility] Post block charge card',
  'post',
  serviceTypes<PostBlockChargeCard, SuccessResponse>(),
  {
    getUrl: ({ cardId }) =>
      `/emobility/charge-cards/${cardId}/block/v${apiVersion}`,
    getBody: () => ({}),
    version: apiVersion,
    getHeaders: ({ accountId }) => ({
      Accept: 'application/json',
      [accountIdHeaderName]: accountId,
    }),
  }
);

export const {
  postBlockChargeCard,
  postBlockChargeCardActions,
  postBlockChargeCardClear,
  postBlockChargeCardError,
  postBlockChargeCardSuccess,
} = postBlockChargeCardAPI;
