<div class="listItem" [class.listItem__active]="active">
  <div class="listItem__iconLeading" *ngIf="iconLeading">
    <fa-icon class="iconLeading" [icon]="iconLeading"></fa-icon>
  </div>
  <ng-container *ngTemplateOutlet="labelSlot"></ng-container>
  <div class="listItem__iconTrailing" *ngIf="iconTrailing">
    <fa-icon class="iconTrailing" [icon]="iconTrailing"></fa-icon>
  </div>
</div>

<ng-template #labelSlot>
  <div *ngIf="label" class="listItem__label">
    {{ label }}
  </div>
  <ng-content *ngIf="!label" select="[slot='label']"></ng-content>
</ng-template>
