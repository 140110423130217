import type { FormControlState, FormControlValueTypes } from 'ngrx-forms';
import { disable, enable, reset, setValue } from 'ngrx-forms';

/**
 * Function that leverages a `disable()/enable()` trick of ngrx-forms to reset all validations and errors on a control while also setting a value.
 * Interestingly, just using `reset()` only resets pristine, untouched, and unsubmitted.
 * @param control the control to be reset
 * @param value the value to which the control should be reset
 * @returns
 */
export const setValueWithReset = <T extends FormControlValueTypes>(
  control: FormControlState<T>,
  value: T
) => setValue(enable(disable(reset(control))), value);
