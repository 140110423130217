<wl-list-variant-hub
  *ngIf="listConfig?.variant === 'hub'"
  [rendering]="rendering"
  [subVariant]="listConfig?.subVariant"
  [listConfig]="listConfig"
  [cardConfig]="cardConfig"
  wlTrackImpression="contentblock-impression"
  [trackImpressionInfo]="rendering"
  [trackImpressionContext]="context"
></wl-list-variant-hub>
<wl-list-variant-step-by-step
  *ngIf="listConfig?.variant === 'stepbystep'"
  [rendering]="rendering"
  [subVariant]="listConfig?.subVariant"
  [listConfig]="listConfig"
  [cardConfig]="cardConfig"
  wlTrackImpression="contentblock-impression"
  [trackImpressionInfo]="rendering"
  [trackImpressionContext]="context"
></wl-list-variant-step-by-step>
<wl-list-variant-minimal
  *ngIf="listConfig?.variant === 'minimal'"
  [rendering]="rendering"
  [subVariant]="listConfig?.subVariant"
  [listConfig]="listConfig"
  wlTrackImpression="contentblock-impression"
  [trackImpressionInfo]="rendering"
  [trackImpressionContext]="context"
></wl-list-variant-minimal>
