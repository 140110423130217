import type { ScLinkField } from '@core/jss-models';
import { createAction, props } from '@ngrx/store';

const CHATBOT_PREFIX = '[Chatbot]';

export const triggerChatbotAction = createAction(
  `${CHATBOT_PREFIX} Trigger chatbot`,
  props<{ fallbackUrl?: ScLinkField }>()
);

export const triggerChatbotErrorAction = createAction(
  `${CHATBOT_PREFIX} Trigger chatbot error`,
  props<{ fallbackUrl?: ScLinkField }>()
);
