import type { Consumption } from '@essent/new-customer';
import { DirectionTariff, EnergyType } from '@essent/new-customer';

import type { CalculateFormValues } from '../calculate';

export interface ElectricityPayload {
  low: number;
  normal: number;
  return: number;
}

export const getElectricityPayload = <T extends CalculateFormValues>(
  values: T
) => {
  const normal = values.doubleMeter
    ? values.electricityUsageNormal || 0
    : values.electricityUsage || 0;

  const low = values.doubleMeter ? values.electricityUsageOffPeak || 0 : 0;

  const electricityReturn = values.hasSolarPanel
    ? values.electricityReturn || 0
    : 0;

  return {
    normal,
    low,
    return: electricityReturn,
  };
};

export const buildConsumptionPayload = (
  electricity: ElectricityPayload,
  gas: number
): Consumption[] => {
  const payload: Consumption[] = [];
  if (electricity.normal > 0) {
    payload.push({
      energyType: EnergyType.ELECTRICITY,
      standardAnnualUsages: [
        {
          directionTariff: DirectionTariff.SUPPLY_LOW,
          reading: electricity.low,
        },
        {
          directionTariff: DirectionTariff.SUPPLY_NORMAL,
          reading: electricity.normal,
        },
        {
          directionTariff: DirectionTariff.BACK_SUPPLY_LOW,
          reading: 0,
        },
        {
          directionTariff: DirectionTariff.BACK_SUPPLY_NORMAL,
          reading: electricity.return,
        },
      ],
    });
  }
  if (gas > 0) {
    payload.push({
      energyType: EnergyType.GAS,
      standardAnnualUsages: [
        {
          directionTariff: DirectionTariff.SUPPLY_NORMAL,
          reading: gas,
        },
      ],
    });
  }
  return payload;
};
