/* eslint-disable no-restricted-globals */
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { getEnvironmentConfig, initSentry } from '@innogy/core/observability';

import { DEPLOY_PATH_VALUE, EssentAppModule } from './app/app.module';
import { environment } from './environments/environment';

import 'hammerjs';

if (environment.production) {
  enableProdMode();
}

getEnvironmentConfig(DEPLOY_PATH_VALUE).then(initSentry).catch(console.error);

document.readyState !== 'loading'
  ? bootstrap()
  : document.addEventListener('DOMContentLoaded', bootstrap);

function bootstrap() {
  platformBrowserDynamic()
    .bootstrapModule(EssentAppModule)
    .catch((err) => console.error(err));
}
