import type { DropdownItem } from '@innogy/common-ui/forms';
import type { OrderInswitchInhouseOptionDropdownItem } from '@innogy/become-a-customer/shared/interfaces';
import { box } from 'ngrx-forms';
import type { EnvConfigSegment } from '@core/config-models';

import { orderInswitchInhouseOptions } from './order-fulfillment-inswitch-inhouse.model';
import type { OrderInswitchInhouseFields } from '../../../order-fulfillment-container.model';

export const getOrderInswitchInhouseDropdownOptions = (
  segment: EnvConfigSegment,
  fields?: OrderInswitchInhouseFields
): DropdownItem[] => {
  const validItems: OrderInswitchInhouseOptionDropdownItem[] =
    orderInswitchInhouseOptions(segment, fields)
      .filter((item) => item.label?.value !== undefined)
      .map((item) => ({ ...item, label: item.label?.value as string }));

  return validItems.map((item) => ({
    value: box(item),
    label: item.label,
  }));
};
