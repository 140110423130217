import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ComponentFields } from '@sitecore-jss/sitecore-jss-angular';

@Component({
  selector: 'wl-aom-footer',
  templateUrl: './aom-footer.component.html',
  styleUrls: ['./aom-footer.component.essent.scss'],
})
export class AomFooterComponent {
  @Input()
  orderPageUrl?: string;

  @Input()
  fields?: ComponentFields;

  constructor(private readonly activeModal: NgbActiveModal) {}

  submitSelectedProposition() {
    this.activeModal.close({ submit: true });
  }
}
