<ng-container *ngIf="computedVariant$ | async as computedVariant">
  <button
    *ngIf="computedAlignment$ | async as computedAlignment"
    [wlAutomationId]="automationId"
    [class.text-start]="
      computedVariant !== 'large' && computedAlignment === 'left'
    "
    class="card rounded w-100 h-100"
    [class.highlighted]="highlighted"
    (click)="clicked.emit()"
    [ngClass]="[
      'background--' + background,
      'background-hover--' + hoverBackground,
    ]"
  >
    <span
      class="w-100 h-100 d-flex align-items-center"
      [class.flex-column]="computedVariant === 'large'"
      [class.card-body]="computedVariant === 'large'"
      [class.p-2]="computedVariant === 'small'"
      [class.justify-content-start]="
        computedVariant !== 'large' && computedAlignment === 'left'
      "
      [class.justify-content-center]="
        computedVariant === 'large' || computedAlignment === 'center'
      "
    >
      <div *ngIf="iconName" class="me-1">
        <fa-icon
          class="icon d-flex align-items-center justify-content-center"
          [icon]="iconName"
          [size]="
            (computedIconSize$ | async) ||
            (computedVariant === 'small' ? 'lg' : '2x')
          "
          [fixedWidth]="true"
        ></fa-icon>
      </div>
      <span
        [class.ms-1]="iconName && computedVariant === 'small'"
        [class.mt-2]="iconName && computedVariant === 'large'"
      >
        <span
          *ngIf="title"
          class="title"
          [class.title--taxonomy]="context === 'taxonomy'"
          [class.h4]="computedVariant === 'large'"
          [class.color-link]="useLinkStyle"
          [attr.needs-fixed-width]="
            (computedVariant === 'small' && computedAlignment === 'center') ||
            null
          "
        >
          {{ title }}
        </span>
        <div
          *ngIf="subTitle"
          class="subtitle"
          [class.color-gray]="!useLinkStyle"
          [class.color-link]="useLinkStyle"
        >
          {{ subTitle }}
        </div>
      </span>
      <span *ngIf="showNext" class="d-flex align-items-center ms-auto">
        <fa-icon
          class="icon d-flex align-items-center justify-content-center"
          icon="chevron-right"
        ></fa-icon>
      </span>
    </span>
  </button>
</ng-container>
