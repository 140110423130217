<wl-modal
  *ngIf="modalContent as content"
  [modalTitle]="content.title"
  (closeModal)="dismiss()"
>
  <ng-template wlModalBody>
    <p>{{ content.explanation }}</p>
    <form
      [formGroup]="form"
      (ngSubmit)="emailOffer()"
      class="d-flex flex-column gap-2"
    >
      <div class="d-flex flex-column gap-1">
        <label for="email-address" class="form-label fw-bold mb-0">
          {{ content.emailAddressField.label }}
        </label>
        <input
          id="email-address"
          formControlName="emailAddress"
          [placeholder]="content.emailAddressField.placeholder"
          required
          type="email"
          class="form-control"
        />
        <div
          *ngIf="emailAddressControl.invalid && emailAddressControl.touched"
          class="small text-danger"
        >
          <p
            *ngIf="emailAddressControl.hasError('required')"
            class="mb-0"
            wlAutomationId="missing-email-error-message"
          >
            {{ content.emailAddressField.errorMessages.missing }}
          </p>
          <p
            *ngIf="emailAddressControl.hasError('email')"
            class="mb-0"
            wlAutomationId="invalid-email-error-message"
          >
            {{ content.emailAddressField.errorMessages.invalid }}
          </p>
        </div>
        <small class="text-secondary">
          {{ content.emailAddressField.note }}
        </small>
      </div>
      <button class="btn btn-primary">
        {{ content.buttonLabels.emailOffer }}
      </button>
    </form>
    <button
      type="button"
      class="btn btn-link w-100 mt-1"
      (click)="dismiss()"
      wlAutomationId="dismiss-button"
    >
      {{ content.buttonLabels.dismiss }}
    </button>
  </ng-template>
</wl-modal>
