import { createSelector } from '@ngrx/store';

import { getCustomerDetailsState } from './feature.selectors';

const getChangeContactPersonForm = createSelector(
  getCustomerDetailsState,
  (state) => state.changeContactPersonForm
);

export const getChangeContactPersonFormState = createSelector(
  getCustomerDetailsState,
  (state) => state.changeContactPersonForm.changeContactPersonFormState
);

export const getChangeContactPersonTechnicalError = createSelector(
  getChangeContactPersonForm,
  (state) => state.technicalError
);
