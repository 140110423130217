import { createBaseAction } from '@essent/common';
import type { ProductSelection } from '@integration/emobility-api-models';
import { createAction, props } from '@ngrx/store';
import type { LinkField } from '@sitecore-jss/sitecore-jss-angular';

const PREFIX = '[Emobility Lead Form]';

export const initializeLeadForm = createBaseAction<{
  thankYouPageUrl?: LinkField;
  chargePointConfigurationEnabled: boolean;
  e2eTrackingId: string;
}>(`${PREFIX} initialize`);

export const setEmobilityLeadFormType = createAction(
  `${PREFIX} set lead form type`,
  props<{ proposition: ProductSelection }>()
);
