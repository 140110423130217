import { createFeatureSelector } from '@ngrx/store';

import type { CommonUiServiceComponentsState } from './common-ui-service-components-store.interface';

export const commonUiServiceComponentsSelectorKey =
  'common-ui-service-components';

export const getCommonUiServiceComponentsState =
  createFeatureSelector<CommonUiServiceComponentsState>(
    commonUiServiceComponentsSelectorKey
  );
