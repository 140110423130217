import { Inject, Injectable } from '@angular/core';
import { openGenericModal } from '@innogy/common-ui/modals';
import { ENVIRONMENT_CONFIG } from '@core/config-angular';
import { EnvironmentConfig } from '@core/config-models';
import type {
  InsulationFunnelSettingsInterface,
  InsulationFunnelStep,
} from '@innogy/eplus/models';
import type { InsulationProductWithCalculations } from '@innogy/eplus/temporary-core-modules';
import { getInsulationAddressCheckSuccess } from '@innogy/eplus/temporary-core-modules';
import { getIsExperienceEditorActive } from '@core/jss-routing';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';

import { createFunnelEffectCreators } from '../../shared/generators/funnel/funnel.effects.factory';
import {
  handleInsulationFunnelAddressNotFoundErrorAction,
  handleInsulationFunnelGenericErrorAction,
  handleInsulationFunnelPrivateErrorAction,
  initializeInsulationFunnelStepAction,
  mintInsulationTrackingIdAction,
  onInsulationFunnelNavigationAction,
  selectInsulationProductsAction,
  setInsulationFunnelInitializedAction,
} from './insulation-funnel.actions';
import { selectInsulationFunnelSettings } from './insulation-funnel.selectors';

@Injectable()
export class InsulationFunnelEffects {
  funnelSettings$ = this.store$.select(selectInsulationFunnelSettings);
  isXpEditorActive$ = this.store$.select(getIsExperienceEditorActive);

  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store,
    @Inject(ENVIRONMENT_CONFIG) private readonly config: EnvironmentConfig
  ) {}

  private readonly effects = createFunnelEffectCreators<
    InsulationFunnelStep,
    InsulationFunnelSettingsInterface,
    InsulationProductWithCalculations
  >(
    this.actions$,
    this.funnelSettings$,
    this.isXpEditorActive$,
    'startPage',
    'wizard',
    this.config,
    {
      initializeFunnelStepAction: initializeInsulationFunnelStepAction,
      handleFunnelPrivateErrorAction: handleInsulationFunnelPrivateErrorAction,
      onFunnelNavigationAction: onInsulationFunnelNavigationAction,
      setFunnelInitializedAction: setInsulationFunnelInitializedAction,
      handleFunnelGenericErrorAction: handleInsulationFunnelGenericErrorAction,
      mintTrackingIdAction: mintInsulationTrackingIdAction,
    }
  );

  public readonly onInitInsulationFunnel$ =
    this.effects.initializeFunnelStepEffectCreator();

  public readonly onInsulationFunnelNavigation$ =
    this.effects.onFunnelNavigationEffectCreator();

  public readonly onInsulationFunnelPrivateError$ =
    this.effects.handleFunnelPrivateErrorEffectCreator();

  public readonly onInsulationFunnelGenericError$ =
    this.effects.handleFunnelGenericErrorEffectCreator();

  public readonly mintInsulationTrackingId$ =
    this.effects.mintTrackingIdEffectCreator();

  public readonly onInsulationFunnelAddressNotFoundErrorAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(handleInsulationFunnelAddressNotFoundErrorAction),
        concatLatestFrom(() => this.funnelSettings$),
        map(([_, funnelSettings]) => {
          if (!funnelSettings.addressNotFoundErrorModal) {
            return handleInsulationFunnelPrivateErrorAction({
              message:
                'addressNotFoundErrorModal is not defined on FunnelSettings',
            });
          }
          return openGenericModal(funnelSettings.addressNotFoundErrorModal);
        })
      )
  );

  public readonly selectProductsOnInsulationAddressCheckSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(getInsulationAddressCheckSuccess),
        map(({ payload }) => {
          return payload.products.filter((product) =>
            payload.suitableProductTypes.includes(product.productType.value)
          );
        }),
        map((products) => selectInsulationProductsAction({ products }))
      )
  );
}
