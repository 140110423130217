import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';
import { format as dateFnsFormat, isValid, parse } from 'date-fns';
import type { Locale } from 'date-fns/locale';
import { nl } from 'date-fns/locale';

/**
 * Pipe that formats dates with the date-fns library, accepts both string and Date input
 */
@Pipe({
  name: 'dateFns',
})
export class DateFnsPipe implements PipeTransform {
  transform(
    date: string | Date | undefined,
    format: string,
    inputFormat = 'yyyy-MM-dd',
    inputLocale?: Locale
  ) {
    const _date =
      typeof date === 'string'
        ? parse(date, inputFormat, new Date(), { locale: inputLocale })
        : date;

    if (_date && isValid(_date)) {
      return dateFnsFormat(_date, format, {
        locale: nl,
      });
    }

    return undefined;
  }
}
