import type { ActionConstructor, CreatedAction } from 'ngrx-forms';
import { onNgrxFormsAction } from 'ngrx-forms';

export function onNgrxFormsControlId<TAction extends ActionConstructor, TState>(
  actionConstructor: TAction,
  controlIds: string | string[],
  handler: (state: TState, action: CreatedAction<TAction>) => TState
) {
  const ids = Array.isArray(controlIds) ? controlIds : [controlIds];
  return onNgrxFormsAction<TAction, TState>(
    actionConstructor,
    (state, action) => {
      if (ids.includes(action.controlId)) {
        return handler(state, action);
      }

      return state;
    }
  );
}
