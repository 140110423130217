/**
 * Generic sort function that can be used with array.sort()
 *
 * @example
 *
 * interface MyType {
 *  a: string;
 *  b: string;
 * }
 *
 * const myArray: MyType[] = [];
 * const sort = myArray.sort(sortByCurried<MyType>('a'))
 */
export const sortByCurried =
  <T>(prop: keyof T) =>
  (a: T, b: T) => {
    return sorter(a[prop], b[prop]);
  };

/**
 * Generic sort function that can be used with array.sort()
 *
 * @example
 *
 * interface MyType {
 *  a: string;
 *  b: string;
 * }
 *
 * const myArray: MyType[] = [];
 * const sort = myArray.sort((a, b) => sortBy(a, b, 'a'))
 */
export const sortBy = <T>(a: T, b: T, prop: keyof T) => {
  return sortByCurried(prop)(a, b);
};

/**
 * Generic sort function that can be used with array.sort()
 *
 * @example
 *
 * const myArray: int[] = [];
 * const sort = myArray.sort(sorter)
 */
export const sorter = <T>(a: T, b: T) => {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }

  return 0;
};
