<div
  class="brand-header brand-header--{{ theme }}"
  [class.brand-header--stretch]="stretchBackdrop"
  *ngIf="heading || subheading"
>
  <h1 class="brand-header__headings">
    <span
      *ngIf="heading"
      class="brand-header__heading display-1"
      [class.d-block]="!inlineHeadings"
      [class.d-inline-block]="inlineHeadings"
      [class.add-trailing-nbsp]="inlineHeadings && subheading"
    >
      {{ heading }}
    </span>
    <span
      *ngIf="subheading"
      class="brand-header__subheading display-1"
      [class.d-block]="!inlineHeadings"
      [class.d-inline-block]="inlineHeadings"
    >
      {{ subheading }}
    </span>
  </h1>
  <ng-content select="[slot='content']"></ng-content>
</div>
