<button
  class="card rounded w-100 h-100"
  [class.highlighted]="highlighted"
  (click)="clicked.emit()"
>
  <span
    class="card-body w-100 d-flex align-items-center flex-column justify-content-center"
  >
    <img *ngIf="src" [src]="src" [alt]="alt || title" />
    <span class="title h4" [class.pt-2]="src">{{ title }}</span>
  </span>
</button>
