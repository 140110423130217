import type { GenericProductData } from '@innogy/common-ui/models';
import type { SharedEplusFunnelSettingsInterface } from '@innogy/eplus/models';
import type { ScLinkField } from '@core/jss-models';
import { createAction, props } from '@ngrx/store';

import type { NamedFunnelActions } from './funnel.actions.factory.model';

const prefix = (type: string) => `[${type} Funnel]`;

export function createInitializeFunnelStepAction<
  TStep extends string,
  TSettings extends SharedEplusFunnelSettingsInterface,
>(type: string) {
  return createAction(
    `${prefix(type)} Step initialized`,
    props<{
      step: TStep;
      funnelSettings: TSettings | undefined;
    }>()
  );
}

export function createSetFunnelInitializedAction<
  TSettings extends SharedEplusFunnelSettingsInterface,
>(type: string) {
  return createAction(
    `${prefix(type)} Initialize funnel`,
    props<{
      funnelSettings: TSettings;
    }>()
  );
}

export function createMintTrackingIdAction(type: string) {
  return createAction(
    `${prefix(type)} Mint E2E tracking ID`,
    props<{
      id: string;
    }>()
  );
}

export function createClearFunnelInitializedAction(type: string) {
  return createAction(`${prefix(type)} Reset funnel initialization`);
}

export function createOnFunnelNavigationAction(type: string) {
  return createAction(
    `${prefix(type)} Navigate`,
    props<{ page: ScLinkField | undefined }>()
  );
}

export function createHandleFunnelPrivateErrorAction(type: string) {
  return createAction(
    `${prefix(type)} Private Error`,
    props<{ message: string }>()
  );
}

export function createHandleFunnelGenericErrorAction(type: string) {
  return createAction(`${prefix(type)} Generic Error`);
}

export function createSelectProductAction<TProduct extends GenericProductData>(
  type: string
) {
  return createAction(
    `${prefix(type)} Select product`,
    props<{ product: TProduct }>()
  );
}

export function createSelectProductsAction<TProduct extends GenericProductData>(
  type: string
) {
  return createAction(
    `${prefix(type)} Select products`,
    props<{ products: TProduct[] }>()
  );
}

export function createToggleProductSelectionAction<
  TProduct extends GenericProductData,
>(type: string) {
  return createAction(
    `${prefix(type)} Toggle product selection`,
    props<{ product: TProduct }>()
  );
}

export function createConfirmProductSelectionAction(type: string) {
  return createAction(`${prefix(type)} Confirm product selection`);
}

export function createResetProductSelectionAction(type: string) {
  return createAction(`${prefix(type)} Reset product selection`);
}

export const createNamedFunnelActions = <
  TActionType extends string,
  TStep extends string,
  TSettings extends SharedEplusFunnelSettingsInterface,
  TProduct extends GenericProductData,
>(
  type: TActionType
): NamedFunnelActions<TActionType, TStep, TSettings, TProduct> =>
  ({
    [`initialize${type}FunnelStepAction`]:
      createInitializeFunnelStepAction(type),
    [`set${type}FunnelInitializedAction`]:
      createSetFunnelInitializedAction(type),
    [`clear${type}FunnelInitializedAction`]:
      createClearFunnelInitializedAction(type),
    [`on${type}FunnelNavigationAction`]: createOnFunnelNavigationAction(type),
    [`handle${type}FunnelPrivateErrorAction`]:
      createHandleFunnelPrivateErrorAction(type),
    [`handle${type}FunnelGenericErrorAction`]:
      createHandleFunnelGenericErrorAction(type),
    [`select${type}ProductAction`]: createSelectProductAction(type),
    [`select${type}ProductsAction`]: createSelectProductsAction(type),
    [`toggle${type}ProductSelectionAction`]:
      createToggleProductSelectionAction(type),
    [`confirm${type}ProductSelectionAction`]:
      createConfirmProductSelectionAction(type),
    [`reset${type}ProductSelectionAction`]:
      createResetProductSelectionAction(type),
    [`mint${type}TrackingIdAction`]: createMintTrackingIdAction(type),
  }) as NamedFunnelActions<TActionType, TStep, TSettings, TProduct>;
