<wl-image-card-button
  *ngIf="image.src"
  [src]="image.src"
  [title]="title"
  (clicked)="onSelect()"
  [highlighted]="active"
></wl-image-card-button>
<wl-icon-card-button
  *ngIf="!image.src"
  [iconName]="icon | scIcon"
  [title]="title"
  [highlighted]="active"
  variantMobile="large"
  iconSize="3x"
  iconSizeMobile="2x"
  (clicked)="onSelect()"
></wl-icon-card-button>
