<wl-partial-card>
  <ng-container slot="header">
    <wl-partial-sticker-header
      [title]="title?.value"
      [subtitle]="subTitle?.value"
      [sticker]="sticker?.value"
    ></wl-partial-sticker-header>
  </ng-container>
  <ng-container slot="content">
    <ng-content></ng-content>
  </ng-container>
</wl-partial-card>
