import { Component, Input } from '@angular/core';

import { ContentTheme } from '../../models';

@Component({
  selector: 'wl-partial-media-card',
  templateUrl: './media-card.component.html',
  styleUrls: ['./media-card.component.ed.scss'],
})
export class MediaCardComponent {
  @Input() grow = false;
  @Input() shadow = false;

  @Input() theme: ContentTheme = 'default';
}
