import {
  extendPostalAddressFormReducer,
  type PostalAddressFormValues,
} from '@innogy/common-ui/forms';
import { combineReducers, type ActionReducer } from '@ngrx/store';
import type { FormGroupState } from 'ngrx-forms';

export const heatpumpsWizardKey = 'heatpumpsWizard';
export const heatpumpsWizardFormId = 'heatpumpsWizardForm';

export type HeatpumpsWizardFormValues = PostalAddressFormValues;

export interface HeatpumpsWizardAddressCheck {
  hasErrored: boolean;
}

export interface HeatpumpsWizardState {
  formState: FormGroupState<HeatpumpsWizardFormValues>;
}

const initialFormValues: HeatpumpsWizardFormValues = {
  postalCode: '',
  communicationNumber: NaN,
  houseNumberAddition: '',
};

const {
  extendedControlIds,
  initialExtendedFormGroupState: initialHeatpumpsWizardFormGroupState,
  extendedReducer: heatpumpsWizardFormStateReducer,
} = extendPostalAddressFormReducer<HeatpumpsWizardFormValues>({
  formId: heatpumpsWizardFormId,
  baseInitialState: initialFormValues,
  extendingValidators: {},
});

export const heatpumpsWizardFormControlIds = extendedControlIds;

export const initialHeatpumpsWizardState: HeatpumpsWizardState = {
  formState: initialHeatpumpsWizardFormGroupState,
};

export const heatpumpsWizardReducer: ActionReducer<HeatpumpsWizardState> =
  combineReducers({
    formState: heatpumpsWizardFormStateReducer,
  });
