import { Component, Input } from '@angular/core';
import { ImageField, TextField } from '@sitecore-jss/sitecore-jss-angular';

@Component({
  selector: 'wl-calculate-card[title]',
  templateUrl: './calculate-card.component.html',
  styleUrls: [
    './calculate-card.component.essent.scss',
    './calculate-card.component.ed.scss',
  ],
})
export class CalculateCardComponent {
  @Input() title!: TextField;
  @Input() subTitle?: TextField;
  @Input() sticker?: ImageField;
}
