import { Component, Input } from '@angular/core';
import { ScLinkField } from '@core/jss-models';
import { TextField } from '@sitecore-jss/sitecore-jss-angular';

@Component({
  selector: 'wl-app-buttons',
  templateUrl: './app-buttons.component.html',
  styleUrls: [
    './app-buttons.component.essent.scss',
    './app-buttons.component.ed.scss',
    './app-buttons.component.ew.scss',
  ],
})
export class AppButtonsComponent {
  @Input() trackingName = 'app-buttons';
  @Input() titleField?: TextField;
  @Input() androidLink?: ScLinkField;
  @Input() androidLinkTrackingField?: TextField;
  @Input() iosLink?: ScLinkField;
  @Input() iosLinkTrackingField?: TextField;
}
