export const sitecoreItemByIdQuery = `
  query QUERY_BY_TEMPLATE_ID($templateId: String!) {
    item(path: $templateId) {
      name
      id
      fields(excludeStandardFields: true) {
        name
        ... on MultilistField {
          targetItems {
            id
            fields(excludeStandardFields: true) {
              name
              ... on MultilistField {
                targetItems {
                  fields(excludeStandardFields: true) {
                    name
                    ... on LinkField {
                      jss
                    }
                    ... on TextField {
                      jss
                    }
                    ... on NumberField {
                      jss
                    }
                    ... on CheckboxField {
                      jss
                    }
                    ... on LookupField {
                      jss
                    }
                  }
                }
              }
              ... on LinkField {
                jss
              }
              ... on TextField {
                jss
              }
              ... on NumberField {
                jss
              }
              ... on CheckboxField {
                jss
              }
              ... on LookupField {
                jss
              }
            }
          }
        }
        ... on LinkField {
          jss
        }
        ... on TextField {
          jss
        }
        ... on NumberField {
          jss
        }
        ... on CheckboxField {
          jss
        }
        ... on LookupField {
          jss
        }
      }
    }
  }
`;
