import { Component, Input } from '@angular/core';
import { EcmpHeader } from '@innogy/shared/channel-recognition';

@Component({
  selector: 'wl-ecmp-header-image[ecmpHeader]',
  templateUrl: './ecmp-header-image.component.html',
  styleUrls: ['./ecmp-header-image.component.scss'],
})
export class EcmpHeaderImageComponent {
  @Input() ecmpHeader!: EcmpHeader;
}
