import { Component, Input } from '@angular/core';

@Component({
  selector: 'wl-not-found[title]',
  templateUrl: './not-found.component.html',
  styleUrls: ['not-found.component.ed.scss', 'not-found.component.essent.scss'],
})
export class NotFoundComponent {
  @Input() title!: string;
}
