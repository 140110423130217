import type { HttpErrorResponse } from '@angular/common/http';
import type { ContractAddress } from '@essent/address';
import {
  getContracts,
  getContractsClear,
  getContractsError,
  getContractsSuccess,
} from '@essent/address';
import { Status } from '@essent/common';
import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';

export interface State {
  status: Status;
  error?: HttpErrorResponse;
  contractAddresses?: ContractAddress[];
}

export const initialState: State = {
  status: Status.IDLE,
  contractAddresses: [],
};

const _reducer = createReducer(
  initialState,
  on(getContracts, (state) => ({
    ...state,
    status: Status.PENDING,
    error: undefined,
    contractAddresses: undefined,
  })),
  on(getContractsSuccess, (state, { payload }) => ({
    ...state,
    contractAddresses: payload.supplyAddress,
    error: undefined,
    status: Status.SUCCESS,
  })),
  on(getContractsError, (state, { payload }) => ({
    ...state,
    contractAddresses: undefined,
    error: payload,
    status: Status.ERROR,
  })),
  on(getContractsClear, (_) => initialState)
);

export function contractAddressesReducer(
  state: State = initialState,
  action: Action
): State {
  return _reducer(state, action);
}

export const selectContractAddresses = (state: State) =>
  state.contractAddresses ?? [];
