// This is a workaround for a circular import.
// Code will soon be purged from codebase as this component will be replaced
// by a new picker. Choice is made to solve issue like this for the time being.
export type Option = {
  label: string;
  value: string;
};

export interface UsageQuestionnaireProps {
  submitLabel: string;
  supplyTypeOptionsLabel: string;
  errorMessage: string;
  residentsOptions: Option[];
  subtitle: string;
  supplyTypeOptionsPlaceHolder: string;
  housingTypeOptions: Option[];
  residentsOptionsLabel: string;
  supplyTypeOptions: Option[];
  title: string;
  housingTypeOptionsLabel: string;
  residentsOptionsPlaceHolder: string;
  housingTypeOptionsPlaceHolder: string;
  componentName: string;
}

export const BAC_QUESTIONNAIRE_ID = 'bac-usage-questionnaire';
