<div class="footer-lower">
  <div class="container">
    <div class="footer-lower-grid">
      <!-- links -->
      <div class="area__links">
        <div
          sc-placeholder
          name="bottom-links-container"
          [rendering]="rendering"
        ></div>
      </div>
      <!-- brand logo -->
      <div class="area__brand">
        <wl-footer-brand></wl-footer-brand>
      </div>
      <!-- copyright -->
      <div class="area__copyright">
        <span *scText="fields?.copyright"></span>
      </div>
    </div>
  </div>
</div>
