import type {
  BreadcrumbListStructuredData,
  ListItemStructuredData,
} from '@core/structured-data';

import type {
  BreadcrumbNavItem,
  BreadcrumbRecursiveProps,
} from './breadcrumb.model';

export function mapRouteContext(
  context: {
    [key: string]: any;
  },
  rootUrl: string
): BreadcrumbNavItem[] {
  const recursiveProps: BreadcrumbRecursiveProps = {
    rootUrl,
    routeArray: [],
    recursiveTimes: 0,
    recursiveMax: 7,
  };

  if (!context['parent']) {
    return [];
  }

  const navItems = recursiveRouteMap(context, recursiveProps);

  navItems.reverse();

  return navItems;
}

export function recursiveRouteMap(
  item: any,
  props: BreadcrumbRecursiveProps
): BreadcrumbNavItem[] {
  const isRoot = isRootUrl(item.url, props.rootUrl);
  props.routeArray.push({
    displayName: getDisplayName(item),
    url: item.url,
    isRoot,
  });

  if (isRoot || !item.parent || props.recursiveTimes >= props.recursiveMax) {
    return props.routeArray;
  } else {
    props.recursiveTimes++;
    return recursiveRouteMap(item.parent, props);
  }
}

export function getDisplayName(item: any): string {
  const displayName = !!item.BreadcrumbTitle?.jss?.value
    ? item.BreadcrumbTitle?.jss?.value
    : item.displayName;

  // 'text-transform': 'capitalize' doesn't seem to work, so capitalize here
  return displayName[0].toUpperCase() + displayName.slice(1);
}

export function isRootUrl(url: string, rootUrl: string): boolean {
  return url === rootUrl || url === `${rootUrl}/`;
}

export const generateStructuredDataFromRoutes = (
  routes: BreadcrumbNavItem[],
  baseUrl: string
): BreadcrumbListStructuredData => ({
  '@context': 'https://schema.org',
  '@type': 'BreadcrumbList',
  itemListElement: routes.map(
    (item, index): ListItemStructuredData => ({
      '@type': 'ListItem',
      position: index + 1,
      name: item.displayName,
      item: `${baseUrl}${item.url}`,
    })
  ),
});
