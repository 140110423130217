import {
  getAcquisitionOffers,
  getConsumptionValuesVM,
  getFunnelSettings,
  getSupplyAddressVM,
  hasMemberGetMember,
  isGetOffersLoading,
} from '@innogy/become-a-customer/state';
import { createSelector } from '@ngrx/store';

export const acquisitionCommodityOfferVM = createSelector(
  getAcquisitionOffers,
  getConsumptionValuesVM,
  getSupplyAddressVM,
  getFunnelSettings,
  hasMemberGetMember,
  isGetOffersLoading,
  (
    offers,
    consumption,
    address,
    funnelSettings,
    hasMemberGetMember,
    isLoading
  ) => ({
    offers,
    consumption,
    address,
    funnelSettings,
    hasMemberGetMember,
    isLoading,
  })
);
