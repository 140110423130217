import { createExtensionDropdownList } from '@innogy/become-a-customer/shared';
import { createSelector } from '@ngrx/store';

import { getBecomeACustomerState } from '../../become-a-customer-feature.selector';

export const getInstallationAddressState = createSelector(
  getBecomeACustomerState,
  (state) => state.getInstallationAddress
);

export const getInstallationAddressHouseNumberExtensionAsDropdownItems =
  createSelector(getInstallationAddressState, (state) =>
    createExtensionDropdownList(state.data)
  );

export const getInstallationAddressStatus = createSelector(
  getInstallationAddressState,
  (state) => state.status
);
