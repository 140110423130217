import { Component, Input } from '@angular/core';

import { ContentTheme } from '../../models';

@Component({
  selector: 'wl-partial-brand-header',
  templateUrl: './brand-header.component.html',
  styleUrls: ['./brand-header.component.ed.scss'],
})
export class BrandHeaderComponent {
  /**
   * Defines the theming of the brand header.
   */
  @Input() theme: ContentTheme = 'default';
  /**
   * Main header text.
   */
  @Input() heading?: string;
  /**
   * Sub header text.
   */
  @Input() subheading?: string;
  /**
   * When enabled, the headings will be inlined instead of starting the subheading on a new line.
   */
  @Input() inlineHeadings?: boolean = false;
  /**
   * Defines whether or not the backdrop should stretch to the side of the screen.
   */
  @Input() stretchBackdrop?: boolean = false;
}
