import type { KeyValue } from 'ngrx-forms';

import type { ProgressiveFormGroupState } from '../state';
import { progressiveFormStateReducer } from '../reducer';
import { MarkAsyncTasksAsCompletedAction } from '../actions';

export function markAsyncTasksAsCompleted<TValue extends KeyValue>(
  state: ProgressiveFormGroupState<TValue>
) {
  return progressiveFormStateReducer(
    state,
    new MarkAsyncTasksAsCompletedAction(state.id)
  );
}
