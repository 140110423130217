import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

/**
 * This "cast" pipe can be used to map a generic type to a specific type in a template, or the other way around.
 *
 * An example for when this could be useful is when you have a strongly-typed "rendering",
 * but you need to pass it to a component that expects a generic "rendering".
 *
 * @example ts
 * toInnogyComponentRendering!: InnogyComponentRendering;
 * @example html
 * <wl-goat [rendering]="typedRendering | cast: toInnogyComponentRendering">...<wl-goat>
 */
@Pipe({ name: 'cast' })
export class CastPipe implements PipeTransform {
  transform<T>(input: unknown, _: T | undefined): T {
    return input as unknown as T;
  }
}
