import type { ComponentNameAndModule } from '@sitecore-jss/sitecore-jss-angular';
import {
  DynamicPricesGuard,
  GoToDynamicPricesGuard,
} from '@innogy/tariffs/store';

const loadTariffsModule = async () => {
  return import('./lib/tariffs-lazy.module').then(
    (mod) => mod.TariffsLazyModule
  );
};

export const tariffsInMijnLazyImports: ComponentNameAndModule[] = [
  {
    path: 'DynamicPrices',
    loadChildren: loadTariffsModule,
    canActivate: [DynamicPricesGuard],
  },
  {
    path: 'GoToDynamicPrices',
    loadChildren: loadTariffsModule,
    canActivate: [GoToDynamicPricesGuard],
  },
];

export const tariffsInOpenLazyImports: ComponentNameAndModule[] = [
  {
    path: 'DynamicPrices',
    loadChildren: loadTariffsModule,
    canActivate: [],
  },
];
