<div [class.text-center]="content?.centerText" *ngIf="hasTopItems">
  <wl-basic-content-partial-heading
    class="mb-1"
    [heading]="content?.heading"
    [centerText]="content?.centerText"
    [size]="headingSize"
  ></wl-basic-content-partial-heading>
  <ng-container *ngIf="content?.subheading?.text?.value">
    <p
      *scText="content?.subheading?.text"
      [ngClass]="[content?.subheading?.style, subheadingSize]"
    ></p>
  </ng-container>
  <ng-container *ngIf="content?.text?.text?.value">
    <div class="basic-content-between__rich-text">
      <wl-rich-text-wrapper [field]="content?.text?.text">
        <div [ngClass]="[content?.text?.style]"></div>
      </wl-rich-text-wrapper>
    </div>
  </ng-container>
</div>
<div [class.mb-4]="hasButtons">
  <ng-content></ng-content>
</div>
<div [class.text-center]="content?.centerText" *ngIf="hasBottomItems">
  <div *ngIf="hasButtons">
    <a
      *wlGenericLink="content?.buttons?.primary?.link"
      [ngClass]="['mb-1', content?.buttons?.primary?.style]"
    ></a>
    <a
      *wlGenericLink="content?.buttons?.secondary?.link"
      [ngClass]="['mb-1', content?.buttons?.secondary?.style]"
      [class.ms-2]="content?.buttons?.primary?.link?.href"
    ></a>
  </div>
  <ng-container *ngIf="content?.microcopy?.text?.value">
    <p
      *scText="content?.microcopy?.text"
      [ngClass]="[content?.microcopy?.style]"
    ></p>
  </ng-container>
</div>
