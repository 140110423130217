<div class="footer-upper">
  <div class="container">
    <div
      class="footer-upper-grid"
      [class.footer-upper-grid--app-buttons-empty]="!showAppButtons"
    >
      <!-- placeholder -->
      <div class="area__rendering">
        <div
          [rendering]="rendering"
          sc-placeholder
          name="main-footer-container-left"
        ></div>
      </div>
      <!-- download app buttons -->
      <div class="area__app-buttons">
        <wl-app-buttons
          *ngIf="showAppButtons"
          trackingName="footernavigation"
          [titleField]="fields.appButtonsTitle"
          [androidLink]="fields.appButtonsAndroidLink"
          [androidLinkTrackingField]="fields.appButtonsAndroidLinkTrackingField"
          [iosLink]="fields.appButtonsIosLink"
          [iosLinkTrackingField]="fields.appButtonsIosLinkTrackingField"
        ></wl-app-buttons>
      </div>
      <!-- social media buttons -->
      <div class="area__socials">
        <wl-footer-socials [rendering]="rendering"></wl-footer-socials>
      </div>
    </div>
  </div>
</div>
