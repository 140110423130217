<div [ngSwitch]="brand" class="w-100 h-100 d-block">
  <wl-not-found
    *ngSwitchCase="'ed'"
    title="Oeps, de stekker is eruit."
  ></wl-not-found>

  <wl-not-found
    *ngSwitchDefault
    title="Oeps, we zijn even niet thuis"
  ></wl-not-found>
</div>
