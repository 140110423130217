import { Component, Input } from '@angular/core';

@Component({
  selector: 'wl-commodity-price-breakdown-row',
  templateUrl: './commodity-price-breakdown-row.component.html',
  styleUrls: [
    './commodity-price-breakdown-row.component.ed.scss',
    './commodity-price-breakdown-row.component.essent.scss',
  ],
})
export class CommodityOfferPriceBreakdownRowComponent {
  @Input() title?: string;
  @Input() subtitle?: string;
  @Input() costWithDiscount?: number;
  @Input() costWithoutDiscount?: number;
  @Input() tooltipDescription?: string;
  @Input() discountPrice?: number;
  @Input() alignPriceHorizontal? = false;
  @Input() isBBA? = false;

  get costWithoutDiscountClasses(): { [key: string]: boolean } {
    return {
      'gray-text text-decoration-line-through':
        !!this.discountPrice && !this.isBBA,
      'fw-bold': !this.discountPrice || !!this.isBBA,
    };
  }

  get subTitleValue(): string | undefined {
    return this.isBBA || this.discountPrice ? this.subtitle : undefined;
  }
}
