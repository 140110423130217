import type { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import type { HttpResponse as JssResponse } from '@sitecore-jss/sitecore-jss-angular';
import type { Observable } from 'rxjs';
import { lastValueFrom } from 'rxjs';

@Injectable()
export class JssDataFetcherService {
  constructor(private readonly httpClient: HttpClient) {}

  fetch = <T, U>(url: string, data?: U): Promise<JssResponse<T>> => {
    let result$: Observable<HttpResponse<T>>;

    const options = {
      observe: 'response',
      withCredentials: true,
    } as const;

    if (data) {
      result$ = this.httpClient.post<T>(url, data, options);
    } else {
      result$ = this.httpClient.get<T>(url, options);
    }

    return lastValueFrom(result$)
      .then((response) => ({
        data: response.body as T,
        status: response.status,
        statusText: response.statusText,
      }))
      .catch((error: HttpErrorResponse) => {
        if (error instanceof Error) {
          throw error;
        }

        return {
          data: error.error as T,
          status: error.status,
          statusText: error.statusText,
        };
      });
  };
}
