<div
  class="essent-order-overview-header bg-brand text-white"
  [class]="
    fluidContainer
      ? 'essent-order-overview-header--retention-view'
      : 'essent-order-overview-header--bac-view'
  "
>
  <div class="bg-brand text-white">
    <div class="d-flex justify-content-between">
      <p
        class="essent-order-overview-header__heading text-break mb-1 h4"
        wlCSCapture
      >
        {{ offer?.productTitle }}
      </p>
      <wl-offer-overview-change-offer
        textColor="text-light"
        *ngIf="showEditButton"
      ></wl-offer-overview-change-offer>
    </div>

    <ng-container *ngIf="showCostsInHeader; else oldHeader">
      <essent-order-overview-budget-bill-cost
        class="d-block d-lg-none my-2"
        [monthlyAmountLabel]="monthlyAmountLabel"
        [monthlyAmountTooltip]="monthlyAmountTooltip"
        [monthlyAmount]="offer?.expectedMonthlyAmount"
        [budgetBillLabel]="budgetBillLabel"
        [budgetBillTooltip]="budgetBillTooltip"
        [budgetBillAmount]="offer?.budgetBillAmount"
      ></essent-order-overview-budget-bill-cost>
    </ng-container>
    <ng-template #oldHeader>
      <essent-order-overview-budget-bill-cost
        class="d-block d-lg-none my-2"
        [budgetBillLabel]="budgetBillLabel"
        [budgetBillAmount]="offer?.budgetBillAmount"
      ></essent-order-overview-budget-bill-cost>
    </ng-template>
  </div>
</div>
