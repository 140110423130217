<wl-offer-label
  *ngIf="offer?.isHighlightedLabel"
  wlAutomationId="offer-label"
  [class.invisible]="!offer?.isHighlighted"
  class="floating-offer-label"
  [offer]="offer"
>
</wl-offer-label>

<div
  class="d-flex flex-column flex-grow-1 card border-0 card--offer"
  *ngIf="offer"
  [ngClass]="{
    'card--offer__highlight border-top-0 rounded-0 rounded-bottom':
      offer?.isHighlighted,
  }"
>
  <div
    class="flex-1 d-flex flex-column card-header__product p-2 border border-bottom-0"
    [class.border-top-0]="offer?.isHighlighted"
    [class.rounded-top]="!offer?.isHighlighted"
  >
    <ng-container *ngIf="offer.incentiveImage">
      <div class="offer__sticker-container">
        <ng-container
          *ngIf="
            fields?.StickerPopover?.value;
            then withPopover;
            else withoutPopover
          "
        ></ng-container>
        <ng-template #withPopover>
          <img
            class="img-popover"
            [src]="offer?.incentiveImage"
            [alt]="offer?.incentiveTitle"
            ngbPopover="{{ fields?.StickerPopover?.value }}"
            wlAutomationId="incentive-sticker"
          />
        </ng-template>
        <ng-template #withoutPopover>
          <img
            wlAutomationId="incentive-sticker"
            [src]="offer?.incentiveImage"
            [alt]="offer?.incentiveTitle"
          />
        </ng-template>
      </div>
    </ng-container>

    <div class="card-header__title" wlCSCapture>
      <fa-icon icon="plug" class="fa-icon"></fa-icon>
      {{ productTitle }}
    </div>
    <div class="card-header__subtitle" *ngIf="subtitle" wlCSCapture>
      {{ subtitle }}
    </div>
    <div *ngIf="showMoreIncentiveInfoButton" [class.invisible]="isCashback">
      <button
        class="btn btn-link card-header__incentive-info-link p-0"
        (click)="onShowIncentiveInfo(offer)"
      >
        {{ fields?.ShowMoreIncentiveInfoLabel?.value }}
      </button>
    </div>
  </div>

  <wl-offer-usps
    *ngIf="offer.usps && offer.usps?.length"
    [usps]="offer.usps"
    class="flex-grow-1 p-2 border-bottom"
  >
  </wl-offer-usps>
  <wl-offer-breakdown
    [offer]="offer"
    [rendering]="rendering"
    [hideIncentiveWhenUavailable]="true"
    class="flex-1 pt-2 px-2"
  ></wl-offer-breakdown>
  <wl-offer-budgetbill
    [rendering]="rendering"
    [offer]="offer"
    [hasMemberGetMember]="hasMemberGetMember"
    class="flex-1 px-2"
  ></wl-offer-budgetbill>
  <div class="m-2" *ngIf="showMoreInfoBlock">
    <wl-offer-more-info-block
      [rendering]="rendering"
      [offer]="offer"
      [moreInfoButtonClick]="moreInfoButtonClick"
    ></wl-offer-more-info-block>
  </div>

  <div class="flex-1 px-2">
    <wl-toggle-with-info
      *ngIf="addOn && fields?.ShowProductAddons?.value"
      [rendering]="rendering"
      [title]="{ value: addOn?.title }"
      [description]="{ value: addOn?.description }"
      [tooltip]="addOn?.tooltip"
      toggleId="alternative-offer"
      [checked]="!!isAddOnChecked"
      (toggle)="onAddOnToggle($event)"
      [containerClass]="!!isAddOnChecked ? 'border-success' : ''"
    >
    </wl-toggle-with-info>
  </div>

  <div class="flex-1 p-2">
    <button
      class="btn btn-primary mb-1 d-block w-100"
      wlAutomationId="offer-submit-button"
      *scText="fields?.OfferSubmitLabel"
      (click)="selectProposition(offer)"
      type="button"
      wlTrackImpression="button-impression"
      [trackImpressionInfo]="rendering"
      [trackImpressionName]="rendering?.fields?.OfferSubmitLabel?.value"
      [trackImpressionLinkurl]="rendering?.fields?.OrderPage?.value.href"
      trackImpressionMode="button"
      wlTrackLink
      [trackLinkInfo]="{
        rendering: rendering,
        field: rendering?.fields?.OfferSubmitLabel?.value,
        eventType: 'button-click',
        linkurl: rendering?.fields?.OrderPage?.value.href,
      }"
      wlCSCapture
    ></button>

    <div
      class="offer__submit-sub-label text-center mt-1 mb-2"
      *scText="rendering?.fields?.SubmitSubLabel"
      wlCSCapture
    ></div>

    <div class="text-center">
      <button
        wlTrackLink
        [trackLinkInfo]="{
          rendering: { componentName: 'contractdetail' },
          field: 'toon prijs samenstelling',
          eventType: 'link-click',
        }"
        (click)="openAomModal.emit(offer)"
        wlAutomationId="offer-trigger-aom-button"
        class="btn btn-link p-0 mb-2 text-primary"
        *scText="rendering?.fields?.TarrifModalLinkText"
        wlCSCapture
      ></button>
    </div>
  </div>
</div>
