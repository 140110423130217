import type { Actions } from '@ngrx/effects';
import { ofType } from '@ngrx/effects';
import { MarkAsSubmittedAction } from 'ngrx-forms';
import { filter } from 'rxjs/operators';

export const ofFormSubmitAction = (formId: string) => (source$: Actions) =>
  source$.pipe(
    ofType<MarkAsSubmittedAction>(MarkAsSubmittedAction.TYPE),
    filter((action) => action.controlId === formId)
  );
