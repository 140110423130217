import { createSelector } from '@ngrx/store';

import { eplusInsulationFeature } from '../insulation-form.state';
import { insulationApiKey } from './insulation-api.reducer';

const selectInsulationApiState = createSelector(
  eplusInsulationFeature,
  (state) => state[insulationApiKey]
);

/**
 * Address check endpoint
 */

export const selectGetInsulationNewCustomerAddressCheckState = createSelector(
  selectInsulationApiState,
  (state) => state.getInsulationNewCustomerAddressCheck
);

export const selectGetInsulationNewCustomerAddressCheckData = createSelector(
  selectGetInsulationNewCustomerAddressCheckState,
  (state) => state.data
);

export const selectInsulationProductsWithCalculations = createSelector(
  selectGetInsulationNewCustomerAddressCheckData,
  (state) => state?.products
);

/**
 * Product endpoint
 */

export const selectGetInsulationProductsState = createSelector(
  selectInsulationApiState,
  (state) => state.getInsulationProducts
);

/**
 * Confirmation endpoint
 */

export const selectPostInsulationConfirmationState = createSelector(
  selectInsulationApiState,
  (state) => state.postInsulationNewCustomerConfirmation
);
