import type { FormGroupState } from 'ngrx-forms';

export enum AddressTypes {
  ADDRESS = 'address',
  POBOX = 'pobox',
}

export interface FormAddress {
  addressType: `${AddressTypes}`;
  postalCode: string;
  communicationNumber: number;
  city: string;
  houseNumberAddition: string;
  street: string;
  countryCode: string;
}

export type AddressFormState = FormGroupState<FormAddress>;

export type AddressValidationResult = 'ERROR' | 'NOT_FOUND' | 'FOUND';

export interface AddressControlIds {
  addressFormId: string;
  communicationNumberControlId: string;
  houseNumberAdditionControlId: string;
  postalCodeControlId: string;
  streetControlId: string;
  cityControlId: string;
  addressTypeControlId: string;
  countryCodeControlId: string;
}
