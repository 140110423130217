import { inject, Injectable } from '@angular/core';
import { WINDOW } from '@innogy/utils-dom';
import { ENVIRONMENT_CONFIG } from '@core/config-angular';
import { PlatformService } from '@core/platform';
import { LocationService } from '@core/jss-seo';

import type {
  Alloy,
  AlloyPayload,
  AlloyProposition,
  AlloySendEventResponse,
} from './adobe-web-sdk.model';
import { XdmBuilderService } from './xdm-builder.service';

export const SDK_INSTANCE_NAME = 'alloy';
declare global {
  interface Window {
    [SDK_INSTANCE_NAME]?: Alloy;
  }
}

/**
 * This service is an adapter for the Adobe Web SDK, providing a purpose-made interface.
 * New usecases of interactins with the SDK can be registered in this service.
 * It takes care of lazy initialization of the SDK and proxies all other calls that outside services need
 * access to.
 */
@Injectable({
  providedIn: 'root',
})
export class AdobeWebSDKService {
  #sdk?: Alloy;

  readonly #window = inject(WINDOW);
  readonly #envConfig = inject(ENVIRONMENT_CONFIG);
  readonly #sdkConfig = this.#envConfig.adobeWebSDK;
  readonly #locationService = inject(LocationService);
  readonly #platform = inject(PlatformService);
  readonly #XDMBuilder = inject(XdmBuilderService);

  /**
   * checks the client configuration and platform to see whether or not the SDK is allowed to be enabled.
   * @returns boolean indicating whether or not the SDK should be enabled for this client.
   */
  public isEnabled() {
    return (this.#sdkConfig.enabled && this.#platform.isClient()) ?? false;
  }

  /**
   * Lazily initializes the Adobe Web SDK.
   * @returns an observable that events flow through.
   */
  public init() {
    if (this.isEnabled()) {
      return this.#loadSDK();
    }
    return Promise.resolve(false);
  }

  /**
   * returns a reference to the actual web SDK instance.
   * @returns reference to the actual web SDK.
   */
  public get ref(): Alloy {
    if (!this.#sdk) {
      throw new Error(
        'You are attempting to access the web SDK while it is not instantiated yet.'
      );
    }
    return this.#sdk;
  }

  public applyPropositions(payload: AlloyPayload) {
    return this.ref('applyPropositions', payload);
  }

  public fetchPropositionsForTest(testName: string) {
    return this.#sendEventEnhanced({
      renderDecisions: false,
      decisionScopes: [testName],
    });
  }

  public markPropositionAsDisplayed(payload: AlloyProposition[]) {
    return this.#sendEventEnhanced({
      xdm: {
        eventType: 'decisioning.propositionDisplay',
        _experience: {
          decisioning: {
            propositions: payload.map((proposition) => ({
              id: proposition.id,
              scope: proposition.scope,
              scopeDetails: proposition.scopeDetails,
            })),
            propositionEventType: {
              display: 1,
            },
          },
        },
      },
    });
  }

  /**
   * temporary function to send a basic linkClick event to adobe analytics.
   */
  public sendAnalyticsEvent(interactionName: string, formName: string) {
    return this.ref('sendEvent', {
      xdm: {
        eventType: 'web.webinteraction.linkClicks',
        web: {
          webInteraction: {
            name: interactionName,
            type: 'link',
          },
        },
        data: {
          customLinkType: 'o',
          formName,
          formStep: 'Form Confirmation',
        },
        documentUnloading: true,
      },
    });
  }

  async #loadSDK() {
    // We use a CJS import here because adobe does not expose typings and
    // using the MJS version causes immense problems in our project setup;
    // importing this way can localize the references to alloy within this library.
    const { createInstance } = await require('@adobe/alloy');
    this.#sdk = createInstance({ name: SDK_INSTANCE_NAME }) as Alloy;
    // Call the ref directly so we can unit test this assignment.
    this.ref('configure', {
      personalizationStorageEnabled: false,
      context: ['web', 'device', 'environment', 'placeContext'],
      targetMigrationEnabled: true,
      thirdPartyCookiesEnabled: true,
      idMigrationEnabled: true,
      clickCollectionEnabled: true,
      downloadLinkQualifier:
        '\\.(exe|zip|wav|mp3|mov|mpg|avi|wmv|pdf|doc|docx|xls|xlsx|ppt|pptx)$',
      debugEnabled: false,
      // We are allowed to opt-in to consent for sending events to the adobe Web SDK by default.
      // Filtering of what events are to be forwarded and not to be forwarded is to be done in the edge configuration.
      // @see https://experienceleague.adobe.com/en/docs/experience-platform/web-sdk/commands/setconsent
      // @see https://business.gov.nl/regulation/cookies/
      defaultConsent: 'in',
      datastreamId: this.#sdkConfig.datastreamId,
      edgeDomain: this.#getEdgeDomain(),
      orgId: '0E57467352783B530A490D45@AdobeOrg',
      onBeforeEventSend: (content: Record<string, any>) => {
        if (content['xdm'].web?.webReferrer) {
          delete content['xdm'].web.webReferrer.URL;
        }
      },
    });
    // We have to manually link the sdk to the global window namespace to prevent issues in
    // the adobe web sdk hooks. Can't make this shit up. :')
    this.#window[SDK_INSTANCE_NAME] = this.#sdk;
    return this.#sdk;
  }

  /**
   * internal method that enhances sendEvent calls with custom XDM dimensions
   * @param payload Alloy Payload.
   * @returns Enriched alloy payload with custom XDM dimensions.
   */
  #sendEventEnhanced(payload: AlloyPayload) {
    return this.ref('sendEvent', {
      ...payload,
      xdm: this.#XDMBuilder.enhanceSchemaWithPageInfo(payload['xdm']),
    }) as Promise<AlloySendEventResponse>;
  }

  /**
   * Adobe web sdk cannot handle localhost for edgeDomain so for local testing we use the test environment
   */
  #getEdgeDomain(): string {
    if (
      this.#locationService.origin.includes('localhost') ||
      this.#locationService.origin.includes('127.0.0.1')
    ) {
      let domain = 'essent';
      const brand = this.#envConfig.brand;
      if (brand === 'ed') {
        domain = 'energiedirect';
      }
      if (brand === 'energiewonen') {
        domain = 'energiewonen';
      }
      return `mock.tst.${domain}.nl`;
    }
    return new URL(this.#locationService.origin).host;
  }
}
