import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';

import { setPartnerIdAction } from './partner-id.actions';

export interface PartnerIdState {
  partnerId?: string;
}

const _reducer = createReducer(
  {},
  on(setPartnerIdAction, (state, { partnerId }) => ({ ...state, partnerId }))
);

export function partnerIdReducer(state: PartnerIdState = {}, action: Action) {
  return _reducer(state, action);
}
