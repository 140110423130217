import { Component, Input } from '@angular/core';
import { TextField } from '@sitecore-jss/sitecore-jss-angular';

@Component({
  selector: 'wl-content-card',
  templateUrl: './content-card.component.html',
  styleUrls: [],
})
export class ContentCardComponent {
  @Input()
  title?: TextField;
  @Input()
  body?: TextField;
  @Input()
  automationId?: string;
}
