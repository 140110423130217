import type { TariffBreakdownState } from '@innogy/shared/tariff-breakdown/models';
import type { ActionReducerMap } from '@ngrx/store';
import { createFeatureSelector } from '@ngrx/store';

import {
  tariffBreakdownComponentKey,
  tariffBreakdownReducer,
} from './tariff-breakdown.reducer';

export const tariffBreakdownSelectorKey = 'tariff-breakdown';

export interface TariffBreakdownReducerState {
  readonly [tariffBreakdownComponentKey]: TariffBreakdownState;
}

export const tariffBreakdownReducers: ActionReducerMap<TariffBreakdownReducerState> =
  {
    [tariffBreakdownComponentKey]: tariffBreakdownReducer,
  };

export const tariffBreakdownFeature =
  createFeatureSelector<TariffBreakdownReducerState>(
    tariffBreakdownSelectorKey
  );
