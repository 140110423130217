import type { KeyValue } from 'ngrx-forms';

import type { ProgressiveFormGroupState } from '../state';
import { progressiveFormStateReducer } from '../reducer';
import { MarkAsInertAction } from '../actions';

export function markAsInert<TValue extends KeyValue>(
  state: ProgressiveFormGroupState<TValue>
) {
  return progressiveFormStateReducer(state, new MarkAsInertAction(state.id));
}
