import { Component, Input } from '@angular/core';
import type {
  BaseComponentFields,
  InnogyComponentRendering,
  ScLinkField,
} from '@core/jss-models';
import { getFieldValue } from '@core/jss-utils';
import type {
  ComponentFields,
  TextField,
} from '@sitecore-jss/sitecore-jss-angular';

export type FooterUpperComponentFields = BaseComponentFields & {
  appButtonsTitle: TextField;
  appButtonsAndroidLink: ScLinkField;
  appButtonsAndroidLinkTrackingField: TextField;
  appButtonsIosLink: ScLinkField;
  appButtonsIosLinkTrackingField: TextField;
};

export type FooterUpperRendering = InnogyComponentRendering & {
  fields: FooterUpperComponentFields;
};

@Component({
  selector: 'wl-footer-upper[rendering]',
  templateUrl: './footer-upper.component.html',
  styleUrls: [
    './footer-upper.component.essent.scss',
    './footer-upper.component.ed.scss',
  ],
})
export class FooterUpperComponent {
  @Input() rendering!: FooterUpperRendering;

  get showAppButtons(): boolean {
    return getFieldValue<boolean>(
      this.rendering,
      'appButtonsShouldShow',
      false
    );
  }

  get fields(): ComponentFields {
    return this.rendering.fields;
  }
}
