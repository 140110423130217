<wl-experience-editor>
  <ng-template wlLive>
    <ng-container *ngIf="placement$ | async as placement">
      <wl-button
        [config]="config"
        [rendering]="rendering"
        wlTrackImpression="button-impression"
        trackImpressionMode="button"
        [trackImpressionName]="config?.text"
        [trackImpressionLinkurl]="config?.url"
        [trackImpressionInfo]="rendering"
      ></wl-button>
    </ng-container>
  </ng-template>
  <ng-template wlPreview>
    <ng-container
      *ngIf="config?.icon; then btnIcon; else btnDefault"
    ></ng-container>

    <ng-template #btnIcon>
      <a role="button" class="btn btn--icon-prefixed" [ngClass]="config?.class">
        <em [ngClass]="config?.icon"></em>
        <span *scText="rendering?.fields?.text"></span>
      </a>
    </ng-template>
    <ng-template #btnDefault>
      <a
        role="button"
        class="btn btn-primary"
        [ngClass]="config?.class"
        *scText="rendering?.fields?.text"
      ></a>
    </ng-template>
  </ng-template>
</wl-experience-editor>
