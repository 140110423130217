import type { AsyncState } from '@essent/common';
import type { EstimatedConsumption } from '@essent/estimate-consumption';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const commonUiSalesSelectorKey = 'common-ui-sales';

export type EstimateConsumptionByHouseTypeState =
  AsyncState<EstimatedConsumption>;

export interface CommonUiSalesState {
  estimateConsumptionByHouseType: EstimateConsumptionByHouseTypeState;
}

export const selectCommonUiSales = createFeatureSelector<CommonUiSalesState>(
  commonUiSalesSelectorKey
);

export const getEstimateConsumption = createSelector(
  selectCommonUiSales,
  (state) => state.estimateConsumptionByHouseType
);
