import { provideBootstrapEffects } from '@innogy/utils-state';

import { ChannelRecognitionEffects } from './channel-recognition.effects';

export * from './channel-recognition-actions';
export * from './channel-recognition.selectors';

export const channelRecognitionProviders = provideBootstrapEffects([
  ChannelRecognitionEffects,
]);
