import type { KeyValue } from 'ngrx-forms';

import type { NGRXProgressiveFormsActions } from '../actions';
import { ActivateFormStepAction } from '../actions';
import type { ProgressiveFormGroupState } from '../state';

export function activeReducer<TValue extends KeyValue>(
  state: ProgressiveFormGroupState<TValue>,
  action: NGRXProgressiveFormsActions
): ProgressiveFormGroupState<TValue> {
  if (action.type !== ActivateFormStepAction.TYPE) {
    return state;
  }

  if (action.stepId !== state.id && state.isActive) {
    return {
      ...state,
      isActive: false,
      isInactive: true,
    };
  }

  if (action.stepId !== state.id) {
    return state;
  }

  if (state.isActive) {
    return state;
  }

  return {
    ...state,
    isVisited: true,
    isUnvisited: false,
    isActive: true,
    isInactive: false,
  };
}
