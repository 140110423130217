<div class="order-overview-ed__card card rounded">
  <div class="card-body p-2">
    <ng-container *ngIf="offer; else loading">
      <div class="d-flex justify-content-between align-items-center">
        <p class="h4 text-break mb-0">{{ offer?.productTitle }}</p>
        <wl-offer-overview-change-offer
          *ngIf="rendering?.fields?.showEditButton?.value"
          [rendering]="rendering"
          class="ms-1"
        ></wl-offer-overview-change-offer>
      </div>

      <p
        class="mt-3 mb-0"
        *scText="rendering?.fields?.SummaryCalculationBasedOn"
      ></p>
      <wl-order-overview-consumption
        class
        *ngIf="consumptionValuesVM"
        [consumptionValues]="consumptionValuesVM"
      ></wl-order-overview-consumption>
      <hr />
      <wl-order-overview-budget-bill
        [beforeDiscountYearlyLabel]="
          rendering?.fields?.BeforeDiscountExpectedYearlyLabel
        "
        [beforeDiscountYearlyAmount]="offer.beforeDiscountExpectedYearlyAmount"
        [cashbackAmount]="offer.discountPrice"
        [cashbackLabel]="rendering?.fields?.IncentiveLabel"
        [yearlyLabel]="yearlyCostLabel"
        [yearlyAmount]="offer.expectedYearlyAmount"
        [monthlyAmount]="offer.expectedMonthlyAmount"
        [monthlyAmountLabel]="rendering?.fields?.MonthlyAmountLabel"
        [monthlyAmountTooltip]="rendering?.fields?.MonthlyAmountTooltip?.value"
        [budgetBillAmount]="offer.budgetBillAmount"
        [budgetBillLabel]="rendering?.fields?.BudgetBillSuggestion"
        [budgetBillTooltip]="
          rendering?.fields?.BudgetBillSuggestionTooltip?.value
        "
      ></wl-order-overview-budget-bill>

      <div
        class="my-3 rounded-3 text-center order-overview-ed__mgm-badge"
        *ngIf="displayMGM((hasMemberGetMember$ | async) || false)"
      >
        {{
          rendering?.fields?.MemberGetMemberSharedValue?.value || ''
            | interpolate
              : {
                  variables: {
                    mgmValue: mgmSharedValue | roundedCurrency,
                  },
                }
        }}
      </div>

      <hr />
      <div>
        <div class="d-flex mb-3 mt-3 justify-content-center">
          <button
            class="p-0 btn btn-link text-primary"
            [wlTrackLink]="rendering?.fields?.AOMModalLinkText?.value"
            [trackLinkInfo]="{
              rendering: {
                componentName: 'product detail',
              },
              field: rendering?.fields?.AOMModalLinkText?.value,
              eventType: 'link-click',
            }"
            wlAutomationId="show-tarifs"
            (click)="showTarifs.emit(offer)"
            *scText="rendering?.fields?.AOMModalLinkText"
          ></button>
          <span
            *ngIf="!!rendering?.fields?.AOMModalTooltip?.value"
            class="d-flex align-items-center ps-2"
          >
            <wl-tooltip
              [tooltip]="{
                description: rendering?.fields?.AOMModalTooltip?.value,
              }"
            ></wl-tooltip>
          </span>
        </div>
        <div *ngIf="offer.usps?.length">
          <div class="rounded p-2 order-overview-ed__usps">
            <wl-offer-usps
              *ngIf="offer.usps && offer.usps.length"
              [usps]="offer.usps"
            ></wl-offer-usps>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #loading>
  <wl-svg-skeleton-loader [mask]="skeletonLoaderMask"> </wl-svg-skeleton-loader>
</ng-template>
