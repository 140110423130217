<wl-modal
  (closeModal)="dismissModal()"
  [modalTitle]="modalTitle?.value"
  wlTrackImpression="contentblock-impression"
  trackImpressionMode="any"
  [trackImpressionInfo]="{
    name: modalTitle?.value,
    type: 'popup',
  }"
>
  <ng-template wlModalBody>
    <wl-rich-text-wrapper wlAutomationId="wl-modal-body" [field]="modalMessage">
      <p class="text-small text-start ms-2 mb-0"></p>
    </wl-rich-text-wrapper>
  </ng-template>
</wl-modal>
