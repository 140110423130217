<wl-partial-card
  *ngIf="showStep"
  class="mb-2 progressive-form-card"
  [divider]="isActive || (isValid && isSubmitted) || isReadonly"
  [class.valid-and-submitted]="(isValid && isSubmitted) || isReadonly"
  [class.untouched]="isFutureStep || isSkipped"
  [wlAutomationId]="formStepId"
  [id]="formStepId"
>
  <ng-container slot="header">
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <h3 class="h4 my-0 d-flex align-items-center" wlCSCapture>
          <fa-icon
            *ngIf="(isValid && isSubmitted) || isReadonly"
            class="me-1"
            icon="circle-check"
          ></fa-icon>
          {{ title }} {{ progression }}
        </h3>
      </div>
      <div>
        <button
          class="ms-2 btn btn-outline-secondary btn-sm"
          *ngIf="canBeEdited && !isReadonly"
          [wlAutomationId]="changeButtonAutomationId"
          (click)="setEditingFormStep.emit()"
          wlCSCapture
        >
          <span class="d-none d-sm-block">{{ changeButtonText }}</span>
          <span class="d-block d-sm-none">
            <fa-icon icon="pen"></fa-icon>
          </span>
        </button>
      </div>
    </div>
    <p *ngIf="isActive" class="my-0" wlCSCapture>{{ subtitle }}</p>
  </ng-container>
  <ng-container slot="content">
    <ng-content></ng-content>
  </ng-container>
</wl-partial-card>
