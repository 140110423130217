<div
  class="d-flex segmented-control"
  wlAutomationId="segmented-control w-100 d-flex"
>
  <button
    *ngFor="let entry of entries; trackBy: trackById"
    (click)="clicked(entry)"
    class="segmented-control__item d-flex justify-content-center rounded"
    [class.segmented-control__item--selected]="selected?.id === entry.id"
    [class.fw-bold]="selected?.id === entry.id"
    [wlAutomationId]="'segmented-control-item-' + entry.id"
  >
    <ng-container *ngIf="entry.icon; else text">
      <span class="d-flex justify-content-center align-items-center">
        <fa-icon class="d-block mx-1" [icon]="entry.icon"></fa-icon>
        <span class="segmented-control__item__text">{{ entry.text }}</span>
      </span>
    </ng-container>

    <ng-template #text>
      {{ entry.text }}
    </ng-template>
  </button>
</div>
