import { Component, inject } from '@angular/core';
import { UtilsEnvironmentModule } from '@innogy/utils/environment';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ENVIRONMENT_CONFIG } from '@core/config-angular';

import { CONSENT_REASON } from '../consent.service';

@Component({
  selector: 'wl-cookiewall',
  standalone: true,
  imports: [UtilsEnvironmentModule],
  templateUrl: './cookiewall.component.html',
  styleUrls: ['./cookiewall.component.scss'],
})
export class CookiewallComponent {
  modal = inject(NgbActiveModal);
  readonly #brand = inject(ENVIRONMENT_CONFIG).app.replace('mijn-', '');

  brandName = this.#brand[0].toUpperCase() + this.#brand.slice(1);

  accept() {
    this.modal.close(CONSENT_REASON.ACCEPT);
  }

  reject() {
    this.modal.close(CONSENT_REASON.REJECT);
  }
}
