import type { EstimatedConsumption } from '@essent/estimate-consumption';
import { BusinessType } from '@innogy/become-a-customer/shared/interfaces';

export const estimateBusinessConsumption = (
  businessType?: BusinessType
): EstimatedConsumption => {
  switch (businessType) {
    case BusinessType.ZZP:
      return {
        electricity: { total: 3500, high: 1750, low: 1750 },
        gas: 1700,
      };
    case BusinessType.EENMANSZAAK:
      return {
        electricity: { total: 6500, high: 3250, low: 3250 },
        gas: 2000,
      };
    case BusinessType.WINKEL:
      return {
        electricity: { total: 16000, high: 8000, low: 8000 },
        gas: 4000,
      };
    case BusinessType.KANTOOR:
      return {
        electricity: { total: 50000, high: 25000, low: 25000 },
        gas: 10000,
      };
    case BusinessType.INDUSTRIEEL:
      return {
        electricity: { total: 70000, high: 35000, low: 35000 },
        gas: 20000,
      };
    default:
      return {
        electricity: { total: 0, high: 0, low: 0 },
        gas: 0,
      };
  }
};
