import type { ToolTrackingConfig } from '@innogy/core/analytics';
import { createToolStepConfig, parseToolConfig } from '@innogy/core/analytics';

import { selectHeatpumpsQuestionnaireAnalyticsPayload } from '../heatpumps-questionnaire';

const heatpumpsOrderFormSteps = [
  {
    step: 0,
    stepName: '- adresgegevens',
    id: 'heatpumps-address-data',
  },
  {
    step: 1,
    stepName: '- persoonlijke gegevens',
    id: 'heatpumps-personal-details',
  },
  {
    step: 2,
    stepName: '- bevestigen',
    id: 'heatpumps-confirm',
  },
] as const;

export type HeatpumpsOrderFormToolTrackingSteps =
  (typeof heatpumpsOrderFormSteps)[number]['id'];

const heatpumpsOrderFormToolTrackingConfig: ToolTrackingConfig<HeatpumpsOrderFormToolTrackingSteps> =
  {
    steps: heatpumpsOrderFormSteps,
    toolComplete: createToolStepConfig(
      selectHeatpumpsQuestionnaireAnalyticsPayload,
      (payload) => ({
        step: 3,
        payload,
      })
    ),
  };

export const parsedHeatpumpsOrderFormToolTrackingConfig = parseToolConfig({
  ...heatpumpsOrderFormToolTrackingConfig,
  toolName: 'warmtepomp check order form',
});
