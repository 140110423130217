import { NgModule } from '@angular/core';

import { LoadFontsComponent } from './load-fonts.component';

@NgModule({
  imports: [],
  exports: [LoadFontsComponent],
  providers: [],
  declarations: [LoadFontsComponent],
})
export class CoreLoadFontsModule {}
