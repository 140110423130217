import type { OnDestroy } from '@angular/core';
import { Directive, HostListener, Input } from '@angular/core';
import type {
  RichTextField,
  TextField,
} from '@sitecore-jss/sitecore-jss-angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import type {
  AnalyticsActionFieldTypes,
  ToolTrackValues,
} from './track-tool.model';
import { AnalyticsActionTypes } from './track-tool.model';
import { TrackToolService } from './track-tool.service';

/**
 * This directive will track changes for a specific form component.
 * This directive is intended for text input fields.
 *
 * @param field reference to the text field
 * @param sendValue Option to include the value
 * @param hashValue Option to apply a hashing algorithm to the provided value
 * @param inputLabel The description of the selected value
 * @param step The step of the tool
 */
interface TrackFieldfocusModel extends ToolTrackValues {
  field: TextField | RichTextField | string;
  sendValue: boolean;
  hashValue: boolean;
  type: AnalyticsActionFieldTypes;
}

@Directive({
  selector: '[wlTrackFieldfocus]',
})
export class TrackFieldfocusDirective implements OnDestroy {
  private hasFiredFocus = false;
  private hasFiredChange = false;
  private previousValue?: string;

  private readonly defaultValues: TrackFieldfocusModel = {
    sendValue: false,
    hashValue: false,
    field: '',
    step: 0,
    type: AnalyticsActionTypes.FIELD_FOCUS,
  };

  private readonly onDestroy$ = new Subject();

  @Input('wlTrackFieldfocus')
  trackFieldfocus: TrackFieldfocusModel = this.defaultValues;

  getValues = () => ({
    ...this.defaultValues,
    ...this.trackFieldfocus,
  });

  @HostListener('focus') onFocus() {
    if (this.hasFiredFocus) {
      return;
    }
    this.hasFiredFocus = true;
    this.defaultValues.type = AnalyticsActionTypes.FIELD_FOCUS;
    this.triggerAngularTics(undefined);
  }

  @HostListener('focusout', ['$event.target.value']) onBlur(value: string) {
    const { sendValue } = this.getValues();

    // If we don't want to send the value, fire the analytics event only once.
    if (!sendValue && this.hasFiredChange) {
      return;
    }

    if (sendValue && !this.valueHasChangedAndIsNotEmpty(value)) {
      return;
    }

    this.hasFiredChange = true;
    this.previousValue = value;
    this.defaultValues.type = AnalyticsActionTypes.FIELD_COMPLETE;
    this.triggerAngularTics(sendValue ? value : undefined);
  }

  private triggerAngularTics(value: string | undefined) {
    const values = this.getValues();

    const trackingValues = this.trackToolService.getTrackValues(values);
    const extraprops = this.trackToolService.getExtraProperties(
      value,
      values,
      values.field
    );

    return this.trackToolService
      .trackTool(trackingValues, extraprops)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe();
  }

  constructor(private readonly trackToolService: TrackToolService) {}

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  private valueHasChangedAndIsNotEmpty(value: string) {
    return this.previousValue !== value && value.length > 0;
  }
}
