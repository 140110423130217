import isString from 'lodash/isString';

import type { GetPartnerIdQuery, PartnerIdConfig } from './interfaces';
import { Channel } from './interfaces';

/* eslint-disable complexity */
export function determinePartnerId(
  { params = {}, path, referrer = '' }: GetPartnerIdQuery,
  config: PartnerIdConfig
) {
  const ecmp = params['ecmp'] ?? '';

  if (Array.isArray(ecmp)) {
    throw new Error(
      `Expecting 1 ECMP query parameter while ${ecmp.length} are received: ${ecmp}`
    );
  }

  if (!isString(ecmp)) {
    throw new Error(
      `Expecting the ECMP query parameter to be a string: ${ecmp}`
    );
  }

  if (ecmp.startsWith('sea:nbs:')) {
    return extractPartnerIdFromConfig(config, Channel.PaidSearchNonBrand);
  } else if (ecmp.startsWith('sea:brs:')) {
    return extractPartnerIdFromConfig(config, Channel.PaidSearchBrand);
  } else if (ecmp.startsWith('dis:')) {
    return extractPartnerIdFromConfig(config, Channel.Display);
  } else if (
    ecmp.startsWith('soc:') ||
    matchWithReferrers(config.socialReferrers, referrer)
  ) {
    return extractPartnerIdFromConfig(config, Channel.Social);
  } else if (ecmp.startsWith('ema:')) {
    return extractPartnerIdFromConfig(config, Channel.Email);
  } else if (matchAffiliate(ecmp, 'dai')) {
    return extractPartnerIdFromConfig(config, Channel.DaisyconAlgemeen);
  } else if (matchAffiliate(ecmp, 'dav')) {
    return extractPartnerIdFromConfig(config, Channel.DaisyconVergelijker);
  } else if (matchAffiliate(ecmp, 'eanu')) {
    return extractPartnerIdFromConfig(
      config,
      Channel.DaisyconEnergieAanbiedingenNu
    );
  } else if (matchAffiliate(ecmp, 'eaco')) {
    return extractPartnerIdFromConfig(
      config,
      Channel.DaisyconEnergieAanbiedingenCom
    );
  } else if (params['mgmco_saleid']) {
    return extractPartnerIdFromConfig(config, Channel.MemberGetMember);
  } else if (matchWithReferrers(config.searchReferrers, referrer)) {
    return extractPartnerIdFromConfig(config, Channel.OrganicSearch);
  } else if (path && path.indexOf('/psv') > -1) {
    return extractPartnerIdFromConfig(config, Channel.PSV);
  } else {
    return undefined;
  }
}
/* eslint-enable complexity */

export function determineECMPHeader(config: PartnerIdConfig, ecmp: string) {
  return config.ecmpHeaders.find((e) => ecmp.includes(e.ecmp));
}

export function extractPartnerIdFromConfig(
  config: PartnerIdConfig,
  channel: Channel
) {
  const match = config.partnerIdMap.find((e) => e.channel === channel);
  return match?.extPartnerId;
}

function matchWithReferrers(referrers: string[], match: string) {
  try {
    const matchAsURL = new URL(match);
    if (match && matchAsURL) {
      for (const referrer of referrers) {
        // Check if the parsed URL host equals ([www|*]).referrer
        if (
          matchAsURL.host === referrer ||
          matchAsURL.host.split(/\.(.+)/)[1] === referrer
        ) {
          return true;
        }
      }
    }
    return false;
  } catch (e) {
    // If casting the match to a URL failed, return false
    return false;
  }
}

function matchAffiliate(ecmp: string, affiliate: string) {
  const affiliateMatcher = new RegExp(`:${affiliate}(?::|$)`);
  return ecmp.startsWith('aff:') && ecmp.match(affiliateMatcher);
}
