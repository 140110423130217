import type { Status } from '@essent/common';
import { createSelector } from '@ngrx/store';
import type { Entry } from '@innogy/utils-state';
import { getError } from '@innogy/utils-state';

import type { FunctionalErrorItemRendering } from './functional-errors.state';
import { selectFunctionalErrorFeature } from './functional-errors.state';

export const selectFunctionalErrorState = createSelector(
  selectFunctionalErrorFeature,
  (state) => state.functionalErrors
);

export const selectFunctionalErrorEntities = createSelector(
  selectFunctionalErrorState,
  (state) => state.entities
);

export const functionalDefaultError = {
  status: 'ERROR',
  entry: {
    fields: {
      ErrorIcon: {
        url: '/sitecore/content/platformwide-settings/theme/severity/danger',
        fields: {
          value: {
            value: 'danger',
          },
        },
      },
      ErrorTitle: {
        value: 'Oeps er gaat iets mis.',
      },
      ErrorMessage: {
        value: 'Probeer het later opnieuw',
      },
    },
  },
} as any as Entry<FunctionalErrorItemRendering>;

export const selectFunctionalEntity = (functionalItem: string) =>
  createSelector(selectFunctionalErrorEntities, (state) => {
    const entry = state[functionalItem];
    if (entry && getError(entry.status as Status)) {
      return functionalDefaultError;
    }
    return entry;
  });
