import { Component, Input } from '@angular/core';
import { Offer } from '@innogy/become-a-customer/shared/interfaces';

@Component({
  selector: 'essent-commodity-offer-product-breakdown-row',
  templateUrl: './commodity-product-breakdown-row.component.html',
  styleUrls: ['./commodity-product-breakdown-row.component.essent.scss'],
})
export class CommodityOfferEssentProductBreakdownRowComponent {
  @Input() offer?: Offer;

  @Input()
  public description?: string;
  @Input()
  public value!: number;
  @Input()
  public prefix = '';

  @Input()
  public rowStyle: 'regular' | 'discount' = 'regular';

  @Input()
  public textStyle: 'regular' | 'bold' = 'regular';

  public get backgroundClass(): { [key: string]: boolean } {
    return {
      'commodity-offer-breakdown-cell--discount': this.rowStyle === 'discount',
      'bg-light': this.rowStyle === 'regular',
      'fw-bold': this.textStyle === 'bold',
    };
  }
}
