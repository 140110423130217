<wl-composable-card [config]="cardConfig">
  <div class="row h-100" *ngIf="hasItemAlignedLeftOrRight; else basicContent">
    <div
      class="col-12 col-lg-6 order-1 mb-2 mb-lg-0"
      *ngIf="settings?.itemAlignment === 'left'"
    >
      <sc-placeholder
        name="basic-content"
        [rendering]="rendering"
      ></sc-placeholder>
    </div>
    <div class="col-12 col-lg-6 order-2">
      <ng-template *ngTemplateOutlet="basicContent"></ng-template>
    </div>
    <div
      class="col-12 col-lg-6 order-1 order-lg-3 mb-2 mb-lg-0"
      *ngIf="settings?.itemAlignment === 'right'"
    >
      <sc-placeholder
        name="basic-content"
        [rendering]="rendering"
      ></sc-placeholder>
    </div>
  </div>
</wl-composable-card>

<ng-template #basicContent>
  <wl-basic-content-partial-top-between
    [settings]="settings"
    [content]="content"
    [context]="context"
    class="h-100"
  >
    <sc-placeholder
      name="basic-content"
      [rendering]="rendering"
    ></sc-placeholder>
  </wl-basic-content-partial-top-between>
</ng-template>
