import type { State as SharedState } from '@innogy/utils-state';
import type { Action, ActionReducerMap } from '@ngrx/store';
import { createFeatureSelector } from '@ngrx/store';

import * as fromChangeCommunicationAddress from './change-communication-address-form.reducer';
import * as fromChangeContactPersonForm from './change-contact-person-form.reducer';
import * as fromChangePhoneNumberForm from './change-phone-number-form.reducer';
import * as fromAddress from './communication-address.reducer';
import * as fromContracts from './contract-addresses.reducer';
import * as fromCustomerConsent from './customer-consent/customer-consent.reducer';
import * as fromPreferences from './preferences.reducer';
import * as fromPrices from './prices.reducer';
import * as fromReimbursements from './reimbursements.reducer';
import * as fromUpdate from './update-communication-address.reducer';

export interface CustomerDetailsState {
  readonly communicationPreferences: fromPreferences.State;
  readonly correspondenceDetails: fromAddress.State;
  readonly contractAddresses: fromContracts.State;
  readonly update: fromUpdate.State;
  readonly changePhoneNumberForm: fromChangePhoneNumberForm.State;
  readonly changeCommunicationAddressForm: fromChangeCommunicationAddress.State;
  readonly changeContactPersonForm: fromChangeContactPersonForm.State;
  readonly prices: fromPrices.State;
  readonly reimbursements: fromReimbursements.State;
  readonly customerConsent: fromCustomerConsent.State;
}

export interface State extends SharedState {
  readonly customerDetails: CustomerDetailsState;
}

export const reducers: ActionReducerMap<CustomerDetailsState, Action> = {
  communicationPreferences: fromPreferences.consentsReducer,
  correspondenceDetails: fromAddress.correspondenceDetailsReducer,
  contractAddresses: fromContracts.contractAddressesReducer,
  update: fromUpdate.updateCommunicationAddressReducer,
  changePhoneNumberForm: fromChangePhoneNumberForm.reducer,
  changeCommunicationAddressForm: fromChangeCommunicationAddress.reducer,
  changeContactPersonForm: fromChangeContactPersonForm.reducer,
  prices: fromPrices.reducer,
  reimbursements: fromReimbursements.reducer,
  customerConsent: fromCustomerConsent.reducer,
};

export const customerDetailsSelectorKey = 'customerDetails';
export const getCustomerDetailsState =
  createFeatureSelector<CustomerDetailsState>(customerDetailsSelectorKey);
