import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';

import { setIsCustomerAction } from './is-customer.actions';

export interface IsCustomerState {
  isCustomer?: boolean;
}

const initialState: IsCustomerState = {
  isCustomer: undefined,
};

const _isCustomerReducer = createReducer(
  initialState,
  on(setIsCustomerAction, (state, action) => ({
    ...state,
    isCustomer: action.isCustomer,
  }))
);

export function isCustomerReducer(
  state: IsCustomerState = initialState,
  action: Action
): IsCustomerState {
  return _isCustomerReducer(state, action);
}
