import { EnergyType, Status } from '@essent/common';
import type { DynamicTariff } from '@essent/tariffs/dynamic-prices/interfaces/dynamic-prices.interface';
import { UnitOfMeasurement } from '@integration/base-models';

import { formatDate } from '../utils/format-date.util';

const generateDateTime = (index: number) => {
  const date = new Date();
  date.setMinutes(0);
  date.setHours(index);
  return date.toISOString();
};

const mockEntry = {
  tariffs: Array.from(
    { length: 24 },
    (_, index): DynamicTariff => ({
      startDateTime: generateDateTime(index),
      endDateTime: generateDateTime(index + 1),
      totalAmount: 0.3, // This is just a loading placeholder with a kind of average price
      groups: [],
      totalAmountVat: 0.004,
      totalAmountEx: 0.25,
    })
  ),
  minAmount: 0,
  maxAmount: 0,
  averageAmount: 0,
  unitOfMeasurement: UnitOfMeasurement.KWH,
  vatPercentage: 0,
  minAmountEx: 0,
  averageAmountEx: 0,
  maxAmountEx: 0,
};

export const dynamicPricesLoadingEntity = {
  status: Status.IDLE,
  id: 'loading',
  entry: {
    date: formatDate(new Date()),
    [EnergyType.ELECTRICITY]: {
      energyType: EnergyType.ELECTRICITY,
      ...mockEntry,
    },
    [EnergyType.GAS]: {
      energyType: EnergyType.GAS,
      ...mockEntry,
    },
  },
};
