import type { AsyncState } from '@essent/common';
import { Status, createAsyncStateReducer } from '@essent/common';
import type { CustomerConsents } from '@essent/customer';
import {
  getCustomerConsent,
  getCustomerConsentClear,
  getCustomerConsentError,
  getCustomerConsentSuccess,
  patchCustomerConsent,
  patchCustomerConsentClear,
  patchCustomerConsentError,
  patchCustomerConsentSuccess,
} from '@essent/customer';
import type { Action, ActionReducerMap } from '@ngrx/store';
import { combineReducers } from '@ngrx/store';

export type PatchCustomerConsentState = AsyncState<void>;
export type GetCustomerConsentState = AsyncState<CustomerConsents>;
export interface State {
  patchCustomerConsent: PatchCustomerConsentState;
  getCustomerConsent: GetCustomerConsentState;
}

export const initialState: State = {
  patchCustomerConsent: {
    status: Status.IDLE,
  },
  getCustomerConsent: {
    status: Status.IDLE,
  },
};

const getReducer = createAsyncStateReducer(
  getCustomerConsent,
  getCustomerConsentSuccess,
  getCustomerConsentError,
  getCustomerConsentClear
);

const patchReducer = createAsyncStateReducer(
  patchCustomerConsent,
  patchCustomerConsentSuccess,
  patchCustomerConsentError,
  patchCustomerConsentClear
);

const _reducers: ActionReducerMap<State, Action> = {
  patchCustomerConsent: patchReducer,
  getCustomerConsent: getReducer,
};

const _combinedReducers = combineReducers(_reducers);

export function reducer(state = initialState, action: Action): State {
  return _combinedReducers(state, action);
}
