<ngb-accordion [closeOthers]="true">
  <ngb-panel *ngFor="let category of rendering?.fields?.Categories">
    <ng-template ngbPanelHeader>
      <button class="accordion-button" ngbPanelToggle>
        <wl-partial-accordion-title
          [icon]="category.fields?.Icon | scIcon"
          [title]="category.fields?.LabelName?.value"
        ></wl-partial-accordion-title>
      </button>
    </ng-template>

    <ng-template ngbPanelContent>
      <div
        class="p-2 d-flex"
        *ngFor="let categoryItem of category.fields?.Items"
        (click)="navigateToLink.emit(categoryItem.fields?.link.value.href)"
      >
        <fa-icon
          *ngIf="categoryItem.fields?.icon?.value"
          class="my-auto me-2"
          [icon]="categoryItem.fields?.icon | scIcon"
          [fixedWidth]="true"
        ></fa-icon>
        <div *wlGenericRichText="categoryItem.fields?.title"></div>
      </div>
    </ng-template>
  </ngb-panel>
</ngb-accordion>
