import { Component, Input } from '@angular/core';

@Component({
  selector: 'wl-partial-sticker-header',
  templateUrl: './sticker-header.component.html',
  styleUrls: [
    './sticker-header.component.essent.scss',
    './sticker-header.component.ed.scss',
  ],
})
export class StickerHeaderComponent {
  @Input() title?: string;
  @Input() subtitle?: string;
  @Input() sticker?: Partial<HTMLImageElement>;
}
