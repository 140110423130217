import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DeployUrlPipe } from './deploy-url.pipe';

const EXPORTS = [DeployUrlPipe];

@NgModule({
  imports: [CommonModule],
  declarations: [...EXPORTS],
  exports: [...EXPORTS],
})
export class UtilsEnvironmentModule {}
