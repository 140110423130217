import { format } from 'date-fns';
import { nl } from 'date-fns/locale';

import { DateFnsPipe } from '../pipes/date-fns.pipe';

export function getTrueMonthNumberByDate(date: string) {
  return new Date(date).getMonth() + 1;
}

export function getDateOffset(days = 0, months = 0, years = 0): Date {
  const d = new Date();
  return new Date(
    d.getFullYear() + years,
    d.getMonth() + months,
    d.getDate() + days,
    d.getHours(),
    d.getMinutes(),
    d.getSeconds(),
    d.getMilliseconds()
  );
}

/**
 * Compares two dates
 * @param date1 Date
 * @param date2 Date
 * @returns return 1 if date1 is a later date than date2,
 * returns -1 if date2 is later than date 1
 * returns 0 if dates are equal
 */
export function compareDates(date1: Date, date2: Date): number {
  if (date1 > date2) {
    return 1;
  }
  if (date2 > date1) {
    return -1;
  }
  if (date1.getTime() === date2.getTime()) {
    return 0;
  }
  return NaN;
}

const yyyymmdd = /^([12]\d{3}-(0?[1-9]|1[0-2])-(0?[1-9]|[12]\d|3[01]))$/;
const ddmmyyyy = /^(0?[1-9]|[12]\d|3[01])-(0?[1-9]|1[0-2])-([1-9]\d{3})$/;
const ddMMMMyyyy =
  /^(([0-9])|([0-2][0-9])|([3][0-1]))[,\s][a-zA-Z]{3,}[,\s]\d{4}$/;
const outputDateFormat = 'yyyy-MM-dd';

/*
 * return valid yyyy-MM-dd or dd-mm-yyyy dateString in yyyy-MM-dd format
 * For invalid dates return empty string.
 */
export const normalizeDateString = (dateString: string): string => {
  const dateFormatter = new DateFnsPipe();

  if (yyyymmdd.test(dateString)) {
    return (
      dateFormatter.transform(dateString, outputDateFormat, outputDateFormat) ??
      ''
    );
  } else if (ddmmyyyy.test(dateString)) {
    return (
      dateFormatter.transform(dateString, outputDateFormat, 'dd-MM-yyyy') ?? ''
    );
  } else if (ddMMMMyyyy.test(dateString)) {
    return (
      dateFormatter.transform(
        dateString,
        outputDateFormat,
        'dd MMMM yyyy',
        nl
      ) ?? ''
    );
  }

  return '';
};

export const formatDateToYearMonthDay = (date: Date) =>
  format(date, 'yyyy-MM-dd');

export const todayApiString = () => formatDateToYearMonthDay(new Date());

/**
 * This function will format the user input to a dd-MM-yyyy date format (or part of it)
 *  e.g.
 *    010 -> 01-0
 *    01-012 -> 01-01-2
 *
 * @param input Unformatted user input
 */
export function transformDateInput(input = ''): string {
  const insertString = (
    inputString: string,
    insertion: string,
    index: number
  ) =>
    inputString.substring(0, index) + insertion + inputString.substring(index);
  let output;
  const yearInput = /\d{2}-\d{2}-\d/;

  // If user inputs 'dd-' or 'mm-' just return the input as is
  if (/\d{2}-$/.test(input) && !yearInput.test(input)) {
    output = input;
  } else if (/(\d)-$/.test(input) && !yearInput.test(input)) {
    // If user inputs 'd-' or 'dd-m-' reformat to '0d-' and 'dd-0m-'
    output = input.replace(/([1-9])-$/, '0$1-');
  } else {
    // If user does not type any dashes, automatically insert dashes between dd-mm-yyyy
    const cleanString = input.replace(/\D/g, '') || ''; // Removes all non-numeric characters
    const size = cleanString.length;
    output = cleanString.substring(0, 8); // Limit to 8 digits

    // Insert dash after input
    if (size > 4) {
      output = insertString(output, '-', 4);
    }
    if (size > 2) {
      output = insertString(output, '-', 2);
    }
  }
  return output;
}

export const getTodaysDate = () => toMidnightTime(new Date());

export const toMidnightTime = (date: Date) => {
  // New date to guarantee immutability
  const newDate = new Date(date);
  newDate.setHours(0);
  newDate.setMinutes(0);
  newDate.setSeconds(0);
  newDate.setMilliseconds(0);
  return newDate;
};

export const stripTimezoneFromISOServerDate = (dateFromServer: string) => {
  return dateFromServer.slice(0, 'yyyy-MM-ddThh:mm:ss'.length);
};

export function durationSecondsToHhMm(duration: number): string {
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);

  let result = '';

  if (hours > 0) {
    result += `${hours} uur`;
  }

  if (minutes > 0) {
    result += result.length > 0 ? ' en ' : '';
    result += minutes === 1 ? '1 minuut' : `${minutes} minuten`;
  }

  return result.length > 0 ? result : '0 minuten';
}
