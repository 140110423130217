import { Component, Input } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'wl-partial-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: [
    './list-item.component.essent.scss',
    './list-item.component.ed.scss',
  ],
})
export class ListItemComponent {
  @Input() label?: string;
  @Input() iconLeading?: IconProp;
  @Input() iconTrailing?: IconProp;
  @Input() active? = false;
}
