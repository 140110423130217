import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import debounce from 'lodash/debounce';

import { ButtonSize, ButtonTheme, ButtonVariant } from '../../models';

@Component({
  selector: 'wl-partial-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  // States
  @Input() disabled = false;
  @Input() loading = false;

  // Behaviour
  @Input() role?: 'link' | 'button';
  @Input() type: 'button' | 'reset' | 'submit' = 'submit';
  @Input() href?: string;
  @Input() target: '_self' | '_blank' | '_parent' | '_top' = '_self';
  @Input() rel?: string;
  @Input() anchor?: string;
  /**
   * With `debounceClick = true`, the (click) emitter will be capped to 1 per 250ms.
   * `debounceClick` can also be set to a number to modify the default 250ms.
   */
  @Input() debounceClick: number | boolean = false;

  // Accessibility
  @Input() ariaLabel?: string;
  @HostBinding('[attr.aria-disabled]') get isDisabled() {
    return this.disabled || this.loading;
  }

  // Styles
  @Input() size: ButtonSize = 'default';
  @Input() theme: ButtonTheme = 'primary';
  @Input() context: ButtonVariant = 'default';
  @Input() fullWidth = false;
  @Input() block = false;
  @Input() active = false;

  @Output() clicked = new EventEmitter<MouseEvent>();

  @ViewChild('buttonElement') buttonElement?: ElementRef<HTMLButtonElement>;

  private get clickDebounceTime() {
    return typeof this.debounceClick === 'number' ? this.debounceClick : 500;
  }

  /**
   * With lodash debounce it eliminates the delay from click to action. With `leading: true` it will
   * emit `click` immediately, but only a second time when clicked again after 250ms.
   */
  private readonly clickDebouncer = debounce(
    (event: MouseEvent) => this.clicked.emit(event),
    this.clickDebounceTime,
    { leading: true, trailing: false }
  );

  get ariaLabelFallback(): string | undefined {
    return this.buttonElement?.nativeElement.innerText;
  }

  /**
   * Workaround to make buttons work with the
   * currently used bootstrap classes for ED restyling.
   * When moving to component library, remove this coupling with bootstrap
   * and base styles on component inputs solely.
   */
  get computedStyle() {
    const inferredContext = this.context === 'default' ? '' : this.context;
    const inferredClass = `btn-${inferredContext ? inferredContext + '-' : ''}${
      this.theme
    }`;

    return `btn ${inferredClass}`;
  }

  onClick(event: MouseEvent): void {
    if (this.debounceClick) {
      this.clickDebouncer(event);
    } else {
      this.clicked.emit(event);
    }
  }
}
