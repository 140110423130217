import { createSelector } from '@ngrx/store';

import { selectStoreProducts } from '../store-api';
import { selectStoreFunnelSettings } from '../store-funnel';

export const selectStoreProductOverviewViewModel = createSelector(
  selectStoreProducts,
  selectStoreFunnelSettings,
  (products, { selectedProducts }) => ({
    products,
    selectedProducts,
  })
);
