import { createServiceApi, serviceTypes } from '@essent/common';
import type { LeadFormData } from '@integration/emobility-api-models';

const apiVersion = '1';
export const postLeadFormApi = createServiceApi(
  '[PostLeadFormState] Post lead form',
  'post',
  serviceTypes<LeadFormData, void>(),
  {
    getUrl: (_input: LeadFormData) => `/emobility/lead/v${apiVersion}`,
    version: apiVersion,
    defaultHeaders: {
      Accept: 'application/json',
    },
    getBody: (input: LeadFormData) => input,
  }
);

export const {
  postLeadFormActions,
  postLeadForm,
  postLeadFormClear,
  postLeadFormError,
  postLeadFormSuccess,
} = postLeadFormApi;
