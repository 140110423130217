import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { FilterEntry } from './filter.model';

@Component({
  selector: 'wl-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.essent.scss', './filter.component.ed.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterComponent<T> {
  @Input()
  entries?: FilterEntry<T>[];
  @Input()
  selected?: FilterEntry<T>;

  @Input()
  styleIsStretched = false;

  @Output()
  entrySelected = new EventEmitter<T>();

  clicked(entry: FilterEntry<T>) {
    this.selected = entry;
    this.entrySelected.emit(entry.value);
  }

  trackById(_: number, entry: FilterEntry<T>) {
    return entry.id;
  }
}
