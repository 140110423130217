import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';
import type { AbstractControlState } from 'ngrx-forms';

@Pipe({
  name: 'isInvalid',
})
export class IsInvalidPipe<T> implements PipeTransform {
  transform(
    formGroupControl: AbstractControlState<T>,
    checkDirty = true
  ): boolean {
    return (
      formGroupControl == null ||
      ((!checkDirty || formGroupControl.isDirty) &&
        formGroupControl.isTouched &&
        formGroupControl.isInvalid)
    );
  }
}
