<div
  class="card availability"
  wlTrackImpression="contentblock-impression"
  [trackImpressionInfo]="rendering"
  [trackImpressionContext]="context"
>
  <div class="card-body d-flex">
    <fa-icon
      *ngIf="icon"
      class="px-2"
      [ngClass]="{
        availability__open: isOpen,
        availability__closed: !isOpen,
      }"
      [icon]="icon | scIcon"
    ></fa-icon>

    <div>
      <div *ngIf="isOpen">
        <div
          class="availability__open"
          *scText="rendering?.fields?.OpenText"
        ></div>
      </div>
      <div *ngIf="!isOpen">
        <div
          class="availability__closed"
          *scText="rendering?.fields?.ClosedText"
        ></div>
      </div>
      <div class="availability__additional" *ngIf="additionalText">
        {{ additionalText }}
      </div>
    </div>
  </div>
</div>
