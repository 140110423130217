import { Component, Input } from '@angular/core';
import type { LinkField } from '@sitecore-jss/sitecore-jss-angular';

@Component({
  selector: 'wl-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent {
  @Input()
  list?: LinkField[];

  @Input()
  ulClass?: string;

  @Input()
  liClass?: string;
}
