import { Component, Input } from '@angular/core';

@Component({
  selector: 'wl-vertical-offer-sticker',
  templateUrl: './vertical-offer-sticker.component.html',
  styleUrls: [
    './vertical-offer-sticker.component.ed.scss',
    './vertical-offer-sticker.component.essent.scss',
  ],
})
export class VerticalOfferStickerComponent {
  @Input()
  stickerTitle?: string;

  @Input()
  stickerSource?: string;
}
