import type { ComponentNameAndModule } from '@sitecore-jss/sitecore-jss-angular';

const loadChildren = () =>
  import('./lib/reviews-lazy.module').then((mod) => mod.ReviewsLazyModule);

const components = [
  'ReviewScoreWidget',
  'Review',
  'ReviewEntry',
  'ReviewOverview',
];

export const reviewsLazyImports: ComponentNameAndModule[] = components.map(
  (name) => ({ path: name, loadChildren })
);
