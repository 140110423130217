import { Component, Input } from '@angular/core';
import { TextField } from '@sitecore-jss/sitecore-jss-angular';

@Component({
  selector: 'wl-order-overview-cost',
  templateUrl: './order-overview-cost.component.html',
  styleUrls: ['./order-overview-cost.component.scss'],
})
export class OrderOverviewCostComponent {
  @Input()
  label?: TextField;
  @Input()
  cost?: number;
  @Input()
  hasDiscount = false;
  @Input()
  boldLabel = false;
  @Input()
  tooltip = '';
}
