import { Inject, Injectable } from '@angular/core';
import {
  GenericModalResults,
  GenericModalSources,
} from '@innogy/common-ui/shared/interfaces';
import { WINDOW } from '@innogy/utils-dom';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, tap } from 'rxjs/operators';

import { closeGenericModal } from '../generic-modal.actions';

@Injectable()
export class ExitFunnelModalEffects {
  constructor(
    private readonly actions$: Actions,
    @Inject(WINDOW) private readonly windowService: any
  ) {}

  public readonly secondaryExitFunnelModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(closeGenericModal),
        filter(
          (action) =>
            action.source === GenericModalSources.EXIT_FUNNEL &&
            action.result === GenericModalResults.PRIMARY
        ),
        tap((action) => {
          const { href } = action.additionalPayload?.exitUrl || {};
          if (href) {
            this.windowService.window.location.href = href;
          }
        })
      ),
    { dispatch: false }
  );
}
