import { provideBootstrapEffects } from '@innogy/utils-state';

import { AOMModalEffects } from './aom';
import { ConsumptionEanMismatchModalEffects } from './consumption-ean-mismatch.effects';
import { NoOffersModalEffects } from './no-offers-modal.effects';

export * from './aom';
export * from './consumption-ean-mismatch.effects';
export * as incompatiblePromotionModalEffects from './incompatible-promotion/incompatible-promotion-modal.effects';
export * from './no-offers-modal.effects';

export const modalEffects = [
  NoOffersModalEffects,
  ConsumptionEanMismatchModalEffects,
  AOMModalEffects,
];

export const modalProviders = [provideBootstrapEffects([])];
