import type { RouterAction, RouterReducerState } from '@ngrx/router-store';
import { routerReducer } from '@ngrx/router-store';
import type { ActionReducerMap, MetaReducer, RuntimeChecks } from '@ngrx/store';

import { extMetaReducers } from './build-specifics';

export interface State {
  router: RouterReducerState;
}

export const routerSelectorKey = 'router';

// TODO remove any, see https://github.com/ngrx/platform/issues/951 (https://gitlab.essent.nl/Sitecoreplus/Frontend/issues/87)
export const reducers: ActionReducerMap<State, RouterAction<any> | any> = {
  router: routerReducer,
};

export const runtimeChecks: RuntimeChecks = {
  strictStateImmutability: false, // TODO: change back to true
  strictActionImmutability: true,
  strictStateSerializability: false,
  strictActionSerializability: false,
  strictActionWithinNgZone: false,
};

export const metaReducers: MetaReducer<State>[] = [...extMetaReducers];
