<div class="receipt-breakdown-row d-flex justify-content-between">
  <p class="receipt-breakdown-row--content-left flex-grow-1">
    <ng-container *ngTemplateOutlet="leftProjection"></ng-container>
  </p>
  <p
    class="receipt-breakdown-row--content-right flex-grow-1 text-end"
    *ngIf="rightProjection"
  >
    <ng-container *ngTemplateOutlet="rightProjection"></ng-container>
  </p>
</div>
<hr class="receipt-breakdown-row--separator" *ngIf="separator" />
