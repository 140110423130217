import type { ModuleWithProviders, Type } from '@angular/core';
import { NgModule } from '@angular/core';
import { Angulartics2Module } from 'angulartics2';
import { CookieService } from 'ngx-cookie-service';

import { AnalyticsSetupComponent } from './analytics-setup/analytics-setup.component';
import { CoreAnalyticsFeatureModule } from './core-analytics-feature.module';
import { CoreAnalyticsRootModule } from './core-analytics-root.module';
import { AnalyticsEnhancerSources } from './provider/analytics-enhancer-sources.service';
import type { AnalyticsEnhancer } from './provider/analytics-enhancer.model';
import {
  FEATURE_ANALYTICS_ENHANCERS,
  ROOT_ANALYTICS_ENHANCERS,
} from './provider/analytics-enhancer.model';
import { Angulartics2AdobeLaunchService } from './shared/angulartics2-adobe-launch.service';
import { StripSymbolsPipe } from './shared/strip-symbols.pipe';
import { TrackAccordionDirective } from './track-accordion/track-accordion.directive';
import { TrackFeedbackDirective } from './track-feedback/track-feedback.directive';
import { TrackFeedbackService } from './track-feedback/track-feedback.service';
import { TrackFilterDirective } from './track-filter/track-filter.directive';
import { TrackImpressionDirective } from './track-impression/track-impression.directive';
import { TrackLinkDirective } from './track-link/track-link.directive';
import { TrackLinkService } from './track-link/track-link.service';
import { TrackSearchResultDirective } from './track-sitesearch/track-search-result.directive';
import { TrackSearchDirective } from './track-sitesearch/track-search.directive';
import { TrackErrorDirective } from './track-tool/track-error.directive';
import { TrackFieldfocusDirective } from './track-tool/track-fieldfocus.directive';
import { TrackStepDirective } from './track-tool/track-step.directive';
import { TrackToolService } from './track-tool/track-tool.service';
import { TrackValueChangeDirective } from './track-tool/track-value-change.directive';
import { TrackProductDirective } from './track-product/track-product.directive';

const exportableDeclarations = [
  TrackAccordionDirective,
  TrackErrorDirective,
  TrackFieldfocusDirective,
  TrackFilterDirective,
  TrackImpressionDirective,
  TrackLinkDirective,
  TrackSearchDirective,
  TrackSearchResultDirective,
  TrackStepDirective,
  TrackValueChangeDirective,
  TrackFeedbackDirective,
  TrackProductDirective,
  StripSymbolsPipe,
];

@NgModule({
  declarations: [...exportableDeclarations, AnalyticsSetupComponent],
  providers: [CookieService],
  exports: [
    ...exportableDeclarations,
    AnalyticsSetupComponent,
    Angulartics2Module,
  ],
})
export class CoreAnalyticsModule {
  static forFeature(
    featureEnhancers: Type<AnalyticsEnhancer>[]
  ): ModuleWithProviders<CoreAnalyticsFeatureModule> {
    return {
      ngModule: CoreAnalyticsFeatureModule,
      providers: [
        featureEnhancers,
        {
          provide: FEATURE_ANALYTICS_ENHANCERS,
          multi: true,
          deps: featureEnhancers,
          useFactory: createSourceInstances,
        },
      ],
    };
  }

  static forRoot(
    rootEnhancers: Type<AnalyticsEnhancer>[] = []
  ): ModuleWithProviders<CoreAnalyticsRootModule> {
    return {
      ngModule: CoreAnalyticsRootModule,
      providers: [
        AnalyticsEnhancerSources,
        Angulartics2AdobeLaunchService,
        TrackLinkService,
        TrackFeedbackService,
        TrackToolService,
        rootEnhancers,
        {
          provide: ROOT_ANALYTICS_ENHANCERS,
          deps: rootEnhancers,
          useFactory: createSourceInstances,
        },
      ],
    };
  }
}

export function createSourceInstances(...instances: AnalyticsEnhancer[]) {
  return instances;
}
