import type { UsageQuestionnaire } from '@innogy/eplus/models';
import { createAction, props } from '@ngrx/store';

export const setSolarPaybackEstimate = createAction(
  '[EplusSolarPayback] Set estimate',
  props<{ estimate: number }>()
);

export const submitSolarPaybackUsage = createAction(
  '[EplusSolarPayback] Submit energy usage for calculation',
  props<{ energyUsage: number }>()
);

export const openSolarPaybackEstimateModal = createAction(
  '[EplusSolarPayback] Open estimate modal',
  props<{ modalRendering: { fields: UsageQuestionnaire } }>()
);

export const closeSolarPaybackEstimateModal = createAction(
  '[EplusSolarPayback] Close estimate modal'
);
