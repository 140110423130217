import { DynamicTariffGroupTypes } from '@essent/tariffs';
import { format } from 'date-fns';
import { EnergyType } from '@essent/common';
import type { DynamicPricesDate } from '@essent/tariffs/dynamic-prices/interfaces/dynamic-prices.interface';
import { UnitOfMeasurement } from '@integration/base-models';

export const createTariffMock = (
  totalAmount: number,
  hour: number,
  date: string
) => ({
  startDateTime: `${date}T${String(hour).padStart(2, '0')}:00:00.000`,
  endDateTime: `${date}T${String(hour + 1).padStart(2, '0')}:00:00.000`,
  totalAmount,
  totalAmountEx: totalAmount / 1.21,
  totalAmountVat: totalAmount - totalAmount / 1.21,
  groups: [
    {
      description: 'Beursprijs',
      type: DynamicTariffGroupTypes.MARKET_PRICE,
      amount: -0.092,
      amountEx: -0.076,
      amountVat: -0.016,
    },
    {
      description: 'Inkoopvergoeding',
      type: DynamicTariffGroupTypes.PURCHASING_FEE,
      amount: 0.02,
      amountEx: 0.0165,
      amountVat: 0.0035,
    },
    {
      description: 'Energiebelasting',
      type: DynamicTariffGroupTypes.TAX,
      amount: -0.0111,
      amountEx: -0.0092,
      amountVat: -0.0019,
    },
  ],
});

export const dynamicPricesByDateResponseMock = (
  inputDate: string
): DynamicPricesDate => {
  const date = format(inputDate, 'yyyy-MM-dd');
  return {
    date,
    electricity: {
      energyType: EnergyType.ELECTRICITY,
      unitOfMeasurement: UnitOfMeasurement.KWH,
      vatPercentage: 21,
      /* eslint-disable @typescript-eslint/no-loss-of-precision */
      tariffs: Array.from({ length: 24 }, (_, index) => index).map((index) =>
        createTariffMock(index / 24, index, date)
      ),
      maxAmount: 0.2340435753803702,
      averageAmount: 0.1720435753803702,
      minAmount: -0.003661238053027791,
      minAmountEx: -0.003019822314049587,
      averageAmountEx: 0.1418698014876033,
      maxAmountEx: 0.1932511415525114,
    },
    gas: {
      energyType: EnergyType.GAS,
      unitOfMeasurement: UnitOfMeasurement.M3,
      vatPercentage: 21,
      tariffs: Array.from({ length: 24 }, (_, index) => index).map((index) =>
        createTariffMock(1 / 24, index, date)
      ),
      maxAmount: 0.29196214331864134,
      averageAmount: 0.19196214331864134,
      minAmount: 0.018180419387317705,
      minAmountEx: 0.015000349489256198,
      averageAmountEx: 0.15867768595041322,
      maxAmountEx: 0.21739130434782608,
    },
  };
};
