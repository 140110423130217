/**
 * DO NOT USE THIS METHOD.
 * Get the name of the first control that should be focused.
 * This is used by the progressive form to focus an input after uncollapsing a step.
 * @deprecated Will be removed together with the progressive form.
 * @returns The input with attribute autofocus or the first input of the form.
 */
export const getFirstFocusControlName = (
  document: Document,
  formId?: string
): string | undefined => {
  const form = !formId ? undefined : document.getElementById(formId);

  let input = form?.querySelector(
    'input[autofocus]:not([autofocus="off"])'
  ) as HTMLInputElement;
  input = input ?? (form?.querySelector('input') as HTMLInputElement);

  return input?.name;
};
