import type { AsyncState } from '@essent/common';
import {
  createAsyncStateReducer,
  createInitialAsyncState,
} from '@essent/common';
import type { StoreProductData } from '@innogy/eplus/temporary-core-modules';
import {
  getStoreProductsActions,
  postStoreOrderActions,
} from '@innogy/eplus/temporary-core-modules';
import type { Action, ActionReducerMap } from '@ngrx/store';
import { combineReducers } from '@ngrx/store';

export const storeApiKey = 'storeAPI';

export type GetStoreProductsState = AsyncState<{
  products: StoreProductData[];
}>;
export type PostStoreOrderState = AsyncState<void>;

export interface StoreAPIState {
  getStoreProducts: GetStoreProductsState;
  postStoreOrder: PostStoreOrderState;
}

export const initialStoreAPIState: StoreAPIState = {
  getStoreProducts: createInitialAsyncState(),
  postStoreOrder: createInitialAsyncState(),
};

const _getStoreProductsReducer = createAsyncStateReducer(
  getStoreProductsActions
);

const _postStoreOrderReducer = createAsyncStateReducer(postStoreOrderActions);

const _reducers: ActionReducerMap<StoreAPIState, Action> = {
  getStoreProducts: _getStoreProductsReducer,
  postStoreOrder: _postStoreOrderReducer,
};

const _combinedReducers = combineReducers(_reducers);
export const storeAPIReducer = (
  state: StoreAPIState = initialStoreAPIState,
  action: Action
): StoreAPIState => _combinedReducers(state, action);
