import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { ChattingEffects, chattingSelectorKey, reducers } from './+state';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(chattingSelectorKey, reducers),
    EffectsModule.forFeature([ChattingEffects]),
  ],
})
export class ChattingStoreModule {}
