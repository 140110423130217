import type { ToolTrackingConfig } from '@innogy/core/analytics';
import {
  createToolStepConfig,
  E2E_TRACKING_ID_KEY,
  parseToolConfig,
} from '@innogy/core/analytics';

import { InsulationProductTrackingConfig } from '../insulation-products/insulation-product-overview.analytics-config';
import { selectInsulationOrderFormAnalyticsPayload } from './insulation-order-form.selectors';

const insulationOrderFormSteps = [
  {
    step: 0,
    stepName: 'adres gegevens',
    id: 'adres gegevens',
  },
  {
    step: 1,
    stepName: 'persoonlijke gegevens',
    id: 'persoonlijke gegevens',
  },
  {
    step: 2,
    stepName: 'controleren',
    id: 'controleren',
  },
] as const;

export type InsulationOrderFormToolTrackingSteps =
  (typeof insulationOrderFormSteps)[number]['id'];

const insulationOrderFormToolTrackingConfig: ToolTrackingConfig<InsulationOrderFormToolTrackingSteps> =
  {
    steps: insulationOrderFormSteps,
    toolComplete: createToolStepConfig(
      selectInsulationOrderFormAnalyticsPayload,
      (payload) => ({
        step: 3,
        payload: {
          ['type gebouw']: payload.buildingType,
          ['producten']: payload.products.map((product) =>
            InsulationProductTrackingConfig.mapToCommerce(product)
          ),
          [E2E_TRACKING_ID_KEY]: payload.trackingId,
        },
      })
    ),
  };

export const parsedInsulationOrderFormToolTrackingConfig = (
  toolName?: string
) =>
  parseToolConfig({
    ...insulationOrderFormToolTrackingConfig,
    ...(toolName && { toolName }),
  });
