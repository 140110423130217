import { Component, Input } from '@angular/core';
import type {
  InnogyComponentRendering,
  BaseComponentFields,
} from '@core/jss-models';
import type {
  ComponentFields,
  TextField,
} from '@sitecore-jss/sitecore-jss-angular';

export type FooterLowerComponentFields = BaseComponentFields & {
  copyright: TextField;
};

export type FooterLowerRendering = InnogyComponentRendering & {
  fields: FooterLowerComponentFields;
};

@Component({
  selector: 'wl-footer-lower[rendering]',
  templateUrl: './footer-lower.component.html',
  styleUrls: [
    './footer-lower.component.essent.scss',
    './footer-lower.component.ed.scss',
  ],
})
export class FooterLowerComponent {
  @Input() rendering!: FooterLowerRendering;

  get fields(): ComponentFields {
    return this.rendering.fields;
  }
}
