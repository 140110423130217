import { NgModule } from '@angular/core';

import { NavigationSharedModule } from './navigation.shared.module';
import { StickyHeaderContainerComponent } from './sticky-header/sticky-header-container/sticky-header-container.component';
import { StickyHeaderComponent } from './sticky-header/sticky-header.component';
import { TopNavigationComponent } from './sticky-header/top-navigation/top-navigation.component';
import { AddNavCategorySummariesPipe } from './pipes/add-nav-category-summaries.pipe';

@NgModule({
  imports: [NavigationSharedModule],
  declarations: [
    StickyHeaderComponent,
    StickyHeaderContainerComponent,
    TopNavigationComponent,
    AddNavCategorySummariesPipe,
  ],
  exports: [StickyHeaderContainerComponent, TopNavigationComponent],
})
export class NavigationEssentModule {}
