import { Directive, HostBinding } from '@angular/core';

export const captureAttribute = 'data-cs-capture';

/**
 * Adds data-cs-capture attr to its host so all the data within the host will be captured/shown in Content Square.
 */
@Directive({
  selector: '[wlCSCapture]',
})
export class CSCaptureDirective {
  @HostBinding(`attr.${captureAttribute}`)
  isEnabled = true;
}
