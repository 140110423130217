import { createFeatureSelector, createSelector } from '@ngrx/store';

import type { CreateOnlineAccountState } from './create-online-account.reducer';

export const createOnlineAccountSelectorKey = 'createOnlineAccountForm';

export const getCreateOnlineAccountState =
  createFeatureSelector<CreateOnlineAccountState>(
    createOnlineAccountSelectorKey
  );

export const getCreateOnlineAccountFormState = createSelector(
  getCreateOnlineAccountState,
  (onlineAccountState) => onlineAccountState.formState
);
