import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import {
  ExperienceEditorComponent,
  ExperienceEditorLiveDirective,
  ExperienceEditorPreviewDirective,
} from './experience-editor/experience-editor.component';
import { ShowInExperienceEditorDirective } from './experience-editor/show-in-experience-editor.directive';
import { ShowInLiveDirective } from './experience-editor/show-in-live.directive';
import { ExperienceEditorFeedbackComponent } from './experience-editor-feedback/experience-editor-feedback.component';

@NgModule({
  imports: [CommonModule, StoreModule],
  declarations: [
    ExperienceEditorComponent,
    ExperienceEditorLiveDirective,
    ExperienceEditorPreviewDirective,
    ShowInExperienceEditorDirective,
    ShowInLiveDirective,
    ExperienceEditorFeedbackComponent,
  ],
  exports: [
    ExperienceEditorComponent,
    ExperienceEditorLiveDirective,
    ExperienceEditorPreviewDirective,
    ShowInExperienceEditorDirective,
    ShowInLiveDirective,
    ExperienceEditorFeedbackComponent,
  ],
})
export class CoreExperienceEditorModule {}
