import type { KeyValue } from 'ngrx-forms';
import { formGroupReducer } from 'ngrx-forms';

import type { NGRXFormsActions } from '../actions';
import type { ProgressiveFormGroupState } from '../state';

export function formStateReducer<TValue extends KeyValue>(
  state: ProgressiveFormGroupState<TValue>,
  action: NGRXFormsActions<any>
): ProgressiveFormGroupState<TValue> {
  const output = {
    ...state,
    formState: formGroupReducer(state.formState, action),
  };
  return output;
}
