import type { NgrxValueConverter } from 'ngrx-forms';

import { formatInitials } from './format-initials';

export type InitialsConverter = NgrxValueConverter<
  string | null,
  string | null
>;

export const initialsConverter = (): InitialsConverter => {
  let previous = ''; // Must be here because of executioncontext ???
  return {
    convertViewToStateValue: (value: string) => {
      const newValue = formatInitials(value, previous);
      previous = newValue;
      return newValue;
    },
    convertStateToViewValue: (value: string) => formatInitials(value),
  };
};
