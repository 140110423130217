import { Component, Input } from '@angular/core';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss-angular';

@Component({
  selector: 'wl-anchor',
  templateUrl: './anchor.component.html',
  styleUrls: ['./anchor.component.scss'],
})
export class AnchorComponent {
  @Input()
  rendering?: ComponentRendering;
}
