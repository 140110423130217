import type { ActionCreator, ReducerTypes } from '@ngrx/store';
import { createReducer } from '@ngrx/store';
import type { FormGroupState, KeyValue } from 'ngrx-forms';
import {
  MarkAsSubmittedAction,
  markAsDirty,
  markAsTouched,
  onNgrxFormsAction,
} from 'ngrx-forms';

import type { ProgressiveFormStateImplementor } from './state';
import {
  onProgressiveNGRXForms,
  onProgressiveNGRXFormsAction,
  wrapReducerWithProgressiveFormsUpdate,
} from './reducer';
import { SubmitFormStepAction } from './actions';
import { activateFormStep } from './update-function';

/***
 * creates a reducer that reduces all (progressive) NGRX forms events.
 * Additional update functions can be provided.
 * @note: this reducer will not automatically run validation on form state updates. If that's what you want,
 * you can use `createProgressiveNgrxFormReducerWithFormStateUpdate`
 */
export function createProgressiveNgrxFormReducer<
  TState extends ProgressiveFormStateImplementor<TFormValues>,
  TFormValues extends KeyValue,
>(
  initialState: TState,
  formGroupValidator: (state: TState) => FormGroupState<TFormValues>,
  ...additionalOnFns: ReducerTypes<TState, readonly ActionCreator<any, any>[]>[]
) {
  return createReducer(
    initialState,
    onProgressiveNGRXForms(),
    onNgrxFormsAction(MarkAsSubmittedAction, (state, action) => {
      if (action.controlId === state.progressiveForm.id) {
        return {
          ...state,
          progressiveForm: {
            ...state.progressiveForm,
            // We mark as touched and dirty so validation actually runs on all child controls.
            // this is the currently accepted UX pattern, on changes, update these rules.
            formState: markAsTouched(markAsDirty(formGroupValidator(state))),
          },
        };
      }
      return state;
    }),
    ...additionalOnFns
  );
}

/***
 * creates a reducer that reduces all (progressive) NGRX forms events and will run
 * the validator as an update function after the initial reducer.
 * This is useful when a form is expected to run validation on every form state update.
 * Additional update functions can be provided.
 */
export function createProgressiveNgrxFormReducerWithFormStateUpdate<
  TState extends ProgressiveFormStateImplementor<TFormValues>,
  TFormValues extends KeyValue,
>(
  initialState: TState,
  formGroupValidator: (state: TState) => FormGroupState<TFormValues>,
  ...additionalOnFns: ReducerTypes<TState, readonly ActionCreator<any, any>[]>[]
) {
  return wrapReducerWithProgressiveFormsUpdate(
    createProgressiveNgrxFormReducer(
      initialState,
      formGroupValidator,
      ...additionalOnFns
    ),
    (state) => formGroupValidator(state)
  );
}

/**
 * creates a reducerFn that activates the form step when a form step with the provided ID is submitted.
 * Only runs when the current step's state is unsubmitted.
 * @param stepId the unique step id of the form step based upon which we want to activate.
 */
export function activateProgressiveFormStepOnSubmissionOf<
  TValue extends KeyValue,
  TState extends ProgressiveFormStateImplementor<TValue>,
>(stepId: string) {
  return onProgressiveNGRXFormsAction(
    SubmitFormStepAction,
    (state: TState, action) => {
      if (action.stepId !== stepId || state.progressiveForm.isSubmitted) {
        return state;
      }
      return {
        ...state,
        progressiveForm: activateFormStep(state.progressiveForm),
      };
    }
  );
}
