import { createSelector } from '@ngrx/store';

import { getBecomeACustomerState } from '../../become-a-customer-feature.selector';

export const getInstallationDetailsState = createSelector(
  getBecomeACustomerState,
  (state) => state.installationDetails.getInstallations
);

export const putEanDetailsState = createSelector(
  getBecomeACustomerState,
  (state) => state.installationDetails.putEanDetails
);
