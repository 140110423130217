import { Component, Input } from '@angular/core';
import { RichTextField, TextField } from '@sitecore-jss/sitecore-jss-angular';

@Component({
  selector:
    'wl-order-overview-budget-bill[budgetBillLabel][monthlyAmountLabel]',
  templateUrl: './order-overview-budget-bill.component.html',
  styleUrls: [
    './order-overview-budget-bill.component.essent.scss',
    './order-overview-budget-bill.component.ed.scss',
  ],
})
export class OrderOverviewBudgetBillComponent {
  @Input()
  beforeDiscountYearlyLabel?: TextField;
  @Input()
  beforeDiscountYearlyAmount = 0;

  @Input()
  cashbackLabel?: TextField;
  @Input()
  cashbackAmount? = 0;

  @Input()
  yearlyLabel?: string;
  @Input()
  yearlyAmount = 0;

  @Input()
  monthlyAmountLabel!: RichTextField;
  @Input()
  monthlyAmountTooltip = '';
  @Input()
  monthlyAmount = 0;

  @Input()
  budgetBillLabel!: RichTextField;
  @Input()
  budgetBillTooltip = '';
  @Input()
  budgetBillAmount? = 0;

  @Input()
  showCostsInHeader = false;
}
