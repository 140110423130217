import type { OnDestroy } from '@angular/core';
import { Directive, HostListener, Input } from '@angular/core';
import type {
  RichTextField,
  TextField,
} from '@sitecore-jss/sitecore-jss-angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import type {
  AnalyticsActionFieldTypes,
  ToolTrackValues,
} from './track-tool.model';
import { AnalyticsActionTypes } from './track-tool.model';
import { TrackToolService } from './track-tool.service';

interface TrackFieldvalueModel extends ToolTrackValues {
  label: TextField | RichTextField | string;
  trackFocus: boolean;
  sendValue: boolean;
  hashValue?: boolean;
  inputLabel: TextField | RichTextField | string;
  type?: AnalyticsActionFieldTypes;
}

/**
 * This directive will track changes for a specific form component.
 * This directive is intended for components like radiobuttons, checkboxes and selects.
 *
 * Do NOT use this on a text input field, since every keystroke will trigger an analytics event.
 *
 * @param label The description of the field
 * @param sendValue Option to include the value
 * @param hashValue Option to apply a hashing algorithm to the provided value
 * @param inputLabel The description of the selected value
 * @param step The step of the tool
 */
@Directive({
  selector: '[wlTrackValueChange]',
})
export class TrackValueChangeDirective implements OnDestroy {
  private hasFiredFocus = false;
  private hasFiredChange = false;
  private previousValue?: string;

  private readonly defaultValues: TrackFieldvalueModel = {
    label: '',
    trackFocus: false,
    sendValue: false,
    hashValue: false,
    inputLabel: '',
    step: 0,
    type: AnalyticsActionTypes.FIELD_FOCUS,
  };

  @Input('wlTrackValueChange')
  trackValueChange: TrackFieldvalueModel = this.defaultValues;

  private readonly onDestroy$ = new Subject();

  getValues = () => ({
    ...this.defaultValues,
    ...this.trackValueChange,
  });

  @HostListener('focus') onFocus() {
    const { trackFocus } = this.getValues();
    if (this.hasFiredFocus || !trackFocus) {
      return;
    }
    this.hasFiredFocus = true;
    this.defaultValues.type = AnalyticsActionTypes.FIELD_FOCUS;
    setTimeout(() => {
      this.triggerAngularTics(undefined);
    });
  }

  @HostListener('change') onChange() {
    const { sendValue } = this.getValues();

    if (this.hasFiredChange && !sendValue) {
      return;
    }
    this.hasFiredChange = true;

    setTimeout(() => {
      const innerModel = this.getValues();
      const value = this.trackToolService.getValue(innerModel.inputLabel);
      this.defaultValues.type = AnalyticsActionTypes.FIELD_COMPLETE;
      if (this.previousValue !== value) {
        this.triggerAngularTics(innerModel.sendValue ? value : undefined);
        this.previousValue = value;
      }
    });
  }

  private triggerAngularTics(value: string | undefined) {
    const values = this.getValues();

    const trackingValues = this.trackToolService.getTrackValues(values);
    const extraprops = this.trackToolService.getExtraProperties(
      value,
      values,
      values.label
    );

    return this.trackToolService
      .trackTool(trackingValues, extraprops)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe();
  }

  constructor(private readonly trackToolService: TrackToolService) {}

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
