import { createSelector } from '@ngrx/store';

import { getBecomeACustomerState } from '../../become-a-customer-feature.selector';
import { getSupplyAddressViewModel } from './supply-address-vm';

export const getSupplyAddressState = createSelector(
  getBecomeACustomerState,
  (state) => state.supplyAddress.getSupplyAddress
);

export const getSupplyAddress = createSelector(
  getBecomeACustomerState,
  (state) => state.supplyAddress.getSupplyAddress.data
);

export const getSupplyAddressStatus = createSelector(
  getBecomeACustomerState,
  (state) => state.supplyAddress.getSupplyAddress.status
);

export const getSupplyAddressVM = createSelector(
  getBecomeACustomerState,
  (state) =>
    getSupplyAddressViewModel(state.supplyAddress.getSupplyAddress.data)
);

export const getSelectedSupplyInstallationAddressState = createSelector(
  getSupplyAddressState,
  (state) => state?.data
);
