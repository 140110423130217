import { createNamedApi } from '@essent/common';

import { PostCreateLeadService } from './post-create-lead.service';

export const postCreateLeadApi = createNamedApi(
  '[PostCreateLeadState] Post Create Lead',
  PostCreateLeadService
);

export const {
  postCreateLead,
  postCreateLeadActions,
  postCreateLeadClear,
  postCreateLeadError,
  postCreateLeadSuccess,
} = postCreateLeadApi;
