<div *ngIf="offer" class="d-flex flex-column">
  <div
    class="product-breakdown-table mb-2"
    [class.mb-3]="!content?.offerSubmitDescriptionLabel"
  >
    <ed-commodity-offer-product-breakdown-row
      [description]="content?.expectedYearlyCostLabel"
      [value]="offer.beforeDiscountExpectedYearlyAmount"
    ></ed-commodity-offer-product-breakdown-row>
    <ed-commodity-offer-product-breakdown-row
      *ngIf="offer.discountPrice"
      [description]="content?.cashbackLabel"
      rowStyle="discount"
      [prefix]="'-'"
      [value]="offer.discountPrice"
    ></ed-commodity-offer-product-breakdown-row>
    <ed-commodity-offer-product-breakdown-row
      *ngIf="offer.expectedYearlyAmount"
      [description]="yearlyCostsLabel(offer)"
      rowStyle="darker"
      textStyle="bold"
      [value]="offer.expectedYearlyAmount"
    ></ed-commodity-offer-product-breakdown-row>
  </div>

  <div
    class="d-flex justify-content-center"
    *ngIf="showMemberGetMember && content?.memberGetMemberSharedValue as value"
  >
    <div class="referral-sticker px-2">
      <span class="referral-sticker__text">
        {{
          value | interpolate: { variables: { mgmValue: getMGMValue(offer) } }
        }}
      </span>
    </div>
  </div>
  <p *ngIf="content?.offerSubmitDescriptionLabel">
    <span class="description">{{ content?.offerSubmitDescriptionLabel }}</span>
    <wl-tooltip
      *ngIf="!!content?.offerSubmitDescriptionTooltip"
      [tooltip]="{ description: content?.offerSubmitDescriptionTooltip }"
    ></wl-tooltip>
  </p>
</div>
