import {
  isNumeric,
  isPostalCode,
  onNgrxFormsControlId,
  useValidatorIf,
  validateSequential,
} from '@innogy/utils-deprecated';
import type { Action } from '@ngrx/store';
import { createReducer } from '@ngrx/store';
import type { StateUpdateFns } from 'ngrx-forms';
import {
  createFormGroupState,
  onNgrxForms,
  reset,
  ResetAction,
  setValue,
  updateGroup,
  wrapReducerWithFormStateUpdate,
  ALL_NGRX_FORMS_ACTION_TYPES,
  SetValueAction,
  UnfocusAction,
} from 'ngrx-forms';
import { maxLength, required } from 'ngrx-forms/validation';

import type {
  AddressControlIds,
  AddressFormState,
  FormAddress,
} from './address-form.types';
import { AddressTypes } from './address-form.types';

const isAddress = (addressType: `${AddressTypes}`) =>
  addressType === AddressTypes.ADDRESS;

export const getAddressValidator = (
  state: AddressFormState
): StateUpdateFns<FormAddress> => ({
  postalCode: validateSequential(required, isPostalCode),
  communicationNumber: validateSequential(required, isNumeric),
  houseNumberAddition: validateSequential(
    useValidatorIf(maxLength(6), isAddress(state.value.addressType))
  ),
  street: validateSequential(
    useValidatorIf(required, isAddress(state.value.addressType))
  ),
  city: validateSequential(required),
});

export const addressValidator = (state: AddressFormState) =>
  updateGroup<FormAddress>(state, getAddressValidator(state));

export const resetForAddress = (state: AddressFormState) =>
  reset(
    updateGroup<FormAddress>(state, {
      city: setValue(''),
      street: setValue(''),
      communicationNumber: setValue<number>(NaN),
      houseNumberAddition: setValue(''),
    })
  );

export const resetForPobox = (state: AddressFormState) =>
  reset(
    updateGroup<FormAddress>(state, {
      city: setValue(''),
      street: setValue(''),
      communicationNumber: setValue<number>(NaN),
      houseNumberAddition: setValue(''),
    })
  );

export const changeAddressTypeReducer = (
  state: AddressFormState,
  action: SetValueAction<any>
) => (isAddress(action.value) ? resetForAddress(state) : resetForPobox(state));

export const formatPostalCode = (state: AddressFormState) => {
  const formattedPostalcode = state.value.postalCode
    .replace(' ', '')
    .toUpperCase();

  return updateGroup(state, {
    postalCode: setValue(formattedPostalcode),
  });
};

export const defaultFormState: FormAddress = {
  street: '',
  communicationNumber: NaN,
  houseNumberAddition: '',
  postalCode: '',
  city: '',
  countryCode: 'NL',
  addressType: AddressTypes.ADDRESS,
};

export const getAddressFormControlIds = (
  addressFormId: string
): AddressControlIds => ({
  addressFormId,
  postalCodeControlId: `${addressFormId}.postalCode`,
  communicationNumberControlId: `${addressFormId}.communicationNumber`,
  houseNumberAdditionControlId: `${addressFormId}.houseNumberAddition`,
  streetControlId: `${addressFormId}.street`,
  cityControlId: `${addressFormId}.city`,
  countryCodeControlId: `${addressFormId}.countryCode`,
  addressTypeControlId: `${addressFormId}.addressType`,
});

export const createAddressFormState = (
  addressFormId: string,
  initialAddressState: FormAddress = defaultFormState
) => {
  const addressFormControlIds = getAddressFormControlIds(addressFormId);

  function resetReducer(state: AddressFormState) {
    return reset(setValue(state, initialAddressState));
  }

  const initialFormGroup = createFormGroupState(
    addressFormId,
    initialAddressState
  );

  const initialAddressFormState: AddressFormState =
    addressValidator(initialFormGroup);

  const _reducer = createReducer(
    initialAddressFormState,
    onNgrxForms(),
    onNgrxFormsControlId(ResetAction, addressFormId, resetReducer),
    onNgrxFormsControlId(
      UnfocusAction,
      addressFormControlIds.postalCodeControlId,
      formatPostalCode
    ),
    onNgrxFormsControlId(
      SetValueAction,
      addressFormControlIds.addressTypeControlId,
      changeAddressTypeReducer
    )
  );

  const wrappedReducer = wrapReducerWithFormStateUpdate(
    _reducer,
    (state) => state,
    (_, state) => addressValidator(state)
  );

  function addressReducer(
    state: AddressFormState = initialAddressFormState,
    action: Action
  ): AddressFormState {
    return wrappedReducer(state, action);
  }

  const onAddressFormAction = <T extends Record<string, any>>(
    selector: (state: T) => AddressFormState
  ) => {
    return {
      types: ALL_NGRX_FORMS_ACTION_TYPES,
      reducer: (state: T, action: Action) => {
        const formState = selector(state);
        const key = Object.keys(state).find((k) => state[k] === formState);

        return key == null
          ? state
          : { ...state, [key]: addressReducer(formState, action) };
      },
    };
  };

  return {
    addressReducer,
    initialAddressFormState,
    initialAddressState,
    onAddressFormAction,
    addressFormControlIds,
    addressValidator,
  };
};
