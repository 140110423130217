import type { HttpErrorResponse } from '@angular/common/http';
import { Status } from '@essent/common';
import type { ActionCreator, FunctionWithParametersType } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';

import type { StateStatus } from './state-status.model';

interface StateStatusActionConfig<T> {
  updateAction: ActionCreator;
  successAction: ActionCreator<
    string,
    FunctionWithParametersType<any, { payload: T }>
  >;
  errorAction: ActionCreator<
    string,
    FunctionWithParametersType<any, { payload: HttpErrorResponse }>
  >;
  clearAction: ActionCreator;
}

export function createStateStatusReducer<T>(
  config: StateStatusActionConfig<T>,
  initialState: StateStatus<T> = { status: Status.IDLE }
) {
  const reducer = createReducer<StateStatus<T>>(
    initialState,
    on(config.updateAction, (state) => ({
      ...state,
      status: Status.PENDING,
    })),
    on(config.successAction, (_, action) => ({
      status: Status.SUCCESS,
      data: action.payload,
    })),
    on(config.errorAction, (_, action) => ({
      status: Status.ERROR,
      error: action.payload,
    })),
    on(config.clearAction, (_) => initialState)
  );

  return { reducer, initialState };
}
