import { createAction, props } from '@ngrx/store';
import type { FormGroupState } from 'ngrx-forms';

import type { FormStep } from './progressive-form.model';

export const initProgressiveForm = createAction(
  `[Progressive Form] Initialize Progressive Form`,
  props<{
    formId: string;
  }>()
);

export const openFormStep = createAction(
  `[Progressive Form] Open Form Step`,
  props<{
    formId: string;
    stepId: string;
    stepFormState?: FormGroupState<any>;
    noScroll?: boolean;
    isEditing?: boolean;
  }>()
);

export const setActiveFormStep = createAction(
  `[Progressive Form] Set Active Form Step`,
  props<{
    formId: string;
    stepId: string;
    stepFormState?: FormGroupState<any>;
    noScroll?: boolean;
    isEditing?: boolean;
  }>()
);

export const setEditing = createAction(
  `[Progressive Form] Set Form Step Edit`,
  props<{ formId: string; editing: boolean }>()
);

export const cancelEditingFormStep = createAction(
  `[Progressive Form] Cancel Form Step Edit`,
  props<{ formId: string; stepId: string }>()
);

export const resetProgressiveForm = createAction(
  `[Progressive Form] Reset Progressive Form`,
  props<{
    formId: string;
  }>()
);

export const setFormConfig = createAction(
  `[Progressive Form] Set Progressive Form Configuration`,
  props<{
    formId: string;
    formSteps: string[];
    scrollToInitialStep: boolean;
    progressOnValidSubmit: boolean;
    formStepsEntities?: FormStep[];
  }>()
);

export const continueFromLastStep = createAction(
  `[Progressive Form] Continue From Last Step`,
  props<{
    formId: string;
  }>()
);

export const submitFormStep = createAction(
  `[Progressive Form] Submit form`,
  props<{
    formId: string;
    formState: FormGroupState<any>;
  }>()
);

export const updateSkipFormSteps = createAction(
  `[Progressive Form] Skip form steps`,
  props<{
    formId: string;
    stepIds: string[];
    state: boolean;
  }>()
);

export const updateCompletedFormSteps = createAction(
  `[Progressive Form] Completed form steps`,
  props<{
    formId: string;
    stepIds: string[];
    state: boolean;
  }>()
);

export const setValueAndSubmit = createAction(
  `[Progressive Form] Set Value and submit form`,
  props<{
    formId: string;
    stepId: string;
    controlId: string;
    value: any;
  }>()
);
