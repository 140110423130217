import { createSelector } from '@ngrx/store';

import { getBecomeACustomerState } from '../become-a-customer-feature.selector';

export const getUsageQuestionnaireFormState = createSelector(
  getBecomeACustomerState,
  (state) => state.UsageQuestionnaireForm
);

export const getUsageQuestionnaireSMEFormState = createSelector(
  getBecomeACustomerState,
  (state) => state.UsageQuestionnaireFormSME.formState
);
