import { Component, Input } from '@angular/core';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss-angular';

import { TimerWidgetContent } from './timer-widget-wrapper.model';

@Component({
  selector: 'wl-timer-widget-wrapper',
  templateUrl: './timer-widget-wrapper.component.html',
})
export class TimerWidgetWrapperComponent {
  @Input({ required: true })
  set rendering(value: ComponentRendering) {
    this.content = new TimerWidgetContent(value);
  }

  content!: TimerWidgetContent;
}
