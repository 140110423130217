import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import type { DropdownItem } from '@innogy/common-ui/forms';
import { TrackToolStep } from '@innogy/core/analytics';
import { ScDropDownFormInput } from '@innogy/sitecore-forms/models';
import { FormState } from 'ngrx-forms';

@Component({
  selector: 'wl-sc-dropdown[control][fields][trackStep]',
  templateUrl: './sc-dropdown.component.html',
})
export class ScDropdownComponent implements OnInit {
  @Input() control!: FormState<string | null | undefined>;
  @Input() fields!: ScDropDownFormInput;
  @Input() trackStep!: TrackToolStep;
  @Input() floatingLabels = false;

  dropdownItems: DropdownItem[] = [];

  ngOnInit() {
    this.dropdownItems = this.fields.Items.map((item) => ({
      label: item.fields.Label.value ?? '',
      value: item.fields.Value.value ?? '',
      icon: item.fields.Icon?.fields?.value?.value,
    }));
  }
}
