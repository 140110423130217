<div class="h3" *scText="listConfig?.title"></div>
<ul class="mb-0 list-unstyled list-unstyled--no-spacing">
  <li
    class="py-1 list-minimal"
    *ngFor="let item of listItems"
    [class.list-minimal__brand]="subVariant === 'brand'"
    [class.list-minimal__brand-alt]="subVariant === 'primary'"
    [class.list-minimal__white]="subVariant === 'white'"
  >
    <div class="d-flex">
      <fa-icon
        *ngIf="item.fields?.icon"
        [icon]="item.fields?.icon | scIcon"
        class="px-2 d-flex justify-content-center align-items-baseline"
        [fixedWidth]="true"
      ></fa-icon>
      <div class="list-minimal-content">
        <wl-rich-text-wrapper [field]="item.fields?.title">
          <div></div>
        </wl-rich-text-wrapper>
      </div>
    </div>
  </li>
</ul>
