<table class="table table-borderless mb-0" wlAutomationId="costs.electricity">
  <thead>
    <tr>
      <th id="energy-title" scope="col">
        <ng-container *ngIf="energyTitle">
          <span class="energy-title" *scText="energyTitle" wlCSCapture></span>
        </ng-container>
      </th>
      <th
        id="summer"
        scope="col"
        class="text-end"
        [innerHTML]="getSummerDate | safeHtml"
        wlCSCapture
      ></th>
      <th
        id="winter"
        scope="col"
        class="text-end"
        [innerHTML]="getWinterDate | safeHtml"
        wlCSCapture
      ></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngIf="showRedeliveryCosts">
      <th id="delivery-title" headers="energy-title" colspan="3" scope="col">
        <ng-container *ngIf="deliveryTitle">
          <span class="h5" *scText="deliveryTitle" wlCSCapture></span>
        </ng-container>
      </th>
    </tr>
    <tr *ngFor="let tariffPeriod of tariffPeriods; index as i">
      <th [id]="'tariff-' + i" scope="row">
        <em wlCSCapture
          >{{ 'per' | translate }} {{ 'ElectricityUsageUnit' | translate }}</em
        ><br />
        <span
          [innerHTML]="tariffPeriod.description | safeHtml"
          wlCSCapture
        ></span>
      </th>
      <td
        [headers]="'summer delivery-title tariff-' + i"
        class="text-end"
        wlCSCapture
      >
        {{ tariffPeriod?.summerPrice | currency: 'EUR' : 'symbol' : '1.5' }}
      </td>
      <td
        [headers]="'winter delivery-title tariff-' + i"
        class="text-end"
        wlCSCapture
      >
        {{ tariffPeriod?.winterPrice | currency: 'EUR' : 'symbol' : '1.5' }}
      </td>
    </tr>
    <tr wlAutomationId="costs.electricityDelivery" class="mb-1">
      <th id="fixed-delivery" colspan="2" scope="row" class="fst-italic">
        <ng-container *ngIf="fixedDeliveryCostsLabel">
          <span *scText="fixedDeliveryCostsLabel" wlCSCapture></span>
        </ng-container>
      </th>
      <td headers="delivery-title fixed-delivery" class="text-end" wlCSCapture>
        {{ fixedDeliveryCosts }}
      </td>
    </tr>
    <ng-container *ngIf="showRedeliveryCosts">
      <tr aria-hidden="true">
        <td colspan="3">
          <hr />
        </td>
      </tr>
      <tr>
        <th id="redelivery-title" colspan="3" scope="col">
          <ng-container *ngIf="redeliveryTitle">
            <span class="h5" *scText="redeliveryTitle" wlCSCapture></span>
          </ng-container>
        </th>
      </tr>
      <tr>
        <td colspan="3">
          {{ fixedRedeliveryCostsScale }}
        </td>
      </tr>
      <tr wlAutomationId="costs.electricityRedelivery">
        <th
          id="fixed-redelivery"
          headers="redelivery-title"
          colspan="2"
          scope="row"
          class="fst-italic"
        >
          <ng-container *ngIf="fixedRedeliveryCostsLabel">
            <span *scText="fixedRedeliveryCostsLabel" wlCSCapture></span>
          </ng-container>
        </th>
        <td
          headers="redelivery-title fixed-redelivery"
          class="text-end"
          wlCSCapture
        >
          {{ fixedRedeliveryCosts }}
        </td>
      </tr>
      <tr>
        <td
          colspan="3"
          class="font-size-sm microcopy fst-italic"
          *wlGenericRichText="redeliveryDescription"
        ></td>
      </tr>
    </ng-container>
  </tbody>
</table>
