import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CommonUiFormsModule } from '@innogy/common-ui/forms';
import { CommonUiSharedModule } from '@innogy/common-ui/shared';
import { CommonUiUtilityComponentsModule } from '@innogy/common-ui/utility-components';
import { CoreAnalyticsModule } from '@innogy/core/analytics';
import { CoreExperienceEditorModule } from '@innogy/core/experience-editor';
import { UtilsModule } from '@innogy/utils-deprecated';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { JssModule } from '@sitecore-jss/sitecore-jss-angular';
import { NgrxFormsModule } from 'ngrx-forms';
import { StoreModule } from '@ngrx/store';

import { UsageQuestionnaireComponent } from './usage-questionnaire/usage-questionnaire.component';
import { UsageQuestionnaireModalComponent } from './usage-questionnaire-modal/usage-questionnaire-modal.component';
import { estimateConsumptionByHouseTypeReducer } from './api-state/estimate-consumption-by-house.reducer';
import { commonUiSalesSelectorKey } from './api-state/estimate-consumption-by-house.selector';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    CoreAnalyticsModule,
    CoreExperienceEditorModule,
    CommonUiUtilityComponentsModule,
    CommonUiFormsModule,
    JssModule,
    UtilsModule,
    NgbModalModule,
    CommonUiSharedModule,
    NgrxFormsModule,
    StoreModule.forFeature(commonUiSalesSelectorKey, [
      estimateConsumptionByHouseTypeReducer,
    ]),
  ],
  declarations: [UsageQuestionnaireComponent, UsageQuestionnaireModalComponent],
  exports: [UsageQuestionnaireComponent, UsageQuestionnaireModalComponent],
})
export class CommonUiSalesModule {}
