import { createSelector } from '@ngrx/store';

import { selectGetInsulationNewCustomerAddressCheckData } from '../insulation-api';
import { eplusInsulationFeature } from '../insulation-form.state';
import { selectInsulationFunnelSettings } from '../insulation-funnel';
import { insulationProductOverviewKey } from './insulation-product-overview.reducer';

const selectInsulationProductOverviewState = createSelector(
  eplusInsulationFeature,
  (state) => state[insulationProductOverviewKey]
);

export const selectInsulationProductOverviewViewModel = createSelector(
  selectInsulationProductOverviewState,
  selectGetInsulationNewCustomerAddressCheckData,
  selectInsulationFunnelSettings,
  (state, addressCheckData, funnelSettings) => ({
    ...state,
    addressData: addressCheckData?.address,
    products: addressCheckData?.products ?? [],
    alreadyInsulated: addressCheckData?.alreadyInsulated ?? false,
    suitableProductTypes: addressCheckData?.suitableProductTypes ?? [],
    selectedProducts: funnelSettings.selectedProducts,
    selectedProductsTotalYearlySavings: funnelSettings.selectedProducts.reduce(
      (total, product) => product.yearlySavings + total,
      0
    ),
  })
);
