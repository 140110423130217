export enum StructuredDataType {
  FaqPage = 'FAQPage',
  Question = 'Question',
  Answer = 'Answer',
  Organization = 'Organization',
  AggregateRating = 'AggregateRating',
}

export const STRUCTURED_DATA_CONTEXT = 'https://schema.org';

export interface BaseStructuredData {
  '@context': 'https://schema.org';
}
