import type { HeatpumpsFunnelSettingsInterface } from '@innogy/eplus/models';
import { createReducer, on } from '@ngrx/store';

import {
  clearHeatpumpsFunnelInitializedAction,
  mintHeatpumpsTrackingIdAction,
  setHeatpumpsFunnelInitializedAction,
} from './heatpumps-funnel.actions';

export const heatpumpsFunnelKey = 'heatpumps-funnel';

export type HeatpumpsFunnelState = HeatpumpsFunnelSettingsInterface;

export const initialHeatpumpsFunnelState: HeatpumpsFunnelState = {
  initialized: false,
};

export const heatpumpsFunnelReducer = createReducer(
  initialHeatpumpsFunnelState,
  on(setHeatpumpsFunnelInitializedAction, (state, { funnelSettings }) => ({
    ...state,
    ...funnelSettings,
    initialized: true,
  })),
  on(clearHeatpumpsFunnelInitializedAction, (state) => ({
    ...state,
    initialized: false,
  })),
  on(mintHeatpumpsTrackingIdAction, (state, { id: trackingId }) => ({
    ...state,
    trackingId,
  }))
);
