import type { KeyValue } from 'ngrx-forms';

import type { ProgressiveFormGroupState } from '../state';
import { progressiveFormStateReducer } from '../reducer';
import { MarkAsSubmittableAction } from '../actions';

export function markAsSubmittable<TValue extends KeyValue>(
  state: ProgressiveFormGroupState<TValue>
) {
  return progressiveFormStateReducer(
    state,
    new MarkAsSubmittableAction(state.id)
  );
}
