import type { ClientEnvironmentConfig } from '@core/config-models';
import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';

import { configLoaded } from './config.actions';

export interface ConfigState {
  config: ClientEnvironmentConfig | null;
}

const initialState: ConfigState = {
  config: null,
};

const featureReducer = createReducer(
  initialState,
  on(configLoaded, (_, action) => ({ config: action.config }))
);

export function configReducer(state: ConfigState | undefined, action: Action) {
  return featureReducer(state, action);
}
