export enum ScriptInjectOn {
  CLIENT_ONLY = 'CLIENT_ONLY',
  SERVER_ONLY = 'SERVER_ONLY',
  CLIENT_AND_SERVER = 'CLIENT_AND_SERVER',
}

export interface ScriptInjectionConfig {
  /**
   * Script source
   */
  src?: string;
  /**
   * Script type property
   * @default 'text/javascript'
   */
  type?: string;
  /**
   * Script async property
   * @default false
   */
  async?: boolean;
  /**
   * Script innerHTML property
   */
  innerHTML?: string;
  /**
   * Callback function (called when the script is loaded)
   */
  onLoad?: () => void;
  /**
   * Callback function (called when the script could not be loaded)
   */
  onError?: () => void;
  /**
   * Callback function (called when the script is already loaded)
   */
  onAlreadyLoaded?: () => void;
  /**
   * Target element (where to place the script)
   * @default 'head'
   */
  target?: 'head' | 'body';
  /**
   * Can be used to make sure this script is injected at the top of the "target"
   * @default false
   */
  injectBeforeOtherScripts?: boolean;
  /**
   * Specify on what platform to inject the script
   * @default ScriptInjectOn.CLIENT_AND_SERVER
   */
  injectOnPlatform?: ScriptInjectOn;
  /**
   * If it should inject the script outside the Angular Zone
   * @default true
   */
  runOutsideAngular?: boolean;
  /**
   * A boolean that can only be set to true by absolute madlads
   * @default false
   */
  injectInExperienceEditor?: boolean;
}

export type ScriptStatus = {
  injected: boolean;
  loaded?: boolean;
  error?: boolean;
};
