import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CSCaptureDirective } from './capture/cs-capture.directive';
import { CSMaskDirective } from './mask/cs-mask.directive';

export const contentSquareExports = [CSCaptureDirective, CSMaskDirective];

@NgModule({
  declarations: [...contentSquareExports],
  exports: [...contentSquareExports],
  imports: [CommonModule],
})
export class UtilsContentsquareModule {}
