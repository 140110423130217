<div class="d-block">
  <div class="d-flex justify-content-between align-items-center">
    <h3 *scText="title" class="m-0 modal-page-header__title"></h3>
    <a
      *wlGenericLink="href; extras: navigationExtras"
      class="modal-page-header__link"
    >
      <fa-icon class="modal-page-header__icon" icon="xmark"></fa-icon>
    </a>
  </div>
</div>
