import { createSelector } from '@ngrx/store';

import { getNavigationState } from '../navigation.selectors';
import { ecmpHeaderSelectorKey } from './ecmp-header.reducer';

export const getEcmpHeaderState = createSelector(
  getNavigationState,
  (state) => state[ecmpHeaderSelectorKey]
);

export const getEcmpHeader = createSelector(getEcmpHeaderState, (state) => {
  return state;
});
