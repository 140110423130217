import type {
  ProductTrackingConfig,
  ParsedProductTrackingConfig,
  CommerceMapperContext,
} from '../track-tool/track-tool.model';

export const defaultCommerceMapperContext = {
  index: 0,
  listName: '',
  numberOfProducts: 1,
};

export function parseTrackProductConfig<TProduct, TCommerce>(
  config: ProductTrackingConfig<TProduct, TCommerce>
): ParsedProductTrackingConfig<TProduct, TCommerce> {
  return {
    mapToCommerce: function (input, context = defaultCommerceMapperContext) {
      if (Array.isArray(input)) {
        return input.map((product) =>
          config.productToCommerce(product, context)
        );
      }
      return config.productToCommerce(input, context);
    } as {
      (input: TProduct, context: CommerceMapperContext): TCommerce;
      (input: TProduct[], context: CommerceMapperContext): TCommerce[];
    },
  };
}
