import { ViewportScroller } from '@angular/common';
import type { OnDestroy, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { PlatformService } from '@core/platform';
import { PageInfoService } from '@innogy/core/analytics';
import { ScrollService } from '@innogy/utils-deprecated';
import type { Subscription } from 'rxjs';
import { Subject, timer } from 'rxjs';
import { audit, debounce, filter, map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'wl-scroll-manager',
  template: '',
})
export class ScrollManagerComponent implements OnInit, OnDestroy {
  private readonly onDestroy$ = new Subject();
  subscription: Subscription | undefined;

  constructor(
    readonly platform: PlatformService,
    readonly pageInfoService: PageInfoService,
    readonly viewportScroller: ViewportScroller,
    readonly router: Router,
    readonly route: ActivatedRoute,
    readonly scrollService: ScrollService
  ) {}

  readonly #navigationChanges = this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    filter((e) => e.id > 2) //The first two events appear on page-load via SSR which are picked up by the browser and not by us.
  );

  #handleScrollOnNavigation() {
    this.subscription = this.#navigationChanges
      .pipe(
        audit(() => this.pageInfoService.allTopLevelPlaceholdersLoaded$),
        /**
         * There is a 250ms delay before scrolling for 2 reasons:
         *   1. When the (loaded) event is emitted from <sc-placeholder>, it is only
         *      markedForCheck in the ChangeDetector, it still has to run.
         *   2. There could also be items in the placeholder that need to lazy-load,
         *      there is currently no way to wait for that.
         */
        debounce(() => timer(250)),
        map((_) => {
          const { fragment } = this.route.snapshot;
          if (fragment) {
            // Any internal navigation afterward should scroll to anchors with animation
            this.scrollService.scrollToAnchorAnimated(fragment);
          } else {
            // If there is a actual route change that has no fragment, it means we want to scroll to [0,0]
            // This means scroll position restoration is not supported in our set-up.
            this.viewportScroller.scrollToPosition([0, 0]);
          }
        }),
        takeUntil(this.onDestroy$)
      )
      .subscribe();
  }

  ngOnInit() {
    if (this.platform.isClient()) {
      this.#handleScrollOnNavigation();
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
