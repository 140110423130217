import { createNamedApi } from '@essent/common';

import { GetMarketingModelContractService } from './get-marketing-model-contract.service';

export const getMarketingModelContractApi = createNamedApi(
  '[GetMarketingModelContractState] Get Marketing Model Contract',
  GetMarketingModelContractService
);

export const {
  getMarketingModelContractActions,
  getMarketingModelContract,
  getMarketingModelContractClear,
  getMarketingModelContractError,
  getMarketingModelContractSuccess,
} = getMarketingModelContractApi;
