import { Component, Input } from '@angular/core';

@Component({
  selector: 'wl-offer-usps',
  templateUrl: './offer-usps.component.html',
  styleUrls: [],
})
export class OfferUspsComponent {
  @Input() usps: string[] = [];
}
