// The IdTokenPayload is here because it has close ties to the UserInfo type

export interface IdTokenPayload {
  brand: string;
  account_id: string;
  online_account_id: string;
  groups: string[];
  client_id: string;
  client_name: string;
  client_type: string;
  hasMultipleAccounts: boolean;
  has_authenticator: boolean;
}

export interface UserInfo {
  brand: string;
  accountId: string;
  onlineAccountId: string;
  groups: string[];
  clientId: string;
  clientName: string;
  clientType: string;
  hasMultipleAccounts: boolean;
  hasAuthenticator: boolean;
  // NOTE: the UserInfo objects contains some JWTPayload key-value parts as well
  //       (but camelCased because of the mapping that happens in core-modules).
  //       see type JWTPayload for more info
}

export interface UserInfoResponse {
  authenticated: boolean;
  userinfo?: UserInfo;
}

export enum AuthenticationStatus {
  Pending,
  Success,
  Failure,
}
