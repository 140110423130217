import { Component, HostBinding, Input } from '@angular/core';
import { ColumnContext } from '@innogy/common-ui/shared/interfaces';
import { ImageSettings } from '@innogy/common-ui/utility-components';
import { InnogyComponentRendering } from '@core/jss-models';
import { toScrollAnchorId } from '@innogy/utils-deprecated';

import { ComposableCardConfig } from '../composables/card/card.model';
import type {
  BasicContentVariant,
  BasicContentVariantInternal,
} from './basic-content-settings.model';
import {
  BasicContentSettings,
  BasicContentValues,
} from './basic-content-settings.model';

@Component({
  selector: 'wl-basic-content',
  templateUrl: './basic-content.component.html',
})
export class BasicContentComponent {
  @Input() variant: BasicContentVariant | BasicContentVariantInternal =
    'minimal';
  @Input() content?: BasicContentValues;
  @Input() settings?: BasicContentSettings;
  @Input() context?: ColumnContext;
  @Input() image?: ImageSettings;
  @Input() rendering?: InnogyComponentRendering;
  @Input() cardConfig?: ComposableCardConfig;

  @HostBinding('attr.id') get id() {
    if (this.rendering?.uid) {
      return toScrollAnchorId(this.rendering.uid);
    }
    return '';
  }
}
