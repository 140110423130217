import { createNamedApi } from '@essent/common';

import { GetInsulationAddressCheckService } from './get-insulation-address-check.service';

export const getInsulationAddressCheckApi = createNamedApi(
  '[GetInsulationAddressCheckState] Get Insulation Address Check',
  GetInsulationAddressCheckService
);

export const {
  getInsulationAddressCheckActions,
  getInsulationAddressCheck,
  getInsulationAddressCheckClear,
  getInsulationAddressCheckError,
  getInsulationAddressCheckSuccess,
} = getInsulationAddressCheckApi;
