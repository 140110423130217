import {
  validateSequential,
  isPostalCode,
  isNumeric,
  useValidatorIf,
  isEmailAddress,
  isPhoneNumber,
  isInitials,
  isLastName,
  postalCodeIsExcluded,
  isFriendlyFormatIBAN,
} from '@innogy/utils-deprecated';
import type {
  ScAddressFormInput,
  ScEmailFormInput,
  ScFormInput,
  ScFormInputType,
} from '@innogy/sitecore-forms/models';
import type { Boxed } from 'ngrx-forms';
import { equalTo, required, requiredTrue } from 'ngrx-forms/validation';

import type { ScFormState } from './create-generic-form-reducer';
import { controlNameForInput } from './form-values';

const conditionalRequired = <T = boolean>(input: ScFormInput) =>
  useValidatorIf<T>(required, input.Required?.value === true);

const conditionalRequiredTrue = <
  T extends boolean | Boxed<boolean> | null | undefined,
>(
  input: ScFormInput
) => useValidatorIf<T>(requiredTrue, input.Required?.value === true);

// eslint-disable-next-line complexity
export function getValidatorsForField(
  type: ScFormInputType['value'],
  fieldData: ScFormInput,
  state: ScFormState['form']
) {
  switch (type) {
    case 'address':
      return scFormAddressInputValidator(fieldData as ScAddressFormInput);
    case 'email':
      return scFormEmailInputValidator(fieldData as ScEmailFormInput, state);
    case 'payment':
      return scFormPaymentInputValidator(fieldData);
    case 'phone':
      return scFormPhoneInputValidator(fieldData);
    case 'radio':
      return scFormRadioInputValidator(fieldData);
    case 'checkbox':
      return scFormCheckboxInputValidator(fieldData);
    case 'checkboxSet':
      return scFormCheckboxSetInputValidator(fieldData);
    case 'name':
      return scFormNameInputValidator(fieldData);
    case 'dropdown':
      return scFormDropdownInputValidator(fieldData);
    case 'text':
    default:
      return scFormTextInputValidator(fieldData);
  }
}

export const scFormAddressInputValidator = (input: ScAddressFormInput) => {
  const postalcodeExclusion =
    input.PostalCodeExclusion.value?.toUpperCase() || '';
  return {
    postalCode: validateSequential(
      conditionalRequired(input),
      isPostalCode,
      useValidatorIf(
        postalCodeIsExcluded(postalcodeExclusion),
        postalcodeExclusion.length > 0
      )
    ),
    communicationNumber: validateSequential(required, isNumeric),
    street: validateSequential(required),
    city: validateSequential(required),
  };
};

export const scFormTextInputValidator = (input: ScFormInput) => ({
  [controlNameForInput(input)]: validateSequential(conditionalRequired(input)),
});

export const scFormEmailInputValidator = (
  input: ScEmailFormInput,
  state: ScFormState['form']
) => {
  const isEmailValidation = input.IsValidation?.value === true;
  if (isEmailValidation) {
    const equalToInput = input.ShouldValidateInput[0].fields;
    const controlName = controlNameForInput(equalToInput);

    return {
      [controlNameForInput(input)]: validateSequential(
        equalTo(state.formState.value[controlName]),
        isEmailAddress
      ),
    };
  } else {
    return {
      [controlNameForInput(input)]: validateSequential(
        conditionalRequired(input),
        isEmailAddress
      ),
    };
  }
};

export const scFormPaymentInputValidator = (input: ScFormInput) => ({
  [controlNameForInput(input)]: validateSequential(
    conditionalRequired(input),
    isFriendlyFormatIBAN
  ),
});

export const scFormPhoneInputValidator = (input: ScFormInput) => ({
  [controlNameForInput(input)]: validateSequential(
    conditionalRequired(input),
    isPhoneNumber
  ),
});

export const scFormRadioInputValidator = (input: ScFormInput) => ({
  [controlNameForInput(input)]: validateSequential(conditionalRequired(input)),
});

export const scFormCheckboxInputValidator = (input: ScFormInput) => ({
  [controlNameForInput(input)]: validateSequential<boolean>(
    conditionalRequired(input),
    conditionalRequiredTrue(input)
  ),
});

export const scFormCheckboxSetInputValidator = (input: ScFormInput) => ({
  [controlNameForInput(input)]: validateSequential(conditionalRequired(input)),
});

export const scFormDropdownInputValidator = (input: ScFormInput) => ({
  [controlNameForInput(input)]: validateSequential(conditionalRequired(input)),
});

export const scFormNameInputValidator = (input: ScFormInput) => ({
  initials: validateSequential<string>(conditionalRequired(input), isInitials),
  lastName: validateSequential<string>(conditionalRequired(input), isLastName),
});
