import type { AsyncState } from '@essent/common';
import { Status } from '@essent/common';
import {
  getContractGroups,
  getContractGroupsSuccess,
  getContractGroupsError,
  getContractGroupsClear,
} from '@essent/contract';
import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';
import type { ContractGroup } from '@integration/contracting-api-models';

import { selectContractGroupId } from './contract.actions';

export interface ContractsState extends AsyncState<ContractGroup[]> {
  data: ContractGroup[];
  selectedContractGroupId?: string;
}

export const initialState: ContractsState = {
  data: [],
  status: Status.IDLE,
};

export const _reducer = createReducer(
  initialState,
  on(getContractGroups, (state) => ({
    ...state,
    status: Status.PENDING,
  })),
  on(getContractGroupsSuccess, (state, action) => ({
    ...state,
    data: action.payload.contractGroup,
    status: Status.SUCCESS,
  })),
  on(getContractGroupsError, (state, action) => ({
    ...state,
    data: [],
    error: action.payload,
    status: Status.ERROR,
  })),
  on(selectContractGroupId, (state, action) => ({
    ...state,
    selectedContractGroupId: action.payload,
  })),
  on(getContractGroupsClear, () => initialState)
);

export function contractGroupReducer(
  state: ContractsState = initialState,
  action: Action
): ContractsState {
  return _reducer(state, action);
}

export const getError = (state?: ContractsState) => state?.error;
export const getHasError = (state?: ContractsState) =>
  state?.status === Status.ERROR;
export const getIsLoading = (state?: ContractsState) =>
  !state || state.status === Status.PENDING || state.status === Status.IDLE;
export const getStatus = (state?: ContractsState) => state?.status;
export const getGroups = (state?: ContractsState) => state?.data;
export const getSelectedId = (state?: ContractsState) =>
  state?.selectedContractGroupId;
