<div ngbAccordion [animation]="true" [destroyOnHide]="false">
  <div
    *ngFor="let item of items; let i = index; trackBy: trackByItem"
    [ngbAccordionItem]="item.id"
    [collapsed]="item.isCollapsed"
  >
    <div ngbAccordionHeader>
      <button
        class="accordion-button"
        ngbAccordionButton
        wlTrackImpression="accordion-impression"
        [trackImpressionInfo]="item.impressionData"
        wlTrackAccordion
        [trackAccordionInfo]="item.clickData"
        wlAutomationId="faq-header-button"
      >
        <wl-partial-accordion-title
          [title]="item.question"
        ></wl-partial-accordion-title>
      </button>
    </div>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody>
        <ng-template>
          <div class="row answer-padding answer-container">
            <ng-container *ngIf="!item.imageSettings">
              <div class="col-12">
                <wl-rich-text-wrapper
                  [field]="{ value: item.answer }"
                  wlAutomationId="faq-body-richtext"
                >
                  <div></div>
                </wl-rich-text-wrapper>
              </div>
            </ng-container>
            <ng-container *ngIf="item.imageSettings">
              <div [class.col-md-12]="isVertical" class="col-md-6">
                <wl-rich-text-wrapper [field]="{ value: item.answer }">
                  <div></div>
                </wl-rich-text-wrapper>
              </div>
              <div [class.col-md-12]="isVertical" class="col-md-6">
                <wl-image [imageSettings]="item.imageSettings"></wl-image>
              </div>
            </ng-container>
          </div>
          <div class="mt-2" *ngIf="feedbackSettings">
            <wl-quick-feedback
              [settings]="feedbackSettings"
              [question]="item.question"
            ></wl-quick-feedback>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
<wl-structured-data [structuredData]="structuredData"></wl-structured-data>
