import { createSelector } from '@ngrx/store';
import { createExtensionDropdownList } from '@innogy/become-a-customer/shared';

import { getOrderState } from '../order.selector';

export const getOrderAddressProgressiveFormState = createSelector(
  getOrderState,
  (state) => state.orderAddressForm.progressiveForm
);

export const getOrderAddressFormState = createSelector(
  getOrderAddressProgressiveFormState,
  (state) => state.formState
);

export const getAddressFormRendering = createSelector(
  getOrderState,
  (state) => state.orderAddressForm.rendering
);

export const getCorrespondenceInstallationAddressState = createSelector(
  getOrderState,
  (state) => state.orderAddressForm.correspondenceAddress
);

export const getSelectedCorrespondenceInstallationAddressState = createSelector(
  getOrderState,
  (state) => {
    const supplyData = state.orderAddressForm.correspondenceAddress;
    const controls = state.orderAddressForm.progressiveForm.formState.controls;
    const houseNumberExtension =
      controls?.houseNumberAdditionCorrespondence?.value;

    if (!supplyData) {
      return undefined;
    }

    if (!houseNumberExtension) {
      return supplyData[0];
    }

    return supplyData.find(
      (i) => i.houseNumberExtension === houseNumberExtension
    );
  }
);

export const getCorrespondenceInstallationAddressHouseNumberExtensionAsDropdownItems =
  createSelector(
    getCorrespondenceInstallationAddressState,
    createExtensionDropdownList
  );

export const getAddressStepRendering = createSelector(
  getOrderState,
  (state) => state.orderAddressForm.rendering
);

export const selectSelectedInswitchInhouseOption = createSelector(
  getOrderAddressFormState,
  (state) => state.controls.inswitchInhouse?.value?.value
);

export const selectDisableFunnelAdvancement = createSelector(
  selectSelectedInswitchInhouseOption,
  (state) => state?.disableFunnelAdvancement
);

export const selectSelectedStartDate = createSelector(
  getOrderAddressFormState,
  (state) => state.controls.selectedStartDate
);
