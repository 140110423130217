import {
  getActiveDuration,
  getActiveOfferset,
  getActiveOffersetPresentDurations,
  getConsumptionValuesVM,
  getFirstActiveOffer,
  getFunnelSettings,
  getSupplyAddressVM,
} from '@innogy/become-a-customer/state';
import { getIsExperienceEditorActive } from '@core/jss-routing';
import { createSelector } from '@ngrx/store';

export const verticalOfferComponentVm = createSelector(
  getFunnelSettings,
  getActiveOffersetPresentDurations,
  getActiveDuration,
  getFirstActiveOffer,
  getActiveOfferset,
  getConsumptionValuesVM,
  getSupplyAddressVM,
  getIsExperienceEditorActive,
  (
    funnelSettings,
    durations,
    activeDuration,
    activeOffer,
    activeOfferSet,
    consumption,
    supplyAddress,
    isExperienceEditorActive
  ) => ({
    regularOfferset: funnelSettings.offerset,
    addOn: funnelSettings.addOn,
    durations,
    activeDuration,
    activeOffer,
    activeOfferSet,
    consumption,
    supplyAddress,
    isExperienceEditorActive,
  })
);
