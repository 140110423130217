import { createNamedApi } from '@essent/common';

import { GetInsulationProductsService } from './get-insulation-products.service';

export const getInsulationProductsApi = createNamedApi(
  '[GetInsulationProductsState] Get Insulation Products',
  GetInsulationProductsService
);

export const {
  getInsulationProductsActions,
  getInsulationProducts,
  getInsulationProductsClear,
  getInsulationProductsError,
  getInsulationProductsSuccess,
} = getInsulationProductsApi;
