import type { ActionReducerMap } from '@ngrx/store';
import {
  BAC_QUESTIONNAIRE_ID,
  createUsageQuestionnaireReducer,
} from '@innogy/common-ui/sales';

import { putInitiateFlowReducer } from './bac/1.initiate-flow';
import { propositionReducer } from './bac/10.proposition';
import { personalDetailsReducer } from './bac/12.personal-details';
import { correspondenceDetailsReducer } from './bac/13.correspondence-details';
import { paymentDetailsReducer } from './bac/14.payment-details';
import { additionalInformationReducer } from './bac/15.additional-information';
import { startDateReducer } from './bac/17.suggested-startdate';
import { confirmationReducer } from './bac/18.confirmation';
import { supplyAddressReducer } from './bac/2.supply-address';
import { installationDetailsReducer } from './bac/3.installation-details';
import { metaDataReducer } from './bac/5.meta-data';
import { consumptionReducer } from './bac/7.consumption';
import { budgetBillReducer } from './bac/budget-bill';
import { flowIdReducer } from './bac/flow-id';
import { installationAddressReducer } from './bac/installation-address';
import { isCustomerReducer } from './bac/is-customer';
import { offersReducer } from './bac/offers';
import { organisationDetailsReducer } from './bac/organisation-details';
import { partnerIdReducer } from './bac/partner-id';
import type { BecomeACustomerState } from './become-a-customer-state.interface';
import { BAGRegistrationReducer } from './bac/bag-registration';
import { funnelSettingsReducer } from './funnel';
import { getOfferReducer, getOffersReducer } from './get-offer';
import { memberGetMemberReducer } from './bac/member-get-member';
import { UsageQuestionnaireSME } from './usage-questionnaire';

export const becomeACustomerReducers: ActionReducerMap<BecomeACustomerState> = {
  flowId: flowIdReducer,
  getInstallationAddress: installationAddressReducer,
  supplyAddress: supplyAddressReducer,
  consumption: consumptionReducer,
  installationDetails: installationDetailsReducer,
  proposition: propositionReducer,
  getOffer: getOfferReducer,
  getOffers: getOffersReducer,
  personalDetails: personalDetailsReducer,
  correspondenceDetails: correspondenceDetailsReducer,
  additionalInformation: additionalInformationReducer,
  startDate: startDateReducer,
  budgetBill: budgetBillReducer,
  paymentDetails: paymentDetailsReducer,
  confirmation: confirmationReducer,
  partnerId: partnerIdReducer,
  organisationDetails: organisationDetailsReducer,
  isCustomer: isCustomerReducer,
  funnelSettings: funnelSettingsReducer,
  offers: offersReducer,
  metaData: metaDataReducer,
  putInitiateFlow: putInitiateFlowReducer,
  BAGRegistration: BAGRegistrationReducer,
  memberGetMember: memberGetMemberReducer,
  UsageQuestionnaireForm: createUsageQuestionnaireReducer(BAC_QUESTIONNAIRE_ID),
  UsageQuestionnaireFormSME: UsageQuestionnaireSME.reducer,
};
