import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AddArrayControlAction, SetValueAction } from 'ngrx-forms';
import { filter, switchMap } from 'rxjs/operators';

import { addFileAction } from './file-input.actions';

@Injectable()
export class FileInputEffects {
  constructor(private readonly actions$: Actions) {}

  registerFileAfterAdd$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addFileAction),
      filter((action) => !action.singleFile),
      switchMap((action) => [
        new AddArrayControlAction(action.controlId, action.referenceId),
      ])
    )
  );
  registerFileAfterReplace$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addFileAction),
      filter((action) => action.singleFile),
      switchMap((action) => [
        new SetValueAction(action.controlId, [action.referenceId]),
      ])
    )
  );
}
