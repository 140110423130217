import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import type { BaseService } from '@essent/common';
import type { Observable } from 'rxjs';
import { API_PUBLIC_PREFIX } from '@core/config-angular';

import type { CreateLeadHeatpumpRequest } from '../../models/heatpumps/create-lead-heatpump-request.interface';

@Injectable()
export class PostHeatpumpsLeadService
  implements BaseService<CreateLeadHeatpumpRequest, void>
{
  public readonly apiVersion = '1';
  public readonly endpoint = `${this.apiUrl || ''}/eplus/leads/heatpump/v${
    this.apiVersion
  }`;

  public constructor(
    @Optional()
    @Inject(API_PUBLIC_PREFIX)
    private readonly apiUrl: string,
    private readonly httpClient: HttpClient
  ) {}

  public call$(payload: CreateLeadHeatpumpRequest): Observable<void> {
    const headers = { Accept: 'application/json', Brand: 'essent' };
    return this.httpClient.post<void>(this.endpoint, payload, { headers });
  }
}
