import type { Action } from '@ngrx/store';
import type {
  FormGroupState,
  InferenceWrapper,
  InferredFormState,
  KeyValue,
} from 'ngrx-forms';
import { setValue, markAsPristine } from 'ngrx-forms';

import type { PrefillAction } from './models';
import { validateNgrxState } from './validate-ngrx-state';

export interface onPrefillOptions {
  submitIfValid?: boolean;
  validateOnPrefill?: boolean;
}
const defaultOptions: onPrefillOptions = {
  submitIfValid: false,
  validateOnPrefill: true,
};

export function onPrefillActionReducer<
  TState extends { formState: FormGroupState<TFormValues> },
  TFormValues extends KeyValue,
>(
  validator: (state: TState) => FormGroupState<TFormValues>,
  options?: onPrefillOptions
) {
  const fullOptions = {
    ...defaultOptions,
    ...options,
  };
  return (state: TState, action: Action): TState => {
    const formState: InferredFormState<InferenceWrapper<TFormValues>> =
      setValue<TFormValues>({
        ...state.formState.value,
        ...(action as ReturnType<PrefillAction<TFormValues>>).payload,
      })(state.formState);

    if (!fullOptions.validateOnPrefill) {
      return { ...state, formState };
    }

    const updatedState = validateNgrxState(
      { ...state, formState },
      validator,
      fullOptions.submitIfValid
    );

    return {
      ...updatedState,
      formState: markAsPristine(updatedState.formState),
    };
  };
}
