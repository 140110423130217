import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { isSitecorePlaceholderImage } from '@core/jss-utils';

import { HubSubVariant } from './list-variant-hub.model';
import { ListVariantBaseComponent } from '../list-variant.base.component';
import { ComposableCardConfig } from '../../../composables/card/card.model';

@Component({
  selector: 'wl-list-variant-hub',
  templateUrl: './list-variant-hub.component.html',
  styleUrls: [
    './list-variant-hub.component.ed.scss',
    './list-variant-hub.component.essent.scss',
  ],
})
export class ListVariantHubComponent
  extends ListVariantBaseComponent
  implements OnInit
{
  @Input() subVariant: HubSubVariant = 'brand';
  @Input() cardConfig?: ComposableCardConfig;

  backgroundColorClass?: string;

  override ngOnInit(): void {
    super.ngOnInit();
    if (this.cardConfig) {
      this.cardConfig.borderRoundingClass = 'rounded-top';
      this.backgroundColorClass = this.cardConfig.backgroundColorClass;
      this.cardConfig.backgroundColorClass = '';
    }
  }

  get image(): string | undefined {
    return isSitecorePlaceholderImage(this.imageSettings?.image?.src)
      ? undefined
      : this.imageSettings?.image?.src;
  }

  get hasDarkBackground(): boolean {
    return ['bg-brand-gradient', 'bg-brand'].includes(
      this.backgroundColorClass || 'unknown'
    );
  }
}
