import { Inject, NgModule } from '@angular/core';

import { CoreAnalyticsRootModule } from './core-analytics-root.module';
import type { AnalyticsEnhancer } from './provider/analytics-enhancer.model';
import { FEATURE_ANALYTICS_ENHANCERS } from './provider/analytics-enhancer.model';

@NgModule({})
export class CoreAnalyticsFeatureModule {
  constructor(
    root: CoreAnalyticsRootModule,
    @Inject(FEATURE_ANALYTICS_ENHANCERS)
    enhancerSourceGroups: AnalyticsEnhancer[][]
  ) {
    enhancerSourceGroups.forEach((group) =>
      group.forEach((enhancerSourceInstance) =>
        root.addEnhancers(enhancerSourceInstance)
      )
    );
  }
}
