import { StoreDevtoolsModule } from '@ngrx/store-devtools';

export const extModules = [
  // see https://ngrx.io/guide/store-devtools/config
  StoreDevtoolsModule.instrument({
    // readonly redux devtools
    logOnly: true,
    // maximum allowed actions to be stored in the history tree, reduce performance impact
    maxAge: 30,
    // pauses recording actions and state changes when the extension window is not open, reduce performance impact
    autoPause: true,
    name: 'sitecore+',
    connectInZone: true,
  }),
];
export const extMetaReducers = [];
