import type { AsyncState } from '@essent/common';
import {
  createAsyncStateReducer,
  createInitialAsyncState,
} from '@essent/common';
import type { BAGRegistration } from '@essent/contract';
import {
  getBAGRegistration,
  getBAGRegistrationClear,
  getBAGRegistrationError,
  getBAGRegistrationSuccess,
} from '@essent/contract';
import type { Action, ActionReducerMap } from '@ngrx/store';
import { combineReducers, createReducer, on } from '@ngrx/store';

import {
  markBAGRegistrationAsFaultyAction,
  resetBAGRegistrationFaultyAction,
} from './bag-registration.actions';

export type GetBAGRegistrationState = AsyncState<BAGRegistration>;

export interface BAGRegistrationState {
  getBAGRegistration: GetBAGRegistrationState;
  isFaulty: boolean;
}

const initialState: BAGRegistrationState = {
  getBAGRegistration: createInitialAsyncState(),
  isFaulty: false,
};

const _isFaulty = createReducer(
  false,
  on(markBAGRegistrationAsFaultyAction, () => true),
  on(resetBAGRegistrationFaultyAction, () => false)
);

const _getBAGRegistration = createAsyncStateReducer(
  getBAGRegistration,
  getBAGRegistrationSuccess,
  getBAGRegistrationError,
  getBAGRegistrationClear
);

const _reducers: ActionReducerMap<BAGRegistrationState, Action> = {
  getBAGRegistration: _getBAGRegistration,
  isFaulty: _isFaulty,
};

const _combinedReducers = combineReducers(_reducers);

export function BAGRegistrationReducer(
  state: BAGRegistrationState = initialState,
  action: Action
): BAGRegistrationState {
  return _combinedReducers(state, action);
}
