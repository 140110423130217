import { createAction, props } from '@ngrx/store';

import { BAC_ACTION_PREFIX } from '../constants';

export const setFlowIdAction = createAction(
  `${BAC_ACTION_PREFIX} Set flowId`,
  props<{ flowId: string }>()
);

export const setFlowIdFromCookieAction = createAction(
  `${BAC_ACTION_PREFIX} Set flowId from cookie`,
  props<{ flowId: string }>()
);

export const noFlowIdErrorAction = createAction(
  `${BAC_ACTION_PREFIX} no flowId error`
);
