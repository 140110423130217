import type {
  GenericModalSitecoreSources,
  GenericModalSources,
} from '@innogy/common-ui/shared/interfaces';

import type {
  InsulationFunnelSettingsFields,
  InsulationFunnelSettingsInterface,
  SharedEplusFunnelSettingsInterface,
  SolarPanelsFunnelSettingsFields,
  SolarPanelsFunnelSettingsInterface,
  StoreFunnelSettingsFields,
  StoreFunnelSettingsInterface,
} from '../funnel';

const mockAddressNotFoundErrorModal = {
  body: 'mi ma moo moo',
  source:
    'addressNotFoundErrorModal' as GenericModalSources.ADDRESS_NOT_FOUND_ERROR_MODAL,
  title: 'UH',
  tracking: {
    componentName: 'goaty',
    primaryButtonTrackingField: 'goats',
    secondaryButtonTrackingField: 'goaterson',
  },
};

const mockSharedFunnelSettings: SharedEplusFunnelSettingsInterface = {
  trackingId: 'mock_012345678',
  id: 'goatyo',
  productOverviewPage: {
    href: '/solarpanel/products',
    linktype: 'internal',
  },
  orderPage: { href: '/solarpanel/order', linktype: 'internal' },
  successPage: { href: '/solarpanel/thank-you', linktype: 'internal' },
};

export const mockSolarPanelsFunnelSettings: SolarPanelsFunnelSettingsInterface =
  {
    ...mockSharedFunnelSettings,
    startPage: { href: '/solarpanel/start', linktype: 'internal' },
    suitabilityCheckPage: {
      href: '/solarpanel/suitability',
      linktype: 'internal',
    },
    resultOverviewPage: {
      href: '/solarpanels/result',
      linktype: 'internal',
    },
  };

export const mockInsulationFunnelSettings: InsulationFunnelSettingsInterface = {
  ...mockSharedFunnelSettings,
  startPage: { href: '/solarpanel/start', linktype: 'internal' },
  addressNotFoundErrorModal: mockAddressNotFoundErrorModal,
};

export const mockStoreFunnelSettings: StoreFunnelSettingsInterface = {
  ...mockSharedFunnelSettings,
  apiIntegrationPath: '/sitecore/some/mock/path',
  productCategory: 'shisha',
  productType: 'met blokjes',
  hidePrices: false,
};

const mockGenericModalSources: GenericModalSitecoreSources = {
  Body: {
    value: 'goat',
  },
  PrimaryButtonText: {
    value: 'goat',
  },
  PrimaryButtonStyle: {
    name: 'goat',
    displayName: 'goat',
    fields: {
      value: {
        value: 'btn btn-goat',
      },
    },
  },
  Title: {
    value: 'goat',
  },
  SecondaryButtonText: {
    value: '',
  },
  SecondaryButtonStyle: null as any,
  Position: {
    displayName: 'goat',
    name: 'goat',
    fields: {
      value: {
        value: 'modal--center',
      },
    },
  },
  PrimaryButtonName: {
    value: 'goat',
  },
  SecondaryButtonName: {
    value: '',
  },
  ComponentName: {
    value: 'goat',
  },
  componentName: {
    value: 'SomeModal',
  },
} as any as GenericModalSitecoreSources;

export const mockSolarPanelsFunnelSettingsFromSitecore: SolarPanelsFunnelSettingsFields =
  {
    componentName: { value: 'goat-componentName' },
    id: 'goat-id',
    fields: {
      StartPage: {
        value: { href: '/goat-StartPage' },
      },
      HouseIsUnsuitablePage: {
        value: { href: '/goat-HouseUnsuitablePage' },
      },
      HouseNotFoundPage: {
        value: { href: '/goat-HouseNotFoundPage' },
      },
      HouseIsMonumentPage: {
        value: { href: '/goat-HouseIsMonumentPage' },
      },
      HouseHasInsufficientRoofAreaPage: {
        value: { href: '/goat-HouseHasInsufficientRoofAreaPage' },
      },
      ResultOverviewPage: {
        value: { href: '/goat-ResultOverviewPage' },
      },
      ProductOverviewPage: {
        value: { href: '/goat-ProductOverviewPage' },
      },
      SuitabilityCheckPage: {
        value: { href: '/goat-SuitabilityCheckPage' },
      },
      OrderPage: {
        value: { href: '/goat-OrderPage' },
      },
      SuccessPage: {
        value: { href: '/goat-SuccessPage' },
      },
      CalculationParameters: { id: 'goat-id' },
      GenericErrorModal: {
        fields: mockGenericModalSources,
      },
      AddressNotFoundErrorModal: {
        fields: mockGenericModalSources,
      },
      ShowProductPage: {
        value: true,
      },
      ShowResultOverviewPage: {
        value: true,
      },
      EnableSuitabilityCheckPage: {
        value: false,
      },
      UsageQuestionnaire: {
        fields: {
          SubmitLabel: { value: 'goat-SubmitLabel' },
          supplyTypeOptionsLabel: {
            value: 'goat-supplyTypeOptionsLabel',
          },
          errorMessage: {
            value: 'goat-errorMessage',
          },
          residentsOptions: [
            {
              name: 'goat-residentsOption',
              displayName: 'goat-residentsOption',
              fields: {
                label: { value: 'goat-residentsOption-label' },
                value: { value: 'goat-residentsOption-value' },
              },
            },
          ],
          Subtitle: {
            value: 'goat-Subtitle',
          },
          supplyTypeOptionsPlaceHolder: {
            value: 'goat-supplyTypeOptionsPlaceHolder',
          },
          housingTypeOptions: [
            {
              name: 'goat-housingTypeOption',
              displayName: 'goat-housingTypeOption',
              fields: {
                label: { value: 'goat-housingTypeOption-label' },
                value: { value: 'goat-housingTypeOption-value' },
              },
            },
          ],
          residentsOptionsLabel: {
            value: 'goat-residentsOptionsLabel​',
          },
          supplyTypeOptions: [
            {
              name: 'goat-supplyTypeOption',
              displayName: 'goat-supplyTypeOption',
              fields: {
                label: { value: 'goat-supplyTypeOption-label' },
                value: { value: 'goat-supplyTypeOption-value' },
              },
            },
          ],
          Title: { value: 'goat-Title' },
          housingTypeOptionsLabel: {
            value: 'goat-​housingTypeOptionsLabel',
          },
          residentsOptionsPlaceHolder: {
            value: 'goat-residentsOptionsPlaceHolder',
          },
          housingTypeOptionsPlaceHolder: {
            value: 'goat-housingTypeOptionsPlaceHolder',
          },
          componentName: { value: 'goat-componentName' },
        },
      },
    },
  };

export const mockInsulationFunnelSettingsFromSitecore: InsulationFunnelSettingsFields =
  {
    componentName: { value: 'goat-componentName' },
    id: 'goat-id',
    fields: {
      StartPage: {
        value: { href: '/goat-StartPage' },
      },
      ProductOverviewPage: {
        value: { href: '/goat-ProductOverviewPage' },
      },
      OrderPage: {
        value: { href: '/goat-OrderPage' },
      },
      SuccessPage: {
        value: { href: '/goat-SuccessPage' },
      },
      CalculationParameters: { id: 'goat-id' },
      GenericErrorModal: {
        fields: mockGenericModalSources,
      },
      AddressNotFoundErrorModal: {
        fields: mockGenericModalSources,
      },
    },
  };

export const mockStoreFunnelSettingsFromSitecore: StoreFunnelSettingsFields = {
  componentName: { value: 'goat-componentName' },
  id: 'goat-id',
  fields: {
    ProductOverviewPage: {
      value: { href: '/goat-ProductOverviewPage' },
    },
    OrderPage: {
      value: { href: '/goat-OrderPage' },
    },
    SuccessPage: {
      value: { href: '/goat-SuccessPage' },
    },
    GenericErrorModal: {
      fields: mockGenericModalSources,
    },
    Integration: {
      value: '/home/some/page/integrationConfig',
    },
    ProductType: {
      value: 'goat',
    },
    ProductCategory: {
      value: 'milk',
    },
    HidePrices: {
      value: true,
    },
  },
};
