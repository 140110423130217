<div class="offer-highlighted-spacing" *ngIf="!offer?.isHighlighted"></div>
<wl-offer-label
  *ngIf="offer?.isHighlighted"
  wlAutomationId="offer-label"
  [offer]="offer"
>
</wl-offer-label>
<wl-partial-card
  *ngIf="offer; else loading"
  id="vertical-offer"
  wlAutomationId="vertical-offer"
  [borderRadius]="offer?.isHighlighted ? 'bottom' : 'all'"
>
  <div slot="header">
    <wl-partial-sticker-header
      [title]="offer?.productTitle"
      [subtitle]="offer?.productSubtitle"
      [sticker]="{
        src: offer?.incentiveImage ?? '',
        alt: 'sticker behorende bij offer',
      }"
    >
    </wl-partial-sticker-header>
  </div>
  <div slot="content">
    <div
      [class.mb-1]="
        showDurationTitle || (!showDurationTitle && !showOfferDurations)
      "
    ></div>

    <ng-container *ngIf="showDurationTitle && showOfferDurations">
      <div
        class="fw-bold"
        wlAutomationId="duration-title"
        *scText="rendering.fields.DurationTitle"
      ></div>
    </ng-container>

    <div class="items-container">
      <wl-vertical-offer-duration
        *ngIf="showOfferDurations && activeDuration"
        wlAutomationId="offer-duration"
        [durations]="durations"
        [activeDuration]="activeDuration"
        (changeDuration)="durationChanged($event)"
      ></wl-vertical-offer-duration>

      <div>
        <div
          class="d-flex justify-content-between mb-1"
          *ngIf="showTarrifModalButton"
        >
          <b *scText="rendering.fields.OfferBreakdownTitle"></b>
          <wl-vertical-offer-trigger-aom-button
            wlAutomationId="offer-trigger-aom-component"
            [rendering]="rendering"
            [isCompactMode]="toggleCompactMode"
            (showTariffs)="openAomModal.emit(offer)"
          ></wl-vertical-offer-trigger-aom-button>
        </div>
        <wl-offer-breakdown
          *ngIf="showBreakdown"
          wlAutomationId="offer-breakdown"
          [rendering]="rendering"
          [offer]="offer"
        ></wl-offer-breakdown>

        <wl-offer-budgetbill
          [rendering]="rendering"
          [offer]="offer"
          [hasMemberGetMember]="hasMemberGetMember"
        ></wl-offer-budgetbill>
      </div>

      <!-- border-0 is hacky, border zit hardcoded in toggle-with-info... -->
      <wl-toggle-with-info
        *ngIf="activeDuration && addOn && showProductAddons"
        [rendering]="rendering"
        class="d-flex bg-light rounded"
        [title]="{ value: addOn?.title }"
        [description]="{ value: addOn?.description }"
        [tooltip]="addOn?.tooltip"
        wlAutomationId="offer-add-on"
        [checked]="!!isAddOnChecked"
        (toggle)="addOnToggled($event)"
        containerClass="border-0"
      >
      </wl-toggle-with-info>

      <div>
        <button
          class="btn btn-sales-primary w-100"
          wlAutomationId="offer-submit-button"
          *scText="rendering.fields.OfferSubmitLabel"
          (click)="selectProposition.emit(offer)"
        ></button>
        <ng-container *ngIf="showOfferSubmitMicro">
          <p
            class="mt-1 mb-0 text-center"
            *scText="rendering.fields.OfferSubmitMicro"
          ></p>
        </ng-container>
      </div>

      <wl-vertical-offer-dropdown
        *ngIf="showTarrifBreakdownDropdown"
        wlAutomationId="offer-dropdown"
        [rendering]="rendering"
        [offer]="offer"
        (showTarifs)="openAomModal.emit($event)"
        [initialDropdownState]="xpEditorActive"
      ></wl-vertical-offer-dropdown>

      <ng-container *ngIf="showUspList">
        <hr class="m-0" />
        <wl-offer-usps [usps]="offer.usps"></wl-offer-usps>
        <hr class="m-0" />
      </ng-container>

      <ng-container *ngIf="showMoreInfoBlock">
        <wl-offer-more-info-block
          [rendering]="rendering"
          [offer]="offer"
          [moreInfoButtonClick]="moreInfoButtonClick"
        ></wl-offer-more-info-block>
      </ng-container>

      <hr class="m-0" />

      <wl-commodity-offer-calculation-based-on
        [variant]="'narrow'"
        [label]="rendering?.fields?.CalculationBasedOnLabel?.value"
        [address]="supplyAddressVm ?? undefined"
        [consumptions]="consumptionValuesVm"
        [electricityLabel]="'ElectricityUsageUnit' | translate"
        [gasLabel]="'GasUsageUnit' | translate"
        [adjustCalculationLabel]="
          rendering?.fields?.OpenModalAdjustCalculation?.value
        "
        (adjustCalculation)="openCalculateModal.emit($event)"
      ></wl-commodity-offer-calculation-based-on>
    </div>
  </div>
</wl-partial-card>

<ng-template #loading>
  <div wlAutomationId="skeleton-loader" class="border rounded bg-white p-4">
    <wl-svg-skeleton-loader
      [mask]="skeletonLoaderMask"
    ></wl-svg-skeleton-loader>
  </div>
</ng-template>
