<!-- Variant 0: minimal -->
<wl-basic-content-variant-minimal
  *ngIf="variant === 'minimal'"
  [content]="content"
  [settings]="settings"
  [context]="context"
  [cardConfig]="cardConfig"
>
</wl-basic-content-variant-minimal>

<!-- Variant 1: (sub)title + text | <ng-content> | buttons + microcopy -->
<wl-basic-content-variant-ng-content-between
  *ngIf="variant === 'ngContentBetween'"
  [content]="content"
>
  <ng-content></ng-content>
</wl-basic-content-variant-ng-content-between>

<!-- Variant 2: Vertical-Icon -->
<wl-basic-content-variant-vertical-icon
  *ngIf="variant === 'vertical-icon'"
  [content]="content"
  [settings]="settings"
  [context]="context"
  [cardConfig]="cardConfig"
></wl-basic-content-variant-vertical-icon>

<!-- Variant 3: Vertical Image -->
<wl-basic-content-variant-vertical-image
  *ngIf="variant === 'vertical-image'"
  [content]="content"
  [settings]="settings"
  [context]="context"
  [image]="image"
  [cardConfig]="cardConfig"
></wl-basic-content-variant-vertical-image>

<!-- Variant 4: Placeholder -->
<wl-basic-content-variant-placeholder
  *ngIf="variant === 'placeholder'"
  [content]="content"
  [settings]="settings"
  [context]="context"
  [rendering]="rendering"
  [cardConfig]="cardConfig"
></wl-basic-content-variant-placeholder>
