<nav
  class="navbar navbar-expand-md navbar-light pb-md-2 pb-lg-3 pt-3 px-0 bg-white"
>
  <div class="container justify-content-start">
    <div class="d-flex flex-md-column flex-lg-row">
      <a
        class="navbar-brand me-6 pb-lg-1"
        *wlGenericLink="
          { value: { href: brandLogoLinkUrl || '/' } };
          extras: { queryParamsHandling: 'preserve' }
        "
      >
        <img
          [src]="'assets/logo.svg?v=2' | deployUrl"
          class="navbar--logo"
          alt="logo"
        />
      </a>

      <div class="navbar-nav mx-n2 align-items-end mt-md-1 mt-lg-0">
        <ng-container *ngFor="let navItem of navItems">
          <ng-container
            *ngIf="
              navItem.children && navItem.children.length > 0;
              then dropdown;
              else item
            "
          ></ng-container>

          <ng-template #dropdown>
            <wl-navbar-dropdown
              [item]="navItem"
              [language]="language"
            ></wl-navbar-dropdown>
          </ng-template>
          <ng-template #item>
            <wl-navbar-item
              [navItem]="navItem"
              [language]="language"
              [class.text-brand]="navItem.active"
              [class.text-secondary]="!navItem.active"
            ></wl-navbar-item>
          </ng-template>
        </ng-container>
      </div>
    </div>
  </div>
</nav>
