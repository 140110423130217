import { createSelector } from '@ngrx/store';

import { eplusSolarPanelsFeature } from '../solar-panels-form.state';
import { solarPanelsProductOverviewKey } from './solar-panels-product-overview.reducer';

const selectSolarPanelsProductOverviewState = createSelector(
  eplusSolarPanelsFeature,
  (state) => state[solarPanelsProductOverviewKey]
);

export const selectSolarPanelsProductOverviewViewModel =
  selectSolarPanelsProductOverviewState;
