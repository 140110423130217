import type { OnChanges, SimpleChanges } from '@angular/core';
import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import type { FormControlState, FormControlValueTypes } from 'ngrx-forms';
import { FormState } from 'ngrx-forms';

import { IsInvalidPipe } from '../pipes/is-invalid.pipe';
import { IsValidPipe } from '../pipes/is-valid.pipe';

export type ValidationClass = 'is-valid' | 'is-invalid' | 'is-warning';

/**
 * Sets either is-valid or is-invalid based upon a formState
 */
@Directive({
  selector: '[wlValidationClasses]',
})
export class ValidationClassesDirective<T extends FormControlValueTypes>
  implements OnChanges
{
  @Input('wlValidationClasses')
  formState?: FormState<T>;

  @Input()
  hasWarning = false;

  @Input('wlValidationClassesSkipDirtyCheck')
  skipDirtyCheck = false;

  @Input()
  omitValidationClasses?: string[];

  private readonly element: HTMLInputElement;
  private readonly VALID_CLASS = 'is-valid';
  private readonly INVALID_CLASS = 'is-invalid';
  private readonly WARNING_CLASS = 'is-warning';
  private readonly VALIDATION_CLASSES = [
    this.VALID_CLASS,
    this.INVALID_CLASS,
    this.WARNING_CLASS,
  ];

  constructor(
    el: ElementRef,
    private readonly renderer: Renderer2,
    private readonly isValidPipe: IsValidPipe<T>,
    private readonly isInvalidPipe: IsInvalidPipe<T>
  ) {
    this.element = el.nativeElement;
  }

  ngOnChanges({ formState }: SimpleChanges) {
    if (!this.hasChanges(formState.previousValue, formState.currentValue)) {
      return;
    }

    const dirtyCheck = this.skipDirtyCheck
      ? true
      : formState.currentValue?.isDirty;

    if (formState.currentValue?.isTouched && dirtyCheck) {
      if (
        this.isValidPipe.transform(formState.currentValue, !this.skipDirtyCheck)
      ) {
        this.make(this.VALID_CLASS);
      } else if (
        this.isInvalidPipe.transform(
          formState.currentValue,
          !this.skipDirtyCheck
        )
      ) {
        if (this.hasWarning) {
          this.make(this.WARNING_CLASS);
        } else {
          this.make(this.INVALID_CLASS);
        }
      }
    } else {
      this.resetValidation();
    }
  }

  private hasChanges(
    previous?: FormControlState<T>,
    current?: FormControlState<T>
  ) {
    return (
      previous !== current ||
      previous?.isValid !== current?.isValid ||
      previous?.value !== current?.value ||
      previous?.isTouched !== current?.isTouched ||
      previous?.isDirty !== current?.isDirty
    );
  }

  private make(className: ValidationClass) {
    this.resetValidation();
    if (!this.omitValidationClasses?.some((v) => v === className)) {
      this.renderer.addClass(this.element, className);
    }
  }

  private resetValidation() {
    this.VALIDATION_CLASSES.forEach((validationClass) =>
      this.renderer.removeClass(this.element, validationClass)
    );
  }
}
