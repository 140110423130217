import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { combineEffectsWithProviders } from '@essent/common';

import { postChatTokenApi } from './api/post-chat-token.service';
import { API } from './shared/chat-token.model';

const { EFFECTS, PROVIDERS } = combineEffectsWithProviders(postChatTokenApi);

@NgModule({
  declarations: [],
  imports: [EffectsModule.forFeature(EFFECTS)],
  providers: PROVIDERS,
})
export class ChattingCoreModule {
  public constructor(
    @Optional()
    @SkipSelf()
    parentModule: ChattingCoreModule
  ) {
    if (parentModule) {
      throw new Error('ChattingCoreModule is imported more than once.');
    }
  }

  public static forRoot(config?: {
    api?: string;
  }): ModuleWithProviders<ChattingCoreModule> {
    return {
      ngModule: ChattingCoreModule,
      providers: [
        {
          provide: API,
          useValue: config ? config.api : undefined,
        },
      ],
    };
  }
}
