import { patchCommunicationAddressError } from '@essent/communication-address';
import { getCorrespondenceDetailsSuccess } from '@essent/customer';
import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';
import type { FormGroupState } from 'ngrx-forms';
import {
  createFormGroupState,
  markAsUnsubmitted,
  onNgrxForms,
  onNgrxFormsAction,
  reset,
  ResetAction,
  setValue,
  updateGroup,
  validate,
  wrapReducerWithFormStateUpdate,
  UnfocusAction,
} from 'ngrx-forms';
import { notEqualTo } from 'ngrx-forms/validation';

export const CHANGE_CONTACT_PERSON_FORM_ID = 'changeContactPersonForm';
export const contactPersonControlId = `${CHANGE_CONTACT_PERSON_FORM_ID}.contactPerson`;

export interface FormStateChangeContactPerson {
  contactPerson: string;
}

export interface State {
  changeContactPersonFormState: FormGroupState<FormStateChangeContactPerson>;
  technicalError: boolean;
  initialContactPerson?: string;
}

export const initialFormState: FormStateChangeContactPerson = {
  contactPerson: '',
};

export const initialFormGroupState =
  createFormGroupState<FormStateChangeContactPerson>(
    CHANGE_CONTACT_PERSON_FORM_ID,
    initialFormState
  );

export const initialState: State = {
  changeContactPersonFormState: initialFormGroupState,
  technicalError: false,
  initialContactPerson: '',
};

export const validateAndUpdateForms = (state: State) => {
  return updateGroup<FormStateChangeContactPerson>({
    contactPerson: validate(notEqualTo(state.initialContactPerson)),
  })(state.changeContactPersonFormState);
};

const _reducer = createReducer(
  initialState,
  onNgrxForms(),
  onNgrxFormsAction(ResetAction, (state, action) => {
    if (action.controlId === CHANGE_CONTACT_PERSON_FORM_ID) {
      const changeContactPersonFormState = setValue(
        state.changeContactPersonFormState,
        initialFormState
      );

      return {
        ...state,
        changeContactPersonFormState: reset(changeContactPersonFormState),
        technicalError: false,
      };
    }
    return state;
  }),
  onNgrxFormsAction(UnfocusAction, (state, action) => {
    if (action.controlId.startsWith(`${CHANGE_CONTACT_PERSON_FORM_ID}.`)) {
      const changeContactPersonFormState = validateAndUpdateForms(state);
      return {
        ...state,
        technicalError: false,
        changeContactPersonFormState,
      };
    }
    return state;
  }),
  on(patchCommunicationAddressError, (state) => {
    return {
      ...state,
      technicalError: true,
      changeContactPersonFormState: markAsUnsubmitted(
        state.changeContactPersonFormState
      ),
    };
  }),
  on(getCorrespondenceDetailsSuccess, (state, action) => {
    return {
      ...state,
      initialContactPerson:
        action.payload.correspondence.address &&
        action.payload.correspondence.address.careOfFullName,
    };
  })
);

export const wrappedReducer = wrapReducerWithFormStateUpdate(
  _reducer,
  (state) => state.changeContactPersonFormState,
  (_, state) => validateAndUpdateForms(state)
);

export function reducer(state: State = initialState, action: Action): State {
  return wrappedReducer(state, action);
}
