import type { DynamicPricesResponse } from '@essent/tariffs';
import { Brand } from '@integration/base-models';

import { dynamicPricesByDateResponseMock } from './dynamic-prices-by-date-response.mock';

export const dynamicPricesResponseMock: DynamicPricesResponse = {
  brand: Brand.ESSENT,
  prices: [
    dynamicPricesByDateResponseMock('2024-04-07'),
    dynamicPricesByDateResponseMock('2024-04-08'),
    dynamicPricesByDateResponseMock('2024-04-09'),
  ],
};
