import type { AsyncState } from '@essent/common';
import { Status } from '@essent/common';
import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';

import type { UserInfo } from '../account.model';
import { AuthenticationStatus } from '../account.model';
import {
  loadUserInfoSuccess,
  loadUserInfo,
  loadUserInfoError,
} from './account.actions';

export interface State extends AsyncState<UserInfo> {
  authenticated: AuthenticationStatus;
}

export const initialState: State = {
  status: Status.IDLE,
  authenticated: AuthenticationStatus.Pending,
};

const reducer = createReducer(
  initialState,
  on(loadUserInfo, (state) => ({
    ...state,
    status: Status.PENDING,
  })),
  on(loadUserInfoSuccess, (_, action) => ({
    status: Status.SUCCESS,
    data: action.payload.userinfo,
    authenticated: action.payload.authenticated
      ? AuthenticationStatus.Success
      : AuthenticationStatus.Failure,
  })),
  on(loadUserInfoError, (_, action) => ({
    status: Status.ERROR,
    data: undefined,
    error: action.payload,
    authenticated: AuthenticationStatus.Failure,
  }))
);

export function accountReducer(
  state: State = initialState,
  action: Action
): State {
  return reducer(state, action);
}

export const getStatus = (state: State) => state.status;
export const getData = (state: State) => state.data;
export const getError = (state: State) => state.error;
export const getAuthenticated = (state: State) => state.authenticated;
