<wl-composable-card [config]="cardConfig" class="list-hub">
  <div
    class="list-hub-header"
    [class.list-hub-header__brand]="subVariant === 'brand'"
    [class.list-hub-header__alt]="subVariant !== 'brand'"
  >
    <p class="h4 mb-0" *scText="listConfig?.title"></p>
  </div>
  <wl-image
    class="d-block"
    *ngIf="image"
    [imageSettings]="imageSettings"
  ></wl-image>
  <ul class="reset-list-styles">
    <li *ngFor="let item of listItems">
      <a *wlGenericLink="item.fields?.link" class="d-block">
        <wl-partial-list-item [iconTrailing]="'chevron-right'">
          <span slot="label" *wlGenericRichText="item.fields?.title"></span>
        </wl-partial-list-item>
      </a>
    </li>
  </ul>
</wl-composable-card>
