import { createSelector } from '@ngrx/store';
import {
  getConsumptionValuesEnergyTypes,
  getConsumptionValuesViewModel,
} from '@innogy/become-a-customer/shared';
import type { ConsumptionValuesVM } from '@innogy/become-a-customer/shared/interfaces';

import { getBecomeACustomerState } from '../../become-a-customer-feature.selector';

export const putConsumptionState = createSelector(
  getBecomeACustomerState,
  (state) => state.consumption.putConsumption
);

export const getConsumptionState = createSelector(
  getBecomeACustomerState,
  (state) => state.consumption.getConsumption
);

export const getConsumptionValuesVM = createSelector(
  getConsumptionState,
  (getConsumption): ConsumptionValuesVM | undefined =>
    getConsumptionValuesViewModel(getConsumption?.data)
);

export const getConsumptionEnergyTypes = createSelector(
  getConsumptionState,
  (getConsumption) => getConsumptionValuesEnergyTypes(getConsumption?.data)
);
