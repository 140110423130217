import { Component, Input } from '@angular/core';
import { ConsumptionValuesVM } from '@innogy/become-a-customer/shared/interfaces';

@Component({
  selector: 'wl-order-overview-consumption[consumptionValues]',
  templateUrl: './order-overview-consumption.component.html',
  styleUrls: [
    './order-overview-consumption.component.ed.scss',
    './order-overview-consumption.component.essent.scss',
  ],
})
export class OrderOverviewConsumptionComponent {
  @Input()
  consumptionValues!: ConsumptionValuesVM;
}
