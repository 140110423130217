import { NgModule } from '@angular/core';
import { TemporaryCoreModulesModule } from '@innogy/eplus/temporary-core-modules';
import { provideBootstrapEffects } from '@innogy/utils-state';

import { GenericFormEffects } from './generic-form/generic-form.effects';

@NgModule({
  imports: [TemporaryCoreModulesModule],
  providers: [provideBootstrapEffects([GenericFormEffects])],
})
export class SitecoreFormsStoreModule {}
