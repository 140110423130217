import type { Action, ActionReducer } from '@ngrx/store';
import type { FormGroupState, KeyValue } from 'ngrx-forms';
import { wrapReducerWithFormStateUpdate } from 'ngrx-forms';

export function wrappedFormReducer<T1 extends KeyValue, T2 extends KeyValue>(
  reducer: ActionReducer<T1, Action>,
  validateForm: (state: any) => FormGroupState<T2>,
  formatForm?: (state: any) => FormGroupState<T2>
): ActionReducer<T1, Action> {
  return wrapReducerWithFormStateUpdate(
    reducer,
    (state: any) => state.formState,
    (_, state) => {
      if (formatForm) {
        return formatForm({
          ...state,
          formState: validateForm(state),
        });
      } else {
        return validateForm(state);
      }
    }
  );
}
