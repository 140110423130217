import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import type { BaseService } from '@essent/common';
import { API_PREFIX } from '@core/config-angular';
import type { Observable } from 'rxjs';

import type { SolarPanelsNewCustomerConfirmationRequestBodyV1 } from '../../models';

@Injectable()
export class PostSolarPanelsConfirmationService
  implements BaseService<SolarPanelsNewCustomerConfirmationRequestBodyV1, void>
{
  public readonly apiVersion = '1';
  public readonly endpoint = `${
    this.apiUrl || ''
  }/eplus/solarpanels/new-customer/confirmation/v${this.apiVersion}`;

  public constructor(
    @Optional()
    @Inject(API_PREFIX)
    private readonly apiUrl: string,
    private readonly httpClient: HttpClient
  ) {}

  public call$(
    payload: SolarPanelsNewCustomerConfirmationRequestBodyV1
  ): Observable<void> {
    return this.httpClient.post<void>(this.endpoint, payload);
  }
}
