import type { StreetAddress, SupplyAddress } from '@essent/new-customer';

export const getSupplyAddressViewModel = (
  supplyAddress?: SupplyAddress
): Partial<StreetAddress> | undefined => {
  if (supplyAddress) {
    return {
      ...supplyAddress,
      street:
        supplyAddress.street !== 'Unknown' ? supplyAddress.street : undefined,
      city: supplyAddress.city !== 'Unknown' ? supplyAddress.city : undefined,
    };
  }
  return undefined;
};
