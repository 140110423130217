import { createSelector } from '@ngrx/store';
import { Status } from '@essent/common';

import { getCustomerDetailsState } from './feature.selectors';
import * as fromUpdate from './update-communication-address.reducer';

const getUpdateState = createSelector(
  getCustomerDetailsState,
  (feature) => feature.update
);

const getUpdateStatus = createSelector(getUpdateState, fromUpdate.getStatus);

export const getUpdateCommunicationAddressWithStates = createSelector(
  getUpdateStatus,
  (status) => {
    const showError = status === Status.ERROR;
    const showLoading =
      (status === Status.IDLE || status === Status.PENDING) && !showError;
    const showSuccess = status === Status.SUCCESS;
    const showChangeForm = !showSuccess;

    return {
      showError,
      showLoading,
      showSuccess,
      showChangeForm,
    };
  }
);
