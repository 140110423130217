import type { Entries } from '@innogy/utils-state';
import type { ValidationErrors } from 'ngrx-forms';
import type { UploadableFile, UploadedFile } from '@core/file-upload';
import { isUploadedFile } from '@core/file-upload';

export interface FileValidator {
  maxSize?: true;
  fileType?: true;
}

declare module 'ngrx-forms' {
  export interface ValidationErrors {
    file?: FileValidator;
  }
}

/**
 * Validates that the given file is of an allowed file type and does not exceed maximum allowed size.
 * @param value
 */
export const fileValidator =
  (
    fileState: Entries<UploadableFile | UploadedFile>,
    options: { maxFileSize?: number; allowedFileTypes?: RegExp }
  ) =>
  (referenceId: string): ValidationErrors => {
    const errors: ValidationErrors = {};
    const uploadableFile = fileState[referenceId]?.entry;
    if (uploadableFile == null || isUploadedFile(uploadableFile)) {
      return errors;
    }

    if (
      options.maxFileSize != null &&
      uploadableFile.file.size > options.maxFileSize
    ) {
      if (errors.file == null) {
        errors.file = {};
      }

      errors.file.maxSize = true;
    }

    if (
      options.allowedFileTypes != null &&
      !options.allowedFileTypes.test(uploadableFile.file.type)
    ) {
      if (errors.file == null) {
        errors.file = {};
      }

      errors.file.fileType = true;
    }

    return errors;
  };
