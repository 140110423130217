import { Component } from '@angular/core';

import { MinimalHeaderComponent } from '../shared/minimal-header';

@Component({
  selector: 'wl-minimal-sticky-header',
  templateUrl: './minimal-sticky-header.component.html',
  styleUrls: ['./minimal-sticky-header.component.essent.scss'],
})
export class MinimalStickyHeaderComponent extends MinimalHeaderComponent {}
