import { createAction } from '@ngrx/store';

export const enum CalculateSharedTypes {
  SAVE_FORM_STATE = '[CalculateForm] Save form state',
  REVERT_FORM_STATE = '[CalculateForm] Revert form state',
}

export const SaveFormState = createAction(CalculateSharedTypes.SAVE_FORM_STATE);
export const RevertFormState = createAction(
  CalculateSharedTypes.REVERT_FORM_STATE
);
