import type { ToolTrackingConfig } from '@innogy/core/analytics';
import {
  createToolStepConfig,
  E2E_TRACKING_ID_KEY,
  parseToolConfig,
} from '@innogy/core/analytics';

import {
  ANALYTICS_KEY_ASBESTOS,
  ANALYTICS_KEY_CONSUMPTION,
  ANALYTICS_KEY_RENEWED_ROOF,
  ANALYTICS_KEY_ROOF_TYPE,
} from '../../constants';
import { SolarPanelsProductTrackingConfig } from '../solar-panels-products/solar-panels-product-overview.analytics-config';
import { selectSolarPanelsOrderFormAnalyticsPayload } from './solar-panels-order-form.selectors';

const solarPanelOrderFormSteps = [
  {
    step: 0,
    stepName: 'adres gegevens',
    id: 'adres gegevens',
  },
  {
    step: 1,
    stepName: 'persoonlijke gegevens',
    id: 'persoonlijke gegevens',
  },
  {
    step: 2,
    stepName: 'controleren',
    id: 'controleren',
  },
] as const;

export type SolarPanelOrderFormToolTrackingSteps =
  (typeof solarPanelOrderFormSteps)[number]['id'];

const solarPanelsOrderFormToolTrackingConfig: ToolTrackingConfig<SolarPanelOrderFormToolTrackingSteps> =
  {
    toolName: 'zonnepanelen lead formulier',
    steps: solarPanelOrderFormSteps,
    toolComplete: createToolStepConfig(
      selectSolarPanelsOrderFormAnalyticsPayload,
      (payload) => ({
        step: 3,
        payload: {
          [ANALYTICS_KEY_CONSUMPTION]: payload.consumption,
          [ANALYTICS_KEY_ASBESTOS]: payload.asbestos ?? '',
          [ANALYTICS_KEY_ROOF_TYPE]: payload.roofType ?? '',
          [ANALYTICS_KEY_RENEWED_ROOF]:
            payload.renewedRoofWithinTimeLimit ?? '',
          ['product']: payload.product
            ? SolarPanelsProductTrackingConfig.mapToCommerce(payload.product)
            : {},
          [E2E_TRACKING_ID_KEY]: payload.trackingId,
        },
      })
    ),
  };

export const parsedSolarPanelsOrderFormToolTrackingConfig = (
  toolName?: string
) =>
  parseToolConfig({
    ...solarPanelsOrderFormToolTrackingConfig,
    ...(toolName && { toolName }),
  });
