import { Component, Input } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'wl-partial-accordion-title',
  templateUrl: './partial-accordion-title.component.html',
  styleUrls: ['./partial-accordion-title.component.scss'],
})
export class PartialAccordionTitleComponent {
  @Input()
  icon?: IconProp;
  @Input()
  title?: string;
  @Input()
  after?: string;
}
