import {
  getActiveDuration,
  getActiveOffers,
  getActiveOfferset,
  getActiveOffersetPresentDurations,
  getActiveOffersetRetrievedDurations,
  getConsumptionValuesVM,
  getFunnelSettings,
  getSupplyAddressVM,
} from '@innogy/become-a-customer/state';
import { createSelector } from '@ngrx/store';

export const horizontalOfferComponentVm = createSelector(
  getFunnelSettings,
  getActiveOfferset,
  getActiveOffers,
  getActiveDuration,
  getActiveOffersetPresentDurations,
  getActiveOffersetRetrievedDurations,
  getConsumptionValuesVM,
  getSupplyAddressVM,
  (
    funnelSettings,
    activeOfferset,
    activeOffers,
    activeDuration,
    presentDurations,
    retrievedDurations,
    consumptionValues,
    supplyAddressVM
  ) => ({
    activeOfferset,
    activeOffers,
    activeDuration,
    presentDurations,
    retrievedDurations,
    regularOfferset: funnelSettings.offerset,
    addOn: funnelSettings.addOn,
    consumptionValues,
    supplyAddressVM,
  })
);
