<ng-container
  *ngIf="{
    topics: topics$ | async,
    slides: slides$ | async,
  } as vm"
>
  <div
    class="row"
    wlTrackImpression="contentblock-impression"
    [trackImpressionInfo]="rendering"
    [trackImpressionContext]="context"
  >
    <div class="col-xs-12 col-md-6">
      <div class="border w-100 position-relative">
        <ngb-carousel
          [interval]="0"
          [wrap]="false"
          *ngIf="vm.slides"
          #ngbCarousel
          [showNavigationArrows]="false"
          class="carousel"
          (swipeleft)="carousel?.next()"
          (swiperight)="carousel?.prev()"
          (slide)="onSlide($event)"
        >
          <ng-template
            ngbSlide
            id="{{ i + 1 }}"
            *ngFor="let slide of vm.slides; index as i; trackBy: trackBySlides"
          >
            <div class="carousel-slide">
              <img
                [src]="slide.image.src"
                [alt]="slide.image.alt"
                class="carousel-slide-image"
              />
              <div>
                <button
                  class="carousel-slide-button btn btn-primary"
                  (click)="openTopic(topic)"
                  *ngFor="let topic of slide.topics"
                  [title]="topic.title"
                  [ngStyle]="topicPosition(topic)"
                  wlTrackLink
                  [trackLinkInfo]="{
                    rendering: rendering,
                    field: topic.title,
                    linkurl: '',
                    type: 'button',
                    eventType: 'link-click',
                  }"
                >
                  {{ topic.topicIndex }}
                </button>
              </div>
            </div>
          </ng-template>
        </ngb-carousel>
        <div *ngIf="vm.slides as slides" wlAutomationId="arrows" class="arrows">
          <button
            [class.disabled]="activePage === 1"
            class="btn prev-arrow"
            (click)="carousel?.prev()"
          >
            <fa-icon icon="chevron-left"></fa-icon>
          </button>
          <button
            [class.disabled]="activePage === slides.length + 1"
            class="btn next-arrow"
            (click)="carousel?.next()"
          >
            <fa-icon icon="chevron-right"></fa-icon>
          </button>
        </div>
      </div>
      <wl-pagination
        class="d-flex justify-content-center mt-2"
        [collectionSize]="vm.slides?.length || 0"
        [rotate]="true"
        [maxSize]="2"
        [pageSize]="1"
        [ellipses]="true"
        [(page)]="activePage"
        [overrides]="{ prevText, nextText, summaryOnMobile: true, summaryText }"
      >
      </wl-pagination>
    </div>
    <div class="col-xs-12 col-md-6">
      <ul class="list-unstyled list-no-default-spacing">
        <li
          [class.border-top]="i === 0"
          class="d-flex p-2 flex align-items-center border-bottom"
          *ngFor="let topic of vm.topics; let i = index"
        >
          <span class="topic-title text-center h4 me-2 mb-0">{{
            topic.topicIndex
          }}</span>
          <button
            wlTrackLink
            [trackLinkInfo]="{
              rendering: rendering,
              field: topic.title,
              linkurl: '',
              type: 'text',
              eventType: 'link-click',
            }"
            class="btn btn-link m-0 px-0 text-primary"
            (click)="openTopic(topic)"
          >
            {{ topic.title }}
          </button>
        </li>
      </ul>
    </div>
  </div>
</ng-container>
