import type {
  ComponentFields,
  Field,
  RichTextField,
  TextField,
} from '@sitecore-jss/sitecore-jss-angular';

export enum GenericModalResults {
  CLOSE,
  PRIMARY,
  SECONDARY,
}

export enum GenericModalSources {
  AOM_CONTENT = 'aomContent',
  GENERIC_MODAL = 'generic',
  BAC_ERROR = 'bacErrorModal',
  RESIDENTIAL = 'changeResidentialFunctionModal',
  CHANGE_SUPPLY_ADDRESS = 'changeSupplyAddressModal',
  CHATBOT = 'chatbotModal',
  EXIT_FUNNEL = 'exitBacSalesFunnelModal',
  CONSUMPTION_EAN_MISMATCH_E = 'consumptionEanMismatchElectricityModal',
  CONSUMPTION_EAN_MISMATCH_G = 'consumptionEanMismatchGasModal',
  NO_OFFERS = 'noOffersModal',
  CONSUMPTION_TRESHOLD_EXCEEDED = 'consumptionTresholdExceededModal',
  INTENDED_USAGE_MISMATCH = 'intendedUsageMismatchModal',
  GENERIC_ERROR_MODAL = 'genericErrorModal',
  ADDRESS_NOT_FOUND_ERROR_MODAL = 'addressNotFoundErrorModal',
  CONSENT_SAVED_MODAL = 'consentSavedModal',
  CONSENT_ARE_YOU_SURE_MODAL = 'consentAreYouSureModal',
  INCOMPATIBLE_PROMOTION = 'incompatiblePromotionModal',
  DYNAMIC_OFFER = 'dynamicOfferModal',
  OFFER_EMAILED = 'offerEmailedModal',
  OFFER_EMAIL_FAILED = 'offerEmailFailedModal',
}

export enum GenericModalPosition {
  LEFT = 'modal--left',
  CENTER = 'modal--center',
  RIGHT = 'modal--right',
  BOTTOM = 'modal--bottom',
}

export interface GenericModalTrackingConfig {
  componentName: string;
  primaryButtonTrackingField?: string;
  secondaryButtonTrackingField?: string;
}

export interface GenericModalProps {
  source: GenericModalSources;
  title: string;
  body: string;
  tracking: GenericModalTrackingConfig;
  primaryButtonText?: string;
  primaryButtonStyle?: string;
  chatbotSelectorClass?: string;
  secondaryButtonText?: string;
  secondaryButtonStyle?: string;
  additionalPayload?: { [key: string]: any };
  interpolatablePayload?: { [key: string]: any };
  position?: GenericModalPosition;
  backdrop?: boolean;
  animation?: boolean;
  // Provide time in seconds
  autoCloseTime?: number;

  // We can provide additional properties in sitecore.
  [key: string]: any;
}

export interface CloseGenericModalProps {
  source: GenericModalSources;
  result: GenericModalResults;
  additionalPayload?: any;
}

export type GenericModalSitecoreSources = {
  Title?: TextField;
  Body?: RichTextField;
  PrimaryButtonText?: TextField;
  PrimaryButtonStyle?: Field;
  ChatbotSelectorClass?: TextField;
  SecondaryButtonText?: TextField;
  SecondaryButtonStyle?: Field;
  Position?: Field;
  /* Analytics */
  ComponentName?: TextField;
  PrimaryButtonName?: TextField;
  SecondaryButtonName?: TextField;
} & ComponentFields;

export type GenericModalPayloadMapping = {
  from: string;
  to: string;
};

export const emptyGenericModal: () => GenericModalProps = () => ({
  body: '',
  source: '' as GenericModalSources,
  title: '',
  tracking: {
    componentName: '',
  },
});
