import { Component, Input } from '@angular/core';
import type { NavigationExtras } from '@angular/router';

import { BasicContentVariantInheritableComponent } from '../../variants/basic-content-variant.inheritable.component';

@Component({
  selector: 'wl-basic-content-partial-top-between[content][settings][context]',
  templateUrl: './basic-content-partial-top-between.component.html',
  styleUrls: [
    './basic-content-partial-top-between.component.ed.scss',
    './basic-content-partial-top-between.component.essent.scss',
  ],
})
export class BasicContentPartialTopBetweenComponent extends BasicContentVariantInheritableComponent {
  @Input() showIconInHeader = true;

  public navigationExtras: NavigationExtras = {
    queryParamsHandling: 'merge',
  };
}
