import type { NgrxValueConverter } from 'ngrx-forms';

import { formatUppercase } from './format-uppercase';

export type UppercaseConverter = NgrxValueConverter<
  string | null,
  string | null
>;

export const uppercaseConverter: UppercaseConverter = {
  convertViewToStateValue: (value: string) => formatUppercase(value),
  convertStateToViewValue: (value: string) => formatUppercase(value),
};
