import { Component, Input } from '@angular/core';
import type { TariffPeriodForDropdown } from '@innogy/become-a-customer/shared/interfaces';
@Component({
  selector: 'ed-commodity-vertical-offer-dropdown-table',
  templateUrl: './commodity-vertical-offer-dropdown-table.component.html',
  styleUrls: ['./commodity-vertical-offer-dropdown-table.component.scss'],
})
export class CommodityVerticalOfferDropdownTableComponent {
  @Input() energyTitle?: string;
  @Input() fixedRedeliveryTitle?: string;
  @Input() fixedDeliveryCostLabel?: string;
  @Input() fixedRedeliveryCostLabel?: string;
  @Input() fixedDeliveryCostValue?: number;
  @Input() fixedRedeliveryCostValue?: number;
  @Input() fixedRedeliveryCostScaleValue?: string;
  @Input() tariffPeriods?: TariffPeriodForDropdown[];
  @Input() dynamicPricingExpectedDeliveryRateTitle?: string;
}
