import type { AsyncState } from '@essent/common';
import {
  createAsyncStateReducer,
  createInitialAsyncState,
} from '@essent/common';
import type { GetBudgetBill } from '@essent/new-customer';
import {
  getBudgetBill,
  getBudgetBillClear,
  getBudgetBillError,
  getBudgetBillSuccess,
  putBudgetBill,
  putBudgetBillClear,
  putBudgetBillError,
  putBudgetBillSuccess,
} from '@essent/new-customer';
import type { Action, ActionReducerMap } from '@ngrx/store';
import { combineReducers } from '@ngrx/store';

export type PutBudgetBillState = AsyncState<void>; // FIXME, FlowId ?
export type GetBudgetBillState = AsyncState<GetBudgetBill>;

export interface BudgetBillState {
  putBudgetBill: PutBudgetBillState;
  getBudgetBill: GetBudgetBillState;
}

const initialState: BudgetBillState = {
  putBudgetBill: createInitialAsyncState(),
  getBudgetBill: createInitialAsyncState(),
};

const _putBudgetBillReducer = createAsyncStateReducer(
  putBudgetBill,
  putBudgetBillSuccess,
  putBudgetBillError,
  putBudgetBillClear
);

const _getBudgetBillReducer = createAsyncStateReducer(
  getBudgetBill,
  getBudgetBillSuccess,
  getBudgetBillError,
  getBudgetBillClear
);

const _reducers: ActionReducerMap<BudgetBillState, Action> = {
  putBudgetBill: _putBudgetBillReducer,
  getBudgetBill: _getBudgetBillReducer,
};

const _combinedReducers = combineReducers(_reducers);

export function budgetBillReducer(
  state: BudgetBillState = initialState,
  action: Action
): BudgetBillState {
  return _combinedReducers(state, action);
}
