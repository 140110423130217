import type { ModuleWithProviders } from '@angular/core';
import { ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core';
import { IS_SITECORE_SERVER } from '@core/jss-models';
import { CoreLoaderModule } from '@innogy/core/loader';
import * as Sentry from '@sentry/angular';

export interface ClientConfig {
  loaderColor: string;
}

@NgModule({
  imports: [CoreLoaderModule],
  exports: [CoreLoaderModule],
})
export class CoreModulesCommonClientModule {
  public constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModulesCommonClientModule
  ) {
    if (parentModule) {
      throw new Error(
        'CoreModulesCommonClientModule is imported more than once.'
      );
    }
  }

  static forRoot(): ModuleWithProviders<CoreModulesCommonClientModule> {
    return {
      ngModule: CoreModulesCommonClientModule,
      providers: [
        {
          provide: IS_SITECORE_SERVER,
          useValue: false,
        },
        {
          provide: ErrorHandler,
          useValue: Sentry.createErrorHandler(),
        },
      ],
    };
  }
}
