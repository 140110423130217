import { CreateOnlineAccountGuard } from '@innogy/create-online-account/store';
import type { ComponentNameAndModule } from '@sitecore-jss/sitecore-jss-angular';

const createOnlineAccountLazyImports: ComponentNameAndModule[] = [
  {
    path: 'CreateOnlineAccountForm',
    loadChildren: () =>
      import('./lib/create-online-account-lazy.module').then(
        (mod) => mod.CreateOnlineAccountLazyModule
      ),
    canActivate: [CreateOnlineAccountGuard],
  },
];

export { createOnlineAccountLazyImports };
