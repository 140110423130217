import { NgModule } from '@angular/core';
import { DefaultComponentModule } from '@innogy/core/modules/components';

import { MemberGetMemberWidgetComponent } from './member-get-member-widget/member-get-member-widget.component';

const components = [MemberGetMemberWidgetComponent];

@NgModule({
  imports: [DefaultComponentModule],
  declarations: components,
  exports: components,
})
export class MemberGetMemberModule {}
