import type { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import type { GenericLightboxProps } from '../generic-lightbox.model';

export const initializeGenericLightbox = (
  modal: NgbModalRef,
  props: GenericLightboxProps
): void => {
  modal.componentInstance.title = props.title;
  modal.componentInstance.body = props.body;
  modal.componentInstance.url = props.url;
  modal.componentInstance.image = props.image;
  modal.componentInstance.imageLabel = props.imageLabel;
  modal.componentInstance.tracking = props.tracking;
  modal.componentInstance.cta = props.cta;
};
