import { NgModule } from '@angular/core';
import { DefaultComponentModule } from '@innogy/core/modules/components';
import { NgrxFormsModule } from 'ngrx-forms';
import { ProgressiveNgrxFormsModule } from '@innogy/progressive-ngrx-forms';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';

import { SimpleProgressiveFormCardComponent } from './components/simple-progressive-form-card/simple-progressive-form-card.component';
import { ShowProgressiveFormsIconPipe } from './pipes/show-progressive-forms-icon.pipe';
import { ShowProgressiveFormsEditButtonPipe } from './pipes/show-progressive-forms-edit-button.pipe';
import { ShowProgressiveFormsSeparatorPipe } from './pipes/show-progressive-forms-separator.pipe';
import { ShowProgressiveFormsSummaryPipe } from './pipes/show-progressive-forms-summary.pipe';
import { ShowProgressiveFormsFormPipe } from './pipes/show-progressive-forms-form.pipe';
import { FocusOnFirstChildControlDirective } from './directives/focus-on-first-child-control.directive';

@NgModule({
  imports: [
    DefaultComponentModule,
    NgrxFormsModule,
    ProgressiveNgrxFormsModule,
    NgbCollapseModule,
  ],
  declarations: [
    SimpleProgressiveFormCardComponent,
    ShowProgressiveFormsIconPipe,
    ShowProgressiveFormsEditButtonPipe,
    ShowProgressiveFormsSeparatorPipe,
    ShowProgressiveFormsSummaryPipe,
    ShowProgressiveFormsFormPipe,
    FocusOnFirstChildControlDirective,
  ],
  exports: [SimpleProgressiveFormCardComponent, ProgressiveNgrxFormsModule],
})
export class CommonUiProgressiveFormsModule {}
