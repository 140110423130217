import type {
  StoreFunnelSettingsInterface,
  StoreFunnelStep,
} from '@innogy/eplus/models';
import type { StoreProductData } from '@innogy/eplus/temporary-core-modules';
import { createAction, props } from '@ngrx/store';

import { createNamedFunnelActions } from '../../shared/generators';

const feature = 'Store';

export const {
  initializeStoreFunnelStepAction,
  clearStoreFunnelInitializedAction,
  setStoreFunnelInitializedAction,
  onStoreFunnelNavigationAction,
  handleStoreFunnelPrivateErrorAction,
  handleStoreFunnelGenericErrorAction,
  selectStoreProductAction,
  selectStoreProductsAction,
  toggleStoreProductSelectionAction,
  resetStoreProductSelectionAction,
  confirmStoreProductSelectionAction,
  mintStoreTrackingIdAction,
} = createNamedFunnelActions<
  typeof feature,
  StoreFunnelStep,
  StoreFunnelSettingsInterface,
  StoreProductData
>(feature);

export const storeAssociatedGenericFormIdAction = createAction(
  `[${feature} Funnel] store associated generic form id`,
  props<{ formId: string; instanceId: string }>()
);
