import { Injectable } from '@angular/core';
import type { SolarPanelsNewCustomerAddressCheckRequestQueryV1 } from '@innogy/eplus/temporary-core-modules';
import {
  getSolarPanelsAddressCheck,
  getSolarPanelsAddressCheckError,
  getSolarPanelsAddressCheckSuccess,
} from '@innogy/eplus/temporary-core-modules';
import type { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { MarkAsSubmittedAction, SetValueAction } from 'ngrx-forms';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import {
  expectedConsumptionDefinedAction,
  UsageQuestionnaireModalComponent,
} from '@innogy/common-ui/sales';

import {
  continueFlowFromSolarPanelsWizardAction,
  handleSolarPanelsFunnelAddressNotFoundErrorAction,
  handleSolarPanelsFunnelPrivateErrorAction,
  navigateToHouseHasInsufficientRoofAreaPageAction,
  navigateToHouseIsMonumentPageAction,
  navigateToHouseIsUnsuitablePageAction,
  navigateToProductOverviewPageAction,
  navigateToResultOverviewPageAction,
  navigateToSuitabilityCheckPageAction,
  resetSolarPanelsProductSelectionAction,
  selectSolarPanelsFunnelSettings,
} from '../solar-panels-funnel';
import { resetSolarPanelsOrderFormAction } from '../solar-panels-order-form/solar-panels-order-form.actions';
import { hideSolarPanelsProductOverviewAction } from '../solar-panels-products';
import {
  hideSolarPanelsSuitabilityCheckFormAction,
  resetSuitabilityFormAction,
  showSolarPanelsSuitabilityCheckFormAction,
} from '../solar-panels-suitability-check';
import {
  solarPanelsWizardFormSubmitAction,
  solarPanelsWizardQuestionnaireCloseModalAction,
  solarPanelsWizardQuestionnaireOpenModalAction,
} from './solar-panels-wizard.actions';
import {
  solarPanelsWizardFormConsumptionControlId,
  solarPanelsWizardFormId,
} from './solar-panels-wizard.reducer';
import { selectSolarPanelsWizardViewModel } from './solar-panels-wizard.selectors';
import { getUsageQuestionnaireFormState } from '../usage-questionnaire-modal';
import { SOLAR_USAGE_QUESTIONNAIRE_ID } from '../solar-panels-form.state';

@Injectable()
export class SolarPanelsWizardEffects {
  funnelSettings$ = this.store$.select(selectSolarPanelsFunnelSettings);
  solarPanelsWizardState$ = this.store$.select(
    selectSolarPanelsWizardViewModel
  );
  private solarPanelsWizardModal?: NgbModalRef;

  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store,
    private readonly modalService: NgbModal
  ) {}

  /**
   * On submit,
   * dispatch a mark as submitted action.
   * @todo: move to updatefn in reducer when the extending supports this.
   */
  public readonly submitSolarPanelsWizardForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(solarPanelsWizardFormSubmitAction),
      map(() => new MarkAsSubmittedAction(solarPanelsWizardFormId))
    )
  );

  public readonly fallThroughToHouseIsUnsuitablePage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(solarPanelsWizardFormSubmitAction),
      concatLatestFrom(() => [
        this.solarPanelsWizardState$,
        this.funnelSettings$,
      ]),
      // only break out when both the incorrect value is passed and a page is configured in sitecore.
      filter(
        ([, { formState }, { houseIsUnsuitablePage }]) =>
          formState.isValid &&
          formState.value.houseType !== 'bought_with_own_roof' &&
          !!houseIsUnsuitablePage?.href
      ),
      map(([, , { houseIsUnsuitablePage }]) =>
        navigateToHouseIsUnsuitablePageAction({
          pageData: houseIsUnsuitablePage,
        })
      )
    )
  );

  /**
   * On submit,
   * reset everything &
   * dispatch address check.
   */
  public readonly submitSolarPanelsWizardFormSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(solarPanelsWizardFormSubmitAction),
      concatLatestFrom(() => [
        this.solarPanelsWizardState$,
        this.funnelSettings$,
      ]),
      // continue when:
      // formState is valid AND ( own roof, OR no fallthrough page configured - for backwards compatibility)
      filter(
        ([_, state, { houseIsUnsuitablePage }]) =>
          state.formState.isValid &&
          (state.formState.value.houseType === 'bought_with_own_roof' ||
            !houseIsUnsuitablePage?.href)
      ),
      mergeMap(([_, { formState }, funnelSettings]) => {
        if (!funnelSettings.id) {
          return [
            handleSolarPanelsFunnelPrivateErrorAction({
              message: 'id is not defined on FunnelSettings',
            }),
          ];
        }
        const {
          postalCode,
          communicationNumber,
          houseNumberAddition,
          consumption,
        } = formState.value;
        return [
          hideSolarPanelsSuitabilityCheckFormAction(),
          resetSuitabilityFormAction(),
          hideSolarPanelsProductOverviewAction(),
          resetSolarPanelsProductSelectionAction(),
          resetSolarPanelsOrderFormAction(),
          getSolarPanelsAddressCheck({
            payload: {
              postalcode: postalCode,
              housenumber: communicationNumber.toString(),
              housenumberaddition: houseNumberAddition,
              consumption: consumption.toString(),
              funnelsettingsId: funnelSettings.id,
            } as SolarPanelsNewCustomerAddressCheckRequestQueryV1,
          }),
        ];
      })
    )
  );

  /**
   * Determines whether or not the flow can be continued after submission,
   * or if we need to be routed to a non-suitability page.
   */
  public readonly determineIfFlowCanContinueAfterSubmission$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(getSolarPanelsAddressCheckSuccess),
        concatLatestFrom(() => this.funnelSettings$),
        mergeMap(
          ([
            action,
            { houseIsMonumentPage, houseHasInsufficientRoofAreaPage },
          ]) => {
            const houseIsMonument = action.payload.criteria.isMonument;
            const houseHasInsufficientRoofArea =
              !action.payload.criteria.hasSufficientRoofArea;

            // If a house is monumental and a page is configured
            // for people to be sent to in this edge-case
            // the flow is aborted and the prospect is routed.
            if (houseIsMonument && houseIsMonumentPage?.href) {
              return [
                navigateToHouseIsMonumentPageAction({
                  pageData: houseIsMonumentPage,
                }),
              ];

              // If a house has insufficient roof area and a page is configured
              // for people to be sent to in this edge-case
              // the flow is aborted and the prospect is routed.
            } else if (
              houseHasInsufficientRoofArea &&
              houseHasInsufficientRoofAreaPage?.href
            ) {
              return [
                navigateToHouseHasInsufficientRoofAreaPageAction({
                  pageData: houseHasInsufficientRoofAreaPage,
                }),
              ];

              // In any other case, we can continue the flow.
            } else {
              return [continueFlowFromSolarPanelsWizardAction()];
            }
          }
        )
      )
  );

  /**
   * On success,
   * if the criteria pass,
   * and the suitability check step is enabled
   * dispatch an action that indicates we want to move to that page.
   */
  public readonly navigateToSuitabilityCheckPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(continueFlowFromSolarPanelsWizardAction),
      concatLatestFrom(() => this.funnelSettings$),
      filter(([, { showSuitabilityCheckPage }]) => !!showSuitabilityCheckPage),
      mergeMap(([, { suitabilityCheckPage }]) => [
        showSolarPanelsSuitabilityCheckFormAction(),
        navigateToSuitabilityCheckPageAction({
          pageData: suitabilityCheckPage,
        }),
      ])
    )
  );

  /**
   * On success,
   * if the criteria pass,
   * and the suitability check page is disabled,
   * and the resultpage is enabled,
   * and the product page is disabled,
   * dispatch an action that indicates we want to move to that page.
   */
  public readonly navigateToResultOverviewPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(continueFlowFromSolarPanelsWizardAction),
      concatLatestFrom(() => this.funnelSettings$),
      filter(
        ([, funnelSettings]) =>
          !funnelSettings.showSuitabilityCheckPage &&
          !funnelSettings.showProductOverviewPage &&
          !!funnelSettings.showResultOverviewPage
      ),
      map(([, { resultOverviewPage }]) =>
        navigateToResultOverviewPageAction({
          pageData: resultOverviewPage,
        })
      )
    )
  );

  /**
   * On success,
   * if the criteria pass,
   * and the suitability check page is disabled,
   * and the product page is enabled,
   * dispatch an action that indicates we want to move to that page.
   */
  public readonly navigateToProductOverviewPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(continueFlowFromSolarPanelsWizardAction),
      concatLatestFrom(() => this.funnelSettings$),
      filter(
        ([, funnelSettings]) =>
          !funnelSettings.showSuitabilityCheckPage &&
          !!funnelSettings.showProductOverviewPage
      ),
      map(([, funnelSettings]) =>
        navigateToProductOverviewPageAction({
          pageData: funnelSettings.productOverviewPage,
        })
      )
    )
  );

  /**
   * On error,
   * if the action is a 404
   * dispatch an Address not found error action
   */
  public readonly onAddressCheckError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSolarPanelsAddressCheckError),
      filter((action) => action.payload.status === 404),
      map(() => handleSolarPanelsFunnelAddressNotFoundErrorAction())
    )
  );

  /**
   * Effect that opens the Solar Panels Usage Wizard.
   * Uses the generic ModalService to spawn a modal.
   */
  public readonly openSolarPanelsWizardQuestionnaireModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(solarPanelsWizardQuestionnaireOpenModalAction),
        concatLatestFrom(() => this.funnelSettings$),
        tap(([_, funnelSettings]) => {
          this.solarPanelsWizardModal = this.modalService.open(
            UsageQuestionnaireModalComponent,
            {
              centered: true,
              keyboard: true,
            }
          );

          this.solarPanelsWizardModal.componentInstance.formState$ =
            this.store$.select(getUsageQuestionnaireFormState);
          this.solarPanelsWizardModal.componentInstance.rendering =
            funnelSettings.usageWizard;
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect that closes the Solar Panels Usage Wizard.
   */
  public readonly closeSolarPanelsWizardQuestionnaireModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(solarPanelsWizardQuestionnaireCloseModalAction),
        tap(() => {
          this.solarPanelsWizardModal?.close();
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect that applies the estimate base on the questionnaire modal to the solar panel form.
   */
  public applyConsumptionEstimateToSolarPanelWizardForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(expectedConsumptionDefinedAction),
      filter((action) => action.formId === SOLAR_USAGE_QUESTIONNAIRE_ID),
      mergeMap((action) => {
        return [
          new SetValueAction(
            solarPanelsWizardFormConsumptionControlId,
            action.expectedConsumption.electricity.total
          ),
          solarPanelsWizardQuestionnaireCloseModalAction(),
        ];
      })
    )
  );
}
