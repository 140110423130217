<div class="row" [class.d-none]="collapsible" [class.d-md-flex]="collapsible">
  <div
    *ngFor="let accordion of accordions; trackBy: trackByItems"
    class="col-12 col-md mb-4 mb-md-0"
  >
    <p class="links__title mb-1 font-size-lg fw-bold">{{ accordion.title }}</p>
    <nav [attr.aria-label]="accordion.title">
      <wl-list [list]="accordion.links" ulClass="list-unstyled mb-0"></wl-list>
    </nav>
  </div>
</div>

<div class="accordion">
  <div
    *ngFor="let accordion of accordions; trackBy: trackByItems"
    class="accordion__item"
    [class.d-md-none]="collapsible"
    [class.d-none]="!collapsible"
  >
    <p
      (click)="accordion.collapsed = !accordion.collapsed"
      [ngClass]="{ show: !accordion.collapsed }"
      class="accordion__item-header fw-bold font-size-lg"
    >
      {{ accordion.title }}
      <fa-icon
        icon="chevron-up"
        class="icon-chevron d-md-none float-end"
      ></fa-icon>
    </p>
    <div [ngbCollapse]="accordion.collapsed">
      <nav [attr.aria-label]="accordion.title">
        <wl-list
          [list]="accordion.links"
          ulClass="list-unstyled mb-0"
        ></wl-list>
      </nav>
    </div>
  </div>
</div>
