import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CommonUiFormsModule } from '@innogy/common-ui/forms';
import { CommonUiPartialsModule } from '@innogy/common-ui/partials';
import { CommonUiUtilityComponentsModule } from '@innogy/common-ui/utility-components';
import { CoreAnalyticsModule } from '@innogy/core/analytics';
import { AutomationIdDirective } from '@innogy/utils-automation-id';
import { UtilsModule } from '@innogy/utils-deprecated';
import { UtilsContentsquareModule } from '@innogy/utils/contentsquare';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslatePipe, TranslateDirective } from '@ngx-translate/core';
import { JssModule } from '@sitecore-jss/sitecore-jss-angular';
import { NgrxFormsModule } from 'ngrx-forms';

import { ProgressiveFormBodyDirective } from './progressive-form-body.directive';
import { ProgressiveFormContentStepWrapperComponent } from './progressive-form-content-step-wrapper/progressive-form-content-step-wrapper.component';
import { ProgressiveFormStepCardComponent } from './progressive-form-step-card/progressive-form-step-card.component';
import { ProgressiveFormStepWrapperComponent } from './progressive-form-step-wrapper/progressive-form-step-wrapper.component';
import { ProgressiveStepSingleMultivalueQuestionComponent } from './progressive-form-steps/progressive-step-single-multivalue-question/progressive-step-single-multivalue-question.component';
import { ProgressiveStepSingleNumberQuestionComponent } from './progressive-form-steps/progressive-step-single-number-question/progressive-step-single-number-question.component';
import { ProgressiveStepSingleOptionQuestionComponent } from './progressive-form-steps/progressive-step-single-option-question/progressive-step-single-option-question.component';
import { ProgressiveFormSubmitButtonComponent } from './progressive-form-submit-button/progressive-form-submit-button.component';
import { ProgressiveFormSummaryDirective } from './progressive-form-summary.directive';
import { ProgressiveFormValueProgressButtonComponent } from './progressive-form-value-progress-button/progressive-form-value-progress-button.component';

const components = [
  ProgressiveFormStepWrapperComponent,
  ProgressiveFormContentStepWrapperComponent,
  ProgressiveFormStepCardComponent,
  ProgressiveFormBodyDirective,
  ProgressiveFormSummaryDirective,
  ProgressiveFormSubmitButtonComponent,
  ProgressiveFormValueProgressButtonComponent,
  ProgressiveStepSingleOptionQuestionComponent,
  ProgressiveStepSingleMultivalueQuestionComponent,
  ProgressiveStepSingleNumberQuestionComponent,
];
/**
 * @deprecated: use `CommonUiProgressiveFormsModule` instead.
 *
 * Please migrate all components to your own project when single-use.
 * If a component might be reusable, request review from the core team to your changes
 * in the `libs/common-ui/progressive-forms` project.
 */
@NgModule({
  imports: [
    CommonModule,
    CommonUiFormsModule,
    CommonUiPartialsModule,
    CommonUiUtilityComponentsModule,
    NgrxFormsModule,
    CoreAnalyticsModule,
    TranslatePipe,
    TranslateDirective,
    UtilsModule,
    FontAwesomeModule,
    NgbCollapseModule,
    JssModule,
    UtilsContentsquareModule,
    AutomationIdDirective,
  ],
  exports: components,
  declarations: components,
})
export class ProgressiveFormComponentsModule {}
