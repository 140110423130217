import type { Observable, OperatorFunction } from 'rxjs';
import { of } from 'rxjs';
import {
  filter,
  map,
  mergeMap,
  startWith,
  take,
  withLatestFrom,
} from 'rxjs/operators';

import { isNotNullish } from './is-not-nullish';

export type FlatMapOperator = <T, R>(
  f: (val: T) => Observable<R>
) => OperatorFunction<T, R>;

/**
 * Listen for events and combine these with data out store.
 * Normally this can be done with `withLatestFrom` operator, `waitForData` waits until the store selection returns a value.
 *
 * ##### Example:
 *
 * ```typescript
 * public init$: Observable<Action> =
 *  createEffect(() =>
 *    this.actions$.pipe(
 *      ofType<Init>(Types.INIT),
 *      waitForData(this.store.pipe(select(selectData))),
 *      mergeMap(([_, data]) => [
 *        new SelectData({
 *          id: data.id,
 *        }),
 *      ])
 *    )
 *  );
 * ```
 *
 * @param data$
 * @param mapper FlatMap operator used, default is mergeMap
 * @return Observable<[T, R]>
 */

export function waitForData<T, R>(
  data$: Observable<R>,
  mapper: FlatMapOperator = mergeMap
) {
  return function (source$: Observable<T>) {
    const wait = (e: T) =>
      data$.pipe(
        filter(isNotNullish),
        map((d): [T, NonNullable<R>] => [e, d]),
        take(1)
      );

    return source$.pipe(
      withLatestFrom(data$.pipe(startWith(null))),
      mapper(([e, d]) =>
        isNotNullish(d) ? of<[T, NonNullable<R>]>([e, d]) : wait(e)
      )
    );
  };
}
