<div
  class="carousel d-flex overflow-hidden"
  (scroll)="onScroll()"
  wlAutomationId="slides"
  #slider
>
  <ng-container *ngFor="let image of images">
    <img
      *scImage="image"
      class="carousel__image sliced-corner-right flex-shrink-0 w-75 flex-basis-75"
      #slide
    />
  </ng-container>
</div>
<div class="d-flex justify-content-center mt-2 position-relative">
  <div wlAutomationId="slide-indicators">
    <ng-container *ngFor="let image of images; let i = index">
      <button
        class="btn btn-slide-indicator me-1"
        [class.active]="i === currentSlideIndex"
        (click)="goToSlide(i)"
      ></button>
    </ng-container>
  </div>
  <div class="position-absolute right-0 me-2 me-sm-0 slide-controls">
    <button
      class="btn btn-link p-0 me-3"
      (click)="previousSlide()"
      [disabled]="!hasPreviousSlide()"
    >
      <fa-icon icon="chevron-left" size="lg"></fa-icon>
    </button>
    <button
      class="btn btn-link p-0"
      (click)="nextSlide()"
      [disabled]="!hasNextSlide()"
    >
      <fa-icon icon="chevron-right" size="lg"></fa-icon>
    </button>
  </div>
</div>
