import { Component, Input } from '@angular/core';
import { InnogyComponentRendering } from '@core/jss-models';
import { LocationService } from '@core/jss-seo';
import { ColumnContext } from '@innogy/common-ui/shared/interfaces';

@Component({
  selector: 'wl-taxonomy-container',
  templateUrl: './taxonomy-container.component.html',
  styleUrls: [],
})
export class TaxonomyContainerComponent {
  @Input() rendering?: InnogyComponentRendering;
  @Input() context?: ColumnContext;

  selectedCategory: InnogyComponentRendering | undefined;

  constructor(private readonly locationService: LocationService) {}

  setSelectedCategory(category: InnogyComponentRendering): void {
    this.selectedCategory = category;
  }

  public navigateToLink(href: string | undefined): void {
    this.locationService.navigateGeneralLink(href || '');
  }
}
