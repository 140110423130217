<ng-container *ngIf="consumptionValues.electricity as electricity">
  <div *ngIf="electricity.supplyLow; else single" class="d-flex flex-column">
    <span
      *ngIf="electricity.supplyNormal as amount"
      wlAutomationId="electricitySupplyHigh"
      class="pe-2 d-flex align-items-center"
      wlCSCapture
    >
      <fa-icon
        icon="sun"
        class="fa-icon me-2 consumption-icon"
        size="lg"
      ></fa-icon>
      {{ amount }} {{ 'ElectricityUsageUnit' | translate }}</span
    >
    <span
      *ngIf="electricity.supplyLow as amount"
      wlAutomationId="electricitySupplyLow"
      class="pe-2 d-flex align-items-center"
      wlCSCapture
    >
      <fa-icon
        icon="moon"
        class="fa-icon me-2 consumption-icon"
        size="lg"
      ></fa-icon>
      {{ amount }} {{ 'ElectricityUsageUnit' | translate }}
    </span>
  </div>
  <ng-template #single>
    <span
      *ngIf="electricity.supplyNormal as amount"
      wlAutomationId="electricitySupplyNormal"
      class="pe-2 d-flex align-items-center"
      wlCSCapture
    >
      <fa-icon
        icon="plug"
        class="fa-icon me-2 consumption-icon"
        size="lg"
      ></fa-icon>
      {{ amount }} {{ 'ElectricityUsageUnit' | translate }}
    </span>
  </ng-template>
</ng-container>

<div *ngIf="consumptionValues.gas" wlAutomationId="gasSupply">
  <span class="pe-2 d-flex align-items-center" wlCSCapture>
    <fa-icon
      icon="fire-flame"
      class="fa-icon me-2 consumption-icon"
      size="lg"
    ></fa-icon>
    {{ consumptionValues.gas }}
    <span [innerHtml]="'GasUsageUnit' | translate" wlCSCapture></span>
  </span>
</div>

<div
  *ngIf="consumptionValues.electricity.returnSupplyNormal as returnSupply"
  wlAutomationId="returnSupplyNormal"
>
  <span class="pe-2 d-flex align-items-center" wlCSCapture>
    <fa-icon
      icon="solar-panel"
      class="fa-icon me-2 consumption-icon"
      size="lg"
    ></fa-icon>
    {{ returnSupply }} {{ 'ElectricityUsageUnit' | translate }}
  </span>
</div>
