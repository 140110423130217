import { createSelector } from '@ngrx/store';
import { E2E_TRACKING_ID_KEY } from '@innogy/core/analytics';

import { eplusHeatpumpsFeature } from '../heatpumps.state';
import { heatpumpsQuestionnaireKey } from './heatpumps-questionnaire.reducer';
import { selectHeatpumpsFunnelSettings } from '../heatpumps-funnel';

export const selectHeatpumpsQuestionnaireState = createSelector(
  eplusHeatpumpsFeature,
  (state) => state[heatpumpsQuestionnaireKey]
);

export const selectHeatpumpsCurrentQuestion = createSelector(
  selectHeatpumpsQuestionnaireState,
  (state) => state.currentQuestion
);

export const selectHeatpumpsAnswers = createSelector(
  selectHeatpumpsQuestionnaireState,
  (state) => state.submittedAnswers
);

export const selectHeatpumpsQuestionnaireAnalyticsPayload = createSelector(
  selectHeatpumpsAnswers,
  selectHeatpumpsFunnelSettings,
  (heatpumpsAnswers, funnelSettings) => ({
    ...heatpumpsAnswers,
    [E2E_TRACKING_ID_KEY]: funnelSettings.trackingId,
  })
);

export const selectHeatpumpsHasPreviousQuestion = createSelector(
  selectHeatpumpsQuestionnaireState,
  (state) => state.history.length > 1
);
