import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import type { SafeHtml } from '@angular/platform-browser';
import { DomSanitizer } from '@angular/platform-browser';

import { StructuredData } from './structured-data.model';

@Component({
  selector: 'wl-structured-data',
  template: '<div *ngIf="structuredData" [innerHTML]="html"></div>',
  styles: [],
})
export class StructuredDataComponent implements OnInit {
  @Input() structuredData?: StructuredData;

  public html: SafeHtml | undefined;

  constructor(private readonly sanitizer: DomSanitizer) {}

  ngOnInit() {
    this.html = this.getSafeHTML(this.structuredData);
  }

  getSafeHTML(jsonLD: StructuredData | undefined): SafeHtml {
    if (!jsonLD) {
      return '';
    }

    const json = JSON.stringify(jsonLD, null, 2).replace(
      /<\/script>/g,
      '<\\/script>'
    );

    // escape / to prevent script tag in JSON
    const html = `<script type="application/ld+json">${json}</script>`;

    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
