import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';

import {
  hideSolarPanelsProductOverviewAction,
  showSolarPanelsProductOverviewAction,
} from './solar-panels-product-overview.actions';

export const solarPanelsProductOverviewKey = 'solarPanelsProductOverview';

export interface SolarPanelsProductOverviewState {
  isVisible: boolean;
}

export const initialSolarPanelsProductOverviewState: SolarPanelsProductOverviewState =
  {
    isVisible: false,
  };

const reducer = createReducer(
  initialSolarPanelsProductOverviewState,
  on(showSolarPanelsProductOverviewAction, (state) => ({
    ...state,
    isVisible: true,
  })),
  on(hideSolarPanelsProductOverviewAction, (state) => ({
    ...state,
    isVisible: false,
  }))
);

export const solarPanelsProductOverviewReducer = (
  state: SolarPanelsProductOverviewState = initialSolarPanelsProductOverviewState,
  action: Action
): SolarPanelsProductOverviewState => reducer(state, action);
