import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { reducers, tariffsSelectorKey } from './+state/tariffs.state';
import { DynamicPricesEffects } from './+state';

@NgModule({
  imports: [
    StoreModule.forFeature(tariffsSelectorKey, reducers),
    EffectsModule.forFeature([DynamicPricesEffects]),
  ],
})
export class TariffsStoreModule {}
