import { createAction, props } from '@ngrx/store';

export const recaptchaResolved = createAction(
  `[Recaptcha] Resolved`,
  props<{ formId: string; recaptchaResponse: string }>()
);

export const submitCreateOnlineAccount = createAction(
  '[Create Online Account] Submit',
  props<{ recaptchaResponse?: string }>()
);
