import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';

import { setMGMSaleIdAction } from './member-get-member.actions';

export interface MemberGetMemberState {
  saleId?: string;
}

const _reducer = createReducer(
  {},
  on(setMGMSaleIdAction, (state, { saleId }) => ({ ...state, saleId }))
);

export function memberGetMemberReducer(
  state: MemberGetMemberState = {},
  action: Action
) {
  return _reducer(state, action);
}
