import { Status } from '@essent/common';
import type { HttpErrorResponse } from '@angular/common/http';

export interface CollectionState<T> {
  entities: Entries<T>;
  ids: string[];
}

export const createInitialCollectionState = <T>(): CollectionState<T> => ({
  entities: {},
  ids: [],
});

export interface Entry<T> {
  status?: Status;
  error?: HttpErrorResponse;
  id: string;
  entry?: T;
}

export interface Entries<T> {
  [key: string]: Entry<T> | undefined;
}

function getIdentifier(identifier: { actionId?: string } | string) {
  return (
    (typeof identifier === 'string' ? identifier : identifier.actionId) ||
    'unknown'
  );
}

export function updateCollectionState<T>(
  state: CollectionState<T>,
  identifier: { actionId?: string } | string,
  updatedState: Omit<Entry<T>, 'id'>
): CollectionState<T> {
  const id = getIdentifier(identifier);
  const entities = {
    ...state.entities,
    [id]: {
      ...updatedState,
      id,
    },
  };

  return {
    ...state,
    entities,
    ids: Object.keys(entities),
  };
}
export function removeFromCollectionState<T>(
  state: CollectionState<T>,
  identifier: { actionId?: string } | string
): CollectionState<T> {
  const id = getIdentifier(identifier);
  const entities = {
    ...state.entities,
  };
  delete entities[id];

  return {
    ...state,
    entities,
    ids: Object.keys(entities),
  };
}

export function getCollectionObject<T>(
  state: CollectionState<T>,
  identifier: { actionId?: string } | string
): Entry<T> | undefined {
  const id = getIdentifier(identifier);
  return state.entities[id];
}

export function getCollectionEntry<T>(
  state: CollectionState<T>,
  identifier: { actionId?: string } | string
): T | undefined {
  return getCollectionObject(state, identifier)?.entry;
}

export function getCollectionStatus<T>(state: CollectionState<T>): Status {
  if (!state) {
    return Status.ERROR;
  }

  const values = Object.values(state.entities) as Array<Entry<T>>;
  const _isLoading = values.some((value) => value.status === Status.PENDING);

  if (_isLoading) {
    return Status.PENDING;
  }

  // Every always returns true on an empty array
  const areAllFailed =
    values.length > 0 && values.every((value) => value.status === Status.ERROR);

  if (areAllFailed) {
    return Status.ERROR;
  }
  if (values.length > 0) {
    return Status.SUCCESS;
  }

  return Status.IDLE;
}

export function isLoading(...entries: (Entry<unknown> | undefined)[]) {
  const definedEntries = entries.filter(
    (entry): entry is Entry<unknown> => entry != null
  );

  if (definedEntries.length === 0) {
    return false;
  }

  return definedEntries.some(
    (entry) => entry.status === Status.IDLE || entry.status === Status.PENDING
  );
}

export function isError(...entries: (Entry<unknown> | undefined)[]) {
  const definedEntries = entries.filter(
    (entry): entry is Entry<unknown> => entry != null
  );

  if (definedEntries.length === 0) {
    return false;
  }

  return definedEntries.some((entry) => entry.status === Status.ERROR);
}
