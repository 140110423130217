import {
  Component,
  EventEmitter,
  Input,
  type OnInit,
  Output,
  PLATFORM_ID,
  inject,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { type Observable, finalize, map, take, timer } from 'rxjs';
import { isPast } from 'date-fns';

import { secondsUntilDate } from './timer.helpers';
import { Time, type TimerLabels } from './timer.model';

@Component({
  selector: 'wl-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.ed.scss', './timer.component.essent.scss'],
})
export class TimerComponent implements OnInit {
  @Input({ required: true }) dateToCountDownTo: Date | undefined;
  @Input() labels?: Partial<TimerLabels>;

  @Output() timerEnded = new EventEmitter<void>();

  private readonly applicationRunningInBrowser = isPlatformBrowser(
    inject(PLATFORM_ID)
  );
  timerShown = false;
  secondsToCount?: Observable<number>;
  timerLabels!: TimerLabels;

  makeTime = (n: number | null) => (n !== null ? new Time(n) : null);

  ngOnInit(): void {
    this.timerLabels = {
      daysLabel: this.labels?.daysLabel ?? 'Dagen',
      hoursLabel: this.labels?.hoursLabel ?? 'Uren',
      minutesLabel: this.labels?.minutesLabel ?? 'Minuten',
      secondsLabel: this.labels?.secondsLabel ?? 'Seconden',
    };

    if (!this.dateToCountDownTo || isPast(this.dateToCountDownTo)) {
      return;
    }

    if (this.applicationRunningInBrowser) {
      const totalNumberOfSecondsToCount = secondsUntilDate(
        this.dateToCountDownTo
      );
      this.secondsToCount = timer(0, 1000).pipe(
        map((n) => totalNumberOfSecondsToCount - n),
        take(totalNumberOfSecondsToCount + 1),
        finalize(() => {
          this.timerEnded.emit();
          this.timerShown = false;
        })
      );
    }

    this.timerShown = true;
  }
}
