<label
  *ngIf="!labelFloating && label !== ''"
  class="datepicker-label fw-bold"
  [for]="formsControl?.id"
>
  {{ label }}
</label>
<div
  class="input-group"
  [class.form-floating]="labelFloating"
  #trackingWrapper
  [wlTrackValueChange]="{
    rendering: rendering,
    label: fieldName,
    inputLabel: formsControl?.value?.toString(),
    sendValue: true,
    trackFocus: true,
    toolName: toolName,
    stepName: stepName,
    formName: formName,
    step: step,
  }"
>
  <!--
  For this form-control, we can not rely on the formsControl.value for certain things (e.g. [class.has-value]).
  The form value will be set by the [ngrxValueConverter], and this converter will only update the form once a
  valid date is filled in. This is needed because the ngbDatepicker adapter can't handle a partial (invalid) date.
  As a result we have to use an internal value property directly.

  TODO: Another version of this component should be created where the ngrx-forms can be used properly, at this
        moment ngrx-form validation and formatting can only be done once the entire date has been filled in.
  -->
  <input
    class="form-control"
    [wlValidationClasses]="formsControl"
    [wlValidationClassesSkipDirtyCheck]="skipDirtyCheck"
    [class.has-value]="value || formsControl?.value"
    [placeholder]="placeholder || ' '"
    [name]="formsControl?.id"
    [id]="formsControl?.id"
    type="text"
    [value]="value || (formsControl?.value | date: 'dd-MM-yyyy')"
    (input)="onValueChange($event?.target?.value)"
    [ngrxFormControlState]="formsControl"
    [ngrxValueConverter]="dateValueConverter"
    [ngrxEnableFocusTracking]="true"
    ngbDatepicker
    [minDate]="minDate"
    [maxDate]="maxDate"
    [dayTemplate]="customDay"
    [displayMonths]="1"
    [navigation]="navigation"
    outsideDays="visible"
    [showWeekNumbers]="false"
    [restoreFocus]="false"
    #datePicker="ngbDatepicker"
    #dateInput
    (click)="openDatepicker(datePicker)"
    (dateSelect)="selectDate($event)"
    [wlAutomationId]="automationId || formsControl?.id"
    [autocomplete]="autocomplete"
  />
  <label
    *ngIf="labelFloating && label !== ''"
    class="datepicker-label"
    [for]="formsControl?.id"
  >
    {{ label }}
  </label>
  <div
    *ngIf="tooltip"
    class="position-absolute datepicker-tooltip h-100 px-2 d-flex flex-column justify-content-center"
  >
    <wl-tooltip
      [tooltip]="{
        description: tooltip,
      }"
    ></wl-tooltip>
  </div>
  <button
    class="btn btn-outline-secondary py-0 datepicker__button"
    type="button"
    wlAutomationId="datepicker.toggle"
    aria-label="Open kalender"
    (click)="openDatepicker(datePicker)"
  >
    <fa-icon icon="calendar-days" class="fa-icon"></fa-icon>
  </button>
</div>

<ng-template
  #customDay
  let-date
  let-currentMonth="currentMonth"
  let-selected="selected"
  let-disabled="disabled"
  let-focused="focused"
>
  <div
    class="btn-primary custom-day text-dark"
    [class.focused]="focused"
    [class.bg-primary]="selected"
    [class.hidden]="date.month !== currentMonth"
    [class.text-muted]="disabled"
  >
    {{ date.day }}
  </div>
</ng-template>
