import type { ComponentNameAndModule } from '@sitecore-jss/sitecore-jss-angular';

const loadChildren = () =>
  import('./lib/cookie-settings-lazy.module').then(
    (mod) => mod.CookieSettingsLazyModule
  );

const cookieSettingsLazyImports: ComponentNameAndModule[] = [
  {
    path: 'CookieSettings',
    loadChildren,
  },
];
export { cookieSettingsLazyImports };
