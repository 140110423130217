import type { TypedComponentRendering } from '@core/jss-models';
import type {
  ProgressiveFormContainerFields,
  ProgressiveFormStepConfig,
  ScDynamicProgressiveFormConfig,
} from '@innogy/shared/progressive-form/sitecore/models';
import { normalizeUid } from '@core/jss-utils';

export const renderingMapper = ({
  fields,
  dataSource = 'NO_DATASOURCE_FOUND',
}: TypedComponentRendering<ProgressiveFormContainerFields>): ScDynamicProgressiveFormConfig => {
  const { FormSteps, ToolName: toolName } = fields;
  const uid = normalizeUid(dataSource);

  const formSteps: ProgressiveFormStepConfig[] = FormSteps.map(
    ({ fields, id }) => {
      return {
        id,
        fields,
      };
    }
  );

  return { formSteps, toolName, uid };
};
