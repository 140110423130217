import type { MetaDataItem } from '@essent/new-customer';

export function getMetaDataValues(
  items: MetaDataItem[] = []
): Partial<Record<MetaDataItem['name'], string>> {
  return items.reduce(
    (mapped, { name, value }) => ({
      ...mapped,
      [name]: value,
    }),
    {}
  );
}
