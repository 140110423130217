import { PREFIX } from '@innogy/eplus/store';
import type { FormPaymentDetails } from '@innogy/shared/progressive-form/sitecore';
import { createAction, props } from '@ngrx/store';

export const paymentDetailsFormId = 'payment-details';

export const prefillEmobilityPaymentDetailsStateAction = createAction(
  `${PREFIX} prefill payment details`,
  props<{ payload: Partial<FormPaymentDetails> }>()
);
