import { Directive, HostBinding } from '@angular/core';

export const maskAttribute = 'data-cs-mask';

/**
 * Adds data-cs-mask attr to its host so all the data within the host will be hidden in Content Square.
 */
@Directive({
  selector: '[wlCSMask]',
})
export class CSMaskDirective {
  @HostBinding(`attr.${maskAttribute}`)
  isEnabled = true;
}
