import { electronicFormatIBAN, isValidIBAN } from 'ibantools';
import type { ValidationErrors } from 'ngrx-forms';

export interface IsIBANValidator {
  actual: any;
}

declare module 'ngrx-forms/src/state' {
  export interface ValidationErrors {
    isIBAN?: IsIBANValidator;
  }
}

const _IsIBAN = <T>(value: T | string) => {
  return typeof value === 'string' && isValidIBAN(value);
};

/**
 * Validates that the given value is an IBAN
 * @param value
 */
export const isIBAN = <T>(value: T | string): ValidationErrors => {
  if (_IsIBAN(value)) {
    return {};
  }

  return {
    isIBAN: { actual: value },
  };
};

export const isFriendlyFormatIBAN = (value: string): ValidationErrors => {
  const electronicValue = electronicFormatIBAN(value) ?? value;

  if (_IsIBAN(electronicValue)) {
    return {};
  }

  return {
    isFriendlyFormatIBAN: { actual: value },
  };
};
