import type {
  AbstractControlState,
  InferenceWrapper,
  InferredFormState,
  ValidationFn,
} from 'ngrx-forms';
import { setErrors } from 'ngrx-forms';

import { validateSequential } from './validate-sequential';

export const validateSequentialIf = (
  related: boolean
): (<T>(
  ...validationFunctions: ValidationFn<T>[]
) => (
  control: AbstractControlState<T>
) => InferredFormState<InferenceWrapper<T>>) =>
  related
    ? validateSequential
    : (..._: unknown[]) =>
        (control: any) =>
          setErrors(control, {});
