import { Component, Input } from '@angular/core';
import { Offer } from '@innogy/become-a-customer/shared/interfaces';

import { CommodityOfferLabels } from '../../../commodity-offers.model';

@Component({
  selector: 'wl-commodity-offer-price-tag',
  templateUrl: './commodity-offer-price-tag.component.html',
  styleUrls: [
    './commodity-offer-price-tag.component.ed.scss',
    './commodity-offer-price-tag.component.essent.scss',
  ],
})
export class CommodityOfferPriceTagComponent {
  @Input()
  public offer?: Offer;
  @Input()
  public content?: CommodityOfferLabels;
  @Input()
  public showBudgetbillProposal = true;

  /**
   * This discount infix should only be visible if the offer has a discount and if
   * there is a value available for the label
   */
  showMonthlyCostsLabelDiscountInfix() {
    return (
      this.offer?.incentiveTitle && this.content?.monthlyCostsLabelDiscountInfix
    );
  }
}
