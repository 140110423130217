import { createAction, props } from '@ngrx/store';

const PREFIX = '[Eplus Confirmation Form]';

/**
 * This action dynamically adds (or replaces) a boolean formControl and applies the validator (if provided)
 */
export const addCheckboxFormControlAction = createAction(
  `${PREFIX} Add checkbox form control`,
  props<{ name: string; validator?: any }>()
);
