import type { HouseType, Residents } from '@essent/estimate-consumption';
import { useValidatorIf, validateSequential } from '@innogy/utils-deprecated';
import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';
import type { FormGroupState } from 'ngrx-forms';
import {
  MarkAsSubmittedAction,
  createFormGroupState,
  onNgrxForms,
  onNgrxFormsAction,
  setUserDefinedProperty,
  updateGroup,
  wrapReducerWithFormStateUpdate,
} from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';

import { enableSupplyTypeQuestionAction } from './usage-questionnaire.actions';

export const enum SupplyType {
  E_AND_G = 'ELECTRICITY_AND_GAS',
  E_ONLY = 'ELECTRICITY',
  G_ONLY = 'GAS',
}

export interface UsageQuestionnaireFormValues {
  residents?: Residents;
  houseType?: HouseType;
  supplyType?: SupplyType;
}

export interface UsageQuestionnaireControlIds {
  formId: string;
  residentsControlId: string;
  houseTypeControlId: string;
  supplyTypeControlId: string;
  businessTypeControlId: string;
}

export type UsageQuestionnaireState =
  FormGroupState<UsageQuestionnaireFormValues>;

export const defaultFormState: UsageQuestionnaireFormValues = {
  residents: undefined,
  houseType: undefined,
  supplyType: undefined,
};

const validateAndUpdateForms = (state: UsageQuestionnaireState) => {
  return updateGroup<UsageQuestionnaireFormValues>({
    residents: validateSequential(required),
    houseType: validateSequential(required),
    supplyType: validateSequential(
      useValidatorIf(
        required,
        state.userDefinedProperties['supplyTypeRequired']
      )
    ),
  })(state);
};

export const getUsageFormControlIds = (
  formId: string
): UsageQuestionnaireControlIds => ({
  formId,
  residentsControlId: `${formId}.residents`,
  houseTypeControlId: `${formId}.houseType`,
  supplyTypeControlId: `${formId}.supplyType`,
  businessTypeControlId: `${formId}.businessType`,
});

export const createUsageQuestionnaireFormState = (
  formId: string,
  initialState: UsageQuestionnaireFormValues = defaultFormState
) => {
  const usageQuestionnaireFormControlIds = getUsageFormControlIds(formId);

  const initialFormGroup = createFormGroupState(formId, initialState);

  const initialUsageQuestionnaireFormState =
    validateAndUpdateForms(initialFormGroup);

  const _reducer = createReducer(
    initialUsageQuestionnaireFormState,
    onNgrxForms(),
    onNgrxFormsAction(MarkAsSubmittedAction, (state, action) => {
      if (action.controlId === formId) {
        return validateAndUpdateForms(state);
      }
      return state;
    }),
    on(enableSupplyTypeQuestionAction, (state, action) => {
      if (action.formId === formId) {
        return setUserDefinedProperty('supplyTypeRequired', true)(state);
      }
      return state;
    })
  );

  const wrappedReducer = wrapReducerWithFormStateUpdate(
    _reducer,
    (state) => state,
    (_, state) => validateAndUpdateForms(state)
  );

  function usageQuestionnaireReducer(
    state: UsageQuestionnaireState,
    action: Action
  ): UsageQuestionnaireState {
    return wrappedReducer(state, action);
  }

  return {
    usageQuestionnaireReducer,
    initialFormGroup,
    usageQuestionnaireFormControlIds,
  };
};
export function createUsageQuestionnaireReducer(formId: string) {
  const usageQuestionnaireFormState = createUsageQuestionnaireFormState(formId);
  const _wrappedReducer = usageQuestionnaireFormState.usageQuestionnaireReducer;

  return (
    state = usageQuestionnaireFormState.initialFormGroup,
    action: Action
  ): UsageQuestionnaireState => {
    return _wrappedReducer(state, action);
  };
}
