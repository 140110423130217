import { createSelector } from '@ngrx/store';

import { getBecomeACustomerState } from '../../become-a-customer-feature.selector';

export const getBAGRegistrationState = createSelector(
  getBecomeACustomerState,
  (state) => state.BAGRegistration.getBAGRegistration
);

export const getBAGRegistrationData = createSelector(
  getBAGRegistrationState,
  (state) => state.data
);

export const getBAGRegistrationFaulty = createSelector(
  getBecomeACustomerState,
  (state) => state.BAGRegistration.isFaulty
);
