<div class="position-relative h-100">
  <ng-container>
    <wl-commodity-offer-card
      *ngIf="variant === 'card'"
      [offer]="offer"
      [showLoadingState]="showLoadingState"
    >
      <div class="mb-2">
        <essent-commodity-offer-header
          [duration]="offer?.durationTitle"
          [addLabelSpacer]="ensureHorizontalAlignment"
          [priceDurationLabel]="content?.priceDurationLabel"
          [productTitle]="offer?.productTitle"
          [price]="offer?.expectedMonthlyAmount"
          [highlight]="offer?.isHighlightedLabel"
          [incentive]="offer?.incentiveTitle"
          [floatingIncentive]="false"
          [showPriceTag]="false"
        ></essent-commodity-offer-header>
      </div>
      <div class="d-flex flex-column flex-grow-1">
        <ng-container *ngTemplateOutlet="offerBody"></ng-container>
      </div>
    </wl-commodity-offer-card>

    <wl-commodity-offer-accordion
      *ngIf="variant === 'accordion'"
      [opened]="active"
      [showLoadingState]="showLoadingState"
      [offer]="offer"
    >
      <ng-template #accordionHeader let-collapsed="collapsed">
        <essent-commodity-offer-header
          [addLabelSpacer]="false"
          [duration]="offer?.durationTitle"
          [priceDurationLabel]="content?.priceDurationLabel"
          [price]="offer?.expectedMonthlyAmount"
          [incentive]="offer?.incentiveTitle"
          [productTitle]="offer?.productTitle"
          [highlight]="offer?.isHighlightedLabel"
          [floatingIncentive]="false"
          [showPriceTag]="collapsed"
        ></essent-commodity-offer-header>
      </ng-template>
      <ng-template #accordionBody>
        <ng-container *ngTemplateOutlet="offerBody"></ng-container>
      </ng-template>
    </wl-commodity-offer-accordion>
  </ng-container>
</div>

<ng-template #offerBody>
  <div class="mb-3" *ngIf="offer">
    <span class="text-decoration-line-through">
      <ng-container *ngIf="offer.incentiveId">
        {{ offer.beforeDiscountExpectedMonthlyAmount | roundedCurrency }}
      </ng-container>
      <!-- Add a spacer to make sure the price tags align with each other -->
      <div
        *ngIf="!offer.incentiveId && ensureHorizontalAlignment"
        class="mt-3"
      ></div>
    </span>

    <div wlAutomationId="offer-budget-bill-amount-section">
      <span class="h1 text-brand">
        {{ offer.expectedMonthlyAmount | roundedCurrency }}
      </span>
      <span class="lh-sm">{{ content?.priceDurationLabel }}</span>
    </div>
    <div *ngIf="offer.incentiveId">
      <span>
        {{ content?.budgetbillAmountLabel }}
      </span>
    </div>
    <button
      *ngIf="offer.incentiveId && content?.moreInfoPriceAndConditionLinkLabel"
      class="btn p-0 border-0 btn-link mt-1"
      (click)="openMoreInfo.emit()"
      wlAutomationId="more-info-button"
      wlCSCapture
    >
      {{ content?.moreInfoPriceAndConditionLinkLabel }}
    </button>
  </div>
  <div class="mt-auto" *ngIf="offer">
    <div *ngIf="offer.usps" class="mb-2">
      <wl-commodity-usp-list [usps]="offer.usps"></wl-commodity-usp-list>
    </div>
    <button
      class="btn btn-primary w-100"
      (click)="selectOffer.emit()"
      wlAutomationId="offer-submit-button"
      wlCSCapture
    >
      {{ content?.offerSubmitButtonLabel }}
    </button>
    <div class="d-flex justify-content-center">
      <button
        class="btn p-0 border-0 btn-link mt-2 mb-1"
        (click)="viewTariffs.emit()"
        wlAutomationId="info-card-button"
        wlCSCapture
      >
        {{ content?.tariffModalLinkTextLabel }}
      </button>
    </div>
  </div>
</ng-template>
