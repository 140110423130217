import { CommonModule, DOCUMENT } from '@angular/common';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import type { Routes, UrlSegment } from '@angular/router';
import { RouterModule } from '@angular/router';
import { ConfigLoaderService } from '@core/config-angular';
import { NotFound, ServerError } from '@core/models';
import {
  JssRouteComponent,
  jssRouteMatcherFactory,
  JssRouteResolver,
} from '@core/jss-routing';
import { UtilsModule } from '@innogy/utils-deprecated';
import { PlatformService } from '@core/platform';
import { StoreModule } from '@ngrx/store';

import { NotFoundContainerComponent } from './not-found-container/not-found-container.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ServerErrorComponent } from './server-error/server-error.component';

export function jssRouteMatcher(url: UrlSegment[]) {
  // DO NOT USE THE "GLOBAL_CONFIG" ANYWHERE ELSE. ITS MEANT ONLY FOR THIS SPECIFIC PART OF THE WHOLE APPLICATION.
  return jssRouteMatcherFactory(
    url,
    ConfigLoaderService.GLOBAL_CONFIG.sitecore || { apiKey: '' }
  );
}

export const routes: Routes = [
  { path: NotFound, component: NotFoundContainerComponent },
  { path: ServerError, component: ServerErrorComponent },
  {
    // matcher is effectively a catch-all route, see docs https://angular.io/api/router/UrlMatcher
    matcher: jssRouteMatcher,
    component: JssRouteComponent,
    resolve: {
      jssState: JssRouteResolver,
    },
    runGuardsAndResolvers: 'always',
  },
];

export function contentReadyEvent(
  document: Document,
  platformService: PlatformService
): () => void {
  return () => {
    if (platformService.isClient()) {
      // This event is needed for analytics (and for the cookie banner)
      document.dispatchEvent(new Event('ContentReady'));
    }
  };
}

@NgModule({
  imports: [
    CommonModule,
    /**
     * NOTE: 'anchorScrolling' and 'scrollPositionRestoration' in 'RouterModule' are not enabled, we handle this manually in <wl-scroll-manager>
     *       This way we can:
     *         1. Wait for some Sitecore placeholders to load before starting to scroll
     *         2. Have different VERTICAL_SCROLL_OFFSET's per app
     *         3. Decide when to scroll immediately (initial page load) or when to animate the scrolling (internal navigation)
     *         4. Ensure there are no scroll restoration problems when re-initializing the application router on hydration.
     *            An issue with this approach is that we cannot handle scroll offsets on anchor scrolling.
     **/
    RouterModule.forRoot(routes, {
      /**
       * DISCLAIMER for anyone in the future that wants to change the onSameUrlNavigation to 'reload'...
       *            in the past this broke the /kennisbank pages because they get stuck in an infinite redirect loop
       */
      /**
       * DISCLAIMER for anyone that wants to change scrollPositionRestoration to 'enabled'....
       *            this property is set to disabled so that we have full control over the way the router handles scrolling.
       *            all of this has been configured via the <wl-scroll-manager> component.
       */
      onSameUrlNavigation: 'ignore',
      scrollPositionRestoration: 'disabled',
      anchorScrolling: 'disabled',
    }),
    StoreModule,
    UtilsModule,
  ],
  exports: [RouterModule, ServerErrorComponent, NotFoundContainerComponent],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: contentReadyEvent,
      deps: [DOCUMENT, PlatformService],
      multi: true,
    },
  ],
  declarations: [
    ServerErrorComponent,
    NotFoundComponent,
    NotFoundContainerComponent,
  ],
})
export class CoreRoutingModule {}
