<fieldset>
  <legend>
    <strong>{{ label }}</strong>
  </legend>
  <div
    class="form-check"
    *ngFor="let input of checkboxItems; let index = index"
  >
    <input
      #input
      class="form-check-input"
      type="checkbox"
      [value]="input.value"
      [name]="name"
      [id]="name + input.label"
      (change)="onInputChanged()"
    />
    <label
      class="form-check-label"
      [for]="name + input.label"
      [wlAutomationId]="'checkboxset-' + name + input.label"
    >
      {{ input.label }}
    </label>
  </div>
</fieldset>
