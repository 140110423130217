import type { AsyncState } from '@essent/common';
import {
  createAsyncStateReducer,
  createInitialAsyncState,
} from '@essent/common';
import type {
  InsulationProductWithCalculations,
  InsulationNewCustomerAddressCheckResponseV1,
} from '@innogy/eplus/temporary-core-modules';
import {
  getInsulationAddressCheck,
  getInsulationAddressCheckClear,
  getInsulationAddressCheckError,
  getInsulationAddressCheckSuccess,
  getInsulationProductsActions,
  postInsulationConfirmationActions,
} from '@innogy/eplus/temporary-core-modules';
import type { Action, ActionReducerMap } from '@ngrx/store';
import { combineReducers } from '@ngrx/store';

export const insulationApiKey = 'insulationAPI';

export type GetInsulationNewCustomerAddressCheckState =
  AsyncState<InsulationNewCustomerAddressCheckResponseV1>;
export type GetInsulationProductsState = AsyncState<
  InsulationProductWithCalculations[]
>;
export type PostInsulationNewCustomerConfirmationState = AsyncState<void>;
export interface InsulationAPIState {
  getInsulationNewCustomerAddressCheck: GetInsulationNewCustomerAddressCheckState;
  getInsulationProducts: GetInsulationProductsState;
  postInsulationNewCustomerConfirmation: PostInsulationNewCustomerConfirmationState;
}

export const initialInsulationAPIState: InsulationAPIState = {
  getInsulationNewCustomerAddressCheck: createInitialAsyncState(),
  getInsulationProducts: createInitialAsyncState(),
  postInsulationNewCustomerConfirmation: createInitialAsyncState(),
};

const _getInsulationNewCustomerAddressCheckReducer = createAsyncStateReducer(
  getInsulationAddressCheck,
  getInsulationAddressCheckSuccess,
  getInsulationAddressCheckError,
  getInsulationAddressCheckClear
);

const _getInsulationProductsReducer = createAsyncStateReducer(
  getInsulationProductsActions
);

const _postInsulationNewCustomerConfirmationReducer = createAsyncStateReducer(
  postInsulationConfirmationActions
);

const _reducers: ActionReducerMap<InsulationAPIState, Action> = {
  getInsulationNewCustomerAddressCheck:
    _getInsulationNewCustomerAddressCheckReducer,
  getInsulationProducts: _getInsulationProductsReducer,
  postInsulationNewCustomerConfirmation:
    _postInsulationNewCustomerConfirmationReducer,
};

const _combinedReducers = combineReducers(_reducers);
export const insulationAPIReducer = (
  state: InsulationAPIState = initialInsulationAPIState,
  action: Action
): InsulationAPIState => _combinedReducers(state, action);
