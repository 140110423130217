import { Component, Input } from '@angular/core';
import { TrackToolStep } from '@innogy/core/analytics';
import { ScCheckboxFormInput } from '@innogy/sitecore-forms/models';
import type { FormState } from 'ngrx-forms';

@Component({
  selector: 'wl-sc-checkbox[control][fields][trackStep]',
  templateUrl: './sc-checkbox.component.html',
})
export class ScCheckboxComponent {
  @Input() control!: FormState<string> | FormState<boolean>;
  @Input() fields!: ScCheckboxFormInput;
  @Input() trackStep!: TrackToolStep;
}
