<div class="position-relative h-100">
  <ng-container>
    <wl-commodity-offer-card
      *ngIf="variant === 'card'"
      [offer]="offer"
      [showLoadingState]="showLoadingState"
    >
      <div class="mb-2">
        <essent-commodity-offer-header
          [duration]="offer?.durationTitle"
          [addLabelSpacer]="ensureHorizontalAlignment"
          [priceDurationLabel]="content?.priceDurationLabel"
          [productTitle]="offer?.productTitle"
          [price]="offer?.expectedMonthlyAmount"
          [highlight]="offer?.isHighlightedLabel"
          [incentive]="offer?.incentiveTitle"
          [showPriceTag]="true"
        ></essent-commodity-offer-header>
      </div>
      <div class="d-flex flex-column flex-grow-1">
        <ng-container *ngTemplateOutlet="offerBody"></ng-container>
      </div>
    </wl-commodity-offer-card>

    <wl-commodity-offer-accordion
      *ngIf="variant === 'accordion'"
      [opened]="active"
      [showLoadingState]="showLoadingState"
      [offer]="offer"
    >
      <ng-template #accordionHeader>
        <essent-commodity-offer-header
          [addLabelSpacer]="false"
          [duration]="offer?.durationTitle"
          [priceDurationLabel]="content?.priceDurationLabel"
          [price]="offer?.expectedMonthlyAmount"
          [incentive]="offer?.incentiveTitle"
          [productTitle]="offer?.productTitle"
          [highlight]="offer?.isHighlightedLabel"
          [showPriceTag]="true"
        ></essent-commodity-offer-header>
      </ng-template>
      <ng-template #accordionBody>
        <ng-container *ngTemplateOutlet="offerBody"></ng-container>
      </ng-template>
    </wl-commodity-offer-accordion>
  </ng-container>
</div>

<ng-template #offerBody>
  <div class="mb-2" [ngStyle]="setMinimumHeightOfferCardBody">
    <wl-commodity-price-breakdown
      [offer]="offer"
      [content]="content"
    ></wl-commodity-price-breakdown>
  </div>
  <div class="mt-auto">
    <button
      class="btn btn-primary w-100"
      (click)="selectOffer.emit()"
      wlAutomationId="offer-submit-button"
      wlCSCapture
    >
      {{ content?.offerSubmitButtonLabel }}
    </button>
    <div class="d-flex justify-content-center">
      <button
        class="btn p-0 border-0 btn-link mt-2 mb-1"
        (click)="viewTariffs.emit()"
        wlAutomationId="info-card-button"
        wlCSCapture
      >
        {{ content?.tariffModalLinkTextLabel }}
      </button>
    </div>
  </div>
</ng-template>
