import { Injectable } from '@angular/core';
import type { BaseActionType } from '@essent/common';
import type {
  InsulationNewCustomerAddressCheckRequestQueryV1,
  InsulationNewCustomerAddressCheckResponse,
} from '@innogy/eplus/temporary-core-modules';
import {
  getInsulationAddressCheck,
  getInsulationAddressCheckError,
  getInsulationAddressCheckSuccess,
} from '@innogy/eplus/temporary-core-modules';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store } from '@ngrx/store';
import { MarkAsSubmittedAction } from 'ngrx-forms';
import { filter, map, mergeMap } from 'rxjs/operators';

import {
  handleInsulationFunnelAddressNotFoundErrorAction,
  handleInsulationFunnelGenericErrorAction,
  handleInsulationFunnelPrivateErrorAction,
  onInsulationFunnelNavigationAction,
  resetInsulationProductSelectionAction,
  selectInsulationFunnelSettings,
} from '../insulation-funnel';
import { resetInsulationOrderFormAction } from '../insulation-order-form/insulation-order-form.actions';
import {
  hideInsulationProductOverviewAction,
  showInsulationProductOverviewAction,
} from '../insulation-products';
import { insulationWizardFormSubmitAction } from './insulation-wizard.actions';
import { insulationWizardFormId } from './insulation-wizard.reducer';
import { selectInsulationWizardViewModel } from './insulation-wizard.selectors';

@Injectable()
export class InsulationWizardEffects {
  funnelSettings$ = this.store$.select(selectInsulationFunnelSettings);
  insulationWizardState$ = this.store$.select(selectInsulationWizardViewModel);

  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store
  ) {}

  public readonly submitInsulationWizardForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(insulationWizardFormSubmitAction),
      map(() => new MarkAsSubmittedAction(insulationWizardFormId))
    )
  );

  public readonly submitInsulationWizardFormSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(insulationWizardFormSubmitAction),
      concatLatestFrom(() => [
        this.insulationWizardState$,
        this.funnelSettings$,
      ]),
      filter(([_, state]) => state.formState.isValid),
      mergeMap(([_, { formState }, funnelSettings]) => {
        if (!funnelSettings.id) {
          return [
            handleInsulationFunnelPrivateErrorAction({
              message: 'id is not defined on FunnelSettings',
            }),
          ];
        }
        const {
          postalCode,
          communicationNumber,
          houseNumberAddition,
          buildingType,
        } = formState.value;
        return [
          hideInsulationProductOverviewAction(),
          resetInsulationProductSelectionAction(),
          resetInsulationOrderFormAction(),
          getInsulationAddressCheck({
            payload: {
              postalcode: postalCode,
              housenumber: communicationNumber.toString(),
              housenumberaddition: houseNumberAddition,
              buildingType: buildingType,
              funnelsettingsId: funnelSettings.id,
            } as InsulationNewCustomerAddressCheckRequestQueryV1,
          }),
        ];
      })
    )
  );

  public readonly navigateToAlreadyInsulatedPage$ =
    this.createOnSuccessNavigationEffect({
      additionalActions: [],
      filter: (action) => action.payload.alreadyInsulated,
      navigateToPage: 'alreadyInsulatedPage',
    });

  public readonly navigateToNoProductsAvailablePage$ =
    this.createOnSuccessNavigationEffect({
      additionalActions: [],
      filter: (action) =>
        !action.payload.alreadyInsulated &&
        action.payload.suitableProductTypes.length === 0,
      navigateToPage: 'noProductsAvailablePage',
    });

  public readonly navigateToProductOverviewPage$ =
    this.createOnSuccessNavigationEffect({
      additionalActions: [showInsulationProductOverviewAction()],
      filter: (action) =>
        !action.payload.alreadyInsulated &&
        action.payload.suitableProductTypes.length > 0,
      navigateToPage: 'productOverviewPage',
    });

  public readonly onAddressCheckError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getInsulationAddressCheckError),
      map((action) =>
        action.payload.status === 404
          ? handleInsulationFunnelAddressNotFoundErrorAction()
          : handleInsulationFunnelGenericErrorAction()
      )
    )
  );

  private createOnSuccessNavigationEffect(input: {
    filter: (
      action: Action & BaseActionType<InsulationNewCustomerAddressCheckResponse>
    ) => boolean;
    navigateToPage:
      | 'alreadyInsulatedPage'
      | 'noProductsAvailablePage'
      | 'productOverviewPage';
    additionalActions: Action<any>[];
  }) {
    return createEffect(() =>
      this.actions$.pipe(
        ofType(getInsulationAddressCheckSuccess),
        filter((action) => input.filter(action)),
        concatLatestFrom(() => this.funnelSettings$),
        mergeMap(([, funnelSettings]) => {
          if (!funnelSettings[input.navigateToPage]) {
            return [
              handleInsulationFunnelPrivateErrorAction({
                message: `${input.navigateToPage} page is not defined on FunnelSettings`,
              }),
            ];
          }
          return [
            ...input.additionalActions,
            onInsulationFunnelNavigationAction({
              page: funnelSettings[input.navigateToPage],
            }),
          ];
        })
      )
    );
  }
}
