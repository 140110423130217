import type { Action } from '@ngrx/store';
import type { Actions as _NGRXFormsActions } from 'ngrx-forms';
import { ALL_NGRX_FORMS_ACTION_TYPES } from 'ngrx-forms';

import type { ProgressiveFormGroupId } from './state';

const prefix = `progressive-forms/`;

export class ActivateFormStepAction implements Action {
  static readonly TYPE = `${prefix}ACTIVATE_FORM_STEP`;
  readonly type = `${prefix}ACTIVATE_FORM_STEP`;
  readonly stepId: ProgressiveFormGroupId;
  constructor(stepId: ProgressiveFormGroupId) {
    this.stepId = stepId;
  }
}

export class InactivateFormStepAction implements Action {
  static readonly TYPE = `${prefix}INACTIVATE_FORM_STEP`;
  readonly type = `${prefix}INACTIVATE_FORM_STEP`;
  readonly stepId: ProgressiveFormGroupId;
  constructor(stepId: ProgressiveFormGroupId) {
    this.stepId = stepId;
  }
}

export class MarkAsEditableAction implements Action {
  static readonly TYPE = `${prefix}MARK_AS_EDITABLE`;
  readonly type = `${prefix}MARK_AS_EDITABLE`;
  readonly stepId: ProgressiveFormGroupId;
  constructor(stepId: ProgressiveFormGroupId) {
    this.stepId = stepId;
  }
}

export class MarkAsInertAction implements Action {
  static readonly TYPE = `${prefix}MARK_AS_INERT`;
  readonly type = `${prefix}MARK_AS_INERT`;
  readonly stepId: ProgressiveFormGroupId;
  constructor(stepId: ProgressiveFormGroupId) {
    this.stepId = stepId;
  }
}

export class SubmitFormStepAction implements Action {
  static readonly TYPE = `${prefix}SUBMIT_FORM_STEP`;
  readonly type = `${prefix}SUBMIT_FORM_STEP`;
  readonly stepId: ProgressiveFormGroupId;
  constructor(stepId: ProgressiveFormGroupId) {
    this.stepId = stepId;
  }
}

export class UnsubmitFormStepAction implements Action {
  static readonly TYPE = `${prefix}UNSUBMIT_FORM_STEP`;
  readonly type = `${prefix}UNSUBMIT_FORM_STEP`;
  readonly stepId: ProgressiveFormGroupId;
  constructor(stepId: ProgressiveFormGroupId) {
    this.stepId = stepId;
  }
}

export class BackupFormStateAction implements Action {
  static readonly TYPE = `${prefix}BACKUP_FORM_STATE`;
  readonly type = `${prefix}BACKUP_FORM_STATE`;
  readonly stepId: ProgressiveFormGroupId;
  constructor(stepId: ProgressiveFormGroupId) {
    this.stepId = stepId;
  }
}

export class RestoreFormStateAction implements Action {
  static readonly TYPE = `${prefix}RESTORE_FORM_STATE`;
  readonly type = `${prefix}RESTORE_FORM_STATE`;
  readonly stepId: ProgressiveFormGroupId;
  constructor(stepId: ProgressiveFormGroupId) {
    this.stepId = stepId;
  }
}

export class DisableFormStepAction implements Action {
  static readonly TYPE = `${prefix}DISABLE_FORM_STEP`;
  readonly type = `${prefix}DISABLE_FORM_STEP`;
  readonly stepId: ProgressiveFormGroupId;
  constructor(stepId: ProgressiveFormGroupId) {
    this.stepId = stepId;
  }
}

export class EnableFormStepAction implements Action {
  static readonly TYPE = `${prefix}ENABLE_FORM_STEP`;
  readonly type = `${prefix}ENABLE_FORM_STEP`;
  readonly stepId: ProgressiveFormGroupId;
  constructor(stepId: ProgressiveFormGroupId) {
    this.stepId = stepId;
  }
}

export class MarkAsUnsubmittableAction implements Action {
  static readonly TYPE = `${prefix}MARK_AS_UNSUBMITTABLE`;
  readonly type = `${prefix}MARK_AS_UNSUBMITTABLE`;
  readonly stepId: ProgressiveFormGroupId;
  constructor(stepId: ProgressiveFormGroupId) {
    this.stepId = stepId;
  }
}

export class MarkAsSubmittableAction implements Action {
  static readonly TYPE = `${prefix}MARK_AS_SUBMITTABLE`;
  readonly type = `${prefix}MARK_AS_SUBMITTABLE`;
  readonly stepId: ProgressiveFormGroupId;
  constructor(stepId: ProgressiveFormGroupId) {
    this.stepId = stepId;
  }
}

export class MarkAsVisitedAction implements Action {
  static readonly TYPE = `${prefix}MARK_AS_VISITED`;
  readonly type = `${prefix}MARK_AS_VISITED`;
  readonly stepId: ProgressiveFormGroupId;
  constructor(stepId: ProgressiveFormGroupId) {
    this.stepId = stepId;
  }
}

export class MarkAsUnvisitedAction implements Action {
  static readonly TYPE = `${prefix}MARK_AS_UNVISITED`;
  readonly type = `${prefix}MARK_AS_UNVISITED`;
  readonly stepId: ProgressiveFormGroupId;
  constructor(stepId: ProgressiveFormGroupId) {
    this.stepId = stepId;
  }
}

export class FocusInitialControlAction implements Action {
  static readonly TYPE = `${prefix}FOCUS_INITIAL_CONTROL`;
  readonly type = `${prefix}FOCUS_INITIAL_CONTROL`;
  readonly stepId: ProgressiveFormGroupId;
  constructor(stepId: ProgressiveFormGroupId) {
    this.stepId = stepId;
  }
}

export class ResetFormStepAction implements Action {
  static readonly TYPE = `${prefix}RESET_FORM_STEP`;
  readonly type = `${prefix}RESET_FORM_STEP`;
  readonly stepId: ProgressiveFormGroupId;
  constructor(stepId: ProgressiveFormGroupId) {
    this.stepId = stepId;
  }
}

export class MarkAsRestorationPointAction implements Action {
  static readonly TYPE = `${prefix}MARK_AS_RESTORATION_POINT`;
  readonly type = `${prefix}MARK_AS_RESTORATION_POINT`;
  readonly stepId: ProgressiveFormGroupId;
  constructor(stepId: ProgressiveFormGroupId) {
    this.stepId = stepId;
  }
}

export class ActivateRestorationPointAction implements Action {
  static readonly TYPE = `${prefix}ACTIVATE_RESTORATION_POINT`;
  readonly type = `${prefix}ACTIVATE_RESTORATION_POINT`;
  readonly stepId: ProgressiveFormGroupId;
  constructor(stepId: ProgressiveFormGroupId) {
    this.stepId = stepId;
  }
}

export class MarkAsyncTasksAsPendingAction implements Action {
  static readonly TYPE = `${prefix}MARK_ASYNC_TASKS_AS_PENDING`;
  readonly type = `${prefix}MARK_ASYNC_TASKS_AS_PENDING`;
  readonly stepId: ProgressiveFormGroupId;
  constructor(stepId: ProgressiveFormGroupId) {
    this.stepId = stepId;
  }
}

export class MarkAsyncTasksAsCompletedAction implements Action {
  static readonly TYPE = `${prefix}MARK_AS_FORM_STEP_WITH_TASKS_COMPLETED`;
  readonly type = `${prefix}MARK_AS_FORM_STEP_WITH_TASKS_COMPLETED`;
  readonly stepId: ProgressiveFormGroupId;
  constructor(stepId: ProgressiveFormGroupId) {
    this.stepId = stepId;
  }
}

export type NGRXFormsActions<TValue> = _NGRXFormsActions<TValue>;

export type NGRXProgressiveFormsActions =
  | ActivateFormStepAction
  | InactivateFormStepAction
  | MarkAsEditableAction
  | MarkAsInertAction
  | MarkAsVisitedAction
  | MarkAsUnvisitedAction
  | SubmitFormStepAction
  | UnsubmitFormStepAction
  | MarkAsSubmittableAction
  | MarkAsUnsubmittableAction
  | DisableFormStepAction
  | EnableFormStepAction
  | FocusInitialControlAction
  | BackupFormStateAction
  | RestoreFormStateAction
  | ResetFormStepAction
  | MarkAsRestorationPointAction
  | ActivateRestorationPointAction
  | MarkAsyncTasksAsPendingAction
  | MarkAsyncTasksAsCompletedAction;

export function isProgressiveNGRXFormsAction(
  action: Actions
): action is NGRXProgressiveFormsActions {
  return !!action.type && action.type.startsWith('progressive-forms/');
}

export function isNGRXFormsAction<TValue>(
  action: Actions
): action is NGRXFormsActions<TValue> {
  return !!action.type && action.type.startsWith('ngrx/forms/');
}

export type Actions<TValue = any> =
  | NGRXFormsActions<TValue>
  | NGRXProgressiveFormsActions;

export const ALL_PROGRESSIVE_NGRX_FORMS_ACTION_TYPES: Actions<any>['type'][] = [
  ...ALL_NGRX_FORMS_ACTION_TYPES,
  ActivateFormStepAction.TYPE,
  InactivateFormStepAction.TYPE,
  MarkAsEditableAction.TYPE,
  MarkAsInertAction.TYPE,
  MarkAsVisitedAction.TYPE,
  MarkAsUnvisitedAction.TYPE,
  SubmitFormStepAction.TYPE,
  UnsubmitFormStepAction.TYPE,
  MarkAsSubmittableAction.TYPE,
  MarkAsUnsubmittableAction.TYPE,
  DisableFormStepAction.TYPE,
  EnableFormStepAction.TYPE,
  FocusInitialControlAction.TYPE,
  BackupFormStateAction.TYPE,
  RestoreFormStateAction.TYPE,
  ResetFormStepAction.TYPE,
  MarkAsRestorationPointAction.TYPE,
  ActivateRestorationPointAction.TYPE,
  MarkAsyncTasksAsPendingAction.TYPE,
  MarkAsyncTasksAsCompletedAction.TYPE,
];
