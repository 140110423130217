import { FunnelSettingsGuard } from '@innogy/become-a-customer/state';
import type { ComponentNameAndModule } from '@sitecore-jss/sitecore-jss-angular';

const loadChildren = () =>
  import('./lib/become-a-customer.lazy.module').then(
    (mod) => mod.BecomeACustomerLazyModule
  );

const becomeACustomerLazyImports: ComponentNameAndModule[] = [
  {
    path: 'CalculateUsageQuestionnaire',
    loadChildren,
  },
  {
    path: 'SMEUsageQuestionnaire',
    loadChildren,
  },
  {
    path: 'Calculate',
    loadChildren,
  },
  {
    path: 'VerticalOffer',
    loadChildren,
    canActivate: [FunnelSettingsGuard],
  },
  {
    path: 'HorizontalOffer',
    loadChildren,
    canActivate: [FunnelSettingsGuard],
  },
  {
    path: 'AcquisitionCommodityOffer',
    loadChildren,
    canActivate: [FunnelSettingsGuard],
  },
  {
    path: 'Order',
    loadChildren,
    canActivate: [FunnelSettingsGuard],
  },
  {
    path: 'OrderOverviewED',
    loadChildren,
  },
  {
    path: 'OrderOverviewSME',
    loadChildren,
  },
  {
    path: 'OrderOverviewEssent',
    loadChildren,
  },
  {
    path: 'EmailQuotationReactionHandler',
    loadChildren,
  },
  {
    path: 'MGMWidget',
    loadChildren,
  },
];
export { becomeACustomerLazyImports };
