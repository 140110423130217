import type { ScText } from '@innogy/common-ui/models';
import type { UsageQuestionnaire } from '@innogy/eplus/models';
import type { ScLinkField } from '@core/jss-models';

export interface SolarPaybackWizardRendering {
  LandingTitle: ScText;
  LandingDescription: ScText;
  LandingUsageLabel: ScText;
  LandingUsagePlaceholder: ScText;
  LandingEstimate: ScText;
  LandingSubmit: ScText;
  LandingUsageTooltip: ScText;
  LandingUsageRequiredError: ScText;
  LandingUsageBetweenError: ScText;
  ResultTitle: ScText;
  ResultYieldPower: ScText;
  ResultYieldPowerTooltip: ScText;
  ResultRecommendedAmountOfPanels: ScText;
  ResultRecommendedAmountOfPanelsTooltip: ScText;
  ResultSavingsPerYear: ScText;
  ResultSavingsPerYearTooltip: ScText;
  ResultUsps: ScText;
  ResultPrimaryLink: ScLinkField;
  ResultPaybackTimeTooltip: ScText;
  ResultPaybackTime: ScText;
  UsageEstimate: { fields: UsageQuestionnaire };
}

const maakEenKeuze = 'Maak een keuze';
const flatOfAppartement = 'Flat of appartement';
const tweeOnderEenKap = 'Twee onder een kap';
const vrijstaandeWoning = 'Vrijstaande woning';
const tweePersonen = '2 personen';
const driePersonen = '3 personen';
const vierPersonen = '4 personen';
const vijfPersonen = '5 personen';
const zesPersonenOfMeer = '6 personen of meer';

export const MockSolarPaybackWizardRendering: SolarPaybackWizardRendering = {
  LandingUsageTooltip: { value: '' },
  LandingUsageLabel: {
    value: 'Hoeveel kWh stroom verbruik je per jaar?',
  },
  LandingUsagePlaceholder: { value: 'Je stroomverbruik' },
  LandingEstimate: { value: 'Schat mijn verbruik in' },
  LandingSubmit: { value: 'Bekijk je terugverdientijd' },
  LandingTitle: { value: 'Bereken je terugverdientijd' },
  LandingDescription: {
    value: 'Ontdek binnen 1 minuut de terugverdientijd van je zonnepanelen.',
  },
  LandingUsageRequiredError: { value: 'Vul je stroomverbruik in' },
  LandingUsageBetweenError: { value: 'Vul een getal in tussen 1250 en 13000' },
  ResultPrimaryLink: {
    value: {
      href: '/zonnepanelen/adviesgesprek',
      text: 'Vraag adviesgesprek aan',
      anchor: 'adviesgesprek',
      linktype: 'internal',
      class: '',
      title: '',
      target: '',
      querystring: '',
      id: '{A55188BC-3EE4-4C36-94D2-450DBAF23057}',
    },
  },
  ResultTitle: { value: 'Resultaten' },
  ResultYieldPower: {
    value: 'Je wekt {{yieldPower|?}} kWh energie per jaar op',
  },
  ResultYieldPowerTooltip: { value: 'Lorem ipsum' },
  ResultRecommendedAmountOfPanels: {
    value:
      'Met je huidige verbruik heb je {{recommendedPanels|?}} panelen nodig',
  },
  ResultRecommendedAmountOfPanelsTooltip: { value: 'Ipsum Lorem' },
  ResultSavingsPerYear: {
    value: 'Je bespaart {{savingsPerYear|?}}&nbsp;per jaar',
  },
  ResultSavingsPerYearTooltip: { value: 'Placeholder tooltip' },
  ResultUsps: {
    value:
      '<ul class=" rich-text-list-icon-check-success">\n    <li><span>Je hebt de juiste hoeveelheid zonnepanelen liggen.</span></li>\n    ' +
      '<li><span>Telefonisch of aan huis</span></li>\n    <li><span>Je eigen persoonlijke adviseur</span></li>\n</ul>\n<br>',
  },
  ResultPaybackTime: {
    value: 'Jouw terugverdientijd is {{paybackTime|?}} jaar',
  },
  ResultPaybackTimeTooltip: { value: 'Hier komt een tooltip' },
  UsageEstimate: {
    fields: {
      supplyTypeOptionsPlaceHolder: { value: maakEenKeuze },
      housingTypeOptions: [
        {
          id: 'a62d3b4c-152d-4c5f-b2e2-2c7ecafd5e68',
          url: '/algemene-content/verbruik-questionnaire/flat-of-appartement',
          name: flatOfAppartement,
          displayName: flatOfAppartement,
          fields: {
            value: { value: 'appartement' },
            label: { value: flatOfAppartement },
          },
        },
        {
          id: 'e42b1257-002c-4924-8ab5-107fd33a296a',
          url: '/algemene-content/verbruik-questionnaire/hoekwoning',
          name: 'Hoekwoning',
          displayName: 'Hoekwoning',
          fields: {
            value: { value: 'hoek' },
            label: { value: 'Hoekwoning' },
          },
        },
        {
          id: '8d7f7bf4-38c7-453f-81bd-aacbc0f639bd',
          url: '/algemene-content/verbruik-questionnaire/rijtjeshuis',
          name: 'Rijtjeshuis',
          displayName: 'Rijtjeshuis',
          fields: {
            value: { value: 'rijtje' },
            label: { value: 'Rijtjeshuis' },
          },
        },
        {
          id: '4ae0b6f8-9c5b-4203-8ab2-d2cc1d0ffe84',
          url: '/algemene-content/verbruik-questionnaire/twee-onder-een-kap',
          name: tweeOnderEenKap,
          displayName: tweeOnderEenKap,
          fields: {
            value: { value: '2onder1kap' },
            label: { value: tweeOnderEenKap },
          },
        },
        {
          id: 'dce3bc92-4252-471c-ae6f-9ef273194979',
          url: '/algemene-content/verbruik-questionnaire/vrijstaande-woning',
          name: vrijstaandeWoning,
          displayName: vrijstaandeWoning,
          fields: {
            value: { value: 'vrijstaand' },
            label: { value: vrijstaandeWoning },
          },
        },
      ],
      residentsOptionsLabel: {
        value: 'Hoeveel personen wonen er op dit adres?​',
      },
      supplyTypeOptions: [
        {
          id: '1a3a2dcd-c982-455f-b306-079755df0899',
          url: '/algemene-content/verbruik-questionnaire/stroom-en-gas',
          name: 'Stroom en Gas',
          displayName: 'Stroom en Gas',
          fields: {
            value: { value: 'ELECTRICITY_AND_GAS' },
            label: { value: 'Stroom & Gas' },
          },
        },
        {
          id: '6b0519af-4116-4489-93b3-9943cf9dd32f',
          url: '/algemene-content/verbruik-questionnaire/aleen-stroom',
          name: 'Aleen stroom',
          displayName: 'Aleen stroom',
          fields: {
            value: { value: 'ELECTRICITY' },
            label: { value: 'Alleen stroom' },
          },
        },
      ],
      Title: { value: 'Mijn verbruik inschatten' },
      housingTypeOptionsLabel: {
        value: 'Om wat voor type woning gaat het?​',
      },
      residentsOptionsPlaceHolder: { value: maakEenKeuze },
      housingTypeOptionsPlaceHolder: { value: maakEenKeuze },
      SubmitLabel: { value: 'Schat verbruik in​' },
      supplyTypeOptionsLabel: {
        value: 'Voor welk type energie wil je het verbruik inschatten?​',
      },
      errorMessage: { value: 'Maak een keuze om door te gaan.' },
      residentsOptions: [
        {
          id: '8d679a0f-eead-435c-ad60-020d3f267149',
          url: '/algemene-content/verbruik-questionnaire/1-persoon',
          name: '1 persoon',
          displayName: '1 persoon',
          fields: {
            value: { value: '1' },
            label: { value: '1 persoon' },
          },
        },
        {
          id: '5c6b667c-3cbe-47ba-b01d-dedd1684f6f7',
          url: '/algemene-content/verbruik-questionnaire/2-personen',
          name: tweePersonen,
          displayName: tweePersonen,
          fields: {
            value: { value: '2' },
            label: { value: tweePersonen },
          },
        },
        {
          id: '6f8365bb-1ee7-408f-81a2-4bd946e7a71f',
          url: '/algemene-content/verbruik-questionnaire/3-personen',
          name: driePersonen,
          displayName: driePersonen,
          fields: {
            value: { value: '3' },
            label: { value: driePersonen },
          },
        },
        {
          id: '36922ef7-0b64-473a-bb77-505d82a5ebd9',
          url: '/algemene-content/verbruik-questionnaire/4-personen',
          name: vierPersonen,
          displayName: vierPersonen,
          fields: {
            value: { value: '4' },
            label: { value: vierPersonen },
          },
        },
        {
          id: '80a1ca39-5b8a-412d-8e7a-a3a893d1796b',
          url: '/algemene-content/verbruik-questionnaire/5-personen',
          name: vijfPersonen,
          displayName: vijfPersonen,
          fields: {
            value: { value: '5' },
            label: { value: vijfPersonen },
          },
        },
        {
          id: 'f807d6c3-94aa-4f7e-889f-de49bba37a06',
          url: '/algemene-content/verbruik-questionnaire/6-personen-of-meer',
          name: zesPersonenOfMeer,
          displayName: zesPersonenOfMeer,
          fields: {
            value: { value: '6' },
            label: { value: zesPersonenOfMeer },
          },
        },
      ],
      Subtitle: {
        value:
          'Beantwoord onderstaande vragen en we maken een inschatting van je jaarverbruik.',
      },
      componentName: { value: 'Keuzehulp' },
    },
  },
};
