import { createAction } from '@ngrx/store';

const PREFIX = '[Insulation product overview]';

export const showInsulationProductOverviewAction = createAction(
  `${PREFIX} Show overview`
);

export const hideInsulationProductOverviewAction = createAction(
  `${PREFIX} Hide overview`
);
