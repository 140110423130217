import type { Observable } from 'rxjs';
import { merge } from 'rxjs';
import { buffer, filter, map, mergeAll, withLatestFrom } from 'rxjs/operators';

/**
 * Buffer events when condition is true, pass events through when condition is false
 * @see https://stackoverflow.com/a/47248106
 *
 * @param source$
 * @param bufferIt$
 */
export function bufferIf<T>(
  source$: Observable<T>,
  bufferIt$: Observable<boolean>
) {
  const buffered$ = source$.pipe(
    withLatestFrom(bufferIt$),
    filter(([_, bufferIsOn]) => bufferIsOn),
    map(([x]) => x),
    buffer(bufferIt$.pipe(filter((x) => !x))),
    filter((x) => x.length > 0), // filter out empty buffers
    mergeAll() // unwind the buffer
  );

  const unbuffered$ = source$.pipe(
    withLatestFrom(bufferIt$),
    filter(([_, bufferIsOn]) => !bufferIsOn),
    map(([x]) => x)
  );

  return merge(buffered$, unbuffered$);
}

export const bufferIfOp =
  <T>(bufferIt$: Observable<boolean>) =>
  (source$: Observable<T>) =>
    bufferIf(source$, bufferIt$);
