import type { PrefillAction } from '@innogy/shared/forms';
import {
  createNgrxFormReducer,
  onPrefillActionReducer,
  wrappedFormReducer,
} from '@innogy/shared/forms';
import {
  conditionalOn,
  isIBAN,
  onNgrxFormsControlId,
  validateSequential,
} from '@innogy/utils-deprecated';
import type { Action } from '@ngrx/store';
import type { FormGroupState } from 'ngrx-forms';
import {
  createFormGroupState,
  reset,
  ResetAction,
  setValue,
  updateGroup,
} from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';

export const progressivePaymentDetailsFormFeatureKey =
  'progressivePaymentDetailsForm';

export interface FormPaymentDetails {
  iban: string;
}
export interface PaymentDetailsReducerOptions {
  prefillOnAction?: PrefillAction<FormPaymentDetails>;
  validateOnPrefill?: boolean;
}

export interface PaymentDetailsFormState {
  formState: FormGroupState<FormPaymentDetails>;
}

export const createInitialPaymentDetailsFormState = (formId: string) =>
  createFormGroupState<FormPaymentDetails>(formId, {
    iban: '',
  });

export const getInitialPaymentDetailsState = (
  formId: string
): PaymentDetailsFormState => ({
  formState: createInitialPaymentDetailsFormState(formId),
});

export const validatePaymentDetailsForm = (state: PaymentDetailsFormState) => {
  return updateGroup<FormPaymentDetails>({
    iban: validateSequential(required, isIBAN),
  })(state.formState);
};

export const resetPaymentDetailsForm =
  (formId: string) => (state: PaymentDetailsFormState) => ({
    ...state,
    formState: reset(
      setValue(
        state.formState,
        createInitialPaymentDetailsFormState(formId).value
      )
    ),
  });

const _reducer = (formId: string, options: PaymentDetailsReducerOptions = {}) =>
  createNgrxFormReducer(
    getInitialPaymentDetailsState(formId),
    formId,
    validatePaymentDetailsForm,
    onNgrxFormsControlId(ResetAction, formId, resetPaymentDetailsForm(formId)),
    conditionalOn(
      !!options.prefillOnAction,
      options.prefillOnAction,
      onPrefillActionReducer(validatePaymentDetailsForm, options)
    )
  );

export function createProgressivePaymentDetailsReducer(
  formId: string,
  options?: PaymentDetailsReducerOptions
) {
  const _wrappedReducer = wrappedFormReducer(
    _reducer(formId, options),
    validatePaymentDetailsForm
  );

  return (
    state: PaymentDetailsFormState = getInitialPaymentDetailsState(formId),
    action: Action
  ): PaymentDetailsFormState => {
    return _wrappedReducer(state, action);
  };
}
