<wl-experience-editor>
  <ng-template wlLive>
    <div *ngIf="showComponent" class="information-bar">
      <ng-container *ngTemplateOutlet="body"></ng-container>
    </div>
  </ng-template>
  <ng-template wlPreview>
    <div *ngIf="currentPageIsEnabled" class="information-bar">
      <ng-container *ngTemplateOutlet="body"></ng-container>
    </div>
  </ng-template>
</wl-experience-editor>

<ng-template #body>
  <div class="container information-bar-content">
    <div class="d-flex align-items-center">
      <div *wlGenericRichText="rendering?.fields?.message"></div>
    </div>
  </div>
</ng-template>
