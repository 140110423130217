<!-- KNOWN BUG: When clicking the logo in the footer while already on the homepage, it will not scroll-up. The reason
 for this is because we initialize the RouterModule with onSameUrlNavigation: 'ignore' in the config. This should
 be changed to onSameUrlNavigation: 'reload' (the default value), but changing this right now breaks the kennisbank
 because of the navigation strategy used in the KnowledgeBaseService. It will get stuck in an infinite loop -->
<a
  [routerLink]="basePath"
  [attr.aria-label]="'Terug naar de hoofdpagina van ' + brand"
>
  <img [src]="'assets/logo-footer.svg?v=2' | deployUrl" class="logo" alt="" />
</a>
