import { combineEffectsWithProviders } from '@essent/common';

import { getOfferApi } from './get-offer.config';
import { getOffersApi } from './get-offers.config';

export * from './get-offer.reducer';
export * from './get-offer.service';
export * from './get-offer.config';

export * from './get-offers.reducer';
export * from './get-offers.selector';
export * from './get-offers.service';

const {
  EFFECTS: getOfferEffectsWithProviders,
  PROVIDERS: getOfferProvidersWithEffects,
} = combineEffectsWithProviders(getOfferApi, getOffersApi);

export const getOfferEffects = [...getOfferEffectsWithProviders];

export const getOfferProviders = [...getOfferProvidersWithEffects];
