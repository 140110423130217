import { InjectionToken } from '@angular/core';
import type { ClientEnvironmentConfig, JSSConfig } from '@core/config-models';

import type { AppConfig } from './app-config';

export const ENVIRONMENT_CONFIG = new InjectionToken<ClientEnvironmentConfig>(
  'Environment Config'
);

export const JSS_CONFIG = new InjectionToken<JSSConfig>('JSS Config');

export const API_PREFIX = new InjectionToken<string>('API_PREFIX');

export const API_PUBLIC_PREFIX = new InjectionToken<string>(
  'API_PUBLIC_PREFIX'
);

export const ROOT_PLACEHOLDER_KEY = new InjectionToken<string>(
  'ROOT_PLACEHOLDER_KEY'
);

export const CONFIGMODULE_CONFIG = new InjectionToken<AppConfig>(
  'App Configuration'
);

export const VERTICAL_SCROLL_OFFSET = new InjectionToken<number>(
  'Vertical scroll offset'
);
