import { Component, EventEmitter, Input, Output } from '@angular/core';
import { JssNavItem } from '@core/jss-models';

@Component({
  selector: 'wl-navbar-dropdown',
  templateUrl: './navbar-dropdown.component.html',
  styleUrls: [
    './navbar-dropdown.component.ed.scss',
    './navbar-dropdown.component.essent.scss',
  ],
})
export class NavbarDropdownComponent {
  @Input()
  language?: string;

  @Input()
  item!: JssNavItem;

  @Output()
  clicked = new EventEmitter<JssNavItem>();

  opened = false;

  open() {
    this.opened = true;
  }

  close() {
    this.opened = false;
  }
}
