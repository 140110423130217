import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import {
  getRequestClient,
  RequestClientInjectionToken,
} from '@essent/ngrx-service-wrapper';
import {
  API_PREFIX,
  API_PUBLIC_PREFIX,
  ConfigModule,
} from '@core/config-angular';

@NgModule({
  imports: [CommonModule, ConfigModule],
  providers: [
    {
      provide: RequestClientInjectionToken,
      useFactory: getRequestClient,
      deps: [HttpClient, API_PREFIX],
    },
  ],
})
export class ApiClientMijnModule {}

@NgModule({
  imports: [CommonModule, ConfigModule],
  providers: [
    {
      provide: RequestClientInjectionToken,
      useFactory: getRequestClient,
      deps: [HttpClient, API_PUBLIC_PREFIX],
    },
  ],
})
export class ApiClientOpenModule {}
