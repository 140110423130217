import {
  createNgrxFormReducer,
  wrappedFormReducer,
} from '@innogy/shared/forms';
import type { Action } from '@ngrx/store';
import {
  createFormGroupState,
  updateGroup,
  validate,
  type FormGroupState,
} from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';

export interface ChargePointConfigurationFormState {
  chargePointType: string | null;
  productColor: string | null;
  fixedCable: boolean | null;
}

export interface ChargePointConfigurationState {
  formState: FormGroupState<ChargePointConfigurationFormState>;
}

export const chargePointConfigurationFormId =
  'emobility-lead-charge-point-configuration';

const chargePointConfigurationInitialState =
  createFormGroupState<ChargePointConfigurationFormState>(
    chargePointConfigurationFormId,
    {
      chargePointType: null,
      productColor: null,
      fixedCable: null,
    }
  );

export const initialChargePointConfigurationState: ChargePointConfigurationState =
  {
    formState: chargePointConfigurationInitialState,
  };

export const validateChargePointConfigurationForm = (
  state: ChargePointConfigurationState
) => {
  return updateGroup(state.formState, {
    chargePointType: validate(required),
    productColor: validate(required),
    fixedCable: validate(required),
  });
};

export const _reducer = createNgrxFormReducer(
  initialChargePointConfigurationState,
  chargePointConfigurationFormId,
  validateChargePointConfigurationForm
);

const _wrappedReducer = wrappedFormReducer(
  _reducer,
  validateChargePointConfigurationForm
);

export function chargePointConfigurationReducer(
  state: ChargePointConfigurationState | undefined,
  action: Action
) {
  return _wrappedReducer(state, action);
}
