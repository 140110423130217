import { createSelector } from '@ngrx/store';

import { getBecomeACustomerState } from '../../become-a-customer-feature.selector';

export const startDateState = createSelector(
  getBecomeACustomerState,
  (state) => state.startDate
);

export const getStartDateState = createSelector(
  startDateState,
  (state) => state.getStartDate
);

export const putStartDateState = createSelector(
  startDateState,
  (state) => state.putStartDate
);

export const getStartDateStateData = createSelector(
  getStartDateState,
  (state) => state.data && state.data[0]
);
