import { Location } from '@angular/common';
import type { OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import {
  Directive,
  ElementRef,
  Input,
  Renderer2,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import {
  extractNavigationExtrasFromPath,
  isAbsoluteUrl,
  isSitecoreMediaLink,
  mergeNavigationExtras,
} from '@core/routing-utils';
import { ScrollService } from '@innogy/utils-deprecated';
import {
  LinkField,
  GenericLinkDirective as OriginalGenericLinkDirective,
} from '@sitecore-jss/sitecore-jss-angular';
import merge from 'lodash/merge';

type UnlistenFunction = () => void;

export const defaultNavigationExtras: NavigationExtras = {
  queryParamsHandling: 'merge',
};

@Directive({ selector: '[wlGenericLink]' })
export class GenericLinkDirective
  extends OriginalGenericLinkDirective
  implements OnDestroy, OnChanges
{
  #navigationExtras = defaultNavigationExtras;

  @Input('wlGenericLink') override field!: LinkField;
  @Input('wlGenericLinkEditable') override editable = true;
  @Input('wlGenericLinkAttrs') override attrs: any = {};
  @Input('wlGenericLinkExtras') override extras?: NavigationExtras;

  private readonly listeners: UnlistenFunction[] = [];

  constructor(
    viewContainer: ViewContainerRef,
    templateRef: TemplateRef<any>,
    renderer: Renderer2,
    elementRef: ElementRef,
    private readonly router2: Router,
    private readonly location: Location,
    private readonly scrollService: ScrollService
  ) {
    super(viewContainer, templateRef, renderer, elementRef, router2);
  }

  protected override renderTemplate(
    props: Record<string, string | number | boolean | null>,
    linkText: string
  ) {
    const viewRef = this.viewContainer.createEmbeddedView(this.templateRef);
    const properties = Object.entries(props);

    /**
     * For some reason, when creating a Sitecore link to another page, and adding an anchor to this link, the
     * anchor will not be appended to the href, but is added as a separate attribute on the link.
     * e.g. <a href="/some/page" anchor="goat">
     * We extract it here to provide it as a NavigationExtra when navigating
     */
    const fragment = props['anchor'] as string | undefined;
    const queryString = props['querystring'] as string | undefined;

    viewRef.rootNodes.forEach((node) => {
      properties.forEach(([key, propValue]) => {
        if (key === 'id') {
          // The directive uses the sitecore element ID.
          // We can have multiple links on a page linking to the same item causing id collisions.
          // the id is not needed on these anchors, so we remove it.
          this.updateAttribute(node, key, null);
        } else if (
          key === 'href' &&
          typeof propValue === 'string' &&
          !isAbsoluteUrl(propValue)
        ) {
          const [path, navigationExtras] = extractNavigationExtrasFromPath(
            propValue,
            queryString
          );

          /**
           * We set the original propValue (href) as attribute, the fragment may be missing from this href if it's
           * saved in a separate property called "anchor" (see explanation above). This is not a problem because this
           * href value will not be used when navigating because we call preventDefault() below here to build up our
           * own path including the fragment.
           */
          this.updateAttribute(node, key, propValue);

          const listener = this.renderer.listen(node, 'click', (event) => {
            /**
             * When we have a media linktype, we should not call 'preventDefault' so we navigate to it normally
             * instead of using the angular router. This is needed so that media items (e.g. PDF's) can be downloaded.
             */
            if (isSitecoreMediaLink(propValue)) {
              return;
            }
            event.preventDefault();

            const combinedNavigationExtras = mergeNavigationExtras(
              this.#navigationExtras,
              navigationExtras,
              { fragment }
            );
            if (!path && combinedNavigationExtras?.fragment) {
              /**
               * When there is no path defined (meaning it should stay on the current page), but there
               * is a fragment, it should (try to) scroll to the anchor-point.
               */
              this.scrollService.scrollToAnchorAnimated(
                combinedNavigationExtras.fragment
              );
            } else {
              /**
               * Angular navigation will see an empty path as a navigation to the root path (/), in this case we will use the
               * current path. If a link should redirect to the homepage a "/" should be used (or an absolute URL).
               */
              this.router2.navigate(
                [path || this.location.path()],
                combinedNavigationExtras
              );
            }
          });
          this.listeners.push(listener);
        } else {
          this.updateAttribute(node, key, propValue);
        }
      });

      if (node.childNodes?.length === 0 && linkText) {
        node.textContent = linkText;
      }
    });
  }

  ngOnDestroy(): void {
    this.listeners.forEach((unlisten) => unlisten());
  }

  override ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes['extras']) {
      const mergedValues = {};
      merge(
        mergedValues,
        defaultNavigationExtras,
        changes['extras'].currentValue
      );
      this.#navigationExtras = mergedValues;
    }
  }
}
