import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import type { FormAddress } from '@innogy/common-ui/forms';
import type { SimpleProgressiveFormCardContent } from '@innogy/common-ui/progressive-forms';
import { TrackToolStep } from '@innogy/core/analytics';
import { getFieldValue, getItemLinkFieldValue } from '@core/jss-utils';
import type { ProgressiveFormGroupState } from '@innogy/progressive-ngrx-forms';
import { ProgressiveAddressFormRendering } from '@innogy/shared/progressive-form/sitecore';

@Component({
  selector:
    'wl-eplus-progressive-address-form[progressiveFormState][rendering][trackStep]',
  templateUrl: './eplus-progressive-address-form.component.html',
})
export class EplusProgressiveAddressFormComponent implements OnInit {
  @Input() trackStep!: TrackToolStep;
  @Input() rendering!: ProgressiveAddressFormRendering;

  @Input()
  progressiveFormState!: ProgressiveFormGroupState<FormAddress> | null;

  progressiveFormCardContent?: SimpleProgressiveFormCardContent;

  ngOnInit(): void {
    this.progressiveFormCardContent = {
      title: getFieldValue(this.rendering.fields, 'addressStepTitle', ''),
      subtitle: getFieldValue(this.rendering.fields, 'addressStepSubTitle', ''),
      submitButtonText: getFieldValue(
        this.rendering.fields,
        'addressNextButtonText',
        ''
      ),
      submitButtonIcon: getItemLinkFieldValue(
        this.rendering.fields,
        'addressNextButtonIcon'
      ) as IconProp,
      stepNumber: this.trackStep.step,
    };
  }
}
