import type { FormGroupState, KeyValue } from 'ngrx-forms';
import {
  markAsDirty,
  markAsSubmitted,
  markAsTouched,
  markAsUnsubmitted,
} from 'ngrx-forms';

export const validateNgrxState = <
  TState extends { formState: FormGroupState<TFormValues> },
  TFormValues extends KeyValue,
>(
  state: TState,
  validator: (state: TState) => FormGroupState<TFormValues>,
  submitIfValid = true
): TState => {
  let validatedFormState = markAsTouched(markAsDirty(validator(state)));
  if (submitIfValid) {
    validatedFormState = validatedFormState.isValid
      ? markAsSubmitted(validatedFormState)
      : markAsUnsubmitted(validatedFormState);
  }
  return {
    ...state,
    formState: validatedFormState,
  };
};
