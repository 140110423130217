import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CommonUiPartialsModule } from '@innogy/common-ui/partials';
import { CoreJssUtilsModule } from '@core/jss-utils';
import { CoreAnalyticsModule } from '@innogy/core/analytics';
import { CoreExperienceEditorModule } from '@innogy/core/experience-editor';
import { AutomationIdDirective } from '@innogy/utils-automation-id';
import { UtilsModule } from '@innogy/utils-deprecated';
import { UtilsDomModule } from '@innogy/utils-dom';
import { UtilsContentsquareModule } from '@innogy/utils/contentsquare';
import {
  NgbAccordionModule,
  NgbAlertModule,
  NgbPopoverModule,
  NgbRatingModule,
} from '@ng-bootstrap/ng-bootstrap';
import { TranslatePipe, TranslateDirective } from '@ngx-translate/core';
import { JssModule } from '@sitecore-jss/sitecore-jss-angular';

import { AccordionComponent } from './accordion/accordion.component';
import { AddressComponent } from './address/address.component';
import { AsyncButtonComponent } from './async-button/async-button.component';
import { CalculateCardComponent } from './calculate-card/calculate-card.component';
import { ChangeButtonComponent } from './change-button/change-button.component';
import { ChecklistItemComponent } from './checklist-item/checklist-item.component';
import { CollapsableInfoBlockComponent } from './collapsable-info-block/collapsable-info-block.component';
import { ContentCardComponent } from './content-card/content-card.component';
import { ErrorCardComponent } from './error-card/error-card.component';
import { ErrorMessageComponent } from './error-message/error-message.component';
import { FilterComponent } from './filter/filter.component';
import { GenericLinkDirective } from './generic-link/generic-link.directive';
import { GenericRichTextDirective } from './generic-rich-text/generic-rich-text.directive';
import { IconCardButtonComponent } from './icon-card-button/icon-card-button.component';
import { ImageCardButtonComponent } from './image-card-button/image-card-button.component';
import { ImageComponent } from './image/image.component';
import { InfoCardComponent } from './info-card/info-card.component';
import { InformationBlockComponent } from './information-block/information-block.component';
import { ListComponent } from './list/list.component';
import { ModalPageHeaderComponent } from './modal-page-header/modal-page-header.component';
import { MoreInfoButtonComponent } from './more-info-button/more-info-button.component';
import { RatingComponent } from './rating/rating.component';
import { RichTextWrapperComponent } from './rich-text-wrapper/rich-text-wrapper.component';
import { SegmentedControlComponent } from './segmented-control/segmented-control.component';
import { SelfServiceCardComponent } from './selfservice-card/selfservice-card.component';
import { SuccessPageComponent } from './success-page/success-page.component';
import { ServerErrorComponent } from './technical-issues/server-error.component';
import { ToolTipComponent } from './tooltip/tooltip.component';

const components = [
  AccordionComponent,
  ToolTipComponent,
  ServerErrorComponent,
  SuccessPageComponent,
  RichTextWrapperComponent,
  ModalPageHeaderComponent,
  ListComponent,
  ContentCardComponent,
  ErrorCardComponent,
  ErrorMessageComponent,
  ChangeButtonComponent,
  FilterComponent,
  InfoCardComponent,
  AddressComponent,
  ChecklistItemComponent,
  RatingComponent,
  IconCardButtonComponent,
  ImageCardButtonComponent,
  GenericRichTextDirective,
  GenericLinkDirective,
  ImageComponent,
  InformationBlockComponent,
  MoreInfoButtonComponent,
  AsyncButtonComponent,
  CalculateCardComponent,
  CollapsableInfoBlockComponent,
  SelfServiceCardComponent,
  SegmentedControlComponent,
];

@NgModule({
  imports: [
    CommonModule,
    CommonUiPartialsModule,
    CoreAnalyticsModule,
    CoreExperienceEditorModule,
    FontAwesomeModule,
    NgbAccordionModule,
    NgbPopoverModule,
    NgbAlertModule,
    NgbRatingModule,
    JssModule,
    UtilsModule,
    TranslatePipe,
    TranslateDirective,
    UtilsContentsquareModule,
    UtilsDomModule,
    CoreJssUtilsModule,
    AutomationIdDirective,
  ],
  declarations: components,
  exports: components,
})
export class CommonUiUtilityComponentsModule {}
