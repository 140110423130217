import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PlatformService } from './platform/platform.service';

@NgModule({
  providers: [PlatformService],
  imports: [CommonModule],
})
export class CorePlatformModule {}
