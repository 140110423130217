import type { ComponentNameAndModule } from '@sitecore-jss/sitecore-jss-angular';

export * from './lib/marketing-heading.lazy.module';
export { marketingHeadingLazyImports };

const marketingHeadingLazyImports: ComponentNameAndModule[] = [
  {
    path: 'MarketingHeading',
    loadChildren: () =>
      import('./lib/marketing-heading.lazy.module').then(
        (mod) => mod.MarketingHeadingLazyModule
      ),
  },
];
