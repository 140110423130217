import { createAction } from '@ngrx/store';

const PREFIX = '[Solar product overview]';

export const showSolarPanelsProductOverviewAction = createAction(
  `${PREFIX} Show overview`
);

export const hideSolarPanelsProductOverviewAction = createAction(
  `${PREFIX} Hide overview`
);
