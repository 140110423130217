import { Component, Input } from '@angular/core';
import { type FormGroupControls, FormGroupState } from 'ngrx-forms';
import { UsageWizardSMEProps } from '@innogy/become-a-customer/shared/interfaces';
import type { UsageQuestionnaireSME } from '@innogy/become-a-customer/state';

@Component({
  selector: 'wl-usage-questionnaire-sme',
  templateUrl: './usage-questionnaire-sme.component.html',
})
export class UsageQuestionnaireSMEComponent {
  @Input({ required: true })
  formState!: FormGroupState<UsageQuestionnaireSME.FormValues>;
  @Input() rendering?: UsageWizardSMEProps;
  @Input() gasHidden = false;

  get formControls(): FormGroupControls<UsageQuestionnaireSME.FormValues> {
    return this.formState.controls;
  }
}
