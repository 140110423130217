import { Status } from '@essent/common';

import type { CollectionState } from './collection-state';
import { getCollectionStatus } from './collection-state';
import type { StateStatus } from './state-status.model';

export const getSuccess = (status: Status) => status === Status.SUCCESS;
export const getError = (status: Status) => status === Status.ERROR;

export const getIdle = (status: Status) => status === Status.IDLE;
export const getPending = (status: Status) => status === Status.PENDING;
export const getLoading = (status: Status) =>
  status === Status.PENDING || status === Status.IDLE;
export const getLoadingState = <T>(state: CollectionState<T>) =>
  getLoading(getCollectionStatus(state));
export const getErrorState = <T>(state: CollectionState<T>) =>
  getError(getCollectionStatus(state));
export const getStatus = <T>(state: StateStatus<T>) => state.status;
export const getData = <T>(state: StateStatus<T>) => state.data;
