<ng-container *ngIf="timerShown">
  <div *ngIf="makeTime(secondsToCount | async) as countdown" class="timer">
    <div class="timer__tile">
      <span class="timer__tile__number">{{ countdown.days }}</span>
      <span class="timer__tile__label">{{ timerLabels.daysLabel }}</span>
    </div>
    <div class="timer__tile">
      <span class="timer__tile__number">{{ countdown.hours }}</span>
      <span class="timer__tile__label">{{ timerLabels.hoursLabel }}</span>
    </div>
    <div class="timer__tile">
      <span class="timer__tile__number">{{ countdown.minutes }}</span>
      <span class="timer__tile__label">{{ timerLabels.minutesLabel }}</span>
    </div>
    <div class="timer__tile">
      <span class="timer__tile__number">{{ countdown.seconds }}</span>
      <span class="timer__tile__label">{{ timerLabels.secondsLabel }}</span>
    </div>
  </div>
</ng-container>
