import type {
  ValidationFn,
  AbstractControlState,
  InferredFormState,
  InferenceWrapper,
  ValidationErrors,
} from 'ngrx-forms';
import { setErrors } from 'ngrx-forms';

/**
 * The default ngrx-forms validate function evaluates all validation functions and returns all errors.
 * This function evaluates them in order, and only adds the first found error, allowing you to only show
 * a single error at a time.
 *
 * @param validators
 */
export const validateSequential =
  <T>(...validators: ValidationFn<T>[]) =>
  (
    controlState: AbstractControlState<T>
  ): InferredFormState<InferenceWrapper<T>> => {
    let errors: ValidationErrors = {};
    let hasError = false;

    for (let i = 0; i < validators.length && !hasError; i++) {
      const validator = validators[i];

      errors = validator(controlState.value);
      hasError = Object.keys(errors).length !== 0;
    }

    return setErrors(controlState, errors);
  };
