import { createSelector } from '@ngrx/store';

import { getBecomeACustomerState } from '../../become-a-customer-feature.selector';

export const getFlowId = createSelector(
  getBecomeACustomerState,
  ({ flowId }) => flowId.flowId
);

export const getShouldExtendTimeToLive = createSelector(
  getBecomeACustomerState,
  ({ flowId }) => flowId.extendTimeToLive
);
