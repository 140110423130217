import type { ComponentNameAndModule } from '@sitecore-jss/sitecore-jss-angular';

const knowledgeBaseLazyImports: ComponentNameAndModule[] = [
  {
    path: 'CategoryNavigation',
    loadChildren: () =>
      import('./lib/knowledge-base.lazy.module').then(
        (mod) => mod.KnowledgeBaseLazyModule
      ),
  },
  {
    path: 'Overview',
    loadChildren: () =>
      import('./lib/knowledge-base.lazy.module').then(
        (mod) => mod.KnowledgeBaseLazyModule
      ),
  },
  {
    path: 'JumbotronLegacy',
    loadChildren: () =>
      import('./lib/knowledge-base.lazy.module').then(
        (mod) => mod.KnowledgeBaseLazyModule
      ),
  },
  {
    path: 'ShareButtons',
    loadChildren: () =>
      import('./lib/knowledge-base.lazy.module').then(
        (mod) => mod.KnowledgeBaseLazyModule
      ),
  },
];
export { knowledgeBaseLazyImports };
