import type { OnDestroy } from '@angular/core';
import { Injectable } from '@angular/core';
import type { ToolTrackValues } from '@innogy/core/analytics';
import {
  AnalyticsActionTypes,
  lowerCaseObjectKeys,
  TrackToolService,
} from '@innogy/core/analytics';
import { AnalyticsMapPipe } from '@innogy/utils-deprecated';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import type { CalculateToolstepLaststepResult } from './calculate-toolstep-laststep.model';
@Injectable({
  providedIn: 'root',
})
export class CalculateToolstepLaststepService implements OnDestroy {
  private readonly onDestroy$ = new Subject();

  public readonly trackCalculateLaststep = (
    calculateResultValues: CalculateToolstepLaststepResult
  ) => {
    const trackingValues: ToolTrackValues = {
      step: 1,
      stepName: '',
      type: AnalyticsActionTypes.LAST_STEP,
      rendering: {
        componentName: 'keuzehulp',
      },
    };
    const {
      stroomverbruikJaar,
      stroomverbruikJaarDal,
      stroomverbruikJaarPiek,
      gasverbruikJaar,
      terugleveringJaar,
      bewonersAantal,
      woningType,
      aansluitingType,
    } = calculateResultValues;
    const extraValues = {
      result: lowerCaseObjectKeys({
        stroomverbruikJaar,
        stroomverbruikMaand: this.getMonthlyUsage(stroomverbruikJaar),
        stroomverbruikJaarDal,
        stroomverbruikMaandDal: this.getMonthlyUsage(stroomverbruikJaarDal),
        stroomverbruikJaarPiek,
        stroomverbruikMaandPiek: this.getMonthlyUsage(stroomverbruikJaarPiek),
        gasverbruikJaar: gasverbruikJaar,
        gasverbruikMaand: this.getMonthlyUsage(gasverbruikJaar),
        terugleveringJaar,
        terugleveringMaand: this.getMonthlyUsage(terugleveringJaar),
        aantalPersonen: bewonersAantal,
        typeHuis: this.analyticsMap.transform(woningType),
        typeAansluiting: this.analyticsMap.transform(aansluitingType),
      }),
    };

    return this.trackToolService
      .trackTool(trackingValues, extraValues)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe();
  };

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  constructor(
    private readonly trackToolService: TrackToolService,
    private readonly analyticsMap: AnalyticsMapPipe
  ) {}

  /**
   * Utilities
   */
  private getMonthlyUsage(consumption: number) {
    return Math.floor(consumption / 12);
  }
}
