import type { UsageQuestionnaireState } from '@innogy/common-ui/sales';
import { createUsageQuestionnaireReducer } from '@innogy/common-ui/sales';
import type { ActionReducerMap } from '@ngrx/store';
import { createFeatureSelector } from '@ngrx/store';

import type { SolarPanelsAPIState } from './solar-panels-api/solar-panels-api.reducer';
import {
  solarPanelsApiKey,
  solarPanelsAPIReducer,
} from './solar-panels-api/solar-panels-api.reducer';
import type { SolarPanelsFunnelState } from './solar-panels-funnel/solar-panels-funnel.reducer';
import {
  solarPanelsFunnelKey,
  solarPanelsFunnelReducer,
} from './solar-panels-funnel/solar-panels-funnel.reducer';
import type { SolarPanelsOrderFormState } from './solar-panels-order-form/solar-panels-order-form.reducer';
import {
  solarPanelsOrderFormKey,
  solarPanelsOrderFormReducer,
} from './solar-panels-order-form/solar-panels-order-form.reducer';
import type { SolarPanelsProductOverviewState } from './solar-panels-products/solar-panels-product-overview.reducer';
import {
  solarPanelsProductOverviewKey,
  solarPanelsProductOverviewReducer,
} from './solar-panels-products/solar-panels-product-overview.reducer';
import type { SolarPanelsResultOverviewState } from './solar-panels-result-overview/solar-panels-result-overview.reducer';
import {
  solarPanelsResultOverviewKey,
  solarPanelsResultOverviewReducer,
} from './solar-panels-result-overview/solar-panels-result-overview.reducer';
import type { SolarPanelsSuitabilityCheckState } from './solar-panels-suitability-check/solar-panels-suitability-check.reducer';
import {
  solarPanelsSuitabilityCheckKey,
  solarPanelsSuitabilityCheckReducer,
} from './solar-panels-suitability-check/solar-panels-suitability-check.reducer';
import type { SolarPanelsWizardState } from './solar-panels-wizard/solar-panels-wizard.reducer';
import {
  solarPanelsWizardFormId,
  solarPanelsWizardReducer,
} from './solar-panels-wizard/solar-panels-wizard.reducer';

export const solarPanelsSelectorKey = 'eplus-solar-panels';
export const SOLAR_USAGE_QUESTIONNAIRE_ID = 'solarpanels-usage-wizard';

export interface SolarPanelsReducerState {
  readonly [solarPanelsFunnelKey]: SolarPanelsFunnelState;
  readonly [solarPanelsWizardFormId]: SolarPanelsWizardState;
  readonly [solarPanelsSuitabilityCheckKey]: SolarPanelsSuitabilityCheckState;
  readonly [solarPanelsProductOverviewKey]: SolarPanelsProductOverviewState;
  readonly [solarPanelsOrderFormKey]: SolarPanelsOrderFormState;
  readonly [solarPanelsApiKey]: SolarPanelsAPIState;
  readonly [solarPanelsResultOverviewKey]: SolarPanelsResultOverviewState;
  readonly UsageQuestionnaireForm: UsageQuestionnaireState;
}

export const solarPanelsReducers: ActionReducerMap<SolarPanelsReducerState> = {
  [solarPanelsFunnelKey]: solarPanelsFunnelReducer,
  [solarPanelsWizardFormId]: solarPanelsWizardReducer,
  [solarPanelsSuitabilityCheckKey]: solarPanelsSuitabilityCheckReducer,
  [solarPanelsProductOverviewKey]: solarPanelsProductOverviewReducer,
  [solarPanelsOrderFormKey]: solarPanelsOrderFormReducer,
  [solarPanelsApiKey]: solarPanelsAPIReducer,
  [solarPanelsResultOverviewKey]: solarPanelsResultOverviewReducer,
  UsageQuestionnaireForm: createUsageQuestionnaireReducer(
    SOLAR_USAGE_QUESTIONNAIRE_ID
  ),
};

export const eplusSolarPanelsFeature =
  createFeatureSelector<SolarPanelsReducerState>(solarPanelsSelectorKey);
