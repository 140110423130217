import type { OnInit } from '@angular/core';
import { Component, HostBinding, Inject, Input } from '@angular/core';
import type { BackgroundSettings } from '@innogy/common-ui/shared';
import { getBackgroundSettingsFromRendering } from '@innogy/common-ui/shared';
import { ColumnContext } from '@innogy/common-ui/shared/interfaces';
import type { EnvConfigBrand } from '@core/config-models';
import { EnvironmentConfig } from '@core/config-models';
import { ENVIRONMENT_CONFIG } from '@core/config-angular';
import { InnogyComponentRendering } from '@core/jss-models';
import { MediaObserverService } from '@innogy/shared/responsive-tools';
import { toScrollAnchorId } from '@innogy/utils-deprecated';
import { map } from 'rxjs/operators';

import type { BasicContentValues } from '../basic-content/basic-content-settings.model';
import { getBasicContentValuesFromRendering } from '../basic-content/basic-content-settings.model';
import type {
  JumbotronHeight,
  JumbotronSettings,
  JumbotronVariant,
} from './jumbotron-settings.model';
import { getJumbotronSettingsFromRendering } from './jumbotron-settings.model';

@Component({
  selector: 'wl-jumbotron',
  templateUrl: './jumbotron-container.component.html',
  styleUrls: ['./jumbotron-container.component.scss'],
})
export class JumbotronContainerComponent implements OnInit {
  @Input({ required: true }) rendering!: InnogyComponentRendering;
  @Input() context?: ColumnContext;

  @HostBinding('attr.id') get id() {
    return this.anchorPoint;
  }

  content!: BasicContentValues;
  backgroundSettings?: BackgroundSettings;
  jumbotronSettings!: JumbotronSettings;
  brand: EnvConfigBrand;

  constructor(
    private readonly mediaObserverService: MediaObserverService,
    @Inject(ENVIRONMENT_CONFIG) private readonly _config: EnvironmentConfig
  ) {
    this.brand = this._config.brand;
  }

  isMobile$ = this.mediaObserverService.deviceInfo$.pipe(
    map(({ isMobile }) => isMobile)
  );

  ngOnInit() {
    this.content = getBasicContentValuesFromRendering(this.rendering);
    this.jumbotronSettings = getJumbotronSettingsFromRendering(this.rendering);
    this.backgroundSettings = getBackgroundSettingsFromRendering(
      this.rendering
    );
  }

  get height(): JumbotronHeight | undefined {
    return this.jumbotronSettings?.height;
  }

  get variant(): JumbotronVariant {
    return this.jumbotronSettings?.variant;
  }

  get showMarketingHeader(): boolean {
    return this.jumbotronSettings?.marketingHeader === true;
  }

  get verticalPadding(): string | undefined {
    return this.jumbotronSettings?.verticalPadding;
  }

  get anchorPoint(): string {
    if (this.jumbotronSettings?.anchorPoint) {
      return this.jumbotronSettings?.anchorPoint;
    }
    if (this.rendering?.uid) {
      return toScrollAnchorId(this.rendering.uid);
    }
    return '';
  }
}
