import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';
import { openGenericModal } from '@innogy/common-ui/modals';
import { GenericModalPosition } from '@innogy/common-ui/shared/interfaces';

import { selectSolarPanelsProductsWithCalculations } from '../solar-panels-api';
import {
  handleSolarPanelsFunnelPrivateErrorAction,
  selectSelectedSolarPanelsProduct,
  selectSolarPanelsFunnelSettings,
  setSelectedSolarPanelProductAction,
  onSolarPanelsFunnelNavigationAction,
} from '../solar-panels-funnel';
import {
  solarPanelsShowPropositionMoreInfoModalAction,
  solarPanelsResultOverviewSubmitAction,
} from './solar-panels-result-overview.actions';

@Injectable()
export class SolarPanelsResultOverviewEffects {
  funnelSettings$ = this.store$.select(selectSolarPanelsFunnelSettings);
  availableProducts$ = this.store$.select(
    selectSolarPanelsProductsWithCalculations
  );
  selectedProduct$ = this.store$.select(selectSelectedSolarPanelsProduct);

  constructor(
    private readonly store$: Store,
    private readonly actions$: Actions
  ) {}

  /**
   * When a customer is redirect from the suitability check directly to the result overview
   * there is no selected product yet. The first product gets selected in order to display something.
   */
  public readonly showSolarPanelsResultOverview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(onSolarPanelsFunnelNavigationAction),
      concatLatestFrom(() => [
        this.funnelSettings$,
        this.availableProducts$,
        this.selectedProduct$,
      ]),
      filter(
        ([action, funnelSettings, _, selectedProduct]) =>
          action.page === funnelSettings.resultOverviewPage &&
          selectedProduct === undefined
      ),
      map(([, , availableProducts]) => {
        const product = availableProducts?.[0];

        if (product) {
          return setSelectedSolarPanelProductAction({
            product,
          });
        }

        return handleSolarPanelsFunnelPrivateErrorAction({
          message:
            'No product available to display on the result overview page',
        });
      })
    )
  );

  public readonly solarPanelsResultOverviewSubmit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(solarPanelsResultOverviewSubmitAction),
      concatLatestFrom(() => this.funnelSettings$),
      map(([_, funnelSettings]) =>
        onSolarPanelsFunnelNavigationAction({ page: funnelSettings.orderPage })
      )
    )
  );

  public readonly openPropositionInfoModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(solarPanelsShowPropositionMoreInfoModalAction),
      map((info) => {
        return openGenericModal({
          ...info.props,
          position: GenericModalPosition.LEFT,
        });
      })
    )
  );
}
