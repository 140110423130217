import type { AsyncState } from '@essent/common';
import {
  createAsyncStateReducer,
  createInitialAsyncState,
} from '@essent/common';
import type { FlowPutSuccess, StartDate } from '@essent/new-customer';
import {
  getStartDate,
  getStartDateClear,
  getStartDateError,
  getStartDateSuccess,
  putStartDate,
  putStartDateClear,
  putStartDateError,
  putStartDateSuccess,
} from '@essent/new-customer';
import type { Action, ActionReducerMap } from '@ngrx/store';
import { combineReducers } from '@ngrx/store';

export type PutStartDateState = AsyncState<FlowPutSuccess>;
export type GetStartDateState = AsyncState<StartDate[]>;

export interface StartDateState {
  putStartDate: PutStartDateState;
  getStartDate: GetStartDateState;
}

const initialState: StartDateState = {
  putStartDate: createInitialAsyncState(),
  getStartDate: createInitialAsyncState(),
};

const _putStartDateReducer = createAsyncStateReducer(
  putStartDate,
  putStartDateSuccess,
  putStartDateError,
  putStartDateClear
);

const _getStartDateReducer = createAsyncStateReducer(
  getStartDate,
  getStartDateSuccess,
  getStartDateError,
  getStartDateClear
);

const _reducers: ActionReducerMap<StartDateState, Action> = {
  putStartDate: _putStartDateReducer,
  getStartDate: _getStartDateReducer,
};

const _combinedReducers = combineReducers(_reducers);

export function startDateReducer(
  state: StartDateState = initialState,
  action: Action
): StartDateState {
  return _combinedReducers(state, action);
}
