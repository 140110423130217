<wl-simple-progressive-form-card
  [progressiveFormState]="progressiveFormState"
  [content]="progressiveFormCardContent"
  [trackingConfig]="{
    toolName: trackStep.toolName,
    step: trackStep.step,
    stepName: trackStep.stepName,
  }"
>
  <ng-template #content>
    <span
      class="h6"
      wlCSCapture
      *scText="rendering?.fields?.salutationLabel"
    ></span>
    <ng-container *ngIf="salutationOptions">
      <div class="row mb-2">
        <div class="col-12">
          <wl-radio
            [rendering]="rendering"
            [step]="trackStep.step"
            [stepName]="trackStep.stepName"
            fieldName="geslacht"
            [sendValue]="false"
            [formsControl]="formState?.controls?.salutation"
            [radioItems]="salutationOptions"
            [inline]="true"
          ></wl-radio>
          <wl-input-error
            *ngIf="
              formState?.controls?.salutation?.isTouched &&
              formState?.controls?.salutation?.errors?.required &&
              rendering?.fields?.salutationRequiredError?.value
            "
            [automationId]="'salutation-required-error'"
            [rendering]="rendering"
            [messageField]="rendering?.fields?.salutationRequiredError"
            [step]="trackStep.step"
            [stepName]="trackStep.stepName"
          ></wl-input-error>
        </div>
      </div>
    </ng-container>
    <div class="row mb-2">
      <div class="col-6">
        <wl-text
          wlAutocomplete="firstname"
          [rendering]="rendering"
          [step]="trackStep.step"
          [stepName]="trackStep.stepName"
          fieldName="voorletter"
          [sendValue]="false"
          [valueConverter]="_initialsConverter"
          [formsControl]="formState?.controls?.initials"
          [placeholder]="rendering?.fields?.initialsPlaceholder?.value || ''"
          [label]="rendering?.fields?.initialsLabel?.value"
        ></wl-text>
        <wl-input-error
          *ngIf="
            formState?.controls?.initials?.isTouched &&
            formState?.controls?.initials?.errors?.required &&
            rendering?.fields?.initialsRequiredError?.value
          "
          [automationId]="'initials-required-error'"
          [rendering]="rendering"
          [messageField]="rendering?.fields?.initialsRequiredError"
          [step]="trackStep.step"
          [stepName]="trackStep.stepName"
        ></wl-input-error>
      </div>
      <div class="col-6">
        <wl-text
          wlAutocomplete="prefix"
          [rendering]="rendering"
          [step]="trackStep.step"
          [stepName]="trackStep.stepName"
          fieldName="tussenvoegsel"
          [sendValue]="false"
          [formsControl]="formState?.controls?.prefix"
          [placeholder]="rendering?.fields?.prefixPlaceholder?.value || ''"
          [label]="rendering?.fields?.prefixLabel?.value"
        ></wl-text>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-12">
        <wl-text
          wlAutocomplete="lastname"
          [rendering]="rendering"
          [step]="trackStep.step"
          [stepName]="trackStep.stepName"
          fieldName="achternaam"
          [sendValue]="false"
          [formsControl]="formState?.controls?.lastName"
          [placeholder]="rendering?.fields?.lastNamePlaceholder?.value || ''"
          [label]="rendering?.fields?.lastNameLabel?.value"
        ></wl-text>
        <wl-input-error
          *ngIf="
            formState?.controls?.lastName.isTouched &&
            formState?.controls?.lastName.errors?.required &&
            rendering?.fields?.lastNameRequiredError?.value
          "
          [automationId]="'last-name-required-error'"
          [rendering]="rendering"
          [messageField]="rendering?.fields?.lastNameRequiredError"
          [step]="trackStep.step"
          [stepName]="trackStep.stepName"
        ></wl-input-error>
        <wl-input-error
          *ngIf="
            formState?.controls?.lastName?.isTouched &&
            formState?.controls?.lastName?.errors?.pattern &&
            rendering?.fields?.lastNameInvalidError?.value
          "
          [automationId]="'last-name-invalid-error'"
          [rendering]="rendering"
          [messageField]="rendering?.fields?.lastNameInvalidError"
          [step]="trackStep.step"
          [stepName]="trackStep.stepName"
        ></wl-input-error>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-12">
        <wl-phone
          wlAutocomplete="phone"
          [rendering]="rendering"
          [step]="trackStep.step"
          [stepName]="trackStep.stepName"
          fieldName="telefoonnummer"
          [sendValue]="false"
          [formsControl]="formState?.controls?.phoneNumber"
          [tooltip]="rendering?.fields?.phoneNumberTooltip?.value || ''"
          [placeholder]="rendering?.fields?.phoneNumberPlaceholder?.value || ''"
          [label]="rendering?.fields?.phoneNumberLabel?.value"
        ></wl-phone>
        <wl-input-error
          *ngIf="
            formState?.controls?.phoneNumber.isTouched &&
            formState?.controls?.phoneNumber.errors?.required &&
            rendering?.fields?.phoneNumberRequiredError?.value
          "
          [automationId]="'phone-number-required-error'"
          [rendering]="rendering"
          [messageField]="rendering?.fields?.phoneNumberRequiredError"
          [step]="trackStep.step"
          [stepName]="trackStep.stepName"
        ></wl-input-error>
        <wl-input-error
          *ngIf="
            formState?.controls?.phoneNumber?.isTouched &&
            formState?.controls?.phoneNumber?.errors?.isPhoneNumber &&
            rendering?.fields?.phoneNumberInvalidError?.value
          "
          [automationId]="'phone-number-invalid-error'"
          [rendering]="rendering"
          [messageField]="rendering?.fields?.phoneNumberInvalidError"
          [step]="trackStep.step"
          [stepName]="trackStep.stepName"
        ></wl-input-error>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-12">
        <wl-email
          wlAutocomplete="email"
          [rendering]="rendering"
          [step]="trackStep.step"
          [stepName]="trackStep.stepName"
          [sendValue]="false"
          fieldName="emailadres"
          [formsControl]="formState?.controls?.emailAddress"
          [placeholder]="
            rendering?.fields?.emailAddressPlaceholder?.value || ''
          "
          [tooltip]="rendering?.fields?.emailAddressTooltip?.value || ''"
          [label]="rendering?.fields?.emailAddressLabel?.value"
        ></wl-email>
        <wl-input-error
          *ngIf="
            formState?.controls?.emailAddress?.isTouched &&
            formState?.controls?.emailAddress?.errors?.required &&
            rendering?.fields?.emailAddressRequiredError?.value
          "
          [automationId]="'email-address-required-error'"
          [rendering]="rendering"
          [messageField]="rendering?.fields?.emailAddressRequiredError"
          [step]="trackStep.step"
          [stepName]="trackStep.stepName"
        ></wl-input-error>
        <wl-input-error
          *ngIf="
            formState?.controls?.emailAddress?.isTouched &&
            formState?.controls?.emailAddress?.errors?.isEmailAddress &&
            rendering?.fields?.emailAddressInvalidError?.value
          "
          [automationId]="'email-address-is-email-address-error'"
          [rendering]="rendering"
          [messageField]="rendering?.fields?.emailAddressInvalidError"
          [step]="trackStep.step"
          [stepName]="trackStep.stepName"
        ></wl-input-error>
      </div>
    </div>

    <div
      class="homebattery-interest"
      *ngIf="rendering.fields?.HomeBatteryInterestLabel?.value"
    >
      <wl-checkbox
        [formsControl]="formState?.controls?.homeBatteryInterest"
        [step]="trackStep.step"
        [stepName]="trackStep.stepName"
        [fieldName]="rendering?.fields?.HomeBatteryInterestLabel?.value"
        [sendValue]="false"
      >
        <strong>
          <div *scText="rendering.fields.HomeBatteryInterestLabel"></div>
        </strong>
        <div
          class="mt-1"
          *scText="rendering.fields.HomeBatteryInterestDescription"
        ></div>
      </wl-checkbox>
    </div>
  </ng-template>
  <ng-template #summary>
    <div wlCSMask>
      <p class="mb-0">
        {{
          selectedSalutationOption(
            salutationOptions,
            formState?.value?.salutation
          )
        }}
        {{ formState?.value?.initials }}
        {{ formState?.value?.prefix }}
        {{ formState?.value?.lastName }}
      </p>
      <p class="mb-0">{{ formState?.value?.phoneNumber }}</p>
      <p class="mb-0">{{ formState?.value?.emailAddress }}</p>
    </div>
  </ng-template>
</wl-simple-progressive-form-card>
