import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { bacFlowEffects, bacFlowProviders } from './bac';
import { becomeACustomerSelectorKey } from './become-a-customer-feature.selector';
import { becomeACustomerReducers } from './become-a-customer-state.reducers';
import {
  becomeACustomerCalculateSelectorKey,
  calculateEffects,
  calculateProviders,
  calculateReducers,
} from './calculate';
import { channelRecognitionProviders } from './channel-recognition';
import { chatbotEffects } from './chatbot';
import { funnelEffects, funnelProviders } from './funnel';
import { getOfferEffects, getOfferProviders } from './get-offer';
import {
  incompatiblePromotionModalEffects,
  modalEffects,
  modalProviders,
} from './modals';
import {
  becomeACustomerOfferSelectorKey,
  offerEffects,
  offerReducer,
} from './offer';
import {
  becomeACustomerOrderSelectorKey,
  orderEffects,
  orderProviders,
  OrderReducers,
} from './order';
import { UsageQuestionnaireEffects } from './usage-questionnaire';

const effects = [
  ...chatbotEffects,
  ...modalEffects,
  ...calculateEffects,
  ...UsageQuestionnaireEffects,
  ...offerEffects,
  ...orderEffects,
  ...bacFlowEffects,
  ...funnelEffects,
  ...getOfferEffects,
];

const providers = [
  ...bacFlowProviders,
  ...channelRecognitionProviders,
  ...calculateProviders,
  ...funnelProviders,
  ...orderProviders,
  ...getOfferProviders,
  ...modalProviders,
];

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(becomeACustomerSelectorKey, becomeACustomerReducers),
    StoreModule.forFeature(becomeACustomerOfferSelectorKey, offerReducer),
    StoreModule.forFeature(becomeACustomerOrderSelectorKey, OrderReducers),
    StoreModule.forFeature(
      becomeACustomerCalculateSelectorKey,
      calculateReducers
    ),
    EffectsModule.forFeature(effects),
    EffectsModule.forFeature(incompatiblePromotionModalEffects),
  ],
  providers,
})
export class BecomeACustomerStateModule {}
