import { SegmentType } from '@essent/common';

export function envSegmentToSegmentType(envSegment: string) {
  switch (envSegment) {
    case 'consument':
      return SegmentType.HH;
    case 'zakelijk':
      return SegmentType.SME;
    // SOHO KAN NOG NIET GEMAPT WORDEN OMDAT ER GEEN VERSCHIL TUSSEN ZAKELIJK EN SOHO IS,
    // VOOR NU UITGECOMMENT
    // case 'bestaat nog niet':
    //   return CustomerSegment.SMALL_OFFICE_HOME_OFFICE;
    default:
      return SegmentType.HH;
  }
}
