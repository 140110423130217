import {
  accountIdHeaderName,
  createServiceApi,
  serviceTypes,
} from '@essent/common';
import type { ChargeCardsResponse } from '@integration/emobility-api-models';

const apiVersion = '1';

export interface GetChargeCards {
  accountId: string;
}

export const getChargeCardsAPI = createServiceApi(
  '[Emobility] Get charge cards',
  'get',
  serviceTypes<GetChargeCards, ChargeCardsResponse>(),
  {
    getUrl: () => `/emobility/charge-cards/v${apiVersion}`,
    version: apiVersion,
    getHeaders: ({ accountId }) => ({
      Accept: 'application/json',
      [accountIdHeaderName]: accountId,
    }),
  }
);

export const {
  getChargeCards,
  getChargeCardsActions,
  getChargeCardsClear,
  getChargeCardsError,
  getChargeCardsSuccess,
} = getChargeCardsAPI;
