import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT_CONFIG } from '@core/config-angular';
import { EnvironmentConfig } from '@core/config-models';
import { TrackToolService } from '@innogy/core/analytics';
import {
  postStoreOrder,
  postStoreOrderError,
  postStoreOrderSuccess,
} from '@innogy/eplus/temporary-core-modules';
import { getQueryParameterHistory } from '@core/jss-routing';
import { resetProgressiveForm } from '@innogy/shared/progressive-form';
import {
  GenericProgressiveFormService,
  resetGenericProgressiveFormAction,
  submitGenericProgressiveFormAction,
} from '@innogy/shared/progressive-form/sitecore';
import type { ProgressiveFormFormState } from '@innogy/shared/progressive-form/sitecore/models';
import { genericFormToGenericFormSubmit } from '@innogy/sitecore-forms/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { routerNavigatedAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import capitalize from 'lodash/capitalize';
import type { FormGroupControls } from 'ngrx-forms';
import { delay, filter, map, mergeMap, tap, throttle } from 'rxjs/operators';

import {
  clearStoreFunnelInitializedAction,
  handleStoreFunnelGenericErrorAction,
  onStoreFunnelNavigationAction,
  resetStoreProductSelectionAction,
  selectSelectedStoreProducts,
  selectStoreFunnelSettings,
} from '../store-funnel';
import {
  submitStoreOrderAction,
  trackStoreOrderFormCompleteAction,
} from './store-order-form.actions';
import { parsedStoreOrderFormToolTrackingConfig } from './store-order-form.analytics-config';

@Injectable()
export class StoreOrderFormEffects {
  funnelSettings$ = this.store$.select(selectStoreFunnelSettings);
  selectedProduct$ = this.store$.select(selectSelectedStoreProducts);
  queryParamHistory$ = this.store$.select(getQueryParameterHistory);

  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<any>,
    private readonly genericProgressiveFormService: GenericProgressiveFormService,
    private readonly trackToolService: TrackToolService,
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(ENVIRONMENT_CONFIG) private readonly config: EnvironmentConfig
  ) {}

  public readonly onSubmitStoreOrderForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(submitGenericProgressiveFormAction),
      // throttle until the related API has returned an action, concluding new events can be
      // processed again.
      throttle(() =>
        this.actions$.pipe(ofType(postStoreOrderSuccess, postStoreOrderError))
      ),
      concatLatestFrom(() => this.funnelSettings$),
      filter(
        ([action, { orderFormId }]) =>
          orderFormId !== undefined && action.formId === orderFormId
      ),
      concatLatestFrom(([_, { orderFormId }]) => [
        this.selectedProduct$,
        this.selectorForFormId$(orderFormId as string),
      ]),
      mergeMap(
        ([
          [{ toolName }, { apiIntegrationPath, trackingId }],
          selectedProducts,
          formState,
        ]) => {
          const formControls: FormGroupControls<ProgressiveFormFormState> =
            this.genericProgressiveFormService.flattenFormGroupControls(
              formState
            );

          return [
            trackStoreOrderFormCompleteAction({
              formControls,
              toolName,
              stepCount: Object.entries(formState).length,
            }),
            submitStoreOrderAction({
              formControls,
              apiIntegrationPath,
              selectedProducts,
              trackingId: trackingId as string,
            }),
          ];
        }
      )
    )
  );

  public readonly trackStoreOrderFormComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(trackStoreOrderFormCompleteAction),
        tap(({ formControls, stepCount, toolName }) => {
          const config = parsedStoreOrderFormToolTrackingConfig({
            toolName,
            formControls,
            stepCount,
          });
          this.trackToolService.completeTool(config);
        })
      ),
    { dispatch: false }
  );

  public readonly submitStoreOrderForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(submitStoreOrderAction),
      concatLatestFrom(() => [this.queryParamHistory$]),
      mergeMap(
        ([
          { apiIntegrationPath, formControls, selectedProducts, trackingId },
          queryParamHistory,
        ]) => {
          const settings = {
            brand: this.config.brand,
            environment: capitalize(this.config.segment),
            referrer: this.document.referrer,
            formSettingsPath: apiIntegrationPath,
            e2eTrackingId: trackingId,
            gclid: queryParamHistory['gclid'] ?? '',
          };

          const additionalFormValues = {
            product: selectedProducts.map((p) => p.suiteCrmId).join(','),
            campagneid: selectedProducts.map((p) => p.campaignId).join(','),
            gewensttypecontract: 'Koop',
            leadtracking: 'DirectOther',
          };

          const payload = genericFormToGenericFormSubmit(
            settings,
            formControls,
            additionalFormValues
          );

          return [
            postStoreOrder({
              payload,
            }),
          ];
        }
      )
    )
  );

  public readonly onSubmitStoreOrderFormSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(postStoreOrderSuccess),
      concatLatestFrom(() => this.funnelSettings$),
      filter(([, funnelSettings]) => !!funnelSettings.successPage),
      mergeMap(([, funnelSettings]) => [
        onStoreFunnelNavigationAction({
          page: funnelSettings.successPage,
        }),
      ])
    )
  );

  public readonly resetStoreOrderFormAndProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigatedAction),
      concatLatestFrom(() => this.funnelSettings$),
      filter(
        ([{ payload }, funnelSettings]) =>
          payload.routerState.url === funnelSettings.successPage?.href
      ),
      // Even though we wait for navigated events, apparently
      // an extra delay is needed in order to prevent firing tool-step events
      // after form rest on the former page.
      // .5s is chosen arbitrarily, yet seems to work even under 3g conditions.
      delay(500),
      mergeMap(([, funnelSettings]) => [
        resetGenericProgressiveFormAction({
          payload: funnelSettings.orderFormId as string,
        }),
        resetProgressiveForm({ formId: funnelSettings.orderFormId as string }),
        resetStoreProductSelectionAction(),
        clearStoreFunnelInitializedAction(),
      ])
    )
  );

  public readonly onSubmitStoreOrderFormError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(postStoreOrderError),
      tap(({ payload }) => {
        console.error(
          'Error while submitting generic store order',
          payload.error
        );
      }),
      map(handleStoreFunnelGenericErrorAction)
    )
  );

  private readonly selectorForFormId$ = (formId: string) => {
    const selector = this.genericProgressiveFormService.selectorForForm(formId);
    return this.store$.select(selector);
  };
}
