import type { OnInit } from '@angular/core';
import { Component, HostBinding, Input } from '@angular/core';
import type { ResponsiveSettings } from '@innogy/common-ui/shared';
import { BackgroundSettings } from '@innogy/common-ui/shared';

import { ContentTheme } from '../../models';

@Component({
  selector: 'wl-partial-jumbotron',
  templateUrl: './jumbotron.component.html',
  styleUrls: ['./jumbotron.component.ed.scss'],
})
export class PartialJumbotronComponent implements OnInit {
  @Input() theme: ContentTheme = 'default';
  @Input() backgroundSettings?: BackgroundSettings;

  @Input() heading?: string | undefined;
  @Input() subheading?: string | undefined;
  @Input() showButtonsInHeading? = false;
  @Input() inlineHeadings?: boolean | undefined = false;
  @Input() anchorPoint?: string | undefined;
  @Input() mobileTopImage?: boolean | undefined = false;
  @Input() layoutButtonsInline?: boolean = false;

  @HostBinding('attr.id') get id() {
    return this.anchorPoint;
  }

  mobileImage: ResponsiveSettings | undefined;

  ngOnInit() {
    this.mobileImage = this.getMobileImage();
  }

  getMobileImage(): ResponsiveSettings | undefined {
    if (this.backgroundSettings?.useDesktopBackgroundAsFallback) {
      if (this.backgroundSettings.mobile?.src) {
        return this.backgroundSettings.mobile;
      }
      return this.backgroundSettings.desktop;
    }
    return this.backgroundSettings?.mobile;
  }
}
