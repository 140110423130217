import type { ExtendReducerProjectorArgs } from '@innogy/utils-deprecated';
import {
  extendFormReducer,
  isNumeric,
  isPostalCode,
  validateSequential,
} from '@innogy/utils-deprecated';
import { required } from 'ngrx-forms/validation';

import type { PostalAddressFormValues } from '../model/postal-address-form.model';

export function extendPostalAddressFormReducer<
  T extends PostalAddressFormValues,
>({
  formId,
  extendingValidators,
  baseInitialState,
}: ExtendReducerProjectorArgs<T>) {
  return extendFormReducer<PostalAddressFormValues, T>({
    formId,
    baseInitialState,
    validators: {
      postalCode: validateSequential(required, isPostalCode),
      communicationNumber: validateSequential(required, isNumeric),
      ...extendingValidators,
    },
  });
}
