import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import type { JssNavItem } from '@core/jss-models';

@Component({
  selector: 'wl-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.essent.scss', './navbar.component.ed.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarComponent {
  @Input()
  navItems!: JssNavItem[];

  @Input()
  language!: string;

  @Input()
  brandLogoLinkUrl!: string;
}
