import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import type { OrderPersonalFormValues } from '@innogy/become-a-customer/state';
import type { RadioItem } from '@innogy/common-ui/forms';
import type { InitialsConverter } from '@innogy/utils-deprecated';
import {
  initialsConverter,
  normalizeDateString,
} from '@innogy/utils-deprecated';

import { OrderFulfillmentStepComponent } from '../order-fulfillment-step.component';
import { ORDER_PERSONAL_STEP_INDEX } from '../constants';

@Component({
  selector: 'wl-order-fulfillment-personal[progressiveFormState]',
  templateUrl: './order-fulfillment-personal.component.html',
  styleUrls: ['./order-fulfillment-personal.component.ed.scss'],
})
export class OrderFulfillmentPersonalComponent
  extends OrderFulfillmentStepComponent<OrderPersonalFormValues>
  implements OnInit
{
  @Input() labelsShownAboveFields = false;
  @Input()
  genderOptions?: RadioItem[];

  _initialsConverter?: InitialsConverter = initialsConverter();

  ngOnInit(): void {
    this.progressiveFormCardContent = {
      title: this.fields.PersonalCardTitle?.value,
      subtitle: this.fields.PersonalCardSubTitle?.value,
      submitButtonText: this.fields.PersonalCardSubmitButton?.value,
      stepNumber: ORDER_PERSONAL_STEP_INDEX,
    };
  }

  get givenDateOfBirth() {
    return normalizeDateString(
      this.formState?.controls?.dateOfBirth?.value ?? ''
    );
  }

  get emailFieldValuesMatch() {
    return (
      this.formState?.value?.email === this.formState?.value?.emailValidation
    );
  }
}
