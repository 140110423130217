import { Injectable } from '@angular/core';
import type { BreakpointState } from '@angular/cdk/layout';
import { BreakpointObserver } from '@angular/cdk/layout';
import { DeviceDetectorService } from 'ngx-device-detector';
import { merge, of } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

import { BREAKPOINTS } from './breakpoints/breakpoints';

export interface DeviceInfo {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;

  isSmall: boolean;
  isExtraSmall: boolean;
  isMedium: boolean;
  isLarge: boolean;
  isExtraLarge: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class MediaObserverService {
  constructor(
    private readonly breakpointObserver: BreakpointObserver,
    private readonly detectorService: DeviceDetectorService
  ) {}

  private readonly initialDeviceDetector$ = of(this.initialDeviceInfo());

  private readonly mediaObserver$ = this.breakpointObserver
    .observe([
      BREAKPOINTS.XSmall,
      BREAKPOINTS.Small,
      BREAKPOINTS.Medium,
      BREAKPOINTS.Large,
      BREAKPOINTS.XLarge,
    ])
    .pipe(
      debounceTime(10),
      map(({ breakpoints }: BreakpointState): DeviceInfo => {
        return {
          isMobile:
            breakpoints[BREAKPOINTS.XSmall] || breakpoints[BREAKPOINTS.Small],
          isTablet: breakpoints[BREAKPOINTS.Medium],
          isDesktop:
            breakpoints[BREAKPOINTS.Large] || breakpoints[BREAKPOINTS.XLarge],
          isExtraSmall: breakpoints[BREAKPOINTS.XSmall],
          isSmall: breakpoints[BREAKPOINTS.Small],
          isMedium: breakpoints[BREAKPOINTS.Medium],
          isLarge: breakpoints[BREAKPOINTS.Large],
          isExtraLarge: breakpoints[BREAKPOINTS.XLarge],
        };
      })
    );

  public deviceInfo$ = merge(this.initialDeviceDetector$, this.mediaObserver$);

  private initialDeviceInfo(): DeviceInfo {
    if (this.detectorService.getDeviceInfo().deviceType === 'Unknown') {
      // Fallback to mobile in case the device type is not known, so the SSR has something to render.
      return {
        isMobile: true,
        isTablet: false,
        isDesktop: false,
        isExtraSmall: false,
        isSmall: true,
        isMedium: false,
        isLarge: false,
        isExtraLarge: false,
      };
    }
    return {
      isMobile: this.detectorService.isMobile(),
      isTablet: this.detectorService.isTablet(),
      isDesktop: this.detectorService.isDesktop(),
      isExtraSmall: false,
      isSmall: this.detectorService.isMobile(),
      isMedium: this.detectorService.isTablet(),
      isLarge: this.detectorService.isDesktop(),
      isExtraLarge: false,
    };
  }
}
