import type { AfterViewInit } from '@angular/core';
import { Component, Inject } from '@angular/core';
import { ENVIRONMENT_CONFIG } from '@core/config-angular';
import { EnvironmentConfig } from '@core/config-models';
import { DomService, ScriptInjectOn } from '@innogy/utils-dom';

import { MemberGetMemberService } from '../member-get-member.service';

/**
 * The wrapper for the member-get-member (vriendenvoordeel) widget
 */
@Component({
  selector: 'wl-member-get-member-widget',
  templateUrl: './member-get-member-widget.component.html',
})
export class MemberGetMemberWidgetComponent implements AfterViewInit {
  project!: string;
  selector = 'mgmco-widget';

  constructor(
    @Inject(ENVIRONMENT_CONFIG) readonly config: EnvironmentConfig,
    private readonly domService: DomService,
    private readonly mgmService: MemberGetMemberService
  ) {
    this.project = config.app;
  }

  ngAfterViewInit() {
    this.domService.injectScript({
      src: this.config.mgm.embeddedWidgetUrl,
      injectOnPlatform: ScriptInjectOn.CLIENT_ONLY,
      async: true,
      onLoad: () => this.initWidget(),
      onAlreadyLoaded: () => this.initWidget(),
    });
  }

  private initWidget() {
    const { project, selector } = this;
    this.mgmService.initWidget({
      displayDefaultIntro: false,
      // NOTE: On 30/11/2022 we received information from an MGMco developer that the
      //       MGM acceptance environment has shut down, only the production environment is running.
      environment: 'production',
      project,
      selector: `#${selector}`,
    });
  }
}
