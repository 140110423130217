import type { OnInit } from '@angular/core';
import {
  Directive,
  HostListener,
  Inject,
  Input,
  Optional,
} from '@angular/core';
import { ActionsSubject } from '@ngrx/store';
import type { KeyValue } from 'ngrx-forms';

import type { Actions } from '../actions';
import { ActivateFormStepAction, BackupFormStateAction } from '../actions';
import { ProgressiveFormGroupState } from '../state';

/**
 * Takes care of dispatching the right actions when a form step should go into editing mode (active & submitted).
 * Ensures a backup of the form state is made whem going into editing mode.
 */
@Directive({
  selector:
    // eslint-disable-next-line @angular-eslint/directive-selector
    'a[role=button][editProgressiveFormStep],button[editProgressiveFormStep]',
})
export class EditProgressiveFormStepDirective<TStateValue extends KeyValue>
  implements OnInit
{
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('editProgressiveFormStep')
  state?: ProgressiveFormGroupState<TStateValue>;

  constructor(
    @Optional()
    @Inject(ActionsSubject)
    private readonly actionsSubject: ActionsSubject | null
  ) {
    this.actionsSubject = actionsSubject;
  }
  protected dispatchAction(action: Actions) {
    if (this.actionsSubject !== null) {
      this.actionsSubject.next(action);
    } else {
      throw new Error(
        'ActionsSubject must be present in order to dispatch actions!'
      );
    }
  }

  ngOnInit() {
    if (!this.state) {
      throw new Error('The progressive form step state must not be undefined!');
    }
  }

  @HostListener('click', ['$event'])
  onClick(event: Event) {
    event.preventDefault();
    if (this.state?.formState.isValid) {
      this.dispatchAction(new BackupFormStateAction(this.state.id));
      this.dispatchAction(new ActivateFormStepAction(this.state.id));
    }
  }
}
