<div *ngIf="offer" class="d-flex flex-column">
  <essent-commodity-offer-product-breakdown-row
    *ngIf="
      selectCommodity(offer, energyType.ELECTRICITY) as electricityOverview
    "
    [description]="content?.electricityLabel"
    [value]="electricityOverview.totalPrice.expectedYearlyAmount"
  ></essent-commodity-offer-product-breakdown-row>
  <essent-commodity-offer-product-breakdown-row
    *ngIf="selectCommodity(offer, energyType.GAS) as gasOverview"
    [description]="content?.gasLabel"
    [value]="gasOverview.totalPrice.expectedYearlyAmount"
  ></essent-commodity-offer-product-breakdown-row>
  <wl-essent-sme-commodity-offer-price-breakdown-row
    *ngIf="offer.expectedYearlyAmount"
    [description]="yearlyCostsLabel(offer)"
    [discountDescription]="content?.cashbackLabel"
    textStyle="bold"
    [value]="offer.expectedYearlyAmount"
    [discountValue]="offer.discountPrice"
  ></wl-essent-sme-commodity-offer-price-breakdown-row>
  <p class="mt-2" *ngIf="content?.offerSubmitDescriptionLabel">
    <span class="description">{{ content?.offerSubmitDescriptionLabel }}</span>
    <wl-tooltip
      *ngIf="!!content?.offerSubmitDescriptionTooltip"
      [tooltip]="{ description: content?.offerSubmitDescriptionTooltip }"
    ></wl-tooltip>
  </p>
</div>
