import { createFeatureSelector, createSelector } from '@ngrx/store';

import type { OfferState } from './offer.reducer';

export const becomeACustomerOfferSelectorKey = 'offer';

export const getOfferState = createFeatureSelector<OfferState>(
  becomeACustomerOfferSelectorKey
);

export const getSitecoreAlternativeOfferSet = createSelector(
  getOfferState,
  (state) => state.sitecoreAlternativeOfferSet
);

export const readyToLoadOffers = createSelector(
  getOfferState,
  (state) => state.readyToLoadOffers
);
