import { createSelector } from '@ngrx/store';
import { Status } from '@essent/common';

import { getPaymentDetailsState } from './index';
import type { UpdatePaymentDetailsVM } from '../payment-details.model';
import type { State } from './update-payment-method.reducer';

export const getUpdateState = createSelector(
  getPaymentDetailsState,
  (feature) => feature.updatePaymentMethod
);

export const getUpdateError = createSelector(
  getUpdateState,
  (state: State) => state.error
);

export const getUpdateStatus = createSelector(
  getUpdateState,
  (state: State) => state.status
);

export const getHasUpdateError = createSelector(
  getUpdateStatus,
  (status) => status === Status.ERROR
);

export const getUpdatePaymentDetailsWithStates = createSelector(
  getUpdateStatus,
  (status): UpdatePaymentDetailsVM => {
    const showError = status === Status.ERROR;
    const showLoading =
      (status === Status.IDLE || status === Status.PENDING) && !showError;
    const showSuccess = status === Status.SUCCESS;
    const showChangeForm = !showSuccess;

    return {
      showError,
      showLoading,
      showSuccess,
      showChangeForm,
    };
  }
);
