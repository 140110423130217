import { createSelector } from '@ngrx/store';

import { getOrderState } from '../order.selector';

export const getOrderConfirmationProgressiveFormState = createSelector(
  getOrderState,
  (state) => state.orderConfirmationForm.progressiveForm
);

export const getOrderConfirmationFormState = createSelector(
  getOrderConfirmationProgressiveFormState,
  (state) => state.formState
);
