import type { ActionReducerMap } from '@ngrx/store';
import type { Actions } from 'ngrx-forms';

import type { CalculateFormState } from './calculate.reducer';
import { calculateReducer } from './calculate.reducer';

export interface CalculateState {
  readonly calculateForm: CalculateFormState;
}

export const becomeACustomerCalculateSelectorKey = 'calculate';

export const calculateReducers: ActionReducerMap<
  CalculateState,
  Actions<unknown>
> = {
  calculateForm: calculateReducer,
};
