import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import {
  getFirstFocusControlName,
  markAsValidSubmissionAction,
} from '@innogy/utils-deprecated';
import { isNotNullish } from '@innogy/utils-rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { FocusAction } from 'ngrx-forms';
import { filter, switchMap } from 'rxjs/operators';

import {
  collapseFormStepAction,
  finishCollapseStepAnimationAction,
} from './progressive-form-step';
import {
  setActiveFormStep,
  updateCompletedFormSteps,
} from './progressive-form.actions';
import { getNextFormStepIndex } from './progressive-form.helpers';
import { generateSelectors } from './progressive-form.selectors';

/**
 * @deprecated - please use the `ProgressiveNGRXFormsModule` instead.
 * For information on how to migrate, see `forms/progressive ngrx forms` in the application docs.
 */
@Injectable()
export class ProgressiveFormStepConfigEffects {
  constructor(
    private readonly store$: Store,
    private readonly actions$: Actions,
    @Inject(DOCUMENT)
    private readonly document: Document
  ) {}

  public readonly handleFormStepSubmit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(markAsValidSubmissionAction),
      filter((action) => action.formId !== undefined),
      concatLatestFrom((action) => this.stateForm$(action.formId as string)),
      filter(
        ([_, progressiveState]) =>
          progressiveState.useFormStepConfig === true &&
          progressiveState.currentStepId !== undefined
      ),
      filter(([action, progressiveState]) =>
        progressiveState.formSteps.includes(action.controlId)
      ),
      switchMap(([action, progressiveState]) => {
        const nextFormStepIndex = getNextFormStepIndex(progressiveState);
        if (nextFormStepIndex) {
          const formId = action.formId as string;
          const stepId = progressiveState.currentStepId as string;

          return [
            updateCompletedFormSteps({
              formId: formId,
              stepIds: [stepId],
              state: true,
            }),
            collapseFormStepAction({
              formId,
              stepId,
            }),
          ];
        }
        return [];
      })
    )
  );

  public readonly setActiveFormStepOnCollapsed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(finishCollapseStepAnimationAction),
      filter((action) => action.collapsed === true),
      concatLatestFrom(({ formId }) => this.stateForm$(formId)),
      filter(
        ([_, progressiveState]) => progressiveState.useFormStepConfig === true
      ),
      filter(
        ([action, progressiveState]) =>
          action.stepId === progressiveState.currentStepId
      ),
      switchMap(([action, progressiveState]) => {
        const formId = action.formId || '';
        const stepId = progressiveState.naturalNextStepId || '';

        return [
          setActiveFormStep({
            formId,
            stepId: stepId,
            isEditing: progressiveState.completedFormSteps.includes(stepId),
          }),
        ];
      })
    )
  );

  public readonly setFocusOnCollapse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(finishCollapseStepAnimationAction),
      filter((action) => !action.collapsed),
      concatLatestFrom((action) => this.stateForm$(action.formId)),
      filter(
        ([action, progressiveState]) =>
          progressiveState.useFormStepConfig === true &&
          action.stepId === progressiveState.currentStepId
      ),
      switchMap(([_, progressiveState]) => [
        new FocusAction(
          getFirstFocusControlName(
            this.document,
            progressiveState.currentStepId
          ) ?? ''
        ),
      ])
    )
  );

  private readonly stateForm$ = (formId: string) =>
    this.store$
      .select(generateSelectors().getProgressiveStateForForm(formId))
      .pipe(filter(isNotNullish));
}
