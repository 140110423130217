import { Injectable } from '@angular/core';
import type { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import {
  NgbDateAdapter,
  NgbDateParserFormatter,
} from '@ng-bootstrap/ng-bootstrap';

import { toDateString, toNgbDate } from './datepicker.helper';

/* eslint-disable max-classes-per-file */

/**
 * A service for parsing and formatting dates for the NgbInputDatepicker (ngbDatepicker) directive
 * Converts between the internal `NgbDateStruct` model presentation and a `string` that is displayed in the
 * input element.
 */
@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {
  fromModel(value: string | null): NgbDateStruct | null {
    return value ? toNgbDate(value) : null;
  }

  toModel(date: NgbDateStruct | null): string | null {
    return date ? toDateString(date) : null;
  }
}

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  /**
   * This will parse the dd-MM-yyyy date to an NgbDateStruct so that the ngbDatepicker can understand manual input
   *
   * @param [value] date-string (dd-MM-yyyy format)
   */
  parse(value: string): NgbDateStruct | null {
    return value ? toNgbDate(value) : null;
  }

  /**
   * This will transform a selected NgbDateStruct into a string representation to be set as input value.
   *
   * @param [date] The selected date
   */
  format(date: NgbDateStruct | null): string {
    return date ? toDateString(date) : '';
  }
}
