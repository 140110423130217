import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@innogy/utils-dom';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { filter, switchMap, tap } from 'rxjs/operators';
import { firstValueFrom } from 'rxjs';

import type { ChattingState } from './index';
import { postChatToken } from '../chat-token';
import { logoutChatAndRedirect, startChat } from './chatting.actions';
import type {
  IAdvizeAuthenticationOption,
  IAdvizeInterface,
} from './chatting.interface';
import { IAdvizeAuthenticationType } from './chatting.interface';
import type { chattingSelectorKey } from './chatting.selectors';
import { getChatToken, getIsChatTokenLoadingDone } from './chatting.selectors';

@Injectable({
  providedIn: 'root',
})
export class ChattingEffects {
  constructor(
    @Inject(WINDOW)
    private readonly windowRef: Window & {
      iAdvizeInterface: IAdvizeInterface;
    },
    private readonly actions$: Actions,
    private readonly store$: Store<{ [chattingSelectorKey]: ChattingState }>
  ) {}

  private readonly chatToken$ = this.store$.pipe(select(getChatToken));

  startChat$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(startChat),
        tap(({ payload }) => this.setAuthOptionInIAdvize(payload.login))
      ),
    { dispatch: false }
  );

  logoutChatAndRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(logoutChatAndRedirect),
        tap(({ payload }) => payload.chatEnabled && this.logoutFromIAdvize()),
        tap(({ payload }) =>
          this.windowRef.location.assign(payload.redirect_url)
        )
      ),
    { dispatch: false }
  );

  private async setAuthOptionInIAdvize(login: boolean): Promise<void> {
    const iAdvizeInterface = await this.getIAdvizeInterface();
    iAdvizeInterface.push(async (iAdvize) => {
      iAdvize.activate(async () => {
        if (login) {
          const token = await this.getNewChatToken();
          if (token) {
            return this.getSecuredAuthOption(token);
          }
        }
        return this.getAnonymousAuthOption();
      });
    });
  }

  private async logoutFromIAdvize(): Promise<void> {
    const iAdvizeInterface = await this.getIAdvizeInterface();
    iAdvizeInterface.push((iAdvize) => {
      iAdvize.logout();
    });
  }

  private async getIAdvizeInterface(): Promise<IAdvizeInterface> {
    this.windowRef.iAdvizeInterface = this.windowRef?.iAdvizeInterface || [];
    return this.windowRef.iAdvizeInterface;
  }

  private getAnonymousAuthOption(): IAdvizeAuthenticationOption {
    return {
      authenticationOption: {
        type: IAdvizeAuthenticationType.ANONYMOUS,
      },
    };
  }

  private getNewChatToken() {
    this.store$.dispatch(postChatToken());
    const chatToken$ = this.store$.pipe(
      select(getIsChatTokenLoadingDone),
      filter((done) => done),
      switchMap(() => this.chatToken$)
    );
    return firstValueFrom(chatToken$);
  }

  private getSecuredAuthOption(token: string): IAdvizeAuthenticationOption {
    return {
      authenticationOption: {
        type: IAdvizeAuthenticationType.SECURED_AUTHENTICATION,
        token,
      },
    };
  }
}
