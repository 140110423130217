import { createNamedApi } from '@essent/common';

import { PostStoreOrderService } from './post-store-order.service';

export const postStoreOrderApi = createNamedApi(
  '[PostStoreOrderState] Post Store Order',
  PostStoreOrderService
);

export const {
  postStoreOrderActions,
  postStoreOrderSuccess,
  postStoreOrderClear,
  postStoreOrderError,
  postStoreOrder,
} = postStoreOrderApi;
