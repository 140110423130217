<wl-rich-text-wrapper
  [field]="rendering?.fields?.content"
  [rendering]="rendering"
>
  <div
    class="rte"
    wlTrackImpression="contentblock-impression"
    [trackImpressionInfo]="rendering"
    [trackImpressionContext]="context"
  ></div>
</wl-rich-text-wrapper>
