import { DOCUMENT } from '@angular/common';
import { Directive, Inject, Renderer2 } from '@angular/core';

import { AddClassToBodyDirective } from './add-class-to-body.directive';

@Directive({
  selector: '[wlDisableBodyScrolling]',
})
export class DisableBodyScrollingClassDirective extends AddClassToBodyDirective {
  public override bodyClassName = 'body-disable-scrolling-sm';
  constructor(
    protected override readonly renderer: Renderer2,
    @Inject(DOCUMENT) protected override readonly document: Document
  ) {
    super(renderer, document);
  }
}
