import { createSelector } from '@ngrx/store';

import { getBecomeACustomerState } from '../../become-a-customer-feature.selector';

export const additionalInformationValuesState = createSelector(
  getBecomeACustomerState,
  (state) => state.additionalInformation.additionalInformationValues
);

export const getResidentialInformationState = createSelector(
  additionalInformationValuesState,
  (state) => {
    return state.residential;
  }
);
