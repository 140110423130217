import { createNamedApi } from '@essent/common';

import { GetSolarPaybackService } from './get-solar-payback.service';

export const getSolarPaybackApi = createNamedApi(
  '[GetSolarPaybackState] Get Solar Payback',
  GetSolarPaybackService
);

export const {
  getSolarPaybackActions,
  getSolarPayback,
  getSolarPaybackClear,
  getSolarPaybackError,
  getSolarPaybackSuccess,
} = getSolarPaybackApi;
