import { castToNewCustomerPriceGroup } from '@innogy/become-a-customer/shared';
import type {
  PriceGroup,
  GetTariffsResponseProduct,
} from '@integration/offerstore-api-models';

export const FIXED_DELIVERY_COSTS_ID = '5';
export const ELECTRICITY_PRODUCT_CODE = 'E_H_BD';
export const GAS_PRODUCT_CODE = 'G_H_B';

export const castPriceGroups = (priceGroups: PriceGroup[]) =>
  priceGroups.map((priceGroup) =>
    // @NOTE -> should we cast the other way around (in AOM) since offerStore is "the future"?
    castToNewCustomerPriceGroup(priceGroup, {
      toExpectedMonthlyAmount: 'monthly',
      toExpectedYearlyAmount: 'yearly',
    })
  );

export const getMonthlyPriceById = (
  prices: { id: string; monthly: number }[],
  id: string
) => prices.find((price) => price.id === id)?.monthly || NaN;

export const getCommodityByCode = (
  products: GetTariffsResponseProduct[],
  code: string
) => products.find((product) => product.name === code);
