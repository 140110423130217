import { Component } from '@angular/core';

import { BasicContentVariantInheritableComponent } from '../basic-content-variant.inheritable.component';

@Component({
  selector: 'wl-basic-content-variant-vertical-icon',
  templateUrl: './basic-content-variant-vertical-icon.component.html',
  styleUrls: [],
})
export class BasicContentVariantVerticalIconComponent extends BasicContentVariantInheritableComponent {}
