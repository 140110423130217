import type { ComponentNameAndModule } from '@sitecore-jss/sitecore-jss-angular';

export * from './lib/ab-testing-lazy.module';

const loadChildrenFunc = () =>
  import('./lib/ab-testing-lazy.module').then(
    (module) => module.ABTestingLazyModule
  );

export const ABTestingLazyImports: ComponentNameAndModule[] = [
  {
    path: 'ComponentTesting',
    loadChildren: loadChildrenFunc,
  },
];
