<div
  class="row top-tasks-container justify-content-center"
  wlTrackImpression="contentblock-impression"
  [trackImpressionInfo]="rendering"
  [trackImpressionContext]="context"
>
  <div
    class="col-12 col-md-6"
    *ngFor="let task of topTasks"
    [class.px-2]="!alignVertically"
    [class.py-1]="!alignVertically"
    [class.pb-2]="alignVertically"
    [class.col-lg-3]="!alignVertically && hasEvenAmountOfTopTasks"
    [class.col-lg-4]="!alignVertically && !hasEvenAmountOfTopTasks"
  >
    <wl-icon-card-button
      *ngIf="task"
      wlAutomationId="icon-card-button"
      wlTrackLink
      [trackLinkInfo]="{
        rendering: task,
        field: task.fields?.title?.value,
        linkurl: task.fields?.link?.value?.href,
        type: 'button',
        eventType: 'navigation-click',
      }"
      wlTrackImpression="navigation-impression"
      [iconName]="task.fields?.icon | scIcon"
      [title]="task.fields?.title?.value"
      [variant]="variant"
      [variantMobile]="variantMobile"
      [alignment]="textAlignment"
      [alignmentMobile]="textAlignmentMobile"
      [useLinkStyle]="useLinkStyle"
      [subTitle]="task.fields?.subTitle?.value"
      (clicked)="navigateToLink(task.fields?.link?.value?.href)"
      [background]="background"
      [hoverBackground]="hoverBackground"
    ></wl-icon-card-button>
  </div>
</div>
