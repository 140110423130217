import type { KeyValue } from 'ngrx-forms';
import { ResetAction } from 'ngrx-forms';

import type { NGRXProgressiveFormsActions } from '../actions';
import { ResetFormStepAction } from '../actions';
import type { ProgressiveFormGroupState } from '../state';
import { dispatchActionOnNGRXFormState } from './util';

export function resetReducer<TValue extends KeyValue>(
  state: ProgressiveFormGroupState<TValue>,
  action: NGRXProgressiveFormsActions
): ProgressiveFormGroupState<TValue> {
  /**
   * @todo: verify if reset should be shared with NGRX forms or not.
   */
  if (action.type !== ResetFormStepAction.TYPE) {
    return state;
  }

  if (action.stepId !== state.id) {
    return state;
  }

  if (state.isUnvisited && state.isUnsubmitted) {
    return state;
  }

  return {
    ...state,
    formState: dispatchActionOnNGRXFormState(
      state,
      (controlId) => new ResetAction(controlId)
    ),
    backupFormState: dispatchActionOnNGRXFormState(
      state,
      (controlId) => new ResetAction(controlId)
    ),
    isSubmitted: false,
    isUnsubmitted: true,
    isVisited: false,
    isUnvisited: true,
    isRestorationPoint: false,
  };
}
