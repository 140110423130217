import { createAsyncStateReducer } from '@essent/common';
import type {
  GetMarketingProfilePayload,
  MarketingProfile,
} from '@essent/marketing';
import { getMarketingProfileActions } from '@essent/marketing';

export const getMarketingProfileReducer = createAsyncStateReducer<
  GetMarketingProfilePayload,
  MarketingProfile
>(getMarketingProfileActions);
