import type { ActionReducerMap } from '@ngrx/store';
import { createFeatureSelector } from '@ngrx/store';

import {
  sustainabilityCreateLeadResultId,
  sustainabilityCreateLeadResultReducer,
  sustainabilityFormConfigId,
  sustainabilityFormConfigReducer,
  sustainabilityPostcodeValidationId,
  sustainabilityPostcodeValidationReducer,
  type SustainabilityCreateLeadResultState,
  type SustainabilityFormConfigState,
  type SustainabilityPostcodeValidationState,
} from './sustainability.reducers';
import {
  sustainabilityTimeslotsId,
  sustainabilityTimeslotsReducer,
  type SustainabilityTimeslotsState,
} from './get-timeslots.reducers';

export const sustainabilitySelectorKey = 'eplus-sustainability';

export interface SustainabilityReducerState {
  readonly [sustainabilityPostcodeValidationId]: SustainabilityPostcodeValidationState;
  readonly [sustainabilityCreateLeadResultId]: SustainabilityCreateLeadResultState;
  readonly [sustainabilityFormConfigId]: SustainabilityFormConfigState;
  readonly [sustainabilityTimeslotsId]: SustainabilityTimeslotsState;
}

export const sustainabilityReducers: ActionReducerMap<SustainabilityReducerState> =
  {
    [sustainabilityPostcodeValidationId]:
      sustainabilityPostcodeValidationReducer,
    [sustainabilityCreateLeadResultId]: sustainabilityCreateLeadResultReducer,
    [sustainabilityFormConfigId]: sustainabilityFormConfigReducer,
    [sustainabilityTimeslotsId]: sustainabilityTimeslotsReducer,
  };

export const eplusSustainabilityFeature =
  createFeatureSelector<SustainabilityReducerState>(sustainabilitySelectorKey);
