import type { EnvironmentConfig } from '@core/config-models';

export const getLoginUrl = (config: EnvironmentConfig): string | undefined => {
  if (config.services != null && config.services.identityProvider != null) {
    return config.services.identityProvider.endpoint;
  }

  return undefined;
};

export const getLoginRedirectUrl = (location: Location): string => {
  return encodeURIComponent(`${location.pathname}${location.search}`);
};
