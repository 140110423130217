<wl-modal
  (closeModal)="close()"
  [modalTitle]="title"
  wlAutomationId="generic-lightbox"
  wlTrackImpression="contentblock-impression"
  trackImpressionMode="popup"
  [trackImpressionInfo]="{
    name: title,
  }"
>
  <ng-template wlModalBody>
    <div class="d-flex flex-column h-100">
      <div class="flex-grow-1">
        <wl-rich-text-wrapper
          *ngIf="body && body.value; else iframe"
          wlAutomationId="lightbox-richtext"
          [field]="body"
        >
          <div></div>
        </wl-rich-text-wrapper>
        <div *ngIf="image?.src" class="mt-2">
          <p *ngIf="imageLabel" class="mb-1">
            <strong>{{ imageLabel }}:</strong>
          </p>
          <img
            class="rounded mb-2 w-100"
            [src]="image?.src"
            [alt]="image?.alt"
          />
        </div>
      </div>
      <div
        class="d-flex justify-content-end mt-1"
        *ngIf="cta?.text && cta?.onClick"
      >
        <button
          class="btn btn-primary"
          (click)="onCtaClick()"
          wlAutomationId="lightbox-cta-button"
        >
          {{ cta?.text }}
        </button>
      </div>
    </div>
  </ng-template>
</wl-modal>

<!-- iframe template -->
<ng-template #iframe>
  <iframe
    class="w-100 h-100 border-0"
    *ngIf="url"
    [src]="url | safe: 'resourceUrl'"
    [title]="title"
    sandbox="allow-same-origin allow-scripts allow-popups allow-popups-to-escape-sandbox"
    wlAutomationId="lightbox-iframe"
  ></iframe>
</ng-template>
