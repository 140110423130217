import type { ComponentNameAndModule } from '@sitecore-jss/sitecore-jss-angular';

const loadChildren = () =>
  import('./lib/shared-member-get-member-lazy.module').then(
    (mod) => mod.SharedMemberGetMemberLazyModule
  );

const memberGetMemberLazyImports: ComponentNameAndModule[] = [
  {
    path: 'MemberGetMemberWidget',
    loadChildren,
  },
];
export { memberGetMemberLazyImports };
