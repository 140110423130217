<fieldset>
  <legend class="h6 fw-bold mb-1">
    {{ content.meterLabel
    }}<!---->
    <wl-tooltip [tooltip]="popoverDescription" class="ms-1" />
  </legend>
  <wl-radio
    [formsControl]="formControls?.doubleMeter"
    [radioItems]="radioButtonDescriptions"
    [rendering]="rendering"
    fieldName="dubbele meter"
    stepName="invullen"
  />
</fieldset>
