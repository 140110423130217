import type { HttpErrorResponse } from '@angular/common/http';
import { Status } from '@essent/common';
import { getContractGroups, getContractGroupsError } from '@essent/contract';
import type { PaymentDetails } from '@essent/financial';
import {
  getPaymentDetails,
  getPaymentDetailsClear,
  getPaymentDetailsError,
  getPaymentDetailsSuccess,
  patchPaymentDetailsActions,
} from '@essent/financial';
import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';

export interface State {
  status: Status;
  contractGroupId?: string;
  error?: HttpErrorResponse;
  details?: PaymentDetails;
}

export const initialState: State = {
  status: Status.IDLE,
};

const reducer = createReducer(
  initialState,
  on(getContractGroups, getPaymentDetails, (state) => ({
    details: state.details,
    status: Status.PENDING,
  })),
  on(getPaymentDetailsSuccess, (_, action) => ({
    contractGroupId: action.actionId,
    status: Status.SUCCESS,
    details: action.payload,
  })),
  on(getContractGroupsError, getPaymentDetailsError, (_, action) => ({
    contractGroupId: action.actionId,
    status: Status.ERROR,
    error: action.payload,
    details: undefined,
  })),
  on(
    getPaymentDetailsClear,
    patchPaymentDetailsActions.successAction,
    () => initialState
  )
);

export function paymentDetailsReducer(
  state = initialState,
  action: Action
): State {
  return reducer(state, action);
}
