export interface CostPerUnitVM {
  electricity?: {
    fixedRedeliveryCostsScale?: string;
    fixedRedeliveryDailyCosts?: number;
    fixedRedeliveryPeriodAmount?: number;
    fixedDeliveryDailyCosts?: number;
    fixedDeliveryPeriodAmount?: number;
    tariffPeriods?: TariffPeriod[];
  };
  gas?: {
    fixedDeliveryDailyCosts?: number;
    fixedDeliveryPeriodAmount?: number;
    tariffPeriods?: TariffPeriod[];
  };
}

export interface TariffPeriod {
  summerPrice?: number;
  winterPrice?: number;
  description: string;
}

export enum TariffZoneElectricity {
  Zone1 = '1 t/m 2.900',
  Zone2 = '2.901 t/m 10.000',
  Zone3 = '10.001 t/m 50.000',
  Zone4 = '50.0001 t/m 10 mln',
}

export enum TariffZoneGas {
  Zone1 = '1 t/m 1.000',
  Zone2 = '1.001 t/m 170.000',
}
export enum TariffType {
  Single = 'Enkeltarief',
  Normal = 'Normaaltarief',
  Low = 'Daltarief',
  ExpectedDelivery = 'Verwacht',
}

export enum TariffSeasonDates {
  SummerStart = '01-04',
  SummerEnd = '30-09',
  WinterStart = '01-10',
  WinterEnd = '31-03',
}
