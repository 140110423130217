import type { KeyValue } from 'ngrx-forms';

import type { NGRXProgressiveFormsActions } from '../actions';
import { MarkAsVisitedAction } from '../actions';
import type { ProgressiveFormGroupState } from '../state';

export function markAsVisitedReducer<TValue extends KeyValue>(
  state: ProgressiveFormGroupState<TValue>,
  action: NGRXProgressiveFormsActions
): ProgressiveFormGroupState<TValue> {
  if (action.type !== MarkAsVisitedAction.TYPE) {
    return state;
  }

  if (action.stepId !== state.id) {
    return state;
  }

  if (state.isVisited) {
    return state;
  }

  return {
    ...state,
    isVisited: true,
    isUnvisited: false,
  };
}
