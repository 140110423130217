import type { Duration } from 'date-fns';
import { add, formatISO, isDate } from 'date-fns';
import type { ValidationErrors } from 'ngrx-forms';

import { normalizeDateString } from '../../dates/date.helper';

declare module 'ngrx-forms/src/state' {
  export interface ValidationErrors {
    underRange?: string;
    overRange?: string;
  }
}

type Temporal = string | Date | Duration;

const format = (temporalValue: Temporal): string => {
  if (typeof temporalValue === 'string') {
    // Try to normalize date, otherwise use original value
    const normalizedDate = normalizeDateString(temporalValue) || temporalValue;
    let date = new Date(normalizedDate);
    if (isNaN(date.getTime())) {
      date = new Date();
    }
    return formatISO(date, { representation: 'date' });
  }
  if (isDate(temporalValue)) {
    return formatISO(temporalValue, { representation: 'date' });
  }
  return formatISO(add(new Date(), temporalValue), { representation: 'date' });
};

/**
 * Checks if the given date is in a specific range. Can be both absolute (use date values) or relative to "new Date()"
 * (use date-fns#Duration values). ISO strings are also supported. The range is inclusive on the lower side and
 * exclusive on the upper side. i.e. range [2020-01-01, 2020-02-01) will include 2020-01-01 but not include 2020-02-01.
 *
 * @param from Lower bound (included in range)
 * @param to Upper bound (excluded from range)
 * @param inclusive Include the "to" date in the range
 *
 * @return a function that accepts the date to check
 */
export const isDateInRange =
  (from: Temporal, to: Temporal, inclusive = false) =>
  <T extends string | Date>(date: T): ValidationErrors => {
    const dateFmt = format(date);
    const fromFmt = format(from);
    const toFmt = format(to);
    if (dateFmt < fromFmt) {
      return {
        underRange: `${dateFmt} is lower than range [${fromFmt}, ${toFmt})`,
      };
    }
    if (inclusive ? dateFmt > toFmt : dateFmt >= toFmt) {
      return {
        overRange: `${dateFmt} is higher than range [${fromFmt}, ${toFmt})`,
      };
    }
    return {};
  };

export function verifyDateInRange<T extends string | Date>(
  date: T,
  from: Temporal,
  to: Temporal,
  inclusive = false
): boolean {
  return (
    JSON.stringify(isDateInRange(from, to, inclusive)(date)) ===
    JSON.stringify({})
  );
}
