import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { provideBootstrapEffects } from '@innogy/utils-state';
import { StoreModule } from '@ngrx/store';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';

import { EcmpHeaderEffects } from './+state/ecmp-header';
import {
  navigationReducer,
  navigationSelectorKey,
} from './+state/navigation.selectors';
import { NavigationEffects } from './+state/navigation.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(navigationSelectorKey, navigationReducer),
  ],
  providers: [
    provideBootstrapEffects([EcmpHeaderEffects, NavigationEffects]),
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class NavigationStoreModule {}
