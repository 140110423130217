/* eslint-disable max-classes-per-file */
import type { HttpErrorResponse } from '@angular/common/http';
import type { Params } from '@angular/router';
import type { Action } from '@ngrx/store';
import type { MinimalActivatedRouteSnapshot } from '@ngrx/router-store';

import type { JssState } from '../jss-state.model';

export const enum JssStateActionTypes {
  UPDATE = '[JssState] update state',
  ERROR = '[JssState] error state',
  CHANGE_ROUTE = '[JssState] change route state',
  ACTIVATE_ERROR_ROUTE = '[JssState] activate error route',
  EXPERIENCE_EDITOR_ACTIVE = '[JssState] experience editor active',
  EXPERIENCE_EDITOR_SERVER_ACTIVE = '[JssState] experience editor server active',
  FATAL_ERROR = '[JssState] fatal error',
  META_TITLE_UPDATED = '[JssState] meta title updated',
  META_LINK_UPDATED = '[JssState] meta link updated',
  ORGANIZATIONAL_DATA_UPDATED = '[JssState] organizational data updated',
  PLACEHOLDER_LOADING = '[JssState] JSS placeholder loading',
  PLACEHOLDER_LOADED = '[JssState] JSS placeholder loaded',
  ALL_TOP_LEVEL_PLACEHOLDERS_LOADED = '[JssState] JSS all top-level placeholders loaded',
  MAIN_CONTENT_LOADED = '[JssState] Main content loaded',
  UPDATE_HISTORY = '[JssState] Update history',
}

export interface JssUrl {
  route: string;
  language: string;
  extraQueryParams?: Params;
}

export interface JssUrlWithStatus extends JssUrl {
  statusCode: number;
}

export interface JssFatalError {
  route: string;
  error: Error;
}

export interface MetaLink {
  type: 'canonical' | 'amphtml';
  url: string;
}

export class JssStateUpdate implements Action {
  readonly type = JssStateActionTypes.UPDATE;
  public constructor(public payload: JssState) {}
}

export class JssStateError implements Action {
  readonly type = JssStateActionTypes.ERROR;
  public constructor(public payload: HttpErrorResponse) {}
}

export class JssStateFatalError implements Action {
  readonly type = JssStateActionTypes.FATAL_ERROR;
  public constructor(public payload: JssFatalError) {}
}

export class JssStateChangeRoute implements Action {
  readonly type = JssStateActionTypes.CHANGE_ROUTE;
  public constructor(public payload: JssUrl) {}
}

export class JssStateActiveErrorRoute implements Action {
  readonly type = JssStateActionTypes.ACTIVATE_ERROR_ROUTE;
  public constructor(public payload: JssUrlWithStatus) {}
}

export class JssStateExperienceEditorActive implements Action {
  readonly type = JssStateActionTypes.EXPERIENCE_EDITOR_ACTIVE;
  public constructor(public payload: boolean) {}
}

export class JssStateExperienceEditorServerActive implements Action {
  readonly type = JssStateActionTypes.EXPERIENCE_EDITOR_SERVER_ACTIVE;
  public constructor(public payload: boolean) {}
}

export class JssStateMetaTitleUpdated implements Action {
  readonly type = JssStateActionTypes.META_TITLE_UPDATED;
  public constructor(public payload: string) {}
}

export class JssStateMetaLinkUpdated implements Action {
  readonly type = JssStateActionTypes.META_LINK_UPDATED;
  public constructor(public payload: MetaLink) {}
}

export class JssStateOrganizationalDataUpdated implements Action {
  readonly type = JssStateActionTypes.ORGANIZATIONAL_DATA_UPDATED;
  public constructor(public payload?: string) {}
}

export class JssStatePlaceholderLoading implements Action {
  readonly type = JssStateActionTypes.PLACEHOLDER_LOADING;
  public constructor(public payload?: string) {}
}

export class JssStatePlaceholderLoaded implements Action {
  readonly type = JssStateActionTypes.PLACEHOLDER_LOADED;
  public constructor(public payload?: string) {}
}

export class JssStateAllTopLevelPlaceholdersLoaded implements Action {
  readonly type = JssStateActionTypes.ALL_TOP_LEVEL_PLACEHOLDERS_LOADED;
}

export class JssStateMainContentLoaded implements Action {
  readonly type = JssStateActionTypes.MAIN_CONTENT_LOADED;
}

export class JssStateUpdateHistory implements Action {
  readonly type = JssStateActionTypes.UPDATE_HISTORY;
  public constructor(
    public payload: MinimalActivatedRouteSnapshot['queryParams']
  ) {}
}

export type JssStateActionsUnion =
  | JssStateUpdate
  | JssStateError
  | JssStateChangeRoute
  | JssStateActiveErrorRoute
  | JssStateExperienceEditorActive
  | JssStateExperienceEditorServerActive
  | JssStateMetaTitleUpdated
  | JssStateMetaLinkUpdated
  | JssStateOrganizationalDataUpdated
  | JssStatePlaceholderLoading
  | JssStatePlaceholderLoaded
  | JssStateUpdateHistory;
