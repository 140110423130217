import { createFeatureSelector, createSelector } from '@ngrx/store';

import type { FormStepFeatureState } from './progressive-form-step.reducer';
import {
  formStepAdapter,
  formStepFeatureKey,
} from './progressive-form-step.reducer';

const { selectEntities } = formStepAdapter.getSelectors();

export const selectFormStepFeatureState =
  createFeatureSelector<FormStepFeatureState>(formStepFeatureKey);

export const selectFormStepEntities = createSelector(
  selectFormStepFeatureState,
  selectEntities
);

export const selectFormStepEntitiesById = (ids: string[]) =>
  createSelector(selectFormStepEntities, (formStepEntities) =>
    ids.map((id) => formStepEntities[id])
  );
export const selectFormStepEntityById = (id: string) =>
  createSelector(
    selectFormStepEntities,
    (formStepEntities) => formStepEntities[id]
  );

export const selectFormStepShouldCollapse = (id: string) =>
  createSelector(
    selectFormStepEntityById(id),
    (step) => step?.config.isReadonly || step?.state.expectedCollapsed
  );

export const selectIsStepReadonly = (id: string) =>
  createSelector(
    selectFormStepEntityById(id),
    (step) => step?.config.isReadonly
  );
