import type { ValidationErrors, ValidationFn } from 'ngrx-forms';

export const useValidatorIf = <T>(
  validator: ValidationFn<T>,
  toggle: boolean
): ((value: T) => ValidationErrors) => {
  if (toggle) {
    return validator;
  } else {
    return () => ({});
  }
};
