import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'wl-image-card-button',
  templateUrl: './image-card-button.component.html',
  styleUrls: [
    './image-card-button.component.ed.scss',
    './image-card-button.component.essent.scss',
  ],
})
export class ImageCardButtonComponent {
  @Input()
  title = '';

  @Input()
  src?: string;

  @Input()
  alt?: string;

  @Output()
  clicked = new EventEmitter<void>();

  @Input()
  highlighted = false;
}
