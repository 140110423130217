import {
  isInt,
  requiredNumber,
  useValidatorIf,
  validateSequential,
} from '@innogy/utils-deprecated';
import { isNotNullish } from '@innogy/utils-rxjs';
import type { FormGroupState, ValidationErrors } from 'ngrx-forms';
import { updateGroup } from 'ngrx-forms';
import { greaterThanOrEqualTo } from 'ngrx-forms/validation';

import type { CalculateFormValues } from './form-values.interface';

const hasValue = (value?: number) => isNotNullish(value) && !isNaN(value);

const getCalculateValidators = (isRequired: boolean, value?: number) => [
  useValidatorIf(requiredNumber, isRequired),
  useValidatorIf(isInt, hasValue(value)),
  useValidatorIf(greaterThanOrEqualTo(0), hasValue(value)),
  useValidatorIf(greaterThanOrEqualTo(1), hasValue(value) && isRequired),
];

declare module 'ngrx-forms/src/state' {
  export interface ValidationErrors {
    noInputsFilled?: {
      error: string;
    };
  }
}

const noInputsFilled = <T extends CalculateFormValues>(
  state: FormGroupState<T>
) => {
  return <K extends number | null | undefined>(_value: K): ValidationErrors => {
    const value =
      state.value.electricityUsage ||
      state.value.electricityUsageNormal ||
      state.value.electricityUsageOffPeak ||
      state.value.gasUsage;

    if (!value) {
      return {
        noInputsFilled: {
          error: 'No input value provided to any calculate input field',
        },
      };
    }
    return {};
  };
};

export const validateElectricity = <T extends CalculateFormValues>(
  isRequired: boolean,
  state: FormGroupState<T>,
  noInputsRequired = false
) => {
  const isSingleRequired = isRequired && !state.value.doubleMeter;
  const isDoubleRequired = isRequired && state.value.doubleMeter;
  const isReturnRequired = isRequired && state.value.hasSolarPanel;

  return updateGroup<CalculateFormValues>({
    electricityUsage: validateSequential(
      ...getCalculateValidators(isSingleRequired, state.value.electricityUsage),
      useValidatorIf(noInputsFilled(state), noInputsRequired)
    ),
    electricityUsageNormal: validateSequential(
      ...getCalculateValidators(
        isDoubleRequired,
        state.value.electricityUsageNormal
      ),
      useValidatorIf(noInputsFilled(state), noInputsRequired)
    ),
    electricityUsageOffPeak: validateSequential(
      ...getCalculateValidators(
        isDoubleRequired,
        state.value.electricityUsageOffPeak
      ),
      useValidatorIf(noInputsFilled(state), noInputsRequired)
    ),
    electricityReturn: validateSequential(
      ...getCalculateValidators(isReturnRequired, state.value.electricityReturn)
    ),
  });
};

export const validateGas = <T extends CalculateFormValues>(
  isRequired: boolean,
  state: FormGroupState<T>,
  noInputsRequired = false
) => {
  return updateGroup<CalculateFormValues>({
    gasUsage: validateSequential(
      ...getCalculateValidators(isRequired, state.value.gasUsage),
      useValidatorIf(noInputsFilled(state), noInputsRequired)
    ),
  });
};
