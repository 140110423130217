import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { GenericModalModule } from '@innogy/common-ui/modals';
import { CommonUiServiceComponentsStoreModule } from '@innogy/common-ui/service-components/store';
import {
  CommonUiUtilityComponentsModule,
  PaginationModule,
} from '@innogy/common-ui/utility-components';
import { CoreAnalyticsModule } from '@innogy/core/analytics';
import { UtilsModule } from '@innogy/utils-deprecated';
import { StructuredDataModule } from '@core/structured-data';
import {
  NgbAccordionModule,
  NgbCarouselModule,
  NgbCollapseModule,
} from '@ng-bootstrap/ng-bootstrap';
import { JssModule } from '@sitecore-jss/sitecore-jss-angular';
import { CommonUiPartialsModule } from '@innogy/common-ui/partials';

import { AvailabilityContainerComponent } from './availability/availability-container.component';
import { FaqComponent } from './faq/faq.component';
import { QuickFeedbackComponent } from './faq/quick-feedback/quick-feedback.component';
import { HowToQuestionnaireContainerComponent } from './how-to-questionnaire-container/how-to-questionnaire-container.component';
import { HowToQuestionnaireOptionComponent } from './how-to-questionnaire-container/how-to-questionnaire-option/how-to-questionnaire-option.component';
import { HowToQuestionnaireQuestionComponent } from './how-to-questionnaire-container/how-to-questionnaire-question/how-to-questionnaire-question.component';
import { HowToReadBillContainerComponent } from './how-to-read-bill/how-to-read-bill-container/how-to-read-bill-container.component';
import { TaxonomyAccordionComponent } from './taxonomy/taxonomy-accordion/taxonomy-accordion.component';
import { TaxonomyContainerComponent } from './taxonomy/taxonomy-container.component';
import { TopTasksContainerComponent } from './top-tasks/top-tasks-container/top-tasks-container.component';
import { FaqContainerComponent } from './faq/faq-container/faq-container.component';

const exportedComponents = [
  HowToQuestionnaireContainerComponent,
  FaqContainerComponent,
  QuickFeedbackComponent,
  TaxonomyContainerComponent,
  AvailabilityContainerComponent,
  TopTasksContainerComponent,
  HowToReadBillContainerComponent,
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    CommonUiUtilityComponentsModule,
    UtilsModule,
    CoreAnalyticsModule,
    CommonUiServiceComponentsStoreModule,
    NgbCollapseModule,
    NgbAccordionModule,
    JssModule,
    NgbCarouselModule,
    GenericModalModule,
    PaginationModule,
    StructuredDataModule,
    CommonUiPartialsModule,
  ],
  declarations: [
    ...exportedComponents,
    HowToQuestionnaireQuestionComponent,
    HowToQuestionnaireOptionComponent,
    TaxonomyAccordionComponent,
    FaqComponent,
  ],
  exports: [...exportedComponents],
})
export class CommonUiServiceComponentsModule {}
