import { ProductDuration } from '@essent/common';
import type {
  MGMProductCode,
  Offer,
} from '@innogy/become-a-customer/shared/interfaces';
import type { EnvConfigBrand } from '@core/config-models';

import { isMonoOffer } from './offer-helpers';

export function getMGMProductCode(
  offer: Offer,
  brand: EnvConfigBrand
): MGMProductCode {
  if (isMonoOffer(offer)) {
    return `${brand}mono`;
  } else if (offer.duration === ProductDuration.FLEX) {
    return `${brand}flex`;
  } else {
    return `${brand}${offer.duration}jaar`;
  }
}

export function getMGMValue(offer: Offer, brand: EnvConfigBrand): number {
  switch (getMGMProductCode(offer, brand)) {
    case 'essent3jaar':
    case 'essent5jaar':
    case 'ed2jaar':
    case 'ed3jaar':
      return 100;
    default:
      return 50;
  }
}

export function getMGMSharedValue(
  offer: Offer | undefined,
  brand: EnvConfigBrand
): number {
  if (offer) {
    return Math.round(getMGMValue(offer, brand) / 2);
  }
  return 0;
}
