import type { ComponentNameAndModule } from '@sitecore-jss/sitecore-jss-angular';

const loadChildren = () =>
  import('./lib/shared-tariff-breakdown-lazy.module').then(
    (mod) => mod.SharedTariffBreakdownLazyModule
  );

const tariffBreakdownLazyImports: ComponentNameAndModule[] = [
  {
    path: 'TariffBreakdown',
    loadChildren,
  },
];
export { tariffBreakdownLazyImports };
