import type { ProgressiveFormState } from './progressive-form.model';

export function getFormStepIndex(
  formSteps: string[],
  formStepId: string
): number {
  return formSteps.findIndex((cur) => cur === formStepId);
}

export function getNextFormStepIndex(
  progressiveState?: ProgressiveFormState
): number | undefined {
  if (progressiveState?.isEditingPreviouslySubmittedForm) {
    return getFormStepIndex(
      progressiveState.formSteps,
      progressiveState.previousStepId ?? ''
    );
  }

  if (progressiveState?.naturalNextStepId) {
    return getFormStepIndex(
      progressiveState.formSteps,
      progressiveState.naturalNextStepId
    );
  }

  return;
}

export function isInitialStep(progressiveState: ProgressiveFormState): boolean {
  return (
    progressiveState.currentStepId === progressiveState.formSteps[0] &&
    !progressiveState.isEditingPreviouslySubmittedForm
  );
}

export function shouldSkipInitialStep(
  progressiveState: ProgressiveFormState
): boolean {
  return (
    isInitialStep(progressiveState) && !progressiveState.scrollToInitialStep
  );
}
