import type { AsyncState } from '@essent/common';
import {
  createAsyncStateReducer,
  createInitialAsyncState,
} from '@essent/common';
import type { DynamicPricesAvailabilityResponse } from '@essent/tariffs';
import {
  getDynamicPricesAvailability,
  getDynamicPricesAvailabilityClear,
  getDynamicPricesAvailabilityError,
  getDynamicPricesAvailabilitySuccess,
} from '@essent/tariffs';
import type { Action } from '@ngrx/store';

export type DynamicPricesAvailabilityState =
  AsyncState<DynamicPricesAvailabilityResponse>;

export const initialDynamicPricesAvailabilityState: DynamicPricesAvailabilityState =
  createInitialAsyncState();

const _reducer = createAsyncStateReducer(
  getDynamicPricesAvailability,
  getDynamicPricesAvailabilitySuccess,
  getDynamicPricesAvailabilityError,
  getDynamicPricesAvailabilityClear
);

export function dynamicPricesAvailabilityReducer(
  state = initialDynamicPricesAvailabilityState,
  action: Action
) {
  return _reducer(state, action);
}
