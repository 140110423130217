import type { AsyncState } from '@essent/common';
import {
  createAsyncStateReducer,
  createInitialAsyncState,
} from '@essent/common';
import type { FlowPutSuccess } from '@essent/new-customer';
import {
  putProposition,
  putPropositionClear,
  putPropositionError,
  putPropositionSuccess,
} from '@essent/new-customer';
import type { Action, ActionReducerMap } from '@ngrx/store';
import { combineReducers } from '@ngrx/store';

export type PutPropositionState = AsyncState<FlowPutSuccess>;

export interface PropositionState {
  putProposition: PutPropositionState;
}

const initialState: PropositionState = {
  putProposition: createInitialAsyncState(),
};

const _putPropositionReducer = createAsyncStateReducer(
  putProposition,
  putPropositionSuccess,
  putPropositionError,
  putPropositionClear
);

const _reducers: ActionReducerMap<PropositionState, Action> = {
  putProposition: _putPropositionReducer,
};

const _combinedReducers = combineReducers(_reducers);

export function propositionReducer(
  state: PropositionState = initialState,
  action: Action
): PropositionState {
  return _combinedReducers(state, action);
}
