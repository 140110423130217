import { Component, EventEmitter, Input, Output } from '@angular/core';
import type { Offer } from '@innogy/become-a-customer/shared/interfaces';
import { ConsumptionValuesVM } from '@innogy/become-a-customer/shared/interfaces';
import type { StreetAddress } from '@essent/new-customer';

import type {
  CommodityOfferComponentLabels,
  CommodityOfferLabels,
} from '../commodity-offers.model';

@Component({
  selector: 'wl-horizontal-commodity-offer',
  templateUrl: './horizontal-commodity-offer.component.html',
})
export class HorizontalCommodityOfferComponent {
  @Input() offers?: Offer[];
  @Input() content?: CommodityOfferLabels & CommodityOfferComponentLabels;
  @Input() showMemberGetMember = false;
  @Input() consumptionValues?: ConsumptionValuesVM;
  @Input() supplyAddress?: Partial<StreetAddress>;
  @Input() accountId?: string;
  @Input() flowId?: string;
  @Output() selectOffer = new EventEmitter<Offer>();
  @Output() openMoreInfo = new EventEmitter<Offer>();
  @Output() viewTariffs = new EventEmitter<Offer>();
  @Output() adjustCalculation = new EventEmitter<void>();
}
