import { DOCUMENT } from '@angular/common';
import type { OnInit } from '@angular/core';
import { Component, Inject, Input, Renderer2 } from '@angular/core';
import { PlatformService } from '@core/platform';
import { DomService, ScriptInjectOn, WINDOW } from '@innogy/utils-dom';

interface Font {
  fontFamilyName: string;
  options: {
    weight?: number | string | undefined;
    style?: string | undefined;
    stretch?: string | undefined;
  };
}

@Component({
  selector: 'wl-load-fonts',
  template: '',
})
export class LoadFontsComponent implements OnInit {
  @Input()
  public font:
    | 'Lato'
    | 'WorkSans'
    | 'OpenSans'
    | 'Fabrikat'
    | 'Baton'
    | 'FilsonSoft' = 'Lato';

  private readonly fontStagePrefix = 'fonts-stage';
  private readonly subFont: Font = {
    fontFamilyName: `${this.font}Subset`,
    options: {
      weight: 400,
    },
  };
  private readonly fonts: Font[] = [
    {
      fontFamilyName: this.font,
      options: {
        weight: 300,
      },
    },
    {
      fontFamilyName: this.font,
      options: {
        weight: 400,
      },
    },
    {
      fontFamilyName: this.font,
      options: {
        weight: 700,
      },
    },
    {
      fontFamilyName: this.font,
      options: {
        weight: 900,
      },
    },
  ];
  private readonly documentElement = this.document.documentElement;
  private readonly sessionKey = 'fontsLoadedCriticalFontPreloadFallback';
  private readonly sessionKeyValue = 'true';
  private readonly repeatVisitOptimizationScript = `
      (function () {
        'use strict';
        if ('sessionStorage' in window && sessionStorage.${this.sessionKey} === 'true') {
          document.documentElement.className += ' ${this.fontStagePrefix}-2';
        }
      })();`;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(WINDOW) private readonly windowRef: Window,
    private readonly platformService: PlatformService,
    private readonly renderer: Renderer2,
    private readonly domService: DomService
  ) {}

  ngOnInit() {
    if (this.platformService.isClient()) {
      this.loadFonts(this.subFont, this.fonts, this.fontStagePrefix);
    }

    this.setupRepeatVisitOptimization();
  }

  setupRepeatVisitOptimization() {
    this.domService.injectScript({
      innerHTML: this.repeatVisitOptimizationScript,
      injectOnPlatform: ScriptInjectOn.SERVER_ONLY,
    });
  }

  private async loadFonts(subFont: Font, fontFamily: Font[], prefix: string) {
    try {
      if (this.areFontsCached()) {
        // optimization for repeat view
        this.addLastStageClass();
      } else {
        // modern browsers with css font loading support
        await this.loadStagedFonts(subFont, fontFamily, prefix);
      }
    } catch (err) {
      console.warn('Some critical font are not available:', err);
    }
  }

  private addLastStageClass() {
    this.renderer.addClass(this.documentElement, `${this.fontStagePrefix}-2`);
  }

  private async loadStagedFonts(
    subFont: Font,
    fontFamily: Font[],
    prefix: string
  ) {
    await this.document.fonts.load(`1em ${subFont.fontFamilyName}`);

    await Promise.all(
      fontFamily.map((font) =>
        this.document.fonts.load(
          `${font.options.weight} 1em ${font.fontFamilyName}`
        )
      )
    );

    this.renderer.addClass(this.documentElement, `${prefix}-2`);
    // Optimization for Repeat Views
    this.storeFontsCached();
  }

  private areFontsCached(): boolean {
    return (
      this.windowRef.sessionStorage.getItem(this.sessionKey) ===
      this.sessionKeyValue
    );
  }

  private storeFontsCached() {
    this.windowRef.sessionStorage.setItem(
      this.sessionKey,
      this.sessionKeyValue
    );
  }
}
