import type { PostalAddressFormValues } from '@innogy/common-ui/forms';
import { extendPostalAddressFormReducer } from '@innogy/common-ui/forms';
import {
  getSolarPanelsAddressCheckError,
  getSolarPanelsAddressCheckSuccess,
} from '@innogy/eplus/temporary-core-modules';
import type { FormGroupValidatorMap } from '@innogy/utils-deprecated';
import { isNumeric, validateSequential } from '@innogy/utils-deprecated';
import type { ActionReducer } from '@ngrx/store';
import { combineReducers, createReducer, on } from '@ngrx/store';
import type { FormGroupState } from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';

export const solarPanelsWizardKey = 'solarPanelsWizard';
export const solarPanelsWizardFormId = 'solarPanelsWizardForm';
export const solarPanelsWizardFormConsumptionControlId = `${solarPanelsWizardFormId}.consumption`;

export type SolarPanelsWizardHouseAcquisitionOption =
  | 'bought_with_own_roof'
  | 'other';

export interface SolarPanelsWizardFormValues extends PostalAddressFormValues {
  consumption: number;
  houseType: SolarPanelsWizardHouseAcquisitionOption | undefined;
}

export interface SolarPanelsWizardAddressCheck {
  hasErrored: boolean;
}

export interface SolarPanelsWizardState {
  formState: FormGroupState<SolarPanelsWizardFormValues>;
  addressCheck: SolarPanelsWizardAddressCheck;
}

const initialFormValues: SolarPanelsWizardFormValues = {
  postalCode: '',
  communicationNumber: NaN,
  houseNumberAddition: '',
  consumption: NaN,
  houseType: undefined,
};

const initialAddressCheckState: SolarPanelsWizardAddressCheck = {
  hasErrored: false,
};

const validators: FormGroupValidatorMap<
  SolarPanelsWizardFormValues,
  PostalAddressFormValues
> = {
  consumption: validateSequential(required, isNumeric),
  houseType: validateSequential(required),
};

const {
  extendedControlIds,
  initialExtendedFormGroupState: initialSolarPanelsWizardFormGroupState,
  extendedReducer: solarPanelsWizardFormStateReducer,
} = extendPostalAddressFormReducer<SolarPanelsWizardFormValues>({
  formId: solarPanelsWizardFormId,
  baseInitialState: initialFormValues,
  extendingValidators: validators,
});

const solarPanelsWizardAddressCheckReducer = createReducer(
  initialAddressCheckState,
  // >= 400 counts as a backend error, 404 counts as house not found.
  on(getSolarPanelsAddressCheckError, (_state, action) => ({
    hasErrored: action.payload?.status >= 400 && action.payload?.status !== 404,
  })),
  on(getSolarPanelsAddressCheckSuccess, () => ({
    hasErrored: false,
  }))
);

export const solarPanelsWizardFormControlIds = extendedControlIds;

export const initialSolarPanelsWizardState: SolarPanelsWizardState = {
  formState: initialSolarPanelsWizardFormGroupState,
  addressCheck: initialAddressCheckState,
};

export const solarPanelsWizardReducer: ActionReducer<SolarPanelsWizardState> =
  combineReducers({
    formState: solarPanelsWizardFormStateReducer,
    addressCheck: solarPanelsWizardAddressCheckReducer,
  });
