<ngb-carousel
  [class.first-slide]="isFirstOffer"
  [class.last-slide]="isLastOffer"
  *ngIf="offers.length > 0"
  [interval]="0"
  [wrap]="false"
  #ngbCarousel
  class="carousel"
  (slid)="setCurrentSlide($event)"
  (swipeleft)="carousel?.next()"
  (swiperight)="carousel?.prev()"
>
  <ng-template
    ngbSlide
    *ngFor="let offer of offers; index as i; trackBy: trackByOffers"
    id="{{ i }}"
  >
    <wl-horizontal-offer-card
      [wlAutomationId]="'horizontal-offer-card-' + i"
      class="d-block px-5 pb-5"
      [offer]="offer"
      [rendering]="rendering"
      [addOn]="addOn"
      [hasMemberGetMember]="hasMemberGetMember"
      [isAddOnChecked]="isAddOnChecked"
      [moreInfoButtonClick]="
        moreInfoButtonClick ? moreInfoButtonClick(offer) : undefined
      "
      (addOnToggle)="onAddOnToggle($event)"
      (submitSelectedProposition)="selectProposition($event)"
      (openAomModal)="openAomModal.emit($event)"
      (showMoreIncentiveInfo)="showMoreIncentiveInfo.emit($event)"
    ></wl-horizontal-offer-card>
  </ng-template>
</ngb-carousel>

<div
  *ngIf="offers && offers.length > 1"
  wlAutomationId="arrows"
  class="arrows d-flex justify-content-center"
>
  <button
    [class.disabled]="isFirstOffer"
    class="btn btn-brand prev-arrow mx-1"
    (click)="carousel?.prev()"
  >
    <fa-icon icon="chevron-left"></fa-icon>
  </button>
  <button
    [class.disabled]="isLastOffer"
    class="btn btn-brand next-arrow mx-1"
    (click)="carousel?.next()"
  >
    <fa-icon icon="chevron-right"></fa-icon>
  </button>
</div>
