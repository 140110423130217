import { createSelector } from '@ngrx/store';

import { eplusStoreFeature } from '../store-form.state';
import { storeFunnelKey } from './store-funnel.reducer';

export const selectStoreFunnelSettings = createSelector(
  eplusStoreFeature,
  (state) => state[storeFunnelKey]
);
export const selectSelectedStoreProducts = createSelector(
  selectStoreFunnelSettings,
  ({ selectedProducts }) => selectedProducts
);
