import type { Actions } from '@ngrx/effects';
import { ofType } from '@ngrx/effects';
import { filter } from 'rxjs/operators';

import { markAsValidSubmissionAction } from '../actions';

export const ofValidatedSubmitAction =
  (controlId: string | string[]) => (source$: Actions) =>
    source$.pipe(
      ofType(markAsValidSubmissionAction),
      filter((action) => {
        if (typeof controlId === 'string') {
          return controlId === action.controlId;
        }

        return controlId.includes(action.controlId);
      })
    );
