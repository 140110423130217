import { NgModule } from '@angular/core';
import { ComposablesModule } from '@innogy/common-ui/common-renderings';
import { CommonUiFormsModule } from '@innogy/common-ui/forms';
import { DefaultComponentModule } from '@innogy/core/modules/components';
import { ProgressiveFormComponentsModule } from '@innogy/shared/progressive-form';
import { NgrxFormsModule } from 'ngrx-forms';

import { GenericFormContainerComponent } from './components/generic-form-container/generic-form-container.component';
import { GenericFormComponent } from './components/generic-form/generic-form.component';
import {
  ScAddressComponent,
  ScCheckboxComponent,
  ScCheckboxSetComponent,
  ScDropdownComponent,
  ScEmailComponent,
  ScNameComponent,
  ScPaymentComponent,
  ScPhoneComponent,
  ScRadioComponent,
  ScTextComponent,
} from './components/inputs';

const components = [
  GenericFormContainerComponent,
  GenericFormComponent,
  ScAddressComponent,
  ScTextComponent,
  ScCheckboxComponent,
  ScEmailComponent,
  ScNameComponent,
  ScPaymentComponent,
  ScDropdownComponent,
  ScPhoneComponent,
  ScRadioComponent,
  ScCheckboxSetComponent,
];

@NgModule({
  imports: [
    DefaultComponentModule,
    CommonUiFormsModule,
    NgrxFormsModule,
    ComposablesModule,
    ProgressiveFormComponentsModule,
  ],
  declarations: components,
  exports: components,
})
export class SitecoreFormsModule {}
