import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { filter, map, mergeMap } from 'rxjs';
import { MarkAsSubmittedAction } from 'ngrx-forms';
import { AddressTypes } from '@innogy/customer-details/store';
import {
  getAddressCheck,
  getAddressCheckError,
  getAddressCheckSuccess,
} from '@essent/address';
import { RequestStrategy } from '@essent/common';
import { openGenericModal } from '@innogy/common-ui/modals';

import {
  handleHeatpumpsFunnelAddressNotFoundErrorAction,
  handleHeatpumpsFunnelGenericErrorAction,
  handleHeatpumpsFunnelPrivateErrorAction,
  onHeatpumpsFunnelNavigationAction,
  selectHeatpumpsFunnelSettings,
} from '../heatpumps-funnel';
import { heatpumpsWizardFormSubmitAction } from './heatpumps-wizard.actions';
import { heatpumpsWizardFormId } from './heatpumps-wizard.reducer';
import {
  prefillHeatpumpsOrderFormAddressStateAction,
  resetHeatpumpsOrderFormAction,
} from '../heatpumps-order-form';
import { selectHeatpumpsWizardFormState } from './heatpumps-wizard.selectors';

@Injectable()
export class HeatpumpsWizardEffects {
  funnelSettings$ = this.store.select(selectHeatpumpsFunnelSettings);

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store
  ) {}

  public readonly markAsSubmitted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(heatpumpsWizardFormSubmitAction),
      map(() => new MarkAsSubmittedAction(heatpumpsWizardFormId))
    )
  );

  public readonly submitHeatpumpsWizardFormSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(heatpumpsWizardFormSubmitAction),
      concatLatestFrom(() => this.store.select(selectHeatpumpsWizardFormState)),
      filter(([_, formData]) => formData.isValid),
      mergeMap(([_, formData]) => [
        resetHeatpumpsOrderFormAction(),
        getAddressCheck({
          actionId: heatpumpsWizardFormId,
          requestStrategy: RequestStrategy.CANCEL,
          payload: {
            postcode: formData.controls.postalCode.value,
            houseNumber:
              formData.controls.communicationNumber?.value.toString() ?? '',
          },
        }),
      ])
    )
  );

  public readonly handleAddressCheckError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAddressCheckError),
      filter(({ actionId }) => actionId === heatpumpsWizardFormId),
      map((action) =>
        action.payload.status === 404
          ? handleHeatpumpsFunnelAddressNotFoundErrorAction()
          : handleHeatpumpsFunnelGenericErrorAction()
      )
    )
  );

  public readonly onHeatpumpsFunnelAddressNotFoundErrorAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(handleHeatpumpsFunnelAddressNotFoundErrorAction),
        concatLatestFrom(() => this.funnelSettings$),
        map(([_, funnelSettings]) => {
          if (!funnelSettings.addressNotFoundErrorModal) {
            return handleHeatpumpsFunnelPrivateErrorAction({
              message:
                'addressNotFoundErrorModal is not defined on FunnelSettings',
            });
          }
          return openGenericModal(funnelSettings.addressNotFoundErrorModal);
        })
      )
  );

  public readonly handleAddressCheckSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAddressCheckSuccess),
      filter(({ actionId }) => actionId === heatpumpsWizardFormId),
      concatLatestFrom(() => [
        this.funnelSettings$,
        this.store.select(selectHeatpumpsWizardFormState),
      ]),
      mergeMap(([addressData, funnelSettings, formState]) => [
        onHeatpumpsFunnelNavigationAction({
          page: funnelSettings.questionnairePage,
        }),
        prefillHeatpumpsOrderFormAddressStateAction({
          payload: {
            addressType: AddressTypes.ADDRESS,
            postalCode: formState.controls.postalCode.value,
            communicationNumber: formState.controls.communicationNumber.value,
            city: addressData.payload.city,
            houseNumberAddition: formState.controls.houseNumberAddition?.value,
            street: addressData.payload.street,
            countryCode: addressData.payload.country,
          },
        }),
      ])
    )
  );
}
