import { Injectable } from '@angular/core';
import {
  getConsumptionError,
  getStartDateError,
  putBudgetBillError,
  putConfirmationError,
  putCorrespondenceDetailsError,
  putPaymentDetailsError,
  putPersonalDetailsError,
  putStartDateError,
} from '@essent/new-customer';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { select, Store } from '@ngrx/store';
import type { FormGroupState } from 'ngrx-forms';
import { MarkAsUnsubmittedAction, SetAsyncErrorAction } from 'ngrx-forms';
import { mergeMap } from 'rxjs/operators';

import { handleBacApiErrorAction } from '../bac/0.errors';
import { putPersonalDetailsState } from '../bac/12.personal-details';
import { putCorrespondenceDetailsState } from '../bac/13.correspondence-details';
import { putPaymentDetailsState } from '../bac/14.payment-details';
import {
  getStartDateState,
  putStartDateState,
} from '../bac/17.suggested-startdate';
import { putConfirmationState } from '../bac/18.confirmation';
import { getConsumptionState } from '../bac/7.consumption';
import { putBudgetBillState } from '../bac/budget-bill';
import { getGetOfferState } from '../get-offer';
import { getOffersError } from '../get-offer/get-offers.actions';
import type { OrderPaymentFormValues } from './payment/order-payment.reducer';
import { hasBlacklistedError } from './payment/order-payment.reducer';
import { getOrderPaymentFormState } from './payment/order-payment.selector';

@Injectable()
export class OrderErrorEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<any>
  ) {}

  private readonly putPersonalDetailsState$ = this.store$.pipe(
    select(putPersonalDetailsState)
  );
  private readonly putCorrespondenceDetailsState$ = this.store$.pipe(
    select(putCorrespondenceDetailsState)
  );
  private readonly putPaymentDetailsState$ = this.store$.pipe(
    select(putPaymentDetailsState)
  );
  private readonly paymentDetailsFormValues$ = this.store$.pipe(
    select(getOrderPaymentFormState)
  );
  private readonly putBudgetBillState$ = this.store$.pipe(
    select(putBudgetBillState)
  );
  private readonly putConfirmationState$ = this.store$.pipe(
    select(putConfirmationState)
  );

  private readonly putStartDateState$ = this.store$.pipe(
    select(putStartDateState)
  );

  private readonly getStartDateState$ = this.store$.pipe(
    select(getStartDateState)
  );

  private readonly getConsumptionState$ = this.store$.pipe(
    select(getConsumptionState)
  );

  private readonly getOfferState$ = this.store$.pipe(select(getGetOfferState));

  public readonly handleOrderAPIErrors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        putPersonalDetailsError,
        putCorrespondenceDetailsError,
        putPaymentDetailsError,
        putBudgetBillError,
        putConfirmationError,
        putStartDateError,
        getStartDateError,
        getConsumptionError,
        getOffersError
      ),
      concatLatestFrom(() => [
        this.putPersonalDetailsState$,
        this.putCorrespondenceDetailsState$,
        this.putPaymentDetailsState$,
        this.putBudgetBillState$,
        this.putConfirmationState$,
        this.putStartDateState$,
        this.getStartDateState$,
        this.getConsumptionState$,
        this.getOfferState$,
        this.paymentDetailsFormValues$,
      ]),
      mergeMap(
        ([
          _,
          putPersonalDetails,
          putCorrespondenceDetails,
          putPaymentDetails,
          putBudgetBill,
          putConfirmation,
          putStartDate,
          getStartDate,
          getConsumption,
          getOffer,
          paymentDetailsFormValues,
        ]) => {
          const apiErrors = [];

          if (putPersonalDetails.error) {
            apiErrors.push(
              handleBacApiErrorAction({
                message: putPersonalDetails.error.message,
                stepName: 'Order',
              })
            );
          }
          if (putCorrespondenceDetails.error) {
            apiErrors.push(
              handleBacApiErrorAction({
                message: putCorrespondenceDetails.error.message,
                stepName: 'Order',
              })
            );
          }
          if (putPaymentDetails.error) {
            apiErrors.push(
              ...this.getPaymentActions(
                putPaymentDetails,
                paymentDetailsFormValues
              )
            );
          }
          if (putBudgetBill.error) {
            apiErrors.push(
              handleBacApiErrorAction({
                message: putBudgetBill.error.message,
                stepName: 'Order',
              })
            );
          }
          if (putConfirmation.error) {
            apiErrors.push(
              handleBacApiErrorAction({
                message: putConfirmation.error.message,
                stepName: 'Order',
              })
            );
          }
          if (putStartDate.error) {
            apiErrors.push(
              handleBacApiErrorAction({
                message: putStartDate.error.message,
                stepName: 'Order',
              })
            );
          }
          if (getStartDate.error) {
            apiErrors.push(
              handleBacApiErrorAction({
                message: getStartDate.error.message,
                stepName: 'Order',
              })
            );
          }
          if (getConsumption.error) {
            apiErrors.push(
              handleBacApiErrorAction({
                message: getConsumption.error.message,
                stepName: 'Order',
              })
            );
          }
          if (getOffer.error) {
            apiErrors.push(
              handleBacApiErrorAction({
                message: getOffer.error.message,
                stepName: 'Order',
              })
            );
          }

          return apiErrors;
        }
      )
    )
  );

  private getPaymentActions(
    putPaymentDetails: any,
    paymentDetailsFormValues: FormGroupState<OrderPaymentFormValues>
  ): any[] {
    const actions = [];
    const isBlackListed = hasBlacklistedError(putPaymentDetails.error);

    actions.push(
      handleBacApiErrorAction({
        message: putPaymentDetails.error?.message,
        stepName: 'Order',
        showModal: !isBlackListed,
      })
    );

    if (isBlackListed) {
      actions.push(
        new SetAsyncErrorAction(
          paymentDetailsFormValues.controls.iban.id,
          'ibanBlacklisted',
          true
        ),
        new MarkAsUnsubmittedAction(paymentDetailsFormValues.id)
      );
    }

    return actions;
  }
}
