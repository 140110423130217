import { createSelector } from '@ngrx/store';

import { eplusSolarPanelsFeature } from '../solar-panels-form.state';
import { selectSolarPanelsWizardAnalyticsPayload } from '../solar-panels-wizard/solar-panels-wizard.selectors';
import { solarPanelsSuitabilityCheckKey } from './solar-panels-suitability-check.reducer';

const selectSolarPanelsSuitabilityCheckState = createSelector(
  eplusSolarPanelsFeature,
  (state) => state[solarPanelsSuitabilityCheckKey]
);

export const selectSolarPanelsSuitabilityCheckFormState = createSelector(
  selectSolarPanelsSuitabilityCheckState,
  (state) => state.formState
);

export const selectSolarPanelsSuitabilityCheckViewModel = createSelector(
  selectSolarPanelsSuitabilityCheckState,
  (state) => ({
    ...state,
  })
);

export const selectSolarPanelsSuitabilityCheckAnalyticsPayload = createSelector(
  selectSolarPanelsSuitabilityCheckFormState,
  selectSolarPanelsWizardAnalyticsPayload,
  (formState, wizardTrackingPayload) => ({
    ...wizardTrackingPayload,
    asbestos: formState.value.asbestos,
    roofType: formState.value.roofType,
    renewedRoofWithinTimeLimit: formState.value.renewedRoofWithinTimeLimit,
  })
);

export const selectSolarPanelsSuitabilityCheckIsInvalid = createSelector(
  selectSolarPanelsSuitabilityCheckFormState,
  (formState) => {
    if (formState.isValid && formState.isDirty) {
      return false;
    }

    const controls = [
      formState.controls.asbestos,
      formState.controls.roofType,
      formState.controls.renewedRoofWithinTimeLimit,
    ];

    for (const control of controls) {
      if (control?.isDirty && control?.isInvalid) {
        return true;
      }
    }
    return false;
  }
);
