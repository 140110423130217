/**
 * Format any string as initials (A.B.C.3.@.) but keep any trailing dots
 *
 * @param initials latest value of the initials.
 * @param oldInitials pre-latest value of the initials, provide this if you want backspace to also remove the preceding initial instead of just the dot.
 */
export function formatInitials(initials: string, oldInitials?: string) {
  // When user backspaces, also remove the preceding initial instead of only the dot.
  if (
    oldInitials &&
    initials.length - oldInitials.length === -1 &&
    oldInitials.slice(-1) === '.' &&
    initials.slice(-1) !== '.'
  ) {
    initials = initials.slice(0, -2);
  }

  // Remove the last dot (if any) in order to format the string correctly after processing
  if (initials.slice(-1) === '.') {
    initials = initials.substring(0, initials.length - 1);
  }

  return (
    initials
      // Remove all dots that have anything but a dot ahead of it, in pratice this removes
      // all dots except the trailing ones.
      .replace(/\.+(?=[^.])/g, '')
      .toUpperCase()
      .split('')
      // Add a dot after each character, but not after another dot
      .reduce((prev, curr) => {
        if (prev.slice(-1) === '.' && curr === '.') {
          return prev + curr;
        }

        return `${prev + curr}.`;
      }, '')
  );
}
