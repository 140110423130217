import type { ValidationErrors } from 'ngrx-forms';

export const postalCodeRegex = new RegExp('^[1-9][0-9]{3} ?[A-Za-z]{2}$');

export interface IsPostalCodeValidator {
  actual: any;
}

declare module 'ngrx-forms/src/state' {
  export interface ValidationErrors {
    isPostalCode?: IsPostalCodeValidator;
  }
}

export const isPostalCodeValue = (value?: string) => {
  if (value == null) {
    return false;
  }
  return postalCodeRegex.test(value);
};

/**
 * Validates that the given value is a number
 * @param value
 */
export const isPostalCode = (value: string): ValidationErrors => {
  if (isPostalCodeValue(value)) {
    return {};
  }

  return {
    isPostalCode: { actual: value },
  };
};
