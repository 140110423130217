<ng-template #loader>
  <wl-svg-skeleton-loader [mask]="mask"></wl-svg-skeleton-loader>
</ng-template>

<!-- Spacer that takes care of highlights and the not loaded yet duration filter -->
<div class="d-block offset-top"></div>
<div class="d-none d-lg-block">
  <div
    class="row justify-content-center"
    wlAutomationId="horizontal-offer-cards-loader"
  >
    <div class="col-4">
      <div class="card border-0 card--offer p-2">
        <ng-container *ngTemplateOutlet="loader"></ng-container>
      </div>
    </div>
    <div class="col-4">
      <div class="card border-0 card--offer p-2">
        <ng-container *ngTemplateOutlet="loader"></ng-container>
      </div>
    </div>
    <div class="col-4">
      <div class="card border-0 card--offer p-2">
        <ng-container *ngTemplateOutlet="loader"></ng-container>
      </div>
    </div>
  </div>
</div>

<div class="d-lg-none">
  <div
    class="row justify-content-center"
    wlAutomationId="horizontal-offer-cards"
  >
    <div class="col-12 px-5">
      <div class="card border-0 card--offer px-5 p-2">
        <ng-container *ngTemplateOutlet="loader"></ng-container>
      </div>
    </div>
  </div>
</div>
