/* eslint-disable max-classes-per-file */
import { createAction, union } from '@ngrx/store';
import { createBaseAction } from '@essent/common';
import type { HttpErrorResponse } from '@angular/common/http';

import type { UserInfoResponse } from '../account.model';

export const enum SelectedAccountTypes {
  LOAD_USER_INFO = '[SelectedAccountTypes] Load user info',
  LOAD_USER_INFO_SUCCESS = '[SelectedAccountTypes] Load user info success',
  LOAD_USER_INFO_ERROR = '[SelectedAccountTypes] Load user info error',

  LOAD_CUSTOMER_FAILURE = '[SelectedAccountTypes] Failed loading customer',
}
export const loadUserInfo = createAction(SelectedAccountTypes.LOAD_USER_INFO);
export const loadUserInfoSuccess = createBaseAction<UserInfoResponse>(
  SelectedAccountTypes.LOAD_USER_INFO_SUCCESS
);
export const loadUserInfoError = createBaseAction<HttpErrorResponse>(
  SelectedAccountTypes.LOAD_USER_INFO_ERROR
);
export const loadCustomerInfoError = createBaseAction<Error>(
  SelectedAccountTypes.LOAD_CUSTOMER_FAILURE
);

const actions = union({
  loadUserInfo,
  loadUserInfoSuccess,
  loadUserInfoError,
  loadCustomerInfoError,
});

export type AccountsActionsUnion = typeof actions;
