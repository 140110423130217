import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

@Pipe({
  name: 'analyticsMap',
})
export class AnalyticsMapPipe implements PipeTransform {
  transform(input: string | undefined): string {
    if (!input) {
      return '';
    }

    switch (input) {
      case 'hoek':
        return 'hoekwoning';
      case 'rijtje':
        return 'rijtjeshuis';
      case 'ELECTRICITY':
        return 'stroom';
      case 'ELECTRICITY_AND_GAS':
        return 'stroom en gas';
      default:
        return input;
    }
  }
}
