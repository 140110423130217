import { createSelector } from '@ngrx/store';

import { getCustomerDetailsState } from './feature.selectors';
const getChangeCommunicationAddressForm = createSelector(
  getCustomerDetailsState,
  (state) => state.changeCommunicationAddressForm
);

export const getChangeCommunicationAddressFormState = createSelector(
  getChangeCommunicationAddressForm,
  (state) => state.changeCommunicationAddressForm
);

export const getChangeCommunicationAddressTechnicalError = createSelector(
  getChangeCommunicationAddressForm,
  (state) => state.technicalError
);

export const isFetchingAddressCheck = createSelector(
  getChangeCommunicationAddressForm,
  (state) => state.isFetchingAddressCheck
);

export const getInitialAddressType = createSelector(
  getChangeCommunicationAddressForm,
  (state) => state.initialAddressType
);
