import { Injectable } from '@angular/core';
import { closeGenericModal } from '@innogy/common-ui/modals';
import {
  GenericModalResults,
  GenericModalSources,
} from '@innogy/common-ui/shared/interfaces';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { filter, mergeMap } from 'rxjs/operators';

import { performPutConsumptionAction } from '../bac/7.consumption';
import { triggerChatbotAction } from '../chatbot';
import { getFunnelSettings } from '../funnel';

@Injectable()
export class ConsumptionEanMismatchModalEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store
  ) {}

  funnelSetting$ = this.store$.select(getFunnelSettings);

  public readonly onModalPrimaryResult$ = createEffect(() =>
    this.actions$.pipe(
      ofType(closeGenericModal),
      filter(
        (action) =>
          (action.source === GenericModalSources.CONSUMPTION_EAN_MISMATCH_E ||
            action.source === GenericModalSources.CONSUMPTION_EAN_MISMATCH_G) &&
          action.result === GenericModalResults.PRIMARY
      ),
      mergeMap(() => [performPutConsumptionAction()])
    )
  );

  public readonly onModalSecondaryResult$ = createEffect(() =>
    this.actions$.pipe(
      ofType(closeGenericModal),
      filter(
        (action) =>
          (action.source === GenericModalSources.CONSUMPTION_EAN_MISMATCH_E ||
            action.source === GenericModalSources.CONSUMPTION_EAN_MISMATCH_G) &&
          action.result === GenericModalResults.SECONDARY
      ),
      concatLatestFrom(() => this.funnelSetting$),
      mergeMap(([, funnelSettings]) => [
        triggerChatbotAction({
          fallbackUrl: funnelSettings.chatbotFallbackUrl,
        }),
      ])
    )
  );
}
