import { createSelector } from '@ngrx/store';

import { getPaymentDetailsState } from './index';

export const getUpdatePaymentMethodFormState = createSelector(
  getPaymentDetailsState,
  (state) => state.updatePaymentMethodForm.updatePaymentMethodFormState
);

export const getUpdatePaymentMethodPayload = createSelector(
  getUpdatePaymentMethodFormState,
  (state) => ({
    iban: state.value.iban,
    incomingPaymentMethod: state.value.paymentMethod,
  })
);
