<div class="row mb-1">
  <div class="col-12" *ngIf="content?.addressTitle">
    <p class="h6 mb-0 fw-bold">{{ content?.addressTitle }}</p>
  </div>
</div>

<ng-container
  *ngIf="!isAlternateAddressEntryEnabled || !isAddressManualTriggerActive"
>
  <div class="row g-1">
    <!-- PostalCode -->
    <div class="col-12 col-md-4">
      <wl-text
        wlAutocomplete="postal-code"
        [rendering]="rendering"
        [step]="0"
        stepName="invullen"
        fieldName="postcode"
        [sendValue]="false"
        [valueConverter]="_uppercaseConverter"
        [formsControl]="controls?.postalCode"
        [placeholder]="content?.postalCodePlaceholder ?? ''"
        [label]="content?.postalCodeLabel ?? ''"
      ></wl-text>
    </div>
    <!-- HouseNumber -->
    <div class="col-6 col-md-4">
      <wl-number
        wlAutocomplete="housenumber"
        [rendering]="rendering"
        [step]="0"
        stepName="invullen"
        fieldName="huisnummer"
        [sendValue]="false"
        [formsControl]="controls?.houseNumber"
        [placeholder]="content?.houseNumberPlaceholder ?? ''"
        [label]="content?.houseNumberLabel ?? ''"
      ></wl-number>
    </div>
    <div
      class="col-6 col-md-4"
      *ngIf="!controls?.houseNumberAddition?.isDisabled"
    >
      <ng-container *ngIf="!manualHouseNumberAdditionInput; else manualInput">
        <wl-dropdown
          [wlTrackValueChange]="{
            step: 0,
            rendering: rendering,
            label: 'toevoeging',
            inputLabel: controls?.houseNumberAddition?.value || '',
            sendValue: true,
            trackFocus: true,
            stepName: 'invullen',
          }"
          [dropdownItems]="houseNumberAdditions"
          [formsControl]="controls?.houseNumberAddition"
          [label]="content?.houseNumberAdditionLabel ?? ''"
          wlAutomationId="calculate-house-number-addition-dropdown"
        ></wl-dropdown>
      </ng-container>

      <ng-template #manualInput>
        <div class="flex-nowrap position-relative">
          <wl-text
            [rendering]="rendering"
            stepName="invullen"
            [step]="0"
            fieldName="toevoeging"
            placeholder="Toevoeging"
            [formsControl]="controls?.houseNumberAddition"
            [label]="content?.houseNumberAdditionLabel ?? ''"
            wlAutomationId="calculate-house-number-addition-input"
          ></wl-text>
          <button
            class="btn-close inline-input-button my-auto"
            (click)="hideManualHouseNumberAdditionInput.emit()"
          ></button>
        </div>
      </ng-template>
    </div>
    <div class="col-6 col-md-4" *ngIf="showLoader">
      <wl-loading></wl-loading>
    </div>

    <!-- TODO: revisit this, formState is always inValid at this point because the rest of the form (Calculate) has not been filled in yet (SOS-6585)-->
    <div class="col-12 mt-0" *ngIf="formState | isInvalid">
      <!-- Error messages -->
      <ng-container *ngIf="controls?.postalCode | isInvalid">
        <wl-input-error
          wlAutomationId="postalcode-required-error"
          *ngIf="controls?.postalCode?.errors?.required"
          [messageField]="content?.postalCodeRequiredError ?? ''"
          messageDescription="postcode"
          [rendering]="rendering"
          stepName="invullen"
        ></wl-input-error>
        <wl-input-error
          wlAutomationId="postalcode-invalid-error"
          *ngIf="controls?.postalCode?.errors?.isPostalCode"
          [messageField]="content?.postalCodeNotValidError ?? ''"
          messageDescription="postcode"
          [rendering]="rendering"
          stepName="invullen"
        ></wl-input-error>
      </ng-container>
      <ng-container *ngIf="controls?.houseNumber | isInvalid">
        <wl-input-error
          wlAutomationId="housenumber-required-error"
          *ngIf="controls?.houseNumber?.errors?.requiredNumber"
          [messageField]="content?.houseNumberRequiredError"
          messageDescription="huisnummer"
          [rendering]="rendering"
          stepName="invullen"
        ></wl-input-error>
        <wl-input-error
          wlAutomationId="housenumber-invalid-error"
          *ngIf="controls?.houseNumber?.errors?.number"
          [messageField]="content?.houseNumberNotValidError"
          messageDescription="huisnummer"
          [rendering]="rendering"
          stepName="invullen"
        ></wl-input-error>
      </ng-container>
    </div>

    <div class="col-12 mt-2" *ngIf="showAddressNotFoundMessage">
      <wl-info-card
        wlAutomationId="calculate-installation-address-not-found"
        [title]="{ value: content?.addressNotFoundErrorTitle }"
        [message]="{ value: content?.addressNotFoundErrorMessage }"
        [border]="true"
        [shadow]="false"
      ></wl-info-card>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="isAlternateAddressEntryEnabled">
  <ng-container *ngIf="!isAddressManualTriggerActive; else inlineaddress">
    <div class="row mt-1">
      <div class="col-12">
        <button
          (click)="setAddressButtonClicked.emit(true)"
          wlTrackLink
          [trackLinkInfo]="{
            eventType: 'button-click',
            rendering: rendering,
            field: 'Bereken maandbedrag',
          }"
          class="btn btn-sales-primary w-100"
          wlAutomationId="calculate-form-submit-button"
          [disabled]="addressNextButtonDisabled"
        >
          <span>{{ content?.AddressManualInputButtonLabel }}</span>
          <fa-icon
            class="ms-2"
            icon="spinner-third"
            animation="spin"
            *ngIf="showSpinnerWhenNextButtonDisabled"
          />
        </button>
      </div>
    </div>
  </ng-container>

  <ng-template #inlineaddress>
    <div class="row mt-1">
      <div class="col-12">
        <p class="mb-0">
          <span>{{
            installationAddressData ?? content?.addressNotFoundErrorTitle
          }}</span>
          <span
            ><button
              class="btn btn-link"
              (click)="updateAddressButtonClicked()"
            >
              {{ content?.updateAddressLabel }}
            </button>
          </span>
        </p>
      </div>
    </div>
  </ng-template>
</ng-container>
