import { createAction } from '@ngrx/store';

const PREFIX = '[Solar Panels Wizard]';

export const solarPanelsWizardFormSubmitAction = createAction(
  `${PREFIX} Submit form`
);

export const solarPanelsWizardQuestionnaireOpenModalAction = createAction(
  `${PREFIX} Open questionnaire modal`
);

export const solarPanelsWizardQuestionnaireCloseModalAction = createAction(
  `${PREFIX} Close questionnaire modal`
);
