import { NgModule } from '@angular/core';
import {
  DefaultComponentModule,
  DefaultFormComponentModule,
} from '@innogy/core/modules/components';

import { SupplyAddressComponent } from './supply-address/supply-address.component';

@NgModule({
  imports: [DefaultComponentModule, DefaultFormComponentModule],
  declarations: [SupplyAddressComponent],
  exports: [SupplyAddressComponent],
})
export class BecomeACustomerUiModule {}
