import type { ValidationErrors } from 'ngrx-forms';

export interface IsNumberOrNullValidator {
  actual: any;
}

declare module 'ngrx-forms/src/state' {
  export interface ValidationErrors {
    requiredNumber?: IsNumberOrNullValidator;
  }
}
/**
 * Validates that the given number is not null or NaN
 * @param value
 */
export const requiredNumber = (value?: number): ValidationErrors => {
  if (value != null && !isNaN(value)) {
    return {};
  }

  return {
    requiredNumber: { actual: value },
  };
};
